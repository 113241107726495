/** @jsxImportSource @emotion/react */

import React, { useContext, useMemo, useState } from "react";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { sumTwoAnswerCounts } from "../../../utils/utils";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack } from "victory";
import { VictoryLabel } from "victory-core";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../constants/data_platform_colors";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { css } from "@emotion/react";

export default function CardStatsGenderAgeChartForPortrait({
  cardId,
  title,
  colors = [
    DATA_PLATFORM_RED_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_GREEN_GRAY
  ],
  showCounts
}: {
  cardId: string;
  title?: string;
  colors?: [string, string, string];
  showCounts?: boolean;
}) {
  const [isNull, setIsNull] = useState(false);
  const [cardAnswerAgeGenderState] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const dataProps = useContext(DataPropsContext);

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    if (
      cardAnswerAgeGenderState.id &&
      cardAnswerAgeGenderState.id.length < 5 &&
      Object.keys(cardAnswerAgeGenderState).length < 2
    ) {
      setIsNull(true);
      return {};
    }
    setIsNull(false);
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      let ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
      if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
        return prev;
      }

      if (ageGroupNumber > 60) {
        ageGroupNumber = 60;
      }

      const ageGroup = ageGroupNumber.toFixed();

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];
      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            (prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema,
            (birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState]);

  if (!statsByAgeGroup) {
    return null;
  }

  const maleData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];
  const femaleData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];

  Object.keys(statsByAgeGroup).forEach(ageGroup => {
    maleData.push({
      x: ageGroup + "대" + (ageGroup === "60" ? "+" : ""),

      ao:
        ((statsByAgeGroup[ageGroup]["male"]?.o || 0) /
          (statsByAgeGroup[ageGroup]["male"]?.count || 1)) *
        100,
      ad:
        ((statsByAgeGroup[ageGroup]["male"]?.["?"] || 0) /
          (statsByAgeGroup[ageGroup]["male"]?.count || 1)) *
        100,
      ax:
        ((statsByAgeGroup[ageGroup]["male"]?.x || 0) /
          (statsByAgeGroup[ageGroup]["male"]?.count || 1)) *
        100,
      count: statsByAgeGroup[ageGroup]["male"]?.count || 0
    });
    femaleData.push({
      x: ageGroup + "대" + (ageGroup === "60" ? "+" : ""),

      ao:
        ((statsByAgeGroup[ageGroup]["female"]?.o || 0) /
          (statsByAgeGroup[ageGroup]["female"]?.count || 1)) *
        100,
      ad:
        ((statsByAgeGroup[ageGroup]["female"]?.["?"] || 0) /
          (statsByAgeGroup[ageGroup]["female"]?.count || 1)) *
        100,
      ax:
        ((statsByAgeGroup[ageGroup]["female"]?.x || 0) /
          (statsByAgeGroup[ageGroup]["female"]?.count || 1)) *
        100,
      count: statsByAgeGroup[ageGroup]["female"]?.count || 0
    });
  });

  maleData.forEach(ageGroup => {
    if (ageGroup.ao === 0 && ageGroup.ax === 0 && ageGroup.ad === 0) {
      ageGroup.ax = 100;
    }
    if (ageGroup.ao !== 0 && ageGroup.ax === 0 && ageGroup.ad !== 0) {
      (ageGroup.ax = ageGroup.ad + ageGroup.ax), (ageGroup.ad = 0);
    }
  });

  femaleData.forEach(ageGroup => {
    if (ageGroup.ao === 0 && ageGroup.ax === 0 && ageGroup.ad === 0) {
      ageGroup.ax = 100;
    }
    if (ageGroup.ao !== 0 && ageGroup.ax === 0 && ageGroup.ad !== 0) {
      ageGroup.ad = 0;
    }
  });

  const BAR_WIDTH = 25;

  return (
    <div>
      {isNull ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
            color: ${DATA_PLATFORM_RED_GRAY};
          `}
        >
          데이터가 아직 없습니다.
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 400px;
              height: 180px;
            `}
          >
            <div
              css={css`
                margin-right: -15;
              `}
            >
              <div
                css={css`
                  text-align: center;
                  color: #121212;
                  font-size: 16;
                  font-weight: 700;
                  margin-top: -9px;
                `}
              >
                남
              </div>
              <div
                css={css`
                  margin-top: 10px;
                  height: 150px;
                  width: 200px;
                `}
              >
                <VictoryChart horizontal width={480} height={400}>
                  <VictoryStack domain={{ y: [0, 100] }}>
                    <VictoryBar
                      barWidth={BAR_WIDTH}
                      style={{
                        data: {
                          fill: colors[0]
                        }
                      }}
                      data={maleData}
                      y={data => Math.abs(data.ax)}
                    />
                    <VictoryBar
                      barWidth={BAR_WIDTH}
                      style={{
                        data: {
                          fill: colors[1]
                        }
                      }}
                      data={maleData}
                      y={data => Math.abs(data.ad)}
                    />
                    <VictoryBar
                      barWidth={BAR_WIDTH}
                      style={{
                        data: {
                          fill: colors[2]
                        }
                      }}
                      data={maleData}
                      y={data => Math.abs(data.ao)}
                    />
                  </VictoryStack>

                  <VictoryAxis
                    style={{
                      axis: { stroke: "transparent" },
                      ticks: { stroke: "transparent" },
                      tickLabels: { fill: "transparent" }
                    }}
                    tickLabelComponent={
                      <VictoryLabel x={0} textAnchor="start" />
                    }
                    tickValues={maleData.map(point => point.x).reverse()}
                    key={"axis1"}
                  />
                </VictoryChart>
              </div>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 11px;
                margin-left: -18px;
                margin-right: -10px;
                margin-top: 30px;
              `}
            >
              {maleData
                .map(data => (
                  <div
                    css={css`
                      margin-left: 11px;
                      margin-right: -7px;
                      width: 37px;
                      height: 23px;
                    `}
                    key={data.x}
                  >
                    {data.x}
                  </div>
                ))
                .reverse()}
            </div>
            <div>
              <div
                css={css`
                  text-align: center;
                  color: #121212;
                  font-size: 16;
                  font-weight: 700;
                  margin-top: -9px;
                `}
              >
                여
              </div>
              <div
                css={css`
                  margin-top: 10px;
                  height: 150px;
                  width: 200px;
                `}
              >
                <VictoryChart horizontal width={480} height={400}>
                  <VictoryStack domain={{ y: [0, 100] }}>
                    <VictoryBar
                      barWidth={BAR_WIDTH}
                      style={{
                        data: {
                          fill: colors[2]
                        }
                      }}
                      data={femaleData}
                      y={data => Math.abs(data.ao)}
                    />
                    <VictoryBar
                      barWidth={BAR_WIDTH}
                      style={{
                        data: {
                          fill: colors[1]
                        }
                      }}
                      data={femaleData}
                      y={data => Math.abs(data.ad)}
                    />
                    <VictoryBar
                      barWidth={BAR_WIDTH}
                      style={{
                        data: {
                          fill: colors[0]
                        }
                      }}
                      data={femaleData}
                      y={data => Math.abs(data.ax)}
                    />
                  </VictoryStack>

                  <VictoryAxis
                    style={{
                      axis: { stroke: "transparent" },
                      ticks: { stroke: "transparent" },
                      tickLabels: { opacity: 0 }
                    }}
                    tickLabelComponent={
                      <VictoryLabel x={10} textAnchor="middle" />
                    }
                    tickValues={maleData.map(point => point.x).reverse()}
                    key={"axis1"}
                  />
                </VictoryChart>
              </div>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              width: 380px;
              margin-left: 16px;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  margin-left: 10px;
                  width: 32px;
                  height: 10px;
                  background-color: ${DATA_PLATFORM_GREEN_GRAY};
                `}
              ></div>
              <div
                css={css`
                  font-size: 14px;
                  font-weight: 700;
                  margin-left: 10px;
                `}
              >
                찬성
              </div>
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  width: 32px;
                  height: 10px;
                  background-color: ${DATA_PLATFORM_YELLOW_GRAY};
                `}
              ></div>
              <div
                css={css`
                  font-size: 14px;
                  font-weight: 700;
                  margin-left: 10px;
                `}
              >
                중립
              </div>
            </div>
            <div
              css={css`
                margin-right: 10px;
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  width: 32px;
                  height: 10px;
                  background-color: ${DATA_PLATFORM_RED_GRAY};
                `}
              ></div>
              <div
                css={css`
                  font-size: 14px;
                  font-weight: 700;
                  margin-left: 10px;
                `}
              >
                반대
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
