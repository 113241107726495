import { ThumbUpOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BACKGROUND_COLOR, GRAY_DARK } from "../../../constants/enums";
import {
  CommentSchema,
  FirestoreCollection,
  UserNotificationItemSchema
} from "../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  parseDoc,
  truncateLongSentence
} from "../../../utils/utils";
import UserName from "../../profile/OXOUserProfile/UserName";
import CommentPopup from "../../topic/Question/Comments/CommentPopup";

const MAX_COMMENT_CUTOFF = 30;

export default function CommentLikeNotification({
  userNotification
}: {
  userNotification: UserNotificationItemSchema;
}) {
  const history = useHistory();

  const location = useLocation();

  const [comment, setComment] = useState<CommentSchema>();

  const likeUserNotificationMetadata = userNotification.likeUserNotification;
  useEffect(() => {
    if (likeUserNotificationMetadata?.commentId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .doc(likeUserNotificationMetadata?.commentId)
        .onSnapshot(snapshot => setComment(parseDoc(snapshot)));
      return () => unsubscribe();
    }
  }, [likeUserNotificationMetadata?.commentId]);

  const [openComment, setOpenComment] = useState<boolean>(false);

  if (!comment) {
    return null;
  }

  const commentText = comment.comment;

  return (
    <div
      style={{
        display: "flex",
        padding: "20px 20px 20px 10px",
        borderBottom: "1px solid " + BACKGROUND_COLOR
      }}
    >
      <div
        style={{
          color: GRAY_DARK,
          minWidth: "60px",
          padding: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "baseline"
        }}
      >
        <div>
          <ThumbUpOutlined
            style={{
              color: GRAY_DARK,
              fontWeight: "bold"
            }}
          />
          <span
            style={{
              fontSize: "12px",
              marginLeft: "2px",
              verticalAlign: "sub"
            }}
          >
            {likeUserNotificationMetadata?.likes}
          </span>
        </div>
      </div>

      {likeUserNotificationMetadata && (
        <CommentPopup
          commentId={likeUserNotificationMetadata.commentId}
          openComment={openComment}
          setOpenComment={setOpenComment}
        />
      )}

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setOpenComment(true);
        }}
      >
        {likeUserNotificationMetadata && (
          <span
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={e => {
              e.stopPropagation();
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(
                    history.location.search,
                    likeUserNotificationMetadata.likeUserId
                  )
              );
            }}
          >
            <UserName profileUserId={likeUserNotificationMetadata.likeUserId} />
          </span>
        )}
        님이{" "}
        {commentText
          ? '"' + truncateLongSentence(commentText, MAX_COMMENT_CUTOFF) + '"'
          : "삭제된 "}{" "}
        댓글을 공감했습니다.
        <div
          style={{
            fontSize: "14px",
            color: GRAY_DARK
          }}
        >
          {new Date(userNotification.createdAt).toLocaleDateString() +
            " " +
            new Date(userNotification.createdAt).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
}
