import React, { SetStateAction } from "react";
import { GRAY_LIGHT } from "../../../constants/enums";

export default function DetailPagesNavigation({
  menuItemArray,
  tabIndex,
  setTabIndex
}: {
  menuItemArray: string[];
  tabIndex: number;
  setTabIndex: React.Dispatch<SetStateAction<number>>;
}) {
  return menuItemArray.length > 4 ? (
    <div>
      <div style={{ paddingBottom: 10 }} />
      <div
        style={{
          width: "100%",
          minHeight: 33,
          cursor: "pointer",
          paddingTop: 15,
          display: "flex",
          backgroundColor: "white",
          overflow: "auto",
          padding: "0 13px",
          borderBottom: `1px solid ${GRAY_LIGHT}`
        }}
        onTouchMove={e => e.stopPropagation()}
        onTouchStart={e => e.stopPropagation()}
        onTouchEnd={e => e.stopPropagation()}
      >
        {menuItemArray.map((item, i) => {
          return (
            <div
              key={item}
              style={{
                minWidth: 100,
                display: "flex",
                cursor: "pointer",
                justifyContent: "center",
                flexShrink: 0,
                marginBottom: -1
              }}
              onClick={e => {
                setTabIndex(i);

                const openElement = document.getElementById(item);
                if (openElement) {
                  openElement.scrollIntoView({
                    behavior: "smooth"
                  });
                }
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}
              >
                <div
                  style={{
                    ...(tabIndex === i
                      ? {
                          fontWeight: "bold",
                          color: "#3E7A99"
                        }
                      : {
                          fontWeight: "normal",
                          color: "black",
                          borderBottom: "none"
                        })
                  }}
                >
                  {item}
                </div>
                <div
                  style={{
                    height: 4,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: tabIndex === i ? "#3E7A99" : "transparent"
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div
      style={{
        cursor: "pointer",
        paddingTop: 15,
        display: "flex",
        justifyContent: "space-around",
        borderBottom: `0.1px solid ${GRAY_LIGHT}`,
        backgroundColor: "white"
      }}
    >
      {menuItemArray.map((item, i) => {
        return (
          <div
            key={item}
            style={{
              width: "100%",
              height: 35,
              display: "flex",
              cursor: "pointer",
              justifyContent: "center",
              ...(tabIndex === i
                ? {
                    fontWeight: "bold",
                    color: "#3E7A99"
                  }
                : {
                    fontWeight: "normal",
                    color: "black",
                    borderBottom: "none"
                  }),
              marginBottom: -1
            }}
            onClick={() => {
              setTabIndex(i);
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <div
                style={{
                  ...(tabIndex === i
                    ? {
                        fontWeight: "bold",
                        color: "#3E7A99"
                      }
                    : {
                        fontWeight: "normal",
                        color: "black",
                        borderBottom: "none"
                      })
                }}
              >
                {item}
              </div>
              <div
                style={{
                  height: 4,
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  backgroundColor: tabIndex === i ? "#3E7A99" : "transparent"
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
