import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { APPLE_MORE, GRAY_MEDIUM } from "../../../../constants/enums";
import { ROUTES } from "../../../../constants/routes";
import BackButton from "../../../chat/imgs/white_mode_back.svg";
import { DataPropsContext } from "../../Contexts/DataPropsContext";
import { GlobalVariableContext } from "../../Contexts/GlobalVariableContext";
import OXOButton from "../../OXOButton/OXOButton";
import firebase from "firebase/compat";
import { passwordValidationChecker } from "../../../../utils/utils";

export function EmailLogin() {
  const global = useContext(GlobalVariableContext);
  const loginSuccessUrl = global?.loginSuccessUrl || ROUTES.INTRO_LOGGED_IN;
  const history = useHistory();
  const [myState, setMyState] = useState("EMAIL");
  const [showResetPassword, setShowReset] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [message, setMessage] = useState("");
  const authInstance = firebase.auth();
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  if (currentUserId) {
    history.push(ROUTES.HOME);
  }

  useEffect(() => {
    console.log(global?.loginSuccessUrl);
  }, []);

  useEffect(() => {
    switch (myState) {
      case "EMAIL":
        document.getElementById("email")?.focus();
        break;
      case "LOGIN":
        document.getElementById("password")?.focus();
        break;
      case "SIGNUP":
        document.getElementById("password1")?.focus();
        break;
      default:
        document.getElementById("email")?.focus();
        break;
    }
  }, [myState]);

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePassword1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword1(e.target.value);
  };

  const handlePassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value);
  };

  const nextBtnClick = () => {
    authInstance
      .fetchSignInMethodsForEmail(email)
      .then(result => {
        setMessage("");
        if (result.includes("password")) {
          setMyState("LOGIN");
        } else if (result.toString() === "facebook.com") {
          alert("해당 이메일은 페이스북 로그인으로 시도해 주세요");
          history.go(-1);
        } else if (result.toString() === "google.com") {
          alert("해당 이메일은 구글 로그인으로 시도해 주세요");
          history.go(-1);
        } else if (result.toString() === "apple.com") {
          alert("해당 이메일은 애플 로그인으로 시도해 주세요");
          history.go(-1);
        } else {
          setMyState("SIGNUP");
        }
        return true;
      })
      .catch(error => {
        if (error.code === "auth/invalid-email") {
          setMessage("유효하지 않은 이메일 입니다");
        }
      });
  };

  const resetPassword = () => {
    authInstance
      .sendPasswordResetEmail(email)
      .then(result => {
        alert("비밀번호 변경안내 메일을 발송했습니다");
        window.location.reload();
        return true;
      })
      .catch(error => {});
  };

  const loginBtnClick = () => {
    authInstance
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        firebase.analytics().logEvent("v4_login_success", {
          event_category: "signin",
          event_label: firebase.auth.EmailAuthProvider.PROVIDER_ID
        });
        setMessage("");
        window.location.href = loginSuccessUrl;
        return true;
      })
      .catch(error => {
        if (error.code === "auth/wrong-password") {
          setMessage("비밀번호가 올바르지 않습니다");
          setShowReset(true);
        } else if (error.code === "auth/user-not-found") {
          setMessage("존재하지 않는 사용자 입니다");
        } else if (error.code === "auth/too-many-requests") {
          setMessage("중복된 시도입니다. 다음에 다시 시도해 주세요");
        }
      });
  };

  const backBtnClick = () => {
    if (myState === "EMAIL") {
      history.go(-1);
    } else {
      setMyState("EMAIL");
      setMessage("");
      setShowReset(false);
    }
  };

  const signUpBtnClick = () => {
    const passwordValidationCheckResult = passwordValidationChecker(
      password1,
      password2
    );

    if (passwordValidationCheckResult.length) {
      setMessage(passwordValidationCheckResult[0]);
      return;
    } else {
      authInstance
        .createUserWithEmailAndPassword(email, password1)
        .then(result => {
          firebase.analytics().logEvent("v4_signup_success", {
            event_category: "signup",
            event_label: firebase.auth.EmailAuthProvider.PROVIDER_ID
          });
          window.location.href = loginSuccessUrl;
          return true;
        })
        .catch(error => {
          setMessage(error.code);
        });
    }
  };

  return (
    <div>
      <div
        className={"timeline_center_container"}
        style={{
          backgroundColor: "white",
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column"
          }}
        >
          <span
            onClick={backBtnClick}
            style={{
              display: "flex",
              width: 13,
              justifyContent: "initial",
              cursor: "pointer"
            }}
          >
            <img src={BackButton} alt={BackButton} />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {/* 이메일 확인 */}
          <div
            style={{
              width: "100%",
              maxWidth: 335,
              padding: "10px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}
          >
            <div
              style={{
                color: "#207D9D",
                fontSize: 12,
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: 10
              }}
            >
              이메일
            </div>
            <input
              id="email"
              style={{
                borderStyle: "none none solid none",
                borderRadius: 0,
                borderColor: GRAY_MEDIUM,
                borderWidth: 1,
                fontSize: 18,
                margin: "10px 0"
              }}
              value={email}
              onChange={handleEmail}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  document.getElementById("nextBtn")?.click();
                }
              }}
            ></input>

            {myState === "EMAIL" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20
                }}
              >
                <OXOButton
                  id="nextBtn"
                  label={"다음"}
                  onClick={() => {
                    firebase.analytics().logEvent("v4_click_email_login_next", {
                      event_category: "signin"
                    });
                    nextBtnClick();
                  }}
                  style={{
                    width: 297,
                    height: 73,
                    borderRadius: 10,
                    backgroundColor: "white",
                    border: `1px solid #3E7A99`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "#3E7A99",
                    fontSize: 16
                  }}
                />
              </div>
            )}
          </div>
          {/* 로그인 */}
          {myState === "LOGIN" && (
            <div
              style={{
                width: "100%",
                maxWidth: 335,
                padding: "10px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start"
              }}
            >
              <div
                style={{
                  color: "#207D9D",
                  fontSize: 12,
                  display: "flex",
                  justifyContent: "flex-start"
                }}
              >
                비밀번호
              </div>
              <input
                id="password"
                style={{
                  borderStyle: "none none solid none",
                  borderRadius: 0,
                  borderColor: GRAY_MEDIUM,
                  borderWidth: 1,
                  fontSize: 18,
                  margin: "10px 0"
                }}
                type={"password"}
                value={password}
                onChange={handlePassword}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    document.getElementById("loginBtn")?.click();
                  }
                }}
              ></input>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20
                }}
              >
                <OXOButton
                  id="loginBtn"
                  label={"로그인하기"}
                  onClick={() => {
                    firebase
                      .analytics()
                      .logEvent("v4_click_email_login_button", {
                        event_category: "signin"
                      });
                    loginBtnClick();
                  }}
                  style={{
                    width: 297,
                    height: 73,
                    borderRadius: 10,
                    margin: "7px 0",
                    backgroundColor: "#3E7A99",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "white",
                    marginBottom: 36,
                    fontWeight: "bold",
                    fontSize: 16
                  }}
                />
              </div>
            </div>
          )}

          {/* 신규가입 */}
          {myState === "SIGNUP" && (
            <div
              style={{
                width: "100%",
                maxWidth: 335,
                padding: "10px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start"
              }}
            >
              <div
                style={{
                  color: "#207D9D",
                  fontSize: 12,
                  display: "flex",
                  justifyContent: "flex-start"
                }}
              >
                비밀번호
              </div>
              <input
                id="password1"
                style={{
                  borderStyle: "none none solid none",
                  borderRadius: 0,
                  borderColor: GRAY_MEDIUM,
                  borderWidth: 1,
                  fontSize: 18,
                  margin: "10px 0"
                }}
                type={"password"}
                value={password1}
                onChange={handlePassword1}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    document.getElementById("signUpBtn")?.click();
                  }
                }}
              ></input>
              <div
                style={{
                  color: "#207D9D",
                  fontSize: 12,
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingTop: 20
                }}
              >
                비밀번호 확인
              </div>
              <input
                id="password2"
                style={{
                  borderStyle: "none none solid none",
                  borderRadius: 0,
                  borderColor: GRAY_MEDIUM,
                  borderWidth: 1,
                  fontSize: 18,
                  margin: "10px 0"
                }}
                type={"password"}
                value={password2}
                onChange={handlePassword2}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    document.getElementById("signUpBtn")?.click();
                  }
                }}
              ></input>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20
                }}
              >
                <OXOButton
                  id="signUpBtn"
                  label={"신규가입"}
                  onClick={() => {
                    firebase.analytics().logEvent("v4_click_email_signup", {
                      event_category: "signup"
                    });
                    signUpBtnClick();
                  }}
                  style={{
                    width: 297,
                    height: 73,
                    borderRadius: 10,
                    backgroundColor:
                      password1 && password1 === password2
                        ? "#3E7A99"
                        : "white",
                    border: `1px solid #3E7A99`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color:
                      password1 && password1 === password2
                        ? "white"
                        : "#3E7A99",
                    fontWeight:
                      password1 && password1 === password2 ? "bold" : "normal",
                    fontSize: 16
                  }}
                />
              </div>
            </div>
          )}

          {/* 오류 메세지가 출력되는 공간 */}
          <div>{message}</div>

          {/* 비밀번호가 틀렸을때 출력되는 안내공간 */}
          {showResetPassword && (
            <div
              onClick={() => {
                firebase.analytics().logEvent("v4_click_reset_password", {
                  event_category: "signin"
                });
                resetPassword();
              }}
              style={{ cursor: "pointer", color: APPLE_MORE }}
            >
              비밀번호 변경하기[클릭]
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
