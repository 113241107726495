/** @jsxImportSource @emotion/react */

import { WarningOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  FACEBOOK_FONT_BLACK,
  GRAY_BACKGROUND,
  oxoColors,
  TRIBE_INFO_BY_ID,
  UserAnswer
} from "../../../../constants/enums";
import {
  CommentSchema,
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../../constants/firestore_schema";
import blockOx from "../../../../img/ox/oxoxo.svg";
import { parseDoc, useIsAdmin } from "../../../../utils/utils";
import LikeStatsByRow from "../../../foundation/OXOComment/LikeStatsByRow";
import UserName from "../../../profile/OXOUserProfile/UserName";
import UserProfilePhoto from "../../../views/StatsView/UserProfilePhoto";

import { TribeExpression } from "../../../../constants/expressions";
import { isBadContents } from "../../../../utils/contents";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import OXOText from "../../../utility/OXOText/OXOText";
import UserDetailedStats from "../../../views/Today/UserDetailedStats";
import { css } from "@emotion/react";

export default function CommentLine({
  commentId,
  disableExpressions = false
}: {
  commentId: string;
  disableExpressions?: boolean;
}) {
  const [hideBadComment, setHideBadComment] = useState<boolean>(false);

  const [comment] = useFirestoreLiveDocument<CommentSchema>(
    FirestoreCollection.COMMENTS,
    commentId
  );

  useEffect(() => {
    const isBad =
      comment &&
      !!comment.comment &&
      (!!comment.bad || isBadContents(comment.comment));
    if (isBad !== undefined && isBad !== hideBadComment) {
      setHideBadComment(isBad);
    }
  }, [comment]);

  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (comment && comment.isSpecialUser) {
      firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(comment?.userId)
        .get()
        .then(parseDoc)
        .then(setUserProfileMetadata);
    }
  }, [comment]);
  const isAdmin = useIsAdmin();

  if (!comment || (comment.isSpecialUser && !userProfileMetadata)) {
    return null;
  }

  const adminBlock = isAdmin && (
    <span
      style={{ marginLeft: "5px", cursor: "pointer" }}
      onClick={() => {
        if (comment.id) {
          firebase
            .firestore()
            .collection(FirestoreCollection.COMMENTS)
            .doc(comment.id)
            .set({ bad: !comment.bad }, { merge: true })
            .then(() =>
              alert(
                "블라인드: " +
                  !comment.bad +
                  " 처리 했습니다. 새로고침 해야 보일걸요? 바로 보여야 하는데 이상하지만 나중에 고칠게요."
              )
            );
        }
      }}
    >
      <WarningOutlined />
    </span>
  );

  return (
    <div
      style={{
        width: "100%",
        minHeight: 50
      }}
      key={comment.id}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          padding: "0px 10px 10px 10px",
          alignItems: "flex-start",
          justifyContent: "space-around",
          position: "relative"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 5
          }}
        >
          <div
            style={{
              marginTop: 5,
              width: 36,
              height: 36,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor:
                (comment.userTribeId &&
                  TRIBE_INFO_BY_ID[comment.userTribeId].bgColor) ||
                GRAY_BACKGROUND
            }}
          >
            {comment.userTribeId && (
              <UserProfilePhoto
                tribeId={comment.userTribeId}
                photoUrl={userProfileMetadata?.photo}
                width={"22px"}
                expression={
                  disableExpressions
                    ? TribeExpression.NORMAL
                    : comment.answer === UserAnswer.O
                    ? TribeExpression.HAPPY
                    : comment.answer === UserAnswer.X
                    ? TribeExpression.ANGRY
                    : TribeExpression.NORMAL
                }
              />
            )}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            width: "100%"
          }}
        >
          <div
            key={comment.id}
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              marginTop: 5
            }}
          >
            <div
              style={{
                textAlign: "left",
                width: "100%",
                flexGrow: 1
              }}
            >
              <div>
                {hideBadComment ? (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setHideBadComment(false);
                    }}
                  >
                    <div>
                      <img src={blockOx} width={70} alt="blockOxo" />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      욕설이나 비하가 포함된 의견입니다. 클릭하시면 내용을 보실
                      수 있습니다.
                      {adminBlock}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer"
                        }}
                      >
                        <UserName profileUserId={comment.userId} enableLink />
                        {comment.answer && (
                          <div
                            style={{
                              fontSize: 10,
                              padding: "3px 7px",
                              marginLeft: 10,
                              marginTop: -1,
                              borderRadius: 10,
                              backgroundColor:
                                comment.answer === UserAnswer.O
                                  ? oxoColors.O
                                  : comment.answer === UserAnswer.DUNNO
                                  ? oxoColors.DUNNO
                                  : oxoColors.X
                            }}
                          >
                            {comment.answer === UserAnswer.O
                              ? "찬성"
                              : comment.answer === UserAnswer.DUNNO
                              ? "중립"
                              : "반대"}
                          </div>
                        )}
                        <div
                          css={css`
                            margin-left: 10px;
                          `}
                        >
                          <UserDetailedStats userId={comment.userId} />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        textOverflow: "hidden",
                        fontSize: 15,
                        lineHeight: "20px",
                        textAlign: "left",
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-start"
                      }}
                    >
                      <div
                        style={{
                          color: FACEBOOK_FONT_BLACK,
                          paddingRight: 20,
                          width: "100%",
                          marginTop: 2,
                          cursor: "pointer"
                        }}
                      >
                        <OXOText text={comment.comment} useDivForParagraph />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          <div style={{ marginRight: 6, paddingTop: 5 }}>
                            <LikeStatsByRow
                              referenceId={comment.id}
                              displayLikeCount={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "0 15px" }}>
        <div
          style={{
            width: "100%",
            margin: "10px 0 0",
            borderBottom: "1px solid #E5E5E5"
          }}
        />
      </div>
    </div>
  );
}
