import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  FirestoreCollection,
  LikeSchema
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot, groupBy } from "../../../utils/utils";
import LikeStatBadge from "./LikeStatBadge";

export default function LikeStatsByRow({
  referenceId,
  displayLikeCount = false
}: {
  referenceId: string;
  displayLikeCount?: boolean;
}) {
  const [likes, setLikes] = useState<LikeSchema[]>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.LIKES)
      .where("referenceId", "==", referenceId)
      .onSnapshot(snapshot => setLikes(getDocsFromSnapshot(snapshot)));
    return () => unsubscribe();
  }, []);

  if (!likes) {
    return null;
  }

  const likesByTribe = groupBy(likes, (like: LikeSchema) =>
    like.isSpecialUserLike ? "special" : like.likeUserTribeId
  );

  const commentLikeCountArray: number[] = Object.keys(likesByTribe).map(
    tribe => likesByTribe[tribe].length
  );

  return (
    <div style={{ display: "flex" }} key={referenceId}>
      {displayLikeCount && Object.keys(likesByTribe).length !== 0 && (
        <span style={{ color: "#B0B3B2", fontSize: 13, paddingRight: 10 }}>
          좋아요
          {commentLikeCountArray.length
            ? commentLikeCountArray.reduce((a, b) => a + b)
            : 0}
          개
        </span>
      )}
      {Object.keys(likesByTribe).map(tribeId => (
        <LikeStatBadge
          tribeId={tribeId}
          likes={likesByTribe[tribeId]}
          key={tribeId}
        />
      ))}
    </div>
  );
}
