/** @jsxImportSource @emotion/react */

import React, { useContext, useMemo } from "react";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, sumTwoAnswerCounts } from "../../../utils/utils";
import { VictoryBar, VictoryChart, VictoryGroup } from "victory";
import { oxoColors, PROFILE_SIDE_MENU_ITEMS } from "../../../constants/enums";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOButton from "../../foundation/OXOButton/OXOButton";

import { useHistory } from "react-router";
import { css } from "@emotion/react";

const BAR_WIDTH = 15;
const MIN_CUT_OFF_GROUPS = 3;
export default function MentionAgeChart({
  cardId,
  colors = [oxoColors.O, oxoColors.DUNNO, oxoColors.X]
}: {
  cardId: string;
  colors?: [string, string, string];
}) {
  const history = useHistory();
  const [cardAnswerAgeGenderState] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      const ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
      if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
        return prev;
      }

      const ageGroup = ageGroupNumber.toFixed();

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];

      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            (prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema,
            (birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState]);

  if (
    ((statsByAgeGroup && Object.keys(statsByAgeGroup).length) || 0) <
    MIN_CUT_OFF_GROUPS
  ) {
    return null;
  }

  if (!statsByAgeGroup) {
    return null;
  }

  const ageData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];

  Object.keys(statsByAgeGroup).forEach(ageGroup => {
    ageData.push({
      x: ageGroup + "대",

      ao:
        (((statsByAgeGroup[ageGroup]["male"]?.o || 0) +
          (statsByAgeGroup[ageGroup]["female"]?.o || 0)) /
          ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
            (statsByAgeGroup[ageGroup]["female"]?.count || 0))) *
        100,
      ad:
        (((statsByAgeGroup[ageGroup]["male"]?.["?"] || 0) +
          (statsByAgeGroup[ageGroup]["female"]?.["?"] || 0)) /
          ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
            (statsByAgeGroup[ageGroup]["female"]?.count || 0))) *
        100,
      ax:
        (((statsByAgeGroup[ageGroup]["male"]?.x || 0) +
          (statsByAgeGroup[ageGroup]["female"]?.x || 0)) /
          ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
            (statsByAgeGroup[ageGroup]["female"]?.count || 0))) *
        100,
      count:
        (statsByAgeGroup[ageGroup]["male"]?.count || 0) +
        (statsByAgeGroup[ageGroup]["female"]?.count || 0)
    });
  });

  const width = 900;
  const height = 500;

  console.log(ageData);
  return (
    <div style={{ paddingTop: 0 }}>
      {(!currentUserMetadata?.userBasicInfo?.gender ||
        !currentUserMetadata?.userBasicInfo?.birthYear) &&
        !window.navigator.userAgent.startsWith("==Flutter==") && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10
            }}
          >
            <div>
              <OXOButton
                id="nextBtn"
                label={"나이, 성별 입력하기"}
                onClick={() => {
                  history.push(
                    history.location.pathname +
                      addToCardIdStackUrl(
                        history.location.search,
                        PROFILE_SIDE_MENU_ITEMS.SETTING
                      )
                  );
                }}
                style={{
                  width: 200,
                  height: 40,
                  borderRadius: 10,
                  backgroundColor: "white",
                  border: `1px solid #3E7A99`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3E7A99",
                  fontSize: 16
                }}
              />
            </div>
          </div>
        )}
      <div
        css={css`
          width: 450px;
          display: flex;
        `}
      >
        <div
          style={{
            marginRight: -15
          }}
        >
          <VictoryChart height={height} width={width}>
            <VictoryGroup offset={20}>
              <VictoryBar
                barWidth={BAR_WIDTH}
                style={{
                  data: {
                    fill: colors[0]
                  }
                }}
                data={ageData}
                y={data => Math.abs(data.ao)}
              />
              <VictoryBar
                barWidth={BAR_WIDTH}
                style={{
                  data: {
                    fill: colors[1]
                  }
                }}
                data={ageData}
                y={data => Math.abs(data.ad)}
              />
              <VictoryBar
                barWidth={BAR_WIDTH}
                style={{
                  data: {
                    fill: colors[2]
                  }
                }}
                data={ageData}
                y={data => Math.abs(data.ax)}
              />
            </VictoryGroup>
          </VictoryChart>
        </div>
      </div>
    </div>
  );
}
