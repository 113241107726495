import React from "react";

export default function UrlLinkButton({
  color,
  width = 26,
  height = 26
}: {
  color: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM16.1526 6C16.9179 6 17.6297 6.30253 18.1636 6.83641C18.6975 7.37029 18.9822 8.08214 19 8.84736C19 9.61259 18.6975 10.3244 18.1636 10.8583L15.5298 13.4921C15.3874 13.6345 15.2094 13.7057 15.0315 13.7057C14.8535 13.7057 14.6756 13.6345 14.5332 13.4921C14.2485 13.2074 14.2485 12.7625 14.5332 12.4778L17.1492 9.86174C17.4162 9.577 17.5585 9.23888 17.5585 8.84736C17.5585 8.45585 17.4162 8.09993 17.1492 7.83299C16.8645 7.56605 16.5086 7.42368 16.1348 7.42368C15.7611 7.42368 15.4052 7.56605 15.1561 7.83299L12.5222 10.4668C12.2375 10.7515 11.7926 10.7515 11.5079 10.4668C11.2231 10.1821 11.2231 9.73717 11.5079 9.45243L14.1417 6.83641C14.6756 6.30253 15.3874 6 16.1526 6ZM12.4689 14.5421L9.83504 17.1581C9.5681 17.425 9.21218 17.5674 8.82067 17.5674C8.44695 17.5674 8.09103 17.425 7.8063 17.1581C7.25462 16.6064 7.25462 15.6988 7.8063 15.1472L10.4401 12.5133C10.7248 12.2286 10.7248 11.7837 10.4401 11.499C10.1554 11.2142 9.71047 11.2142 9.42574 11.499L6.82752 14.1328C5.72416 15.2361 5.72416 17.0513 6.82752 18.1547C7.3614 18.6886 8.07324 18.9911 8.83847 18.9911C9.6037 18.9911 10.3155 18.6886 10.8494 18.1547L13.4832 15.5209C13.768 15.2361 13.768 14.7912 13.4832 14.5065C13.1985 14.2573 12.7536 14.2573 12.4689 14.5421ZM10.4935 15.2005C10.3155 15.2005 10.1376 15.1294 9.99521 14.987C9.71047 14.72 9.71047 14.2573 9.99521 13.9904L13.9637 9.9863C14.2485 9.70157 14.6934 9.70157 14.9781 9.9863C15.2628 10.271 15.2628 10.7159 14.9781 11.0007L10.9918 14.987C10.8494 15.1294 10.6715 15.2005 10.4935 15.2005Z"
        fill={color}
      />
    </svg>
  );
}
