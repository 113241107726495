export function isBadContents(message: string) {
  return (
    message.includes("병신") ||
    message.includes("좆") ||
    message.includes("개같은") ||
    message.includes("ㅆㅣ") ||
    message.includes("씨발") ||
    message.includes("개새끼")
  );
}
