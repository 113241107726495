import React from "react";

export default function AnswerX({
  color,
  width
}: {
  color: string;
  width: number;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.321987 0H10.9335L30.161 23.3588L49.3886 0H60L35.4667 29.8045L60 59.609L49.7104 60L30.161 36.2502L10.6117 60H0L24.8553 29.8045L0.321987 0Z"
        fill={color}
      />
    </svg>
  );
}
