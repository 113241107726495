/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  DAY,
  DAYS,
  formatKoreanDate,
  toDateOnsetKoreaStandardTime,
  todayInKorea
} from "../../../utils/datetime";
import DateSelectionBar from "../DailyReportsView/DateSelectionBar";
import { css } from "@emotion/react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { getDocsFromSnapshot } from "../../../utils/utils";
import { Checkbox } from "@material-ui/core";
import { ROUTES } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import StatsForCardIdTd from "./StatsForCardIdTd";
import HydratedHashtags from "./HydratedHashtags";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { SurveyQuestionCards } from "./SurveyQuestionCards";

export const CardTypeMap: Record<number, string> = {
  [CardType.TOPIC]: "질문",
  [CardType.TEST_QUESTION]: "테스트 질문",
  [CardType.REAL_NAME_POST]: "말풍산",
  [CardType.SURVEY]: "옥소 여론조사",
  [CardType.USER_SURVEY]: "사용자 여론조사",
  [CardType.SURVEY_QUESTION]: " - 질문",
  [CardType.POST]: "커뮤니티 글",
  [CardType.POST_GROUP]: "커뮤니티 그룹 글",
  [CardType.NEWS_TICKER]: "뉴스 말풍선"
};

const SurveyCards = [CardType.SURVEY, CardType.USER_SURVEY];

export default function CardExplorerView() {
  const history = useHistory();
  const [startDateTime, setStartDateTime] = useState<number>(
    Date.now() - 7 * DAYS
  );
  const [endDateTime, setEndDateTime] = useState<number>(Date.now());

  const [topicCardType, setTopicCardType] = useState(true);
  const [postCardType, setPostCardType] = useState(false);
  const [bubbleCardType, setBubbleCardType] = useState(false);

  const [cards, setCards] = useState<CardSchema[]>();
  useEffect(() => {
    if (!topicCardType && !postCardType && !bubbleCardType) {
      return;
    }

    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [
        ...(topicCardType
          ? [
              CardType.TOPIC,
              CardType.TEST_QUESTION,
              CardType.REAL_NAME_POST,
              CardType.SURVEY
            ]
          : []),
        ...(postCardType
          ? [CardType.POST, CardType.POST_GROUP, CardType.USER_SURVEY]
          : []),
        ...(bubbleCardType ? [CardType.NEWS_TICKER] : [])
      ])
      .where(
        "createdAt",
        "<=",
        toDateOnsetKoreaStandardTime(
          new Date(endDateTime).getFullYear(),
          new Date(endDateTime).getMonth() + 1,
          new Date(endDateTime).getDate()
        ).valueOf() +
          2 * DAY
      )
      .where(
        "createdAt",
        ">",
        toDateOnsetKoreaStandardTime(
          new Date(startDateTime).getFullYear(),
          new Date(startDateTime).getMonth() + 1,
          new Date(startDateTime).getDate()
        ).valueOf()
      )
      .orderBy("createdAt", "desc")
      .get()
      .then(getDocsFromSnapshot)
      .then(setCards);

    return () => {};
  }, [topicCardType, postCardType, bubbleCardType, startDateTime, endDateTime]);

  return (
    <div
      css={css`
        padding: 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            font-size: 14px;
            display: flex;
          `}
        >
          <div>
            <Checkbox
              checked={topicCardType}
              onChange={e => {
                setTopicCardType(e.target.checked);
              }}
            />{" "}
            질문카드
          </div>
          <div>
            <Checkbox
              checked={postCardType}
              onChange={e => {
                setPostCardType(e.target.checked);
              }}
            />{" "}
            커뮤니티 카드
          </div>
          <div>
            <Checkbox
              checked={bubbleCardType}
              onChange={e => {
                setBubbleCardType(e.target.checked);
              }}
            />{" "}
            말풍선
          </div>
        </div>
        <div>
          <DateSelectionBar
            selectedDate={startDateTime}
            setSelectedDate={setStartDateTime}
          />
        </div>
        <div>~</div>
        <div>
          <DateSelectionBar
            selectedDate={endDateTime}
            setSelectedDate={setEndDateTime}
          />
        </div>
      </div>
      <div>
        <div
          css={css`
            margin-top: 10px;
          `}
        >
          Spreadsheet에 복사해서 붙여서 사용하세요~!
        </div>
        <table
          css={css`
            margin-top: 10px;
            font-size: 10px;
            white-space: nowrap;
          `}
        >
          <tr
            css={css`
              font-weight: bold;
            `}
          >
            <td>ID</td>
            <td>종류</td>
            <td>발언자</td>
            <td>제목</td>
            <td>해시태그</td>
            <td>발언일</td>
            <td>O</td>
            <td>?</td>
            <td>X</td>
            <td>응답수</td>
            <td>작성자</td>
            <td>부족</td>
            {/*<td>timestamp</td>*/}
            {/*{["호랑이", "하마", "코끼리", "공룡", "사자"].map(tribeId => {*/}
            {/*  return (*/}
            {/*    <>*/}
            {/*      {["O", "?", "X", "total", "찬성율"].map(answer => {*/}
            {/*        return (*/}
            {/*          <td key={tribeId + answer}>{tribeId + " " + answer}</td>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </>*/}
            {/*  );*/}
            {/*})}*/}
            {/*{["10대", "20대", "30대", "40대", "50대", "60대+"].map(ageGroup => {*/}
            {/*  return ["male", "female"].map(gender => (*/}
            {/*    <>*/}
            {/*      {["O", "?", "X", "total", "찬성율"].map(answer => {*/}
            {/*        return (*/}
            {/*          <td key={ageGroup + gender + answer}>*/}
            {/*            {ageGroup + " " + gender + " " + answer}*/}
            {/*          </td>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </>*/}
            {/*  ));*/}
            {/*})}*/}
          </tr>
          {cards
            ?.filter(
              card =>
                bubbleCardType ||
                card.type !== CardType.REAL_NAME_POST ||
                card.speakerHashTag?.includes("#질문")
            )
            .sort(
              (cardA, cardB) =>
                (cardB.featuredOnNewsTimelineAt || 0) -
                (cardA.featuredOnNewsTimelineAt || 0)
            )
            .map(card => {
              return (
                <>
                  <tr key={card.id}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                      onClick={() => {
                        history.push(ROUTES.CARD_STAT_VIEW + "/" + card.id);
                      }}
                    >
                      {card.id}
                    </td>

                    <td>{CardTypeMap[card.type]}</td>

                    <td
                      css={css`
                        max-width: 100px;
                        overflow: auto;
                      `}
                    >
                      {card.speakerHashTag
                        ?.filter(hashtag => hashtag.length > 0)
                        .join(", ")}
                    </td>

                    <td
                      css={css`
                        max-width: 400px;
                        overflow: auto;
                      `}
                    >
                      {card.title}
                    </td>
                    <td
                      css={css`
                        max-width: 200px;
                        overflow: auto;
                      `}
                    >
                      {card.hashtags && (
                        <HydratedHashtags hashtags={card.hashtags} />
                      )}
                    </td>

                    <td>
                      {formatKoreanDate(
                        todayInKorea(
                          card.featuredOnNewsTimelineAt ||
                            card.publishedAt ||
                            card.createdAt
                        )
                      )}
                    </td>

                    {card.id && <StatsForCardIdTd cardId={card.id} />}
                    {card.createdBy && <td>{card.createdBy}</td>}
                    {card.createdByTribeId && (
                      <td>
                        {TRIBE_INFO_BY_ID[card.createdByTribeId]?.name ||
                          card.createdByTribeId}
                      </td>
                    )}

                    {/*<td>{card.featuredOnNewsTimelineAt}</td>*/}
                    {/*{card.id && <TribeStatsTds cardId={card.id} />}*/}
                    {/*{card.id && <AgeGenderStats cardId={card.id} />}*/}
                  </tr>
                  {SurveyCards.includes(card.type) && (
                    <SurveyQuestionCards cardId={card.id} />
                  )}
                </>
              );
            })}
        </table>
      </div>
    </div>
  );
}
