import firebase from "firebase/compat";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter,
  parseDoc
} from "../../../utils/utils";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { useHistory } from "react-router";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOText from "../../utility/OXOText/OXOText";

export default function MiniTodayTopicCardForToday({
  cardId,
  style
}: {
  cardId: string;
  style?: CSSProperties;
}) {
  const history = useHistory();
  const [card, setCard] = useState<CardSchema | undefined>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => unsubscribe();
  }, [cardId]);

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [windowWidth, windowHeight] = useWindowSize();

  let topicCardDetailScript, btnDetails;

  if (!card || !cardId) {
    if (!cardId) {
      console.log("cardID is missing in TodayTopicCard!");
    }
    return null;
  }

  if (card.topicMetadataV2?.description) {
    [topicCardDetailScript, ...btnDetails] =
      card.topicMetadataV2.description.split("```agree");
  }

  const topicDescription =
    topicCardDetailScript && topicCardDetailScript.split("```")[0];

  return (
    <div
      style={{
        ...style
      }}
    >
      <div
        style={{
          color: "black",
          fontSize: 20,
          fontWeight: "bold",
          lineHeight: 1.5,
          padding: "12px 17px 0"
        }}
      >
        <div
          style={{
            flexGrow: 1,
            minHeight: 20,
            color: "black",
            fontSize: 18,
            fontWeight: "bold",
            lineHeight: 1.7
          }}
          onClick={e => {
            e.stopPropagation();
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, cardId)
            );
          }}
        >
          {card.title && <OXOText text={hashtagTextAdapter(card.title)} />}
        </div>

        <div
          style={{
            fontWeight: "normal",
            cursor: "pointer",
            lineHeight: 1.7,
            fontSize: 16,
            marginTop: 10
          }}
          onClick={e => {
            e.stopPropagation();
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, cardId)
            );
          }}
        >
          {topicDescription && (
            <OXOText text={hashtagTextAdapter(topicDescription)} />
          )}
        </div>
      </div>
      {card?.topicMetadataV2?.photos && (
        <div
          style={{
            flexGrow: 1,
            minHeight: windowWidth >= 750 ? 400 : windowWidth * 0.6,
            display: "flex",
            flexDirection: "column",
            margin: 20,
            borderRadius: 5,
            position: "relative",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(${card?.topicMetadataV2?.photos[0]})`,
            cursor: "pointer"
          }}
          onClick={e => {
            e.stopPropagation();
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, cardId)
            );
          }}
        />
      )}
    </div>
  );
}
