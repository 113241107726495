/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";

import { DIVIDER_COLOR_LIGHT } from "../../../constants/colors";

interface DividerProps {
  /**
   * 선 굵기
   */
  weight?: number;
  /**
   * 위 아래 마진
   */
  margin?: number;
  /**
   * 색
   */
  color?: string;
}

const divider = ({ weight, margin, color }: DividerProps) => {
  return css`
    width: 100%;
    height: ${weight}px;
    margin: ${margin}px 0;
    background-color: ${color};
  `;
};

export default function Divider({
  weight = 1,
  margin = 20,
  color = DIVIDER_COLOR_LIGHT
}: DividerProps) {
  return <div css={{ ...divider({ weight, margin, color }) }} />;
}
