/** @jsxImportSource @emotion/react */

import { Avatar } from "@material-ui/core";
import firebase from "firebase/compat";
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GRAY_HASH_PAGE_BUTTON } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import oxoFace from "../../../../img/ox/oxo_face.png";
import { addToCardIdStackUrl, parseDoc } from "../../../../utils/utils";

export default function HashCommentAvatar({
  speaker,
  size = 30,
  backgroundColor = GRAY_HASH_PAGE_BUTTON
}: {
  speaker: string;
  size?: number;
  backgroundColor?: string;
}) {
  const history = useHistory();
  const [photo, setPhoto] = useState<string>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(speaker)
      .get()
      .then(parseDoc)
      .then((snapshot: CardSchema) => {
        setPhoto(snapshot.hashtagMetadata?.photos?.[0] || "");
      });
  }, [speaker]);

  if (!photo || photo.length === 0) {
    return (
      <Avatar src="/broken-image.jpg" style={{ width: size, height: size }} />
    );
  }

  if (photo === "옥소") {
    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          minWidth: size,
          height: size,
          borderRadius: "50%",
          overflow: "hidden"
        }}
      >
        <img
          src={oxoFace}
          style={{ width: size, height: size }}
          alt="speaker"
        />
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        key={photo}
        style={{
          backgroundColor: backgroundColor,
          width: size,
          height: size,
          borderRadius: "50%",
          overflow: "hidden",
          marginRight: 2,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        onClick={e => {
          if (speaker) {
            e.stopPropagation();
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, speaker)
            );
          }
        }}
      >
        {photo ? (
          <img
            src={photo}
            css={css`
              width: 50px;
              height: 50px;
              filter: gray;
              -webkit-filter: grayscale(100%);
            `}
            alt="speaker"
          />
        ) : (
          <Avatar src="/broken-image.jpg" style={{ width: 18, height: 18 }} />
        )}
      </div>
    </div>
  );
}
