/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";

import firebase from "firebase/compat";

import {
  CardAnswerTribeStatsV2Schema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc, removeIdKey } from "../../../../utils/utils";

import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import getTribeStandupImage from "../../../../utils/image_utils/getTribeStandupImage";

import TribeColumnBarChartContainer from "../../DataExplorerView/TribeColumnBarChartContainer";

export default function CardAnswerTribeV2ColumnBarChart({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerTribeStatsV2Schema>();

  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        if (unsubscriber) unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats || !cardAnswerStats.tribeStats) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 360px;
        margin-top: 57px;
      `}
    >
      {Object.keys(cardAnswerStats.tribeStats).map(tribeId => {
        return (
          <div
            key={tribeId}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: -5px;
            `}
          >
            <div
              css={css`
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                margin-bottom: 20px;
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>

            <div
              key={tribeId}
              css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50px;
              `}
            >
              <TribeColumnBarChartContainer
                stats={cardAnswerStats.tribeStats[tribeId]}
              />

              <div
                css={css`
                  position: absolute;
                  top: -19px;
                  right: -5px;
                `}
              >
                <div>
                  <img
                    key={tribeId}
                    src={getTribeStandupImage(tribeId)}
                    alt="부족 이미지"
                    css={css`
                      width: 50px;
                      height: 50px;
                    `}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
