import React from "react";
import PartyPresidentCard from "../../cards/Cards/PartyPresidentCard";
import { PartiesMetadata } from "../../cards/metadata/parties_metadata";

export default function PoliticsTimeline() {
  const cards = Object.keys(PartiesMetadata).map((questionId, i) => {
    return (
      <div key={questionId} style={{ margin: "8px" }}>
        <PartyPresidentCard cardId={questionId} key={questionId} />
      </div>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        overflow: "auto",
        paddingBottom: "10px",
        justifyContent: "center",
        flexWrap: "wrap"

        // scrollSnapType: "x mandatory"
      }}
    >
      {cards}
    </div>
  );
}
