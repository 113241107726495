import {
  Backdrop,
  createStyles,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteOutlined, MoreVert } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  BLUE_PROFILE,
  DARK_THEME_BACKGROUND,
  GRAY,
  GRAY_MEDIUM,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import {
  CardAnswerSchema,
  ChatSchema,
  CommentSchema,
  FirestoreCollection,
  ReferenceType
} from "../../../constants/firestore_schema";
import { useIsAdmin } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import MiniCommentCard from "../../topic/Question/Comments/MiniCommentCard";
import AddChatAsCommentButton from "../../utility/ReportButton/AddChatAsCommentButton";
import ContentReportButton from "../../utility/ReportButton/ContentReportButton";
import UserBlockButton from "../../utility/ReportButton/UserBlockButton";
import ChatReplyButton from "../../utility/ReportButton/ChatReplyButton";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import { removeFromIndex } from "../../../lib/search";
import { AlgoliaContext } from "../../views/SearchView/AlgoliaContext";

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: 1001,
      color: "black"
    }
  })
);
export default function ChatControls({
  chat,
  setBlocked,
  isDark,
  onDelete,
  onReply,
  disableReply = false
}: {
  chat: ChatSchema;
  setBlocked: Dispatch<SetStateAction<boolean>>;
  isDark?: boolean;
  onDelete?: () => void;
  onReply?: () => void;
  disableReply?: boolean;
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showCommentPreview, setShowCommentPreview] = useState<boolean>();

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + chat.chatRoomId
  );
  const answer = cardAnswer?.answer;
  const isAdmin = useIsAdmin();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const algoliaClient = useContext(AlgoliaContext);

  function deleteChat() {
    if (window.confirm(`"${chat.message}" 메시지를 정말 삭제하시겠어요?`)) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CHATS)
        .doc(chat.id)
        .delete()
        .then(() => {
          if (algoliaClient && chat.id) {
            removeFromIndex(algoliaClient, chat.id, "prod_chat");
          }
        })
        .then(() => onDelete && onDelete());
    }
    setAnchorEl(null);
  }

  function blockChat() {
    if (window.confirm(`"${chat.message}" 메시지 차단을 변경할까요?`)) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CHATS)
        .doc(chat.id)
        .set({ block: !chat.block }, { merge: true });
      setBlocked(prev => !prev);
    }
    setAnchorEl(null);
  }

  return (
    <div style={{ display: "flex" }} onClick={e => e.stopPropagation()}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={e => {
          e.stopPropagation();
          handleClick(e);
        }}
        size={"small"}
      >
        <MoreVert
          style={{
            fontSize: 14,
            color: isDark ? GRAY_MEDIUM : DARK_THEME_BACKGROUND
          }}
        />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch"
          }
        }}
      >
        {onReply && !disableReply && (
          <ChatReplyButton onClose={handleClose} onChatReply={onReply} />
        )}

        {chat.userId === currentUserId && chat.chatRoomId !== "all" && (
          <AddChatAsCommentButton
            onClose={() => {
              setShowCommentPreview(true);
              handleClose();
            }}
          />
        )}

        {chat.id && open && (
          <UserBlockButton reportedUserId={chat.userId} onClose={handleClose} />
        )}

        {chat.id && (
          <ContentReportButton
            setBlind={() => {
              blockChat();
            }}
            reportedUserId={chat.userId}
            reportedUserTribe={chat.tribeId}
            referenceId={chat.id}
            referenceType={ReferenceType.CHAT}
            onClose={handleClose}
          />
        )}

        {(chat.userId === currentUserId || isAdmin) && (
          <MenuItem onClick={() => deleteChat()}>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ marginRight: 5 }}>
                <DeleteOutlined />
              </div>
              <div style={{ paddingBottom: 7 }}>삭제하기</div>
            </div>
          </MenuItem>
        )}
      </Menu>

      {showCommentPreview && (
        <div>
          <Backdrop open={showCommentPreview} className={classes.backdrop}>
            <div>
              <div>
                <MiniCommentCard
                  commentId={"UNDECIDED"}
                  style={{ borderRadius: "20px 20px 0px 0px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderTop: "1px solid",
                  borderTopColor: GRAY
                }}
              >
                <div style={{ flex: 1 }}>
                  <OXOButton
                    label={"댓글 등록"}
                    onClick={() => {
                      setShowCommentPreview(false);
                      const newChat = {
                        userId: chat.userId,
                        questionId: chat.chatRoomId,
                        comment: chat.message,
                        createdAt: chat.createdAt,
                        userTribeId: chat.tribeId,
                        likeUserAndTribes: {}
                      } as CommentSchema;

                      if (answer) {
                        newChat.answer = answer;
                      }

                      firebase
                        .firestore()
                        .collection(FirestoreCollection.COMMENTS)
                        .add(newChat)
                        .then(() => {
                          window.alert("댓글로 추가되었습니다.");
                        })
                        .catch(e => {
                          window.alert("오류가 발생했습니다. " + e);
                        });
                    }}
                    style={{
                      borderRadius: "0 0 0 20px",
                      backgroundColor: TRIBE_INFO_BY_ID[chat.tribeId].bgColor,
                      borderRight: "1px solid",
                      borderRightColor: GRAY,
                      color: BLUE_PROFILE
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <OXOButton
                    label={"취소"}
                    onClick={() => {
                      setShowCommentPreview(false);
                    }}
                    style={{
                      backgroundColor: TRIBE_INFO_BY_ID[chat.tribeId].bgColor,
                      borderRadius: "0 0 20px 0",
                      color: DARK_THEME_BACKGROUND
                    }}
                  />
                </div>
              </div>
            </div>
          </Backdrop>
        </div>
      )}
    </div>
  );
}
