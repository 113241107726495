/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { removeIdKey } from "../../../../utils/utils";
import { css } from "@emotion/react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import getTribeStandupImage from "../../../../utils/image_utils/getTribeStandupImage";
import TribePieChartContainer from "../../DataExplorerView/TribePieChartContainer";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

export default function CardAnswerTribeV1Chart({ cardId }: { cardId: string }) {
  const cardAnswerTribeStats = removeIdKey(
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    )
  );

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <div
      style={{ display: "flex", justifyContent: "space-around", width: "100%" }}
    >
      {Object.keys(cardAnswerTribeStats).map(tribeId => {
        return (
          <div
            key={tribeId}
            style={{
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <TribePieChartContainer stats={cardAnswerTribeStats[tribeId]} />

            <div style={{ position: "absolute", top: 32, right: 8 }}>
              <div>
                <img
                  key={tribeId}
                  src={getTribeStandupImage(tribeId)}
                  alt="부족 이미지"
                  css={css`
                    width: 25px;
                    height: 25px;
                  `}
                />
              </div>
            </div>
            <div
              css={css`
                margin-top: 10px;
                margin-left: 5px;
                font-size: 16px;
                text-align: center;
                font-weight: 700;
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>
          </div>
        );
      })}
    </div>
  );
}
