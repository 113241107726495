/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import firebase from "firebase/compat";
import React, { useState } from "react";
import { DATA_PLATFORM_BACKGROUND_COLOR } from "../../../../constants/data_platform_colors";

declare global {
  interface Window {
    recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    confirmationResult: firebase.auth.ConfirmationResult;
  }
}

export function MobileAuth() {
  const [inputNumber, setInputNumber] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  async function sendSmsCode(phoneNumber: string, countryCode = "+82") {
    let success = false;

    const container = document.createElement("div");
    const body = document.querySelector("body");
    container.id = `recaptcha-container-${Math.random() * 255}`.replace(
      ".",
      "-"
    );
    container.style.display = "none";
    body?.appendChild(container);
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      container.id,
      {
        size: "invisible",
        callback: () => {
          console.log("recaptchaVerifier");
          success = true;
        }
      }
    );

    const id = await recaptchaVerifier.render();
    // @ts-ignore
    grecaptcha.execute(id);

    while (!success) {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    try {
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(
          `${countryCode}${phoneNumber}`,
          recaptchaVerifier
        );
      console.log(`${countryCode}${phoneNumber}`);
      console.log("confirm", confirmationResult);
      window.confirmationResult = confirmationResult;
    } catch (err) {
      console.log(err);
    } finally {
      const containerElement = document.querySelector(`#${container.id}`);
      containerElement && body?.removeChild(containerElement);
    }
  }

  async function verifySmsCode({
    confirmationResult,
    smsCode
  }: {
    confirmationResult: firebase.auth.ConfirmationResult;
    smsCode: string;
  }) {
    try {
      const result = await confirmationResult.confirm(smsCode);
      const token = await result.user?.getIdToken();
      console.log("login", token);
      setIsLogin(true);
      return token;
    } catch (err) {
      console.log(err);
    }
  }

  function signOut() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsLogin(false);
        console.log("signout");
      })
      .catch(err => {
        console.log(err);
      });
  }

  const phoneNumberInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputNumber(e.target.value);
  };

  const codeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSmsCode(e.target.value);
  };

  const onNumberSubmit = () => {
    sendSmsCode(inputNumber);
  };

  const confirmationResult = window.confirmationResult;

  const onCodeSubmit = () => {
    verifySmsCode({
      confirmationResult,
      smsCode
    });
  };

  return (
    <div
      css={css`
        margin-left: 220px;
        background-color: ${DATA_PLATFORM_BACKGROUND_COLOR};
        height: 1600px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      `}
    >
      <div
        css={css`
          margin-top: 30px;
          font-size: 20px;
          font-weight: 700;
        `}
      >
        핸드폰 인증
      </div>
      <div
        css={css`
          margin-top: 50px;
          height: 30px;
          width: 300px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <input
          css={css`
            width: 180px;
            height: 26px;
            border: 1px solid #b5b5b5;
            padding-left: 10px;
          `}
          placeholder={"전화번호"}
          onChange={e => phoneNumberInputHandler(e)}
        ></input>
        <div
          css={css`
            width: 90px;
            height: 30px;
            background-color: #d6d6d6;
            cursor: pointer;
            line-height: 30px;
            font-size: 14px;
          `}
          onClick={() => onNumberSubmit()}
        >
          문자 보내기
        </div>
      </div>
      <div
        css={css`
          margin-top: 30px;
          height: 30px;
          width: 300px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <input
          css={css`
            width: 180px;
            height: 26px;
            border: 1px solid #b5b5b5;
            padding-left: 10px;
          `}
          placeholder={"인증코드"}
          onChange={e => codeInputHandler(e)}
        ></input>
        <div
          css={css`
            width: 90px;
            height: 30px;
            background-color: #d6d6d6;
            cursor: pointer;
            line-height: 30px;
            font-size: 14px;
          `}
          onClick={() => onCodeSubmit()}
        >
          확인
        </div>
      </div>
      {isLogin ? (
        <div
          css={css`
            margin-top: 30px;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          인증되었습니다
        </div>
      ) : (
        <div
          css={css`
            margin-top: 30px;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          미인증 상태입니다
        </div>
      )}
    </div>
  );
}
