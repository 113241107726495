import oxo_standup from "../../../src/img/tribe_standup/oxo_standup.png";
import tiger_standup from "../../../src/img/tribe_standup/tiger_standup.png";
import hippo_standup from "../../../src/img/tribe_standup/hippo_standup.png";
import elephant_standup from "../../../src/img/tribe_standup/elephant_standup.png";
import dino_standup from "../../../src/img/tribe_standup/dino_standup.png";
import lion_standup from "../../../src/img/tribe_standup/lion_standup.png";

const getTribeStandupImage = (tribeImageID: string) => {
  switch (tribeImageID) {
    case "1":
      return tiger_standup;
    case "2":
      return hippo_standup;
    case "3":
      return elephant_standup;
    case "4":
      return dino_standup;
    case "5":
      return lion_standup;
    default:
      return oxo_standup;
  }
};

export default getTribeStandupImage;
