/** @jsxImportSource @emotion/react */

import React from "react";
import { AnswerCountsSchema } from "../../../../constants/firestore_schema";
import { GRAY } from "../../../../constants/enums";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack
} from "victory";
import { css } from "@emotion/react";

export default function CardAnswerBarChartForAnswerCounts({
  answerCounts
}: {
  answerCounts: AnswerCountsSchema;
}) {
  const colors = [
    DATA_PLATFORM_GREEN_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_RED_GRAY
  ];
  const cardData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [
    {
      x: "전체",
      ao: ((answerCounts?.o || 0) / (answerCounts?.count || 1)) * 100,
      ad: ((answerCounts?.["?"] || 0) / (answerCounts?.count || 1)) * 100,
      ax: ((answerCounts?.x || 0) / (answerCounts?.count || 1)) * 100,
      count: answerCounts?.count || 0
    }
  ];

  if (!answerCounts || answerCounts.count === 0) {
    return null;
  }

  return (
    <div>
      <div css={css``}>
        <div>
          <div
            css={css`
              width: 330px;
              height: 20px;
              text-align: center;
              margin-bottom: 5px;
              line-height: 10px;
            `}
          >
            <VictoryChart horizontal height={20} width={300} padding={0}>
              <VictoryStack
                domain={{ y: [0, 100] }}
                style={{
                  data: { width: 20 },
                  labels: { fontSize: 15, fill: GRAY }
                }}
              >
                <VictoryBar
                  style={{
                    data: {
                      fill: colors[0]
                    }
                  }}
                  data={cardData}
                  y={data => Math.abs(data.ao)}
                />
                <VictoryBar
                  style={{
                    data: {
                      fill: colors[1]
                    }
                  }}
                  data={cardData}
                  y={data => Math.abs(data.ad)}
                />
                <VictoryBar
                  style={{
                    data: {
                      fill: colors[2]
                    }
                  }}
                  data={cardData}
                  y={data => Math.abs(data.ax)}
                />
              </VictoryStack>

              <VictoryAxis
                style={{
                  axis: { stroke: "transparent" },
                  ticks: { stroke: "transparent" },
                  tickLabels: { fill: "transparent" }
                }}
                tickLabelComponent={<VictoryLabel x={0} textAnchor="start" />}
                tickValues={cardData.map(point => point.x).reverse()}
                key={"axis1"}
              />
            </VictoryChart>
          </div>
        </div>
      </div>
    </div>
  );
}
