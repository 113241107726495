/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import {
  CardAnswerStatsSchema,
  CardSchema
} from "../../../constants/firestore_schema";
import {
  mapValues,
  multiGroupBy,
  onlyUnique,
  sumTwoAnswerCounts
} from "../../../utils/utils";
import { HashtagPersonBarChart } from "../Today/HashtagPersonBarChart";
import { DATA_PLATFORM_RED_GRAY } from "../../../constants/data_platform_colors";

export default function DailyReportHashtagIssueCard({
  hashtag,
  hashtagCards,
  cardAnswerStatsMap,
  index
}: {
  hashtag: string;
  hashtagCards: CardSchema[];
  cardAnswerStatsMap: { [cardId: string]: CardAnswerStatsSchema };
  index: number;
}) {
  const cardsBySpeaker = multiGroupBy(
    hashtagCards,
    card =>
      card.speakerHashTag
        ?.filter(t => t.length > 0)
        .map(t => {
          return t.replace("#", "");
        }) || []
  );

  const speakerPopularity = mapValues(cardsBySpeaker, cards =>
    cards.map(card => cardAnswerStatsMap[card.id]).reduce(sumTwoAnswerCounts)
  );

  const sortedHashtags = Object.keys(speakerPopularity)
    .filter(hashtag => hashtag.length < 5)
    .sort(
      (hashtagA, hashtagB) =>
        speakerPopularity[hashtagB].o / speakerPopularity[hashtagB].count -
        speakerPopularity[hashtagA].o / speakerPopularity[hashtagA].count
    );

  const slicedHashtags = [
    ...sortedHashtags.slice(0, 3),
    ...sortedHashtags.slice(sortedHashtags.length - 3, sortedHashtags.length)
  ].filter(onlyUnique);

  return (
    <div
      key={hashtag}
      css={css`
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <div
        css={css`
          font-size: 20px;
          font-weight: 700;
          margin-top: 10px;
          margin-left: 30px;
        `}
      >
        {index + 1}. #{hashtag.replace(/_/g, " ")}
      </div>

      <div
        css={css`
          margin-top: 20px;
          margin-left: 94px;
          width: 300px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            color: #bebebe;
          `}
        >
          찬성
        </div>
        <div
          css={css`
            color: ${DATA_PLATFORM_RED_GRAY};
          `}
        >
          반대
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 240px;
          `}
        >
          {slicedHashtags.map(hashtag => (
            <HashtagPersonBarChart
              key={hashtag}
              hashtag={hashtag}
              speakerPopularity={speakerPopularity[hashtag]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
