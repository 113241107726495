import React from "react";
import CharacterLoader from "../../utility/OXOLoader/CharacterLoader";
import UserPostForProfile from "./UserPostForProfile";
import UserReplyForProfile from "./UserReplyForProfile";
import UserPoliticsForProfile from "./UserPoliticsForProfile";

export default function UserProfileRenderer({
  tab,
  profileUserId
}: {
  tab: string;
  profileUserId: string;
}) {
  switch (tab) {
    case "정치성향":
      return <UserPoliticsForProfile profileUserId={profileUserId} />;
    case "댓글":
      return <UserReplyForProfile profileUserId={profileUserId} />;
    case "생각":
      return <UserPostForProfile profileUserId={profileUserId} />;
    default:
      return <CharacterLoader />;
  }
}
