import React from "react";
import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../constants/firestore_schema";

export default function ChatInput({
  userMetadata,
  userId,
  currentChatroomId,
  title,
  photoUrl,
  isDark,
  authorTribeId,
  collectionName = FirestoreCollection.CHATROOMS,
  placeholder,
  parentId = "main",
  onBlur,
  hideHead = false
}: {
  userMetadata: UserProfileMetadataSchema;
  userId: string;
  currentChatroomId: string;
  title?: string;
  photoUrl?: string;
  isDark?: boolean;
  authorTribeId?: string;
  collectionName?: string;
  placeholder?: string;
  parentId?: string;
  onBlur?: () => void;
  hideHead?: boolean;
}) {
  return null;
}
