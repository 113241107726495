/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";

export default function PartySelect({
  selectedHashtag,
  setSelectedHashtag
}: {
  selectedHashtag: string;
  setSelectedHashtag: (hashtag: string) => void;
}) {
  const parties = [
    "더불어민주당",
    "국민의힘",
    "국민의당",
    "정의당",
    "열린민주당"
  ];

  return (
    <div>
      <select
        value={selectedHashtag}
        onChange={e => {
          setSelectedHashtag(e.target.value);
        }}
        css={css`
          width: 190px;
          height: 44px;
          left: 373px;
          top: 756px;
          padding-left: 10px;
          padding-right: 10px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 10px;
        `}
      >
        {parties.map(party => (
          <option value={party} key={party}>
            {party}
          </option>
        ))}
      </select>
    </div>
  );
}
