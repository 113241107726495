import {
  FirestoreCollection,
  NotificationScheduleSchema,
  UserDeviceTokenSchema
} from "../constants/firestore_schema";
import { koreanDate } from "../utils/koreanDate";
import firebase from "firebase/compat";
import { getDocsFromSnapshot } from "../utils/utils";

export const deleteReservedNotification = (
  id: string,
  displaySnackbar: (message: string) => void
) => {
  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATION_SCHEDULE)
    .doc(id)
    .delete()
    .then(() => {
      displaySnackbar("삭제되었습니다");
    });
};

export const sendPushByCondition = (
  title: string,
  body: string,
  condition: string,
  displaySnackbar: (message: string) => void
) => {
  if (!title) {
    displaySnackbar("제목을 입력해 주세요");
    return;
  }

  if (!body) {
    displaySnackbar("내용을 입력해 주세요");
    return;
  }

  const newData: NotificationScheduleSchema = {
    title: title,
    body: body,
    sendAt: koreanDate().getTime(),
    sendComplete: true,
    condition: condition
  };

  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATION_SCHEDULE)
    .add(newData);

  const sendNotification = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("sendNotificationToCondition");

  sendNotification({ title: title, body: body, condition: condition })
    .then(() => {
      displaySnackbar("노티피케이션이 발송되었습니다");
    })
    .catch(() => {
      displaySnackbar("노티피케이션이 발송을 실패했습니다.");
    });
};

export const sendPushToUser = (
  title: string,
  body: string,
  recipientUserId: string,
  displaySnackbar: (message: string) => void
) => {
  if (!title) {
    displaySnackbar("제목을 입력해 주세요");
    return;
  }

  if (!body) {
    displaySnackbar("내용을 입력해 주세요");
    return;
  }

  const newData: NotificationScheduleSchema = {
    title: title,
    body: body,
    sendAt: new Date().valueOf(),
    sendComplete: true,
    recipientUserId: recipientUserId
  };

  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATION_SCHEDULE)
    .add(newData);

  firebase
    .firestore()
    .collection(FirestoreCollection.USER_DEVICE_TOKENS)
    .where("userId", "==", recipientUserId)
    .get()
    .then(getDocsFromSnapshot)
    .then((tokens: UserDeviceTokenSchema[]) => {
      if (tokens.length === 0) {
        return;
      }

      const data = {
        title: title,
        body: body,
        tokens: tokens.map(token => token.token)
      };

      const sendNotification = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("sendNotificationToTokens");

      sendNotification(data).catch(e => {
        console.log(e);
      });
    });
};

export function subscribeToTopic(tokens: string[], topic: string) {
  console.log("subscribeToTopic", tokens, encodeURI(topic));
  firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("subscribeToTopic")({
      tokens: tokens,
      topic: encodeURI(topic)
    })
    .catch(e => {
      console.log(e);
    });
}

export function unsubscribeFromTopic(tokens: string[], topic: string) {
  firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("unsubscribeFromTopic")({
      tokens: tokens,
      topic: encodeURI(topic)
    })
    .catch(e => {
      console.log(e);
    });
}

export const reservePush = (
  title: string,
  body: string,
  target: string,
  selectedDateTime: number,
  displaySnackbar: (message: string) => void
) => {
  if (!title) {
    displaySnackbar("제목을 입력해 주세요");
    return;
  }

  if (!body) {
    displaySnackbar("내용을 입력해 주세요");
    return;
  }

  const newData: NotificationScheduleSchema = {
    title: title,
    body: body,
    sendAt: new Date(selectedDateTime).getTime(),
    sendComplete: false,
    condition: target
  };

  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATION_SCHEDULE)
    .add(newData);

  displaySnackbar("노티피케이션이 예약되었습니다");
};
