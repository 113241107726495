import React from "react";
import { BACKGROUND_COLOR } from "../../../constants/enums";
import NewBadge from "./NewBadge";

export default function CardTag({
  title,
  darkmode = false,
  newBadge = false
}: {
  title: string;
  darkmode?: boolean;
  newBadge?: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2px 15px",
        backgroundColor: darkmode ? "white" : BACKGROUND_COLOR,
        borderRadius: "20px",
        position: "relative"
      }}
    >
      {newBadge && <NewBadge />}
      {title}
    </div>
  );
}
