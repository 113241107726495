import React, { useContext } from "react";
import { GRAY_DARK } from "../../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { useIsAdmin } from "../../../../utils/utils";
import KoreanDate from "../../../cards/CardComponents/KoreanDate";
import HashCommentAvatar from "../Comment/HashCommentAvatar";
import HashCommentName from "../Comment/HashCommentName";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import UserHead from "../../../cards/MiniCards/UserHead";
import UserName from "../../../profile/OXOUserProfile/UserName";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import SpeakerPosition from "../Comment/SpeakerPosition";
import SupporterPercentage from "../Comment/SupporterPercentage";
import { CardDisplayStatus } from "../../../topic/CardDisplayStatus";
import MiddleDot from "../../../foundation/common/MiddleDot";

export default function CommentSpeaker({
  cardId,
  color = GRAY_DARK,
  nameColor = "black"
}: {
  cardId: string;
  color?: string;
  nameColor?: string;
}) {
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const isAdmin = useIsAdmin();

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  if (!card || !cardAnswer) {
    return null;
  }

  const speakers: string[] =
    card.type === CardType.TOPIC
      ? ["옥소"]
      : card.speakerHashTag
          ?.filter(speaker => speaker.length)
          .map(speaker => speaker.replace("#", "")) || ["방랑자"];

  const publishedAt: string[] | undefined = card.publishedAt
    ? new Date(card.publishedAt).toLocaleString().split(" ")
    : undefined;

  let year, month, day, time;

  if (publishedAt) {
    [year, month, day, ...time] = publishedAt;
  }

  if (card.type === CardType.NEWS_TICKER && !card.displayToUsers && !isAdmin) {
    return null;
  }

  return (
    <div
      style={{
        fontWeight: "bolder",
        display: "flex",
        alignItems: "center",
        height: 40,
        marginBottom: 5
      }}
    >
      {card.type === CardType.POST ? (
        <div style={{ paddingRight: 10 }}>
          <UserHead
            userId={card.createdBy}
            expression={card.postMetadata?.expression}
          />
        </div>
      ) : (
        speakers && (
          <div
            style={{
              marginRight: 10,
              display: "flex"
            }}
          >
            {speakers.map((speaker, i) => (
              <div style={{ paddingRight: 0 }} key={i}>
                <HashCommentAvatar
                  speaker={speaker}
                  size={44}
                  backgroundColor={"white"}
                />
              </div>
            ))}
          </div>
        )
      )}
      <div>
        {card.type === CardType.POST ? (
          <div>
            <UserName
              profileUserId={card.createdBy}
              style={{ marginRight: 5, color: nameColor }}
            />
            <div
              style={{
                display: "flex",
                paddingTop: 2,
                fontWeight: "normal",
                fontSize: 12,
                color: color
              }}
            >
              <KoreanDate timestamp={card.publishedAt || card.createdAt} />
            </div>
          </div>
        ) : (
          speakers && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {speakers.map((speaker, i, speakers) => (
                  <div style={{ display: "flex" }} key={i}>
                    <HashCommentName
                      speaker={speaker}
                      style={{ fontSize: 16, color: nameColor }}
                    />
                    {speakers.length > 1 && i !== speakers.length - 1 && (
                      <MiddleDot />
                    )}
                  </div>
                ))}
                <CardDisplayStatus card={card} hideOnDisplay />
              </div>

              <div
                style={{
                  display: "flex",
                  paddingTop: 2,
                  fontWeight: "normal",
                  fontSize: 12,
                  color: color,
                  flexWrap: "wrap",
                  alignItems: "center"
                }}
              >
                {speakers.length === 1 && (
                  <div style={{ display: "flex" }}>
                    <SupporterPercentage
                      cardId={speakers[0]}
                      style={{ color: color }}
                    />{" "}
                    <MiddleDot />
                  </div>
                )}

                {speakers.length === 1 && (
                  <div style={{ display: "flex" }}>
                    <SpeakerPosition
                      style={{ color: color }}
                      speaker={speakers[0]}
                      addMiddleDotIfPresent
                    />
                  </div>
                )}

                <KoreanDate timestamp={card.publishedAt || card.createdAt} />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
