/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getAgeGenderStatsByAgeGroup } from "../../../utils/ageGender";
import { getStatTds } from "./shared";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";

export default function AgeGenderStats({ cardId }: { cardId: string }) {
  const ageGenderStats =
    useFirestoreGetDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  if (!ageGenderStats) {
    return null;
  }

  const ageGenderStatsByAgeGroup = getAgeGenderStatsByAgeGroup(ageGenderStats);

  return (
    <>
      {["10", "20", "30", "40", "50", "60"].map(ageGroup => {
        return ["male", "female"].map(gender =>
          getStatTds(
            ageGenderStatsByAgeGroup?.[ageGroup]?.[gender],
            ageGroup + gender + cardId
          )
        );
      })}
    </>
  );
}
