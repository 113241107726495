import styled from "@emotion/styled";

export const MiddleCardBox = styled.div`
  width: 470px;
  height: 376px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
`;
