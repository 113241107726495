import firebase from "firebase/compat";
import React, { useContext, useEffect, useMemo } from "react";
import {
  GRAY_HASH_PAGE_BUTTON,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import {
  FirestoreCollection,
  NotificationType,
  UserNotificationItemSchema,
  UserNotificationsSchema
} from "../../../constants/firestore_schema";
import { getMapFromSnapshots } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import CommentLikeNotification from "./CommentLikeNotification";
import PostAnswerNotification from "./PostAnswerNotification";
import PostCommentNotification from "./PostCommentNotification";
import PostLikeNotification from "./PostLikeNotification";
import QuestionNotification from "./QuestionNotification";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import TitleAndUserProfile from "../../foundation/TitleAndUserProfile/TitleAndUserProfile";
import PullToRefresh from "react-simple-pull-to-refresh";

const QUESTIONS_PER_PAGE = 20;

export default function NotificationTimeline() {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!currentUserId) {
    // 앱에서 DeepLink 로 접근하는 경우에 대비해 history.push 대신 location.href 를 사용한다
    // 해당 Device의 Subscribe 세팅을 Reset
    window.location.href = "/";
  }

  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [page, setPage] = React.useState(0);
  const [userNotifications, setUserNotifications] =
    React.useState<UserNotificationsSchema>();

  const unseenNotificationIds = useMemo(() => new Set(), []);

  useEffect(() => {
    if (currentUserId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.NOTIFICATIONS)
        .where("userId", "==", currentUserId)
        .orderBy("createdAt", "desc")
        .orderBy("seen")
        .limit((page + 1) * QUESTIONS_PER_PAGE)
        .onSnapshot(snapshot => {
          setUserNotifications(getMapFromSnapshots(snapshot));
        });
    }
  }, [currentUserId, page]);

  const notifications = (
    <div>
      {userNotifications &&
        Object.keys(userNotifications)
          .slice(0, QUESTIONS_PER_PAGE * page)
          .map(notificationId => {
            const userNotification = userNotifications[notificationId];
            if (!userNotification.seen) {
              unseenNotificationIds.add(notificationId);

              firebase
                .firestore()
                .collection(FirestoreCollection.NOTIFICATIONS)
                .doc(notificationId)
                .set({ seen: true }, { merge: true });
            }
            return (
              <div
                style={{
                  backgroundColor: unseenNotificationIds.has(notificationId)
                    ? currentUserMetadata && currentUserMetadata.tribeId
                      ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].bgColor
                      : GRAY_HASH_PAGE_BUTTON
                    : "white"
                }}
                key={notificationId}
              >
                {getNotificationItem(userNotification)}
              </div>
            );
          })}
      {userNotifications && Object.entries(userNotifications).length === 0 && (
        <div
          style={{
            textAlign: "center",
            padding: "20px"
          }}
        >
          아직 알림이 없습니다. 생각이나 댓글을 써 보세요.
        </div>
      )}
    </div>
  );

  if (!userNotifications) {
    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          minHeight: "100vh"
        }}
      >
        <DocumentSkeleton />
      </div>
    );
  }

  return (
    <PullToRefresh
      pullingContent={<div></div>}
      onRefresh={() => {
        return new Promise(() => {
          window.location.reload();
          //window.parent.postMessage("MyApp", "{'action':'reload'}"); // For Android & iOS
        });
      }}
    >
      <div>
        <TitleAndUserProfile title={"알림"} desc={"공감과 댓글"} />

        <div
          style={{
            backgroundColor: "white",
            padding: "20px 0 0",
            minHeight: "100vh"
          }}
        >
          <div style={{ textAlign: "left" }}>
            {notifications}
            <OXOInfiniteScroll setNextPage={() => setPage(prev => prev + 1)} />
          </div>
        </div>
      </div>
    </PullToRefresh>
  );
}

function getNotificationItem(userNotification: UserNotificationItemSchema) {
  switch (userNotification.type) {
    case NotificationType.COMMENT_LIKE:
      return <CommentLikeNotification userNotification={userNotification} />;
    case NotificationType.POST_LIKE:
      return <PostLikeNotification userNotification={userNotification} />;
    case NotificationType.POST_ANSWER:
      return <PostAnswerNotification userNotification={userNotification} />;
    case NotificationType.POST_COMMENT:
      return <PostCommentNotification userNotification={userNotification} />;
    case NotificationType.NEW_QUESTION:
      return <QuestionNotification userNotification={userNotification} />;
  }
  return null;
}
