/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { formatKoreanDate, todayInKorea } from "../../../utils/datetime";
import { getDocsFromSnapshot } from "../../../utils/utils";
import { ROUTES } from "../../../constants/routes";
import { css } from "@emotion/react";
import HydratedHashtags from "./HydratedHashtags";
import StatsForCardIdTd from "./StatsForCardIdTd";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { useHistory } from "react-router-dom";
import { CardTypeMap } from "./CardExplorerView";

export function SurveyQuestionCards({ cardId }: { cardId: string }) {
  const history = useHistory();

  const [cards, setCards] = useState<CardSchema[]>();

  console.log("SURVEY CARDS!!");

  useEffect(() => {
    if (!cardId) {
      return;
    }

    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("parentCardId", "==", cardId)
      .where("type", "==", CardType.SURVEY_QUESTION)
      .get()
      .then(getDocsFromSnapshot)
      .then(setCards);

    return () => {};
  }, [cardId]);
  if (!cards) {
    return null;
  }
  return (
    <>
      {cards

        .sort(
          (cardA, cardB) =>
            (cardB.featuredOnNewsTimelineAt || 0) -
            (cardA.featuredOnNewsTimelineAt || 0)
        )
        .map(card => {
          return (
            <tr key={card.id}>
              <td
                style={{
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
                onClick={() => {
                  history.push(ROUTES.CARD_STAT_VIEW + "/" + card.id);
                }}
              >
                {card.id}
              </td>

              <td>{CardTypeMap[card.type]}</td>

              <td
                css={css`
                  max-width: 100px;
                  overflow: auto;
                `}
              >
                {card.speakerHashTag
                  ?.filter(hashtag => hashtag.length > 0)
                  .join(", ")}
              </td>

              <td
                css={css`
                  max-width: 400px;
                  overflow: auto;
                `}
              >
                {card.title}
              </td>
              <td
                css={css`
                  max-width: 200px;
                  overflow: auto;
                `}
              >
                {card.hashtags && <HydratedHashtags hashtags={card.hashtags} />}
              </td>

              <td>
                {formatKoreanDate(
                  todayInKorea(
                    card.featuredOnNewsTimelineAt ||
                      card.publishedAt ||
                      card.createdAt
                  )
                )}
              </td>

              {card.id && <StatsForCardIdTd cardId={card.id} />}
              {card.createdBy && <td>{card.createdBy}</td>}
              {card.createdByTribeId && (
                <td>
                  {TRIBE_INFO_BY_ID[card.createdByTribeId]?.name ||
                    card.createdByTribeId}
                </td>
              )}

              {/*<td>{card.featuredOnNewsTimelineAt}</td>*/}
              {/*{card.id && <TribeStatsTds cardId={card.id} />}*/}
              {/*{card.id && <AgeGenderStats cardId={card.id} />}*/}
            </tr>
          );
        })}
    </>
  );
}
