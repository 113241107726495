import { PRIMARY_COLOR } from "../../../constants/enums";
import CardMetadata from "../CardComponents/CardMetadata";

export const PartiesMetadata: CardMetadata = {
  "00f094b0-5f40-40ed-8228-20c519b4d178": {
    name: "대통령",
    color: PRIMARY_COLOR
  },
  qfkKkRLYYfw2ftwFkUje: {
    name: "더불어민주당",
    color: "#004EA2"
  },
  "bab69e48-d0f7-4abc-9e0c-cd1605f2d929": {
    name: "국민의힘",
    color: "#EF426F"
  },
  // Bv2VqjlxjHPtiJYfy3tR: {
  //   name: "미래통합당",
  //   color: "#EF426F"
  // },
  "ccad3b0c-0469-407c-89ac-76001ec685c3": {
    name: "국민의당",
    color: "#EA5503"
  },
  "788da44f-d265-4c09-8727-51c6ce7592fd": {
    name: "정의당",
    color: "#FFCC01"
  },
  "253be712-0b5f-484f-9467-6d78a2e16864": {
    name: "열린민주당",
    color: "#003e9b"
  },
  "78d198a3-6ed9-4bdd-b481-524dcc70ae9b": {
    name: "기본소득당",
    color: "#fe8871"
  },
  "bc47b3f3-38a0-41ec-b654-b23a38e6df30": {
    name: "시대전환",
    color: "#5a147e"
  }
};
