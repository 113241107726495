import firebase from "firebase/compat";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { ROUTES } from "../../../constants/routes";
import oxoFace from "../../../img/ox/new_oxo_face.svg";
import { removeFromIndex } from "../../../lib/search";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { AlgoliaContext } from "../../views/SearchView/AlgoliaContext";

export default function MiniOXOCard({ cardId }: { cardId: string }) {
  const [card, setCard] = useState<CardSchema>();

  const history = useHistory();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));

    return () => unsubscribe();
  }, [cardId]);

  const cardRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const dataProps = useContext(DataPropsContext);
  const algoliaClient = useContext(AlgoliaContext);

  const trashClicked = () => {
    if (card && window.confirm(`"${card.title}" 생각을 삭제하시겠습니까?`)) {
      if (algoliaClient) {
        removeFromIndex(algoliaClient, card.id);
      }
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(card.id)
        .delete();
      history.push(ROUTES.HOME);
    }
  };

  if (!card || !dataProps?.mapCoordinates) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "750px"
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "70px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.10)"
        }}
        key={cardId}
        ref={cardRef}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 40,
              height: 40,
              borderRadius: "50%",
              padding: 5,
              marginRight: 5,
              cursor: "pointer"
            }}
          >
            <img src={oxoFace} alt="oxoFaceIcon" style={{ width: 30 }} />
          </div>
          <div
            style={{
              wordBreak: "keep-all",
              textAlign: "left",
              cursor: "pointer",
              width: "100%",
              fontSize: 14,
              fontWeight: "bold"
            }}
            onClick={() => {
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(history.location.search, cardId)
              );
            }}
          >
            {card.title}
          </div>
        </div>
      </div>
    </div>
  );
}
