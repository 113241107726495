import firebase from "firebase/compat";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GRAY, TRIBE_INFO_BY_ID } from "../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import oxoPortrait from "./oxoPortrait.png";

export default function OxoNotice({ cardId }: { cardId: string }) {
  const [card, setCard] = useState<CardSchema>();

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const history = useHistory();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));

    return () => unsubscribe();
  }, [cardId]);

  const cardRef = useRef<HTMLDivElement>(null);

  if (!card || !dataProps?.mapCoordinates) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor:
          currentUserMetadata && currentUserMetadata.tribeId
            ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
            : GRAY,
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 25,
        borderRadius: 8,
        height: 70,
        width: "100%",
        padding: 20
      }}
      onClick={() => {
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, cardId)
        );
      }}
      key={cardId}
      ref={cardRef}
    >
      <div
        style={{
          color: "white",
          wordBreak: "keep-all",
          textAlign: "left",
          fontSize: 16,
          fontWeight: "bold",
          padding: "10px 0 10px 10px"
        }}
      >
        <div
          style={{
            wordBreak: "keep-all",
            textAlign: "left",
            maxWidth: 120
          }}
        >
          {card.title}
        </div>
      </div>
      <div
        style={{
          verticalAlign: "bottom",
          marginBottom: -20
        }}
      >
        <img src={oxoPortrait} style={{ width: 68 }} alt="oxoPortrait" />
      </div>
    </div>
  );
}
