import React from "react";

export default function MiniX({
  fill = "#FD8A87",
  width = 19,
  height = 19
}: {
  fill?: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0L5.1875 3.875L1.9375 0H0.0625003L4.3125 4.9375L0 10H1.875L5.1875 6.0625L8.5625 10H10.375L6.125 4.9375L10.375 0H8.5Z"
        fill={fill}
      />
    </svg>
  );
}
