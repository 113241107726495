import { Dialog, DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GRAY_DARKER } from "../../../constants/enums";
import {
  CardAnswerSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import OXOPill from "../../utility/OXOPill/OXOPill";
import ProfileAnswerDiffQuestions from "./ProfileAnswerDiffQuestions";

import { getDocsFromSnapshot, listToMap } from "../../../utils/utils";
import firebase from "firebase/compat";

export default function ProfileAnswerDiff({
  currentUserId,
  profileUserId
}: {
  currentUserId: string;
  profileUserId: string;
}) {
  let sameAnswerRatio;

  const [currentUserVector, setCurrentUserVector] = useState<{
    [cardId: string]: string;
  }>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .where("userId", "==", currentUserId)
      .get()
      .then(getDocsFromSnapshot)
      .then((currentUserAnswers: CardAnswerSchema[]) => {
        setCurrentUserVector(
          listToMap(
            currentUserAnswers,
            item => item.cardId,
            item => item.answer
          )
        );
      });
    return () => {};
  }, [currentUserId]);

  const [profileUserVector, setProfileUserVector] = useState<{
    [cardId: string]: string;
  }>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .where("userId", "==", profileUserId)
      .get()
      .then(getDocsFromSnapshot)
      .then((profileUserAnswers: CardAnswerSchema[]) => {
        setProfileUserVector(
          listToMap(
            profileUserAnswers,
            item => item.cardId,
            item => item.answer
          )
        );
      });
    return () => {};
  }, [profileUserId]);

  if (currentUserVector && profileUserVector) {
    const commonAnswerCardIds = Object.keys(profileUserVector).filter(x =>
      Object.keys(currentUserVector).includes(x)
    );

    const sameAnswers = commonAnswerCardIds.reduce<number>(
      (result, currentCardId) => {
        if (
          profileUserVector[currentCardId] === currentUserVector[currentCardId]
        ) {
          result += 1;
        }
        return result;
      },
      0
    );
    sameAnswerRatio = sameAnswers / commonAnswerCardIds.length;
  }
  const [openContentsModal, setOpenQuestionDiff] = React.useState(false);

  const handleClose = () => {
    setOpenQuestionDiff(false);
  };

  if (!sameAnswerRatio) {
    return null;
  }
  return (
    <div
      style={{
        color: GRAY_DARKER,
        marginTop: "10px",
        display: "flex",
        alignItems: "flex-end"
      }}
    >
      <Dialog
        open={openContentsModal}
        onClose={handleClose}
        className={"transparentPaperPlease"}
        PaperProps={{ style: { margin: 16 } }}
      >
        <DialogContent>
          {openContentsModal && currentUserVector && profileUserVector && (
            <ProfileAnswerDiffQuestions
              profileUserId={profileUserId}
              currentUserVector={currentUserVector}
              profileUserVector={profileUserVector}
            />
          )}
        </DialogContent>
      </Dialog>
      {`나와의 응답 일치율 ${(sameAnswerRatio * 100).toFixed(1)}%`}
      <OXOPill
        onClick={() => {
          setOpenQuestionDiff(true);
        }}
        text={"응답보기"}
      />
    </div>
  );
}
