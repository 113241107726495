/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";
import {
  CardAnswerTribeStatsSchema,
  CardAnswerTribeStatsV2Schema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import getTribeMarkImage from "../../../utils/image_utils/getTribeMarkImage";

const tribeResultWrapper = css`
  position: relative;
  width: 90%;
  height: 60px;
  margin: 0 auto;
`;

export default function TribeResultsInLine({
  cardId,
  showAnimals = true,
  tribeStats
}: {
  cardId: string;
  showAnimals?: boolean;
  tribeStats?: CardAnswerTribeStatsSchema;
}) {
  const [cardAnswerTribeStats] =
    useFirestoreLiveDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  const [cardAnswerTribeStatsV2] =
    useFirestoreLiveDocument<CardAnswerTribeStatsV2Schema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2,
      cardId
    );

  if (!cardAnswerTribeStats || !cardAnswerTribeStatsV2) {
    return null;
  }

  const stats = tribeStats ?? cardAnswerTribeStatsV2.tribeStats;

  if (!stats) {
    return null;
  }

  return (
    <div css={tribeResultWrapper}>
      {showAnimals &&
        Object.keys(stats).map(tribeId => {
          const sum =
            (stats[tribeId]["o"] || 0) +
            (stats[tribeId]["?"] || 0) +
            (stats[tribeId]["x"] || 0);

          if (sum === 0) {
            return <></>;
          }

          const Mean = 0.5;

          const tribePosition =
            Mean -
            (stats[tribeId]["o"] || 0) / sum / 2 +
            (stats[tribeId]["x"] || 0) / sum / 2;

          return (
            <div key={tribeId}>
              {tribeId !== "id" && (
                <div
                  css={css`
                    position: absolute;
                    left: ${tribePosition * 100 - 10}%;
                  `}
                >
                  <img
                    key={tribeId}
                    src={getTribeMarkImage(tribeId)}
                    alt="부족 이미지"
                    css={css`
                      width: 70px;
                      height: 70px;
                      z-index: 100;
                    `}
                  />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
