import React, { useContext, useState } from "react";
import { GRAY_PROFILE, TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { getTribeStandUpImage, useIsAdmin } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { getUserDescription } from "../OXOUserProfile/UserName";
import NON_LOGIN_USER from "../icons/profile_unselected.svg";
import SwipeableViews from "react-swipeable-views";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import ProfilePagesRenderer from "./ProfilePagesRenderer";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import coinPng from "../../../img/coin.png";
import {
  AssetBalanceSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { OXO_COIN, sendCoinFromOxoTo } from "../../../utils/assets";
import DetailPagesNavigation from "../../cards/DetailViews/DetailPagesNavigation";

export default function MyProfile({ onClose }: { onClose: () => void }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const MENU: string[] = ["정치성향", "댓글", "생각"];
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [windowWidth, windowHeight] = useWindowSize();
  const isAdmin = useIsAdmin();
  const [assetBalance] = useFirestoreLiveDocument<AssetBalanceSchema>(
    FirestoreCollection.ASSET_BALANCES,
    currentUserId + OXO_COIN
  );

  return (
    <div>
      {currentUserMetadata && currentUserMetadata.tribeId ? (
        <div style={{ padding: 15 }}>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div style={{ marginBottom: 7 }}>
              <img
                style={{ maxWidth: 62, maxHeight: 60 }}
                src={getTribeStandUpImage(
                  TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].image
                )}
                alt="profileIcon"
              />
            </div>
            {isAdmin && (
              <div
                onClick={() => {
                  currentUserId &&
                    sendCoinFromOxoTo(currentUserId, 1, "관리자 보너스");
                }}
                style={{ marginLeft: 50, marginTop: 10 }}
              >
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    width: 45,
                    textAlign: "right"
                  }}
                >
                  {assetBalance?.amountMicros
                    ? (assetBalance?.amountMicros / 1000000).toLocaleString(
                        "en-US"
                      )
                    : "0"}
                </div>
                <div
                  style={{
                    lineHeight: "22px",
                    display: "flex",
                    justifyContent: "start",
                    fontSize: 15
                  }}
                >
                  <img
                    style={{ width: 22, height: 22 }}
                    src={coinPng}
                    alt="coinPng"
                  />
                  옥소
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              fontSize: 15,
              color: GRAY_PROFILE,
              display: "flex",
              flexDirection: "column"
            }}
          >
            {getUserDescription(currentUserMetadata, true)}
          </div>
        </div>
      ) : (
        <img
          width={"50px"}
          height={"50px"}
          src={NON_LOGIN_USER}
          alt="nonLogInUserIcon"
        />
      )}
      <div
        style={{
          marginTop: 8,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div style={{ width: "100%" }}>
          <DetailPagesNavigation
            menuItemArray={MENU}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        </div>
        <div style={{ width: "100%" }}>
          <SwipeableViews
            enableMouseEvents
            index={tabIndex + 1}
            onChangeIndex={(i, prevI) => {
              if (i === 0) {
                onClose && onClose();
              }
              setTabIndex(i - 1);
            }}
          >
            <div key={"close"} />
            {MENU.map((tabTitle, i) => {
              if (i === tabIndex) {
                return (
                  <div style={{ minHeight: windowHeight - 110 }} key={tabTitle}>
                    <ProfilePagesRenderer tab={tabTitle} />
                  </div>
                );
              } else {
                return <div key={tabTitle}></div>;
              }
            })}
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
}
