import React from "react";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import {
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardAnswerTribeStatsV2Schema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import CardAnswerTribeV2ColumnBarChart from "../HashCardView/Comment/CardAnswerTribeV2ColumnBarChart";
import CardAnswerTribeV1ColumnBarChart from "../HashCardView/Comment/CardAnswerTribeV1ColumnBarChart";

export default function TribeChartV1OrV2({ cardId }: { cardId: string }) {
  const cardAnswerStats = useFirestoreGetDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  const cardAnswerTribeStatsV1 =
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  const cardAnswerTribeStatsV2 =
    useFirestoreGetDocument<CardAnswerTribeStatsV2Schema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2,
      cardId
    );

  if (!cardAnswerStats || !cardAnswerTribeStatsV1 || !cardAnswerTribeStatsV2) {
    return null;
  }

  return (
    <div>
      {cardAnswerStats.count > cardAnswerTribeStatsV2.totalCount ? (
        <CardAnswerTribeV1ColumnBarChart cardId={cardId} />
      ) : (
        <CardAnswerTribeV2ColumnBarChart cardId={cardId} />
      )}
    </div>
  );
}
