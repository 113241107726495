import Button from "@material-ui/core/Button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { DARK_THEME_BACKGROUND } from "../../constants/enums";
import {
  ChatSchema,
  FirestoreCollection,
  UserLastSeenItemSchema
} from "../../constants/firestore_schema";
import { getDocsFromSnapshot, parseDoc } from "../../utils/utils";
import { DataPropsContext } from "../foundation/Contexts/DataPropsContext";
import OXOInfiniteScroll from "../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import ChatLine from "./ChatLine";
import ChatHighlights from "./ChatHighlights";
import ChatSearch from "./ChatSearch";
import NoContentForTribe from "../topic/Question/Comments/NoContentForTribe";

const BEFORE_CHAT_PER_PAGE = 20;
const AFTER_CHAT_PER_PAGE = 20;

const RESET_PAGE = 10;
export default function ChatRoom({
  chatRoomId,
  onChatBubbleClick,
  style,
  isDark
}: {
  chatRoomId: string;
  onChatBubbleClick?: (chat: ChatSchema) => void;
  style?: CSSProperties;
  isDark?: boolean;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [currentLastSeenCreatedAt, setCurrentLastSeenCreatedAt] =
    useState<number>();

  const [mainCursor, setMainCursor] = useState<number>();

  const [lastSeenChat, setLastSeenChat] = useState<UserLastSeenItemSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.USER_LAST_SEEN_ITEMS)
      .doc(currentUserId + chatRoomId)
      .get()
      .then(snapshot => setLastSeenChat(parseDoc(snapshot)));
  }, [currentUserId, chatRoomId]);

  const lastSeenChatCreatedAt = useMemo(
    () => lastSeenChat?.lastSeenAt,
    [lastSeenChat]
  );

  const [enteredRoomAt, setEnteredRoomAt] = useState<number>();

  useEffect(() => {
    if (lastSeenChat && enteredRoomAt === undefined) {
      setEnteredRoomAt(lastSeenChat.lastSeenAt || new Date().valueOf());
    }
  }, [lastSeenChat]);

  const chatRoomRef = useRef<HTMLDivElement>(null);
  const beforeBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mainCursor) {
      setBeforePage(1);
      setAfterPage(1);
      setEnteredRoomAt(mainCursor);
      chatRoomRef?.current?.scrollTo(0, 0);
      beforeBoxRef?.current?.scrollTo(0, 0);
    }
  }, [mainCursor]);

  const [chatsBefore, setChatsBefore] = useState<ChatSchema[]>();
  const [chatsAfter, setChatsAfter] = useState<ChatSchema[]>();

  const [beforePage, setBeforePage] = useState<number>(1);
  const [afterPage, setAfterPage] = useState<number>(1);

  const [updateChats, setUpdateChats] = useState<boolean>(false);

  const [resetBeforeBoxScroll, setResetBeforeBoxScroll] =
    useState<boolean>(false);
  const [resetAfterBoxScroll, setResetAfterBoxScroll] =
    useState<boolean>(false);

  useEffect(() => {
    if (!enteredRoomAt) {
      return;
    }

    firebase
      .firestore()
      .collection(FirestoreCollection.CHATS)
      .where("chatRoomId", "==", chatRoomId)
      .where("createdAt", "<=", enteredRoomAt)
      .where("parentId", "==", "main")
      .orderBy("createdAt", "desc")
      .limit(BEFORE_CHAT_PER_PAGE * beforePage)
      .get()
      .then(snapshot => {
        setChatsBefore(
          getDocsFromSnapshot(snapshot).filter(
            (chat: ChatSchema) =>
              !(
                currentUserMetadata &&
                currentUserMetadata.blockedUsers?.includes(chat.userId)
              )
          )
        );
        if (resetBeforeBoxScroll && beforeBoxRef?.current) {
          beforeBoxRef.current.scrollTo(0, 0);
          setResetBeforeBoxScroll(false);
        }
      });
  }, [chatRoomId, enteredRoomAt, beforePage, updateChats]);

  const [firstChat, setFirstChat] = useState<ChatSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CHATS)
      .where("chatRoomId", "==", chatRoomId)
      .orderBy("createdAt", "asc")
      .limit(1)
      .get()
      .then(getDocsFromSnapshot)
      .then(chats => chats[0])
      .then(setFirstChat);
  }, [chatRoomId]);

  const [lastChat, setLastChat] = useState<ChatSchema>();
  useEffect(() => {
    if (!enteredRoomAt) {
      return;
    }

    firebase
      .firestore()
      .collection(FirestoreCollection.CHATS)
      .where("chatRoomId", "==", chatRoomId)
      .where("createdAt", ">", enteredRoomAt)
      .where("parentId", "==", "main")
      .orderBy("createdAt", "asc")
      .limit(AFTER_CHAT_PER_PAGE * afterPage)
      .get()
      .then(snapshot => {
        const newChatsAfter: ChatSchema[] = getDocsFromSnapshot(
          snapshot
        ).filter(
          (chat: ChatSchema) =>
            !(
              currentUserMetadata &&
              currentUserMetadata.blockedUsers?.includes(chat.userId)
            )
        );
        setChatsAfter(newChatsAfter);
        if (resetAfterBoxScroll && chatRoomRef?.current) {
          chatRoomRef.current.scrollTo(0, 0);
          setResetAfterBoxScroll(false);
        }

        if (newChatsAfter.filter(chat => chat.id === lastChat?.id).length > 0) {
          if (chatRoomRef && chatRoomRef.current) {
            const scrollDiff =
              chatRoomRef.current.scrollTop -
              (chatRoomRef.current.scrollHeight -
                chatRoomRef.current.offsetHeight);

            if (scrollDiff >= -200) {
              chatRoomRef.current.scrollTo(0, chatRoomRef.current.scrollHeight);
            }
          }
        }
      });
  }, [chatRoomId, enteredRoomAt, afterPage, lastChat, updateChats]);

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CHATS)
      .where("chatRoomId", "==", chatRoomId)
      .where("parentId", "==", "main")
      .orderBy("createdAt", "desc")
      .limit(1)
      .onSnapshot(snapshot => {
        const chatsData: ChatSchema[] = getDocsFromSnapshot(snapshot);
        if (
          chatsAfter &&
          chatsAfter.filter(chat => chat.id === lastChat?.id).length > 0 &&
          chatsAfter.length === AFTER_CHAT_PER_PAGE * afterPage
        ) {
          setAfterPage(prev => prev + 1);
        }

        if (chatsData.length > 0) {
          setLastChat(chatsData[0]);
        }
      });
    return () => {
      unsubscriber();
    };
  }, [chatRoomId, chatRoomRef]);

  const lastChatCreatedAt =
    (chatsAfter &&
      chatsAfter.length > 0 &&
      chatsAfter[chatsAfter.length - 1].createdAt) ||
    (chatsBefore && chatsBefore.length > 0 && chatsBefore[0].createdAt);

  useEffect(() => {
    if (
      currentUserId &&
      lastChatCreatedAt &&
      lastChatCreatedAt > (lastSeenChat ? lastSeenChat.lastSeenAt || 0 : 0)
    ) {
      firebase
        .firestore()
        .collection(FirestoreCollection.USER_LAST_SEEN_ITEMS)
        .doc(currentUserId + chatRoomId)
        .set({
          userId: currentUserId,
          chatroomId: chatRoomId,
          lastSeenAt: lastChatCreatedAt
        } as UserLastSeenItemSchema)
        .then(() => console.log("Last seen chats set"));
    }

    return () => {};
  }, [lastChatCreatedAt, lastSeenChat]);

  if (
    lastChatCreatedAt &&
    (!currentLastSeenCreatedAt || currentLastSeenCreatedAt < lastChatCreatedAt)
  ) {
    setCurrentLastSeenCreatedAt(lastChatCreatedAt);
  }
  if (!enteredRoomAt) {
    return null;
  }

  if (!chatsBefore || !chatsAfter) {
    return null;
  }

  if (
    chatsAfter &&
    chatsAfter.length === 0 &&
    chatsBefore &&
    chatsBefore.length === 0
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <NoContentForTribe />
      </div>
    );
  }

  const showLastButton =
    lastChat &&
    (chatsBefore.length > 0 || chatsAfter.length > 0) &&
    !(
      chatsBefore.filter(chat => chat.id === lastChat?.id).length > 0 ||
      chatsAfter.filter(chat => chat.id === lastChat?.id).length > 0
    );

  const showFirstButton =
    firstChat &&
    (chatsBefore.length > 0 || chatsAfter.length > 0) &&
    !(
      chatsBefore.filter(chat => chat.id === firstChat?.id).length > 0 ||
      chatsAfter.filter(chat => chat.id === firstChat?.id).length > 0
    );

  return (
    <div
      className="hide-scrollbar"
      style={{
        overflow: "auto",
        padding: "0px 10px",
        width: "100%",
        backgroundColor: isDark ? "#212025" : "white",
        ...style
      }}
      ref={chatRoomRef}
    >
      <div
        style={{
          position: "fixed",
          top: 70,
          right: 13,
          zIndex: 20,
          opacity: 0.7
        }}
      >
        {showFirstButton && (
          <Button
            onClick={() => {
              setMainCursor(firstChat?.createdAt);
              setFirstChat(undefined);
            }}
            style={{
              borderRadius: "50%",
              width: 33,
              height: 33,
              minWidth: 33,
              backgroundColor: "#e0e0e0"
            }}
          >
            <KeyboardArrowUp style={{ width: 21, height: 21 }} />
          </Button>
        )}
        <div style={{ marginTop: 20 }}>
          <ChatSearch chatRoomId={chatRoomId} setMainCursor={setMainCursor} />
        </div>
        <div style={{ marginTop: 20 }}>
          <ChatHighlights
            chatRoomId={chatRoomId}
            setMainCursor={setMainCursor}
          />
        </div>

        {showLastButton && (
          <div style={{ marginTop: 20 }}>
            <Button
              onClick={() => {
                setMainCursor(new Date().valueOf());
              }}
              style={{
                borderRadius: "50%",
                width: 33,
                height: 33,
                minWidth: 33,
                backgroundColor: "#e0e0e0"
              }}
            >
              <KeyboardArrowDown style={{ width: 21, height: 21 }} />
            </Button>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          maxHeight: "100%",
          overflow: "auto",
          flexDirection: "column-reverse"
        }}
        onScroll={event => {
          if (chatRoomRef.current && chatRoomRef.current.scrollTop > 0) {
            chatRoomRef.current.scrollTop =
              chatRoomRef.current.scrollTop + event.currentTarget.scrollTop;
          }
        }}
        ref={beforeBoxRef}
      >
        {chatsBefore.map((chat, i) => {
          const nextChat = chatsBefore[i - 1];
          const previousChat = chatsBefore[i + 1];
          const previousDate = previousChat && new Date(previousChat.createdAt);

          return (
            <div
              style={{
                ...(chat.userId === currentUserId
                  ? { alignSelf: "flex-end", textAlign: "right" }
                  : {}),
                marginBottom: "10px",
                width: "100%",
                display: "grid"
              }}
              key={chat.id}
            >
              {previousChat &&
                previousDate.toLocaleDateString() !==
                  new Date(chat.createdAt).toLocaleDateString() && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        padding: "5px 20px",
                        marginTop: 15,
                        marginBottom: 15,
                        borderRadius: "20px",
                        color: "white",
                        backgroundColor: isDark
                          ? DARK_THEME_BACKGROUND
                          : "#b3b3b3"
                      }}
                    >
                      {`${previousDate.getFullYear()}년 ${
                        previousDate.getMonth() + 1
                      }월 ${previousDate.getDate()}일 ${previousDate.toLocaleDateString(
                        "ko-KR",
                        { weekday: "long" }
                      )}`}
                    </div>
                  </div>
                )}
              <div style={{ maxWidth: 750 }}>
                <ChatLine
                  chat={chat}
                  firstLine={
                    !previousChat || previousChat.userId !== chat.userId
                  }
                  lastLine={!nextChat || nextChat.userId !== chat.userId}
                  collapsed={false}
                  onChatBubbleClick={onChatBubbleClick}
                  isDark={isDark}
                  onDelete={() => {
                    setUpdateChats(prev => !prev);
                  }}
                />
              </div>
            </div>
          );
        })}

        <OXOInfiniteScroll
          setNextPage={() => {
            if (
              beforePage > RESET_PAGE &&
              chatsBefore &&
              chatsBefore.length > 0
            ) {
              setMainCursor(chatsBefore[chatsBefore.length - 1].createdAt);
              if (beforeBoxRef && beforeBoxRef.current) {
                setResetBeforeBoxScroll(true);
              }
            } else {
              setBeforePage(prev => prev + 1);
            }
          }}
          style={{ height: 10 }}
        />
      </div>
      <div>
        {chatsAfter.length > 0 &&
          chatsBefore.length > 0 &&
          lastSeenChatCreatedAt === enteredRoomAt && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  padding: "5px 10px",
                  paddingTop: "7px",
                  marginTop: 15,
                  marginBottom: 15,
                  borderRadius: "20px",
                  color: "white",
                  backgroundColor: isDark ? DARK_THEME_BACKGROUND : "#b3b3b3"
                }}
              >
                여기까지 읽으셨어요.
              </div>
            </div>
          )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {chatsAfter.map((chat, i) => {
          const nextChat = chatsAfter[i + 1];
          const previousChat = chatsAfter[i - 1];
          const previousDate = previousChat && new Date(previousChat.createdAt);

          return (
            <div
              style={{
                ...(chat.userId === currentUserId
                  ? { alignSelf: "flex-end", textAlign: "right" }
                  : {}),
                marginBottom: "10px",
                width: "100%"
              }}
              key={chat.id}
            >
              {previousChat &&
                previousDate.toLocaleDateString() !==
                  new Date(chat.createdAt).toLocaleDateString() && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        padding: "5px 20px",
                        marginTop: 15,
                        marginBottom: 15,
                        borderRadius: "20px",
                        color: "white",
                        backgroundColor: isDark
                          ? DARK_THEME_BACKGROUND
                          : "#b3b3b3"
                      }}
                    >
                      {`${previousDate.getFullYear()}년 ${
                        previousDate.getMonth() + 1
                      }월 ${previousDate.getDate()}일 ${previousDate.toLocaleDateString(
                        "ko-KR",
                        { weekday: "long" }
                      )}`}
                    </div>
                  </div>
                )}
              <ChatLine
                chat={chat}
                firstLine={!previousChat || previousChat.userId !== chat.userId}
                lastLine={!nextChat || nextChat.userId !== chat.userId}
                collapsed={false}
                onChatBubbleClick={onChatBubbleClick}
                isDark={isDark}
                onDelete={() => {
                  setUpdateChats(prev => !prev);
                }}
              />
            </div>
          );
        })}
        <OXOInfiniteScroll
          setNextPage={() => {
            if (afterPage > RESET_PAGE && chatsAfter && chatsAfter.length > 0) {
              setMainCursor(chatsAfter[chatsAfter.length - 1].createdAt);
              setResetAfterBoxScroll(true);
            } else {
              setAfterPage(prev => prev + 1);
            }
          }}
          style={{ height: 10 }}
        />
      </div>
    </div>
  );
}
