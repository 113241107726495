/** @jsxImportSource @emotion/react */

import React from "react";
import CardIndexLine from "./CardIndexLine";
import { css } from "@emotion/react";
import { OXO_GRAY700 } from "../../../constants/colors";
import PortraitPage from "../PortraitReportView/PortraitPage";

function getLine(cardId: string, i: number, month: string) {
  return (
    <div
      css={css`
        margin-bottom: 10px;
      `}
      key={cardId}
    >
      {i == 0 && (
        <div
          css={css`
            margin-top: 30px;
            margin-bottom: 20px;
            font-weight: 800;
            font-size: 20px;
            color: ${OXO_GRAY700};
          `}
        >
          {month}월
        </div>
      )}
      <CardIndexLine cardId={cardId} key={cardId} headerTitle={`${i + 1}위.`} />
    </div>
  );
}

export function YearInReviewIndex({
  cardIdForPagesByMonth,
  year
}: {
  cardIdForPagesByMonth: { [p: string]: string[] };
  year: number;
}) {
  return (
    <PortraitPage>
      <header
        css={css`
          font-weight: 800;
          font-size: 30px;
          color: ${OXO_GRAY700};
          margin-bottom: 10px;
        `}
      >
        옥소폴리틱스의 데이터로 돌아보는 {year}
      </header>

      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            width: 430px;
          `}
        >
          {Object.keys(cardIdForPagesByMonth)
            .slice(0, 6)
            .flatMap(month =>
              cardIdForPagesByMonth[month].map((cardId, i) =>
                getLine(cardId, i, month)
              )
            )}
        </div>

        <div
          css={css`
            width: 430px;
          `}
        >
          {Object.keys(cardIdForPagesByMonth)
            .slice(6)
            .flatMap(month =>
              cardIdForPagesByMonth[month].map((cardId, i) =>
                getLine(cardId, i, month)
              )
            )}
        </div>
      </div>
    </PortraitPage>
  );
}
