import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import {
  GRAY,
  GRAY_DARK,
  oxoColors,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import {
  FirestoreCollection,
  MapCoordinatesSchema,
  UserCoordinateAndTribeSchema,
  UserPrivilege,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import {
  getDocsFromSnapshot,
  getShardWithMod,
  parseDoc,
  removeIdKey
} from "../../../utils/utils";
import UserMap, {
  SeriesType
} from "../../foundation/Charts/UserMap/UserMap-victory";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import TribePowerBar from "../../views/StatsView/TribePowerBar";
import RealNameUserDetails from "./RealNameUserDetails";
import { fixMissingMapCoordinatesTribeId } from "../../../lib/tribe_lib";

export default function UserPoliticalMap({
  profileUserId
}: {
  profileUserId: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [currentUserMetadata, setCurrentUserMetadata] =
    useState<UserProfileMetadataSchema>();

  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .onSnapshot(snapshot =>
          setCurrentUserMetadata(removeIdKey(parseDoc(snapshot)))
        );
      return () => unsubscribe();
    }
  }, [currentUserId]);

  useEffect(() => {
    if (profileUserId) {
      const unsubscribeUserProfileMetadata = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(profileUserId)
        .onSnapshot(snapshot => {
          setUserProfileMetadataForProfile(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscribeUserProfileMetadata();
      };
    }
  }, [profileUserId]);

  const [userProfileMetadataForProfile, setUserProfileMetadataForProfile] =
    useState<UserProfileMetadataSchema>();

  if (
    !profileUserId ||
    !userProfileMetadataForProfile ||
    (currentUserId && !currentUserMetadata) ||
    !dataProps?.mapCoordinates
  ) {
    return null;
  }

  const seriesData: { [clusterId: string]: number[][] } = {
    me: [],
    "0": [],
    "1": [],
    "2": [],
    "3": [],
    "4": [],
    "5": [],
    profile: []
  };

  Object.keys(dataProps.mapCoordinates).map((coordinateId: string) => {
    const mapCoordinate = dataProps.mapCoordinates[coordinateId];
    if (!mapCoordinate.tribeId) {
      console.log("Erraneous map coordinate:", mapCoordinate);
      fixMissingMapCoordinatesTribeId(
        dataProps.mapCoordinates,
        mapCoordinate.x,
        mapCoordinate.y
      );
      if (mapCoordinate.count && mapCoordinate.count > 0) {
        firebase
          .firestore()
          .collection(FirestoreCollection.USER_PROFILE_METADATA)
          .where("x", "==", mapCoordinate.x)
          .where("y", "==", mapCoordinate.y)
          .get()
          .then(getDocsFromSnapshot)
          .then(userProfileMetadatas => {
            console.log(userProfileMetadatas);
            console.log(coordinateId);
            if (userProfileMetadatas[0] && userProfileMetadatas[0].tribeId) {
              const data: MapCoordinatesSchema = {};
              data[coordinateId] = {
                tribeId: userProfileMetadatas[0].tribeId
              } as UserCoordinateAndTribeSchema;

              firebase
                .firestore()
                .collection(FirestoreCollection.MAP_COORDINATES_SHARDED)
                .doc(getShardWithMod(coordinateId, 5).toString())
                .set(data, {
                  merge: true
                })
                .then(() => {
                  console.log(coordinateId, userProfileMetadatas[0].tribeId);
                })
                .catch(e => {
                  console.log(e);
                });
            }
          });
      }
      return;
    }
    seriesData[mapCoordinate.tribeId].push([mapCoordinate.x, mapCoordinate.y]);
  });

  const series: SeriesType[] = [];

  if (currentUserMetadata) {
    series.push({
      name: "나의 위치",
      data: [[currentUserMetadata.x, currentUserMetadata.y]],
      color: currentUserMetadata.tribeId
        ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
        : GRAY_DARK,
      label: "나의 위치",
      size: 4
    });
  }

  if (currentUserId !== profileUserId) {
    series.push({
      name: "profile",
      data: [
        [userProfileMetadataForProfile.x, userProfileMetadataForProfile.y]
      ],
      color: oxoColors.ME,
      label: "userName",
      size: 4
    });
  }
  const mapWidth = Math.min(window.screen.width, 750);

  const defaultSize = mapWidth / 350;

  Object.keys(TRIBE_INFO_BY_ID).forEach((tribeId: string) => {
    series.push({
      name: TRIBE_INFO_BY_ID[tribeId].name,
      data: seriesData[tribeId],
      color: TRIBE_INFO_BY_ID[tribeId].userMapColor,
      size: defaultSize,
      labelFontSize: 10
    });
  });

  const clusters: { [clusterId: string]: number } = {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0
  };

  Object.keys(dataProps.mapCoordinates).map(coordinateId => {
    const mapCoordinate = dataProps.mapCoordinates[coordinateId];
    if (!mapCoordinate.tribeId) {
      console.log("Erraneous map coordinate:", mapCoordinate);
      fixMissingMapCoordinatesTribeId(
        dataProps.mapCoordinates,
        mapCoordinate.x,
        mapCoordinate.y
      );
      return;
    }
    seriesData[mapCoordinate.tribeId].push([mapCoordinate.x, mapCoordinate.y]);
    clusters[mapCoordinate.tribeId] += mapCoordinate.count || 0;
  });

  const sumPeople = Object.values(dataProps.mapCoordinates).reduce(
    (a, b) => a + (b.count || 0),
    0
  );

  return (
    <div
      style={{
        textAlign: "left",
        position: "relative"
      }}
    >
      <div className="center-container">
        <div
          style={{
            backgroundColor: "white"
          }}
        >
          <div style={{ marginTop: 20 }}>
            {userProfileMetadataForProfile.privileges &&
            userProfileMetadataForProfile.privileges.includes(
              UserPrivilege.REAL_NAME_USER
            ) ? (
              <div style={{ padding: "0 30px" }}>
                <RealNameUserDetails
                  userId={profileUserId}
                  userProfileMetadata={userProfileMetadataForProfile}
                />
              </div>
            ) : (
              <div style={{ padding: "0 20px" }}>
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    paddingBottom: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <UserMap series={series} />
                  <div
                    style={{
                      paddingTop: "10px",
                      marginBottom: "10px"
                    }}
                  >
                    <TribePowerBar clusters={clusters} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 14,
                      color: GRAY
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
