import React, { useEffect, useState } from "react";
import {
  ChatSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";

import { getDocsFromSnapshot } from "../../utils/utils";
import ChatLine from "./ChatLine";
import firebase from "firebase/compat";

export default function ChatReplies({
  chat,
  isDark,
  onChatBubbleClick
}: {
  chat: ChatSchema;
  isDark?: boolean;
  onChatBubbleClick?: (chat: ChatSchema) => void;
}) {
  const [chats, setChats] = useState<ChatSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CHATS)
      .where("parentId", "==", chat.id)
      .orderBy("createdAt", "asc")
      .onSnapshot(snapshot => {
        setChats(getDocsFromSnapshot(snapshot));
      });
    return () => {};
  }, [chat]);

  if (!chats) {
    return null;
  }

  return (
    <>
      {chats.length > 0 && (
        <div style={{ padding: "10px 0" }}>
          {chats.map((chat, i) => {
            const nextChat = chats[i + 1];
            const previousChat = chats[i - 1];

            return (
              <div key={chat.id} style={{ marginBottom: "10px" }}>
                <ChatLine
                  chat={chat}
                  firstLine={
                    !previousChat || previousChat.userId !== chat.userId
                  }
                  lastLine={!nextChat || nextChat.userId !== chat.userId}
                  collapsed={false}
                  isDark={isDark}
                  disableRightAlign
                  onChatBubbleClick={onChatBubbleClick}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
