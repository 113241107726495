import firebase from "firebase/compat";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot,
  listToMap,
  mapValues,
  onlyUnique,
  parseDoc
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import { StanceTestConfiguration } from "../common/hashpage_shared";
import StanceTestResultCategory from "./StanceTestResultCategory";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { GlobalVariableContext } from "../../../foundation/Contexts/GlobalVariableContext";

export default function StanceTestResults({
  hashtag,
  stanceTestConfiguration
}: {
  hashtag: string;
  stanceTestConfiguration: StanceTestConfiguration;
}) {
  const history = useHistory();
  const global = useContext(GlobalVariableContext);
  const [stanceVectors, setStanceVectors] = useState<{
    [category: string]: UserVectorSchema;
  }>();
  const categories = stanceTestConfiguration.카테고리;

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.TEST_QUESTION)
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setStanceTestCards);
  }, [hashtag]);

  useEffect(() => {
    if (!categories) {
      return;
    }

    const vectorPromises = Object.keys(categories).map(category => {
      return firebase
        .firestore()
        .collection(FirestoreCollection.USER_VECTOR)
        .doc(category)
        .get()
        .then(parseDoc);
    });

    Promise.all(vectorPromises).then(vectors => {
      const categoryVectorPairs: [string, UserVectorSchema][] = Object.keys(
        categories
      ).map((category, i) => {
        return [category, vectors[i] as UserVectorSchema];
      });
      const stanceVectorMap = listToMap(
        categoryVectorPairs,
        item => item[0],
        item => item[1]
      );
      setStanceVectors(stanceVectorMap);
    });

    return () => {};
  }, [stanceTestConfiguration]);

  const categoryMatchingAnswerRatio = useMemo(() => {
    if (!stanceVectors || !stanceTestCards || !categories) {
      return null;
    }
    const matchingAnswersCount: [string, number][] = Object.keys(
      categories
    ).map(category => {
      if (currentUserVector && stanceVectors && stanceVectors[category]) {
        return [
          category,
          stanceTestCards
            .map(question => {
              const questionId = question.id;

              return (
                currentUserVector[questionId] ===
                stanceVectors[category][questionId]
              );
            })
            .filter(a => a).length
        ];
      }
      return [category, 0];
    });

    const categoryMatchingAnswers = listToMap(
      matchingAnswersCount,
      (item: [string, number]) => item[0],
      (item: [string, number]) => item[1]
    ) as { [category: string]: number };

    return mapValues(categoryMatchingAnswers, (count: number) => {
      return count / (stanceTestCards.length || 1);
    }) as { [category: string]: number };
  }, [categories, stanceTestCards, stanceVectors]);

  const sortedCategories = useMemo(() => {
    if (categoryMatchingAnswerRatio) {
      return Object.keys(categoryMatchingAnswerRatio).sort(
        (categoryA, categoryB) => {
          return (
            categoryMatchingAnswerRatio[categoryB] -
            categoryMatchingAnswerRatio[categoryA]
          );
        }
      );
    }
    return null;
  }, [categoryMatchingAnswerRatio]);

  const topCategories = useMemo(() => {
    if (
      !sortedCategories ||
      !sortedCategories?.length ||
      !categoryMatchingAnswerRatio
    ) {
      return null;
    }
    const topScore = categoryMatchingAnswerRatio[sortedCategories[0]];

    return sortedCategories?.filter(
      category => categoryMatchingAnswerRatio[category] === topScore
    );
  }, [sortedCategories, categoryMatchingAnswerRatio]);

  useEffect(() => {
    if (topCategories && topCategories[0]) {
      const winnerCategory = topCategories[0];

      if (currentUserMetadata) {
        if (!currentUserMetadata.testResults) {
          currentUserMetadata.testResults = {};
        }
        currentUserMetadata.testResults[hashtag] = {
          topCategory: winnerCategory,
          testAt: new Date().valueOf()
        };
        currentUserMetadata.finishedTests = [
          ...(currentUserMetadata.finishedTests
            ? currentUserMetadata.finishedTests
            : []),
          ...[hashtag]
        ].filter(onlyUnique);

        firebase
          .firestore()
          .collection(FirestoreCollection.USER_PROFILE_METADATA)
          .doc(currentUserId)
          .set(currentUserMetadata, { merge: true });
      }
    }

    return () => {};
  }, [topCategories]);
  if (
    !stanceVectors ||
    !stanceTestCards ||
    !categoryMatchingAnswerRatio ||
    !sortedCategories ||
    !topCategories
  ) {
    return null;
  }

  const resultString = stanceTestConfiguration.결과?.replace(
    /#\{.+\}/,
    topCategories[0]
  );

  const winnerCategory = topCategories[0];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              paddingTop: 20,
              paddingBottom: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 18 }}>{resultString}</div>
          </div>

          <StanceTestResultCategory
            category={winnerCategory}
            percentage={Math.round(
              categoryMatchingAnswerRatio[winnerCategory] * 100
            )}
            key={winnerCategory}
          />

          <div
            style={{
              color: "#2767C5",
              fontWeight: "bold",
              padding: 10,
              cursor: "pointer"
            }}
            onClick={() => {
              firebase
                .analytics()
                .logEvent("v4_click_test_result_detail_button", {
                  event_category: "hashcard",
                  event_label: hashtag
                });
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(
                    history.location.search,
                    "STANCE_TEST_RESULTS_" + hashtag
                  )
              );
            }}
          >
            결과 자세히 보기 <span style={{ fontWeight: "normal" }}> ❯</span>
          </div>
        </div>
      </div>
    </div>
  );
}
