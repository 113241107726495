import firebase from "firebase/compat";
import { FirestoreCollection, PointFieldName, UserProfileMetadataSchema } from "../constants/firestore_schema";
import { parseDoc, removeIdKey } from "../utils/utils";

/**
 * Caution: This simply adds +1 to existing counting. Make sure you call this only once.
 * @param fieldName
 * @param userId
 * @param likeUserTribeId
 * @param count
 */
export function addUserProfileMetadataCount(
  fieldName: PointFieldName,
  userId: string,
  likeUserTribeId?: string,
  count?: number
) {
  if (!fieldName) {
    return;
  }
  if (fieldName === PointFieldName.countLikes && !likeUserTribeId) {
    console.log(
      "[addUserProfileMetadataCount] 좋아요 metedata를 추가할 때에는 부족이 들어가야 합니다."
    );
    return;
  }
  // Add point
  const userProfileMetadataRef = firebase
    .firestore()
    .collection(FirestoreCollection.USER_PROFILE_METADATA)
    .doc(userId);

  firebase.firestore().runTransaction(transaction => {
    return transaction
      .get(userProfileMetadataRef)
      .then(parseDoc)
      .then(removeIdKey)
      .then((userProfileMetadata: UserProfileMetadataSchema) => {
        const data: UserProfileMetadataSchema = {} as UserProfileMetadataSchema;
        data.countLikes =
          (userProfileMetadata?.countLikes || 0) +
          (fieldName === PointFieldName.countLikes ? count || 1 : 0);
        data.countTribeLikes = userProfileMetadata?.countTribeLikes || {};
        data.countPosts =
          (userProfileMetadata?.countPosts || 0) +
          (fieldName === PointFieldName.countPosts ? count || 1 : 0);
        data.countAnswers =
          (userProfileMetadata?.countAnswers || 0) +
          (fieldName === PointFieldName.countAnswers ? count || 1 : 0);
        data.countComments =
          (userProfileMetadata?.countComments || 0) +
          (fieldName === PointFieldName.countComments ? count || 1 : 0);

        data.points = userProfileMetadata.points || 0;

        if (likeUserTribeId) {
          data.countTribeLikes[likeUserTribeId] =
            (userProfileMetadata.countTribeLikes
              ? userProfileMetadata.countTribeLikes[likeUserTribeId] || 0
              : 0) + (count || 1);
        }

        transaction.set(userProfileMetadataRef, data, { merge: true });
      });
  });
}
