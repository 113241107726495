import firebase from "firebase/compat";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../../constants/firestore_schema";
import { ROUTES } from "../../../../../constants/routes";
import { addAnswer, deleteCardAnswers } from "../../../../../lib/answer_lib";
import {
  getDocsFromSnapshot,
  listToMap,
  mapValues,
  parseDoc
} from "../../../../../utils/utils";
import { DataPropsContext } from "../../../../foundation/Contexts/DataPropsContext";
import {
  HashtagConfiguration,
  parseYamlDescription,
  StanceTestConfiguration
} from "../../common/hashpage_shared";
import StanceTestResultsReview from "../StanceTestResultsReview";
import appDownloadIcon from "../imgs/appDownloadIcon.svg";
import StanceTestShareLargeButtons from "../StanceTestShareBtns/StanceTestShareLargeButtons";
import StanceTestTypeScoreResult from "../StanceTestTypeScoreResult";
import CommentsAndResultsForStanceTestResults from "./CommentsAndResultsForStanceTestResult";
import { GRAY_DEBATE_DARK } from "../../../../../constants/enums";
import { useIsApp } from "../../../../../utils/hooks/useIsApp";
import useFirestoreLiveDocument from "../../../../../utils/hooks/useFirestoreLiveDocument";
import { useIsAndroid } from "../../../../../utils/hooks/useIsAndroid";
import { useIsIos } from "../../../../../utils/hooks/useIsIos";
import { sendCoinFromOxoTo } from "../../../../../utils/assets";

export default function StanceTestResultTypeScore({
  hashtag,
  stanceTestConfiguration
}: {
  hashtag: string;
  stanceTestConfiguration: StanceTestConfiguration;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isApp = useIsApp();
  const isAndroid = useIsAndroid();
  const isIos = useIsIos();
  const [newAnswer, setNewAnswer] = useState<string>();

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + hashtag
  );
  const answer = cardAnswer?.answer;

  function makeAppInstallLink() {
    if (isAndroid)
      return "https://play.google.com/store/apps/details?id=com.oxopolitics";
    else if (isIos) return "https://apps.apple.com/kr/app/id1537018532";
    else if (/Android/i.test(navigator.userAgent))
      return "https://play.google.com/store/apps/details?id=com.oxopolitics";
    else if (/iPhone|iPad|iPod/i.test(navigator.userAgent))
      return "https://apps.apple.com/kr/app/id1537018532";
  }

  const categories =
    stanceTestConfiguration && stanceTestConfiguration.카테고리;

  const [stanceVectors, setStanceVectors] = useState<{
    [category: string]: UserVectorSchema;
  }>();

  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.TEST_QUESTION)
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setStanceTestCards);

    if (currentUserId) {
      sendCoinFromOxoTo(currentUserId, 10, "[1회성]" + hashtag);
    }
  }, [hashtag]);

  useEffect(() => {
    if (
      newAnswer &&
      (!answer || newAnswer !== answer) &&
      currentUserId &&
      currentUserMetadata
    ) {
      console.log(answer, "->", newAnswer);
      addAnswer(hashtag, currentUserId, currentUserMetadata, newAnswer);
    }

    return () => {};
  }, [newAnswer]);

  useEffect(() => {
    if (!categories) {
      return;
    }

    const vectorPromises = Object.keys(categories).map(category => {
      return firebase
        .firestore()
        .collection(FirestoreCollection.USER_VECTOR)
        .doc(category)
        .get()
        .then(parseDoc);
    });

    Promise.all(vectorPromises).then(vectors => {
      const categoryVectorPairs: [string, UserVectorSchema][] = Object.keys(
        categories
      ).map((category, i) => {
        return [category, vectors[i] as UserVectorSchema];
      });
      const stanceVectorMap = listToMap(
        categoryVectorPairs,
        (item: [string, UserVectorSchema]) => item[0],
        (item: [string, UserVectorSchema]) => item[1]
      ) as { [category: string]: UserVectorSchema };
      setStanceVectors(stanceVectorMap);
    });

    return () => {};
  }, [stanceTestConfiguration]);

  const categoryMatchingAnswerRatio = useMemo(() => {
    if (!stanceVectors || !stanceTestCards || !categories) {
      return null;
    }
    const matchingAnswersCount: [string, number][] = Object.keys(
      categories
    ).map(category => {
      if (stanceVectors && stanceVectors[category]) {
        return [
          category,
          stanceTestCards
            .map(question => {
              const questionId = question.id;

              return (
                currentUserVector &&
                currentUserVector[questionId] ===
                  stanceVectors[category][questionId]
              );
            })
            .filter(a => a).length
        ];
      }
      return [category, 0];
    });

    const categoryMatchingAnswers = listToMap(
      matchingAnswersCount,
      (item: [string, number]) => item[0],
      (item: [string, number]) => item[1]
    ) as {
      [category: string]: number;
    };

    return mapValues(categoryMatchingAnswers, (count: number) => {
      return count / (stanceTestCards.length || 1);
    }) as {
      [category: string]: number;
    };
  }, [categories, stanceTestCards, stanceVectors]);

  const sortedCategories = useMemo(() => {
    if (categoryMatchingAnswerRatio) {
      return Object.keys(categoryMatchingAnswerRatio).sort(
        (categoryA, categoryB) => {
          return (
            categoryMatchingAnswerRatio[categoryB] -
            categoryMatchingAnswerRatio[categoryA]
          );
        }
      );
    }
    return null;
  }, [categoryMatchingAnswerRatio]);

  const topCategories = useMemo(() => {
    if (
      !sortedCategories ||
      !sortedCategories?.length ||
      !categoryMatchingAnswerRatio
    ) {
      return null;
    }
    const topScore = categoryMatchingAnswerRatio[sortedCategories[0]];

    return sortedCategories?.filter(
      category => categoryMatchingAnswerRatio[category] === topScore
    );
  }, [sortedCategories, categoryMatchingAnswerRatio]);

  const [winnerProfileCard, setWinnerProfileCard] = useState<CardSchema>();
  useEffect(() => {
    if (topCategories && topCategories[0]) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(topCategories[0].replace("#", ""))
        .get()
        .then(parseDoc)
        .then(setWinnerProfileCard);
    }
  }, [topCategories]);

  const winnerProfileCardHashPageConfiguration:
    | HashtagConfiguration
    | undefined = useMemo(() => {
    if (winnerProfileCard) {
      return parseYamlDescription(
        winnerProfileCard.hashtagMetadata?.description
      );
    }
  }, [winnerProfileCard]);

  if (
    !topCategories ||
    !stanceTestConfiguration ||
    !categoryMatchingAnswerRatio ||
    !winnerProfileCardHashPageConfiguration
  ) {
    return null;
  }

  const resultString = stanceTestConfiguration.결과?.replace(
    /#\{.+\}/,
    topCategories[0]
  );

  const winnerCategory = topCategories[0];

  const highlightColor = stanceTestConfiguration.메인컬러 || "#3E7A99";

  const userScore: number =
    (categoryMatchingAnswerRatio[winnerCategory] * 100 - 50) * 2;

  if (userScore) {
    let userAnswer: string;

    userScore <= -33.4
      ? (userAnswer = "x")
      : userScore <= 33.4
      ? (userAnswer = "?")
      : (userAnswer = "o");

    if (userAnswer !== newAnswer) {
      setNewAnswer(userAnswer);
    }
  }

  const activeAppInstallBtn: boolean = isMobile && !isApp;
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div
            style={{
              paddingTop: 10,
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{
                fontSize: 34,
                color: GRAY_DEBATE_DARK,
                fontFamily: "East Sea Dokdo"
              }}
            >
              {resultString}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              color: highlightColor
            }}
          >
            <div
              style={{
                fontSize: 60
              }}
            >
              <StanceTestTypeScoreResult score={userScore} />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          paddingTop: 30,
          display: "flex",
          justifyContent: "center"
        }}
        onClick={() => {
          firebase.analytics().logEvent("v4_click_share_only_test_button", {
            event_category: "hashcard",
            event_label: hashtag
          });
        }}
      >
        {stanceTestConfiguration.결과링크 && resultString && (
          <StanceTestShareLargeButtons
            hashtag={hashtag}
            linkUrl={stanceTestConfiguration.결과링크}
            facebookTitle={resultString}
            twitterTitle={resultString}
            kakaoTitle={resultString}
            color={highlightColor}
          />
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: 20
        }}
      >
        <div style={{ width: 320 }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {activeAppInstallBtn && (
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: GRAY_DEBATE_DARK,
                    borderRadius: 10,
                    fontWeight: "bold",
                    fontSize: 18,
                    cursor: "pointer",
                    width: 155,
                    height: 70
                  }}
                  onClick={() => {
                    window.open(makeAppInstallLink());
                  }}
                >
                  앱으로 보기
                  <span style={{ paddingLeft: 20 }}>
                    <img src={appDownloadIcon} alt="appDownLoadIcon" />
                  </span>
                </div>
              )}
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: GRAY_DEBATE_DARK,
                  border: `1px solid ${GRAY_DEBATE_DARK}`,
                  borderRadius: 10,
                  fontWeight: "bold",
                  fontSize: 18,
                  cursor: "pointer",
                  width: activeAppInstallBtn ? 155 : 325,
                  height: 70
                }}
                onClick={() => {
                  firebase.analytics().logEvent("v4_click_test_home_button", {
                    event_category: "hashcard",
                    event_label: hashtag
                  });
                  history.push(ROUTES.HOME);
                }}
              >
                홈으로 가기
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {!currentUserMetadata?.tribeId && (
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: GRAY_DEBATE_DARK,
                    border: `1px solid ${GRAY_DEBATE_DARK}`,
                    borderRadius: 10,
                    fontWeight: "bold",
                    fontSize: 18,
                    cursor: "pointer",
                    width: 155,
                    height: 70
                  }}
                  onClick={() => {
                    firebase.analytics().logEvent("v4_click_test_home_button", {
                      event_category: "hashcard",
                      event_label: hashtag
                    });
                    history.push(ROUTES.ONBOARDING);
                  }}
                >
                  부족테스트 하기
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: GRAY_DEBATE_DARK,
                  border: `solid 1px ${GRAY_DEBATE_DARK}`,
                  borderRadius: 10,
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 18,
                  cursor: "pointer",
                  width: currentUserMetadata?.tribeId ? 325 : 155,
                  height: 70
                }}
                onClick={() => {
                  firebase.analytics().logEvent("v4_click_test_again_button", {
                    event_category: "hashcard",
                    event_label: hashtag
                  });
                  if (currentUserVector && currentUserId) {
                    stanceTestCards?.forEach(card => {
                      delete currentUserVector[card.id];
                    });
                    currentUserMetadata &&
                      deleteCardAnswers(
                        currentUserId,
                        stanceTestCards?.map(card => card.id) || [],
                        currentUserMetadata
                      );
                    firebase
                      .firestore()
                      .collection(FirestoreCollection.USER_VECTOR)
                      .doc(currentUserId)
                      .set(currentUserVector);

                    history.push(ROUTES.URL_STACK + `/${hashtag}`);
                  }
                }}
              >
                테스트 다시하기
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: 20, width: "100%", padding: "0px 5px" }}>
        <CommentsAndResultsForStanceTestResults
          topCategoryName={winnerCategory}
          cardId={hashtag}
          highlightColor={highlightColor}
        />
      </div>

      <div style={{ height: 10 }} />

      <div style={{ width: "100%" }}>
        {highlightColor && (
          <StanceTestResultsReview
            hashtag={hashtag}
            stanceTestConfiguration={stanceTestConfiguration}
            highlightColor={highlightColor}
          />
        )}
      </div>

      <div style={{ height: 40 }} />
    </div>
  );
}
