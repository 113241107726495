/** @jsxImportSource @emotion/react */

import React from "react";
import { useCardSubscription } from "../../../utils/hooks/useFirestoreLiveDocument";
import { css } from "@emotion/react";
import CardAnswerBarChartForCardId from "../HashCardView/Comment/CardAnswerBarChartForCardId";
import { DATA_PLATFORM_RED_GRAY } from "../../../constants/data_platform_colors";

export function DataMentionHashtagSummaryCard({
  cardId,
  showComments = true,
  showName = true
}: {
  cardId: string;
  showComments?: boolean;
  showName?: boolean;
}) {
  const [card] = useCardSubscription(cardId);

  if (!card) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 360px;
          margin-bottom: 14px;
        `}
      >
        {showName && (
          <div
            css={css`
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            {card?.title && <div>{card?.title}</div>}
          </div>
        )}

        <div
          css={css`
            display: flex;
            margin-top: 40px;
          `}
        >
          <CardAnswerBarChartForCardId cardId={cardId} showPercentage={true} />
        </div>
      </div>
    </div>
  );
}
