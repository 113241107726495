/** @jsxImportSource @emotion/react */

import * as queryString from "querystring";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  DataPlatformUserProfile,
  FirestoreCollection,
  MapCoordinatesSchema,
  UserPrivilege,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { ROUTES } from "../../../constants/routes";
import { DataPropsType } from "../../../constants/types";
import { getMapFromSnapshots, parseDoc } from "../../../utils/utils";
import MainViewWithNavigation from "./MainViewWithNavigation";
import { DAY, HOURS } from "../../../utils/datetime";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import UrlCardDrawerWrapper from "../../cards/Wrappers/UrlCardDrawerWrapper";
import { IconButton, Snackbar } from "@material-ui/core";
import { SnackbarContext } from "../Contexts/SnackbarContext";
import CloseIcon from "@material-ui/icons/Close";
import { migrateLastSeenChats } from "../../../utils/migrate";

import firebase from "firebase/compat";
import { EmailLogin } from "../Account/SignIn/EmailLogin";
import InvalidRoute from "./InvalidRoute";

import DashboardView from "../../views/DailyReportsView/DailyReportsView";
import CardDetailsPage from "../../views/Today/CardDetailsPage";
import MentionResponseView from "../../views/MentionResponseView/MentionResponseView";
import PopularityRateView from "../../views/PopularityRateView/PopularityRateView";
import SupporterView from "../../views/SupporterView/SupporterView";
import DailyReport from "../../views/DailyReportsView/DailyReport";
import DailyReportPortrait from "../../views/PortraitReportView/DailyReportPortrait";
import { MobileAuth } from "../Account/SignIn/MobileAuth";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import DataExplorerView from "../../views/DataExplorerView/DataExplorerView";
import CardExplorerView from "../../views/DataExplorerView/CardExplorerView";
import { css } from "@emotion/react";
import PoldiView from "../../views/PoldiView/PoldiView";
import HashtagTimeSeriesView from "../../views/HashtagTimeSeriesView/HashtagTimeSeriesView";
import PortraitCardView from "../../views/PortraitCardView/PortraitCardView";
import YearInReview from "../../views/YearInReview/YearInReview";
import YearInReviewPublish from "../../views/YearInReview/YearInReviewPublish";
import CardAnswersView from "../../views/Today/CardAnswersView";
import CardAnswerTribesView from "../../views/Today/tribe_stats/CardAnswerTribesView";
import RecentAnswerTribesView from "../../views/Today/tribe_stats/RecentAnswerTribesView";
import MonthInReviewPublish from "../../views/YearInReview/MonthInReviewPublish";
import MonthInReview from "../../views/YearInReview/MonthInReview";
import CardStatViewBruteForceData from "../../views/Today/CardStatViewBruteForceData";
import CardGptView from "../../views/CardGptView/CardGptView";

const MyProfileView = lazy(
  () => import("../../views/MyProfileView/MyProfileView")
);
const IntroCard = lazy(() => import("../../views/OnboardingView/IntroCard"));
const IntroLoggedIn = lazy(
  () => import("../../views/OnboardingView/IntroLoggedIn")
);
const IntroAgreementView = lazy(
  () => import("../../views/OnboardingView/IntroAgreementView")
);
const SearchView = lazy(() => import("../../views/SearchView/SearchView"));
const TodayView = lazy(() => import("../../views/Today/TodayView"));

const PrivacyPolicy = lazy(() => import("../Privacy/PrivacyPolicy"));

const EMERGENCY_KILL = false;
export const TIME_TO_UPDATE = 48 * HOURS;

export default function Main() {
  const [authLoaded, setAuthLoaded] = useState<boolean>(false);
  firebase.auth().onAuthStateChanged(function () {
    setAuthLoaded(true);
  });

  const currentUserId = firebase.auth().currentUser?.uid;
  const history = useHistory();

  const location = useLocation();
  const [mapCoordinates, setMapCoordinates] = useState<MapCoordinatesSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.MAP_COORDINATES_SHARDED)
      .get()
      .then(snapshot => {
        const allShards: {
          [shardId: string]: MapCoordinatesSchema;
        } = getMapFromSnapshots(snapshot);
        const result: MapCoordinatesSchema = {};

        Object.values(allShards).forEach(shardData => {
          Object.keys(shardData).forEach(coordinateId => {
            if (!result[coordinateId]) {
              result[coordinateId] = shardData[coordinateId];
            } else {
              const coordinate = result[coordinateId];
              if (coordinate.count) {
                coordinate.count += shardData[coordinateId].count || 0;
              }
            }
          });
        });

        setMapCoordinates(result);
      });
  }, []);

  const urlSearchParams = queryString.parse(
    history.location.search.replace("?", "")
  );
  // @ts-ignore
  const urlCardId: string = urlSearchParams["cardId"];

  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const [personHashtag, setPersonHashtag] = useState("이재명");
  const [contenderHashtag, setContenderHashtag] = useState("윤석열");

  const [dataPlatformUserProfile] =
    useFirestoreLiveDocument<DataPlatformUserProfile>(
      FirestoreCollection.DATA_PLATFORM_USER_PROFILES,
      currentUserId
    );

  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [snackBarUrl, setSnackBarUrl] = useState<string>();
  const displaySnackbar = useMemo(
    () => (message: string, url?: string) => {
      setSnackbarMessage(message);
      setShowSnackbar(true);
      setSnackBarUrl(url);
    },
    [snackbarMessage]
  );

  const [currentUserMetadata, setCurrentUserMetadata] =
    useState<UserProfileMetadataSchema>();

  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .onSnapshot(snapshot => {
          const userProfileMetadata = parseDoc(snapshot);
          setCurrentUserMetadata(userProfileMetadata);
        });
      return () => unsubscribe();
    } else {
      setCurrentUserMetadata(undefined);
    }
  }, [currentUserId]);

  const isAdmin =
    currentUserMetadata?.privileges &&
    currentUserMetadata?.privileges?.includes(UserPrivilege.ADMIN);

  if (!authLoaded) {
    return null;
  }

  if ((!currentUserId || !isAdmin) && location.pathname !== ROUTES.INTRO) {
    return (
      <div
        css={css`
          width: 100%;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <div>관리자 로그인이 필요합니다.</div>
        <div
          onClick={() => {
            history.push(ROUTES.INTRO);
          }}
        >
          로그인 하기
        </div>
      </div>
    );
  }

  if (EMERGENCY_KILL) {
    return (
      <div className="center-container" style={{ margin: "30px;" }}>
        <h1>
          접속량이 많아 서버 증설 작업 중입니다. 2시간 이내에 작업을 마치도록
          하겠습니다. 이용에 불편을 드려 죄송합니다.
        </h1>
      </div>
    );
  }

  if (mapCoordinates) {
    Object.keys(mapCoordinates)
      .filter(mapCoordinateKey => mapCoordinateKey.includes("NaN"))
      .forEach(nanKey => {
        delete mapCoordinates[nanKey];
      });
  }

  if (currentUserId && currentUserMetadata?.lastSeenChats) {
    migrateLastSeenChats(currentUserId, currentUserMetadata);
  }

  const dataProps: DataPropsType = {
    mapCoordinates: mapCoordinates || {},
    currentUserMetadata: currentUserId ? currentUserMetadata : undefined,
    currentUserId: currentUserId,
    dataPlatformUserProfile: dataPlatformUserProfile
  };

  // One_off code hack. Do not use this unless you know what you're doing.
  // @ts-ignore
  window.oneOffRunner = () => {};

  if (urlCardId) {
    history.push(ROUTES.HOME);
    history.push(ROUTES.HOME + "?cardStack=" + urlCardId);
  }

  const preloaderElement = document.getElementById("preloader");
  if (preloaderElement && preloaderElement.style.display !== "none") {
    preloaderElement.style.display = "none";
  }

  return (
    <DataPropsContext.Provider value={dataProps}>
      <SnackbarContext.Provider value={displaySnackbar}>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={showSnackbar}
            onClose={handleSnackbarClose}
            autoHideDuration={3000}
            message={
              <div
                style={{ ...(snackBarUrl ? { cursor: "pointer" } : {}) }}
                onClick={() => {
                  if (snackBarUrl) {
                    history.push(snackBarUrl);
                  }
                }}
              >
                {snackbarMessage}
              </div>
            }
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleSnackbarClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />

          <UrlCardDrawerWrapper />
          <Suspense fallback={<div />}>
            <Switch>
              <Route exact path={ROUTES.HOME}>
                <MainViewWithNavigation child={<CardExplorerView />} />
              </Route>

              <Route exact path={ROUTES.DASHBOARD}>
                <MainViewWithNavigation
                  child={
                    <MainViewWithNavigation child={<CardExplorerView />} />

                    // <DashboardView
                    //   person={personHashtag}
                    //   setPerson={setPersonHashtag}
                    //   setContender={setContenderHashtag}
                    // />
                  }
                />
              </Route>
              <Route exact path={ROUTES.INTRO}>
                <IntroCard
                  queryString={history.location.search
                    .toString()
                    .replace("?", "")}
                />
              </Route>

              <Route exact path={ROUTES.DATA_EXPLORER}>
                <MainViewWithNavigation child={<DataExplorerView />} />
              </Route>

              <Route exact path={ROUTES.CARD_DATA}>
                <MainViewWithNavigation child={<CardExplorerView />} />
              </Route>

              <Route exact path={ROUTES.POLDI}>
                <MainViewWithNavigation child={<PoldiView />} />
              </Route>

              <Route exact path={ROUTES.HASHTAG_TIME_SERIES}>
                <MainViewWithNavigation child={<HashtagTimeSeriesView />} />
              </Route>

              {/* 사용 안 하는 라우트들 */}
              <Route exact path={ROUTES.POPULAR_ISSUES}>
                <MainViewWithNavigation
                  child={
                    <DailyReport
                      dayOnset={new Date().valueOf() - 2 * DAY}
                      dayEnd={new Date().valueOf()}
                    />
                  }
                />
              </Route>
              <Route exact path={ROUTES.AGENDA}>
                <MainViewWithNavigation
                  child={
                    <DashboardView
                      person={personHashtag}
                      setPerson={setPersonHashtag}
                      setContender={setContenderHashtag}
                    />
                  }
                />
              </Route>
              <Route exact path={ROUTES.MENTION_RESPONSE}>
                <MainViewWithNavigation child={<MentionResponseView />} />
              </Route>
              <Route exact path={ROUTES.POPULARITY_RATES}>
                <MainViewWithNavigation child={<PopularityRateView />} />
              </Route>
              <Route exact path={ROUTES.SUPPORTERS}>
                <MainViewWithNavigation child={<SupporterView />} />
              </Route>
              <Route
                exact
                path={ROUTES.CARD_STAT_VIEW + "/:cardId"}
                render={props => {
                  const cardId = props.match.params["cardId"];
                  if (!cardId) {
                    throw new Error("카드 아이디가 없습니다.");
                  }
                  return (
                    cardId && (
                      <MainViewWithNavigation
                        child={<CardStatViewBruteForceData cardId={cardId} />}
                      />
                    )
                  );
                }}
              />
              <Route
                exact
                path={ROUTES.CARD_ANSWERS + "/:cardId"}
                render={props => {
                  const cardId = props.match.params["cardId"];
                  if (!cardId) {
                    throw new Error("카드 아이디가 없습니다.");
                  }
                  return (
                    cardId && (
                      <MainViewWithNavigation
                        child={<CardAnswersView cardId={cardId} />}
                      />
                    )
                  );
                }}
              />

              <Route
                exact
                path={ROUTES.CARD_ANSWER_TRIBES + "/:cardId"}
                render={props => {
                  const cardId = props.match.params["cardId"];
                  if (!cardId) {
                    throw new Error("카드 아이디가 없습니다.");
                  }
                  return (
                    cardId && (
                      <MainViewWithNavigation
                        child={<CardAnswerTribesView cardId={cardId} />}
                      />
                    )
                  );
                }}
              />

              <Route
                exact
                path={ROUTES.CARD_GPT + "/:cardId"}
                render={props => {
                  const cardId = props.match.params["cardId"];
                  if (!cardId) {
                    throw new Error("카드 아이디가 없습니다.");
                  }
                  return cardId && <CardGptView cardId={cardId} />;
                }}
              />

              <Route
                exact
                path={ROUTES.RECENT_ANSWER_TRIBES}
                render={() => {
                  return (
                    <MainViewWithNavigation
                      child={<RecentAnswerTribesView />}
                    />
                  );
                }}
              />

              {/** 라우트 정리 예정*/}

              <Route exact path={ROUTES.AUTH}>
                <MainViewWithNavigation child={<MobileAuth />} />
              </Route>
              <Route exact path={ROUTES.INTRO_LOGGED_IN}>
                <MainViewWithNavigation child={<IntroLoggedIn />} />
              </Route>
              <Route exact path={ROUTES.INTRO_AGREEMENT}>
                <MainViewWithNavigation child={<IntroAgreementView />} />
              </Route>

              <Route
                exact
                path={ROUTES.DAILY_REPORT + "/:hashtag"}
                render={props => {
                  const hashtag = props.match.params["hashtag"];
                  if (!hashtag) {
                    return null;
                  }
                  return (
                    <DailyReportPortrait
                      personHashtag={hashtag}
                      competitorHashtag={contenderHashtag} // Not in use currently
                    />
                  );
                }}
              />
              <Route
                exact
                path={ROUTES.PROFILE + "/:userId"}
                render={() => {
                  return <MainViewWithNavigation child={<TodayView />} />;
                }}
              />
              <Route
                exact
                path={ROUTES.CARD + "/:cardId"}
                render={props => {
                  const cardId = props.match.params["cardId"];
                  if (!cardId) {
                    throw new Error("카드 아이디가 없습니다.");
                  }
                  return (
                    cardId && (
                      <MainViewWithNavigation
                        child={<CardDetailsPage cardId={cardId} />}
                      />
                    )
                  );
                }}
              />
              <Route
                exact
                path={ROUTES.CARD_REPORT + "/:cardId"}
                render={props => {
                  const cardId = props.match.params["cardId"];
                  if (!cardId) {
                    throw new Error("카드 아이디가 없습니다.");
                  }
                  return cardId && <PortraitCardView cardId={cardId} />;
                }}
              />
              <Route
                exact
                path={ROUTES.YEAR_IN_REVIEW_PUBLISH + "/:year"}
                render={props => {
                  const year = props.match.params["year"];
                  if (!year) {
                    throw new Error("연도가 없습니다.");
                  }
                  return year && <YearInReviewPublish year={parseInt(year)} />;
                }}
              />
              <Route
                exact
                path={ROUTES.MONTH_IN_REVIEW_PUBLISH + "/:year/:month/:count"}
                render={props => {
                  const year = props.match.params["year"];
                  const month = props.match.params["month"];
                  const count = props.match.params["count"];
                  if (!year) {
                    throw new Error("연도가 없습니다.");
                  }
                  if (!month) {
                    throw new Error("월이 없습니다.");
                  }
                  if (!count) {
                    throw new Error("상위 갯수가 없습니다.");
                  }
                  return (
                    <MonthInReviewPublish
                      year={parseInt(year)}
                      month={parseInt(month)}
                      count={parseInt(count)}
                    />
                  );
                }}
              />
              <Route exact path={ROUTES.MY_PROFILE}>
                <MainViewWithNavigation child={<MyProfileView />} />
              </Route>
              <Route exact path={ROUTES.MONTH_IN_REVIEW}>
                <MainViewWithNavigation child={<MonthInReview />} />
              </Route>
              <Route exact path={ROUTES.YEAR_IN_REVIEW}>
                <MainViewWithNavigation child={<YearInReview />} />
              </Route>
              <Route exact path={ROUTES.SEARCH}>
                <MainViewWithNavigation child={<SearchView />} />
              </Route>

              <Route exact path={ROUTES.EMAIL_LOGIN}>
                <MainViewWithNavigation child={<EmailLogin />} />
              </Route>
              <Route exact path={ROUTES.PRIVACY}>
                <MainViewWithNavigation child={<PrivacyPolicy />} />
              </Route>

              <Route component={InvalidRoute} />
            </Switch>
          </Suspense>
        </div>
      </SnackbarContext.Provider>
    </DataPropsContext.Provider>
  );
}
