import React from "react";
import { VictoryBar, VictoryGroup, VictoryLabel, VictoryStack } from "victory";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

export default function TribePowerBar({
  clusters
}: {
  clusters: { [clusterId: string]: number };
}) {
  const [windowWidth, height] = useWindowSize();

  if (clusters == null) {
    return null; // cannot be the case.
  }

  const stats = Object.keys(TRIBE_INFO_BY_ID).map(tribeId => ({
    x: 0,
    y: clusters[tribeId] || 0
  }));

  const sum = stats.reduce((result, stat) => result + stat.y, 0);

  const barStyle = {
    labels: {
      fill: "white",
      fontSize: 16
    }
  };
  return (
    <VictoryGroup height={31} width={750}>
      <VictoryStack
        horizontal
        colorScale={Object.values(TRIBE_INFO_BY_ID).map(t => t.color)}
      >
        {stats.map((stat, i) => {
          return (
            <VictoryBar
              key={"tribe" + i}
              data={[stat]}
              labels={({ datum }) => ((datum.y / sum) * 100).toFixed(0) + "%"}
              style={barStyle}
              labelComponent={<VictoryLabel dy={35} dx={-40} />}
            />
          );
        })}
      </VictoryStack>
    </VictoryGroup>
  );
}
