import React, { useState } from "react";
import { GRAY_MIDDLE } from "../../../../constants/enums";
import HashPageStoryTimeline from "../Story/HashPageStoryTimeline";

export default function HashCardDetailPagesStoryTab({
  hashtag
}: {
  hashtag: string;
}) {
  const [sortBy, setSortBy] = useState("lastest");

  return (
    <div>
      <div style={{ width: "100%", padding: 20 }}>
        <div
          style={{
            width: 90,
            display: "flex",
            justifyContent: "space-between",
            fontSize: 15
          }}
        >
          <span
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              setSortBy("lastest");
            }}
            style={{
              cursor: "pointer",
              color: sortBy === "lastest" ? "black" : GRAY_MIDDLE
            }}
          >
            최신순
          </span>
          <span
            onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
              setSortBy("oldest");
            }}
            style={{
              cursor: "pointer",
              color: sortBy === "oldest" ? "black" : GRAY_MIDDLE
            }}
          >
            과거순
          </span>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          overflow: "auto",
          maxHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <HashPageStoryTimeline
          hashtags={[hashtag]}
          showDetails={true}
          sortBy={sortBy}
        />
      </div>
    </div>
  );
}
