import React, { useContext, useState } from "react";
import { useIsAdmin } from "../../utils/utils";
import { Dialog, DialogContent } from "@material-ui/core";
import { QuestionAdmin } from "./Question/QuestionAdmin/QuestionAdmin";
import { DataPropsContext } from "../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument from "../../utils/hooks/useFirestoreLiveDocument";
import {
  CardSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";
import { CardDisplayStatus } from "./CardDisplayStatus";

export default function CardAdminTools({ cardId }: { cardId: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const [expandAdmin, setExpandAdmin] = useState<boolean>(false);
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  const handleClose = () => {
    setExpandAdmin(false);
  };

  const isAdmin = useIsAdmin();
  if (!isAdmin || !card) {
    return null;
  }

  return (
    <div>
      {isAdmin && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            padding: 20
          }}
          onClick={() => setExpandAdmin(p => !p)}
        >
          관리자 도구
          <div style={{ marginLeft: 10 }}>
            <CardDisplayStatus card={card} />
          </div>
        </div>
      )}
      <Dialog open={expandAdmin} onClose={handleClose}>
        <DialogContent>
          {expandAdmin && (
            <div style={{ padding: "5px", backgroundColor: "white" }}>
              <QuestionAdmin questionId={cardId} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
