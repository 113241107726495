/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";

export default function StatsForCardIdTd({ cardId }: { cardId: string }) {
  const cardAnswerStats = useFirestoreGetDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  if (!cardAnswerStats) {
    return null;
  }

  return (
    <>
      <td>{cardAnswerStats.o || 0}</td>
      <td>{cardAnswerStats["?"] || 0}</td>
      <td>{cardAnswerStats.x || 0}</td>
      <td>
        {(cardAnswerStats.o || 0) +
          (cardAnswerStats["?"] || 0) +
          (cardAnswerStats.x || 0)}
      </td>
    </>
  );
}
