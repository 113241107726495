import React, { useEffect, useMemo, useState } from "react";
import { GRAY_DARK } from "../../../../constants/enums";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter,
  parseDoc,
  useIsAdmin
} from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  CardSubType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";

import {
  getDefaultHashtagYaml,
  HashtagConfiguration,
  parseYamlConfigurationWithDefault
} from "../../HashCardView/common/hashpage_shared";
import ChatButtonLight from "../../HashCardView/common/ChatButtonLight";
import HashtagPill from "../../HashCardView/common/HashtagPill";
import HashtagFollowButton from "./HashtagFollowButton";
import firebase from "firebase/compat";
import OXOText from "../../../utility/OXOText/OXOText";

export default function TodayHashtagTitle({ hashtag }: { hashtag: string }) {
  const history = useHistory();
  const [card, setCard] = useState<CardSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then(setCard);
  }, [hashtag]);

  const hashPageConfiguration: HashtagConfiguration | undefined =
    useMemo(() => {
      return (
        card &&
        parseYamlConfigurationWithDefault(
          card?.hashtagMetadata?.description,
          hashtag,
          getDefaultHashtagYaml(card.subType, hashtag)
        )
      );
    }, [card, hashtag]);

  const description = hashPageConfiguration?.설명;

  return (
    <div
      style={{
        textAlign: "left",
        fontWeight: "bold",
        paddingBottom: 20,
        paddingTop: 30,
        fontSize: 26,
        color: GRAY_DARK,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column"
      }}
      onClick={() => {
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, hashtag)
        );
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <HashtagPill
          hashtag={hashtag}
          style={{ fontSize: 16, marginLeft: 5 }}
        />

        <div style={{ display: "flex", alignItems: "center", marginRight: 5 }}>
          <ChatButtonLight cardId={hashtag} />
          {useIsAdmin() && (
            <div style={{ paddingLeft: 10 }}>
              <HashtagFollowButton cardId={hashtag} />
            </div>
          )}
        </div>
      </div>
      {card?.hashtagMetadata?.photos?.[0] &&
        card?.subType !== CardSubType.HASHTAG_PERSON && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              padding: 20,
              paddingBottom: 0
            }}
          >
            <img
              src={card?.hashtagMetadata?.photos?.[0]}
              style={{ maxHeight: 200 }}
              alt="TodayHashtagCard"
            />
          </div>
        )}
      {description && (
        <div
          style={{
            marginTop: 20,
            textAlign: "left",
            marginLeft: 10,
            marginRight: 10,
            fontWeight: "normal",
            fontSize: 16,
            lineHeight: "170%",
            color: "#808080",
            cursor: "pointer"
          }}
        >
          <OXOText text={hashtagTextAdapter(description.split("\n")[0])} />
        </div>
      )}
    </div>
  );
}
