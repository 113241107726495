import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CommentSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import CommentLine from "../../topic/Question/Comments/CommentLine";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import UserName from "./UserName";

const COMMENTS_PER_PAGE = 10;
export default function UserProfileCommentsTimeline({
  profileUserId
}: {
  profileUserId: string;
}) {
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [comments, setComments] = React.useState<CommentSchema[]>();
  useEffect(() => {
    setIsLoading(true);

    firebase
      .firestore()
      .collection(FirestoreCollection.COMMENTS)
      .where("userId", "==", profileUserId)
      .orderBy("createdAt", "desc")
      .limit(COMMENTS_PER_PAGE * page)
      .get()
      .then(getDocsFromSnapshot)
      .then(setComments)
      .then(() => setIsLoading(false));
  }, [page]);

  if (!comments) {
    return (
      <div style={{ padding: "20px" }}>
        <DocumentSkeleton />
      </div>
    );
  }

  if (comments?.length === 0) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "30px",
            paddingBottom: "30px"
          }}
        >
          {/* <imgs src={ox} width={100} alt="ox" /> */}
          <br />
          <div style={{ display: "flex" }}>
            <span style={{ fontWeight: "bolder" }}>
              <UserName profileUserId={profileUserId} />
            </span>
            <span>님의</span>
          </div>
          <div>댓글을 기다리고 있어요.</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "white"
      }}
    >
      {(comments && (
        <div
          style={{
            marginTop: "5px",
            width: "100%",
            minHeight: 40,
            position: "relative"
          }}
        >
          {comments.slice(0, page * COMMENTS_PER_PAGE).map((comment, i) => (
            <div
              key={comment.id}
              style={{ margin: "14px 0", padding: "0 5px" }}
            >
              <CommentLine commentId={comment.id} disableExpressions={true} />
            </div>
          ))}

          <OXOInfiniteScroll setNextPage={() => setPage(prev => prev + 1)} />
        </div>
      )) || (
        <div style={{ padding: "40px" }}>
          <DocumentSkeleton />
        </div>
      )}
    </div>
  );
}
