import React from "react";

export default function TwitterButton({
  color,
  width = 27,
  height = 26
}: {
  color: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2436 25.9999C20.4259 25.9999 26.2436 20.1823 26.2436 13C26.2436 5.81766 20.402 0 13.2197 0C6.03739 0 0.219727 5.81766 0.219727 13C0.219727 20.1823 6.03739 26.0239 13.2436 25.9999ZM19.7797 10.9409C19.7797 15.0827 16.6195 19.8709 10.8498 19.8709C9.07812 19.8709 7.42619 19.3442 6.01367 18.5063C6.25308 18.5302 6.49249 18.5542 6.75584 18.5542C8.24018 18.5542 9.58088 18.0514 10.6582 17.2135C9.29359 17.1895 8.14442 16.2798 7.73742 15.0348C7.92895 15.0588 8.14442 15.0827 8.33595 15.0827C8.62324 15.0827 8.91053 15.0348 9.17388 14.963C7.73742 14.6757 6.66008 13.4069 6.66008 11.8986V11.8507C7.06708 12.0662 7.56984 12.2098 8.0726 12.2337C7.23466 11.6831 6.68402 10.7015 6.68402 9.62417C6.68402 9.04959 6.85161 8.49895 7.11496 8.04407C8.67112 9.9354 10.9695 11.2043 13.579 11.324C13.5311 11.0846 13.5072 10.8452 13.5072 10.6058C13.5072 8.882 14.9197 7.46948 16.6435 7.46948C17.5532 7.46948 18.3672 7.85254 18.9418 8.45106C19.66 8.30742 20.3304 8.04407 20.9289 7.68495C20.6895 8.40318 20.1867 9.02565 19.5403 9.4087C20.1628 9.31294 20.7853 9.14535 21.3359 8.90594C20.9289 9.52841 20.4022 10.103 19.7797 10.5339V10.9409Z"
        fill={color}
      />
    </svg>
  );
}
