import { MenuItem } from "@material-ui/core";
import { BlockOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { blockOrUnblockUser } from "../../../lib/users_lib";
import { checkAndRedirectForLogin, parseDoc } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";

export default function UserBlockButton({
  reportedUserId,
  onClose
}: {
  reportedUserId: string;
  onClose: () => void;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [referenceUserMetadata, setReferenceUserMetadata] =
    useState<UserProfileMetadataSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.USER_PROFILE_METADATA)
      .doc(reportedUserId)
      .get()
      .then(parseDoc)
      .then(setReferenceUserMetadata);
  }, [reportedUserId]);

  const blockedUsers = currentUserMetadata?.blockedUsers || [];
  const alreadyBlockedReportedUser = blockedUsers.includes(reportedUserId);

  return (
    <MenuItem
      key={"report"}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: 14
      }}
      onClick={() => {
        if (currentUserId && currentUserMetadata) {
          blockOrUnblockUser(
            currentUserMetadata,
            referenceUserMetadata,
            reportedUserId
          );
        } else {
          if (
            !checkAndRedirectForLogin(
              history,
              currentUserId,
              currentUserMetadata
            )
          ) {
            return;
          }
        }
        onClose();
      }}
    >
      <div style={{ marginRight: 5 }}>
        <BlockOutlined />
      </div>{" "}
      <div style={{ paddingBottom: 7 }}>
        사용자 차단{alreadyBlockedReportedUser && " 해제"}
      </div>
    </MenuItem>
  );
}
