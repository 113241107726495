import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getPartySmallLogo, parseDoc } from "../../../../utils/utils";
import {
  ElectionInfoConfiguration,
  parseYamlDescription,
  ProfileConfiguration
} from "../common/hashpage_shared";

export default function HashPageElectionInfo({
  electionInfo,
  hashtag
}: {
  electionInfo: ElectionInfoConfiguration;
  hashtag: string;
}) {
  const [card, setCard] = useState<CardSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then(setCard);
  }, []);

  if (!electionInfo) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%"
      }}
    >
      {card && (
        <img
          alt={
            parseYamlDescription<ProfileConfiguration>(
              card.hashtagMetadata?.description
            ).정당 || ""
          }
          src={getPartySmallLogo(
            parseYamlDescription<ProfileConfiguration>(
              card.hashtagMetadata?.description
            ).정당 || ""
          )}
          style={{ width: 35, position: "relative", left: 45, top: 14 }}
        />
      )}

      {electionInfo && (
        <div style={{ fontSize: 18, fontWeight: "bold" }}>
          기호 {Object.values(electionInfo)[0]?.기호 || ""}번
        </div>
      )}
    </div>
  );
}
