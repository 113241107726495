export const OXO_GRAY50 = "#ffffff";
export const OXO_GRAY100 = "#f5f5f5";
export const OXO_GRAY200 = "#F2F1EE";
export const OXO_GRAY300 = "#e0e0e0";
export const OXO_GRAY400 = "#b0b0b0";
export const OXO_GRAY500 = "#757575";
export const OXO_GRAY600 = "#616161";
export const OXO_GRAY700 = "#2f2f2f";
export const OXO_GRAY800 = "#1F1F1F";
export const OXO_GRAY900 = "#121212";

export const UNSELECTED_COLOR = "#8E8E8E";
export const UNSELECTED_COLOR_LIGHT = "#E5E5E5";

export const OXO_GREEN = "#519B7A";
export const OXO_RED = "#FB7B77";

export const SCAFFOLD_BACKGROUND_COLOR = "#ffffff";
export const BACKGROUND_COLOR = "#f5f5f5";
export const DIVIDER_COLOR = "#F2F1EE";
export const DIVIDER_COLOR_LIGHT = "#E6E6E6";
export const PRIMARY_COLOR_LIGHT = "#b0b0b0";
export const PRIMARY_COLOR = "#616161";
export const PRIMARY_COLOR_DARK = "#2f2f2f";
export const ACCENT_COLOR = "#121212";

export const OXO_QUIZ_ORDER_NUMBER = "#69CB67";
export const OXO_QUIZ_ANSWER_RED = "#EE6C5A";
export const ANSWER_O_BACKGROUND = "#E2EFEB";
export const ANSWER_O_STROKE = "#429cd9";
export const ANSWER_DUNNO_BACKGROUND = "#FFF9EA";
export const ANSWER_DUNNO_STROKE = "#FBCD57";
export const ANSWER_X_BACKGROUND = "#F7E4E5";
export const ANSWER_X_STROKE = "#FB7B77";

export const ADMIN_COLOR = "#0369FB";
export const ADMIN_COLOR_LIGHT = "#D5E1FC";

export enum THEME_COLOR {
  RED = "RED",
  ORANGE = "ORANGE",
  MUSTARD = "MUSTARD",
  GREEN = "GREEN",
  MINT = "MINT",
  BLUE = "BLUE",
  DUST_BLUE = "DUST_BLUE",
  PURPLE = "PURPLE",
  PINK = "PINK",
  BROWN = "BROWN",
  LIGHT_GRAY = "LIGHT_GRAY",
  DARK_GRAY = "DARK_GRAY"
}

export const THEME_COLOR_PALETTE: {
  [colorName: string]: {
    mainColor: string;
    subColor: string;
  };
} = {
  RED: {
    mainColor: "#C2403D",
    subColor: "#FEECEB"
  },
  ORANGE: {
    mainColor: "#E48F05",
    subColor: "#FFF0DE"
  },
  MUSTARD: {
    mainColor: "#BAAE44",
    subColor: "#F9F7E4"
  },
  GREEN: {
    mainColor: "#91A401",
    subColor: "#F6F9DB"
  },
  MINT: {
    mainColor: "#40A38B",
    subColor: "#E9FAF6"
  },
  BLUE: {
    mainColor: "#2D8BB2",
    subColor: "#E9F9FF"
  },
  DUST_BLUE: {
    mainColor: "#6087C2",
    subColor: "#EEF5FF"
  },
  PURPLE: {
    mainColor: "#8D39A8",
    subColor: "#F9EFFF"
  },
  PINK: {
    mainColor: "#D16F92",
    subColor: "#FBEFF3"
  },
  BROWN: {
    mainColor: "#976744",
    subColor: "#F6F1EC"
  },
  LIGHT_GRAY: {
    mainColor: "#B0B0B0",
    subColor: "#F5F5F5"
  },
  DARK_GRAY: {
    mainColor: "#616161",
    subColor: "#F5F5F5"
  }
};

export const blue = "#0F4C83";
export const darkblue = "#002D56";
export const lightblue = "#EDF2F8";
export const skyblue = "#BED8F8";
export const grayblue = "#ADBFD4";
export const gray = "#77787B";
export const gray50 = "#DDDFE5";
export const gray60 = "#9e9e9e";
export const gray70 = "#F7F8FA";
export const pink = "#FA4275";
export const lightpink = "#FACBD8";
export const primary = "#fddc00";
export const primary50 = "#FDEC7E";
export const primary60 = "#FDE864";
export const primary70 = "#FDE54C";
export const secondary = "#9e9e9e";
export const secondary50 = "#DDDFE5";
export const secondary60 = "#F7F8FA";
export const secondary70 = "#F7F8FA";
export const backgroundColor = "#eee";
export const answerO = "#AADAA5";
export const answerX = "#FE8A86";

export default {
  primary,
  primary50,
  primary60,
  primary70,
  secondary,
  secondary50,
  secondary60,
  secondary70,
  blue,
  darkblue,
  lightblue,
  skyblue,
  gray,
  gray50,
  gray60,
  gray70,
  grayblue,
  pink,
  lightpink,
  backgroundColor,
  answerO,
  answerX
};
