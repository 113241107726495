/** @jsxImportSource @emotion/react */

import firebase from "firebase/compat";
import React, { CSSProperties, useEffect, useState } from "react";
import { GRAY_DARK } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc } from "../../../../utils/utils";
import MiddleDot from "../../../foundation/common/MiddleDot";
import { css } from "@emotion/react";

export default function SpeakerPosition({
  cardId,
  speaker,
  addMiddleDotIfPresent,
  style = {},
  useSummaryStyle = false
}: {
  cardId?: string;
  speaker: string;
  addMiddleDotIfPresent?: boolean;
  style?: CSSProperties;
  useSummaryStyle?: boolean;
}) {
  if (speaker === "") {
    console.log(cardId);
  }
  const [position, setPosition] = useState<string>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(speaker)
      .get()
      .then(snapshot => {
        const data: CardSchema = parseDoc(snapshot);
        data.abbreviation
          ? setPosition(data.abbreviation)
          : setPosition(speaker);
        data.hashtags && data.hashtags.length
          ? setPosition(data.hashtags?.[0])
          : setPosition("");
      });
  }, []);

  const filteredPosition = position?.replace("#", "").replace(/_/gi, " ");

  return useSummaryStyle ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: GRAY_DARK,
        fontSize: 12,
        fontWeight: "normal",
        ...style
      }}
    >
      {position && (
        <div>
          <div>
            {filteredPosition && filteredPosition.length < 8 ? (
              <div
                css={css`
                  display: flex;
                  position: relative;
                `}
              >
                <div
                  css={css`
                    margin: 0 2px 0 2px;
                  `}
                >
                  |
                </div>
                {filteredPosition}
              </div>
            ) : (
              <div css={css``}>
                <br />
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    word-break: keep-all;
                    position: relative;
                    width: 100%;
                  `}
                >
                  <div>{filteredPosition}</div>
                </div>
              </div>
            )}

            {position && addMiddleDotIfPresent && <MiddleDot />}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: GRAY_DARK,
        fontSize: 12,
        fontWeight: "normal",
        ...style
      }}
    >
      {position && (
        <div>{`${position?.replace("#", "").replace(/_/gi, " ")}`}</div>
      )}
      {position && addMiddleDotIfPresent && <MiddleDot />}
    </div>
  );
}
