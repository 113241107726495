import { css } from "@emotion/react";

export const DISPLAY_FLEX = css`
  display: flex;
`;
export const DISPLAY_FLEX_COLUMN = css`
  display: flex;
  flex-direction: column;
`;

export const WIDTH100 = css`
  width: 100%;
`;
