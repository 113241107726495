import { Avatar } from "@material-ui/core";
import React from "react";
import { CardSchema } from "../../../constants/firestore_schema";
import { getPartySmallLogo } from "../../../utils/utils";
import {
  parseYamlDescription,
  PartyConfiguration
} from "./common/hashpage_shared";

export default function HashPagePhoto({
  card,
  size = 300,
  withBadge = false
}: {
  card: CardSchema;
  size?: number;
  withBadge?: boolean;
}) {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: size,
        maxHeight: 300
      }}
    >
      {card?.hashtagMetadata?.photos?.[1] ? (
        <img
          src={card?.hashtagMetadata?.photos?.[1]}
          style={{ width: size, maxHeight: size }}
          alt="hashPhoto"
        />
      ) : (
        <Avatar src="/broken-image.jpg" style={{ width: size, height: size }} />
      )}

      {withBadge && (
        <div
          style={{
            position: "absolute",
            left: 0,
            width: 25,
            height: 25,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src={getPartySmallLogo(
              parseYamlDescription<PartyConfiguration>(
                card.hashtagMetadata?.description
              )?.정당 || "무소속"
            )}
            style={{ width: 25 }}
            alt="partyLogo"
          />
        </div>
      )}
    </div>
  );
}
