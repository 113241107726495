import firebase from "firebase/compat";
import queryString from "querystring";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AnswerSymbol,
  GRAY,
  oxoColors,
  UserAnswer
} from "../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  CommentSchema,
  FirestoreCollection,
  MapCoordinatesSchema,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema,
  UserVectorSchema
} from "../../../constants/firestore_schema";
import { addAnswer } from "../../../lib/answer_lib";
import { addCardViewCount } from "../../../lib/card_lib";
import {
  checkAndRedirectForLogin,
  getDocsFromSnapshot,
  parseDoc,
  removeIdKey
} from "../../../utils/utils";
import UserMap, {
  SeriesType
} from "../../foundation/Charts/UserMap/UserMap-victory";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import CommentTabs from "../../topic/Question/Comments/CommentTabs";
import AnswerDunno from "../../foundation/OXOButton/AnswerDunno";
import AnswerO from "../../foundation/OXOButton/AnswerO";
import AnswerX from "../../foundation/OXOButton/AnswerX";
import { OxFlatButtons } from "../../foundation/OXOButton/OxFlatButtons";
import QuestionChart from "../../topic/Question/QuestionChart/QuestionChart";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import { AlgoliaContext } from "../../views/SearchView/AlgoliaContext";
import { getUserName } from "./UserName";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";

export default function RealNameUserDetails({
  userId,
  userProfileMetadata
}: {
  userId: string;
  userProfileMetadata: UserProfileMetadataSchema;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [scrolled, setScrolled] = useState<boolean>(false);

  const location = useLocation();
  const urlSearchParams = queryString.parse(location.search.replace("?", ""));

  const commentRef = useRef<HTMLDivElement>(null);

  function scrollToCommentEvery100ms() {
    window.setTimeout(() => {
      if (commentRef.current) {
        if (!scrolled) {
          commentRef.current.scrollIntoView({ behavior: "smooth" });
          setScrolled(true);
        }
      } else {
        scrollToCommentEvery100ms();
      }
    }, 200);
  }

  useEffect(() => {
    if (urlSearchParams["scrollToComment"]) {
      scrollToCommentEvery100ms();
    }
    return () => {};
  }, [commentRef.current, urlSearchParams["scrollToComment"]]);

  const updateVote = (answer: string) => {
    if (
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!answer || !currentUserMetadata || !currentUserId) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    setRequestedAnswer(answer);

    const answerData: UserVectorSchema = {};
    answerData[userId] = answer;

    addAnswer(userId, currentUserId, currentUserMetadata, answer);
  };

  const algoliaClient = useContext(AlgoliaContext);

  const [comments, setComments] = useState<CommentSchema[]>();
  const history = useHistory();

  const currentUserMetadata = dataProps?.currentUserMetadata;

  useEffect(() => {
    if (userId) {
      const commentsSubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .where("questionId", "==", userId)
        .onSnapshot(snapshot => setComments(getDocsFromSnapshot(snapshot)));
      return () => commentsSubscribe();
    }
  }, [userId]);

  const [questionAnswers, setQuestionAnswers] =
    React.useState<QuestionAnswerMapSchema>();

  useEffect(() => {
    setQuestionAnswers(undefined);
    setComments(undefined);
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
        .doc(userId)
        .onSnapshot(snapshot => {
          setQuestionAnswers(removeIdKey(parseDoc(snapshot)));
        });
      return () => unsubscribe();
    }
  }, [userId]);

  useEffect(() => {
    addCardViewCount(userId, currentUserId);
  }, []);

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    if (userId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(userId)
        .get()
        .then(snapshot => {
          const card = parseDoc(snapshot) as CardSchema;
          if (card.type === CardType.REAL_NAME_USER_SUPPORTING_CARD) {
            setCard(card);
          } else {
            const card = {
              type: CardType.REAL_NAME_USER_SUPPORTING_CARD,
              title: getUserName(userProfileMetadata, userId),
              createdAt: new Date().valueOf(),
              createdBy: userId,
              createdByTribeId: userProfileMetadata.tribeId,
              displayToUsers: false,
              displayInOnboarding: false,
              views: 0,
              answers: 0,
              comments: 0,
              cardScore: 0
            } as CardSchema;

            firebase
              .firestore()
              .collection(FirestoreCollection.CARDS)
              .doc(userId)
              .set(card, { merge: true })
              .then(() => {
                setCard(card);
              });
          }
        });
    }
  }, [userId]);

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + userId
  );
  const answer = cardAnswer?.answer;

  const [requestedAnswer, setRequestedAnswer] = useState(answer);

  if (
    !dataProps?.mapCoordinates ||
    !card ||
    (currentUserId && !currentUserMetadata)
  ) {
    return null;
  }
  const currentUserTribeId = currentUserMetadata?.tribeId || "1";

  let series = undefined;

  if (questionAnswers) {
    series = getMapSeries(
      questionAnswers,
      dataProps.mapCoordinates,
      currentUserMetadata
    );
  }

  const answerCount =
    questionAnswers &&
    Object.values(questionAnswers).reduce(
      (sum, questionAnswer) =>
        sum +
        questionAnswer.countX +
        questionAnswer.countO +
        questionAnswer.countDunno,
      0
    );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start"
        }}
      >
        <OxFlatButtons onVote={updateVote} answer={requestedAnswer || answer} />
      </div>

      <div>
        <div
          style={{
            marginTop: "30px",
            fontWeight: "bold",
            fontSize: "16px",
            marginBottom: "20px"
          }}
        >
          지지하는 분들의 정치지형도
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              width: "100px",
              marginTop: "10px",
              justifyContent: "space-around"
            }}
          >
            <AnswerO color={oxoColors.O} width={20} />
            <AnswerDunno color={oxoColors.DUNNO} width={20} />
            <AnswerX color={oxoColors.X} width={20} />
          </div>
        </div>

        <div style={{}}>
          {series ? (
            <div style={{ marginTop: "10px" }}>
              <UserMap series={series} />
              <div style={{ marginTop: "20px" }}>
                <QuestionChart cardId={card.id} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 7%",
                  color: GRAY
                }}
              >
                <div>
                  <div
                    style={{
                      textAlign: "right",
                      color: GRAY
                    }}
                  >
                    응답 {answerCount || 0}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ margin: "50px" }}>
              <DocumentSkeleton />
            </div>
          )}
        </div>

        {/*<div*/}
        {/*  style={{*/}
        {/*    paddingBottom: "20px",*/}
        {/*    paddingTop: "10px",*/}
        {/*    width: "100%",*/}
        {/*    borderBottom: "solid 1px " + BACKGROUND_COLOR,*/}
        {/*    fontSize: "16px",*/}
        {/*    textAlign: "center",*/}
        {/*    backgroundColor: "white"*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <UserLikeStats userId={userId} />*/}
        {/*</div>*/}
      </div>
      <div>
        <div style={{ fontWeight: "bold", paddingTop: 40, paddingBottom: 10 }}>
          부족들의 의견
        </div>
        <div style={{}} ref={commentRef}>
          {currentUserTribeId && comments ? (
            <CommentTabs
              comments={comments}
              currentTribeId={currentUserTribeId}
              cardId={userId}
            />
          ) : (
            <div style={{ padding: "16px", height: "450px" }}>
              <DocumentSkeleton />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function getMapSeries(
  questionAnswers: QuestionAnswerMapSchema,
  mapCoordinates: MapCoordinatesSchema,
  userProfileMetadata: UserProfileMetadataSchema | undefined
) {
  const seriesData: { [seriesName: string]: number[][] } = {
    me: [],
    unanswered: []
  };

  const mapWidth = Math.min(window.screen.width, 750);

  seriesData[UserAnswer.O] = [];
  seriesData[UserAnswer.X] = [];
  seriesData[UserAnswer.DUNNO] = [];

  Object.keys(questionAnswers).forEach(coordinateId => {
    const arr = [
      questionAnswers[coordinateId].countO,
      questionAnswers[coordinateId].countX,
      questionAnswers[coordinateId].countDunno
    ];
    const i = arr.indexOf(Math.max(...arr));

    if (i === 0) {
      seriesData[UserAnswer.O].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 1) {
      seriesData[UserAnswer.X].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 2) {
      seriesData[UserAnswer.DUNNO].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    }
  });

  const defaultSize = mapWidth / 350;

  Object.keys(mapCoordinates).map(coordinateId => {
    const mapCoordinate = mapCoordinates[coordinateId];
    seriesData["unanswered"].push([mapCoordinate.x, mapCoordinate.y]);
  });

  const series: SeriesType[] = [
    {
      name: AnswerSymbol.O,
      data: seriesData[UserAnswer.O] || [],
      color: oxoColors.O,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.DUNNO,
      data: seriesData[UserAnswer.DUNNO] || [],
      color: oxoColors.DUNNO,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.X,
      data: seriesData[UserAnswer.X] || [],
      color: oxoColors.X,
      size: defaultSize + 1
    },
    {
      name: "응답 안 함",
      data: seriesData["unanswered"] || [],
      color: oxoColors.OTHERS,
      size: defaultSize,
      opacity: 0.4
    }
  ];

  if (userProfileMetadata) {
    series.unshift({
      name: "나",
      data: [[userProfileMetadata.x, userProfileMetadata.y]],
      color: "#333333",
      label: "나",
      size: 4
    });
  }

  return series;
}
