import { IconButton, Snackbar } from "@material-ui/core";
import { useIsApp } from "../../../utils/hooks/useIsApp";
import { useIsAndroid } from "../../../utils/hooks/useIsAndroid";
import KakaoTalkButton from "../../views/HashCardView/StaceTest/imgs/KakaoTalkButton";
import TwitterButton from "../../views/HashCardView/StaceTest/imgs/TwitterButton";
import FacebookButton from "../../views/HashCardView/StaceTest/imgs/FacebookButton";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/compat";

import React, { useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import UrlLinkButton from "../../views/HashCardView/StaceTest/imgs/UrlLinkButton";

export default function ShareButton({
  cardId,
  facebookTitle,
  twitterTitle,
  thumbnailUrl
}: {
  cardId: string;
  facebookTitle: string;
  twitterTitle: string;
  thumbnailUrl: string;
}) {
  const isApp = useIsApp();
  const isAndroid = useIsAndroid();
  const linkUrl = `https://oxopolitics.com/card/${encodeURI(cardId)}`;
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const color = "#000000";

  /* Safari 에서 FacebookShareButton, TwitterShareButton 사용시
  CSS가 틀어지는 경우가 있어서 버튼을 이미지로 따로 빼고 이미지 클릭시 함수처리*/
  const share = (id: string) => {
    const elem = document.getElementById(id);
    if (elem) elem.click();
  };

  return (
    <div
      style={{
        textAlign: "right",
        display: "flex",
        alignItems: "flex-end",
        fontSize: "18px"
      }}
    >
      <FacebookShareButton id="facebook" url={linkUrl}>
        <></>
      </FacebookShareButton>
      <TwitterShareButton
        id="twitter"
        url={linkUrl}
        title={`[OXOpolitics] ${twitterTitle}`}
      >
        <></>
      </TwitterShareButton>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {isApp ? (
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_copy_link", {
              event_category: "card_detail"
            });
            setSnackbarMessage("링크가 복사되었습니다. 붙여넣기 해 보세요.");
            const win = window as any;
            const json_msg =
              '{"action":"write_clipboard","text":"' + linkUrl + '"}';

            isAndroid
              ? win.send_to_app("Android", json_msg)
              : win.send_to_app("iOS", json_msg);

            setOpenSnackBar(true);
          }}
          style={{
            marginRight: "7px",
            cursor: "pointer"
          }}
        >
          <UrlLinkButton color={color} />
        </div>
      ) : (
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_copy_link", {
              event_category: "card_detail"
            });
            setSnackbarMessage("링크가 복사되었습니다. 붙여넣기 해보세요.");
            navigator.clipboard.writeText(linkUrl).then(value => {
              setOpenSnackBar(true);
            });
          }}
          style={{
            marginRight: "7px",
            cursor: "pointer"
          }}
        >
          <UrlLinkButton color={color} />
        </div>
      )}
      <div style={{ marginRight: "7px", cursor: "pointer" }}>
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_share_kakao", {
              event_category: "hashcard"
            });

            const win = window as any;
            win.kakaotalk_share(twitterTitle, linkUrl, thumbnailUrl);
          }}
        >
          <KakaoTalkButton color={color} />
        </div>
      </div>
      <div
        style={{ marginRight: "7px", cursor: "pointer" }}
        onClick={() => {
          share("facebook");
          firebase.analytics().logEvent("v4_click_share_facebook", {
            event_category: "hashcard"
          });
        }}
      >
        <FacebookButton color={color} />
      </div>
      <div
        style={{ marginRight: "10px", cursor: "pointer" }}
        onClick={() => {
          share("twitter");
          firebase.analytics().logEvent("v4_click_share_twitter", {
            event_category: "hashcard"
          });
        }}
      >
        <TwitterButton color={color} />
      </div>
    </div>
  );
}
