import firebase from "firebase/compat";
import React, { useEffect } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc } from "../../../../utils/utils";
import { HashtagConfiguration, parseYamlDescription } from "./hashpage_shared";

const HashPageAbbreviation = ({
  hashtag,
  abbreviation
}: {
  hashtag: string;
  abbreviation: string;
}) => {
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(data => {
        const hashCard: CardSchema = parseDoc(data);
        const hashYamlData: HashtagConfiguration = parseYamlDescription(
          hashCard.hashtagMetadata?.description
        );
        if (hashYamlData.별칭) {
          if (hashYamlData.별칭 !== abbreviation) {
            const data = {
              abbreviation
            } as CardSchema;

            firebase
              .firestore()
              .collection(FirestoreCollection.CARDS)
              .doc(hashtag)
              .set(data, { merge: true })
              .then(() => {
                alert("별칭이 변경되었습니다.");
              });
          }
        } else {
          const data = {
            abbreviation
          } as CardSchema;

          firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .doc(hashtag)
            .set(data, { merge: true })
            .then(() => {
              alert("별칭이 추가되었습니다.");
            });
        }
      });
  }, [abbreviation]);

  return <></>;
};

export default HashPageAbbreviation;
