import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getDocsFromSnapshot, parseDoc } from "../../../../utils/utils";
import HashtagPill from "../common/HashtagPill";

const HASHTAG_COUNT_LIMIT = 25;

export default function HashCardHierarchy({ hashtag }: { hashtag: string }) {
  const history = useHistory();

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then(setCard);
  }, []);

  const [cards, setCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.HASHTAG_CARD)
      .where("hashtags", "array-contains", "#" + hashtag)
      .orderBy("publishedAt", "desc")
      .get()
      .then(getDocsFromSnapshot)
      .then(setCards);
  }, []);

  const higherHashtags = card?.hashtags?.map(hashtag =>
    hashtag.replace("#", "")
  );
  const lowerHashtags = cards?.map(card => card.id);

  if (!higherHashtags || !lowerHashtags) {
    return null;
  }

  if (higherHashtags.length === 0 && lowerHashtags.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        padding: "26px 30px 26px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div>
        {higherHashtags && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              maxWidth: 400
            }}
          >
            {higherHashtags.map(relatedHashtag => {
              return (
                <HashtagPill
                  key={relatedHashtag}
                  hashtag={relatedHashtag}
                  style={{ marginBottom: 5 }}
                />
              );
            })}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 400
          }}
        >
          {higherHashtags && higherHashtags.length > 0 && (
            <div style={{ marginBottom: 3 }}>|</div>
          )}
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold"
            }}
          >
            <HashtagPill
              hashtag={hashtag}
              onClick={() => {}}
              style={{
                cursor: "default",
                marginBottom: 5,
                padding: "6px 15px 4px",
                fontSize: 16,
                color: "white",
                border: "0px",
                backgroundColor: "gray"
              }}
            />
          </div>
          {lowerHashtags && lowerHashtags.length > 0 && (
            <div style={{ marginBottom: 3 }}>|</div>
          )}
        </div>

        {lowerHashtags && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              maxWidth: 400
            }}
          >
            {lowerHashtags.map(relatedHashtag => {
              return (
                <HashtagPill
                  key={relatedHashtag}
                  hashtag={relatedHashtag}
                  style={{ marginBottom: 5 }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
