import React from "react";

import { ApexOptions } from "apexcharts";

import ReactApexChart from "react-apexcharts";
import {
  ANSWER_O_STROKE,
  ANSWER_DUNNO_STROKE,
  ANSWER_X_STROKE,
  ACCENT_COLOR
} from "../../../constants/colors";

import { AnswerCountSchema } from "../../../constants/firestore_schema";
import { apexChartBasicOptions } from "./fixtures";

export default function OxoTribePieChart({
  stats,
  colorScale = [ANSWER_X_STROKE, ANSWER_DUNNO_STROKE, ANSWER_O_STROKE]
}: {
  stats: AnswerCountSchema;
  colorScale?: string[];
}) {
  if (!stats) {
    return null;
  }

  const data = [stats.o || 0, stats["?"] || 0, stats.x || 0];

  const options: ApexOptions = {
    ...apexChartBasicOptions,
    series: data.reverse(),
    states: {
      active: {
        filter: {
          type: "none"
        }
      }
    },
    labels: ["반대", "중립", "찬성"],
    chart: {
      type: "pie",
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "15",
        fontFamily: "SUIT",
        fontWeight: 600,
        colors: ["black"]
      },
      dropShadow: {
        enabled: false
      },
      formatter: val => {
        return (+val).toFixed(1);
      }
    },
    colors: colorScale,
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "20"
        }
      }
    },
    stroke: {
      width: 10,
      colors: ["#f9f9f9"]
    }
  };

  return (
    <ReactApexChart
      options={options}
      series={data}
      type="pie"
      width={320}
      height={320}
    />
  );
}
