/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import {
  CommentSchema,
  FirestoreCollection,
  LikeCountsSchema
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  listToMap,
  parseDoc
} from "../../../../utils/utils";
import { css } from "@emotion/react";
import SingleLineCommentForPortrait from "../../../views/HashCardView/Comment/SingleLineCommentForPortrait";
import { DATA_PLATFORM_RED_GRAY } from "../../../../constants/data_platform_colors";

export default function TribeCommentsForPortrait({
  cardId,
  length,
  disableExpressions,
  truncate = 100
}: {
  cardId: string;
  length: number;
  disableExpressions: boolean;
  truncate?: number;
}) {
  const [page, setPage] = useState(1);
  const [comments, setComments] = useState<CommentSchema[]>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.COMMENTS)
      .where("questionId", "==", cardId)
      .onSnapshot(snapshot => {
        setComments(getDocsFromSnapshot(snapshot));
      });
    return () => {
      unsubscriber();
    };
  }, [cardId]);

  const [likeCounts, setLikeCounts] =
    useState<{ [commentId: string]: LikeCountsSchema }>();
  useEffect(() => {
    if (!comments) {
      return;
    }
    const promises = comments.map(comment =>
      firebase
        .firestore()
        .collection(FirestoreCollection.LIKE_COUNTS)
        .doc(comment.id)
        .get()
        .then(parseDoc)
    );

    Promise.all(promises).then((likeCounts: LikeCountsSchema[]) => {
      setLikeCounts(
        listToMap(
          likeCounts,
          likeCount => likeCount.referenceId,
          likeCount => likeCount
        )
      );
    });
  }, [comments]);

  const sortedComments = useMemo(() => {
    if (!comments || !likeCounts) {
      return;
    }
    return comments
      .sort((ca, cb) => cb.createdAt - ca.createdAt)
      .sort((ca, cb) => {
        return cb.id && ca.id
          ? (likeCounts[cb.id]?.likeCounts || 0) -
              (likeCounts[ca.id]?.likeCounts || 0)
          : 0;
      });
  }, [comments, likeCounts]);

  if (!sortedComments) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding-bottom: 100;
        `}
      >
        {sortedComments.length > 0 ? (
          <div>
            {sortedComments.slice(0, length).map(comment => {
              return (
                <div
                  css={css`
                    margin-top: 8px;
                  `}
                  key={comment.id}
                >
                  {comment.id && (
                    <SingleLineCommentForPortrait
                      commentId={comment.id}
                      truncate={truncate}
                    />
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div
            css={css`
              font-size: 16px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
              line-height: 160%;
              margin-top: 14px;
              margin-left: 4px;
            `}
          >
            아직 등록된 댓글이 없습니다.
          </div>
        )}
      </div>
    </div>
  );
}
