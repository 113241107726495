/** @jsxImportSource @emotion/react */
import React from "react";
import { useHistory } from "react-router";
import image from "./miniOxo.png";

import { addToCardIdStackUrl } from "../../../utils/utils";

export default function MiniGenderConflictBanner() {
  const hashtag = "젠더갈등조사";

  const history = useHistory();
  const pushUrl: string =
    history.location.pathname +
    addToCardIdStackUrl(history.location.search, `TEST_${hashtag}`);

  return (
    <div
      onClick={() => {
        history.push(pushUrl);
      }}
      css={{
        marginTop: 30,
        height: 70,
        width: "100%",
        padding: 20,
        borderRadius: 12,
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#004B8E",
        alignItems: "center"
      }}
    >
      <div
        css={{
          color: "white",
          wordBreak: "keep-all",
          textAlign: "left",
          cursor: "pointer",
          minWidth: 230,
          fontSize: 17,
          fontWeight: "bold"
        }}
      >
        <div>{"우리 사회에"}</div>
        <div css={{ marginLeft: -1 }}>{"젠더 갈등이란?"}</div>
      </div>

      <div css={{ verticalAlign: "bottom", marginBottom: -5 }}>
        <img
          src={image}
          style={{ width: 65, verticalAlign: "bottom" }}
          alt="banner"
        />
      </div>
    </div>
  );
}
