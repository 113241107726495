import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PRIMARY_RED } from "../../../../constants/enums";
import {
  FirestoreCollection,
  UserLastSeenItemSchema
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot,
  parseDoc
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import chat_selected from "../../../foundation/Navigation/icons/chat_selected.svg";
import OXOButton from "../../../foundation/OXOButton/OXOButton";

export default function ChatButton({
  cardId,
  title,
  photoUrl,
  authorTribeId
}: {
  cardId: string;
  title?: string;
  photoUrl?: string;
  authorTribeId?: string;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [lastSeenChat, setLastSeenChat] = useState<UserLastSeenItemSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.USER_LAST_SEEN_ITEMS)
      .doc(currentUserId + cardId)
      .get()
      .then(parseDoc)
      .then(setLastSeenChat);
  }, [currentUserId, cardId]);

  const [chatBadgeCount, setChatBadgeCount] = useState<number>(0);
  useEffect(() => {
    if (currentUserMetadata && lastSeenChat) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.CHATS)
        .where("createdAt", ">", lastSeenChat?.lastSeenAt || 0)
        .where("chatRoomId", "==", cardId)
        .limit(1)
        .onSnapshot(snapshot => {
          setChatBadgeCount(getDocsFromSnapshot(snapshot).length);
        });
      return () => {
        unsubscribe();
      };
    }
  }, [currentUserMetadata, lastSeenChat]);

  return (
    <div>
      <OXOButton
        label={
          <div
            style={{
              display: "flex",
              paddingTop: 5,
              alignItems: "center"
            }}
          >
            <div style={{ position: "relative" }}>
              {chatBadgeCount > 0 && (
                <div
                  style={{
                    position: "absolute",
                    width: 8,
                    height: 8,
                    right: -8,
                    top: -4,
                    backgroundColor: PRIMARY_RED,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 10
                  }}
                />
              )}
              <img src={chat_selected} width={22} alt="chat_selected" />
            </div>
            <div style={{ width: 5 }} />
            <div
              style={{
                fontSize: 16,
                paddingBottom: 5,
                paddingLeft: 5,
                color: "#626262"
              }}
            >
              부족톡
            </div>
          </div>
        }
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(
                history.location.search,
                cardId ? `CHAT_${cardId}` : "CHAT_all"
              )
          );
        }}
        style={{
          marginBottom: "15px",
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 10,
          width: "100%",
          maxWidth: "730px",
          border: "solid 1px black",
          backgroundColor: "transparent"
        }}
      />
    </div>
  );
}
