import { Popover } from "@material-ui/core";
import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc } from "../../../../utils/utils";
import { HashtagConfiguration, parseYamlDescription } from "./hashpage_shared";

export default function HashtagTaggingHashtag({ text }: { text: string }) {
  const [hasgtagExplanation, setHashtagExplanation] = useState<string>();
  const [abbreviation, setAbbreviation] = useState<string>("");

  useEffect(() => {
    if (text.replace("#", "").length > 0) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(text.replace("#", ""))
        .get()
        .then(parseDoc)
        .then((snapshot: CardSchema) => {
          const hashtagYamlDescription: HashtagConfiguration =
            parseYamlDescription(snapshot.hashtagMetadata?.description);
          setHashtagExplanation(hashtagYamlDescription.설명 || "");
          setAbbreviation(hashtagYamlDescription.별칭 || "");
        });
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = (e: Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  if (text.replace("#", "").length === 0) {
    return null;
  }

  return (
    <span
      style={{
        textDecoration: "underline",
        cursor: "pointer"
      }}
      onClick={event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
      }}
    >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div
          style={{
            padding: 17,
            fontSize: 16,
            backgroundColor: "#363636",
            maxWidth: 328,
            lineHeight: "1.5em",
            fontWeight: "normal",
            color: "white"
          }}
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          {text.replace("#", "").replace(/\_/gi, " ") +
            `${hasgtagExplanation ? " : " + hasgtagExplanation : ""}`}
        </div>
      </Popover>
      {abbreviation ? abbreviation : text.replace("#", "").replace(/\_/gi, " ")}
    </span>
  );
}
