/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import { GRAY } from "../../../../constants/enums";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";
import {
  getAgreePercentage,
  getDisagreePercentage,
  getDunnoPercentage
} from "../../../../utils/data_utils";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack
} from "victory";
import { css } from "@emotion/react";

export default function CardAnswerBarChartForCardId({
  cardId,
  showPercentage = false
}: {
  cardId: string;
  showPercentage?: boolean;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);
  const colors = [
    DATA_PLATFORM_GREEN_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_RED_GRAY
  ];
  const cardData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];

  [cardAnswerStats].map(el => {
    return cardData.push({
      x: "전체",
      ao: ((el?.o || 0) / (el?.count || 1)) * 100,
      ad: ((el?.["?"] || 0) / (el?.count || 1)) * 100,
      ax: ((el?.x || 0) / (el?.count || 1)) * 100,
      count: el?.count || 0
    });
  });

  if (!cardAnswerStats || cardAnswerStats.count === 0) {
    return null;
  }

  return (
    <div>
      {showPercentage && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 490px;
            align-items: center;
          `}
        >
          <div>
            <div
              css={css`
                width: 360px;
                height: 20px;
                text-align: center;
                overflow: hidden;
                margin-bottom: 5px;
                line-height: 10px;
              `}
            >
              <VictoryChart horizontal height={10} width={360} padding={0}>
                <VictoryStack
                  domain={{ y: [0, 100] }}
                  style={{
                    data: { width: 20 },
                    labels: { fontSize: 15, fill: GRAY }
                  }}
                >
                  <VictoryBar
                    style={{
                      data: {
                        fill: colors[0]
                      }
                    }}
                    data={cardData}
                    y={data => Math.abs(data.ao)}
                  />
                  <VictoryBar
                    style={{
                      data: {
                        fill: colors[1]
                      }
                    }}
                    data={cardData}
                    y={data => Math.abs(data.ad)}
                  />
                  <VictoryBar
                    style={{
                      data: {
                        fill: colors[2]
                      }
                    }}
                    data={cardData}
                    y={data => Math.abs(data.ax)}
                  />
                </VictoryStack>

                <VictoryAxis
                  style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fill: "transparent" }
                  }}
                  tickLabelComponent={<VictoryLabel x={0} textAnchor="start" />}
                  tickValues={cardData.map(point => point.x).reverse()}
                  key={"axis1"}
                />
              </VictoryChart>
            </div>

            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  font-weight: 700;
                  text-align: center;
                  color: #bebebe;
                `}
              >
                찬성 {getAgreePercentage(cardAnswerStats).toFixed()}%
              </div>
              <div
                css={css`
                  font-size: 16px;
                  font-weight: 700;
                  text-align: center;
                  color: ${DATA_PLATFORM_YELLOW_GRAY};
                `}
              >
                중립 {getDunnoPercentage(cardAnswerStats).toFixed()}%
              </div>
              <div
                css={css`
                  font-size: 16px;
                  font-weight: 700;
                  text-align: center;
                  color: ${DATA_PLATFORM_RED_GRAY};
                  margin-left: 15px;
                `}
              >
                반대 {getDisagreePercentage(cardAnswerStats).toFixed()}%
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
