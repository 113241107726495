/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { sumTwoAnswerCounts } from "../../../utils/utils";
import { VictoryBar, VictoryGroup, VictoryStack } from "victory";
import { VictoryLabel } from "victory-core";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../constants/data_platform_colors";
import CardAnswerSupporterBarChart from "../HashCardView/Comment/CardAnswerSupporterBarChart";
import useSupporters from "../../views/DailyReportsView/useSupporters";

export default function MentionAgeChartForPortrait({
  cardId,
  isAgree,
  personHashtag,
  competitorHashtag,
  colorScale = [
    DATA_PLATFORM_RED_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_GREEN_GRAY
  ],
  MZ = true,
  average = true
}: {
  cardId: string;
  isAgree: boolean;
  personHashtag: string;
  competitorHashtag: string;
  colorScale?: [string, string, string];
  MZ?: boolean;
  average?: boolean;
}) {
  const supporterData = useSupporters(personHashtag, competitorHashtag);
  const [cardAnswerAgeGenderState] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      const ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
      if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
        return prev;
      }

      const ageGroup = ageGroupNumber.toFixed();
      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];

      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            (prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema,
            (birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState]);

  if (!statsByAgeGroup) {
    return null;
  }

  const ageData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];

  Object.keys(statsByAgeGroup).forEach(ageGroup => {
    ageData.push({
      x: ageGroup + "대",

      ao:
        (((statsByAgeGroup[ageGroup]["male"]?.o || 0) +
          (statsByAgeGroup[ageGroup]["female"]?.o || 0)) /
          ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
            (statsByAgeGroup[ageGroup]["female"]?.count || 0))) *
        100,
      ad:
        (((statsByAgeGroup[ageGroup]["male"]?.["?"] || 0) +
          (statsByAgeGroup[ageGroup]["female"]?.["?"] || 0)) /
          ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
            (statsByAgeGroup[ageGroup]["female"]?.count || 0))) *
        100,
      ax:
        (((statsByAgeGroup[ageGroup]["male"]?.x || 0) +
          (statsByAgeGroup[ageGroup]["female"]?.x || 0)) /
          ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
            (statsByAgeGroup[ageGroup]["female"]?.count || 0))) *
        100,
      count:
        (statsByAgeGroup[ageGroup]["male"]?.count || 0) +
        (statsByAgeGroup[ageGroup]["female"]?.count || 0)
    });
  });

  function getAgreePer(cardStats: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }) {
    return (
      cardStats &&
      ((cardStats.ao || 0) /
        ((cardStats.ao || 0) + (cardStats.ax || 0) + (cardStats.ad || 0))) *
        100
    );
  }

  function getDisagreePer(cardStats: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }) {
    return (
      cardStats &&
      ((cardStats.ax || 0) /
        ((cardStats.ao || 0) + (cardStats.ax || 0) + (cardStats.ad || 0))) *
        100
    );
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin-right: 12px;
        color: ${DATA_PLATFORM_RED_GRAY};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 70px;
          color: ${DATA_PLATFORM_YELLOW_GRAY};
          text-align: right;
          margin-right: 10px;
          font-size: 12px;
        `}
      >
        <div>100</div>
        <div>50</div>
        <div>0</div>
      </div>
      <div
        css={css`
          width: 1px;
          height: 70px;
          background-color: ${DATA_PLATFORM_GREEN_GRAY};
        `}
      ></div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          {average && (
            <div css={css``}>
              {supporterData?.[personHashtag] && (
                <CardAnswerSupporterBarChart
                  cardId={cardId}
                  supporters={supporterData[personHashtag]}
                  label={"평균"}
                  isAgree={isAgree}
                  personHashtag={personHashtag}
                />
              )}
            </div>
          )}
          <div css={css``}>
            {supporterData?.[personHashtag] && (
              <CardAnswerSupporterBarChart
                cardId={cardId}
                supporters={supporterData[personHashtag]}
                label={"지지층"}
                isAgree={isAgree}
                personHashtag={personHashtag}
              />
            )}
          </div>

          <div css={css``}>
            {supporterData?.[personHashtag] && (
              <CardAnswerSupporterBarChart
                cardId={cardId}
                supporters={supporterData[personHashtag]}
                label={"비지지층"}
                isAgree={isAgree}
                personHashtag={personHashtag}
              />
            )}
          </div>
          {MZ && (
            <div
              css={css`
                padding-top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 -12px;
              `}
            >
              <div
                css={css`
                  width: 80px;
                  height: 70px;
                  margin-left: 34px;
                  margin-bottom: 10px;
                `}
              >
                <VictoryGroup height={50} width={58} padding={0}>
                  <VictoryStack
                    colorScale={colorScale}
                    labelComponent={
                      <VictoryLabel data={ageData[0] && [ageData[0].x]} />
                    }
                  >
                    <VictoryBar
                      data={ageData[0] && [ageData[0].ax]}
                      alignment="start"
                    />
                    <VictoryBar
                      data={ageData[0] && [ageData[0].ad]}
                      alignment="start"
                    />
                    <VictoryBar
                      data={ageData[0] && [ageData[0].ao]}
                      alignment="start"
                    />
                  </VictoryStack>
                </VictoryGroup>
              </div>
              <div
                css={css`
                  font-size: 14px;
                  margin-top: 2px;
                  font-weight: 700;
                  text-align: center;
                `}
              >
                MZ 세대
                <br />
                {ageData[0] && isAgree
                  ? `
        ${ageData[0] && getAgreePer(ageData[0]).toFixed()}% 찬성`
                  : `${
                      ageData[0] && getDisagreePer(ageData[0]).toFixed()
                    }% 반대`}
              </div>
            </div>
          )}
        </div>
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${DATA_PLATFORM_GREEN_GRAY};
            position: relative;
            top: -45px;
          `}
        ></div>
      </div>
    </div>
  );
}
