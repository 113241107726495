/** @jsxImportSource @emotion/react */

import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import {
  GRAY,
  GRAY_BACKGROUND,
  THERMOMETER_COLOR,
  UserAnswer
} from "../../../../constants/enums";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot,
  hashtagTextAdapter,
  listToMap,
  parseDoc
} from "../../../../utils/utils";
import CardStatsGenderAgeChart from "../../../cards/DetailViews/CardStatsGenderAgeChart";
import {
  StanceTestConfiguration,
  StanceTestType
} from "../common/hashpage_shared";
import StanceTestResultReviewButton from "./StanceTestResultReviewButton";
import StanceTestResultReviewButtonTypeScore from "./StanceTestResultReviewButtonTypeScore";
import OXOText from "../../../utility/OXOText/OXOText";
import { useHistory } from "react-router";
import MiniBestComments from "../Comment/MiniBestComments";
import FakeCommentInput from "../Comment/FakeCommentInput";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import CardAnswerBarChart from "../Comment/CardAnswerBarChart";

export default function StanceTestTypeListResult({
  hashtag,
  stanceTestConfiguration,
  highlightColor
}: {
  hashtag: string;
  stanceTestConfiguration: StanceTestConfiguration;
  highlightColor: string;
}) {
  const [stanceVectors, setStanceVectors] = useState<{
    [category: string]: UserVectorSchema;
  }>();
  const categories = stanceTestConfiguration.카테고리;

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const history = useHistory();
  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.TEST_QUESTION)
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setStanceTestCards);
  }, [hashtag]);

  const [categoryHashCards, setCategoryHashCards] = useState<{
    [hashtag: string]: CardSchema;
  }>();
  useEffect(() => {
    if (!categories) {
      return;
    }

    const vectorPromises = Object.keys(categories).map(category => {
      return firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(category.replace("#", ""))
        .get()
        .then(parseDoc);
    });

    Promise.all(vectorPromises).then(cards => {
      const categoryVectorPairs: [string, CardSchema][] = Object.keys(
        categories
      ).map((category, i) => {
        return [category, cards[i] as CardSchema];
      });
      const stanceVectorMap = listToMap(
        categoryVectorPairs,
        (item: [string, CardSchema]) => item[0],
        (item: [string, CardSchema]) => item[1]
      ) as { [category: string]: CardSchema };
      setCategoryHashCards(stanceVectorMap);
    });

    return () => {};
  }, [stanceTestConfiguration]);

  useEffect(() => {
    if (!categories) {
      return;
    }

    const vectorPromises = Object.keys(categories).map(category => {
      return firebase
        .firestore()
        .collection(FirestoreCollection.USER_VECTOR)
        .doc(category)
        .get()
        .then(parseDoc);
    });

    Promise.all(vectorPromises).then(vectors => {
      const categoryVectorPairs: [string, UserVectorSchema][] = Object.keys(
        categories
      ).map((category, i) => {
        return [category, vectors[i] as UserVectorSchema];
      });
      const stanceVectorMap = listToMap(
        categoryVectorPairs,
        (item: [string, UserVectorSchema]) => item[0],
        (item: [string, UserVectorSchema]) => item[1]
      ) as { [category: string]: UserVectorSchema };
      setStanceVectors(stanceVectorMap);
    });

    return () => {};
  }, [stanceTestConfiguration]);

  if (!categories || !stanceVectors || !categoryHashCards) {
    return null;
  }

  return (
    <div>
      {stanceTestConfiguration.타입 === StanceTestType.SCORE &&
        stanceTestCards?.map(testCard => {
          const categoryName: string = Object.keys(categories)[0];
          const questionAnswer: string =
            stanceVectors[categoryName][testCard.id];

          const answerOColor: string =
            questionAnswer === UserAnswer.O
              ? THERMOMETER_COLOR.O
              : THERMOMETER_COLOR.X;

          const answerXColor: string =
            questionAnswer === UserAnswer.O
              ? THERMOMETER_COLOR.X
              : THERMOMETER_COLOR.O;

          return (
            <div
              css={{
                paddingTop: 20,
                borderTop: `16px solid ${GRAY_BACKGROUND}`
              }}
              key={testCard.id}
            >
              <div
                css={{
                  fontWeight: "bold",
                  paddingTop: 20,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingBottom: 10,
                  lineHeight: "150%"
                }}
              >
                {
                  <OXOText
                    text={hashtagTextAdapter(
                      testCard.title.replace(/\|/gi, " ")
                    )}
                  />
                }
              </div>
              <div css={{ width: "100%", padding: "0px 10px" }}>
                <StanceTestResultReviewButtonTypeScore
                  buttonType={UserAnswer.O}
                  categories={categories}
                  categoryHashCards={categoryHashCards}
                  stanceVectors={stanceVectors}
                  testCard={testCard}
                  btnColor={answerOColor}
                />
                <StanceTestResultReviewButtonTypeScore
                  buttonType={UserAnswer.DUNNO}
                  categories={categories}
                  categoryHashCards={categoryHashCards}
                  stanceVectors={stanceVectors}
                  testCard={testCard}
                />
                <StanceTestResultReviewButtonTypeScore
                  buttonType={UserAnswer.X}
                  categories={categories}
                  categoryHashCards={categoryHashCards}
                  stanceVectors={stanceVectors}
                  testCard={testCard}
                  btnColor={answerXColor}
                />
              </div>
              <div
                css={{
                  padding: "20px 0px"
                }}
              >
                <CardStatsGenderAgeChart
                  cardId={testCard.id}
                  colors={[answerOColor, THERMOMETER_COLOR.DUNNO, answerXColor]}
                />
              </div>
            </div>
          );
        })}

      {stanceTestConfiguration.타입 === StanceTestType.LIST &&
        stanceTestCards?.map(testCard => {
          return (
            <div key={testCard.id}>
              <div css={{ paddingTop: 20 }} key={testCard.id}>
                <div
                  css={{
                    width: "100%",
                    padding: "0px 30px",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  {testCard.yamlMetadata?.photos?.[0] && (
                    <div
                      css={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        overflow: "hiddden"
                      }}
                    >
                      <img
                        src={testCard.yamlMetadata?.photos?.[0]}
                        alt="stanceTestImg"
                        style={{ width: 40, height: 40 }}
                      />
                    </div>
                  )}
                  <div
                    onClick={e => {
                      history.push(
                        history.location.pathname +
                          addToCardIdStackUrl(
                            history.location.search,
                            testCard.id + "~tab:topic"
                          )
                      );
                    }}
                    css={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      paddingTop: 20,
                      paddingLeft: 10,
                      paddingBottom: 10,
                      lineHeight: "140%"
                    }}
                  >
                    {
                      <OXOText
                        text={hashtagTextAdapter(
                          testCard.title.replace(/\|/gi, " ")
                        )}
                      />
                    }
                  </div>
                </div>

                <StanceTestResultReviewButton
                  userAnswer={UserAnswer.O}
                  categories={categories}
                  categoryHashCards={categoryHashCards}
                  stanceVectors={stanceVectors}
                  highlightColor={highlightColor}
                  testCard={testCard}
                />
                <StanceTestResultReviewButton
                  userAnswer={UserAnswer.DUNNO}
                  categories={categories}
                  categoryHashCards={categoryHashCards}
                  stanceVectors={stanceVectors}
                  highlightColor={highlightColor}
                  testCard={testCard}
                />
                <StanceTestResultReviewButton
                  userAnswer={UserAnswer.X}
                  categories={categories}
                  categoryHashCards={categoryHashCards}
                  stanceVectors={stanceVectors}
                  highlightColor={highlightColor}
                  testCard={testCard}
                />

                <div
                  css={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 10
                  }}
                >
                  <CardAnswerBarChart cardId={testCard.id} />
                </div>
              </div>

              <div
                css={{
                  width: "100%",
                  paddingTop: 10,
                  paddingLeft: 30
                }}
                onClick={e => {
                  history.push(
                    history.location.pathname +
                      addToCardIdStackUrl(
                        history.location.search,
                        testCard.id + "~tab:comments"
                      )
                  );
                }}
              >
                <div
                  css={{
                    fontSize: "13px",
                    color: GRAY,
                    paddingBottom: 2,
                    marginTop: "3px",
                    cursor: "pointer"
                  }}
                >
                  댓글 {testCard && testCard.comments}개 모두 보기
                </div>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4
                  }}
                >
                  <MiniBestComments
                    cardId={testCard.id}
                    maxWidth={700}
                    disableExpressions
                  />
                </div>
                {testCard.comments === 0 && currentUserMetadata?.tribeId && (
                  <div css={{ paddingRight: 30 }}>
                    <FakeCommentInput />
                  </div>
                )}
              </div>
              <div
                css={{ marginTop: 30, border: `3px solid ${GRAY_BACKGROUND}` }}
              />
            </div>
          );
        })}

      {stanceTestConfiguration.타입 === StanceTestType.SIMILARITY &&
        stanceTestCards?.map(testCard => {
          return (
            <div style={{ paddingTop: 20 }} key={testCard.id}>
              <div
                css={{
                  fontWeight: "bold",
                  paddingTop: 20,
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingBottom: 10
                }}
              >
                {
                  <OXOText
                    text={hashtagTextAdapter(
                      testCard.title.replace(/\|/gi, " ")
                    )}
                  />
                }
              </div>

              <StanceTestResultReviewButton
                userAnswer={UserAnswer.O}
                categories={categories}
                categoryHashCards={categoryHashCards}
                stanceVectors={stanceVectors}
                highlightColor={highlightColor}
                testCard={testCard}
              />
              <StanceTestResultReviewButton
                userAnswer={UserAnswer.DUNNO}
                categories={categories}
                categoryHashCards={categoryHashCards}
                stanceVectors={stanceVectors}
                highlightColor={highlightColor}
                testCard={testCard}
              />
              <StanceTestResultReviewButton
                userAnswer={UserAnswer.X}
                categories={categories}
                categoryHashCards={categoryHashCards}
                stanceVectors={stanceVectors}
                highlightColor={highlightColor}
                testCard={testCard}
              />
            </div>
          );
        })}
    </div>
  );
}
