/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import DetailPagesRenderer from "../../cards/DetailViews/DetailPagesRenderer";
import { CardType } from "../../../constants/firestore_schema";
import { useGetCard } from "../../../utils/hooks/useFirestoreGetDocument";
import CardDetailsPageTabs from "../../cards/DetailViews/CardDetailsPageTabs";
import { css } from "@emotion/react";
import { useHistory } from "react-router";

export default function CardDetailsPage({ cardId }: { cardId: string }) {
  const card = useGetCard(cardId);
  const MENU: string[] = [
    card && card.type === CardType.TOPIC ? "질문" : "내용",
    "댓글",
    "톡",
    "응답결과"
  ];

  const [tabIndex, setTabIndex] = useState<number>(0);

  const history = useHistory();

  return (
    <div>
      <div
        css={css`
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          padding: 10px;
          cursor: pointer;
        `}
        onClick={() => {
          history.go(-1);
        }}
      >
        {"<"} {card?.title.replace(/_/g, " ")}
      </div>
      <div>
        <CardDetailsPageTabs
          menuItemArray={MENU}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
      </div>
      <div style={{ paddingTop: 48 }} />
      <div style={{ width: "100%" }}>
        <div key={"close"} />
        {MENU.map((tabTitle, i) => {
          if (i === tabIndex) {
            return (
              <div key={tabTitle}>
                <DetailPagesRenderer cardId={cardId} tab={tabTitle} />
              </div>
            );
          } else {
            return <div key={tabTitle}></div>;
          }
        })}
      </div>
    </div>
  );
}
