/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { CardAnswerTribeStatsSchema } from "../../../../constants/firestore_schema";
import MiniQuestionPieChart from "../../../topic/Question/QuestionChart/MiniQuestionPieChart";
import { css } from "@emotion/react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import {
  getAgreePercentage,
  getDisagreePercentage
} from "../../../../utils/data_utils";
import MiniQuestionStackChart from "../../../topic/Question/QuestionChart/MiniQuestionStackChart";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";

export default function TribeBarChart({
  cardAnswerTribeStats,
  oneLine,
  unitWidth,
  isAgree = true
}: {
  cardAnswerTribeStats: CardAnswerTribeStatsSchema;
  oneLine?: boolean;
  unitWidth?: number;
  isAgree?: boolean;
}) {
  const [isNull, setIsNull] = useState(false);

  const cardStats: CardAnswerTribeStatsSchema = cardAnswerTribeStats;
  const nullStats = { x: 0, o: 0, count: 0, "?": 0 };
  const nullChecker: number[] = [];
  for (let i = 1; i <= 5; i++) {
    cardAnswerTribeStats[i]
      ? (cardStats[i] = cardAnswerTribeStats[i])
      : (cardStats[i] = nullStats) && (nullChecker[i - 1] += 1);
  }

  if (nullChecker.length === 5) {
    setIsNull(true);
  }

  return (
    <div>
      {isNull ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
            color: ${DATA_PLATFORM_RED_GRAY};
          `}
        >
          데이터가 아직 없습니다.
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 49px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-right: 20px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 60px;
                color: ${DATA_PLATFORM_YELLOW_GRAY};
                text-align: right;
                margin-right: 8px;
                font-size: 12px;
                font-weight: 400;
              `}
            >
              <div>100</div>
              <div>50</div>
              <div>0</div>
            </div>
            <div
              css={css`
                width: 1px;
                height: 59px;
                background-color: ${DATA_PLATFORM_GREEN_GRAY};
              `}
            ></div>
            {Object.keys(cardStats).map(tribeId => {
              const cardCharStats = cardStats[tribeId];
              return (
                <div
                  key={tribeId}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    ...(unitWidth ? { width: unitWidth } : {})
                  }}
                >
                  <div
                    css={css`
                      margin-right: -10px;
                      margin-left: 8px;
                    `}
                  >
                    <MiniQuestionStackChart
                      cardChartStats={cardCharStats}
                      size={60}
                    />
                  </div>
                  <div
                    css={css`
                      font-size: 14px;
                      margin-top: 2px;
                      text-align: center;
                      font-weight: 700;
                      color: ${DATA_PLATFORM_RED_GRAY};
                    `}
                  >
                    {TRIBE_INFO_BY_ID[tribeId].shortDescription}
                  </div>
                  <div css={[css``]}>
                    <div
                      css={css`
                        font-size: 14px;
                        margin-top: 2px;
                        text-align: center;
                        font-weight: 700;
                        color: ${DATA_PLATFORM_RED_GRAY};
                      `}
                    >
                      {cardStats[tribeId].x === 0 &&
                      cardStats[tribeId].o === 0 &&
                      cardStats[tribeId]["?"] === 0 ? (
                        <div
                          css={css`
                            font-size: 12px;
                            margin-top: 3px;
                          `}
                        >
                          응답이 없어요
                        </div>
                      ) : isAgree ? (
                        `${getAgreePercentage(
                          cardStats[tribeId]
                        ).toFixed()}% 지지`
                      ) : (
                        `${getDisagreePercentage(
                          cardStats[tribeId]
                        ).toFixed()}% 반대`
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            css={css`
              margin-top: -49px;
              width: 358px;
              height: 1px;
              margin-right: 20px;
              margin-left: 20px;
              background-color: ${DATA_PLATFORM_GREEN_GRAY};
            `}
          ></div>
        </div>
      )}
    </div>
  );
}
