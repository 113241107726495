/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import { useGetCard } from "../../../utils/hooks/useFirestoreGetDocument";
import { css } from "@emotion/react";
import { useHistory } from "react-router";
import TribeStatsForCardId from "../DataExplorerView/TribeStatsForCardId";
import AgeGenderStatsForCardId from "../DataExplorerView/AgeGenderStatsForCardId";
import GenderAgeChartNormalized from "../DataExplorerView/GenderAgeChartNormalized";
import BestComments from "../DataExplorerView/BestComments";
import StatsForCardId from "../DataExplorerView/StatsForCardId";
import VerticalCommentsForCardId from "../../cards/WeeklyReview/VerticalCommentsForCardId";
import CommentStatsForCardId from "../DataExplorerView/CommentStatsForCardId";
import DistrictResultsTable from "./district/DistrictResultsTable";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import DistrictGroupResultsTable from "./district/DistrictGroupResultsTable";
import AgeGenderStatsForCardIdAgeOnly from "../DataExplorerView/AgeGenderStatsForCardIdAgeOnly";
import StatsForCardIdChart from "../DataExplorerView/StatsForCardIdChart";
import ReportPopupButton from "../PortraitReportView/ReportPopupButton";
import { ROUTES } from "../../../constants/routes";
import { ErrorBoundary } from "../../foundation/App/ErrorBoundary";
import DiverseBestComments from "diverse-react/dist/Components/comments/DiverseBestComments";
import firebase from "firebase/compat";
import {
  AnswerCountSchema,
  AnswerCountsSchema,
  CardAnswerSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import { getAverageTribeCardAnswerDataV1 } from "../DataExplorerView/getAverageTribeCardAnswerData";
import DistrictResultsBruteForce from "./DistrictResultsBruteForce";
import { BarLoader } from "react-spinners";
import { Input } from "@material-ui/core";

const H2_CSS = css`
  padding-top: 20px;
`;

export default function CardStatViewBruteForceData({
  cardId
}: {
  cardId: string;
}) {
  const card = useGetCard(cardId);
  const [openDistrictResults, setOpenDistrictResults] =
    useState<boolean>(false);
  const [tribeAnswerCountsV1, setTribeAnswerCountsV1] = useState<{
    [tribeId: string]: AnswerCountSchema;
  }>();

  const [tribeAnswerCountsV2, setTribeAnswerCountsV2] = useState<{
    [tribeId: string]: AnswerCountSchema;
  }>();

  const [ageGenderStats, setAgeGenderStats] = useState<{
    [birthYear: number]: {
      [gender: string]: AnswerCountsSchema;
    };
  }>();
  const [cardAnswers, setCardAnswers] = useState<CardAnswerSchema[]>();

  const [answerStats, setAnswerStats] = useState<AnswerCountSchema>();

  const [birthYearStart, setBirthYearStart] = useState<number>();
  const [birthYearEnd, setBirthYearEnd] = useState<number>();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .where("cardId", "==", cardId);

    if (birthYearStart && birthYearEnd) {
      query = query
        .where("birthYear", ">=", birthYearStart)
        .where("birthYear", "<=", birthYearEnd);
    }

    query
      .get()
      .then(getDocsFromSnapshot)
      .then((cardAnswers: CardAnswerSchema[]) => {
        const errorFreeData = cardAnswers.filter(cardAnswer => {
          if (!cardAnswer.tribeId) {
            console.log("no V1 tribeId", cardAnswer);
            return false;
          }
          if (!cardAnswer.tribeV2Id) {
            console.log("no V2 tribeId", cardAnswer);
            return false;
          }

          if (!cardAnswer.birthYear) {
            console.log("no birthYear", cardAnswer);
            return false;
          }

          if (!cardAnswer.gender) {
            console.log("no gender", cardAnswer);
            return false;
          }

          if (!cardAnswer.jurisdictionId) {
            console.log("no jurisdictionId", cardAnswer);
            return false;
          }

          return true;
        });
        setCardAnswers(errorFreeData);
      });
  }, [cardId, reload]);

  useEffect(() => {
    if (!cardAnswers) {
      return;
    }

    const answerCountsV1 = cardAnswers.reduce(
      (
        r: {
          [tribeId: string]: {
            [answer: string]: number;
          };
        },
        cardAnswer
      ) => {
        if (cardAnswer.tribeId) {
          r[cardAnswer.tribeId][cardAnswer.answer] += 1;
          r[cardAnswer.tribeId].count += 1;
        } else {
          console.log("no V1 tribeId", cardAnswer);
        }
        return r;
      },
      {
        "1": { o: 0, x: 0, "?": 0, count: 0 },
        "2": { o: 0, x: 0, "?": 0, count: 0 },
        "3": { o: 0, x: 0, "?": 0, count: 0 },
        "4": { o: 0, x: 0, "?": 0, count: 0 },
        "5": { o: 0, x: 0, "?": 0, count: 0 }
      }
    );

    const answerCountsV2 = cardAnswers.reduce(
      (
        r: {
          [tribeId: string]: {
            [answer: string]: number;
          };
        },
        cardAnswer
      ) => {
        if (cardAnswer.tribeV2Id) {
          r[cardAnswer.tribeV2Id][cardAnswer.answer] += 1;
          r[cardAnswer.tribeV2Id].count += 1;
        } else {
          console.log("no V2 tribeId", cardAnswer);
        }
        return r;
      },
      {
        "1": { o: 0, x: 0, "?": 0, count: 0 },
        "2": { o: 0, x: 0, "?": 0, count: 0 },
        "3": { o: 0, x: 0, "?": 0, count: 0 },
        "4": { o: 0, x: 0, "?": 0, count: 0 },
        "5": { o: 0, x: 0, "?": 0, count: 0 }
      }
    );

    setTribeAnswerCountsV1(
      answerCountsV1 as unknown as { [tribeId: string]: AnswerCountSchema }
    );

    setTribeAnswerCountsV2(
      answerCountsV2 as unknown as { [tribeId: string]: AnswerCountSchema }
    );

    return () => {};
  }, [cardAnswers]);

  useEffect(() => {
    if (!cardAnswers) {
      return;
    }
    const ageGender = cardAnswers.reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, cardAnswer) => {
      let ageGroupNumber =
        Math.floor(
          (new Date().getFullYear() - Number(cardAnswer.birthYear)) / 10
        ) * 10;

      if (ageGroupNumber < 20) {
        ageGroupNumber = 10;
      }

      if (ageGroupNumber > 60) {
        ageGroupNumber = 60;
      }

      const ageGroup = ageGroupNumber.toFixed();

      if (!prev[ageGroup]) {
        prev[ageGroup] = {};
      }
      const gender = cardAnswer.gender!;
      if (!prev[ageGroup][gender]) {
        prev[ageGroup][gender] = {
          o: 0,
          x: 0,
          "?": 0,
          count: 0
        };
      }

      prev[ageGroup][gender][cardAnswer.answer! as "o" | "?" | "x"] += 1;
      prev[ageGroup][gender]["count"] += 1;

      return prev;
    }, {});

    setAgeGenderStats(ageGender);
  }, [cardAnswers]);

  useEffect(() => {
    if (!cardAnswers) {
      return;
    }
    const answerCounts = cardAnswers.reduce<AnswerCountSchema>(
      (prev, cardAnswer) => {
        prev[cardAnswer.answer! as "o" | "?" | "x"] += 1;
        prev["count"] += 1;

        return prev;
      },
      {
        o: 0,
        x: 0,
        "?": 0,
        count: 0
      }
    );

    setAnswerStats(answerCounts);
  }, [cardAnswers]);

  const averageCardAnswer = useMemo(() => {
    if (!tribeAnswerCountsV1) {
      return undefined;
    }
    return getAverageTribeCardAnswerDataV1(tribeAnswerCountsV1);
  }, [tribeAnswerCountsV1]);

  const history = useHistory();

  if (!card) {
    return (
      <div
        css={css`
          padding: 20px;
        `}
      >
        <BarLoader />
      </div>
    );
  }

  return (
    <div
      css={css`
        width: 100%;
        padding: 20px;
      `}
    >
      <h2>{card?.title.replace(/_/g, " ")}</h2>

      <div
        css={css`
          height: 20px;
        `}
      />
      <div>{new Date(card.createdAt).toLocaleString()}</div>

      <div
        css={css`
          margin-bottom: 20px;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 20px;
          margin-top: 20px;
        `}
      >
        <h2>Filters</h2>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 80px;
            `}
          >
            태어난 해
          </div>
          <div>
            <Input
              type={"number"}
              style={{ width: 80, textAlign: "right" }}
              onChange={e => setBirthYearStart(parseInt(e.target.value))}
            />
            ~
            <Input
              style={{ width: 80, textAlign: "right" }}
              type={"number"}
              onChange={e => setBirthYearEnd(parseInt(e.target.value))}
            />
          </div>
          <div
            css={css`
              width: 100px;
              margin-left: 10px;
            `}
          >
            <OXOButton onClick={() => setReload(p => !p)} label={"필터 적용"} />
          </div>
        </div>
      </div>

      <div>
        <div
          css={css`
            width: 300px;
          `}
        >
          <ReportPopupButton cardId={cardId} />
        </div>

        <div
          css={css`
            width: 300px;
          `}
        >
          <OXOButton
            label={"전체 응답 raw 데이터 보기"}
            onClick={() => history.push(ROUTES.CARD_ANSWERS + "/" + cardId)}
          />
        </div>

        <div
          css={css`
            margin-top: 10px;
            width: 300px;
          `}
        >
          <OXOButton
            label={"전체 부족 데이터 상세 보기"}
            onClick={() =>
              history.push(ROUTES.CARD_ANSWER_TRIBES + "/" + cardId)
            }
          />
        </div>
        <div
          css={css`
            margin-top: 10px;
            width: 300px;
            margin-bottom: 10px;
            font-size: 12px;
          `}
        >
          <OXOButton
            label={"ChatGPT 보고서 보기"}
            onClick={() => history.push(ROUTES.CARD_GPT + "/" + cardId)}
          />
          GPT관리 기능은 Flutter Admin으로 이전했습니다.
        </div>
      </div>
      <div>
        <StatsForCardId cardId={cardId} answerStats={answerStats} />
        <StatsForCardIdChart
          cardId={cardId}
          answerStats={answerStats}
          tribeStats={tribeAnswerCountsV1}
          ageGenderStats={ageGenderStats}
          averageCardAnswer={averageCardAnswer}
        />
      </div>

      <div
        css={css`
          height: 20px;
        `}
      />
      <h2 css={H2_CSS}>부족별 응답 통계</h2>
      <div>
        {card.id && (
          <TribeStatsForCardId
            cardId={cardId}
            tribeStats={tribeAnswerCountsV1}
          />
        )}
      </div>

      <div
        css={css`
          height: 20px;
        `}
      />
      <h2 css={H2_CSS}>연령/성별 응답 통계</h2>

      <div>
        <AgeGenderStatsForCardId
          cardId={cardId}
          ageGenderStats={ageGenderStats}
        />
      </div>
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <AgeGenderStatsForCardIdAgeOnly
          cardId={cardId}
          ageGenderStats={ageGenderStats}
        />
      </div>

      <h2 css={H2_CSS}>인구 보정 통계</h2>
      <GenderAgeChartNormalized cardId={cardId} />

      <h2 css={H2_CSS}>부족별 베스트 댓글</h2>
      <BestComments cardId={cardId} />

      <h2 css={H2_CSS}>지역별 분석</h2>
      <div
        css={css`
          padding: 20px 0;
        `}
      >
        <DistrictResultsTable cardId={cardId} />
      </div>

      <h2 css={H2_CSS}>권역별 분석</h2>
      <div
        css={css`
          padding: 20px 0;
        `}
      >
        <DistrictGroupResultsTable cardId={cardId} />
      </div>

      <div>
        {cardAnswers && <DistrictResultsBruteForce cardAnswers={cardAnswers} />}
      </div>

      <h2 css={H2_CSS}>댓글 수</h2>
      <div>
        <CommentStatsForCardId cardId={card.id} />
      </div>

      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <VerticalCommentsForCardId cardId={cardId} />
      </div>

      <ErrorBoundary>
        <DiverseBestComments
          projectId={"oxopolitics-korea"}
          postId={cardId}
          token={"demo-token"}
        />
      </ErrorBoundary>
    </div>
  );
}
