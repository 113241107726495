import React from "react";

import { ACCENT_COLOR } from "../../../constants/colors";

export default function ButtonX({
  color = ACCENT_COLOR,
  fill = "white"
}: {
  color?: string;
  fill?: string;
}) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 19 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9228 1.84553C15.8116 0.71821 14.0101 0.71821 12.8989 1.84553L9.50002 5.2939L6.10107 1.84549C4.98991 0.71817 3.18838 0.718169 2.07722 1.84549C0.966071 2.97281 0.966071 4.80056 2.07722 5.92788L5.47618 9.3763L1.83337 13.0721C0.722212 14.1994 0.722212 16.0272 1.83337 17.1545C2.94452 18.2818 4.74605 18.2818 5.85721 17.1545L9.50002 13.4587L13.1428 17.1545C14.2539 18.2818 16.0555 18.2818 17.1666 17.1545C18.2778 16.0271 18.2778 14.1994 17.1666 13.0721L13.5239 9.3763L16.9228 5.92793C18.0339 4.8006 18.0339 2.97285 16.9228 1.84553Z"
        stroke={color}
        strokeWidth="1.3"
      />
    </svg>
  );
}
