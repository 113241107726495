import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/compat";

import React, { useEffect, useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../../constants/firestore_schema";
import { useIsAndroid } from "../../../../../utils/hooks/useIsAndroid";
import { useIsApp } from "../../../../../utils/hooks/useIsApp";
import { parseDoc } from "../../../../../utils/utils";
import { parseYamlDescription } from "../../common/hashpage_shared";

import urlShareBtn from "../imgs/url_share.svg";
import kakaoShareBtn from "../imgs/kakao_share.svg";
import fbShareBtn from "../imgs/fb_share.svg";
import twitterShareBtn from "../imgs/twitter_share.svg";

export default function StanceTestShareLargeButtons({
  hashtag,
  linkUrl,
  facebookTitle,
  twitterTitle,
  kakaoTitle,
  color
}: {
  hashtag: string;
  linkUrl: string;
  facebookTitle: string;
  twitterTitle: string;
  kakaoTitle: string;
  color: string;
}) {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isApp = useIsApp();
  const isAndroid = useIsAndroid();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then((snapshot: CardSchema) => {
        setPhotoUrl(
          parseYamlDescription(snapshot.hashtagMetadata?.photos?.[0] || "")
        );
      });
  }, []);

  return (
    <div
      style={{
        width: 257,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "18px"
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {isApp ? (
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_copy_link", {
              event_category: "hashcard"
            });
            setSnackbarMessage("링크가 복사되었습니다. 붙여넣기 해 보세요.");
            const win = window as any;
            const json_msg =
              '{"action":"write_clipboard","text":"' + linkUrl + '"}';

            isAndroid
              ? win.send_to_app("Android", json_msg)
              : win.send_to_app("iOS", json_msg);

            setOpenSnackBar(true);
          }}
          style={{
            cursor: "pointer",
            width: 48,
            height: 48
          }}
        >
          <img src={urlShareBtn} alt="urlShareBtn" />
        </div>
      ) : (
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_copy_link", {
              event_category: "hashcard"
            });
            setSnackbarMessage("링크가 복사되었습니다. 붙여넣기 해 보세요.");
            navigator.clipboard.writeText(linkUrl).then(value => {
              setOpenSnackBar(true);
            });
          }}
          style={{
            cursor: "pointer",
            width: 48,
            height: 48
          }}
        >
          <img src={urlShareBtn} alt="urlShareBtn" />
        </div>
      )}

      <div style={{ cursor: "pointer", width: 48, height: 48 }}>
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_kakao", {
              event_category: "hashcard"
            });

            const kakaotalkTitle = kakaoTitle;

            const win = window as any;
            win.kakaotalk_share(kakaotalkTitle, linkUrl, photoUrl);
          }}
        >
          <img src={kakaoShareBtn} alt="kakaoShareBtn" />
        </div>
      </div>
      <div style={{ cursor: "pointer", width: 48, height: 48 }}>
        <FacebookShareButton url={linkUrl}>
          <div
            onClick={() => {
              firebase.analytics().logEvent("v4_click_test_share_facebook", {
                event_category: "hashcard"
              });
            }}
          >
            <img src={fbShareBtn} alt="fbShareBtn" />
          </div>
        </FacebookShareButton>
      </div>
      <div style={{ cursor: "pointer", height: 48, width: 48 }}>
        <TwitterShareButton
          url={linkUrl}
          title={`[OXOpolitics] ${twitterTitle}`}
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_twitter", {
              event_category: "hashcard"
            });
          }}
        >
          <img src={twitterShareBtn} alt="twitterShareBtn" />
        </TwitterShareButton>
      </div>
    </div>
  );
}
