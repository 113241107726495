/** @jsxImportSource @emotion/react */

import {
  CardAnswerStatsSchema,
  CardSchema
} from "../../../../constants/firestore_schema";
import {
  mapValues,
  multiGroupBy,
  sumTwoAnswerCounts
} from "../../../../utils/utils";
import { css } from "@emotion/react";
import React from "react";
import {
  DATA_PLATFORM_GRAY_COLOR,
  DATA_PLATFORM_RED_GRAY
} from "../../../../constants/data_platform_colors";
import { getAgreePercentage } from "../../../../utils/data_utils";
import HashCommentBar from "../../HashCardView/common/HashCommentBar";
import { CSS_TITLE } from "../DashboardPage";

export default function DailyReportHashtagIssueCardDetails({
  hashtag,
  hashtagCards,
  cardAnswerStatsMap,
  index
}: {
  hashtag: string;
  hashtagCards: CardSchema[];
  cardAnswerStatsMap: { [cardId: string]: CardAnswerStatsSchema };
  index: number;
}) {
  const cardsBySpeaker = multiGroupBy(
    hashtagCards,
    card =>
      card.speakerHashTag
        ?.filter(t => t.length > 0)
        .map(t => {
          return t.replace("#", "");
        }) || []
  );

  const speakerPopularity = mapValues(cardsBySpeaker, cards =>
    cards.map(card => cardAnswerStatsMap[card.id]).reduce(sumTwoAnswerCounts)
  );

  const sortedHashtags = Object.keys(speakerPopularity).sort(
    (hashtagA, hashtagB) =>
      speakerPopularity[hashtagB].o / speakerPopularity[hashtagB].count -
      speakerPopularity[hashtagA].o / speakerPopularity[hashtagA].count
  );

  const winnerHashtags = sortedHashtags.slice(
    0,
    sortedHashtags.length > 2 &&
      speakerPopularity[sortedHashtags[1]].o /
        speakerPopularity[sortedHashtags[1]].count >
        0.2
      ? 2
      : 1
  );
  const loserHashtags = sortedHashtags
    .slice(sortedHashtags.length - 2, sortedHashtags.length)
    .filter(hashtag => !winnerHashtags.includes(hashtag))
    .reverse();

  function getMostPopularCard(speakerCards: CardSchema[]) {
    return speakerCards.sort((cardA, cardB) => {
      return (
        getAgreePercentage(cardAnswerStatsMap[cardB.id]) -
        getAgreePercentage(cardAnswerStatsMap[cardA.id])
      );
    })[0];
  }

  function getLeastPopularCard(speakerCards: CardSchema[]) {
    return speakerCards.sort((cardA, cardB) => {
      return (
        getAgreePercentage(cardAnswerStatsMap[cardA.id]) -
        getAgreePercentage(cardAnswerStatsMap[cardB.id])
      );
    })[0];
  }

  const renderMostPopularCommentBubble = (
    hashtag: string,
    idx: number,
    arr: string[]
  ) => {
    const speakerCards = cardsBySpeaker[hashtag];
    const mostPopularCard = getMostPopularCard(speakerCards);

    return (
      <>
        <div
          key={hashtag}
          css={css`
            width: 460px;
            padding-right: 30px;
            padding-left: 30px;
          `}
        >
          <HashCommentBar cardId={mostPopularCard.id} />
        </div>
        {idx === 0 && arr.length !== 1 && (
          <div
            css={css`
              background-color: #c4c4c4;
              width: 1px;
            `}
            key={hashtag + 1}
          />
        )}
      </>
    );
  };

  const renderLeastPopularCommentBubble = (
    hashtag: string,
    idx: number,
    arr: string[]
  ) => {
    const speakerCards = cardsBySpeaker[hashtag];
    const LeastPopularCard = getLeastPopularCard(speakerCards);
    return (
      <>
        <div
          key={hashtag}
          css={css`
            width: 460px;
            padding-right: 30px;
            padding-left: 30px;
          `}
        >
          <HashCommentBar cardId={LeastPopularCard.id} isAgree={false} />
        </div>
        {idx === 0 && arr.length !== 1 && (
          <div
            css={css`
              background-color: #c4c4c4;
              width: 1px;
            `}
            key={hashtag + 2}
          />
        )}
      </>
    );
  };

  return (
    <div
      key={hashtag}
      css={css`
        font-size: 0.9em;
        margin-top: 20px;
        cursor: pointer;
        width: 100%;
      `}
    >
      <div css={[CSS_TITLE]}>3 오늘의 핫이슈 {index + 1}</div>
      <div
        css={css`
          margin-top: 60px;
          font-size: 20px;
          font-weight: 700;
          color: ${DATA_PLATFORM_RED_GRAY};
        `}
      >
        #{hashtag.replace(/_/g, " ")}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            지지를 많이 받은 발언
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 880px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <div
            css={css`
              margin-top: 40px;
              display: flex;
              justify-content: center;
            `}
          >
            {winnerHashtags.map(renderMostPopularCommentBubble)}
          </div>
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: 40px;
            `}
          >
            <div
              css={css`
                margin-top: 6px;
                font-size: 18px;
                font-weight: 400;
                color: ${DATA_PLATFORM_RED_GRAY};
              `}
            >
              반대를 많이 받은 발언
            </div>
            <div
              css={css`
                margin-top: 6px;
                width: 880px;
                height: 1px;
                background-color: ${DATA_PLATFORM_GRAY_COLOR};
              `}
            />
          </div>
          <div
            css={css`
              display: flex;
              margin-top: 40px;
              justify-content: center;
            `}
          >
            {loserHashtags.map(renderLeastPopularCommentBubble)}
          </div>
        </div>
      </div>
    </div>
  );
}
