import React from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { GRAY_DARK } from "../../../../constants/enums";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import CardAnswerPieChartForCardId from "./CardAnswerPieChartForCardId";

export default function CommentToolsLine({ cardId }: { cardId: string }) {
  const [questionAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          fontWeight: 400,
          alignItems: "center",
          fontSize: 12,
          paddingTop: "5px",
          color: GRAY_DARK
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#363636",
            marginTop: "3px"
          }}
        >
          <div>{questionAnswerStats?.count}</div>
        </div>
        <CardAnswerPieChartForCardId cardId={cardId} size={40} />
      </div>
    </div>
  );
}
