import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import {
  CardSchema,
  CommentSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot, parseDoc } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import CommentTabs from "../../topic/Question/Comments/CommentTabs";

export default function CommentsForCardId({ cardId }: { cardId: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [card, setCard] = useState<CardSchema | undefined>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));
    return () => unsubscribe();
  }, [cardId]);

  const [comments, setComments] = useState<CommentSchema[]>();

  if (comments && card && currentUserId) {
    if (cardId && card.comments !== comments.length) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(cardId)
        .set(
          {
            comments: comments.length
          },
          { merge: true }
        );
    }
  }
  useEffect(() => {
    if (cardId) {
      const commentsSubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .where("questionId", "==", cardId)
        .onSnapshot(snapshot => setComments(getDocsFromSnapshot(snapshot)));
      return () => commentsSubscribe();
    }
  }, [cardId]);

  const currentUserMetadata = dataProps?.currentUserMetadata;

  const currentUserTribeId = currentUserMetadata?.tribeId || "1";

  if (!card) {
    return null;
  }

  return (
    <div>
      {currentUserTribeId && comments && (
        <CommentTabs
          comments={comments}
          currentTribeId={currentUserTribeId}
          cardId={cardId}
          photoUrl={
            card.topicMetadataV2?.photos && card.topicMetadataV2?.photos?.[0]
          }
        />
      )}
    </div>
  );
}
