/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import {
  toDateOnsetKoreaStandardTime,
  todayInKorea
} from "../../../utils/datetime";
import { css } from "@emotion/react";
import { DATA_PLATFORM_BACKGROUND_COLOR } from "../../../constants/data_platform_colors";
import { SupporterSelector } from "./SupporterSelector";
import { PopularityRateAnalysis } from "./PopularityRateAnalysis";

export default function SupporterView() {
  const { day, month, year, dayOfWeek } = todayInKorea(new Date().valueOf());
  const [selectedDate, setSelectedDate] = React.useState<number>(
    toDateOnsetKoreaStandardTime(year, month, day).valueOf()
  );
  const [selectedHashtag, setSelectedHashtag] =
    useState<string>("더불어민주당");

  const [selectedCard, setSelectedCard] = useState<string>("");

  return (
    <div
      css={css`
        background-color: ${DATA_PLATFORM_BACKGROUND_COLOR};
        height: 100vh;
        display: flex;
        overflow: auto;
        margin-left: 240px;
      `}
    >
      <div
        css={css`
          background-color: white;
          width: 432px;
          height: 100%;
          padding-top: 30px;
          padding-left: 10px;
          text-align: left;
          overflow: auto;
        `}
      >
        <SupporterSelector
          selectedDate={selectedDate}
          selectedHashtag={selectedHashtag}
          setSelectedHashtag={setSelectedHashtag}
          setSelectedCard={setSelectedCard}
        ></SupporterSelector>
      </div>
      <div
        css={css`
          height: 100%;
          overflow: auto;
        `}
      >
        <PopularityRateAnalysis></PopularityRateAnalysis>
      </div>
    </div>
  );
}
