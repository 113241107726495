import React, { useState } from "react";
import { UserVectorSchema } from "../../../constants/firestore_schema";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import AnswerRow from "./AnswerRow";
import UserName from "./UserName";

const CARD_PER_PAGE = 20;
export default function ProfileAnswerDiffQuestions({
  profileUserId,
  currentUserVector,
  profileUserVector
}: {
  profileUserId: string;
  profileUserVector: UserVectorSchema;
  currentUserVector: UserVectorSchema;
}) {
  const [page, setPage] = useState<number>(1);
  return (
    <div>
      <table style={{ color: "white" }}>
        <thead>
          <th colSpan={2} style={{ textAlign: "left", padding: 5 }}>
            {" "}
            <UserName profileUserId={profileUserId} />
            님의 응답
          </th>
          <th style={{ padding: 5 }}>나</th>
        </thead>
        <tbody>
          {Object.keys(profileUserVector)
            .slice(0, page * CARD_PER_PAGE)
            .map(cardId => (
              <AnswerRow
                key={cardId}
                cardId={cardId}
                profileUserAnswer={profileUserVector[cardId]}
                currentUserAnswer={currentUserVector[cardId]}
              />
            ))}
          <OXOInfiniteScroll
            setNextPage={() => {
              setPage(prev => prev + 1);
            }}
          />
        </tbody>
      </table>
    </div>
  );
}
