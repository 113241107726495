import firebase from "firebase/compat";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  BACKGROUND_COLOR,
  GRAY,
  oxoColors,
  UserAnswer
} from "../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import CardLoader from "../CardComponents/CardLoader";
import CardTag from "../CardComponents/CardTag";
import OxButtonsForCard from "../../foundation/OXOButton/OxButtonsForCard";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";

export default function MediaCard({ cardId }: { cardId: string }) {
  const location = useLocation();
  const [card, setCard] = useState<CardSchema>();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));
    return () => unsubscribe();
  }, [cardId]);

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  const history = useHistory();
  const answer = cardAnswer?.answer;
  const cardRef = useRef<HTMLDivElement>(null);

  if (!card) {
    return <CardLoader />;
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "340px",
        minWidth: "340px",
        height: "210px",
        borderRadius: "20px",
        position: "relative",
        scrollSnapAlign: "start",
        scrollMarginLeft: "8px",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        border:
          "solid 2px " +
          (answer
            ? answer === UserAnswer.O
              ? oxoColors.O
              : answer === UserAnswer.X
              ? oxoColors.X
              : oxoColors.DUNNO
            : BACKGROUND_COLOR)
      }}
      key={cardId}
      ref={cardRef}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "14px",
          marginLeft: "12px",
          marginTop: "12px"
        }}
      >
        <CardTag title={"언론"} />
      </div>

      <div
        style={{
          paddingRight: "20px",
          paddingLeft: "30px",
          wordBreak: "keep-all",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left"
        }}
      >
        <div
          style={{
            cursor: "pointer",
            fontWeight: "bold"
          }}
          onClick={() => {
            firebase.analytics().logEvent("click_media_1", {
              event_category: "media_card",
              event_label: card.title
            });
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, card.id)
            );
          }}
        >
          {card.title}
        </div>
        <div
          style={{
            cursor: "pointer",
            flexGrow: 0,
            fontSize: "14px",
            display: "flex",
            color: GRAY
          }}
          onClick={() => {
            firebase.analytics().logEvent("click-media_1", {
              event_category: "media_card",
              event_label: card.title
            });
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, card.id)
            );
          }}
        >
          <div>응답 {card.answers}</div>
          <div style={{ margin: "0 5px" }}>|</div>
          <div>댓글 {card.comments}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <OxButtonsForCard cardId={card.id} />
      </div>
    </div>
  );
}
