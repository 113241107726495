import firebase from "firebase/compat";
import React, { CSSProperties, useState } from "react";
import { useHistory } from "react-router-dom";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  parseDoc,
  removeIdKey
} from "../../../utils/utils";
import { defaultProfileModifiers } from "./adjectives";

export default function UserName({
  profileUserId,
  useTribeColor = false,
  decreaseFontSizeForLongName = false,
  enableLink = true,
  isClickActive = true,
  style
}: {
  profileUserId: string | undefined;
  useTribeColor?: boolean;
  decreaseFontSizeForLongName?: boolean;
  enableLink?: boolean;
  isClickActive?: boolean;
  style?: CSSProperties;
}) {
  const history = useHistory();
  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();

  React.useEffect(() => {
    if (profileUserId) {
      const unsubscribeUserProfileMetadata = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(profileUserId)
        .onSnapshot(snapshot => {
          setUserProfileMetadata(removeIdKey(parseDoc(snapshot)));
        });

      return () => {
        unsubscribeUserProfileMetadata();
      };
    }
  }, [profileUserId]);

  if (!userProfileMetadata) {
    return null;
  }

  if (!profileUserId) {
    return null;
  }

  const userName = getUserName(userProfileMetadata, profileUserId);
  return (
    <span
      style={{
        ...(useTribeColor && userProfileMetadata.tribeId
          ? {
              color: TRIBE_INFO_BY_ID[userProfileMetadata.tribeId].color
            }
          : {}),
        ...(decreaseFontSizeForLongName
          ? { fontSize: 1 - (userName.length - 10) / 40 + "em" }
          : {}),
        ...(enableLink ? { cursor: "pointer" } : {}),
        ...style
      }}
      onClick={e => {
        if (isClickActive) {
          if (enableLink) {
            e.stopPropagation();
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, profileUserId)
            );
          }
        }
      }}
    >
      {userName}
    </span>
  );
}

export function getUserName(
  userProfileMetadata: UserProfileMetadataSchema,
  profileUserId: string
) {
  if (profileUserId === "oxo") {
    return "옥소";
  }

  if (userProfileMetadata.userChosenName) {
    return userProfileMetadata.userChosenName;
  }

  const profileTribeInfo =
    userProfileMetadata.tribeId &&
    TRIBE_INFO_BY_ID[userProfileMetadata.tribeId];
  const tribeName = profileTribeInfo ? profileTribeInfo.name : "방랑자";

  return getUserModifier(userProfileMetadata) + " " + tribeName;
}

export function getUserDescription(
  userMetadata?: UserProfileMetadataSchema,
  isSplit = false
) {
  if (!userMetadata) {
    return null;
  }

  if (!isSplit) {
    return (
      <>
        {userMetadata.x < -5
          ? "여권을 지지하며"
          : userMetadata.x > 5
          ? "여권에 반대하며"
          : "중도적이며"}
        {userMetadata.y > 5
          ? "분배 우선의 "
          : userMetadata.y < -5
          ? "성장 우선의 "
          : "균형 성장의 "}
        가치관을 추구
      </>
    );
  } else {
    return (
      <>
        <span>
          {userMetadata.x < -5
            ? "여권을 지지하며"
            : userMetadata.x > 5
            ? "여권에 반대하며"
            : "중도적이며"}
        </span>
        <span>
          {userMetadata.y > 5
            ? "분배 우선의 "
            : userMetadata.y < -5
            ? "성장 우선의 "
            : "균형 성장의 "}
          가치관을 추구
        </span>
      </>
    );
  }
}

export function getUserModifier(
  userProfileMetadata: UserProfileMetadataSchema
) {
  if (userProfileMetadata && userProfileMetadata.modifier) {
    return userProfileMetadata.modifier;
  } else {
    return "부족 없는";
  }
}

export function randomizeUserName(profileUserId: string) {
  const modifier =
    defaultProfileModifiers[
      Math.floor(Math.random() * defaultProfileModifiers.length)
    ];

  firebase
    .firestore()
    .collection(FirestoreCollection.USER_PROFILE_METADATA)
    .doc(profileUserId)
    .set(
      {
        modifier: modifier
      },
      { merge: true }
    );

  return modifier;
}
