import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc } from "../../../utils/utils";

export default function HydratedHashtags({ hashtags }: { hashtags: string[] }) {
  const [hydratedHashtags, setHydratedHashtags] = useState<string[]>();
  useEffect(() => {
    Promise.all(
      hashtags
        .filter(hashtag => hashtag.length > 0)
        .map(hashtag =>
          firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .doc(hashtag.replace("#", ""))
            .get()
            .then(parseDoc)
            .then((card: CardSchema) => card.title)
        )
    ).then(setHydratedHashtags);
  }, [hashtags]);

  return <div>{hydratedHashtags?.join(", ")}</div>;
}
