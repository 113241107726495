/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { AnswerCountsSchema } from "../../../constants/firestore_schema";
import {
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_GRAY
} from "../../../constants/data_platform_colors";
import CardAnswerBarChartForAnswerCounts from "../HashCardView/Comment/CardAnswerBarChartForAnswerCounts";

export function HashtagPersonBarChart({
  hashtag,
  speakerPopularity
}: {
  hashtag: string;
  speakerPopularity: AnswerCountsSchema;
}) {
  return (
    <div
      css={css`
        width: 380px;
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          font-size: 16px;
          font-weight: 400;
          color: ${DATA_PLATFORM_RED_GRAY};
          margin-top: 13px;
        `}
      >
        <div
          css={css`
            width: 50px;
            height: 20px;
            white-space: nowrap;
          `}
        >
          {hashtag.replace(/_/g, " ").slice(0, 4)}
        </div>
      </div>

      <div>
        <div
          css={css`
            font-size: 16px;
            font-weight: 400;
            color: ${DATA_PLATFORM_GREEN_COLOR};
            margin-top: 12px;
            text-align: center;
          `}
        >
          <CardAnswerBarChartForAnswerCounts answerCounts={speakerPopularity} />
        </div>
      </div>
    </div>
  );
}
