import React from "react";
import PoliticalStats from "../../views/StatsView/TribeAndPoltics";

export default function ProfileStatistics() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 14
      }}
    >
      <div style={{ marginTop: 20 }}>
        <PoliticalStats />
      </div>
    </div>
  );
}
