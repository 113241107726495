export const partyUsers: { [userId: string]: string } = {
  lGNQSOWJZnXNVEqljCuKHAt8kSJ3: "정의당",
  // h96XKOgmtcRi5Bl7Skhd1bmITGN2: "민생당",
  // "8ppiscwAiOgCOmGCct6mjtuhywx1": "우리공화당",
  // wDXgygAeBMP9t78stAwO22wBYCx1: "민중당",
  WUb0fycvtvYwb1ujjvY8VBwW5Zx2: "국민의당",
  U3alT4eJiBaNqVXZfXxhTJkwzNV2: "더불어민주당",
  FS3LohVgLDU9wlLaBaurB67tL833: "미래통합당"
};

export const mediaUsers: { [userId: string]: string } = {
  qWxw0rPxz5cOm3LmeqBt3D9eHBz1: "JTBC",
  tVUysXufXxQZ1AgrNrM6NljrSwK2: "YTN",
  oUzlprR5HcMSpVm97y6xAwqHrYb2: "SBS",
  svsr39ABRBNkJ8hihM9nGrlavWX2: "KBS",
  O1AT3panNkMIqLNC9VrnUw62rQw2: "MBC",
  Z7p6eXpEkuh0sQRelD3J4mgVPp02: "김어준의 뉴스공장",
  XcEVLVOeWcTDNhFAVsoAU4W7Oni2: "한겨레신문",
  QZF0uzgsHZZ271sOCmvCwhVRpXK2: "한국일보",
  U6OoilH7M1URhx9cczNmQf9bvuV2: "경향일보",
  jdRWXpy3zhhrjWDveIg1vDuodbr1: "세계일보",
  mIfV07BtRtOVCM1V4qAp4q2bPMu1: "국민일보",
  bEe4nZ4xXbZaPNu9nSoAZckkewv2: "동아일보",
  hG5pzsrbWvaJFQA3Cq5aQ1EI9Zk2: "중앙일보",
  Xak2JbFG86Yspke1UryAMoSBrg92: "조선일보"
  // XbPOnkS3r7NTRNftDtuyetS7Ftv2: "천지일보"
};

export const partyUsersForAdmin: { [userId: string]: string } = {
  lGNQSOWJZnXNVEqljCuKHAt8kSJ3: "정의당",
  // h96XKOgmtcRi5Bl7Skhd1bmITGN2: "민생당",
  // "8ppiscwAiOgCOmGCct6mjtuhywx1": "우리공화당",
  // wDXgygAeBMP9t78stAwO22wBYCx1: "민중당",
  WUb0fycvtvYwb1ujjvY8VBwW5Zx2: "국민의당",
  U3alT4eJiBaNqVXZfXxhTJkwzNV2: "더불어민주당",
  FS3LohVgLDU9wlLaBaurB67tL833: "미래통합당"
};

export const yAxisUserId = "8WCasvaBd3dRbYzj16r88HnyE4G2";
export const xAxisUserId = "bpyBotiODJgcPu75dMZWg8vU20f2";

export const UsersForUserMap: { [userId: string]: string } = {
  "8WCasvaBd3dRbYzj16r88HnyE4G2": "성장 중시 (Y축)",
  bpyBotiODJgcPu75dMZWg8vU20f2: "야당 지지자 (X축)"
  // fCbYb2SbHvbeN5VyE9gjA8xct0b2: "조 바이든",
  // J8AcvoT3KYg9RyCgh1Cg1h9kNeO2: "도널드 트럼프"
};

export const OXO_USER_ID = "HGBQzYwruudhK9R50PXXVmtaGWm2";

export const ADMIN_USER_IDS: string[] = [
  // "1BQAru4vcVRzEF9VOWZkbxZDOM13", // 유호현 (softbass)
  "3r59k2jUwjVYCZNGMKb5cdjEhU72", // 유찬현
  "HDsJXbmFWkNzy1qZpIbjForcwcN2", // will@oxopolitics.com
  "RS790PqvO0YiGCT9fKyUoriNtVJ3", // youngchan@oxopolitics.com
  "s7fl1NcOgJMUrCmgcet8RfpZWjA3", // 노형지
  "S1XDQlYd4GPct8xh40XRDedJ7142", // 김진실
  "Byk9tsbq7IUXtNEAfmafQo8RA7S2", // hanbyul@oxopolitics.com 오한별
  "XAoIoV2cUjcrbwd56N8nt6vxKLu1", // 고대우 kakao
  "wufGdzVnmmMC8PoAkkLSxivEdWC3" // 고대우 gmail
];
