import firebase from "firebase/compat";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { ROUTES } from "../../../../constants/routes";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { getDocsFromSnapshot, parseDoc } from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import {
  HashtagConfiguration,
  parseYamlDescription,
  StanceTestType
} from "../common/hashpage_shared";
import StanceTestResultTypeList from "./StanceTestResultPage/StanceTestResultTypeList";
import StanceTestResultTypeScore from "./StanceTestResultPage/StanceTestResultTypeScore";
import StanceTestResultTypeSimilarity from "./StanceTestResultPage/StanceTestResultTypeSimilarity";

export default function StanceTestResultsDetails({
  hashtag
}: {
  hashtag: string;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  if (!currentUserId) {
    history.push(ROUTES.URL_STACK + "/TEST_" + hashtag);
  }

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();

  useEffect(() => {
    if (currentUserId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .where("type", "==", CardType.TEST_QUESTION)
        .where("hashtags", "array-contains", "#" + hashtag)
        .get()
        .then(getDocsFromSnapshot)
        .then(setStanceTestCards);
    }
  }, [hashtag]);

  const unansweredCards = useMemo(() => {
    if (currentUserId && stanceTestCards) {
      return stanceTestCards.filter(card => currentUserVector);
    }
  }, [stanceTestCards, currentUserVector]);

  useEffect(() => {
    if (unansweredCards && unansweredCards.length !== 0) {
      // /history.push(ROUTES.URL_STACK + "/TEST_" + hashtag);
    }
  }, [unansweredCards]);

  const [hashtagCard, setHashtagCard] = useState<CardSchema>();
  useEffect(() => {
    if (hashtag) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(hashtag)
        .get()
        .then(parseDoc)
        .then(setHashtagCard);
    }
    return () => {};
  }, [hashtag]);

  const hashPageConfiguration: HashtagConfiguration | undefined =
    useMemo(() => {
      if (hashtagCard) {
        return parseYamlDescription(hashtagCard.hashtagMetadata?.description);
      }
    }, [hashtagCard]);

  const stanceTestConfiguration =
    hashPageConfiguration && hashPageConfiguration.성향테스트;

  if (!stanceTestConfiguration) {
    return null;
  }

  return (
    <>
      {stanceTestConfiguration.타입 &&
        (stanceTestConfiguration.타입 === StanceTestType.SCORE ? (
          <StanceTestResultTypeScore
            hashtag={hashtag}
            stanceTestConfiguration={stanceTestConfiguration}
          />
        ) : stanceTestConfiguration.타입 === StanceTestType.LIST ? (
          <StanceTestResultTypeList
            hashtag={hashtag}
            stanceTestConfiguration={stanceTestConfiguration}
          />
        ) : (
          <StanceTestResultTypeSimilarity
            hashtag={hashtag}
            stanceTestConfiguration={stanceTestConfiguration}
          />
        ))}
    </>
  );
}
