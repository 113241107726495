import { ApexOptions } from "apexcharts";

export const apexChartBasicOptions: ApexOptions = {
  chart: {
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: 1,
    colors: ["#fff"]
  },
  grid: {
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  tooltip: {
    marker: {
      show: false
    },
    fillSeriesColor: true,
    shared: false,
    x: {},
    y: {
      formatter: val => {
        return Math.abs(val) + "명";
      }
    }
  },
  legend: {
    show: false,
    offsetX: 12
  },
  xaxis: {
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  }
};
