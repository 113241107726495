/** @jsxImportSource @emotion/react */
import React from "react";

import { css } from "@emotion/react";
import {
  BestCommentsForCardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { removeIdKey } from "../../../utils/utils";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import SingleLineComment from "../HashCardView/Comment/SingleLineComment";

export default function BestComments({
  cardId,
  maxLen = 1000
}: {
  cardId: string;
  maxLen?: number;
}) {
  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );

  if (!bestComments) {
    return null;
  }

  return (
    <div
      css={css`
        text-align: left;
      `}
    >
      {bestComments &&
        Object.keys(removeIdKey(bestComments)).map(tribeId =>
          bestComments && bestComments[tribeId] ? (
            <div
              key={cardId + "bc" + tribeId}
              css={css`
                cursor: pointer;
                padding-bottom: 20px;
              `}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <SingleLineComment
                commentId={bestComments[tribeId]}
                maxLen={maxLen}
              />
            </div>
          ) : (
            <div key={cardId + "bc" + tribeId} />
          )
        )}
    </div>
  );
}
