import React from "react";

import OxBarChart from "./OxBarChart";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import {
  ANSWER_DUNNO_STROKE,
  ANSWER_O_STROKE,
  ANSWER_X_STROKE
} from "../../../constants/colors";
import { getAverageTribeCardAnswerDataV1 } from "./getAverageTribeCardAnswerData";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { removeIdKey } from "../../../utils/utils";

export default function OxBarChartContainer({
  cardId,
  colorScale = [ANSWER_O_STROKE, ANSWER_DUNNO_STROKE, ANSWER_X_STROKE],
  width,
  answerCounts
}: {
  cardId: string;
  colorScale?: string[];
  width?: number;
  answerCounts?: number;
}) {
  const cardAnswerTribeStats =
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  if (!cardAnswerTribeStats) {
    return <></>;
  }
  const tribeAverageAnswerCounts =
    getAverageTribeCardAnswerDataV1(cardAnswerTribeStats);

  const totalCounts = Object.keys(removeIdKey(cardAnswerTribeStats)).reduce(
    (prev, tribeId) => {
      const count =
        (cardAnswerTribeStats[tribeId].o || 0) +
        (cardAnswerTribeStats[tribeId]["?"] || 0) +
        (cardAnswerTribeStats[tribeId].x || 0);
      return prev + count;
    },
    0
  );

  const data: ApexAxisChartSeries = [
    {
      name: "찬성",
      data: [
        {
          x: 0,
          y: Math.round(tribeAverageAnswerCounts.o * 10) / 10
        }
      ]
    },
    {
      name: "중립",
      data: [
        {
          x: 0,
          y: Math.round(tribeAverageAnswerCounts["?"] * 10) / 10
        }
      ]
    },
    {
      name: "반대",
      data: [
        {
          x: 0,
          y: Math.round(tribeAverageAnswerCounts.x * 10) / 10
        }
      ]
    }
  ];

  return (
    <OxBarChart
      data={data}
      colorScale={colorScale}
      width={width}
      totalAnswerCounts={answerCounts ?? totalCounts}
    />
  );
}
