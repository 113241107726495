import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  PROFILE_SIDE_MENU_ITEMS,
  TRIBE_INFO_BY_ID
} from "../../constants/enums";
import { ROUTES } from "../../constants/routes";
import { addToCardIdStackUrl } from "../../utils/utils";
import { DataPropsContext } from "../foundation/Contexts/DataPropsContext";
import { GlobalVariableContext } from "../foundation/Contexts/GlobalVariableContext";
import UserProfilePhoto from "../views/StatsView/UserProfilePhoto";
import NON_LOGIN_USER from "./icons/non_login_user.svg";

export default function ProfileSideBarOpener() {
  const global = useContext(GlobalVariableContext);
  const loginSuccessUrl = global?.loginSuccessUrl || ROUTES.INTRO_LOGGED_IN;
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const history = useHistory();

  if (currentUserId && !currentUserMetadata) {
    return null;
  }

  return (
    <>
      <div
        style={{
          color: "inherit"
          // marginBottom: "8px"
        }}
        onClick={() => {
          window.scrollTo(0, 0);
          if (currentUserId) {
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(
                  history.location.search,
                  `${PROFILE_SIDE_MENU_ITEMS.MAIN}`
                )
            );
          } else {
            if (
              window.confirm(
                "로그인이 필요한 기능입니다. 로그인을 하러 갈까요?"
              )
            ) {
              global?.setLoginSuccessUrl("/?cardStack=profile_main");

              history.push(ROUTES.INTRO + "?successUrl=/urlStack/profile_main");
            }
          }
        }}
      >
        {currentUserMetadata && currentUserMetadata.tribeId ? (
          <>
            <div
              style={{
                position: "relative",
                width: 32,
                height: 32,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  currentUserMetadata.tribeId &&
                  TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].userMapColor
              }}
            >
              <UserProfilePhoto
                tribeId={currentUserMetadata.tribeId}
                photoUrl={currentUserMetadata.photo}
                width={"22px"}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              position: "relative",
              width: 32,
              height: 32
            }}
          >
            <img
              width={"32px"}
              height={"32px"}
              src={NON_LOGIN_USER}
              alt="unLogin"
              style={{ position: "absolute" }}
            />
          </div>
        )}
      </div>
    </>
  );
}
