import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc } from "../../../../utils/utils";

export default function StanceTestResultCategory({
  category,
  percentage
}: {
  category: string;
  percentage: number;
}) {
  const [speakerCard, setSpeakerCard] = useState<CardSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(category.replace("#", ""))
      .get()
      .then(parseDoc)
      .then(setSpeakerCard);
  }, []);

  const photos = speakerCard?.hashtagMetadata?.photos;
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <div>
        {photos && (
          <img src={photos[1]} width={100} height={100} alt="hashtag_photo" />
        )}
      </div>
      {/*<div>*/}
      {/*  {category.replace("#", "")} {percentage}%*/}
      {/*</div>*/}
    </div>
  );
}
