import React from "react";
import CharacterLoader from "../../utility/OXOLoader/CharacterLoader";
import MyPoliticsForProfile from "./MyPoliticsForProfile";
import MyPostForProfile from "./MyPostForProfile";
import MyReplyForProfile from "./MyReplyForProfile";

export default function ProfilePagesRenderer({ tab }: { tab: string }) {
  switch (tab) {
    case "정치성향":
      return <MyPoliticsForProfile />;
    case "댓글":
      return <MyReplyForProfile />;
    case "생각":
      return <MyPostForProfile />;
    default:
      return <CharacterLoader />;
  }
}
