import React, { useEffect, useState } from "react";
import firebase from "firebase/compat";

import {
  CardAnswerStatsSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getDocsFromSnapshot, parseDoc } from "../../../../utils/utils";

export default function HashAnswerCount({
  hashtag,
  stanceTestLastQuestionCount = false
}: {
  hashtag: string;
  stanceTestLastQuestionCount?: boolean;
}) {
  const [lastQuestionId, setLastQuestionId] = useState<string>();
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (stanceTestLastQuestionCount) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .where("type", "==", CardType.TEST_QUESTION)
        .where("hashtags", "array-contains", "#" + hashtag)
        .get()
        .then(getDocsFromSnapshot)
        .then((cards: CardSchema[]) => {
          if (cards.length > 0) {
            const lastQuestion: CardSchema = cards[cards.length - 1];
            setLastQuestionId(lastQuestion.id);
          }
        });
    } else {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(hashtag)
        .get()
        .then(parseDoc)
        .then((snapshot: CardAnswerStatsSchema) => {
          setCardAnswerStats(snapshot);
        });
    }
  }, [hashtag, stanceTestLastQuestionCount]);

  useEffect(() => {
    if (lastQuestionId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(lastQuestionId)
        .get()
        .then(parseDoc)
        .then((snapshot: CardAnswerStatsSchema) => {
          setCardAnswerStats(snapshot);
        });
    }
  }, [lastQuestionId]);

  if (!cardAnswerStats) {
    return null;
  }

  return <div>{cardAnswerStats?.count?.toLocaleString("en-US") || 0}</div>;
}
