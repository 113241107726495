import React, { useContext, useEffect, useState } from "react";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { checkAndRedirectForLogin } from "../../../utils/utils";
import { addAnswer } from "../../../lib/answer_lib";
import { useHistory } from "react-router";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import { MiniOxButtonForHashCard } from "./MiniOxButtonForHashCard";
import { sendCoinFromOxoTo } from "../../../utils/assets";

export default function MiniOxButtonsForHashCard({
  cardId,
  additionalActionWhenClicked
}: {
  cardId: string;
  additionalActionWhenClicked?: () => void;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  const [requestedAnswer, setRequestedAnswer] = useState(cardAnswer?.answer);

  useEffect(() => {
    setRequestedAnswer(cardAnswer?.answer);
  }, [cardAnswer]);

  const updateVote = (answer: string) => {
    if (
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!answer || !currentUserMetadata || !currentUserId) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    if (!requestedAnswer) {
      sendCoinFromOxoTo(currentUserId, 2, `[1회성${cardId}]OX응답`);
    }

    setRequestedAnswer(answer);

    additionalActionWhenClicked && additionalActionWhenClicked();
    addAnswer(cardId, currentUserId, currentUserMetadata, answer);
  };

  const history = useHistory();

  if (!card) {
    return null;
  }

  // 자추 방지
  if (cardId === currentUserId) {
    return null;
  }

  // 자추 방지
  if (card.createdBy === currentUserId) {
    return null;
  }

  return (
    <div>
      <MiniOxButtonForHashCard
        onVote={updateVote}
        answer={cardAnswer?.answer}
      />
    </div>
  );
}
