import React, { useEffect, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import {
  flatten,
  getDocsFromSnapshot,
  parseDoc
} from "../../../../utils/utils";
import OXOInfiniteScroll from "../../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import HashCommentBubble from "../common/HashCommentBubble";
import firebase from "firebase/compat";

const CARDS_PER_PAGE = 20;

export default function HashPageStoryTimeline({
  hashtags,
  showDetails = true,
  sortBy = "oldest"
}: {
  hashtags: string[];
  showDetails?: boolean;
  sortBy?: string;
}) {
  const [page, setPage] = useState<number>(1);

  const [hashtagCards, setHashtagCards] = useState<CardSchema[]>();
  useEffect(() => {
    const hashtagCardsPromises: Promise<CardSchema>[] = hashtags.map(
      hashtag =>
        firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(hashtag)
          .get()
          .then(parseDoc) as Promise<CardSchema>
    );

    Promise.all(hashtagCardsPromises).then((hashtagCards: CardSchema[]) => {
      setHashtagCards(hashtagCards);
    });
  }, [hashtags]);

  const [cards, setCards] = useState<CardSchema[]>();

  useEffect(() => {
    const hashtagCardsPromises: Promise<CardSchema[]>[] = hashtags.map(
      hashtag =>
        firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .where("hashtags", "array-contains", "#" + hashtag)
          .where("type", "in", [
            CardType.NEWS_TICKER,
            CardType.TOPIC,
            CardType.HASHTAG_CARD,
            CardType.POST
          ])
          .orderBy("publishedAt", "desc")
          .limit(CARDS_PER_PAGE * (page + 2))
          .get()
          .then(getDocsFromSnapshot) as Promise<CardSchema[]>
    );

    Promise.all(hashtagCardsPromises).then((hashtagCards: CardSchema[][]) => {
      setCards(
        flatten(hashtagCards)
          .filter(
            card =>
              !(card.type === CardType.HASHTAG_CARD && card.id.includes("_"))
          )
          .filter(card => card.type !== CardType.TOPIC || card.displayToUsers)
          .sort(
            (a: CardSchema, b: CardSchema) =>
              (b.publishedAt || b.createdAt) - (a.publishedAt || a.createdAt)
          )
      );
    });
  }, [hashtags, page]);

  if (!cards) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        width: "100%"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: sortBy === "oldest" ? "column-reverse" : "column",
          overflow: "auto",
          paddingLeft: 10,
          paddingRight: 10,
          flexGrow: 1,
          alignItems: "center"
        }}
      >
        {cards.slice(0, CARDS_PER_PAGE * page).map((card, i) => {
          return (
            <div key={card.id} style={{ display: "grid", marginTop: 10 }}>
              <HashCommentBubble cardId={card.id} showDetails={showDetails} />
            </div>
          );
        })}
        <OXOInfiniteScroll
          setNextPage={() => setPage(prev => prev + 1)}
          style={{ height: 10 }}
        />
      </div>
    </div>
  );
}
