import firebase from "firebase/compat";
import {
  AssetBalanceSchema,
  AssetTransactionSchema,
  CardFeatureStatus,
  CardSchema,
  FirestoreCollection,
  TransactionSubType,
  TransactionType
} from "../constants/firestore_schema";
import { ADMIN_USER_IDS } from "../constants/user_metadata";
import { getDocsFromSnapshot, parseDoc } from "./utils";

export const EXCHANGE = "EXCHANGE";
export const OXO_COIN = "OXO_COIN";
export const MICROS = 1000000;

export interface CoinRewardEntity {
  userId: string;
  amount: number;
  transactionType: TransactionType;
  transactionSubType: TransactionSubType;
  transactionReferenceId?: string;
  reason?: string;
  popup?: boolean;
}

export interface UniqueKeyEntity {
  userId: string;
  transactionReferenceId?: string;
  reason: string;
}

export function getUniqueKey({
  userId,
  transactionReferenceId,
  reason
}: UniqueKeyEntity) {
  if (transactionReferenceId) {
    return userId + transactionReferenceId;
  }

  return userId + reason.replace(/\s/g, "_");
}

export function isTypeOXActiviy(
  transactionType: string,
  transactionSubType: string
) {
  return (
    transactionType === TransactionType.REPEATING_ACTIVITY &&
    transactionSubType === TransactionSubType.REPEATING_ACTIVITY_OX_ANSWER
  );
}

export async function sendCoinFromOxoToV2({
  userId,
  amount,
  transactionType,
  transactionSubType,
  transactionReferenceId,
  reason = ""
}: CoinRewardEntity) {
  const singleAssetTransaction = firebase
    .app()
    .functions("asia-northeast3")
    .httpsCallable("singleAssetTransaction");

  function rewardCoin(reason: string, title?: string) {
    singleAssetTransaction({
      fromUserId: EXCHANGE,
      toUserId: userId,
      assetType: OXO_COIN,
      amount,
      transactionType,
      transactionSubType,
      transactionReferenceId,
      reason: title || reason,
      uniqueKey: getUniqueKey({
        userId,
        transactionReferenceId,
        reason
      })
    }).catch(e => console.log(e, "likely to be a duplicate transaction"));
  }

  if (isTypeOXActiviy(transactionType, transactionSubType)) {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(transactionReferenceId)
      .get()
      .then(parseDoc)
      .then((card: CardSchema) => {
        if (card.featuredOnTodayFocus === CardFeatureStatus.ON) {
          rewardCoin(reason, card.title);
        }
      });
  } else {
    rewardCoin(reason);
  }
}

export async function sendCoinFromOxoTo(
  userId: string,
  amount: number,
  reason = "",
  popup = true
) {
  // 코인 획득 관리자만 가능하도록 설정 - 서비스 시작되면 제거예정
  if (ADMIN_USER_IDS.includes(userId)) {
    if (reason.startsWith("[1회성")) {
      const alreadySent = await alreadySentForSpecialReason(userId, reason);

      if (!alreadySent) {
        assetTransaction({
          fromUserId: EXCHANGE,
          toUserId: userId,
          fromAssetType: OXO_COIN,
          toAssetType: OXO_COIN,
          amount: amount,
          reason: reason,
          popup: popup
        });
      }
    } else {
      assetTransaction({
        fromUserId: EXCHANGE,
        toUserId: userId,
        fromAssetType: OXO_COIN,
        toAssetType: OXO_COIN,
        amount: amount,
        reason: reason,
        popup: popup
      });
    }
  }
}

export function alreadySentForSpecialReason(userId: string, reason: string) {
  return firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_TRANSACTIONS)
    .where("toUserId", "==", userId)
    .where("reason", "==", reason)
    .get()
    .then(getDocsFromSnapshot)
    .then(data => data.length != 0);
}

export async function buyAsset(
  userId: string,
  stock: string,
  stockPrice: number,
  stockAmount: number
) {
  const totalPrice = stockPrice * stockAmount;
  const hasEnoughCoin = await checkCoinBalance(userId, totalPrice);

  if (hasEnoughCoin) {
    assetTransaction({
      fromUserId: userId,
      toUserId: EXCHANGE,
      fromAssetType: OXO_COIN,
      toAssetType: OXO_COIN,
      amount: totalPrice,
      reason: "buyAsset"
    });
    assetTransaction({
      fromUserId: EXCHANGE,
      toUserId: userId,
      fromAssetType: stock,
      toAssetType: stock,
      amount: stockAmount,
      reason: "buyAsset"
    });
  }
}

export async function sellAsset(
  userId: string,
  stock: string,
  stockPrice: number,
  stockAmount: number
) {
  const hasEnoughAsset = await checkAssetBalance(userId, stock, stockAmount);

  if (hasEnoughAsset) {
    const totalPrice = stockPrice * stockAmount;
    assetTransaction({
      fromUserId: EXCHANGE,
      toUserId: userId,
      fromAssetType: OXO_COIN,
      toAssetType: OXO_COIN,
      amount: totalPrice,
      reason: "sellAsset"
    });
    assetTransaction({
      fromUserId: userId,
      toUserId: EXCHANGE,
      fromAssetType: stock,
      toAssetType: stock,
      amount: stockAmount,
      reason: "sellAsset"
    });
  }
}

export function checkAssetBalance(
  userId: string,
  stock: string,
  amount: number
) {
  return firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_BALANCES)
    .doc(userId + stock)
    .get()
    .then(parseDoc)
    .then(data => {
      if (data.amountMicros && data.amountMicros >= amount * MICROS) {
        return true;
      } else {
        alert("보유 주식이 부족합니다");
        return false;
      }
    });
}

export function checkCoinBalance(userId: string, amount: number) {
  return firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_BALANCES)
    .doc(userId + OXO_COIN)
    .get()
    .then(parseDoc)
    .then(data => {
      if (data.amountMicros && data.amountMicros >= amount * MICROS) {
        return true;
      } else {
        alert("보유 옥소가 부족합니다");
        return false;
      }
    });
}

export function assetTransaction({
  fromUserId,
  toUserId,
  fromAssetType,
  toAssetType,
  amount,
  reason = "",
  popup = false
}: {
  fromUserId: string;
  toUserId: string;
  fromAssetType: string;
  toAssetType: string;
  amount: number;
  reason?: string;
  popup?: boolean;
}) {
  const timestamp = Date.now();

  const transaction: AssetTransactionSchema = {
    createdAt: timestamp,
    fromUserId: fromUserId,
    toUserId: toUserId,
    fromAssetType: fromAssetType,
    toAssetType: toAssetType,
    fromAssetTypeAmountMicros: amount * MICROS,
    toAssetTypeAmountMicros: amount * MICROS,
    reason: reason
  };

  const balanceFromUser: AssetBalanceSchema = {
    userId: fromUserId,
    updatedAt: timestamp,
    assetType: fromAssetType
  };

  const balanceToUser: AssetBalanceSchema = {
    userId: toUserId,
    updatedAt: timestamp,
    assetType: fromAssetType
  };

  firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_TRANSACTIONS)
    .doc()
    .set(transaction)
    .then(() => {
      console.log("asset Transaction Success");
    });

  firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_BALANCES)
    .doc(fromUserId + fromAssetType)
    .set(balanceFromUser, { merge: true })
    .then(() => {
      firebase
        .firestore()
        .collection(FirestoreCollection.ASSET_BALANCES)
        .doc(fromUserId + fromAssetType)
        .update({
          amountMicros: firebase.firestore.FieldValue.increment(
            -amount * 1000000
          )
        })
        .then(() => {
          if (fromUserId == EXCHANGE && fromAssetType != OXO_COIN) {
            setNewAmmPrice(fromUserId + fromAssetType);
          }
        });
    });

  firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_BALANCES)
    .doc(toUserId + toAssetType)
    .set(balanceToUser, { merge: true })
    .then(() => {
      let newData;

      if (popup) {
        let popupText = reason.split("]")?.[1] || reason.split("]")?.[0] || "";
        if (popupText.includes("테스트") || popupText.includes("선거")) {
          popupText = "테스트 참여";
        }

        newData = {
          amountMicros: firebase.firestore.FieldValue.increment(
            amount * 1000000
          ),
          popupText: popupText + " " + amount
        };
      } else {
        newData = {
          amountMicros: firebase.firestore.FieldValue.increment(
            amount * 1000000
          )
        };
      }

      firebase
        .firestore()
        .collection(FirestoreCollection.ASSET_BALANCES)
        .doc(toUserId + toAssetType)
        .update(newData)
        .then(() => {
          if (toUserId == EXCHANGE && toAssetType != OXO_COIN) {
            setNewAmmPrice(toUserId + toAssetType);
          }
        });
    });
}

export function setNewAmmPrice(id: string) {
  firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_BALANCES)
    .doc(id)
    .get()
    .then(parseDoc)
    .then(doc => {
      const release = doc.amountMicros / 1000000;
      let refVal = 10000 + release;
      if (refVal < 1) {
        refVal = 1;
      }

      const newPrice = Math.round((doc.initPrice * 10000) / refVal);

      firebase
        .firestore()
        .collection(FirestoreCollection.ASSET_BALANCES)
        .doc(id)
        .update({ price: newPrice });
    });
}

export function resetAmountPopup(userId: string) {
  firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_BALANCES)
    .doc(userId + "OXO_COIN")
    .update({ popupText: "0" });
}

export function regAsset(id: string) {
  firebase
    .firestore()
    .collection(FirestoreCollection.ASSET_VALUES)
    .doc(id)
    .get()
    .then(s => {
      if (s.exists) {
        alert(id + " : 이미 등록이 된 Asset 입니다");
      } else {
        firebase
          .firestore()
          .collection(FirestoreCollection.ASSET_VALUES)
          .doc(id)
          .set({
            asset: id,
            amountMicros: 100 * 1000000,
            createdAt: Date.now()
          });
      }
    });
}

// 코인 서비스전 테스트용 코드 시작 - 코인 서비스 런칭후 삭제예정
export function resetAsset() {
  deleteCollection(FirestoreCollection.ASSET_BALANCES);
  deleteCollection(FirestoreCollection.ASSET_TRANSACTIONS);
  deleteCollection(FirestoreCollection.ASSET_VALUES);
  deleteCollection(FirestoreCollection.ASSET_VALUES_HISTORY);
}

export function deleteCollection(collection: string) {
  firebase
    .firestore()
    .collection(collection)
    .get()
    .then(s => {
      s.docs.forEach(doc => {
        firebase.firestore().collection(collection).doc(doc.id).delete();
      });
    });
}

// 서비스전 테스트용 코드 끝
