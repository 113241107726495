/** @jsxImportSource @emotion/react */

import { TodayPolitician } from "./TodayPolitician";
import React, { useEffect, useState } from "react";
import { HashtagPersonOnTimeline } from "../Today/HashtagPersonOnTimeline";
import { css } from "@emotion/react";
import { Add } from "@material-ui/icons";
import firebase from "firebase/compat";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { parseDoc } from "../../../utils/utils";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";

const PEOPLE_PER_PAGE = 10;
export function PoliticianReports({
  additionalSupporter,
  hashtags,
  sortByPopularityDesc = true,
  addAddButton = false
}: {
  additionalSupporter: string;
  hashtags: string[];
  sortByPopularityDesc?: boolean;
  addAddButton?: boolean;
}) {
  const [page, setPage] = useState(1);
  const [selectedHashtag, setSelectedHashtag] = useState<string>(hashtags[0]);

  const [sortedNames, setSortedNames] = useState<string[]>();
  useEffect(() => {
    if (!sortByPopularityDesc) {
      setSortedNames(hashtags);
      return;
    }

    const promises = hashtags.map(hashtagName =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(hashtagName)
        .get()
        .then(parseDoc)
    );
    Promise.all(promises).then((cardAnswerStats: CardAnswerStatsSchema[]) => {
      const sortedStats = cardAnswerStats
        .filter(cardAnswerStats => cardAnswerStats.count > 1)
        .sort((a, b) => {
          return (b.o || 0) / (b.count || 1) - (a.o || 0) / (a.count || 1);
        });

      setSortedNames(
        sortedStats.map(
          questionAnswerStats => questionAnswerStats.id
        ) as string[]
      );
    });
    return () => {};
  }, [hashtags]);

  if (!sortedNames) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          overflow: auto;
        `}
      >
        {sortedNames.slice(0, page * PEOPLE_PER_PAGE).map(hashtag => (
          <div
            key={hashtag}
            css={css`
              margin: 10px;
              position: relative;
            `}
          >
            <HashtagPersonOnTimeline hashtag={hashtag} key={hashtag} />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                height: 140px;
                width: 100px;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
              `}
              onClick={() => {
                setSelectedHashtag(hashtag);
              }}
            />
          </div>
        ))}

        {addAddButton && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              border: solid black 1px;
              height: 140px;
              width: 100px;
              margin: 10px;
            `}
          >
            <Add />
          </div>
        )}
        <OXOInfiniteScroll setNextPage={() => setPage(p => p + 1)} />
      </div>

      <div
        css={css`
          margin-top: 40px;
        `}
      >
        <TodayPolitician
          hashtag={selectedHashtag}
          additionalSupporter={additionalSupporter}
        />
      </div>
    </div>
  );
}
