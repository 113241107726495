import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/compat";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import ChatRoom from "../../chat/ChatRoom";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { parseDoc } from "../../../utils/utils";

export default function DetailPagesTalkTab({ cardId }: { cardId: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const [windowWidth, windowHeight] = useWindowSize();

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .get()
      .then(snapshot => {
        setCard(parseDoc(snapshot));
      });
  }, [cardId]);

  if (!card) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: windowHeight - 160
        }}
      >
        <ChatRoom
          chatRoomId={cardId}
          style={{ padding: "0px 10px 0px 10px" }}
        />
      </div>
    </div>
  );
}
