/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import {
  CardAnswerSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { css } from "@emotion/react";
import {
  getDocsFromSnapshot,
  groupBy,
  listToMap,
  mapValues
} from "../../../../utils/utils";
import { VictoryBar, VictoryGroup, VictoryStack } from "victory";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";

export default function CardAnswerSupporterBarChartForReport({
  cardId,
  supporters,
  label,
  order = 0,
  isAgree,
  personHashtag,
  colorScale = [
    DATA_PLATFORM_RED_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_GREEN_GRAY
  ]
}: {
  cardId: string;
  supporters: {
    [key: string]: string[];
  };
  label: string;
  order?: number;
  isAgree: boolean;
  personHashtag: string;
  colorScale?: [string, string, string];
}) {
  const [cardAnswers, setCardAnswers] = useState<CardAnswerSchema[]>();
  useEffect(() => {
    if (cardId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWERS)
        .where("cardId", "==", cardId)
        .get()
        .then(getDocsFromSnapshot)
        .then(setCardAnswers);
    }
  }, [cardId]);

  const [supporterAnswerStats, setSupporterAnswerStats] = useState<{
    [supportingStatus: string]: {
      [answer: string]: number;
    };
  }>();
  useEffect(() => {
    if (!cardAnswers) {
      return;
    }

    const userAnswerMap = listToMap(
      cardAnswers,
      cardAnswer => cardAnswer.userId,
      cardAnswer => cardAnswer.answer
    ) as {
      [key: string]: string;
    };

    setSupporterAnswerStats(
      mapValues(supporters, userIds => {
        const answers = userIds
          .map(userId => userAnswerMap[userId])
          .filter(a => a) as string[];
        return mapValues(
          groupBy(answers, answer => answer),
          answers => answers.length
        );
      })
    );
  }, [cardAnswers]);

  const AllAnswerStat = useMemo(() => {
    if (!supporterAnswerStats) {
      return undefined;
    }
    const AllAnswerStat: {
      x: number;
      count: number;
      o: number;
      "?": number;
    } = {
      x: 0,
      count: 0,
      o: 0,
      "?": 0
    };

    if (AllAnswerStat) {
      Object.values(supporterAnswerStats).map(answer => {
        answer.x && (AllAnswerStat.x += answer.x);
        answer.o && (AllAnswerStat.o += answer.o);
        answer["?"] && (AllAnswerStat["?"] += answer["?"]);
      });

      AllAnswerStat.count = Object.values(AllAnswerStat).reduce(
        (a, b) => a + b,
        0
      );
    }
    return AllAnswerStat;
  }, [supporterAnswerStats]);

  const supporterAnswerStat = useMemo(() => {
    if (!supporterAnswerStats) {
      return undefined;
    }
    const supporterAnswerStat = supporterAnswerStats.o as {
      x: number;
      count: number;
      o: number;
      "?": number;
    };

    if (supporterAnswerStat) {
      supporterAnswerStat.count = Object.values(supporterAnswerStat).reduce(
        (a, b) => a + b,
        0
      );
    }
    return supporterAnswerStat;
  }, [supporterAnswerStats]);

  const antiAnswerStat = useMemo(() => {
    if (!supporterAnswerStats) {
      return undefined;
    }
    const antiAnswerStat = supporterAnswerStats.x as {
      x: number;
      count: number;
      o: number;
      "?": number;
    };

    if (supporterAnswerStat) {
      antiAnswerStat.count = Object.values(antiAnswerStat).reduce(
        (a, b) => a + b,
        0
      );
    }
    return antiAnswerStat;
  }, [supporterAnswerStats]);

  if (!cardAnswers || !supporterAnswerStat || !antiAnswerStat) {
    return null;
  }

  let chartData: { x: number; count: number; o: number; "?": number } = {
    x: 0,
    count: 0,
    o: 0,
    "?": 0
  };

  if (order === 0) AllAnswerStat && (chartData = AllAnswerStat);
  if (order === 1) chartData = supporterAnswerStat;
  if (order === 2) chartData = antiAnswerStat;

  function getAgreePer(cardStats: {
    x: number;
    count: number;
    o: number;
    "?": number;
  }) {
    return (
      cardStats &&
      ((cardStats.o || 0) /
        ((cardStats.o || 0) + (cardStats.x || 0) + (cardStats["?"] || 0))) *
        100
    );
  }

  function getDisagreePer(cardStats: {
    x: number;
    count: number;
    o: number;
    "?": number;
  }) {
    return (
      cardStats &&
      ((cardStats.x || 0) /
        ((cardStats.o || 0) + (cardStats.x || 0) + (cardStats["?"] || 0))) *
        100
    );
  }

  return (
    <div
      css={css`
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 -12px;
      `}
    >
      <div
        css={css`
          width: 90px;
          height: 70px;
          margin-left: 40px;
          margin-bottom: 10px;
        `}
      >
        <VictoryGroup height={50} width={62} padding={0}>
          <VictoryStack colorScale={colorScale}>
            <VictoryBar
              data={chartData.x ? [chartData.x] : [0]}
              alignment="start"
            />
            <VictoryBar
              data={chartData["?"] ? [chartData["?"]] : [0]}
              alignment="start"
            />
            <VictoryBar
              data={chartData.o ? [chartData.o] : [0]}
              alignment="start"
            />
          </VictoryStack>
        </VictoryGroup>
      </div>
      <div
        css={css`
          font-size: 14px;
          margin-top: 2px;
          font-weight: 700;
          text-align: center;
          line-height: 20px;
        `}
      >
        {label}
        <br />
        {chartData && chartData.count === 0
          ? "응답이 없어요"
          : isAgree
          ? `
    ${chartData && getAgreePer(chartData).toFixed()}% 찬성`
          : `${chartData && getDisagreePer(chartData).toFixed()}% 반대`}
      </div>
    </div>
  );
}
