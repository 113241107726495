import React, { useEffect, useState } from "react";
import {
  ChatSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc } from "../../utils/utils";
import ChatLine from "./ChatLine";

export default function HighlightChatlineWrapper({
  chatId,
  onChatBubbleClick,
  isDark
}: {
  chatId: string;
  onChatBubbleClick: () => void;
  isDark: boolean;
}) {
  const [chat, setChat] = useState<ChatSchema>();

  useEffect(() => {
    if (!chat && chatId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CHATS)
        .doc(chatId)
        .get()
        .then(parseDoc)
        .then(setChat);
    }
  }, [chatId]);

  if (!chat || chat.tribeId === undefined) {
    return null;
  }

  return (
    <ChatLine
      chat={chat}
      firstLine
      lastLine
      onChatBubbleClick={onChatBubbleClick}
      isDark={isDark}
      showReplies={false}
    />
  );
}
