import { MenuItem } from "@material-ui/core";
import { ChatBubbleOutlined } from "@material-ui/icons";
import React from "react";

export default function AddChatAsCommentButton({
  onClose
}: {
  onClose: () => void;
}) {
  return (
    <MenuItem
      key={"report"}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: 14
      }}
      onClick={() => {
        onClose();
      }}
    >
      <div style={{ marginRight: 5 }}>
        <ChatBubbleOutlined />
      </div>{" "}
      <div style={{ paddingBottom: 7 }}>댓글로 등록하기</div>
    </MenuItem>
  );
}
