/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsTimeSeriesSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  mapValues,
  sumTwoAnswerCounts
} from "../../../../utils/utils";
import { formatKoreanDateIso, todayInKorea } from "../../../../utils/datetime";
import CardAnswerTribeBarChart from "../../HashCardView/Comment/CardAnswerTribeBarChart";
import { DATA_PLATFORM_RED_GRAY } from "../../../../constants/data_platform_colors";

export default function SupporterAnalysisReportDetails({
  hashtag,
  selectedDate
}: {
  hashtag: string;
  selectedDate: number;
}) {
  const [ageGenderStats, setAgeGenderStats] =
    useState<CardAnswerAgeGenderStatsTimeSeriesSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS_TIME_SERIES)
      .where("cardId", "==", hashtag)
      .where(
        "dateInKorea",
        "<=",
        formatKoreanDateIso(todayInKorea(selectedDate))
      )
      .orderBy("dateInKorea", "desc")
      .limit(5)
      .get()
      .then(getDocsFromSnapshot)
      .then(setAgeGenderStats);
  }, [hashtag]);

  const statsByAgeGroups = useMemo(() => {
    if (!ageGenderStats || ageGenderStats.length === 0) {
      return;
    }
    return [ageGenderStats[0], ageGenderStats[ageGenderStats.length - 1]].map(
      cardAnswerAgeGenderStat => {
        return Object.keys(cardAnswerAgeGenderStat).reduce<{
          [ageGroup: string]: {
            [gender: string]: {
              o: number;
              "?": number;
              x: number;
              count: number;
            };
          };
        }>((prev, birthYear) => {
          const ageGroupNumber =
            Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) *
            10;
          if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
            return prev;
          }

          const ageGroup = ageGroupNumber.toFixed();

          const birthYearGenderStat =
            cardAnswerAgeGenderStat?.[Number(birthYear)];
          if (birthYearGenderStat) {
            if (prev[ageGroup]) {
              prev[ageGroup]["male"] = sumTwoAnswerCounts(
                (prev[ageGroup]["male"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                }) as AnswerCountsSchema,
                (birthYearGenderStat["male"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                }) as AnswerCountsSchema
              );

              prev[ageGroup]["female"] = sumTwoAnswerCounts(
                prev[ageGroup]["female"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                },
                birthYearGenderStat["female"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                }
              ) as {
                o: number;
                "?": number;
                x: number;
                count: number;
              };
            } else {
              prev[ageGroup] = birthYearGenderStat;
            }
          }
          return prev;
        }, {});
      }
    );
  }, [ageGenderStats]);

  const ageGenderRatios = useMemo(() => {
    if (!statsByAgeGroups) {
      return;
    }
    return statsByAgeGroups.map((statsByAgeGroup, i) =>
      mapValues(statsByAgeGroup, genderGroups =>
        mapValues(genderGroups, genderGroup =>
          genderGroup.count < 5
            ? 0
            : (genderGroup.o || 0) / (genderGroup.count || 1)
        )
      )
    );
  }, [statsByAgeGroups]);

  const gainAgeGenders = useMemo(() => {
    if (!ageGenderRatios) {
      return undefined;
    }
    const gainAgeGender: {
      age: string;
      gender: string;
      gain: number;
    }[] = [];
    const currentRatio = ageGenderRatios[0];
    const lastRatio = ageGenderRatios[1];
    mapValues(currentRatio, (genderGroups, ageGroup) => {
      return mapValues(genderGroups, (value: number, gender) => {
        const diffRatio =
          (value / (lastRatio[ageGroup]?.[gender] || 1) || 1) - 1;
        if (diffRatio !== 0) {
          gainAgeGender.push({
            age: ageGroup,
            gender: gender,
            gain: diffRatio
          });
        }
        return diffRatio;
      });
    });
    return gainAgeGender;
  }, [ageGenderRatios]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div css={css``}>
        <CardAnswerTribeBarChart cardId={hashtag} />
      </div>

      <div>
        <div
          css={css`
            margin-top: 40px;
            font-size: 20px;
            font-weight: 700;
            color: ${DATA_PLATFORM_RED_GRAY};
          `}
        >
          {gainAgeGenders &&
            gainAgeGenders
              .sort((ga, gb) => ga.gain - gb.gain)
              .map(
                (gainAgeGender, i) =>
                  i < 2 && (
                    <div
                      key={gainAgeGender.age + gainAgeGender.gender}
                      css={css`
                        margin-top: 10px;
                        font-size: 20px;
                      `}
                    >
                      {gainAgeGender.gain > 0 ? "▲" : "▼"} 최근
                      {gainAgeGender.age}대{" "}
                      {gainAgeGender.gender === "male" ? "남" : "여"}
                      성의 지지율이{" "}
                      {Math.abs(gainAgeGender.gain * 100).toFixed()}%{" "}
                      {gainAgeGender.gain > 0 ? "상승" : "하락"}
                      했어요
                    </div>
                  )
              )}
        </div>
      </div>
    </div>
  );
}
