/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";

import {
  AnswerCountSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";

import { getAgeStatsByAgeGroup } from "../../../../utils/ageGender";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

import AgeBarChartV2 from "./AgeBarChartV2";

export default function AgeBarChartV2Container({
  cardId,
  averageCardAnswer
}: {
  cardId: string;

  averageCardAnswer?: AnswerCountSchema;
}) {
  const ageGenderStats =
    useFirestoreGetDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  if (!ageGenderStats) {
    return null;
  }

  const ageStatsByAgeGroup = getAgeStatsByAgeGroup(ageGenderStats);

  return (
    <div
      css={css`
        width: 400px;
        height: 360px;
        margin-top: 40px;
        margin-bottom: 8px;
      `}
    >
      {averageCardAnswer && (
        <AgeBarChartV2 key={"전체"} age={"전체"} stats={averageCardAnswer} />
      )}
      {Object.keys(ageStatsByAgeGroup).map(age => {
        return (
          <AgeBarChartV2
            key={age}
            age={age + "대"}
            stats={ageStatsByAgeGroup[age]}
          />
        );
      })}
    </div>
  );
}
