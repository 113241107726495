/** @jsxImportSource @emotion/react */

import React, { useContext, useEffect, useState } from "react";
import { AnswerSymbol, oxoColors, UserAnswer } from "../../../constants/enums";
import UserMap, {
  SeriesType
} from "../../foundation/Charts/UserMap/UserMap-victory";
import firebase from "firebase/compat";
import {
  FirestoreCollection,
  MapCoordinatesSchema,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { parseDoc, removeIdKey } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { css } from "@emotion/react";

enum SupporterChoiceOption {
  SUPPORTER = "supporter",
  NOT_DETERMINED = "not_determined",
  AGAINST = "against"
}

const PILL_BASE = css`
  font-size: 12px;
  padding: 5px 10px;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 20px;
  border: solid 1px;
  cursor: pointer;
`;

const SELECTED_PILL_STYLE = css`
  color: black;
  border-color: black;
`;
const UNSELECTED_PILL_STYLE = css`
  color: #e5e5e5;
  border-color: #e5e5e5;
`;

export default function SupporterStatistics({ cardId }: { cardId: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [supporterChoice, setSupporterChoice] = useState<SupporterChoiceOption>(
    SupporterChoiceOption.SUPPORTER
  );
  const [questionAnswers, setQuestionAnswers] =
    React.useState<QuestionAnswerMapSchema>();

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setQuestionAnswers(removeIdKey(parseDoc(snapshot)));
      });
    return () => {
      unsubscriber();
    };
  }, [cardId]);

  if (!dataProps?.mapCoordinates) {
    return null;
  }

  let series = undefined;

  if (questionAnswers) {
    series = getMapSeries(
      questionAnswers,
      dataProps.mapCoordinates,
      currentUserMetadata,
      supporterChoice
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginTop: "10px"
        }}
      >
        <div
          css={[
            PILL_BASE,
            supporterChoice === SupporterChoiceOption.SUPPORTER
              ? SELECTED_PILL_STYLE
              : UNSELECTED_PILL_STYLE
          ]}
          onClick={() => {
            setSupporterChoice(SupporterChoiceOption.SUPPORTER);
          }}
        >
          지지층
        </div>
        <div
          css={[
            PILL_BASE,
            supporterChoice === SupporterChoiceOption.NOT_DETERMINED
              ? SELECTED_PILL_STYLE
              : UNSELECTED_PILL_STYLE
          ]}
          onClick={() => {
            setSupporterChoice(SupporterChoiceOption.NOT_DETERMINED);
          }}
        >
          유보층
        </div>
        <div
          css={[
            PILL_BASE,
            supporterChoice === SupporterChoiceOption.AGAINST
              ? SELECTED_PILL_STYLE
              : UNSELECTED_PILL_STYLE
          ]}
          onClick={() => {
            setSupporterChoice(SupporterChoiceOption.AGAINST);
          }}
        >
          반대층
        </div>
      </div>

      <div style={{ marginBottom: "20px", maxHeight: 700 }}>
        {series && (
          <div style={{ marginTop: "10px" }}>
            <UserMap series={series} />
          </div>
        )}
      </div>
    </div>
  );
}

function getMapSeries(
  questionAnswers: QuestionAnswerMapSchema,
  mapCoordinates: MapCoordinatesSchema,
  userProfileMetadata: UserProfileMetadataSchema | undefined,
  supporterChoice: SupporterChoiceOption
) {
  const seriesData: { [seriesName: string]: number[][] } = {
    me: [],
    unanswered: []
  };

  const mapWidth = Math.min(window.screen.width, 750);

  seriesData[UserAnswer.O] = [];
  seriesData[UserAnswer.X] = [];
  seriesData[UserAnswer.DUNNO] = [];

  Object.keys(questionAnswers).forEach(coordinateId => {
    const arr = [
      questionAnswers[coordinateId].countO,
      questionAnswers[coordinateId].countX,
      questionAnswers[coordinateId].countDunno
    ];
    const i = arr.indexOf(Math.max(...arr));

    if (i === 0) {
      seriesData[UserAnswer.O].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 1) {
      seriesData[UserAnswer.X].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 2) {
      seriesData[UserAnswer.DUNNO].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    }
  });

  const defaultSize = mapWidth / 350;

  Object.keys(mapCoordinates).map(coordinateId => {
    const mapCoordinate = mapCoordinates[coordinateId];
    seriesData["unanswered"].push([mapCoordinate.x, mapCoordinate.y]);
  });

  const series: SeriesType[] = [
    supporterChoice === SupporterChoiceOption.SUPPORTER
      ? {
          name: AnswerSymbol.O,
          data: seriesData[UserAnswer.O] || [],
          color: "black",
          size: defaultSize + 1
        }
      : supporterChoice === SupporterChoiceOption.NOT_DETERMINED
      ? {
          name: AnswerSymbol.DUNNO,
          data: seriesData[UserAnswer.DUNNO] || [],
          color: "black",
          size: defaultSize + 1
        }
      : {
          name: AnswerSymbol.X,
          data: seriesData[UserAnswer.X] || [],
          color: "black",
          size: defaultSize + 1
        },
    {
      name: "응답 안 함",
      data: seriesData["unanswered"] || [],
      color: oxoColors.OTHERS,
      size: defaultSize,
      opacity: 0.4
    }
  ];

  if (userProfileMetadata) {
    series.unshift({
      name: "나",
      data: [[userProfileMetadata.x, userProfileMetadata.y]],
      color: "#333333",
      label: "나",
      size: 4
    });
  }

  return series;
}
