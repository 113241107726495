import firebase from "firebase/compat";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import {
  BestCommentsForCardSchema,
  CardAnswerSchema,
  CardSchema,
  CommentSchema,
  FirestoreCollection,
  LikeUserAndTribesSchema
} from "../../../../constants/firestore_schema";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import {
  getCommentLikeByTribe,
  groupBy,
  mapValues,
  parseDoc,
  toHashMap
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";

import TribeButtons from "../../../views/StatsView/TribeButtons";

import CommentLine from "./CommentLine";
import NoContentForTribe from "./NoContentForTribe";
import SwipeableViews from "react-swipeable-views";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { isBadContents } from "../../../../utils/contents";

export default function VerticalCommentsTab({
  currentTribeId,
  comments,
  cardId,
  disableExpressions = false
}: {
  comments: CommentSchema[];
  cardId: string;
  currentTribeId: string;
  disableExpressions?: boolean;
}) {
  const [windowWidth, windowHeight] = useWindowSize();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(currentTribeId) - 1
  );

  const [cardAnswer, setCardAnswer] = useState<CardAnswerSchema>();

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .doc(currentUserId + cardId)
      .onSnapshot(snapshot => setCardAnswer(parseDoc(snapshot)));

    return () => {
      unsubscriber();
    };
  }, [currentUserId, cardId]);

  const answer = cardAnswer?.answer;

  const [tabTribeId, setTabTribeId] = useState<string>(currentTribeId);

  useEffect(() => {
    setTabIndex(parseInt(tabTribeId) - 1);
  }, [tabTribeId]);

  const bestCommentIdPerTribe: { [tribeId: string]: string } = {};
  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => unsubscribe();
  }, [cardId]);

  const commentsMap: { [commentId: string]: CommentSchema } = toHashMap("id")(
    comments
  ) as { [commentId: string]: CommentSchema };

  const commentsDataByTribeDistance: {
    [tribeId: string]: CommentSchema[];
  } = groupBy(
    comments
      .filter(
        comment => !currentUserMetadata?.blockedUsers?.includes(comment.userId)
      )
      .filter(comment => !comment.isSpecialUser)
      .filter(comment => comment.userTribeId),
    (comment: CommentSchema) => comment.userTribeId || ""
  );

  useEffect(() => {
    if (!tabTribeId && card && currentUserId) {
      if (
        comments.some(comment => comment.likeUserAndTribes?.[currentUserId])
      ) {
        setTabTribeId("1");
      } else {
        setTabTribeId(currentTribeId);
      }
    }
  }, [card, comments, currentUserId]);

  Object.keys(commentsDataByTribeDistance).forEach(tribeId => {
    const comments = commentsDataByTribeDistance[tribeId];
    const bestComment = comments
      .filter(comment => !comment.isSpecialUser)
      .filter(comment => !comment.bad && !isBadContents(comment.comment))
      .sort(
        (comment1, comment2) =>
          Object.keys(getCommentLikeByTribe(comment2)).length -
          Object.keys(getCommentLikeByTribe(comment1)).length
      )
      .sort(
        (comment1, comment2) =>
          commentLikeCountPerTribe(getCommentLikeByTribe(comment2), tribeId) -
          commentLikeCountPerTribe(getCommentLikeByTribe(comment1), tribeId)
      )[0];

    if (bestComment) {
      bestCommentIdPerTribe[tribeId] = bestComment.id;
    }
  });

  useEffect(() => {
    if (card) {
      if (
        !card.bestComments ||
        Object.values(card.bestComments).toString() !==
          Object.values(bestCommentIdPerTribe).toString()
      ) {
        firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(cardId)
          .update({
            bestComments: bestCommentIdPerTribe
          });
      }
      if (
        !bestComments ||
        Object.values(bestComments).toString() !==
          Object.values(bestCommentIdPerTribe).toString()
      ) {
        firebase
          .firestore()
          .collection(FirestoreCollection.BEST_COMMENTS_FOR_CARD)
          .doc(cardId)
          .set(bestCommentIdPerTribe);
      }
    }
    return () => {};
  }, [card, Object.values(bestCommentIdPerTribe).toString()]);

  function commentLikeCountPerTribe(
    likeUserAndTribes: LikeUserAndTribesSchema,
    tribeId: string
  ) {
    return Object.keys(likeUserAndTribes).filter(
      likeUser => likeUserAndTribes[likeUser] === tribeId
    ).length;
  }

  const commentIdsByTribeDistance: { [tribeId: string]: string[] } = useMemo(
    () =>
      mapValues(
        commentsDataByTribeDistance,
        (comments: CommentSchema[], tribeId: string) => {
          return (
            comments
              // .sort((comment1, comment2) =>
              //   commentSortBy === "best"
              //     ? Object.keys(getCommentLikeByTribe(comment2)).length -
              //       Object.keys(getCommentLikeByTribe(comment1)).length
              //     : comment2.createdAt - comment1.createdAt
              // )
              .sort(
                (comment1, comment2) =>
                  Object.keys(getCommentLikeByTribe(comment2)).length -
                  Object.keys(getCommentLikeByTribe(comment1)).length
              )
              .sort(
                (comment1, comment2) =>
                  commentLikeCountPerTribe(
                    getCommentLikeByTribe(comment2),
                    tribeId
                  ) -
                  commentLikeCountPerTribe(
                    getCommentLikeByTribe(comment1),
                    tribeId
                  )
              )
              .map(comment => comment.id)
          );
        }
      ),
    [comments.length]
  );

  function getTabPane(tribeId: string) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
          key={"tribe" + tribeId}
          id={"horizontalComments" + tribeId}
        >
          {/*TODO: add pagination or infinite scroll*/}
          {[
            ...(commentIdsByTribeDistance[tribeId]
              ? commentIdsByTribeDistance[tribeId].map((commentId, i) => {
                  const comment = commentsMap[commentId];
                  return (
                    <div
                      style={{
                        marginTop: "5px",
                        width: "100%",
                        minHeight: 40,
                        position: "relative",
                        marginBottom:
                          commentIdsByTribeDistance[tribeId].length >= 4 &&
                          i === commentIdsByTribeDistance[tribeId].length - 1
                            ? 100
                            : 0
                      }}
                      key={commentId + i}
                    >
                      <CommentLine
                        commentId={comment.id}
                        disableExpressions={disableExpressions}
                      />
                    </div>
                  );
                })
              : [
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      textAlign: "center"
                    }}
                    key={"pig" + tribeId}
                  >
                    <NoContentForTribe tribeId={tribeId} />
                  </div>
                ]),
            <div style={{ minWidth: "20px" }} key={"last"} />
          ]}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          width: Math.min(windowWidth, 750),
          backgroundColor: "white",
          textAlign: "center"
        }}
      >
        <TribeButtons
          currentTribeId={tabTribeId}
          setCurrentTribeId={setTabTribeId}
          badges={Object.keys(TRIBE_INFO_BY_ID)
            .map(tribeId =>
              commentIdsByTribeDistance[tribeId]
                ? commentIdsByTribeDistance[tribeId].length
                : 0
            )
            .map(a => a.toString())}
        />
      </div>
      <div style={{ height: windowHeight - 200, overflowY: "auto" }}>
        <SwipeableViews
          enableMouseEvents
          index={tabIndex}
          onChangeIndex={(i, prevI) => {
            setTabIndex(i);
            setTabTribeId((i + 1).toString());
          }}
        >
          {["1", "2", "3", "4", "5"].map(tribeIdIndex => {
            return tribeIdIndex === tabTribeId ? (
              <div
                style={{
                  paddingTop: 10,
                  minHeight: "50vh",
                  paddingBottom: 70
                }}
                key={tribeIdIndex}
              >
                <div>{tabTribeId && getTabPane(tabTribeId)}</div>
              </div>
            ) : (
              <div key={tribeIdIndex} />
            );
          })}
        </SwipeableViews>
      </div>
    </div>
  );
}
