/** @jsxImportSource @emotion/react */

import React from "react";
import PartyPersonSelector from "../DailyReportsView/PartyPersonSelector";
import SupporterSummary from "./SupporterSummary";

export function SupporterSelector({
  selectedDate,
  selectedHashtag,
  setSelectedHashtag,
  setSelectedCard
}: {
  selectedDate: number;
  selectedHashtag: string;
  setSelectedHashtag: (hashtag: string) => void;
  setSelectedCard: (cardId: string) => void;
}) {
  const buttonStyle = {
    backgroundColor: "white",
    borderRadius: "20px",
    border: "solid 1px black",
    color: "black"
  };
  return (
    <div>
      <PartyPersonSelector
        hashtag={selectedHashtag}
        selectedDate={selectedDate}
        setSelectedHashtag={setSelectedHashtag}
      />
      <SupporterSummary hashtag={selectedHashtag} selectedDate={selectedDate} />
    </div>
  );
}
