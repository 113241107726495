import React, { useContext } from "react";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import UserProfileCommentsTimeline from "./UserProfileCommentsTimeline";

export default function UserReplyForProfile({
  profileUserId
}: {
  profileUserId: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  return (
    <div style={{ width: "100%" }}>
      {currentUserId && (
        <UserProfileCommentsTimeline profileUserId={profileUserId} />
      )}
    </div>
  );
}
