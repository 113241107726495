/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import {
  getAgreePercentage,
  getDisagreePercentage,
  getDunnoPercentage
} from "../../../../utils/data_utils";
import { css } from "@emotion/react";
import { VictoryPie } from "victory";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";

export default function CardAnswerPieChartForReport({
  cardId,
  size,
  showPercentage = false,
  colorScale = [
    DATA_PLATFORM_RED_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_GREEN_GRAY
  ]
}: {
  cardId: string;
  size?: number;
  showPercentage?: boolean;
  colorScale?: [string, string, string];
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats || cardAnswerStats.count === 0) {
    return null;
  }

  const stats = [
    {
      x: 0,
      y: cardAnswerStats.x || 0
    },

    {
      x: 0,
      y: cardAnswerStats["?"] || 0
    },
    {
      x: 0,
      y: cardAnswerStats.o || 0
    }
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <span
        css={css`
          font-size: 20px;
          font-weight: 700;
          margin-bottom: -130px;
          color: ${DATA_PLATFORM_RED_GRAY};
        `}
      >
        전체 응답
      </span>
      <div
        css={css`
          width: 230px;
          height: 230px;
        `}
      >
        <VictoryPie
          startAngle={360}
          endAngle={0}
          innerRadius={110}
          data={stats}
          colorScale={colorScale}
          style={{ labels: { display: "none" } }}
        />
      </div>
      {showPercentage && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 280px;
          `}
        >
          <div
            css={css`
              margin-top: 10px;
              font-size: 16px;
              font-weight: 700;
              text-align: left;
              color: #bebebe;
            `}
          >
            찬성 {getAgreePercentage(cardAnswerStats).toFixed()}%
          </div>
          <div
            css={css`
              margin-top: 10px;
              font-size: 16px;
              font-weight: 700;
              text-align: center;
              color: ${DATA_PLATFORM_YELLOW_GRAY};
            `}
          >
            중립 {getDunnoPercentage(cardAnswerStats).toFixed()}%
          </div>
          <div
            css={css`
              margin-top: 10px;
              font-size: 16px;
              font-weight: 700;
              text-align: right;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            반대 {getDisagreePercentage(cardAnswerStats).toFixed()}%
          </div>
        </div>
      )}
    </div>
  );
}
