/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { onlyUnique } from "../../../utils/utils";
import { DailyReportsDataSchema } from "./useDailyReportsDataForHashtag";

export default function PartyPeopleSelect({
  personHashtag,
  setPersonHashtag,
  dailyReportsData
}: {
  personHashtag: string;
  setPersonHashtag: (value: string) => void;
  dailyReportsData: DailyReportsDataSchema;
}) {
  const { cards, relatedHashCards } = dailyReportsData;

  const peopleNames = cards
    ?.flatMap(card => card.speakerHashTag)
    .filter(a => a)
    .filter(onlyUnique)
    .map(name => name?.replace("#", ""))
    .sort() as string[];

  const peopleCards =
    peopleNames &&
    relatedHashCards?.filter(card => peopleNames.includes(card.id));

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonHashtag(event.target.value as string);
  };

  return (
    <div>
      <select
        value={personHashtag}
        onChange={handleChange}
        css={css`
          width: 190px;
          height: 44px;
          left: 373px;
          top: 756px;
          padding-left: 10px;
          padding-right: 10px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 10px;
        `}
      >
        <option value="전체">전체</option>
        {peopleCards &&
          peopleCards.map(card => (
            <option value={card.id} key={card.id}>
              {card.id.replace(/_/g, " ")}
            </option>
          ))}
      </select>
    </div>
  );
}
