import firebase from "firebase/compat";
import React, { useContext, useEffect, useRef, useState } from "react";
import createPersistedState from "use-persisted-state";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { TribeExpression } from "../../../constants/expressions";
import {
  CommentSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { parseDoc } from "../../../utils/utils";
import { getUserName } from "../../profile/OXOUserProfile/UserName";
import UserProfilePhoto from "../../views/StatsView/UserProfilePhoto";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import OXOButton from "../OXOButton/OXOButton";

export default function CommentInput({
  onSubmit,
  commentId,
  questionId
}: {
  onSubmit: (comment: string, currentExpression: TribeExpression) => void;
  commentId?: string;
  questionId: string;
}) {
  const [currentExpression, setCurrentExpression] = useState<TribeExpression>(
    TribeExpression.NORMAL
  );
  const useCommentState = createPersistedState<string>("comment_" + questionId);

  const [commentStored, setCommentStored] = useCommentState("");

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    window.setTimeout(function () {
      if (commentStored && commentStored.length > 0) {
        if (textareaRef.current) {
          // textareaRef.current.value = commentStored;
        }
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (commentId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .doc(commentId)
        .get()
        .then(parseDoc)
        .then((commentInDb: CommentSchema) => {
          setCommentStored(commentInDb.comment);
          setCurrentExpression(
            commentInDb.expression || TribeExpression.NORMAL
          );

          window.setTimeout(function () {
            if (textareaRef && textareaRef.current) {
              textareaRef.current.value = commentInDb.comment;
            }
          }, 100);
        });
    }
  }, [commentId]);

  if ((commentId && !commentStored) || !currentUserId || !currentUserMetadata) {
    return null;
  }

  function submit() {
    if (!commentStored) {
      return;
    }
    setCommentStored("");

    if (textareaRef.current) {
      textareaRef.current.value = "";
    }

    onSubmit(textareaRef.current?.value || commentStored, currentExpression);
  }

  return (
    <div style={{ width: "100%" }}>
      {currentUserMetadata.tribeId &&
        (currentUserMetadata.photo ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <UserProfilePhoto
              tribeId={currentUserMetadata.tribeId}
              photoUrl={currentUserMetadata.photo}
              width={"40px"}
              expression={currentExpression}
            />
          </div>
        ) : (
          <div />
        ))}

      {currentUserId && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "5px",
            borderRadius: "20px",
            backgroundColor:
              currentUserMetadata &&
              currentUserMetadata.tribeId &&
              TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].bgColor,
            padding: "20px"
          }}
        >
          <textarea
            ref={textareaRef}
            placeholder={
              ((currentUserMetadata &&
                getUserName(currentUserMetadata, currentUserId) + "님의 ") ||
                "") + "의견을 들려주세요."
            }
            onBlur={e => setCommentStored(e.target.value)}
            style={{
              width: "100%",
              height: "200px",
              backgroundColor:
                currentUserMetadata &&
                currentUserMetadata.tribeId &&
                TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].bgColor,
              border: 0,
              resize: "none"
            }}
          />
        </div>
      )}
      <div
        style={{
          padding: "10px 0",
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <OXOButton
          label={"댓글 남기기"}
          onClick={() => {
            commentStored && submit();
          }}
          style={{
            color:
              currentUserMetadata &&
              currentUserMetadata.tribeId &&
              TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor,
            fontSize: 16,
            fontWeight: "bold",
            backgroundColor:
              currentUserMetadata &&
              currentUserMetadata.tribeId &&
              TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].bgColor,
            borderRadius: 50,
            height: 48
          }}
        />
      </div>
    </div>
  );
}
