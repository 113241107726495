/** @jsxImportSource @emotion/react */

import React from "react";
import {
  AnswerCountsSchema,
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { CSS_TD, getStatTdsFull, RATIO_BACKGROUND } from "./shared";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { css } from "@emotion/react";
import { getAverageTribeCardAnswerDataV1 } from "./getAverageTribeCardAnswerData";

export default function TribeStatsForCardId({
  cardId,
  tribeStats
}: {
  cardId: string;
  tribeStats?: CardAnswerTribeStatsSchema;
}) {
  const cardAnswerTribeStats =
    tribeStats ??
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  if (!cardAnswerTribeStats) {
    return null;
  }

  const totalAnswers: AnswerCountsSchema = Object.values(
    cardAnswerTribeStats
  ).reduce(
    (total, stat) => {
      return {
        o: total.o + (stat.o || 0),
        "?": total["?"] + (stat["?"] || 0),
        x: total.x + (stat.x || 0),
        count: total.count + (stat.count || 0)
      };
    },
    { o: 0, "?": 0, x: 0, count: 0 }
  );

  const averageAnswers = getAverageTribeCardAnswerDataV1(cardAnswerTribeStats);

  return (
    <table
      css={css`
        width: 500px;
      `}
    >
      <tr>
        <td></td>
        <td>O</td>
        <td>?</td>
        <td>X</td>
        <td>응답수</td>
        <td>O</td>
        <td>?</td>
        <td>X</td>
      </tr>
      {["1", "2", "3", "4", "5"].map(tribeId => {
        const cardAnswerTribeStat = cardAnswerTribeStats[tribeId];
        return (
          <tr key={tribeId}>
            <td>{TRIBE_INFO_BY_ID[tribeId].name}</td>
            {getStatTdsFull(cardAnswerTribeStat, tribeId + cardId)}
          </tr>
        );
      })}
      <tr>
        <td>계</td>
        {getStatTdsFull(totalAnswers, "total" + cardId)}
      </tr>

      <tr>
        <td>부족 평균</td>
        <td colSpan={4}></td>
        <td css={[RATIO_BACKGROUND, CSS_TD]}>
          {((averageAnswers.o / averageAnswers.count) * 100).toFixed(1)}%
        </td>
        <td css={[RATIO_BACKGROUND, CSS_TD]}>
          {((averageAnswers["?"] / averageAnswers.count) * 100).toFixed(1)}%
        </td>
        <td css={[RATIO_BACKGROUND, CSS_TD]}>
          {((averageAnswers.x / averageAnswers.count) * 100).toFixed(1)}%
        </td>
      </tr>
    </table>
  );
}
