import React, { CSSProperties } from "react";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { TribeExpression } from "../../../constants/expressions";

export default function UserProfilePhoto({
  tribeId,
  width,
  photoUrl,
  style,
  expression = TribeExpression.NORMAL
}: {
  tribeId?: string;
  width: string;
  photoUrl?: string;
  style?: CSSProperties;
  expression?: TribeExpression;
}) {
  if (!tribeId) {
    return null;
  }
  if (!TRIBE_INFO_BY_ID[tribeId]) {
    return null;
  }
  return (
    <img
      src={
        photoUrl ||
        "./tribe_expressions/" +
          TRIBE_INFO_BY_ID[tribeId].image +
          "_" +
          expression +
          ".png"
      }
      width={width}
      style={{ verticalAlign: "middle", ...style }}
      alt={TRIBE_INFO_BY_ID[tribeId]?.name || ""}
    />
  );
}
