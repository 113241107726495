import React from "react";
import NotificationTimeline from "../../views/NotificationsView/NotificationTimeline";

export default function ProfileNotification() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 14
      }}
    >
      <div
        style={{
          zIndex: 101,
          position: "fixed",
          textAlign: "center",
          top: 20,
          fontWeight: "bolder"
        }}
      >
        알림
      </div>

      <div style={{ marginTop: 20, padding: "0px 2px", fontSize: 16 }}>
        <NotificationTimeline />
      </div>
    </div>
  );
}
