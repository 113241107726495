/** @jsxImportSource @emotion/react */

import React from "react";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import { css } from "@emotion/react";
import HashCommentAvatar from "../HashCardView/Comment/HashCommentAvatar";
import SpeakerPosition from "../HashCardView/Comment/SpeakerPosition";
import { TextareaAutosize } from "@material-ui/core";

export default function AddHashtagCommentBubble({
  author
}: {
  author: string;
}) {
  return (
    <div>
      <div>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              font-size: 14px;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            `}
          >
            <HashCommentAvatar speaker={author} size={60} />
            <div
              css={css`
                padding-top: 5px;
                padding-bottom: 5px;
              `}
            >
              {author}
            </div>
            <div>
              <SpeakerPosition speaker={author} style={{ fontSize: 10 }} />
            </div>
          </div>
          <div
            css={css`
              width: 100%;
            `}
          >
            <TextareaAutosize
              css={css`
                width: 100%;
              `}
              rows={3}
            />
            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <div css={css``}>
                <OXOButton label={`발언 추가하기`} onClick={() => {}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
