/** @jsxImportSource @emotion/react */

import React from "react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getAgeGenderStatsByAgeGroup } from "../../../utils/ageGender";
import { getStatTdsFull } from "./shared";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";

export default function AgeGenderStatsForCardId({
  cardId,
  ageGenderStats
}: {
  cardId: string;
  ageGenderStats?: {
    [ageGroup: string]: {
      [gender: string]: AnswerCountsSchema;
    };
  };
}) {
  const ageGenderStatsData =
    useFirestoreGetDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  if (!ageGenderStatsData) {
    return null;
  }

  const ageGenderStatsByAgeGroup =
    ageGenderStats ?? getAgeGenderStatsByAgeGroup(ageGenderStatsData);

  const totalAnswers: { [gender: string]: AnswerCountsSchema } = Object.values(
    ageGenderStatsByAgeGroup
  ).reduce(
    (total, stat) => {
      return {
        male: {
          o: total["male"]?.o + (stat["male"]?.o || 0),
          "?": total["male"]?.["?"] + (stat["male"]?.["?"] || 0),
          x: total["male"]?.x + (stat["male"]?.x || 0),
          count: total["male"]?.count + (stat["male"]?.count || 0)
        },
        female: {
          o: total["female"]?.o + (stat["female"]?.o || 0),
          "?": total["female"]?.["?"] + (stat["female"]?.["?"] || 0),
          x: total["female"]?.x + (stat["female"]?.x || 0),
          count: total["female"]?.count + (stat["female"]?.count || 0)
        }
      };
    },
    {
      male: { o: 0, "?": 0, x: 0, count: 0 },
      female: { o: 0, "?": 0, x: 0, count: 0 }
    }
  );

  return (
    <table>
      <tr>
        <td />
        <td colSpan={7}>남성</td>
        <td colSpan={7}>여성</td>
      </tr>
      <tr>
        <td></td>
        <td>O</td>
        <td>?</td>
        <td>X</td>
        <td>응답</td>
        <td>O</td>
        <td>?</td>
        <td>X</td>
        <td>O</td>
        <td>?</td>
        <td>X</td>
        <td>응답</td>
        <td>O</td>
        <td>?</td>
        <td>X</td>
      </tr>
      {["10", "20", "30", "40", "50", "60"].map(ageGroup => {
        return (
          <tr key={ageGroup}>
            <td>{ageGroup}대</td>
            {["male", "female"].map(gender =>
              getStatTdsFull(
                ageGenderStatsByAgeGroup?.[ageGroup]?.[gender],
                ageGroup + gender + cardId
              )
            )}
          </tr>
        );
      })}
      <tr>
        <td>계</td>
        {Object.keys(totalAnswers).map(gender =>
          getStatTdsFull(totalAnswers[gender], "total" + gender + cardId)
        )}
      </tr>
    </table>
  );
}
