/** @jsxImportSource @emotion/react */

import React from "react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getAgeGenderStatsByAgeGroup } from "../../../utils/ageGender";
import { CSS_TD, getStatTdsFull } from "./shared";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { css } from "@emotion/react";
import { sumTwoAnswerCounts } from "../../../utils/utils";

export default function AgeGenderStatsForCardIdAgeOnly({
  cardId,
  ageGenderStats
}: {
  cardId: string;
  ageGenderStats?: {
    [ageGroup: string]: {
      [gender: string]: AnswerCountsSchema;
    };
  };
}) {
  const ageGenderStatsData =
    useFirestoreGetDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  if (!ageGenderStatsData) {
    return null;
  }

  const ageGenderStatsByAgeGroup =
    ageGenderStats ?? getAgeGenderStatsByAgeGroup(ageGenderStatsData);

  return (
    <table css={css``}>
      <tr>
        <td />
        <td colSpan={7}>전체 성별</td>
      </tr>
      <tr>
        <td css={CSS_TD}></td>
        <td css={CSS_TD}>O</td>
        <td css={CSS_TD}>?</td>
        <td css={CSS_TD}>X</td>
        <td css={CSS_TD}>응답</td>
        <td css={CSS_TD}>O</td>
        <td css={CSS_TD}>?</td>
        <td css={CSS_TD}>X</td>
      </tr>
      {["10", "20", "30", "40", "50", "60"].map(ageGroup => {
        return (
          <tr key={ageGroup}>
            <td>{ageGroup}대</td>

            {getStatTdsFull(
              sumTwoAnswerCounts(
                ageGenderStatsByAgeGroup?.[ageGroup]?.["male"],
                ageGenderStatsByAgeGroup?.[ageGroup]?.["female"]
              ),
              ageGroup + "all" + cardId
            )}
          </tr>
        );
      })}
    </table>
  );
}
