import { createTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";

export const theme = createTheme({
  palette: {
    primary: {
      main: purple[500]
    },
    secondary: {
      main: green[500]
    }
  },
  typography: {
    body1: {
      fontSize: 15
    },
    body2: {
      fontSize: 20
    }
  }
});

export default theme;
