import { ChatBubbleOutline } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BACKGROUND_COLOR, GRAY_DARK } from "../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection,
  UserNotificationItemSchema
} from "../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  parseDoc,
  truncateLongSentence
} from "../../../utils/utils";
import UserName from "../../profile/OXOUserProfile/UserName";

const MAX_COMMENT_CUTOFF = 30;

export default function PostCommentNotification({
  userNotification
}: {
  userNotification: UserNotificationItemSchema;
}) {
  const history = useHistory();
  const [post, setPost] = useState<CardSchema>();

  const postCommentUserNotificationMetadata =
    userNotification.postCommentUserNotification;
  useEffect(() => {
    if (
      postCommentUserNotificationMetadata &&
      postCommentUserNotificationMetadata.postId
    ) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(postCommentUserNotificationMetadata.postId)
        .get()
        .then(snapshot => {
          setPost(parseDoc(snapshot));
        });
    }
  }, [postCommentUserNotificationMetadata]);

  if (!post) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "20px 20px 20px 10px",
        borderBottom: "1px solid " + BACKGROUND_COLOR
      }}
    >
      <div
        style={{
          color: GRAY_DARK,
          minWidth: "60px",
          padding: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "baseline"
        }}
      >
        <div>
          <ChatBubbleOutline
            style={{
              color: GRAY_DARK,
              fontWeight: "bold"
            }}
          />
          <span
            style={{
              fontSize: "12px",
              marginLeft: "2px",
              verticalAlign: "sub"
            }}
          >
            {post.comments}
          </span>
        </div>
      </div>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(history.location.search, post.id)
          );
        }}
      >
        {postCommentUserNotificationMetadata && (
          <span
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={e => {
              e.stopPropagation();
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(
                    history.location.search,
                    postCommentUserNotificationMetadata.commentUserId
                  )
              );
            }}
          >
            <UserName
              profileUserId={postCommentUserNotificationMetadata.commentUserId}
            />
          </span>
        )}
        님이{" "}
        {post
          ? '"' + truncateLongSentence(post.title, MAX_COMMENT_CUTOFF) + '"'
          : "삭제된 "}{" "}
        생각에 댓글을 달았습니다.
        <div
          style={{
            fontSize: "14px",
            color: GRAY_DARK
          }}
        >
          {new Date(userNotification.createdAt).toLocaleDateString() +
            " " +
            new Date(userNotification.createdAt).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
}
