import firebase from "firebase/compat";

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PRIMARY_RED, PROFILE_SIDE_MENU_ITEMS } from "../../../constants/enums";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { ROUTES } from "../../../constants/routes";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot,
  useIsAdmin
} from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OxoNotice from "../../views/Today/OXONotice";
import ARROW_RIGHT_ICON from "../icons/arrow_right.svg";
import PROFILE_SIDEBAR_ICON_TEST from "../icons/profileSidebarIcon_test.svg";
import PROFILE_SIDEBAR_ICON_ADMIN from "../icons/profileSidebarIcon_admin.svg";
import PROFILE_SIDEBAR_ICON_FEEDBACK from "../icons/profileSidebarIcon_feedback.svg";
import PROFILE_SIDEBAR_ICON_POLITICS from "../icons/profileSidebarIcon_politics.svg";
import PROFILE_SIDEBAR_ICON_RANKING from "../icons/profileSidebarIcon_ranking.svg";
import PROFILE_SIDEBAR_ICON_SETTING from "../icons/profileSidebarIcon_setting.svg";
import PROFILE_SIDEBAR_ICON_STATISTICS from "../icons/profileSidebarIcon_statistics.svg";
import MiniGenderConflictBanner from "./MiniGenderConflictBanner";

interface profileMenuItem {
  isDrawer: boolean;
  name: string;
  urlTo?: string;
  icon: string;
  isAdmin?: boolean;
}

const profileMenu: profileMenuItem[] = [
  {
    isDrawer: true,
    name: "내 프로필",
    urlTo: PROFILE_SIDE_MENU_ITEMS.POLITICS,
    icon: PROFILE_SIDEBAR_ICON_POLITICS
  },
  {
    isDrawer: true,
    name: "성향테스트",
    urlTo: PROFILE_SIDE_MENU_ITEMS.TEST,
    icon: PROFILE_SIDEBAR_ICON_TEST
  },
  {
    isDrawer: true,
    name: "공감순위표",
    urlTo: PROFILE_SIDE_MENU_ITEMS.RANKING,
    icon: PROFILE_SIDEBAR_ICON_RANKING
  },
  {
    isDrawer: true,
    name: "통계",
    urlTo: PROFILE_SIDE_MENU_ITEMS.STATISTICS,
    icon: PROFILE_SIDEBAR_ICON_STATISTICS
  },
  { isDrawer: false, name: "피드백", icon: PROFILE_SIDEBAR_ICON_FEEDBACK },
  {
    isDrawer: true,
    name: "설정",
    urlTo: PROFILE_SIDE_MENU_ITEMS.SETTING,
    icon: PROFILE_SIDEBAR_ICON_SETTING
  },
  {
    isDrawer: false,
    name: "관리자",
    icon: PROFILE_SIDEBAR_ICON_ADMIN,
    isAdmin: true
  }
];

export default function ProfileMain() {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const history = useHistory();
  const [unseenNotificationCount, setUnseenNotificationCount] =
    React.useState<number>();
  const isAdmin = useIsAdmin();

  const shouldShowGenderConflict =
    firebase.remoteConfig().getBoolean("enableGenderConflict") ||
    (firebase.remoteConfig().getBoolean("enableGenderConflictForAdmin") &&
      isAdmin);

  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.NOTIFICATIONS)
        .where("userId", "==", currentUserId)
        .where("seen", "==", false)
        .onSnapshot(snapshot => {
          const unseenNotifications = getDocsFromSnapshot(snapshot);
          setUnseenNotificationCount(unseenNotifications.length);

          // TODO (will.ryu): sendDesktopWebNotification

          return () => unsubscribe();
        });
    }
  }, [currentUserId]);

  const [cards, setCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.OXO)
      .where("displayToUsers", "==", true)
      .orderBy("cardScore", "desc")
      .limit(1)
      .get()
      .then(snapshot => {
        setCards(getDocsFromSnapshot(snapshot));
      });
  }, []);

  if (!cards) {
    return null;
  }

  return (
    <>
      {currentUserMetadata && (
        <div
          style={{
            padding: "0px 25px 18px 25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: -40
          }}
        >
          <div
            style={{
              marginBottom: 7,
              width: "100%",
              marginTop: 26
            }}
          >
            {shouldShowGenderConflict ? (
              <MiniGenderConflictBanner />
            ) : (
              cards.map(card => (
                <div style={{}} key={card.id}>
                  <OxoNotice cardId={card.id} />
                </div>
              ))
            )}
          </div>
          <ul
            style={{
              width: "100%",
              listStyleType: "none",
              paddingLeft: 0
            }}
          >
            {profileMenu.map(menuItem => {
              return !menuItem.isDrawer ? (
                menuItem.isAdmin ? (
                  isAdmin && (
                    <li
                      key={menuItem.name}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                        cursor: "pointer",
                        justifyContent: "space-between",
                        paddingTop: 5,
                        paddingBottom: 5
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={menuItem.icon}
                          style={{ width: 28, height: 28 }}
                          alt="menuIcon"
                        />
                        <div
                          onClick={() => {
                            menuItem.name == "관리자"
                              ? history.push(ROUTES.ADMIN)
                              : history.push(ROUTES.NOTIFICATION_SCHEDULE);
                          }}
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            minWidth: 150,
                            margin: "0px 15px 0px 10px"
                          }}
                        >
                          {menuItem.name}
                        </div>
                      </div>
                      <div>
                        <img
                          src={ARROW_RIGHT_ICON}
                          style={{ width: 6.5 }}
                          alt="menuArrow"
                        />
                      </div>
                    </li>
                  )
                ) : (
                  <li
                    key={menuItem.name}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                      cursor: "pointer",
                      justifyContent: "space-between",
                      paddingTop: 5,
                      paddingBottom: 5
                    }}
                    onClick={() => {
                      firebase
                        .analytics()
                        .logEvent(`v4_click_profile_menu_${menuItem.urlTo}`, {
                          event_category: "profile"
                        });
                      window.open(
                        encodeURI("https://forms.gle/1ewzn1gqKZZVYriK7"),
                        "_system",
                        "location=yes"
                      );
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={menuItem.icon}
                        alt="menuIcon"
                        style={{ width: 28, height: 28 }}
                      />
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          minWidth: 150,
                          margin: "0px 15px 0px 10px"
                        }}
                      >
                        {menuItem.name}
                      </div>
                    </div>
                    <div>
                      <img
                        src={ARROW_RIGHT_ICON}
                        style={{ width: 6.5 }}
                        alt="menuArrow"
                      />
                    </div>
                  </li>
                )
              ) : (
                <li
                  key={menuItem.name}
                  onClick={e => {
                    e.stopPropagation();
                    firebase
                      .analytics()
                      .logEvent(`v4_click_profile_menu_${menuItem.urlTo}`, {
                        event_category: "profile"
                      });
                    history.push(
                      history.location.pathname +
                        addToCardIdStackUrl(
                          history.location.search,
                          `${menuItem.urlTo}`
                        )
                    );
                  }}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                    cursor: "pointer",
                    justifyContent: "space-between",
                    paddingTop: 5,
                    paddingBottom: 5
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {menuItem.name === "알림" && unseenNotificationCount ? (
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            width: 10,
                            height: 10,
                            right: 5,
                            background: PRIMARY_RED,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px solid white"
                          }}
                        >
                          <img
                            src={menuItem.icon}
                            alt="menuIcon"
                            style={{ width: 28, height: 28 }}
                          />
                        </div>
                      </div>
                    ) : (
                      <img
                        src={menuItem.icon}
                        alt="menuIcon"
                        style={{ width: 28, height: 28 }}
                      />
                    )}
                    <div
                      onClick={e => {
                        firebase
                          .analytics()
                          .logEvent(`v4_click_profile_menu_${menuItem.urlTo}`, {
                            event_category: "profile"
                          });
                        e.stopPropagation();
                        history.push(
                          history.location.pathname +
                            addToCardIdStackUrl(
                              history.location.search,
                              `${menuItem.urlTo}`
                            )
                        );
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        minWidth: 150,
                        margin: "0px 15px 0px 10px"
                      }}
                    >
                      {menuItem.name}
                    </div>
                  </div>
                  <img
                    src={ARROW_RIGHT_ICON}
                    style={{ width: 6.5 }}
                    alt="menuArrow"
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
