/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import useHashtagRelatedCardsAndStats from "../DailyReportsView/useDailyReportsDataForHashtag";
import { CardSchema } from "../../../constants/firestore_schema";
import MiniMentionResponseCard from "./MiniMentionResponseCard";

const CARDS_PER_PAGE = 10;

export function HashtagMentionsColumn({
  selectedDate,
  hashtag,
  setSelectedCard
}: {
  selectedDate: number;
  hashtag: string;
  setSelectedCard: (cardId: string) => void;
}) {
  const [page, setPage] = useState(1);

  const { cards } = useHashtagRelatedCardsAndStats({
    hashtag: hashtag,
    selectedDate: selectedDate,
    limit: CARDS_PER_PAGE * page
  });

  return (
    <div>
      {cards &&
        cards
          .slice(0, page * CARDS_PER_PAGE)
          .map((card: CardSchema) => (
            <MiniMentionResponseCard
              cardId={card.id}
              key={card.id}
              setSelectedCard={setSelectedCard}
            />
          ))}
      <OXOInfiniteScroll setNextPage={() => setPage(p => p + 1)} />
    </div>
  );
}
