import { Popover } from "@material-ui/core";
import React from "react";
import {
  BACKGROUND_COLOR,
  GRAY_LIGHT,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import { LikeSchema } from "../../../constants/firestore_schema";
import UserName from "../../profile/OXOUserProfile/UserName";

export default function LikeStatBadge({
  tribeId,
  likes
}: {
  tribeId: string;
  likes: LikeSchema[];
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div
            style={{
              fontSize: 10,
              padding: 5,
              backgroundColor: BACKGROUND_COLOR
            }}
          >
            {likes.map(like => (
              <div key={like.likeUserTribeId}>
                <UserName profileUserId={like.likeUserId} />
              </div>
            ))}
          </div>
        </Popover>
      )}
      <div
        style={{
          backgroundColor:
            tribeId === "special"
              ? GRAY_LIGHT
              : TRIBE_INFO_BY_ID[tribeId].heavyColor,
          padding: 2,
          width: 16,
          height: 16,
          color: "white",
          fontSize: 10,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 2,
          fontWeight: "bold",
          cursor: "pointer"
        }}
        key={tribeId}
        onClick={e => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        {likes.length}
      </div>
    </div>
  );
}
