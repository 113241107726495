import { HelpOutline } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { BACKGROUND_COLOR, GRAY_DARK } from "../../../constants/enums";
import { UserNotificationItemSchema } from "../../../constants/firestore_schema";
import { addToCardIdStackUrl } from "../../../utils/utils";

export default function QuestionNotification({
  userNotification
}: {
  userNotification: UserNotificationItemSchema;
}) {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        padding: "20px 20px 20px 10px",
        borderBottom: "1px solid " + BACKGROUND_COLOR
      }}
    >
      <div
        style={{
          color: GRAY_DARK,
          minWidth: "60px",
          padding: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "baseline"
        }}
      >
        <div>
          <HelpOutline
            style={{
              color: GRAY_DARK,
              fontWeight: "bold"
            }}
          />
        </div>
      </div>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (userNotification.newQuestionNotification) {
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(
                  history.location.search,
                  userNotification.newQuestionNotification.questionId
                )
            );
          }
        }}
      >
        <span style={{ fontWeight: "bold" }}>
          "{userNotification.newQuestionNotification?.title}"
        </span>
        토픽이 추가되었습니다. 의견을 들려주세요.
      </div>
    </div>
  );
}
