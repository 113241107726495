import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey, useIsAdmin } from "../../../../utils/utils";
import MiniQuestionBarChart from "../../../topic/Question/QuestionChart/MiniQuestionBarChart";
import { syncStats } from "../../../../lib/answer_lib";

export default function CardAnswerBarChart({
  cardId,
  hideBar = false,
  heightWhenShowingResults = 1,
  heightWhenHidingResults = 1,
  backgroundColor = "#363636"
}: {
  cardId: string;
  hideBar?: boolean;
  heightWhenShowingResults?: number;
  heightWhenHidingResults?: number;
  backgroundColor?: string;
}) {
  const [questionAnswerStats, setQuestionAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            setQuestionAnswerStats(removeIdKey(parseDoc(snapshot)));
          }
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (isAdmin && questionAnswerStats) {
      if (
        questionAnswerStats?.o +
          questionAnswerStats?.x +
          questionAnswerStats["?"] !==
        questionAnswerStats.count
      ) {
        syncStats(cardId);
      }
    }
    return () => {};
  }, [questionAnswerStats, isAdmin]);

  if (hideBar || !questionAnswerStats) {
    return (
      <div
        style={{
          marginTop: 10,
          marginBottom: 8,
          height: heightWhenHidingResults,
          backgroundColor: backgroundColor
        }}
      />
    );
  }

  return (
    <div
      style={{
        marginTop: 0,
        width: "100%",
        paddingBottom: 4
      }}
    >
      <MiniQuestionBarChart
        questionChartStats={questionAnswerStats}
        height={heightWhenShowingResults}
      />
    </div>
  );
}
