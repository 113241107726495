import { Switch, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  NotificationSubscriptionType
} from "../../../../constants/firestore_schema";
import { addToIndex, removeFromIndex } from "../../../../lib/search";
import { dateToIso, parseDoc } from "../../../../utils/utils";
import DocumentSkeleton from "../../../utility/OXOLoader/DocumentSkeleton";
import { AlgoliaContext } from "../../../views/SearchView/AlgoliaContext";
import OXOButton from "../../../foundation/OXOButton/OXOButton";
import {
  reservePush,
  sendPushByCondition
} from "../../../../lib/notification_cloud_lib";
import { SnackbarContext } from "../../../foundation/Contexts/SnackbarContext";
import { CardDisplayStatus } from "../../CardDisplayStatus";
import firebase from "firebase/compat";
import FeaturedOnTodayFocusAtEditor from "./FeaturedOnTodayFocusAtEditor";

export function QuestionAdmin({ questionId }: { questionId: string }) {
  const [publishReservationTime, setPublishReservationTime] =
    useState<number>();
  const [card, setCard] = useState<CardSchema>();

  const displaySnackbar = useContext(SnackbarContext);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(questionId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));
    return () => unsubscribe();
  }, []);

  const algoliaClient = useContext(AlgoliaContext);

  if (!card) {
    return <DocumentSkeleton />;
  }

  return (
    <div style={{ paddingBottom: "15px" }}>
      <h2>문제 Admin</h2>
      <table className={"adminTable"}>
        <tbody>
          <tr>
            <td>질문 ID</td>
            <td>{questionId}</td>
          </tr>
          <tr>
            <td>만든 시간</td>
            <td>{new Date(card.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <td>공개</td>
            <td>
              <Switch
                checked={card.displayToUsers}
                onChange={(e, checked) => {
                  if (algoliaClient) {
                    if (checked) {
                      addToIndex(algoliaClient, "질문", questionId, card);
                    } else {
                      removeFromIndex(algoliaClient, questionId);
                    }
                  }
                  firebase
                    .firestore()
                    .collection(FirestoreCollection.CARDS)
                    .doc(questionId)
                    .set(
                      {
                        displayToUsers: checked,
                        publishedAt: Date.now()
                      },
                      { merge: true }
                    )
                    .then(() => {
                      if (checked) {
                        const title =
                          card?.type === CardType.NEWS_TICKER &&
                          card?.hashtags?.[0]
                            ? card.hashtags[0]
                            : "오늘의 정치이슈";
                        const body =
                          (card.speakerHashTag?.[0]
                            ? card.speakerHashTag?.[0].replace("#", "") + ": "
                            : "") + card?.title;
                        if (
                          window.confirm(
                            "푸쉬알림을 보낼까요?: " + "[" + title + "] " + body
                          )
                        ) {
                          if (card?.type === CardType.NEWS_TICKER) {
                            if (card?.hashtags?.[0]) {
                              const condition = `('${
                                NotificationSubscriptionType.HASHTAG
                              }' in topics && '${encodeURI(
                                card.hashtags[0].replace("#", "")
                              )}' in topics)`;

                              console.log(condition);

                              sendPushByCondition(
                                title,
                                body,
                                condition,
                                displaySnackbar
                              );
                            }
                          } else {
                            sendPushByCondition(
                              title,
                              card?.title,
                              "('ALL_TOPICS' in topics)",
                              displaySnackbar
                            );
                          }
                        }
                      }
                    });
                }}
              />{" "}
              사용자들에게 공개하기
            </td>
          </tr>

          <tr>
            <td>예약 공개</td>
            <td>
              <div>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  defaultValue={dateToIso(
                    new Date(card.publishedAt || card.createdAt)
                  )}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e => {
                    setPublishReservationTime(
                      new Date(e.target.value).valueOf()
                    );
                  }}
                />
                <div style={{ marginTop: 10 }}>
                  <OXOButton
                    label={"예약"}
                    style={{ width: 60, height: 30 }}
                    onClick={() => {
                      if (publishReservationTime) {
                        firebase
                          .firestore()
                          .collection(FirestoreCollection.CARDS)
                          .doc(questionId)
                          .set(
                            {
                              displayToUsers: true,
                              publishedAt: publishReservationTime
                            },
                            { merge: true }
                          );
                        reservePush(
                          "옥소폴리틱스 - 모든 사람의 모든 생각",
                          card?.title,
                          "('ALL_TOPICS' in topics)",
                          publishReservationTime,
                          displaySnackbar
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Today Focus로 보내기</td>
            <td>
              <FeaturedOnTodayFocusAtEditor card={card} />
            </td>
          </tr>
          <tr>
            <td>현재 상태</td>
            <td>
              <CardDisplayStatus card={card} />
            </td>
          </tr>

          <tr>
            <td>Type</td>
            <td>
              <select
                onChange={e =>
                  firebase
                    .firestore()
                    .collection(FirestoreCollection.CARDS)
                    .doc(questionId)
                    .set(
                      {
                        type: parseInt(e.target.value)
                      },
                      { merge: true }
                    )
                }
                defaultValue={card.type}
                style={{ width: 200, height: 30 }}
              >
                {Object.keys(CardType)
                  .filter(questionType =>
                    Number.isInteger(parseInt(questionType) as number)
                  )
                  .map(questionType => parseInt(questionType))
                  .map(questionType => (
                    <option value={questionType} key={questionType}>
                      {CardType[questionType]}
                    </option>
                  ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
