import React from "react";
import {
  AnswerCountSchema,
  CardAnswerTribeStatsSchema,
  CardAnswerTribeStatsV2Schema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import {
  ANSWER_DUNNO_STROKE,
  ANSWER_O_STROKE,
  ANSWER_X_STROKE
} from "../../../constants/colors";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import OxLightBarChart from "./OxLightBarChart";
import {
  getAverageTribeCardAnswerData,
  getAverageTribeCardAnswerDataV1
} from "./getAverageTribeCardAnswerData";

export default function OxLightBarChartContainer({
  cardId,
  colorScale = [ANSWER_O_STROKE, ANSWER_DUNNO_STROKE, ANSWER_X_STROKE],
  width,
  averageCardAnswer
}: {
  cardId: string;
  colorScale?: string[];
  width?: number;
  averageCardAnswer?: AnswerCountSchema;
}) {
  const [cardAnswersV2] =
    useFirestoreLiveDocument<CardAnswerTribeStatsV2Schema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2,
      cardId
    );

  const [cardAnswersV1] = useFirestoreLiveDocument<CardAnswerTribeStatsSchema>(
    FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
    cardId
  );

  if (!cardAnswersV2 || !cardAnswersV1) {
    return <></>;
  }

  const averageCardAnswersV2 = getAverageTribeCardAnswerData(cardAnswersV2);
  const averageCardAnswersV1 = getAverageTribeCardAnswerDataV1(cardAnswersV1);

  const tribeAverageCardAnswers =
    averageCardAnswer ??
    (averageCardAnswersV2.count > averageCardAnswersV1.count
      ? averageCardAnswersV2
      : averageCardAnswersV1);

  const data: ApexAxisChartSeries = [
    {
      name: "찬성",
      data: [
        {
          x: 0,
          y: Math.round(tribeAverageCardAnswers.o * 10) / 10
        }
      ]
    },
    {
      name: "중립",
      data: [
        {
          x: 0,
          y: Math.round(tribeAverageCardAnswers["?"] * 10) / 10
        }
      ]
    },
    {
      name: "반대",
      data: [
        {
          x: 0,
          y: Math.round(tribeAverageCardAnswers.x * 10) / 10
        }
      ]
    }
  ];

  return <OxLightBarChart data={data} colorScale={colorScale} width={width} />;
}
