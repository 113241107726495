import React from "react";

export default function MiniDunno({
  fill = "#FDE395",
  width = 19,
  height = 19
}: {
  fill?: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10H12L6 0L0 10ZM2.46667 8.625L6 2.75L9.53333 8.625H2.46667Z"
        fill={fill}
      />
    </svg>
  );
}
