import React, { CSSProperties } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";

export default function OXOInfiniteScroll({
  setNextPage,
  style
}: {
  setNextPage: () => void;
  style?: CSSProperties;
}) {
  return (
    <ReactVisibilitySensor
      onChange={isVisible => {
        if (isVisible) {
          setNextPage();
        }
      }}
      partialVisibility
    >
      <div
        style={{
          width: "100px",
          height: 50,
          display: "flex",
          flexShrink: 0,
          justifyContent: "center",
          alignItems: "center",
          ...style
        }}
      />
    </ReactVisibilitySensor>
  );
}
