/** @jsxImportSource @emotion/react */

import { Dialog, DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GRAY_HASH_PAGE_BUTTON } from "../../../constants/enums";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import arrowLeft from "./icon/arrowLeft.svg";
import arrowRight from "./icon/arrowRight.svg";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export default function PhotoViewer({
  photoUrls,
  height,
  borderRadius = "0px 0px 0px 0px"
}: {
  photoUrls: string[];
  height?: number;
  borderRadius?: string;
}) {
  const [imageViewIndex, setImageViewIndex] = useState<number>(0);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [windowWidth] = useWindowSize();

  const [imageWidth, setImageWidth] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();

  useEffect(() => {
    if (photoUrls.length > 0) {
      const img = new Image();
      img.onload = function () {
        setImageWidth(img.width);
        setImageHeight(img.height);
      };
      img.src = photoUrls[0];
    }
  }, [photoUrls]);
  if (photoUrls.length === 0) {
    return null;
  }

  return (
    <div style={{}}>
      <Dialog
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"cardPopup"}
      >
        {photoUrls.length > 1 && (
          <div
            style={{
              width: "100%",
              height: 80,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px"
            }}
          >
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setImageViewIndex(Math.max(0, imageViewIndex - 1));
              }}
              style={{
                height: 26,
                width: 26,
                borderRadius: "100%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: "50%"
              }}
            >
              <img
                alt={"arrow left"}
                src={arrowLeft}
                style={{ marginRight: 4, height: 14, opacity: "100%" }}
              />
            </div>
            <DialogContent>
              {photoUrls.length > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {photoUrls.map((photoUrl, i) => {
                    return (
                      <div
                        key={photoUrl}
                        style={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          margin: "5px",
                          backgroundColor:
                            imageViewIndex === i
                              ? GRAY_HASH_PAGE_BUTTON
                              : "#363636"
                        }}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setImageViewIndex(i);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </DialogContent>
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setImageViewIndex(
                  Math.min(photoUrls.length - 1, imageViewIndex + 1)
                );
              }}
              style={{
                height: 26,
                width: 26,
                borderRadius: "100%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                opacity: "50%"
              }}
            >
              <img
                alt={"arrow right"}
                src={arrowRight}
                style={{ marginLeft: 4, height: 14, opacity: "100%" }}
              />
            </div>
          </div>
        )}

        <div
          style={{
            overflow: "auto"
          }}
        >
          <div
            style={{
              maxWidth: windowWidth,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backgroundColor: "transparent"
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpenImageModal(false);
            }}
          >
            <TransformWrapper velocityAnimation={{}}>
              <TransformComponent>
                <img
                  alt={"photo" + imageViewIndex}
                  src={photoUrls[imageViewIndex]}
                  style={{ maxWidth: windowWidth }}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        {photoUrls.length > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "5px 0",
              alignItems: "center",
              position: "absolute",
              zIndex: 100
            }}
          >
            {photoUrls.map((photoUrl, i) => {
              return (
                <div
                  key={photoUrl}
                  style={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    margin: "5px",
                    backgroundColor:
                      imageViewIndex === i ? GRAY_HASH_PAGE_BUTTON : "#363636"
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setImageViewIndex(i);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
