/** @jsxImportSource @emotion/react */

import React, { useContext, useEffect } from "react";
import { AnswerSymbol, oxoColors, UserAnswer } from "../../../constants/enums";
import UserMap, {
  SeriesType
} from "../../foundation/Charts/UserMap/UserMap-victory";
import firebase from "firebase/compat";
import {
  FirestoreCollection,
  MapCoordinatesSchema,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { parseDoc, removeIdKey } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import MentionQuestionChart from "../../views/MentionResponseView/MentionQuestionChart";

export default function MentionStatistics({ cardId }: { cardId: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [questionAnswers, setQuestionAnswers] =
    React.useState<QuestionAnswerMapSchema>();

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setQuestionAnswers(removeIdKey(parseDoc(snapshot)));
      });
    return () => {
      unsubscriber();
    };
  }, [cardId]);

  if (!dataProps?.mapCoordinates) {
    return null;
  }

  let series = undefined;

  if (questionAnswers) {
    series = getMapSeries(
      questionAnswers,
      dataProps.mapCoordinates,
      currentUserMetadata
    );
  }

  return (
    <div>
      <div style={{ marginBottom: "20px", maxHeight: 700 }}>
        {series && (
          <>
            <div style={{ marginTop: "20px" }}>
              <MentionQuestionChart cardId={cardId} />
            </div>
            <div style={{ marginTop: "10px" }}>
              <UserMap series={series} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function getMapSeries(
  questionAnswers: QuestionAnswerMapSchema,
  mapCoordinates: MapCoordinatesSchema,
  userProfileMetadata: UserProfileMetadataSchema | undefined
) {
  const seriesData: { [seriesName: string]: number[][] } = {
    me: [],
    unanswered: []
  };

  const mapWidth = Math.min(window.screen.width, 750);

  seriesData[UserAnswer.O] = [];
  seriesData[UserAnswer.X] = [];
  seriesData[UserAnswer.DUNNO] = [];

  Object.keys(questionAnswers).forEach(coordinateId => {
    const arr = [
      questionAnswers[coordinateId].countO,
      questionAnswers[coordinateId].countX,
      questionAnswers[coordinateId].countDunno
    ];
    const i = arr.indexOf(Math.max(...arr));

    if (i === 0) {
      seriesData[UserAnswer.O].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 1) {
      seriesData[UserAnswer.X].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 2) {
      seriesData[UserAnswer.DUNNO].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    }
  });

  const defaultSize = mapWidth / 350;

  Object.keys(mapCoordinates).map(coordinateId => {
    const mapCoordinate = mapCoordinates[coordinateId];
    seriesData["unanswered"].push([mapCoordinate.x, mapCoordinate.y]);
  });

  const series: SeriesType[] = [
    {
      name: AnswerSymbol.O,
      data: seriesData[UserAnswer.O] || [],
      color: oxoColors.O,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.DUNNO,
      data: seriesData[UserAnswer.DUNNO] || [],
      color: oxoColors.DUNNO,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.X,
      data: seriesData[UserAnswer.X] || [],
      color: oxoColors.X,
      size: defaultSize + 1
    },
    {
      name: "응답 안 함",
      data: seriesData["unanswered"] || [],
      color: oxoColors.OTHERS,
      size: defaultSize,
      opacity: 0.4
    }
  ];

  if (userProfileMetadata) {
    series.unshift({
      name: "나",
      data: [[userProfileMetadata.x, userProfileMetadata.y]],
      color: "#333333",
      label: "나",
      size: 4
    });
  }

  return series;
}
