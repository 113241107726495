/** @jsxImportSource @emotion/react */

import React from "react";
import { useCardSubscription } from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_GRAY_COLOR,
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_COLOR
} from "../../../constants/data_platform_colors";

import TextPill from "../../foundation/Pill/TextPill.styled";
import MiniBorderCardBox from "../../foundation/CardBox/MiniBorderCardBox.styled";
import OXOText from "../../utility/OXOText/OXOText";
import { useHistory } from "react-router-dom";
import KoreanDate from "../../cards/CardComponents/KoreanDate";

function MiddleBar() {
  return (
    <div
      css={css`
        margin-left: 5px;
        margin-right: 5px;
      `}
    >
      |
    </div>
  );
}

export default function MiniMentionResponseCard({
  cardId,
  setSelectedCard
}: {
  cardId: string;
  setSelectedCard: (cardId: string) => void;
}) {
  const [card] = useCardSubscription(cardId);

  const cardStats = useFirestoreGetDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  const history = useHistory();
  const popularity = (cardStats?.o || 0) / (cardStats?.count || 1);

  return (
    <MiniBorderCardBox
      css={css`
        cursor: pointer;
      `}
      onClick={() => {
        setSelectedCard(cardId);
      }}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div>
            <span
              css={css`
                font-weight: 700;
                padding-right: 10px;
              `}
            >
              발언자
            </span>
            <span>{card?.speakerHashTag?.[0].replace("#", "")}</span>
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <TextPill
              backgroundColor={
                popularity > 0.5
                  ? DATA_PLATFORM_GREEN_COLOR
                  : DATA_PLATFORM_RED_COLOR
              }
            >
              {popularity > 0.5 ? "긍정적" : "부정적"}
            </TextPill>
            <div
              css={css`
                margin-left: 5px;
                font-size: 10px;
                color: ${popularity > 0.5
                  ? DATA_PLATFORM_GREEN_COLOR
                  : DATA_PLATFORM_RED_COLOR};
                font-weight: 900;
              `}
            >
              {(popularity * 100).toFixed()}%
            </div>
          </div>
        </div>
      </div>

      <div
        css={css`
          margin-top: 10px;
          color: ${DATA_PLATFORM_GRAY_COLOR};

          p {
            font-size: 14px;
            margin-bottom: 0px;
            line-height: 120%;
          }
        `}
      >
        {<OXOText text={card?.title.slice(0, 100)} />}
      </div>

      <div
        css={css`
          padding-top: 10px;
          color: #afafaf;
          font-size: 12px;
          display: flex;
        `}
      >
        <div>댓글 {card?.comments}</div>
        <MiddleBar />
        <div>응답 {cardStats?.count}</div>
        <MiddleBar />
        <div>
          <KoreanDate timestamp={card?.featuredOnNewsTimelineAt || 0} />
        </div>
      </div>
    </MiniBorderCardBox>
  );
}
