import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";

import { parseDoc } from "../utils";
import firebase from "firebase/compat";

/**
 * Subscribe to a document's live updates in firestore.
 *
 * When a document does not exists, this only returns `{ id: document_id }`.
 *
 * @param table Table name on FirestoreCollection.
 * @param id Document ID.
 * @param externalDocument Optional external document. When this is set,
 *        live data will not be fetched.
 * @param debug If true, a console message will be logged every time the doc is updated.
 */
export default function useFirestoreGetDocument<T>(
  table: FirestoreCollection,
  id: string | undefined,
  externalDocument?: T,
  debug = false
) {
  const [value, setValue] = useState<T>();
  useEffect(() => {
    if (externalDocument) {
      return;
    }

    if (!id) {
      return;
    }
    firebase
      .firestore()
      .collection(table)
      .doc(id)
      .get()
      .then(snapshot => {
        if (debug) {
          console.log("Updating", table);
        }
        setValue(parseDoc(snapshot));
      });
  }, [table, id, externalDocument, debug]);

  if (externalDocument) {
    return externalDocument;
  }

  return value;
}

export function useGetCard(cardId: string) {
  return useFirestoreGetDocument<CardSchema>(FirestoreCollection.CARDS, cardId);
}
