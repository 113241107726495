import {
  FirestoreCollection,
  UserDeviceTokenSchema,
  UserNotificationSubscriptionSchema
} from "../constants/firestore_schema";
import { getDocsFromSnapshot } from "../utils/utils";
import firebase from "firebase/compat";
import {
  subscribeToTopic,
  unsubscribeFromTopic
} from "./notification_cloud_lib";

export function updateNotificationSubscription(
  cardId: string,
  add: boolean,
  currentUserId: string
) {
  const subscriptionRef = firebase
    .firestore()
    .collection(FirestoreCollection.USER_NOTIFICATION_SUBSCRIPTIONS)
    .doc(currentUserId + cardId);

  if (add) {
    subscriptionRef
      .set({
        userId: currentUserId,
        cardId: cardId,
        updatedAt: new Date().valueOf(),
        isSubscribed: true
      } as UserNotificationSubscriptionSchema)
      .then(() => {
        firebase
          .firestore()
          .collection(FirestoreCollection.USER_DEVICE_TOKENS)
          .where("userId", "==", currentUserId)
          .get()
          .then(getDocsFromSnapshot)
          .then((deviceTokens: UserDeviceTokenSchema[]) => {
            const tokens = deviceTokens.map(deviceToken => deviceToken.token);
            subscribeToTopic(tokens, cardId);
          });
      });
  } else {
    subscriptionRef
      .set({
        userId: currentUserId,
        cardId: cardId,
        updatedAt: new Date().valueOf(),
        isSubscribed: false
      })
      .then(() => {
        firebase
          .firestore()
          .collection(FirestoreCollection.USER_DEVICE_TOKENS)
          .where("userId", "==", currentUserId)
          .get()
          .then(getDocsFromSnapshot)
          .then((deviceTokens: UserDeviceTokenSchema[]) => {
            const tokens = deviceTokens.map(deviceToken => deviceToken.token);
            unsubscribeFromTopic(tokens, cardId);
          });
      });
  }
}

// @ts-ignore
window.sendUserInfo = () => {
  const subscribeList = ["ALL_TOPICS"];

  const win = window as any;
  const uid = firebase.auth().currentUser?.uid || "LOGOUT";

  if (uid !== "LOGOUT") {
    firebase
      .firestore()
      .collection(FirestoreCollection.USER_NOTIFICATION_SUBSCRIPTIONS)
      .where("userId", "==", uid)
      .get()
      .then(getDocsFromSnapshot)
      .then(lists => {
        lists.forEach((list: any) => {
          subscribeList.push(encodeURI(list.cardId));
        });

        const subscrbe = JSON.stringify(subscribeList);

        if (window.navigator.userAgent.startsWith("==Android==")) {
          win.MyApp.sendToApp(
            `{"action":"set_subscription", "uid": "${uid}", "subscribe": ${subscrbe} }`
          );
        } else if (window.navigator.userAgent.startsWith("==iOS==")) {
          win.webkit.messageHandlers.MyApp.postMessage(
            `{"action":"set_subscription", "uid": "${uid}", "subscribe": ${subscrbe} }`
          );
        }
      });
  } else {
    const subscrbe = JSON.stringify(subscribeList);

    if (window.navigator.userAgent.startsWith("==Android==")) {
      win.MyApp.sendToApp(
        `{"action":"set_subscription", "uid": "${uid}", "subscribe": ${subscrbe} }`
      );
    } else if (window.navigator.userAgent.startsWith("==iOS==")) {
      win.webkit.messageHandlers.MyApp.postMessage(
        `{"action":"set_subscription", "uid": "${uid}", "subscribe": ${subscrbe} }`
      );
    }
  }
};
