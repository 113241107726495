/** @jsxImportSource @emotion/react */

import React from "react";
import { CardSchema } from "../../../constants/firestore_schema";
import { todayInKorea } from "../../../utils/datetime";
import HashtagTimeSeriesAnswersForCard from "./HashtagTimeSeriesAnswersForCard";
import { CSS_TD } from "../DataExplorerView/shared";

export default function HashtagTimeSeriesAnswers({
  cards
}: {
  cards: CardSchema[];
}) {
  if (!cards) {
    return null;
  }

  return (
    <table>
      <tr>
        <td></td>
        <td colSpan={4}>Count</td>
        <td colSpan={3}>단순 비율</td>
        <td colSpan={3}>옥소 지수</td>
      </tr>
      <tr>
        <td css={CSS_TD}>날짜</td>
        <td css={CSS_TD}>O</td>
        <td css={CSS_TD}>?</td>
        <td css={CSS_TD}>X</td>
        <td css={CSS_TD}>계</td>
        <td css={CSS_TD}>O</td>
        <td css={CSS_TD}>?</td>
        <td css={CSS_TD}>X</td>
        <td css={CSS_TD}>O</td>
        <td css={CSS_TD}>?</td>
        <td css={CSS_TD}>X</td>
      </tr>
      {cards.map(card => {
        const koreanDate = todayInKorea(card.featuredOnNewsTimelineAt);

        return (
          <tr key={card.id}>
            <td css={CSS_TD}>
              {koreanDate.year +
                "." +
                koreanDate.month +
                "." +
                koreanDate.day +
                "."}
            </td>
            <HashtagTimeSeriesAnswersForCard cardId={card.id} />
          </tr>
        );
      })}
    </table>
  );
}
