import React from "react";
import { BACKGROUND_COLOR } from "../../../constants/enums";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";

export default function CardLoader() {
  return (
    <div
      style={{
        width: "100%",
        height: "210px",
        padding: "0 10px",
        borderRadius: "20px",
        border: "solid 2px " + BACKGROUND_COLOR,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <DocumentSkeleton />
    </div>
  );
}
