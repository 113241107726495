import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/compat";

import React, { useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useIsAndroid } from "../../../../../utils/hooks/useIsAndroid";
import { useIsApp } from "../../../../../utils/hooks/useIsApp";
import FacebookButton from "../imgs/FacebookButton";
import KakaoTalkButton from "../imgs/KakaoTalkButton";
import TwitterButton from "../imgs/TwitterButton";
import UrlLinkButton from "../imgs/UrlLinkButton";

export default function StanceTestShareButton({
  linkUrl,
  facebookTitle,
  twitterTitle,
  color
}: {
  linkUrl: string;
  facebookTitle: string;
  twitterTitle: string;
  color: string;
}) {
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isApp = useIsApp();
  const isAndroid = useIsAndroid();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        fontSize: "18px"
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {isApp ? (
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_copy_link", {
              event_category: "hashcard"
            });
            setSnackbarMessage("링크가 복사되었습니다. 붙여넣기 해 보세요.");
            const win = window as any;
            const json_msg =
              '{"action":"write_clipboard","text":"' + linkUrl + '"}';

            isAndroid
              ? win.send_to_app("Android", json_msg)
              : win.send_to_app("iOS", json_msg);

            setOpenSnackBar(true);
          }}
          style={{
            cursor: "pointer"
          }}
        >
          <UrlLinkButton color={color} />
        </div>
      ) : (
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_copy_link", {
              event_category: "hashcard"
            });
            setSnackbarMessage("링크가 복사되었습니다. 붙여넣기 해 보세요.");
            navigator.clipboard.writeText(linkUrl).then(value => {
              setOpenSnackBar(true);
            });
          }}
          style={{
            cursor: "pointer"
          }}
        >
          <UrlLinkButton color={color} />
        </div>
      )}

      <div style={{ cursor: "pointer" }}>
        <div
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_kakao", {
              event_category: "hashcard"
            });

            let imageUrl = "";
            let kakaotalkTitle = "";

            if (linkUrl.endsWith("/acs")) {
              kakaotalkTitle =
                "내 생각과 가까운 공약을 가진 서울시장 후보는 안철수입니다.";
              imageUrl = linkUrl + "/acs.jpg";
            } else if (linkUrl.endsWith("/pys")) {
              kakaotalkTitle =
                "내 생각과 가까운 공약을 가진 서울시장 후보는 박영선입니다.";
              imageUrl = linkUrl + "/pys.jpg";
            } else if (linkUrl.endsWith("/osh")) {
              kakaotalkTitle =
                "내 생각과 가까운 공약을 가진 서울시장 후보는 오세훈입니다.";
              imageUrl = linkUrl + "/osh.jpg";
            } else if (linkUrl.endsWith("/phj")) {
              kakaotalkTitle =
                "내 생각과 가까운 공약을 가진 부산시장 후보는 박형준입니다.";
              imageUrl = linkUrl + "/phj.jpg";
            } else if (linkUrl.endsWith("/kyc")) {
              kakaotalkTitle =
                "내 생각과 가까운 공약을 가진 부산시장 후보는 김영춘입니다.";
              imageUrl = linkUrl + "/kyc+.jpg";
            } else if (linkUrl.endsWith("/2021_seoul")) {
              kakaotalkTitle =
                "옥소폴리틱스에서 나와 맞는 서울시장을 알아보세요.";
              imageUrl = linkUrl + "/seoul.jpg";
            } else if (linkUrl.endsWith("/2021_busan")) {
              kakaotalkTitle =
                "옥소폴리틱스에서 나와 맞는 부산시장을 알아보세요.";
              imageUrl = linkUrl + "/busan.jpg";
            }

            const win = window as any;
            win.kakaotalk_share(kakaotalkTitle, linkUrl, imageUrl);
          }}
        >
          <KakaoTalkButton color={color} />
        </div>
      </div>
      <div style={{ cursor: "pointer" }}>
        <FacebookShareButton url={linkUrl}>
          <div
            onClick={() => {
              firebase.analytics().logEvent("v4_click_test_share_facebook", {
                event_category: "hashcard"
              });
            }}
          >
            <FacebookButton color={color} />
          </div>
        </FacebookShareButton>
      </div>
      <div style={{ cursor: "pointer" }}>
        <TwitterShareButton
          url={linkUrl}
          title={`[OXOpolitics] ${twitterTitle}`}
          onClick={() => {
            firebase.analytics().logEvent("v4_click_test_share_twitter", {
              event_category: "hashcard"
            });
          }}
        >
          <TwitterButton color={color} />
        </TwitterShareButton>
      </div>
    </div>
  );
}
