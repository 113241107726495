import React, { useEffect, useState } from "react";
import {
  AnswerCountSchema,
  CardAnswerSchema
} from "../../../constants/firestore_schema";

export default function DistrictResultsBruteForce({
  cardAnswers
}: {
  cardAnswers: CardAnswerSchema[];
}) {
  const [cardAnswersByDistrict, setCardAnswersByDistrict] = useState<{
    [district: string]: AnswerCountSchema;
  }>();
  useEffect(() => {
    if (!cardAnswers) {
      return;
    }
    const cardAnswersByDistrict = cardAnswers.reduce(
      (acc: { [district: string]: AnswerCountSchema }, cardAnswer) => {
        const jurisdictionAddress = cardAnswer.jurisdictionAddress!;
        if (!acc[jurisdictionAddress]) {
          acc[jurisdictionAddress] = {
            o: 0,
            "?": 0,
            x: 0,
            count: 0
          };
        }
        acc[jurisdictionAddress][cardAnswer.answer as "o" | "?" | "x"] += 1;
        acc[jurisdictionAddress].count += 1;
        return acc;
      },
      {}
    );

    setCardAnswersByDistrict(cardAnswersByDistrict);
  }, [cardAnswers]);

  if (!cardAnswersByDistrict) {
    return null;
  }

  return (
    <table>
      <thead>
        <tr>
          <td>지역</td>
          <td>찬성</td>
          <td>보류</td>
          <td>반대</td>
          <td>응답수</td>
        </tr>
      </thead>
      {Object.keys(cardAnswersByDistrict)
        .sort()
        .map(district => {
          return (
            <tr key={district}>
              <td>{district.slice(18)}</td>
              <td> {cardAnswersByDistrict[district]["o"]}</td>
              <td> {cardAnswersByDistrict[district]["?"]}</td>
              <td> {cardAnswersByDistrict[district]["x"]}</td>
              <td> {cardAnswersByDistrict[district].count}</td>
            </tr>
          );
        })}
    </table>
  );
}
