import React, { useContext } from "react";
import { AnswerSymbol, oxoColors, UserAnswer } from "../../../constants/enums";
import {
  FirestoreCollection,
  QuestionAnswerMapSchema,
  UserPrivilege,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import UserMap, {
  SeriesType
} from "../../foundation/Charts/UserMap/UserMap-victory";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import ProfileAnswerDiff from "./ProfileAnswerDiff";
import RealNameUserDetails from "./RealNameUserDetails";
import { getUserName } from "./UserName";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";

export default function UserPoliticsForProfile({
  profileUserId
}: {
  profileUserId: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [questionAnswers] = useFirestoreLiveDocument<QuestionAnswerMapSchema>(
    FirestoreCollection.QUESTION_ANSWER_MAP,
    profileUserId
  );
  const [currentUserMetadata] =
    useFirestoreLiveDocument<UserProfileMetadataSchema>(
      FirestoreCollection.USER_PROFILE_METADATA,
      currentUserId
    );
  const [userProfileMetadataForProfile] =
    useFirestoreLiveDocument<UserProfileMetadataSchema>(
      FirestoreCollection.USER_PROFILE_METADATA,
      profileUserId
    );

  const userName =
    userProfileMetadataForProfile &&
    getUserName(userProfileMetadataForProfile, profileUserId);

  if (
    !profileUserId ||
    !userProfileMetadataForProfile ||
    (currentUserId && !currentUserMetadata) ||
    !dataProps?.mapCoordinates ||
    !questionAnswers
  ) {
    return null;
  }

  const seriesData: { [clusterId: string]: number[][] } = {
    me: [],
    unanswered: [],
    profile: []
  };

  Object.keys(dataProps.mapCoordinates).map(coordinateId => {
    const mapCoordinate = dataProps.mapCoordinates[coordinateId];
    seriesData["unanswered"].push([mapCoordinate.x, mapCoordinate.y]);
  });

  seriesData[UserAnswer.O] = [];
  seriesData[UserAnswer.X] = [];
  seriesData[UserAnswer.DUNNO] = [];

  Object.keys(questionAnswers).forEach(coordinateId => {
    const arr = [
      questionAnswers[coordinateId].countO,
      questionAnswers[coordinateId].countX,
      questionAnswers[coordinateId].countDunno
    ];
    const i = arr.indexOf(Math.max(...arr));

    if (i === 0) {
      seriesData[UserAnswer.O].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 1) {
      seriesData[UserAnswer.X].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    } else if (i === 2) {
      seriesData[UserAnswer.DUNNO].push([
        questionAnswers[coordinateId].x,
        questionAnswers[coordinateId].y
      ]);
    }
  });

  const mapWidth = Math.min(window.screen.width, 750);

  const defaultSize = mapWidth / 350;

  const series: SeriesType[] = [
    {
      name: AnswerSymbol.O,
      data: seriesData[UserAnswer.O] || [],
      color: oxoColors.O,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.DUNNO,
      data: seriesData[UserAnswer.DUNNO] || [],
      color: oxoColors.DUNNO,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.X,
      data: seriesData[UserAnswer.X] || [],
      color: oxoColors.X,
      size: defaultSize + 1
    },
    {
      name: "응답 안 함",
      data: seriesData["unanswered"] || [],
      color: oxoColors.OTHERS,
      size: defaultSize,
      opacity: 0.4
    }
  ];

  if (currentUserMetadata) {
    series.unshift({
      name: "나",
      data: [[currentUserMetadata.x, currentUserMetadata.y]],
      color: oxoColors.ME,
      label: "나",
      size: 4
    });
  }

  series.unshift({
    name: "profile",
    data: [[userProfileMetadataForProfile.x, userProfileMetadataForProfile.y]],
    color: oxoColors.ME,
    label: userName,
    size: 4
  });

  return (
    <div
      style={{
        textAlign: "left",
        position: "relative"
      }}
    >
      <div style={{ marginTop: 20 }}>
        {userProfileMetadataForProfile.privileges &&
        userProfileMetadataForProfile.privileges.includes(
          UserPrivilege.REAL_NAME_USER
        ) ? (
          <RealNameUserDetails
            userId={profileUserId}
            userProfileMetadata={userProfileMetadataForProfile}
          />
        ) : (
          <div
            style={{
              position: "relative",
              backgroundColor: "white",
              borderRadius: "15px",
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <UserMap series={series} />
          </div>
        )}
        <div style={{ padding: "0 20px" }}>
          {currentUserId && profileUserId !== currentUserId && (
            <ProfileAnswerDiff
              currentUserId={currentUserId}
              profileUserId={profileUserId}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function quadraticSolve(a: number, b: number, c: number) {
  const result1 = (-1 * b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
  const result2 = (-1 * b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
  return [result1, result2];
}
