/** @jsxImportSource @emotion/react */

import React from "react";
import { CardAnswerStatsTimeSeriesSchema } from "../../../constants/firestore_schema";
import { VictoryLine } from "victory";
import { oxoColors } from "../../../constants/enums";

export function ApprovalRateLineChart({
  dailyStats
}: {
  dailyStats: CardAnswerStatsTimeSeriesSchema[];
}) {
  const ratios = dailyStats
    .sort((s1, s2) => s1.createdAt - s2.createdAt)
    .map((dailyStat, i) => {
      const ratio = (dailyStat.o || 0) / (dailyStat.count || 1);
      return {
        x: i + 1,
        y: ratio,
        label:
          i === dailyStats.length - 1 || i == 0
            ? (ratio * 100).toFixed() + "%"
            : ""
      };
    });

  const strokeColor =
    ratios[0].y > ratios[ratios.length - 1].y
      ? oxoColors.X
      : ratios[0].y < ratios[ratios.length - 1].y
      ? oxoColors.O
      : oxoColors.DUNNO;

  return (
    <VictoryLine
      style={{
        data: { stroke: strokeColor }
      }}
      height={30}
      width={70}
      padding={0}
      animate={{
        duration: 2000,
        onLoad: { duration: 1000 }
      }}
      data={ratios}
      labels={({ datum }) => datum.label}
      domain={{ y: [0, 1] }}
    />
  );
}
