import React from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { addToCardIdStackUrl } from "../../../../utils/utils";
import HashPagePortraitWithRating from "./HashPagePortraitWithRating";
import HashPageSupportInformation from "./HashPageSupportInformation";

const HashPageSubTypePeople = ({ cardId }: { cardId: string }) => {
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const history = useHistory();
  return (
    <div
      style={{
        width: "100%",
        height: 180,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          padding: 10
        }}
      >
        <div>{<HashPagePortraitWithRating cardId={cardId} />}</div>
        <div style={{ width: 280, padding: 20 }}>
          {<HashPageSupportInformation cardId={cardId} />}
        </div>
      </div>
      <div style={{ width: "100%", padding: "5px 22px", color: "#3E7A99" }}>
        <span style={{ color: "black", fontWeight: "bold", paddingRight: 12 }}>
          {card && card.id.replace(/\_/gi, " ")}
        </span>
        {card?.hashtags &&
          card.hashtags.map((hashtag, i) => (
            <span
              key={hashtag + i}
              style={{ paddingRight: 5, cursor: "pointer" }}
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                history.push(
                  history.location.pathname +
                    addToCardIdStackUrl(
                      history.location.search,
                      hashtag.replace("#", "")
                    )
                );
              }}
            >
              {hashtag}
            </span>
          ))}
      </div>
    </div>
  );
};

export default HashPageSubTypePeople;
