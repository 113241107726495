import React from "react";
import { DEBATE_ABSTRACT, GRAY_DARKER } from "../../../../constants/enums";
import { EducationConfiguration } from "../common/hashpage_shared";

export default function HashEduBackground({
  eduConfiguration
}: {
  eduConfiguration: EducationConfiguration;
}) {
  return (
    <div
      style={{
        padding: 30,
        textAlign: "center",
        backgroundColor: DEBATE_ABSTRACT
      }}
    >
      <div style={{ fontSize: 24, fontWeight: "bold", paddingBottom: 20 }}>
        학력
      </div>
      {eduConfiguration.내용 &&
        eduConfiguration.내용.map((eduBackground, i) => {
          return (
            <div
              key={eduBackground + i}
              style={{
                paddingBottom: 10,
                fontSize: 16,
                fontWeight: "bold",
                color: GRAY_DARKER
              }}
            >
              {eduBackground}
            </div>
          );
        })}
    </div>
  );
}
