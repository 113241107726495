import React from "react";
import whiteModeBackButton from "../../chat/imgs/white_mode_new_back.svg";

export default function ProfileHeader({ title = "" }: { title?: string }) {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: 750,
        zIndex: 101,
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        top: 0,
        fontWeight: "bolder",
        backgroundColor: "white"
      }}
    >
      <div
        style={{
          padding: "15px 20px 10px",
          cursor: "pointer"
        }}
        onClick={e => {
          window.history.back();
        }}
      >
        <img src={whiteModeBackButton} width={10} alt="whiteModeBackButton" />
      </div>
      <div>{title}</div>
      <div style={{ padding: "5px 25px 5px" }}></div>
    </div>
  );
}
