import firebase from "firebase/compat";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../../constants/firestore_schema";
import { ROUTES } from "../../../../../constants/routes";
import { deleteCardAnswers } from "../../../../../lib/answer_lib";
import {
  getDocsFromSnapshot,
  listToMap,
  mapValues,
  parseDoc
} from "../../../../../utils/utils";
import { DataPropsContext } from "../../../../foundation/Contexts/DataPropsContext";
import {
  HashtagConfiguration,
  parseYamlDescription,
  StanceTestConfiguration
} from "../../common/hashpage_shared";
import HashPageElectionInfo from "../../Election/HashPageElectionInfo";
import HashPageName from "../../HashPageName";
import HashPagePhoto from "../../HashPagePhoto";
import StanceTestResultsReview from "../StanceTestResultsReview";
import StanceTestShareButton from "../StanceTestShareBtns/StanceTestShareButtons";
import useFirestoreLiveDocument from "../../../../../utils/hooks/useFirestoreLiveDocument";
import { sendCoinFromOxoTo } from "../../../../../utils/assets";

export default function StanceTestResultTypeSimilarity({
  hashtag,
  stanceTestConfiguration
}: {
  hashtag: string;
  stanceTestConfiguration: StanceTestConfiguration;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const categories =
    stanceTestConfiguration && stanceTestConfiguration.카테고리;
  const [stanceVectors, setStanceVectors] = useState<{
    [category: string]: UserVectorSchema;
  }>();
  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.TEST_QUESTION)
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setStanceTestCards);

    if (currentUserId) {
      sendCoinFromOxoTo(currentUserId, 10, "[1회성]" + hashtag);
    }
  }, [hashtag]);

  useEffect(() => {
    if (!categories) {
      return;
    }

    const vectorPromises = Object.keys(categories).map(category => {
      return firebase
        .firestore()
        .collection(FirestoreCollection.USER_VECTOR)
        .doc(category)
        .get()
        .then(parseDoc);
    });

    Promise.all(vectorPromises).then(vectors => {
      const categoryVectorPairs: [string, UserVectorSchema][] = Object.keys(
        categories
      ).map((category, i) => {
        return [category, vectors[i] as UserVectorSchema];
      });

      console.log(categoryVectorPairs);
      const stanceVectorMap = listToMap(
        categoryVectorPairs,
        (item: [string, UserVectorSchema]) => item[0],
        (item: [string, UserVectorSchema]) => item[1]
      ) as {
        [category: string]: UserVectorSchema;
      };
      setStanceVectors(stanceVectorMap);
    });

    return () => {};
  }, [stanceTestConfiguration]);

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const categoryMatchingAnswerRatio = useMemo(() => {
    if (!stanceVectors || !stanceTestCards || !categories) {
      return null;
    }
    const matchingAnswersCount: [string, number][] = Object.keys(
      categories
    ).map(category => {
      if (currentUserVector && stanceVectors && stanceVectors[category]) {
        return [
          category,
          stanceTestCards
            .map(question => {
              const questionId = question.id;

              return (
                currentUserVector[questionId] ===
                stanceVectors[category][questionId]
              );
            })
            .filter(a => a).length
        ];
      }
      return [category, 0];
    });

    const categoryMatchingAnswers = listToMap(
      matchingAnswersCount,
      (item: [string, number]) => item[0],
      (item: [string, number]) => item[1]
    ) as { [category: string]: number };

    return mapValues(categoryMatchingAnswers, (count: number) => {
      return count / (stanceTestCards.length || 1);
    }) as { [category: string]: number };
  }, [categories, stanceTestCards, stanceVectors]);

  const sortedCategories = useMemo(() => {
    if (categoryMatchingAnswerRatio) {
      return Object.keys(categoryMatchingAnswerRatio).sort(
        (categoryA, categoryB) => {
          return (
            categoryMatchingAnswerRatio[categoryB] -
            categoryMatchingAnswerRatio[categoryA]
          );
        }
      );
    }
    return null;
  }, [categoryMatchingAnswerRatio]);

  const topCategories = useMemo(() => {
    if (
      !sortedCategories ||
      !sortedCategories?.length ||
      !categoryMatchingAnswerRatio
    ) {
      return null;
    }
    const topScore = categoryMatchingAnswerRatio[sortedCategories[0]];

    return sortedCategories?.filter(
      category => categoryMatchingAnswerRatio[category] === topScore
    );
  }, [sortedCategories, categoryMatchingAnswerRatio]);

  const [winnerProfileCard, setWinnerProfileCard] = useState<CardSchema>();
  useEffect(() => {
    if (topCategories && topCategories[0]) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(topCategories[0].replace("#", ""))
        .get()
        .then(parseDoc)
        .then(setWinnerProfileCard);
    }
  }, [topCategories]);

  const winnerProfileCardHashPageConfiguration:
    | HashtagConfiguration
    | undefined = useMemo(() => {
    if (winnerProfileCard) {
      return parseYamlDescription(
        winnerProfileCard.hashtagMetadata?.description
      );
    }
  }, [winnerProfileCard]);

  if (
    !topCategories ||
    !stanceTestConfiguration ||
    !categoryMatchingAnswerRatio ||
    !winnerProfileCardHashPageConfiguration
  ) {
    return null;
  }

  const resultString = stanceTestConfiguration.결과?.replace(
    /#\{.+\}/,
    topCategories[0]
  );

  const winnerCategory = topCategories[0];

  const topCategoryUrl =
    stanceTestConfiguration.카테고리?.[topCategories[0]].URL;

  const highlightColor =
    stanceTestConfiguration.카테고리?.[topCategories[0]].color;

  const highlightColorHeavy =
    stanceTestConfiguration.카테고리?.[topCategories[0]].colorHeavy ||
    highlightColor;

  const hashtagConfiguration = parseYamlDescription<HashtagConfiguration>(
    winnerProfileCard?.hashtagMetadata?.description
  );

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              paddingTop: 10,
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 18 }}>{resultString}</div>
          </div>

          {hashtagConfiguration.선거정보 && (
            <HashPageElectionInfo
              electionInfo={hashtagConfiguration.선거정보}
              hashtag={topCategories[0]}
            />
          )}
          <HashPageName hashtag={topCategories[0]} />

          {winnerProfileCard && <HashPagePhoto card={winnerProfileCard} />}

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              fontFamily: "East Sea Dokdo",
              color: highlightColorHeavy
            }}
          >
            <div style={{ fontSize: 60 }}>
              {(categoryMatchingAnswerRatio[winnerCategory] * 100).toFixed()}
            </div>
            <div style={{ fontSize: 34, paddingBottom: 10 }}>% 일치</div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          color: highlightColorHeavy
        }}
      >
        {stanceTestConfiguration.결과링크 && resultString && (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              firebase.analytics().logEvent("v4_click_share_only_test_button", {
                event_category: "hashcard",
                event_label: hashtag
              });
            }}
          >
            <div
              style={{
                paddingRight: 20,
                width: 180,
                fontWeight: "bold",
                textAlign: "right"
              }}
            >
              테스트만 공유하기
            </div>

            <StanceTestShareButton
              linkUrl={stanceTestConfiguration.결과링크}
              facebookTitle={resultString}
              twitterTitle={resultString}
              color={highlightColorHeavy || "black"}
            />
          </div>
        )}

        {stanceTestConfiguration.카테고리 &&
          stanceTestConfiguration.카테고리[topCategories[0]] && (
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              onClick={() => {
                firebase
                  .analytics()
                  .logEvent("v4_click_share_test_result_button", {
                    event_category: "hashcard",
                    event_label: hashtag
                  });
              }}
            >
              <div
                style={{
                  paddingRight: 20,
                  width: 180,
                  fontWeight: "bold",
                  textAlign: "right"
                }}
              >
                내 결과 공유하기
              </div>
              {topCategoryUrl && resultString && (
                <StanceTestShareButton
                  linkUrl={topCategoryUrl}
                  facebookTitle={resultString}
                  twitterTitle={resultString}
                  color={highlightColorHeavy || "black"}
                />
              )}
            </div>
          )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: highlightColor,
          borderRadius: 10,
          fontWeight: "bold",
          fontSize: 18,
          cursor: "pointer",
          width: 325,
          height: 70
        }}
        onClick={() => {
          firebase.analytics().logEvent("v4_click_test_home_button", {
            event_category: "hashcard",
            event_label: hashtag
          });
          history.push(ROUTES.HOME);
        }}
      >
        홈으로 가기
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: highlightColor,
          border: "solid 1px " + highlightColor,
          borderRadius: 10,
          marginTop: 10,
          fontWeight: "bold",
          fontSize: 18,
          cursor: "pointer",
          width: 325,
          height: 70
        }}
        onClick={() => {
          firebase.analytics().logEvent("v4_click_test_again_button", {
            event_category: "hashcard",
            event_label: hashtag
          });
          if (currentUserVector && currentUserId) {
            stanceTestCards?.forEach(card => {
              delete currentUserVector[card.id];
            });
            currentUserMetadata &&
              deleteCardAnswers(
                currentUserId,
                stanceTestCards?.map(card => card.id) || [],
                currentUserMetadata
              );
            firebase
              .firestore()
              .collection(FirestoreCollection.USER_VECTOR)
              .doc(currentUserId)
              .set(currentUserVector);
            history.go(-1);
          }
        }}
      >
        테스트 다시하기
      </div>

      <div style={{ height: 10 }}></div>
      {highlightColor && (
        <StanceTestResultsReview
          hashtag={hashtag}
          stanceTestConfiguration={stanceTestConfiguration}
          highlightColor={highlightColor}
        />
      )}
    </div>
  );
}
