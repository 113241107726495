import { ChartDataSchema } from "../components/views/DailyReportsView/HashtagPopularityDetails";
import { DomainTuple } from "victory-core";

export function getYRange(
  supportingRateData: ChartDataSchema[] | undefined,
  padding = 5
) {
  let range: DomainTuple = [0, 100];
  if (supportingRateData) {
    const values = supportingRateData.map(data => data.y);
    range = [
      Math.max(Math.min(...values) - padding, 0),
      Math.min(100, Math.max(...values) + padding)
    ];
  }
  return range;
}

export function getAgreePercentage(cardStats: {
  x: number;
  count: number;
  o: number;
  "?": number;
}) {
  const count = (cardStats.o || 0) + (cardStats.x || 0) + (cardStats["?"] || 0);

  return ((cardStats.o || 0) / (count || 1)) * 100;
}

export function getDisagreePercentage(cardStats: {
  x: number;
  count: number;
  o: number;
  "?": number;
}) {
  const count = (cardStats.o || 0) + (cardStats.x || 0) + (cardStats["?"] || 0);

  return ((cardStats.x || 0) / (count || 1)) * 100;
}

export function getDunnoPercentage(cardStats: {
  x: number;
  count: number;
  o: number;
  "?": number;
}) {
  const count = (cardStats.o || 0) + (cardStats.x || 0) + (cardStats["?"] || 0);

  return ((cardStats["?"] || 0) / (count || 1)) * 100;
}
