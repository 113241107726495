import React from "react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import {
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { getMostSupportingTribe } from "../../../../utils/utils";

const HashPageSupportInformation = ({ cardId }: { cardId: string }) => {
  const [cardAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  const [cardAnswerTribeStats] =
    useFirestoreLiveDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  if (!cardAnswerStats || !cardAnswerTribeStats) {
    return null;
  }

  const supporterPercentage = `${(
    ((cardAnswerStats.o || 0) / (cardAnswerStats.count || 1)) *
    100
  ).toFixed()}%`;

  const supporterCountNumber: string = (cardAnswerStats.o || 0).toLocaleString(
    "en-US"
  );
  const mostSupportiveOfTribe = getMostSupportingTribe(cardAnswerTribeStats);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div style={{ fontWeight: "bold" }}>{supporterPercentage}</div>
        <div style={{ paddingTop: 5 }}>지지율</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div style={{ fontWeight: "bold" }}>{supporterCountNumber}</div>
        <div style={{ paddingTop: 5 }}>지지자</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div style={{ fontWeight: "bold" }}>
          {(mostSupportiveOfTribe !== "0" &&
            TRIBE_INFO_BY_ID[mostSupportiveOfTribe].name) ||
            "없음"}
        </div>
        <div style={{ paddingTop: 5 }}>지지부족</div>
      </div>
    </div>
  );
};

export default HashPageSupportInformation;
