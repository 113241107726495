import React, { CSSProperties, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GRAY,
  GRAY_DARK,
  GRAY_EXTRA_LIGHT,
  TRIBE_INFO_BY_ID
} from "../../constants/enums";
import { ChatSchema, ReferenceType } from "../../constants/firestore_schema";
import { addToCardIdStackUrl } from "../../utils/utils";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import KoreanDate from "../cards/CardComponents/KoreanDate";
import ChatControls from "../cards/DetailViews/ChatControls";
import { DataPropsContext } from "../foundation/Contexts/DataPropsContext";
import LikeButton from "../foundation/OXOComment/LikeButton";
import LikeStatsByRow from "../foundation/OXOComment/LikeStatsByRow";
import UserName from "../profile/OXOUserProfile/UserName";
import OXOText from "../utility/OXOText/OXOText";
import UserProfilePhoto from "../views/StatsView/UserProfilePhoto";
import ChatInput from "./ChatInput";
import ChatReplies from "./ChatReplies";
import { isBadContents } from "../../utils/contents";

export default function ChatLine({
  chat,
  firstLine = false,
  lastLine = false,
  collapsed = false,
  onChatBubbleClick,
  style = {},
  isDark,
  onDelete,
  disableReply = false,
  disableRightAlign = false,
  showReplies = true
}: {
  chat: ChatSchema;
  firstLine?: boolean;
  lastLine?: boolean;
  collapsed?: boolean;
  onChatBubbleClick?: (chat: ChatSchema) => void;
  style?: CSSProperties;
  isDark?: boolean;
  onDelete?: () => void;
  disableReply?: boolean;
  disableRightAlign?: boolean;
  showReplies?: boolean;
}) {
  const [replyTo, setReplyTo] = useState<ChatSchema>();
  const [disableOnBlur, setDisableOnblur] = useState<boolean>(false);
  const history = useHistory();
  const [blocked, setBlocked] = useState<boolean>(
    chat && !!chat.message && (!!chat.block || isBadContents(chat.message))
  );

  const [windowWidth, windowHeight] = useWindowSize();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const message =
    currentUserMetadata &&
    currentUserMetadata.blockedUsers?.includes(chat.userId)
      ? chat.message
          .split(" ")
          .map(word => TRIBE_INFO_BY_ID[chat.tribeId].sound)
          .join(" ")
      : chat.message;

  return (
    <div
      style={{
        fontSize: 15,
        display: "flex",
        flexDirection: "column",
        alignItems:
          currentUserId === chat.userId && !collapsed && !disableRightAlign
            ? "flex-end"
            : "flex-start"
      }}
    >
      <div
        style={{
          fontSize: 15,
          display: "flex",
          width: "100%",
          ...(currentUserId === chat.userId && !disableRightAlign
            ? {
                flexDirection: "row-reverse"
              }
            : {})
        }}
      >
        {(chat.userId !== currentUserId || disableRightAlign) && firstLine && (
          <div
            style={{
              borderRadius: "30px",
              padding: "5px",
              width: "35px",
              height: "35px",
              backgroundColor: chat.profilePhotoUrl
                ? GRAY_EXTRA_LIGHT
                : isDark
                ? TRIBE_INFO_BY_ID[chat.tribeId].darkColor
                : TRIBE_INFO_BY_ID[chat.tribeId].talkColor,
              cursor: "pointer",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px"
            }}
            onClick={() => {
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(history.location.search, chat.userId)
              );
            }}
          >
            <UserProfilePhoto
              tribeId={chat.tribeId}
              width={"25px"}
              photoUrl={chat.profilePhotoUrl}
            />
          </div>
        )}
        <div
          style={{
            paddingLeft: firstLine ? 5 : 40,
            width: "100%",
            textAlign: "left"
          }}
        >
          {(chat.userId !== currentUserId || disableRightAlign) && firstLine && (
            <div
              style={{
                fontSize: 13,
                color: GRAY_DARK,
                fontWeight: "bold",
                display: "flex",
                alignItems: "center"
              }}
            >
              <UserName profileUserId={chat.userId} />
              {/* <div style={{ marginLeft: 4, display: "flex" }}>
                {" · "}
                <SupporterPercentage cardId={chat.userId} />
              </div> */}
            </div>
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              ...(currentUserId === chat.userId && !disableRightAlign
                ? {
                    flexDirection: "row-reverse"
                  }
                : {})
            }}
          >
            <div
              style={{
                backgroundColor: collapsed
                  ? "white"
                  : chat.profilePhotoUrl
                  ? GRAY
                  : chat.message.startsWith("![image]")
                  ? "transparent"
                  : isDark
                  ? TRIBE_INFO_BY_ID[chat.tribeId].darkColor
                  : TRIBE_INFO_BY_ID[chat.tribeId].talkColor,
                color: isDark ? "white" : "black",
                borderRadius: "5px",
                padding: chat.message.startsWith("![image]")
                  ? 0
                  : chat.message.includes("http")
                  ? 0
                  : "5px 10px",
                maxWidth: Math.min(windowWidth, 750) - 150,
                marginTop: firstLine ? 5 : -5,
                textAlign: "justify",
                cursor:
                  onChatBubbleClick || !disableReply ? "pointer" : "default",
                ...style
              }}
              className={"chatline"}
              onClick={e => {
                e.stopPropagation();
                if (onChatBubbleClick) {
                  onChatBubbleClick(chat);
                } else {
                  if (!disableReply) {
                    setReplyTo(prev => (prev ? undefined : chat));
                  }
                }
              }}
            >
              {blocked ? (
                <div
                  style={{
                    cursor: "pointer",
                    color: GRAY,
                    lineHeight: 1.5,
                    fontSize: 15
                  }}
                  onClick={() => setBlocked(prev => !prev)}
                >
                  차단된 메시지입니다. 클릭하면 볼 수 있어요.
                </div>
              ) : chat.block ? (
                <div
                  style={{ cursor: "pointer", lineHeight: 1.5, fontSize: 15 }}
                  onClick={() => setBlocked(prev => !prev)}
                >
                  <OXOText text={chat.message} />
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer", lineHeight: 1.5, fontSize: 15 }}
                >
                  <OXOText text={message} />
                </div>
              )}
            </div>
            {!collapsed && (
              <div
                style={{
                  fontSize: 10,
                  color: GRAY,
                  display: "flex",
                  alignItems:
                    currentUserId === chat.userId && !disableRightAlign
                      ? "flex-end"
                      : "flex-start",
                  alignSelf: "flex-end",
                  flexDirection: "column",
                  padding: "0 3px"
                }}
              >
                {chat.id && (
                  <div
                    style={{
                      marginLeft: -5,
                      paddingBottom: chat.message.startsWith("![image]")
                        ? 4
                        : 0,
                      display: "flex",
                      alignItems: "flex-end"
                    }}
                  >
                    <div style={{ marginRight: 5, marginBottom: 2 }}>
                      <LikeButton
                        referenceId={chat.id}
                        referenceParentId={chat.chatRoomId}
                        referenceCreatedAt={chat.createdAt}
                        referenceType={ReferenceType.CHAT}
                        referenceUserId={chat.userId}
                        referenceUserTribeId={chat.tribeId}
                        likeButtonSize={13.4}
                        disableCount
                      />
                    </div>
                    <LikeStatsByRow referenceId={chat.id} />
                    <div style={{ marginBottom: -2 }}>
                      <ChatControls
                        chat={chat}
                        setBlocked={setBlocked}
                        isDark={isDark}
                        onDelete={onDelete}
                        onReply={() => {
                          setDisableOnblur(true);
                          if (onChatBubbleClick) {
                            // This is a hack. onChatBubbleClick currently shows ChatControls.
                            onChatBubbleClick(chat);
                          } else {
                            !disableReply &&
                              setReplyTo(prev => (prev ? undefined : chat));
                          }
                        }}
                        disableReply={disableReply}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {lastLine && !collapsed && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 10,
            color: GRAY,
            marginLeft: 40,
            marginRight: 0,
            marginTop: 3
          }}
        >
          <div>
            <KoreanDate timestamp={chat.createdAt} />
          </div>
        </div>
      )}
      {
        <div
          style={{
            paddingLeft: 40,
            width: Math.min(500, windowWidth - 50)
          }}
        >
          {showReplies && (
            <ChatReplies
              chat={chat}
              isDark={isDark}
              onChatBubbleClick={() => {
                setDisableOnblur(true);
                setReplyTo(prev => (prev ? undefined : chat));
              }}
            />
          )}
          {replyTo && currentUserMetadata && currentUserId && (
            <div style={{ padding: "10px 0" }}>
              <ChatInput
                userMetadata={currentUserMetadata}
                userId={currentUserId}
                currentChatroomId={chat.chatRoomId}
                placeholder={"답글 달기"}
                parentId={chat.id}
                onBlur={() => {
                  if (disableOnBlur) {
                    setDisableOnblur(false);
                  } else {
                    setReplyTo(undefined);
                  }
                }}
                hideHead
              />
            </div>
          )}
        </div>
      }
    </div>
  );
}
