import {
  CardSchema,
  CommentSchema,
  FirestoreCollection,
  LikeSchema,
  MapCoordinatesSchema,
  UserProfileMetadataSchema,
  UserVectorSchema
} from "../constants/firestore_schema";
import {
  answerToNumber,
  getMapFromSnapshots,
  getShardWithMod,
  mapValues,
  parseDoc,
  removeIdKey
} from "../utils/utils";
import { TRIBE_INFO_BY_ID } from "../constants/enums";
import firebase from "firebase/compat";

/**
 * Called in 성향 테스트 결과 페이지들
 *
 * @param userId
 * @param questionId
 * @param prevUserAnswer
 * @param answer
 * @param userVector
 * @param mapCoordinates
 */
export function calculateTribe(
  userId: string,
  mapCoordinates: MapCoordinatesSchema
) {
  if (Object.keys(mapCoordinates).length < 10) {
    console.log("mapCoordinates is not loaded.");
    return;
  }
  const xAxisUserId = "bpyBotiODJgcPu75dMZWg8vU20f2";
  const yAxisUserId = "8WCasvaBd3dRbYzj16r88HnyE4G2";

  if (userId === xAxisUserId || userId === yAxisUserId) {
    return false;
  }

  const xVectorPromise = firebase
    .firestore()
    .collection(FirestoreCollection.USER_VECTOR)
    .doc(xAxisUserId)
    .get()
    .then(parseDoc)
    .then(removeIdKey);

  const yVectorPromise = firebase
    .firestore()
    .collection(FirestoreCollection.USER_VECTOR)
    .doc(yAxisUserId)
    .get()
    .then(parseDoc)
    .then(removeIdKey);

  const currentUserVectorPromise = firebase
    .firestore()
    .collection(FirestoreCollection.USER_VECTOR)
    .doc(userId)
    .get()
    .then(parseDoc)
    .then(removeIdKey);

  const userProfilePromise = firebase
    .firestore()
    .collection(FirestoreCollection.USER_PROFILE_METADATA)
    .doc(userId)
    .get()
    .then(parseDoc)
    .then(removeIdKey);

  return Promise.all([
    xVectorPromise,
    yVectorPromise,
    currentUserVectorPromise,
    userProfilePromise
  ]).then(([xVector, yVector, currentUserVector, userProfileMetadata]) => {
    if (Object.keys(currentUserVector).length < 10) {
      console.log("Too few questions were answered.");
      return;
    }

    return updateUserMapSinglePass(
      mapCoordinates,
      currentUserVector,
      userId,
      xVector,
      yVector,
      userProfileMetadata,
      true
    );
  });
}

function updateCommentTribeIds(userId: string, tribeId: string) {
  firebase
    .firestore()
    .collection(FirestoreCollection.COMMENTS)
    .where("userId", "==", userId)
    .get()
    .then(getMapFromSnapshots)
    .then((comments: { [commentId: string]: CommentSchema }) => {
      let writeBatch = firebase.firestore().batch();
      Object.keys(comments).forEach((commentId, i) => {
        if (i % 400 === 0) {
          writeBatch
            .commit()
            .then(() => console.log("Migration batch committed."));
          writeBatch = firebase.firestore().batch();
        }

        writeBatch.set(
          firebase
            .firestore()
            .collection(FirestoreCollection.COMMENTS)
            .doc(commentId),
          {
            userTribeId: tribeId
          },
          {
            merge: true
          }
        );
      });
      writeBatch.commit();
    });
}

function updateLikeTribeIds(userId: string, tribeId: string) {
  firebase
    .firestore()
    .collection(FirestoreCollection.LIKES)
    .where("likeUserId", "==", userId)
    .get()
    .then(getMapFromSnapshots)
    .then((likes: { [likeId: string]: LikeSchema }) => {
      let writeBatch = firebase.firestore().batch();

      Object.keys(likes).forEach((likeId, i) => {
        if (i % 400 === 0) {
          writeBatch
            .commit()
            .then(() => console.log("Migration batch committed."));
          writeBatch = firebase.firestore().batch();
        }
        writeBatch.set(
          firebase
            .firestore()
            .collection(FirestoreCollection.LIKES)
            .doc(likeId),
          {
            likeUserTribeId: tribeId
          },
          {
            merge: true
          }
        );

        // TODO(will.ryu): 영향 받은 Comment와 카드의 likes 통계 다시 계산 (seems to be an overkill?)
      });

      writeBatch.commit();
    });
}

function updateCardTribeIds(userId: string, tribeId: string) {
  firebase
    .firestore()
    .collection(FirestoreCollection.CARDS)
    .where("createdBy", "==", userId)
    .get()
    .then(getMapFromSnapshots)
    .then((comments: { [commentId: string]: CommentSchema }) => {
      Object.keys(comments).forEach(commentId => {
        firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(commentId)
          .set(
            {
              createdByTribeId: tribeId
            } as CardSchema,
            {
              merge: true
            }
          );
      });
    });
}

export function fixMissingMapCoordinatesTribeId(
  mapCoordinates: MapCoordinatesSchema,
  x: number,
  y: number
) {
  let closestTribe = "3";
  let closestDistance: number | null = null;

  Object.values(mapCoordinates).forEach(mapCoordi => {
    const distance =
      Math.pow(mapCoordi.x - x, 2) + Math.pow(mapCoordi.y - y, 2);

    if (
      (closestDistance == null || closestDistance > distance) &&
      mapCoordi.tribeId !== undefined
    ) {
      closestDistance = distance;
      closestTribe = mapCoordi.tribeId;
    }
  });

  const key = x + "-" + y;

  const data: any = {};
  data[key] = { tribeId: closestTribe };

  firebase
    .firestore()
    .collection(FirestoreCollection.MAP_COORDINATES_SHARDED)
    .doc(getShardWithMod(key, 5).toString())
    .set(data, { merge: true })
    .then(() => console.log("Updated", data));
}

function updateUserMapSinglePass(
  mapCoordinates: MapCoordinatesSchema,
  currentUserVector: UserVectorSchema,
  userId: string,
  xVector: UserVectorSchema,
  yVector: UserVectorSchema,
  prevUserProfileMetadata: UserProfileMetadataSchema | undefined,
  invertY = true
) {
  const userCoordinate = getUserCoordinate(
    currentUserVector,
    userId,
    xVector,
    yVector,
    invertY
  );

  let closestTribe: string = prevUserProfileMetadata?.tribeId || "3";
  let closestDistance: number | null = null;

  const x = Math.round(userCoordinate.coordinates[0] * MAP_RESOLUTION);
  const y = Math.round(userCoordinate.coordinates[1] * MAP_RESOLUTION);

  Object.values(mapCoordinates).forEach(mapCoordi => {
    const distance =
      Math.pow(mapCoordi.x - x, 2) + Math.pow(mapCoordi.y - y, 2);

    if (closestDistance == null || closestDistance > distance) {
      closestDistance = distance;
      closestTribe = mapCoordi.tribeId;
    }
  });

  firebase.analytics().logEvent("v4_new_tribe", {
    event_label: TRIBE_INFO_BY_ID[closestTribe].name
  });
  const tribeId = closestTribe.toString();

  const hasTribeIdChanged = tribeId !== prevUserProfileMetadata?.tribeId;

  /**
   * 업데이트 할 것
   * 1. MAP_COORDINATES -> USER_PROFILE_METADATA: x, y, tribe 업데이트
   * 2. QuestionAnswerMapSchema: 이전 카운트 빼고 새 카운트 추가
   * 3. COMMENTS: answer들 바꿔주기
   *   - Question ID와 User ID로 쿼리 날려서 다 바꾸기
   *   - Comment Likes까지 바꾸는 것은 무리이니 그냥 포기하자
   */
  // 1 MAP_COORDINATES

  const newKey = x + "-" + y;

  // if prev
  //  ? (diff ? (- and +) : no action)
  //  : +

  firebase
    .firestore()
    .collection(FirestoreCollection.USER_PROFILE_METADATA)
    .doc(userId)
    .set(
      {
        x: x,
        y: y,
        tribeId: tribeId
      } as UserProfileMetadataSchema,
      { merge: true }
    );

  const previousKey =
    prevUserProfileMetadata?.x + "-" + prevUserProfileMetadata?.y;

  // prevUserProfileMetadata did not exsist -> New key + 1
  // previousKey === newKey -> no action
  // previousKey !== newKey -> PrevKey -1, newKey +1
  updateMapCoordinates(previousKey, newKey, prevUserProfileMetadata, x, y);

  if (hasTribeIdChanged) {
    updateCommentTribeIds(userId, tribeId);
    updateLikeTribeIds(userId, tribeId);
    updateCardTribeIds(userId, tribeId);
  }

  return true;
}

function updateMapCoordinates(
  previousKey: string,
  newKey: string,
  prevUserProfileMetadata:
    | {
        modifier: string | undefined;
        x: number | undefined;
        y: number | undefined;
        tribeId: string | undefined;
        countPosts: number | undefined;
        countLikes: number | undefined;
        countAnswers: number | undefined;
        countTribeLikes: { [p: string]: number } | undefined;
        countComments: number | undefined;
        points: number | undefined;
      }
    | UserProfileMetadataSchema
    | undefined,
  x: number,
  y: number
) {
  console.log("---");
  if (
    previousKey !== newKey &&
    prevUserProfileMetadata?.x !== undefined &&
    prevUserProfileMetadata?.y !== undefined
  ) {
    const mapCoordinatesRef = firebase
      .firestore()
      .collection(FirestoreCollection.MAP_COORDINATES_SHARDED)
      .doc(getShardWithMod(previousKey, 5).toString());

    firebase
      .firestore()
      .runTransaction(transaction =>
        transaction
          .get(mapCoordinatesRef)
          .then(parseDoc)
          .then(removeIdKey)
          .then((updatedMapCoordinates: MapCoordinatesSchema) => {
            const previousCount =
              updatedMapCoordinates[previousKey]?.count || 0;
            const data: any = {};
            data[previousKey] = {
              count: previousCount - 1
            };

            // Errraneous status. previousCount cannot be zero.
            if (previousCount === 0) {
              return;
            }

            transaction.set(mapCoordinatesRef, data, { merge: true });

            console.log(
              `-- prev ${previousKey}, ${previousCount} => ${previousCount - 1}`
            );
          })
      )
      .then(() => addNewPointCount())
      .then(() => "SUCCESS")
      .catch(e => console.log("ERROR", e));
  } else {
    addNewPointCount();
  }

  function addNewPointCount() {
    if (previousKey !== newKey || !prevUserProfileMetadata) {
      const mapCoordinatesRef = firebase
        .firestore()
        .collection(FirestoreCollection.MAP_COORDINATES_SHARDED)
        .doc(getShardWithMod(newKey, 5).toString());

      firebase
        .firestore()
        .runTransaction(transaction =>
          transaction
            .get(mapCoordinatesRef)
            .then(parseDoc)
            .then(removeIdKey)
            .then((updatedMapCoordinates: MapCoordinatesSchema) => {
              const data: any = {};
              data[newKey] = {
                x: x,
                y: y,
                // Potential bug
                // tribeId: tribeId,
                count: firebase.firestore.FieldValue.increment(1)
              };

              transaction.set(mapCoordinatesRef, data, {
                merge: true
              });

              console.log(
                `new ${newKey}, ${
                  updatedMapCoordinates[newKey]?.count || 0
                } => ${(updatedMapCoordinates[newKey]?.count || 0) + 1}`
              );
            })
        )
        .then(() => "SUCCESS")
        .catch(e => console.log("ERROR", e));
    } else {
      console.log(`No coordinate change ${previousKey} => ${newKey}`);
    }
  }
}

const MAP_RESOLUTION = 20;

function getUserCoordinate(
  currentUserVector: UserVectorSchema,
  userId: string,
  xAxisVector: UserVectorSchema,
  yAxisVector: UserVectorSchema,
  invertY = false
): { coordinates: [number, number] } {
  // Update X, Y vector
  const xVector: { [questionId: string]: number } = mapValues(
    xAxisVector,
    answerToNumber
  );
  const yVector: { [questionId: string]: number } = mapValues(
    yAxisVector,
    (questionAnswer: string) => {
      return answerToNumber(questionAnswer) * (invertY ? -1 : 1);
    }
  );

  const answerByQuestion: { [questionId: string]: string } = currentUserVector;
  const questionIds: string[] = Object.keys(xVector);

  let xCount = 0;
  let yCount = 0;

  const product = questionIds.reduce(
    (result, questionId) => {
      if (questionId === "id") {
        return result;
      }

      if (!answerByQuestion[questionId]) {
        return result;
      }
      const userAnswerNum = answerToNumber(answerByQuestion[questionId]);

      if (userAnswerNum === 0) {
        return result;
      }

      const newX = (xVector[questionId] || 0) * userAnswerNum;
      if (xVector[questionId] === 1 || xVector[questionId] === -1) {
        xCount += 1;
      }

      const newY = (yVector[questionId] || 0) * userAnswerNum;
      if (yVector[questionId] === 1 || yVector[questionId] === -1) {
        yCount += 1;
      }

      return [result[0] + newX, result[1] + newY];
    },
    [0, 0]
  );

  const x = product[0] / (xCount + 1);
  const y = product[1] / (yCount + 1);

  return {
    coordinates: [x, y]
  };
}
