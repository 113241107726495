import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Search } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { ChatSchema } from "../../constants/firestore_schema";
import { Popover } from "@material-ui/core";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import HighlightChatlineWrapper from "./HighlightChatlineWrapper";
import OXOInfiniteScroll from "../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import { DARK_THEME_BACKGROUND } from "../../constants/enums";
import createPersistedState from "use-persisted-state";
import ChatSearchInput from "./ChatSearchInput";
import { AlgoliaContext } from "../views/SearchView/AlgoliaContext";

const CHATS_PER_PAGE = 20;
export default function ChatSearch({
  chatRoomId,
  setMainCursor
}: {
  chatRoomId: string;
  setMainCursor: Dispatch<SetStateAction<number | undefined>>;
}) {
  const useIsDarkPersisted = createPersistedState<boolean>("isDarkChat");
  const [isDark, setIsDark] = useIsDarkPersisted(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [windowWidth, windowHeight] = useWindowSize();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchResults([]);
    setSearchQuery("");
    setAnchorEl(null);
  };

  const [page, setPage] = useState<number>(0);

  const algoliaClient = useContext(AlgoliaContext);

  const index = useMemo(
    () => algoliaClient?.initIndex("prod_chat"),
    [algoliaClient]
  );

  const boxRef = useRef<HTMLDivElement>(null);

  const [searchResults, setSearchResults] = useState<ChatSchema[]>();
  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      if (page === 0 && boxRef) {
        boxRef?.current?.scrollTo(0, 0);
      }
      const options = {
        page: page,
        hitsPerPage: CHATS_PER_PAGE,
        filters: "chatRoomId:" + chatRoomId
      };
      index?.search(searchQuery || "", options).then(hits => {
        setSearchResults(prev => [
          ...(prev === undefined || page === 0 ? [] : prev),
          ...hits.hits.map((hit: any) => {
            return {
              id: hit.objectID,
              createdAt: hit.createdAt
            } as ChatSchema;
          })
        ]);
      });
    }
    return () => {};
  }, [searchQuery, page, chatRoomId]);

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <div
          style={{
            backgroundColor: isDark ? "#212025" : "white"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              padding: 20,
              paddingTop: 10,
              color: isDark ? "white" : "black"
            }}
          >
            <ChatSearchInput
              setSearchQuery={query => {
                setPage(0);
                setSearchQuery(query);
              }}
            />
          </div>
          <div
            style={{
              width: Math.min(500, windowWidth - 50),
              height: Math.min(windowHeight - 200, 800),
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "auto",
              padding: 20
            }}
            ref={boxRef}
          >
            {searchResults &&
              searchResults.map((chat, i) => {
                const previousChat = searchResults[i - 1];
                const previousDate =
                  previousChat && new Date(previousChat.createdAt);

                return (
                  <div key={chat.id}>
                    {previousChat &&
                      previousDate.toLocaleDateString() !==
                        new Date(chat.createdAt).toLocaleDateString() && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              fontSize: 12,
                              padding: "5px 20px",
                              marginTop: 15,
                              marginBottom: 15,
                              borderRadius: "20px",
                              color: "white",
                              backgroundColor: isDark
                                ? DARK_THEME_BACKGROUND
                                : "#b3b3b3"
                            }}
                          >
                            {`${previousDate.getFullYear()}년 ${
                              previousDate.getMonth() + 1
                            }월 ${previousDate.getDate()}일 ${previousDate.toLocaleDateString(
                              "ko-KR",
                              { weekday: "long" }
                            )}`}
                          </div>
                        </div>
                      )}
                    <div
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        cursor: "pointer"
                      }}
                      key={chat.id}
                      onClick={() => {
                        handleClose();

                        setMainCursor(chat.createdAt);
                      }}
                    >
                      {chat.id && (
                        <HighlightChatlineWrapper
                          chatId={chat.id}
                          onChatBubbleClick={() => {
                            handleClose();
                            setMainCursor(chat.createdAt);
                          }}
                          isDark={isDark}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            <OXOInfiniteScroll setNextPage={() => setPage(prev => prev + 1)} />
          </div>
        </div>
      </Popover>
      <Button
        style={{
          borderRadius: "50%",
          width: 33,
          height: 33,
          minWidth: 33,
          backgroundColor: "#e0e0e0"
        }}
        onClick={handleClick}
      >
        <Search style={{ width: 19, height: 19 }} />
      </Button>
    </div>
  );
}
