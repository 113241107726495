import firebase from "firebase/compat";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GRAY_EXTRA_LIGHT } from "../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { ROUTES } from "../../../constants/routes";
import oxoOx from "../../../img/ox/oxoxo.svg";
import { removeFromIndex } from "../../../lib/search";
import { parseDoc } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOText from "../../utility/OXOText/OXOText";
import { AlgoliaContext } from "../../views/SearchView/AlgoliaContext";
import CardLikeStats from "../CardComponents/CardLikeStats";
import CardLoader from "../CardComponents/CardLoader";
import PhotoViewer from "../CardComponents/PhotoViewer";
import OXOCardDetailsForMinicard from "../DetailViews/OXOCardDetailsForMinicard";
import OXOCardTimeline from "../DetailViews/OXOCardTimeline";

export default function MiniOXOCardDetails({ cardId }: { cardId: string }) {
  const [card, setCard] = useState<CardSchema>();

  const expandContent = true;

  const history = useHistory();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));

    return () => unsubscribe();
  }, [cardId]);

  const cardRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const dataProps = useContext(DataPropsContext);
  const algoliaClient = useContext(AlgoliaContext);

  const trashClicked = () => {
    if (card && window.confirm(`"${card.title}" 생각을 삭제하시겠습니까?`)) {
      if (algoliaClient) {
        removeFromIndex(algoliaClient, card.id);
      }
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(card.id)
        .delete();
      history.push(ROUTES.HOME);
    }
  };

  if (!card || !dataProps?.mapCoordinates) {
    return <CardLoader />;
  }

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "750px"
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          minHeight: "170px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: expandContent ? 0 : "20px",
          position: "relative",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
        key={cardId}
        ref={cardRef}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            paddingBottom: "0px"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 50,
                height: 50,
                borderRadius: "50%",
                padding: 5,
                backgroundColor: GRAY_EXTRA_LIGHT,
                marginRight: 5
              }}
            >
              <img src={oxoOx} style={{ width: "40px" }} alt="oxoOx" />
            </div>
            <div
              style={{
                fontWeight: "bold",
                wordBreak: "keep-all",
                textAlign: "left"
              }}
            >
              {card.title}
            </div>
          </div>
        </div>
        <div style={{ textAlign: "left", padding: "20px", lineHeight: 1.7 }}>
          <OXOText
            text={card.postMetadata?.description || ""}
            truncateChars={
              expandContent
                ? undefined
                : card?.postMetadata?.photos &&
                  card?.postMetadata?.photos.length > 0
                ? 150
                : 300
            }
          />
        </div>
        {card?.postMetadata?.photos && card?.postMetadata?.photos.length > 0 && (
          <div style={{ paddingBottom: "10px" }}>
            <PhotoViewer photoUrls={card?.postMetadata?.photos} />
          </div>
        )}
        {expandContent && <OXOCardDetailsForMinicard card={card} />}
        <div style={{ fontSize: 14, padding: "20px", paddingTop: 0 }}>
          <CardLikeStats card={card} />
        </div>
      </div>
      <OXOCardTimeline />
    </div>
  );
}
