import React, { useEffect, useState } from "react";
import firebase from "firebase/compat";
import {
  CardAnswerStatsSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import {
  toDateOnsetKoreaStandardTime,
  todayInKorea
} from "../../../utils/datetime";
import {
  getDocsFromSnapshot,
  groupBy,
  listToMap,
  mapValues,
  parseDoc
} from "../../../utils/utils";
import { DotLoader } from "react-spinners";
import PortraitCardView from "../PortraitCardView/PortraitCardView";
import { YearInReviewIndex } from "./YearInReviewIndex";
import YearInReviewTitle from "./YearInReviewTitle";
import YearInReviewBack from "./YearInReviewBack";

export default function YearInReviewPublish({ year }: { year: number }) {
  const [cardIdForPagesByMonth, setCardIdForPagesByMonth] =
    useState<{ [month: string]: string[] }>();
  useEffect(() => {
    console.log("running");
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where(
        "featuredOnTodayFocusAt",
        ">=",
        toDateOnsetKoreaStandardTime(year, 1, 1).valueOf()
      )
      .where(
        "featuredOnTodayFocusAt",
        "<",
        toDateOnsetKoreaStandardTime(year + 1, 1, 1).valueOf()
      )
      .get()
      .then(getDocsFromSnapshot)
      .then(async (cards: CardSchema[]) => {
        console.log("cards", cards);
        const filteredCards = cards.filter(card =>
          [CardType.TOPIC, CardType.SURVEY_QUESTION, CardType.POST].includes(
            card.type
          )
        );

        const cardStatsMap: { [cardId: string]: number } = await Promise.all(
          filteredCards.map(card =>
            firebase
              .firestore()
              .collection(FirestoreCollection.CARD_ANSWER_STATS)
              .doc(card.id)
              .get()
              .then(parseDoc)
          )
        ).then((cardStats: CardAnswerStatsSchema[]) => {
          return listToMap(
            cardStats,
            cardStat => cardStat.id || "",
            cardStat => cardStat.count
          );
        });

        const cardByMonth: { [month: string]: CardSchema[] } = groupBy(
          filteredCards,
          card => todayInKorea(card.featuredOnTodayTimelineAt).month.toString()
        );

        const cardByMonthSelected = mapValues(cardByMonth, filteredCards =>
          filteredCards
            .sort((a, b) => cardStatsMap[b.id] - cardStatsMap[a.id])
            .slice(0, 3)
            .map(card => card.id)
        );

        setCardIdForPagesByMonth(cardByMonthSelected);
      });
  }, [year]);

  if (!cardIdForPagesByMonth) {
    return <DotLoader />;
  }

  return (
    <div>
      <YearInReviewTitle />
      <YearInReviewIndex
        cardIdForPagesByMonth={cardIdForPagesByMonth}
        year={year}
      />

      {Object.keys(cardIdForPagesByMonth).flatMap(month =>
        cardIdForPagesByMonth[month].map((cardId, i) => (
          <PortraitCardView
            cardId={cardId}
            key={cardId}
            headerTitle={`${month}월 ${i + 1}위`}
          />
        ))
      )}

      <YearInReviewBack />
    </div>
  );
}
