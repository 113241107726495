import React, { useEffect, useState } from "react";
import CharacterLoader from "../../../utility/OXOLoader/CharacterLoader";
import DetailPagesTalkTab from "../../../cards/DetailViews/DetailPagesTalkTab";
import DetailPagesUsermapTab from "../../../cards/DetailViews/DetailPagesUsermapTab";
import DetailPagesReplyTab from "../../../cards/DetailViews/DetailPagesReplyTab";
import firebase from "firebase/compat";
import HashCardDetailPagesStoryTab from "./HashCardDetailPagesStoryTab";
import HashCardDetailPagesInformationTab from "./HashCardDetailPagesInformationTab";
import PoliciesDetail from "../Policies/PoliciesDetail";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc } from "../../../../utils/utils";
import {
  HashtagConfiguration,
  parseYamlDescription
} from "../common/hashpage_shared";
import StanceTestHashtagComponent from "../StaceTest/StanceTestHashtagComponent";

export default function HashCardDetailPagesRenderer({
  cardId,
  tab
}: {
  cardId: string;
  tab: string;
}) {
  const [yamlData, setYamlData] = useState<HashtagConfiguration>();

  useEffect(() => {
    if (tab === "공약비교" || tab === "테스트") {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(`${tab === "공약비교" ? cardId + "_공약비교" : cardId}`)
        .get()
        .then(parseDoc)
        .then((card: CardSchema) => {
          setYamlData(parseYamlDescription(card?.hashtagMetadata?.description));
        });
    }
  }, [cardId, tab]);

  switch (tab) {
    case "내용":
      return <HashCardDetailPagesInformationTab hashtag={cardId} />;
    case "스토리":
      return <HashCardDetailPagesStoryTab hashtag={cardId} />;
    case "맥락":
      return <HashCardDetailPagesStoryTab hashtag={cardId} />;
    case "댓글":
      return <DetailPagesReplyTab cardId={cardId} />;
    case "톡":
      return <DetailPagesTalkTab cardId={cardId} />;
    case "지지율":
      return <DetailPagesUsermapTab cardId={cardId} />;
    case "응답결과":
      return <DetailPagesUsermapTab cardId={cardId} />;
    case "공약비교":
      return (
        (yamlData && yamlData.공약비교내용 && (
          <PoliciesDetail policiesDetailConfiguration={yamlData.공약비교내용} />
        )) || <></>
      );

    case "테스트":
      return (
        (yamlData && yamlData.성향테스트 && (
          <StanceTestHashtagComponent
            hashtag={cardId}
            stanceTestConfiguration={yamlData.성향테스트}
          />
        )) || <></>
      );

    default:
      return <CharacterLoader />;
  }
}
