import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DEBATE_ABSTRACT, TRUMP_RED } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getDateInfo, parseDoc } from "../../../../utils/utils";
import {
  ElectionResultConfiguration,
  ElectionResultStatus
} from "../common/hashpage_shared";
import HashPagePhoto from "../HashPagePhoto";
import HashPeopleInfo from "../Profile/HashPeopleInfo";

export default function HashElectionResult({
  electionResultConfiguration,
  hashtag
}: {
  electionResultConfiguration: ElectionResultConfiguration;
  hashtag: string;
}) {
  const history = useHistory();
  const [winner, setWinner] = useState<CardSchema>();

  useEffect(() => {
    if (electionResultConfiguration.당선) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(electionResultConfiguration.당선)
        .get()
        .then(parseDoc)
        .then(snapshot => {
          setWinner(snapshot);
        });
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: 375,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: DEBATE_ABSTRACT
      }}
    >
      <div
        style={{
          minHeight: 38,
          width: "100%",
          textAlign: "center",
          fontWeight: "bolder",
          fontSize: 24,
          wordBreak: "keep-all",
          padding: "0px 20px",
          marginTop: -4
        }}
      >
        {hashtag.split("_")[0]}
      </div>

      {/* {electionResultConfiguration.집계 &&
        electionResultConfiguration.개표해시태그ID && (
          <div
            style={{
              width: "100%",
              padding: "5px 30px 7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 7,
              cursor: "pointer",
              fontSize: 18,
              fontWeight: "bold",
              color: APPLE_MORE
            }}
            onClick={e => {
              e.stopPropagation();
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(
                    history.location.search,
                    `${electionResultConfiguration.개표해시태그ID}`
                  )
              );
            }}
          >
            {electionResultConfiguration.집계 ===
            ElectionResultStatus.AFTER_COUNT ? (
              <div style={{ fontWeight: "bold" }}>
                개표 방송 다시 보기
                <span style={{ fontWeight: "normal" }}>❯</span>
              </div>
            ) : (
              <div style={{ fontWeight: "bold" }}>
                개표 방송 함께 보기
                <span style={{ fontWeight: "normal" }}>❯</span>
              </div>
            )}
          </div>
        )} */}

      {electionResultConfiguration.집계 && electionResultConfiguration.개표일 && (
        <div
          style={{
            height: 28,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 15,
            padding: "6px 18px 3px 18px",
            backgroundColor:
              electionResultConfiguration.집계 === ElectionResultStatus.COUNTING
                ? TRUMP_RED
                : "#3E7A99",
            color: "white",
            marginBottom: 20
          }}
        >
          {electionResultConfiguration.집계 ===
          ElectionResultStatus.BEFORE_COUNT
            ? `${getDateInfo(
                electionResultConfiguration.개표일,
                "시간"
              )} 개표방송 시작`
            : electionResultConfiguration.집계 === ElectionResultStatus.COUNTING
            ? "개표 진행중"
            : electionResultConfiguration.당선
            ? `${electionResultConfiguration.당선} 후보가 당선되었어요`
            : ""}
        </div>
      )}

      {electionResultConfiguration.집계 !== ElectionResultStatus.AFTER_COUNT &&
        electionResultConfiguration.참가자 && (
          <HashPeopleInfo
            election={hashtag.split("_")[0]}
            participants={electionResultConfiguration.참가자}
            isDirectionColumn={false}
          />
        )}

      {electionResultConfiguration.집계 === ElectionResultStatus.AFTER_COUNT &&
        winner && (
          <>
            <HashPagePhoto card={winner} size={137} withBadge={true} />
          </>
        )}
    </div>
  );
}
