import React, { ReactNode } from "react";
import { VictoryGroup, VictoryLabel, VictoryScatter } from "victory";
import { GRAY } from "../../../../constants/enums";
import DocumentSkeleton from "../../../utility/OXOLoader/DocumentSkeleton";

export interface SeriesType {
  name: string;
  data: number[][];
  color: string;
  userMapColor?: string;
  label?: string;
  showLegend?: boolean;
  size?: number;
  labelFontSize?: number;
  labelComponent?: ReactNode;
  bubbleSize?: number;
  userId?: string;
  opacity?: number;
  allowOverlap?: boolean;
}

export interface OptionsType {
  showLargeBubbleSize?: boolean;
}

export default function UserMap({
  series,
  options
}: {
  series: SeriesType[] | undefined;
  options?: OptionsType;
}) {
  if (series === undefined) {
    return <DocumentSkeleton />;
  }

  return <MapChart series={series} />;
}

function MapChart({ series }: { series: SeriesType[] }) {
  const legendData: {
    name: string;
    symbol: { fill: string };
  }[] = [];

  const usedCoordinates = new Set();

  const data = series
    .map(seriesItem => {
      if (seriesItem.showLegend) {
        legendData.push({
          name: seriesItem.name,
          symbol: {
            fill: seriesItem.userMapColor
              ? seriesItem.userMapColor
              : seriesItem.color
          }
        });
      }

      const bubbleSize: { [coordinate: string]: number } = {};

      // Count data
      seriesItem.data.forEach((data, i) => {
        const x = data[0];
        const y = data[1];
        if (!bubbleSize[x + "-" + y]) {
          bubbleSize[x + "-" + y] = 0;
        }
        bubbleSize[x + "-" + y] += 1;
      });

      return seriesItem.data
        .filter(data => !isNaN(data[0]) && !isNaN(data[1]))
        .map((data, i) => {
          const x = data[0];
          const y = data[1];
          if (usedCoordinates.has(x + "-" + y) && !seriesItem.allowOverlap) {
            return null;
          }
          usedCoordinates.add(x + "-" + y);
          const size = seriesItem.size || data[2];
          return {
            category: seriesItem.name,
            x: x,
            y: y,
            z: seriesItem.bubbleSize
              ? seriesItem.bubbleSize
              : bubbleSize[x + "-" + y],
            size: size ? size : null,
            color: seriesItem.color,
            label: seriesItem.label,
            labelFontSize: seriesItem.labelFontSize,
            opacity: seriesItem.opacity || 1
          };
        })
        .filter(t => t != null);
    })
    .reduce((x, y) => x.concat(y), []);

  const mapWidth = Math.min(window.screen.width, 750);

  const mapHeight = (mapWidth / 5) * 4;
  return (
    <VictoryGroup width={mapWidth} height={mapHeight}>
      <VictoryScatter
        style={{
          data: {
            fill: ({ datum }) => datum.color,
            fillOpacity: ({ datum }) => datum.opacity,
            strokeWidth: 0
          },
          labels: {
            fontSize: ({ datum }) => datum.labelFontSize || datum.size + 14,
            color: ({ datum }) => datum.color
          }
        }}
        data={data}
        labels={({ datum }) => datum.label}
        labelComponent={<VictoryLabel />}
      />
      <VictoryLabel
        text={"여권"}
        dy={mapHeight / 2}
        dx={20}
        style={{ fontSize: 14, fill: GRAY }}
      />
      <VictoryLabel
        text={"야권"}
        dy={mapHeight / 2}
        dx={mapWidth - 40}
        style={{ fontSize: 14, fill: GRAY }}
      />
      <VictoryLabel
        text={"분배"}
        dy={20}
        dx={mapWidth / 2 - 10}
        style={{ fontSize: 14, fill: GRAY }}
      />
      <VictoryLabel
        text={"성장"}
        dy={mapHeight - 20}
        dx={mapWidth / 2 - 10}
        style={{ fontSize: 14, fill: GRAY }}
      />
    </VictoryGroup>
  );
}
