/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import firebase from "firebase/compat";
import {
  CardAnswerStatsSchema,
  CardGptSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { parseDoc } from "../../../utils/utils";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import OXOText from "../../utility/OXOText/OXOText";
import { BarLoader } from "react-spinners";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import TribeChartV1OrV2 from "../PortraitCardView/TribeChartV1OrV2";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import legends from "../DataExplorerView/images/legends.png";
import OxBarChartContainer from "../DataExplorerView/OxBarChartContainer";
import AgeBarChartContainer from "../DataExplorerView/gender_age_chart/AgeBarChartContainer";

const cssText = css`
  padding: 10px;
`;
export default function CardGptView({ cardId }: { cardId: string }) {
  const [statusText, setStatusText] = useState<string>();

  const [cardGpt, setCardGpt] = useState<CardGptSchema>();

  const [reload, setReload] = useState(false);
  const [printable, setPrintable] = useState<boolean>(false);

  const cardAnswerStats = useFirestoreGetDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_GPTS)
      .doc(cardId)
      .get()
      .then(parseDoc)
      .then((cardGpt: CardGptSchema) => {
        setCardGpt(cardGpt);
      });
  }, [cardId, reload]);

  return (
    <div>
      {!printable && (
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              width: 300px;
              padding: 20px;
            `}
          >
            {reload ? (
              <BarLoader />
            ) : (
              <OXOButton
                onClick={async () => {
                  setStatusText("보고서를 생성합니다..");
                  setReload(true);

                  const generateChatGPTReport = firebase
                    .app()
                    .functions("asia-northeast3")
                    .httpsCallable("gpt-generateChatGPTReport");

                  try {
                    const responseForDesc = await generateChatGPTReport({
                      cardId: cardId
                    });
                    console.log(responseForDesc);
                    setStatusText("보고서 생성 성공!");

                    setReload(false);
                  } catch (e) {
                    console.log(e);

                    setStatusText(`보고서 생성 실패 ${e}`);
                    setReload(false);
                  }
                }}
                label={"Generate Report"}
              />
            )}
          </div>

          <div
            css={css`
              width: 300px;
              padding: 20px;
            `}
          >
            <OXOButton
              label={"인쇄용 View 전환"}
              onClick={() => {
                setPrintable(true);
              }}
            />
          </div>

          {statusText && (
            <div
              css={css`
                padding: 20px;
                background-color: palevioletred;
                color: white;
              `}
            >
              <OXOText text={statusText} />
            </div>
          )}
        </div>
      )}
      <div
        css={css`
          padding: 20px;
        `}
      >
        <div css={cssText}>
          <h1> {cardGpt?.cardTitle}</h1>
        </div>

        {cardGpt?.description && (
          <div css={cssText}>
            <OXOText text={cardGpt.description} />
          </div>
        )}

        {cardGpt?.tribeStats && (
          <div css={cssText}>
            <h2>정치 성향별 의견</h2>

            {cardAnswerStats && (
              <div
                css={css`
                  width: 400px;
                `}
              >
                <h3
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  `}
                >
                  옥소지수
                  <img
                    src={legends}
                    css={css`
                      width: 150px;
                      object-fit: contain;
                    `}
                  />
                </h3>

                <OxBarChartContainer
                  cardId={cardId}
                  width={440}
                  answerCounts={cardAnswerStats.count}
                />
              </div>
            )}

            <OXOText text={cardGpt.tribeStats.trim()} />
          </div>
        )}

        <div
          css={[
            css`
              width: 400px;
              margin-top: -50px;
              margin-bottom: 20px;
            `,
            cssText
          ]}
        >
          <TribeChartV1OrV2 cardId={cardId} />
        </div>

        {cardGpt?.tribeComments && (
          <div css={cssText}>
            {Object.keys(cardGpt.tribeComments).map(tribeId => (
              <div key={tribeId}>
                <OXOText
                  text={
                    TRIBE_INFO_BY_ID[tribeId].shortDescription +
                    " 주요 의견: " +
                    cardGpt.tribeComments![tribeId].trim()
                  }
                />
              </div>
            ))}
          </div>
        )}

        {cardGpt?.ageStats && (
          <div css={cssText}>
            <h2>연령 및 성별 의견</h2>

            <div
              css={css`
                margin-top: -30px;
                margin-bottom: 30px;
              `}
            >
              <AgeBarChartContainer cardId={cardId} />
            </div>
            <OXOText text={cardGpt.ageStats.trim()} />
          </div>
        )}
        {cardGpt?.ageComments && (
          <div css={cssText}>
            {Object.keys(cardGpt.ageComments)
              .filter(
                ageGroup => cardGpt.ageComments![ageGroup].trim().length > 5
              )
              .map(ageGroup => (
                <div key={ageGroup}>
                  <OXOText
                    text={
                      ageGroup +
                      "대 주요 의견: " +
                      cardGpt.ageComments![ageGroup].trim()
                    }
                  />
                </div>
              ))}
          </div>
        )}
        {cardGpt?.genderComments && (
          <div css={cssText}>
            {Object.keys(cardGpt.genderComments).map(gender => (
              <div key={gender}>
                <OXOText
                  text={
                    (gender === "male" ? "남성" : "여성") +
                    " 주요 의견: " +
                    (cardGpt.genderComments![gender]?.trim() || "")
                  }
                />
              </div>
            ))}
          </div>
        )}

        {cardGpt?.regionStats && (
          <div css={cssText}>
            <h2>지역별 의견</h2>

            <OXOText text={cardGpt.regionStats.trim()} />
          </div>
        )}
        {cardGpt?.jurisdictionComments && (
          <div css={cssText}>
            {Object.keys(cardGpt.jurisdictionComments)
              .filter(
                region =>
                  cardGpt.jurisdictionComments![region].trim().length > 5
              )
              .map(region => (
                <div key={region}>
                  <OXOText
                    text={
                      region +
                      " 주요 의견: " +
                      (cardGpt.jurisdictionComments![region]?.trim() || "")
                    }
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
