/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  groupBy,
  listToMap,
  mapValues
} from "../../../../utils/utils";
import MiniQuestionPieChart from "../../../topic/Question/QuestionChart/MiniQuestionPieChart";
import { css } from "@emotion/react";
import { getAgreePercentage } from "../../../../utils/data_utils";

export default function CardAnswerSupporterChart({
  cardId,
  supporters,
  showLongDescription = false
}: {
  cardId: string;
  supporters: {
    [answer: string]: string[];
  };
  showLongDescription?: boolean;
}) {
  const [cardAnswers, setCardAnswers] = useState<CardAnswerSchema[]>();
  useEffect(() => {
    if (cardId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWERS)
        .where("cardId", "==", cardId)
        .get()
        .then(getDocsFromSnapshot)
        .then(setCardAnswers);
    }
  }, [cardId]);

  const [supporterAnswerStats, setSupporterAnswerStats] = useState<{
    [supportingStatus: string]: {
      [answer: string]: number;
    };
  }>();
  useEffect(() => {
    if (!cardAnswers) {
      return;
    }

    const userAnswerMap = listToMap(
      cardAnswers,
      cardAnswer => cardAnswer.userId,
      cardAnswer => cardAnswer.answer
    ) as {
      [key: string]: string;
    };

    setSupporterAnswerStats(
      mapValues(supporters, userIds => {
        const answers = userIds
          .map(userId => userAnswerMap[userId])
          .filter(a => a) as string[];
        return mapValues(
          groupBy(answers, answer => answer),
          answers => answers.length
        );
      })
    );
  }, [cardAnswers]);

  if (!cardAnswers || !supporterAnswerStats) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      {["o", "?", "x"].map(supporterKey => {
        const supporterAnswerStat = supporterAnswerStats[supporterKey] || {
          o: 0,
          x: 0,
          "?": 0,
          count: 0
        };

        return (
          <div
            key={supporterKey}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <MiniQuestionPieChart
              cardChartStats={{
                o: supporterAnswerStat["o"] || 0,
                "?": supporterAnswerStat["?"] || 0,
                x: supporterAnswerStat["x"] || 0,
                count: supporterAnswerStat["count"] || 0
              }}
              size={60}
            />
            {showLongDescription ? (
              <div
                css={css`
                  font-size: 12px;
                `}
              >
                {supporterKey === "o"
                  ? "지지자"
                  : supporterKey === "x"
                  ? "반대자"
                  : "중립"}{" "}
                {Object.values(supporterAnswerStats[supporterKey]).reduce(
                  (a, b) => a + b
                )}
                명 중{" "}
                {getAgreePercentage(
                  supporterAnswerStat as {
                    x: number;
                    count: number;
                    o: number;
                    "?": number;
                  }
                ).toFixed()}
                %가 찬성했어요.
              </div>
            ) : (
              <div
                css={css`
                  text-align: center;
                  font-size: 12px;
                  margin-top: -4px;
                `}
              >
                {supporterKey === "o"
                  ? `지지자 (${
                      (supporterAnswerStats["o"] &&
                        Object.keys(supporterAnswerStats["o"]).length > 0 &&
                        Object.values(supporterAnswerStats["o"])?.reduce(
                          (a, b) => a + b
                        )) ||
                      0
                    })`
                  : supporterKey === "x"
                  ? `반대자 (${
                      (supporterAnswerStats["x"] &&
                        Object.keys(supporterAnswerStats["x"]).length > 0 &&
                        Object.values(supporterAnswerStats["x"])?.reduce(
                          (a, b) => a + b
                        )) ||
                      0
                    })`
                  : `중립 (${
                      (supporterAnswerStats["?"] &&
                        Object.keys(supporterAnswerStats["?"]).length > 0 &&
                        Object.values(supporterAnswerStats["?"])?.reduce(
                          (a, b) => a + b
                        )) ||
                      0
                    })`}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
