import React from "react";
import { DEBATE_ABSTRACT, GRAY_DARKER } from "../../../../constants/enums";
import { CareerConfiguration } from "../common/hashpage_shared";

export default function HashCareer({
  careerConfiguration
}: {
  careerConfiguration: CareerConfiguration;
}) {
  return (
    <div
      style={{
        padding: "36px 30px 24px",
        textAlign: "center",
        backgroundColor: DEBATE_ABSTRACT
      }}
    >
      <div style={{ fontSize: 24, fontWeight: "bold", paddingBottom: 20 }}>
        경력
      </div>
      {careerConfiguration.내용 &&
        careerConfiguration.내용.map((career, i) => {
          return (
            <div
              style={{
                paddingBottom: 10,
                fontSize: 16,
                fontWeight: "bold",
                color: GRAY_DARKER
              }}
              key={i}
            >
              {career}
            </div>
          );
        })}
    </div>
  );
}
