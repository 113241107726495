/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import {
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_COLOR
} from "../../../../constants/data_platform_colors";
import {
  getAgreePercentage,
  getDisagreePercentage
} from "../../../../utils/data_utils";
import { css } from "@emotion/react";

export default function CardAnswerWordForDashboard({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  const cardData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];

  [cardAnswerStats].map(el => {
    return cardData.push({
      x: "전체",
      ao: ((el?.o || 0) / (el?.count || 1)) * 100,
      ad: ((el?.["?"] || 0) / (el?.count || 1)) * 100,
      ax: ((el?.x || 0) / (el?.count || 1)) * 100,
      count: el?.count || 0
    });
  });

  if (!cardAnswerStats || cardAnswerStats.count === 0) {
    return null;
  }

  const agreePercentage = getAgreePercentage(cardAnswerStats).toFixed();
  const disagreePercentage = getDisagreePercentage(cardAnswerStats).toFixed();

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      {agreePercentage > disagreePercentage ? (
        <div
          css={css`
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            color: ${DATA_PLATFORM_GREEN_COLOR};
            margin-top: 15px;
          `}
        >
          찬성 {getAgreePercentage(cardAnswerStats).toFixed()}%
        </div>
      ) : (
        <div
          css={css`
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            color: ${DATA_PLATFORM_RED_COLOR};
            margin-top: 15px;
          `}
        >
          반대 {getDisagreePercentage(cardAnswerStats).toFixed()}%
        </div>
      )}
    </div>
  );
}
