/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";

import {
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";

import { getAgeStatsByAgeGroup } from "../../../../utils/ageGender";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

import AgeBarChart from "./AgeBarChart";

export default function AgeBarChartContainer({ cardId }: { cardId: string }) {
  const ageGenderStatsData =
    useFirestoreGetDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  if (!ageGenderStatsData) {
    return null;
  }

  const ageStatsByAgeGroup = getAgeStatsByAgeGroup(ageGenderStatsData);

  return (
    <div
      css={css`
        height: 360px;
        margin-top: 57px;
      `}
    >
      {Object.keys(ageStatsByAgeGroup).map(age => {
        return (
          <AgeBarChart key={age} age={age} stats={ageStatsByAgeGroup[age]} />
        );
      })}
    </div>
  );
}
