import React from "react";
import SingleLineComment from "./SingleLineComment";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { addToCardIdStackUrl, removeIdKey } from "../../../../utils/utils";
import { useHistory } from "react-router";
import {
  BestCommentsForCardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";

export default function MiniBestComments({
  cardId,
  maxWidth = 335,
  margin = 78,
  disableExpressions
}: {
  cardId: string;
  maxWidth?: number;
  margin?: number;
  disableExpressions?: boolean;
}) {
  const [windowWidth, windowHeight] = useWindowSize();
  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );
  const history = useHistory();
  removeIdKey(bestComments);

  console.log("bestComments", bestComments);
  return (
    <div style={{ width: "100%", textAlign: "left" }}>
      {bestComments &&
        Object.keys(bestComments).map(
          tribeId =>
            bestComments && (
              <div
                key={cardId + "bc" + tribeId}
                style={{ cursor: "pointer", marginBottom: "5px" }}
                onClick={e => {
                  e.stopPropagation();
                  history.push(
                    history.location.pathname +
                      addToCardIdStackUrl(
                        history.location.search,
                        cardId + "~tab:comments~tribeId:" + tribeId
                      )
                  );
                }}
              >
                <SingleLineComment
                  commentId={bestComments[tribeId]}
                  key={tribeId}
                  disableExpressions={disableExpressions}
                />
              </div>
            )
        )}
    </div>
  );
}
