import React from "react";

export default function AnswerDunno({
  color,
  width
}: {
  color: string;
  width: number;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0L0 60H60L30 0ZM30 16.5517L12.4138 51.7241H47.5862L30 16.5517Z"
        fill={color}
      />
    </svg>
  );
}
