import React, { ErrorInfo } from "react";
import { GRAY_LIGHT } from "../../../constants/enums";
import { FirestoreCollection } from "../../../constants/firestore_schema";
import browser from "./images/browser.png";
import feedback from "./images/feedback.svg";
import oxohome from "./images/oxohome.svg";
import oxo_error from "./images/oxo_error.png";
import refresh from "./images/refresh.svg";
import firebase from "firebase/compat";

export class ErrorBoundary extends React.Component {
  constructor(props: { props: any }) {
    super(props);
    this.state = { hasError: false };
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error
    });
    const errorObject = {
      name: error?.name || "",
      error: error?.message || "",
      stack: error?.stack || "",
      component: errorInfo?.componentStack || "",
      userId: firebase.auth().currentUser?.uid || "unknown",
      createdAt: new Date().valueOf()
    };
    firebase
      .firestore()
      .collection(FirestoreCollection.ERROR_LOG)
      .add(errorObject);
    firebase.analytics().logEvent("error", errorObject);
    window.scrollTo(0, 0);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      let error = "";
      // @ts-ignore
      if (this.state.error) {
        // @ts-ignore
        error = this.state.error.message;

        if (error.includes("chunk")) {
          firebase.analytics().logEvent("error_reloaded", {
            // @ts-ignore
            name: this.state.error.name
          });

          window.location.reload();
          return null;
        }
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 20,
            backgroundColor: "white"
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: 30,
              fontWeight: "bold",
              marginTop: 30,
              padding: "20px 0"
            }}
          >
            옥소의 집이 <br />
            잠깐 고장났어요...ㅠㅠ
          </div>
          <div style={{ margin: 30 }}>
            <img
              src={oxo_error}
              style={{ width: "100%", maxWidth: 290 }}
              alt="oxo_error"
            />
          </div>
          <div
            style={{
              padding: "30px 0",
              borderStyle: "solid hidden solid",
              borderWidth: 1,
              borderColor: GRAY_LIGHT
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "initial",
                alignItems: "center"
              }}
            >
              <div style={{ height: 30 }}>
                <img
                  src={refresh}
                  style={{ padding: "0 15px" }}
                  alt="refresh"
                />
              </div>
              <div style={{ fontSize: 20, fontWeight: 300 }}>
                <a
                  href={"#"}
                  onClick={() => {
                    window.location.reload();
                  }}
                  style={{ fontWeight: "bold" }}
                >
                  새로고침
                </a>
                을 시도해 보셨나요?
              </div>
            </div>
            <div
              style={{
                height: 30,
                borderLeftStyle: "solid",
                borderLeftColor: "#2f80ed",
                borderLeftWidth: 2,
                marginLeft: 29
              }}
            ></div>
            <div
              style={{
                display: "flex",
                justifyContent: "initial",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  height: 30
                }}
              >
                <img
                  src={feedback}
                  style={{ padding: "0 15px" }}
                  alt="feedback"
                />
              </div>
              <div style={{ fontSize: 20, fontWeight: 300 }}>
                <a
                  href={"https://forms.gle/p76t48HCdC199Uhx5"}
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  버그신고 피드백
                </a>
                을 부탁드려요.
              </div>
            </div>
            <div
              style={{
                height: 30,
                borderLeftStyle: "solid",
                borderLeftColor: "#2f80ed",
                borderLeftWidth: 2,
                marginLeft: 29
              }}
            ></div>
            <div
              style={{
                display: "flex",
                justifyContent: "initial",
                alignItems: "center"
              }}
            >
              <div style={{ height: 30 }}>
                <img
                  src={oxohome}
                  style={{ padding: "0 15px" }}
                  alt="oxohome"
                />
              </div>
              <div style={{ fontSize: 20, fontWeight: 300 }}>
                <a
                  href={"https://oxopolitics.com"}
                  style={{ fontWeight: "bold" }}
                >
                  옥소 홈
                </a>
                으로 가기
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "20px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 300
            }}
          >
            <div style={{ padding: "5px 0" }}>
              아쉽게도 익스플로러에서는 옥소를 만날 수 없어요.
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>크롬, 사파리, 엣지</span>는
              바로 가능해요!
            </div>
          </div>
          <div>
            <img src={browser} height={32} alt="browser" />
          </div>
          <div style={{ padding: 20, color: "white", whiteSpace: "pre-line" }}>
            {error}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
