/** @jsxImportSource @emotion/react */

import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  addToUrlQuery,
  getDocsFromSnapshot,
  groupBy,
  onlyUnique,
  parseUrlQuery
} from "../../../utils/utils";
import { css } from "@emotion/react";
import { CommunityToday } from "./CommunityToday";
import { DailyPartyCard } from "./DailyPartyCard";
import { AgeGenderIssues } from "./AgeGenderIssues";
import OXOPill from "../../utility/OXOPill/OXOPill";
import colors from "../../../constants/colors";
import { useHistory } from "react-router";
import { DAYS } from "../../../utils/datetime";
import { PopularIssues } from "./PopularIssues";
import { PoliticianReports } from "./PoliticianReports";

const TITLE = css`
  font-size: 20px;
  font-weight: bold;
`;

const SECTION = css`
  margin-top: 10px;
  margin-down: 10px;
`;

const DATE_MENUS = ["오늘", "최근 7일", "최근 30일"];
const TYPE_MENUS = ["인물", "이슈", "정당"];
const TARGET_MENUS = ["관심", "주요", "연령대별", "커뮤니티"];

const BASE_PILL_STYLE: CSSProperties = {
  padding: "5px 10px",
  fontWeight: "bold",
  border: 0
};

export function MenuOxoPill({
  isSelected,
  onSelect,
  localeText
}: {
  isSelected: boolean;
  onSelect: () => void;
  localeText: string;
}) {
  return (
    <OXOPill
      style={
        isSelected
          ? {
              ...BASE_PILL_STYLE,
              backgroundColor: colors.pink
            }
          : BASE_PILL_STYLE
      }
      onClick={onSelect}
      text={localeText}
    />
  );
}

export default function DailyReport({
  dayOnset,
  dayEnd
}: {
  dayOnset: number;
  dayEnd?: number;
}) {
  const [cards, setCards] = useState<CardSchema[]>();

  const [refresh, setRefresh] = useState<boolean>(false);
  const history = useHistory();

  const [currentDateMenu, currentTypeMenu, currentTargetMenu] = useMemo(() => {
    const parsedSearchQuery = parseUrlQuery(history.location.search);
    return [
      parsedSearchQuery["dateMenu"] || DATE_MENUS[0],
      parsedSearchQuery["typeMenu"] || TYPE_MENUS[0],
      parsedSearchQuery["targetMenu"] || TARGET_MENUS[0]
    ];
  }, [history.location.search, refresh]);

  useEffect(() => {
    setCards(undefined);
    setCardsByHashtags(undefined);
    setHashtags(undefined);

    const query = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [CardType.NEWS_TICKER])
      .where(
        "createdAt",
        ">=",
        dayOnset -
          (currentDateMenu === "최근 7일"
            ? 6
            : currentDateMenu === "최근 30일"
            ? 29
            : 0) *
            DAYS
      );

    const queryWithEnd = dayEnd ? query.where("createdAt", "<", dayEnd) : query;

    const unsubscriber = queryWithEnd.onSnapshot(snapshot => {
      setCards(getDocsFromSnapshot(snapshot));
    });
    return () => {
      unsubscriber();
    };
  }, [dayOnset, dayEnd, currentDateMenu]);

  const [cardsByHashtags, setCardsByHashtags] =
    useState<{ [hashtag: string]: CardSchema[] }>();
  useEffect(() => {
    if (!cards) {
      return;
    }

    setCardsByHashtags(
      groupBy(cards, card =>
        (card.hashtags?.[0] || "해시태그 없음").replace("#", "")
      )
    );

    return () => {};
  }, [cards]);

  const [hashtags, setHashtags] = useState<string[]>();
  useEffect(() => {
    if (!cardsByHashtags) {
      return;
    }
    setHashtags(Object.keys(cardsByHashtags));
  }, [cardsByHashtags]);

  if (!hashtags || !cardsByHashtags || !cards) {
    return null;
  }

  return (
    <div
      css={css`
        background-color: white;
        width: 100%;
        text-align: left;
        padding: 10px;
      `}
    >
      <div
        css={css`
          display: flex;
          margin-bottom: 10px;
        `}
      >
        {DATE_MENUS.map(dateMenu => {
          return (
            <MenuOxoPill
              key={dateMenu}
              isSelected={currentDateMenu === dateMenu}
              onSelect={() => {
                history.push(
                  history.location.pathname +
                    addToUrlQuery(history.location.search, "dateMenu", dateMenu)
                );
                setRefresh(p => !p);
              }}
              localeText={dateMenu}
            />
          );
        })}
      </div>

      <div
        css={css`
          display: flex;
          margin-bottom: 10px;
        `}
      >
        {TARGET_MENUS.map(targetMenu => {
          return (
            <MenuOxoPill
              key={targetMenu}
              isSelected={currentTargetMenu === targetMenu}
              onSelect={() => {
                history.push(
                  history.location.pathname +
                    addToUrlQuery(
                      history.location.search,
                      "targetMenu",
                      targetMenu
                    )
                );
                setRefresh(p => !p);
              }}
              localeText={targetMenu}
            />
          );
        })}
      </div>

      <div
        css={css`
          display: flex;
          margin-bottom: 10px;
        `}
      >
        {TYPE_MENUS.map(typeMenu => {
          return (
            <MenuOxoPill
              key={typeMenu}
              isSelected={currentTypeMenu === typeMenu}
              onSelect={() => {
                history.push(
                  history.location.pathname +
                    addToUrlQuery(history.location.search, "typeMenu", typeMenu)
                );
                setRefresh(p => !p);
              }}
              localeText={typeMenu}
            />
          );
        })}
      </div>

      {currentTargetMenu === "관심" && currentTypeMenu === "인물" && (
        <div>
          <div css={TITLE}>{currentDateMenu}의 관심 정치인들</div>
          <div css={css``}>
            <PoliticianReports
              hashtags={["송영길", "이재명", "박영선"]}
              additionalSupporter={"송영길"}
              sortByPopularityDesc={false}
              addAddButton={true}
            />
          </div>
        </div>
      )}

      {currentTargetMenu === "주요" && currentTypeMenu === "인물" && (
        <div>
          <div css={TITLE}>{currentDateMenu}의 관심 정치인들</div>
          <PoliticianReports
            hashtags={cards
              .flatMap(
                card =>
                  card.speakerHashTag
                    ?.filter(t => t.length > 0)
                    .map(t => {
                      return t.replace("#", "");
                    }) || []
              )
              .filter(onlyUnique)}
            additionalSupporter={"송영길"}
          />
        </div>
      )}

      {currentTargetMenu === "주요" && currentTypeMenu === "이슈" && (
        <div>
          <div css={TITLE}>{currentDateMenu}의 주요 이슈</div>
          <PopularIssues cardsByHashtags={cardsByHashtags} />
        </div>
      )}

      {currentTargetMenu === "관심" && currentTypeMenu === "정당" && (
        <div>
          <div css={TITLE}>오늘의 정당</div>
          <div>
            <DailyPartyCard hashtag={"더불어민주당"} cards={cards} />
          </div>
          <div>
            <DailyPartyCard hashtag={"국민의힘"} cards={cards} />
          </div>
          <div>
            <DailyPartyCard hashtag={"국민의당"} cards={cards} />
          </div>
        </div>
      )}

      {currentTargetMenu === "주요" && currentTypeMenu === "정당" && (
        <div>
          <div css={TITLE}>오늘의 정당</div>
          <div>
            <DailyPartyCard hashtag={"더불어민주당"} cards={cards} />
          </div>
          <div>
            <DailyPartyCard hashtag={"국민의힘"} cards={cards} />
          </div>
        </div>
      )}

      {currentTargetMenu === "커뮤니티" && (
        <div css={SECTION}>
          <div css={TITLE}>오늘의 생각들</div>
          <div>
            <CommunityToday
              dayOnset={
                dayOnset -
                (currentDateMenu === "최근 7일"
                  ? 6
                  : currentDateMenu === "최근 30일"
                  ? 29
                  : 0) *
                  DAYS
              }
              dayEnd={dayEnd}
            />
          </div>
        </div>
      )}

      {currentTargetMenu === "연령대별" && (
        <div css={SECTION}>
          <div css={TITLE}>세대간 관심 이슈</div>
          <AgeGenderIssues cards={cards} />
        </div>
      )}
    </div>
  );
}
