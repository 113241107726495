import React, { useContext } from "react";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import UserProfileCommentsTimeline from "../OXOUserProfile/UserProfileCommentsTimeline";

export default function MyReplyForProfile() {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  return (
    <div style={{ width: "100%" }}>
      {currentUserId && (
        <UserProfileCommentsTimeline profileUserId={currentUserId} />
      )}
    </div>
  );
}
