import firebase from "firebase/compat";
import {
  CardViewsSchema,
  FirestoreCollection
} from "../constants/firestore_schema";

export function addCardViewCount(
  cardId: string,
  currentUserId: string | undefined
) {
  firebase
    .firestore()
    .collection(FirestoreCollection.CARD_VIEW_COUNTERS)
    .doc(cardId)
    .update({ count: firebase.firestore.FieldValue.increment(1) })
    .catch(e => {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_VIEW_COUNTERS)
        .doc(cardId)
        .set({ count: 1 })
        .catch(e => {
          console.log(e);
        });
    });

  if (currentUserId) {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_VIEWS)
      .doc(currentUserId + cardId)
      .set({
        cardId: cardId,
        userId: currentUserId,
        createdAt: new Date().valueOf()
      } as CardViewsSchema)
      .catch(e => {
        console.log(e);
      });
  }
}
