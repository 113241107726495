/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import {
  addToCardIdStackUrl,
  parseDoc,
  removeIdKey
} from "../../../../utils/utils";
import { useHistory } from "react-router";
import {
  BestCommentsForCardSchema,
  CommentSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { css } from "@emotion/react";
import { GRAY_DARK, TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import OXOText from "../../../utility/OXOText/OXOText";
import UserHead from "../../../cards/MiniCards/UserHead";
import UserName from "../../../profile/OXOUserProfile/UserName";

export default function SingleRepresentativeComment({
  cardId,
  bubbleMaxWidth
}: {
  cardId: string;
  bubbleMaxWidth: number;
}) {
  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );
  const history = useHistory();
  removeIdKey(bestComments);

  const [comments, setComments] = useState<CommentSchema[]>();
  useEffect(() => {
    if (!bestComments) {
      return;
    }
    const promises = Object.values(bestComments).map(commentId =>
      firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .doc(commentId)
        .get()
        .then(parseDoc)
    );

    Promise.all(promises).then((comments: CommentSchema[]) => {
      setComments(comments);
    });
  }, [bestComments]);

  if (!bestComments || !comments) {
    return null;
  }

  comments.sort(
    (a, b) =>
      (b.likeUserAndTribes ? Object.keys(b.likeUserAndTribes).length : 0) -
      (a.likeUserAndTribes ? Object.keys(a.likeUserAndTribes).length : 0)
  );

  const comment = comments[0];
  if (!comment) {
    return null;
  }

  const authorColor =
    comment.userTribeId && TRIBE_INFO_BY_ID[comment.userTribeId]
      ? TRIBE_INFO_BY_ID[comment.userTribeId].heavyColor
      : GRAY_DARK;
  const createdByTribeColorBubbleStyle = {
    color: "white",
    backgroundColor: authorColor
  };

  return (
    <div
      css={css`
        display: flex;
        margin-top: 10px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 90px;
          padding-right: 10px;
          text-align: center;
        `}
      >
        <UserHead userId={comment.userId} />
        <UserName profileUserId={comment.userId} style={{ fontSize: 14 }} />
      </div>

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: bubbleMaxWidth
        }}
      >
        <div>
          <div
            css={{
              borderRadius: 14,
              padding: "12px 17px",
              position: "relative",
              cursor: "pointer",
              ...createdByTribeColorBubbleStyle
            }}
            onClick={e => {
              e.stopPropagation();
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(history.location.search, cardId)
              );
            }}
          >
            <div
              css={{
                minHeight: 20,
                fontSize: 15,
                fontWeight: "normal",
                lineHeight: 1.5
              }}
            >
              <OXOText text={comment.comment} useDivForParagraph />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
