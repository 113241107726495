import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter,
  parseDoc
} from "../../../utils/utils";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { GRAY_CHAT_PREVIEW } from "../../../constants/enums";
import OXOText from "../../utility/OXOText/OXOText";
import OxButtonsForCardWithDescription from "../../foundation/OXOButton/OxButtonsForCardWithDescription";
import CardAdminTools from "../../topic/CardAdminTools";
import { useHistory } from "react-router-dom";

export default function DetailPagesTopicTab({ cardId }: { cardId: string }) {
  const [card, setCard] = useState<CardSchema>();
  const history = useHistory();
  useEffect(() => {
    if (cardId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(cardId)
        .get()
        .then(snapshot => {
          setCard(parseDoc(snapshot));
        });
    }
  }, [cardId]);
  const [windowWidth] = useWindowSize();

  let topicCardDetailScript, btnDetails;

  if (!card) {
    return null;
  }

  if (card.topicMetadataV2?.description) {
    [topicCardDetailScript, ...btnDetails] =
      card.topicMetadataV2.description.split("```agree");
  }

  const topicDescription =
    topicCardDetailScript && topicCardDetailScript.split("```")[0];

  const topicDescriptionDetail =
    topicCardDetailScript && topicCardDetailScript.split("-----")[1];

  return (
    <div>
      <div
        className="topicCardDetail_photo "
        style={{
          minHeight: card?.topicMetadataV2?.photos ? 213 : 0,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        {card?.topicMetadataV2?.photos?.[0] && (
          <div
            style={{
              width: "100%",
              height: windowWidth > 500 ? 426 : 213,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${card?.topicMetadataV2?.photos[0]})`
            }}
          />
        )}
      </div>

      <div
        className="topicCardDetail_title"
        style={{
          backgroundColor: "white"
        }}
      >
        <div
          style={{
            maxWidth: "375px",
            margin: "auto",
            padding: "25px 30px 10px 30px"
          }}
        >
          <div
            style={{
              lineHeight: "170%",
              fontSize: 18,
              fontWeight: "normal",
              color: "black",
              textAlign: "center",
              wordBreak: "keep-all"
            }}
          >
            {card.title && <OXOText text={hashtagTextAdapter(card.title)} />}
          </div>
        </div>

        {card.hashtags && (
          <div
            style={{
              width: "100%",
              padding: "0px 30px",
              textAlign: "center"
            }}
          >
            <div
              style={{
                fontSize: 12,
                color: GRAY_CHAT_PREVIEW
              }}
            >
              {card.hashtags.map((hashtag, i) => (
                <div
                  style={{
                    marginRight: 5,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    history.push(
                      history.location.pathname +
                        addToCardIdStackUrl(
                          history.location.search,
                          hashtag.replace("#", "")
                        )
                    );
                  }}
                  key={hashtag + i}
                >
                  {hashtag.replace(/_/gi, " ")}
                </div>
              ))}
            </div>
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 20
          }}
        >
          <div
            style={{
              width: windowWidth >= 750 ? "100%" : 345,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              lineHeight: 1.7,
              fontSize: 16
            }}
          >
            {topicDescription && topicDescription.length > 0 && (
              <OXOText text={topicDescription} />
            )}
            {topicDescriptionDetail && topicDescriptionDetail.length > 0 && (
              <OXOText text={topicDescriptionDetail} />
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div style={{ width: 345 }}>
            <OxButtonsForCardWithDescription cardId={cardId} />
          </div>
        </div>
      </div>
      <CardAdminTools cardId={cardId} />
    </div>
  );
}
