import {
  FirestoreCollection,
  UserLastSeenItemSchema,
  UserProfileMetadataSchema
} from "../constants/firestore_schema";
import firebase from "firebase/compat";

export function migrateLastSeenChats(
  currentUserId: string,
  currentUserMetadata: UserProfileMetadataSchema
) {
  const deprecatedLastSeenChats = currentUserMetadata.lastSeenChats;
  if (!deprecatedLastSeenChats) {
    return;
  }

  let writeBatch = firebase.firestore().batch();

  Object.keys(deprecatedLastSeenChats).forEach((cardId, i) => {
    if (i % 400 === 0) {
      writeBatch.commit().then(() => console.log("Migration batch committed."));
      writeBatch = firebase.firestore().batch();
    }
    writeBatch.set(
      firebase
        .firestore()
        .collection(FirestoreCollection.USER_LAST_SEEN_ITEMS)
        .doc(currentUserId + cardId),
      {
        userId: currentUserId,
        chatroomId: cardId,
        lastSeenAt: deprecatedLastSeenChats[cardId]
      } as UserLastSeenItemSchema
    );
  });
  writeBatch.commit().then(() => console.log("Migration completed."));

  delete currentUserMetadata.lastSeenChats;

  firebase
    .firestore()
    .collection(FirestoreCollection.USER_PROFILE_METADATA)
    .doc(currentUserId)
    .set(currentUserMetadata)
    .then(() => {
      console.log("currentUserMetadata.lastSeenChats deleted.");
    });
}
