import React, { useContext, useEffect, useState } from "react";
import {
  CardAnswerSchema,
  CardAnswerStatsSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import MiniOxButtonsForCard from "../../../foundation/OXOButton/MiniOxButtonsForCard";
import firebase from "firebase/compat";
import { parseDoc } from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import { addPostAnswerNotification } from "../../../../lib/notification_internal_lib";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import CardAnswerPieChartForCardId from "./CardAnswerPieChartForCardId";

export default function CommentToolsLineForCommunity({
  cardId,
  card
}: {
  cardId: string;
  card: CardSchema;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [questionAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );
  const [cardAnswer, setCardAnswer] = useState<CardAnswerSchema>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .doc(currentUserId + cardId)
      .onSnapshot(snapshot => setCardAnswer(parseDoc(snapshot)));

    return () => {
      unsubscriber();
    };
  }, [currentUserId, cardId]);

  return (
    <div
      style={{
        margin: "10px 0 0px",
        padding: "0 20px",
        width: "100%",
        height: 35,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: 35
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: 35,
            fontSize: "20px",
            fontWeight: "bold",
            color: "#363636"
          }}
        >
          {questionAnswerStats?.count}
        </div>
        {!cardAnswer?.answer && card.createdBy !== currentUserId ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 35,
              marginLeft: 10
            }}
          >
            <CardAnswerPieChartForCardId cardId={cardId} size={23} />
          </div>
        )}
      </div>
      <div
        style={{
          height: 35,
          display: "flex",
          alignItems: "center",
          marginLeft: -2
        }}
      >
        <MiniOxButtonsForCard
          cardId={cardId}
          additionalActionWhenClicked={() => {
            if (card.type === CardType.POST) {
              addPostAnswerNotification(card, card.answers + 1);
            }
          }}
        />
      </div>
    </div>
  );
}
