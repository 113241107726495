/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import HashCommentAvatar from "../HashCardView/Comment/HashCommentAvatar";
import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc } from "../../../utils/utils";
import {
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_COLOR
} from "../../../constants/data_platform_colors";

export function HashtagMiniFace({
  hashtag,
  speakerCards,
  isAgree = true
}: {
  hashtag: string;
  speakerCards: CardSchema[];
  isAgree?: boolean;
}) {
  const [cardStats, setCardStats] = useState<CardAnswerStatsSchema>();
  const [position, setPosition] = useState<string>();
  useEffect(() => {
    if (!speakerCards) {
      return;
    }
    const promises = speakerCards.map(speakerCard =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(speakerCard.id)
        .get()
        .then(parseDoc)
    );

    Promise.all(promises).then((cardAnswerStats: CardAnswerStatsSchema[]) => {
      setCardStats(
        cardAnswerStats.reduce((a, b) => {
          return {
            o: a.o + b.o,
            "?": a["?"] + b["?"],
            x: a.x + b.x,
            count: a.count + b.count
          };
        })
      );
    });

    return () => {};
  }, [speakerCards]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(snapshot => {
        const data: CardSchema = parseDoc(snapshot);
        data.abbreviation
          ? setPosition(data.abbreviation)
          : setPosition(hashtag);
        data.hashtags && data.hashtags.length
          ? setPosition(data.hashtags?.[0])
          : setPosition("");
      });
  }, []);

  const filteredPosition = position?.replace("#", "").replace(/_/gi, " ");

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <HashCommentAvatar speaker={hashtag} size={50} />
      {cardStats &&
        (isAgree ? (
          <div
            css={css`
              font-size: 16px;
              font-weight: 400;
              color: ${DATA_PLATFORM_GREEN_COLOR};
              margin-top: 8px;
              text-align: center;
            `}
          >
            발언 지지율{" "}
            {(((cardStats?.o || 0) / (cardStats?.count || 1)) * 100).toFixed()}%
          </div>
        ) : (
          <div
            css={css`
              font-size: 16px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_COLOR};
              margin-top: 8px;
              text-align: center;
            `}
          >
            발언 지지율{" "}
            {(((cardStats?.o || 0) / (cardStats?.count || 1)) * 100).toFixed()}%
          </div>
        ))}
      <div
        key={hashtag}
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
          margin-top: -6px;
        `}
      >
        {filteredPosition &&
        filteredPosition.length < 8 &&
        hashtag.length < 5 ? (
          <div
            css={css`
              font-size: 16px;
              font-weight: 700;
              color: #121212;
              margin-top: 10px;
              display: flex;
              justify-content: center;
            `}
          >
            <div
              css={css`
                white-space: nowrap;
              `}
            >
              {hashtag.replace(/_/g, " ")}
            </div>
            <div css={css``}>
              <div>
                <div
                  css={css`
                    display: flex;
                    position: relative;
                  `}
                >
                  <div
                    css={css`
                      margin: 0 2px 0 2px;
                    `}
                  >
                    |
                  </div>
                  {filteredPosition}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            css={css`
              font-size: 15px;
              margin-top: 10px;
              font-weight: 700;
              color: #121212;
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <div
              css={css`
                white-space: nowrap;
              `}
            >
              {hashtag.replace(/_/g, " ")}
            </div>
            <div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  word-break: keep-all;
                  width: 100%;
                  font-size: 15px;
                `}
              >
                {filteredPosition}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
