import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TribeExpression } from "../../../constants/expressions";
import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import UserProfilePhoto from "../../views/StatsView/UserProfilePhoto";
import firebase from "firebase/compat";

export default function UserHead({
  userId,
  expression,
  style,
  headSize = 34
}: {
  userId: string;
  expression?: TribeExpression;
  style?: CSSProperties;
  headSize?: number;
}) {
  const history = useHistory();
  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.USER_PROFILE_METADATA)
      .doc(userId)
      .get()
      .then(parseDoc)
      .then(setUserProfileMetadata);
  }, [userId]);

  if (!userProfileMetadata) {
    return (
      <div
        style={{
          width: 44,
          height: 44,
          backgroundColor: "white",
          borderRadius: "50%"
        }}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <div
        style={{
          width: 44,
          height: 44,
          borderRadius: "50%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ...style
        }}
        onClick={() => {
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(history.location.search, userId)
          );
        }}
      >
        {userProfileMetadata.tribeId && (
          <UserProfilePhoto
            tribeId={userProfileMetadata.tribeId}
            photoUrl={userProfileMetadata.photo}
            width={headSize + "px"}
            expression={expression}
          />
        )}
      </div>
    </div>
  );
}
