import React, { useContext, useEffect } from "react";

import { addCardViewCount } from "../../../lib/card_lib";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";

import VerticalCommentsForCardId from "../WeeklyReview/VerticalCommentsForCardId";

export default function DetailPagesReplyTab({
  cardId,
  defaultTribeId
}: {
  cardId: string;
  defaultTribeId?: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  useEffect(() => {
    addCardViewCount(cardId, currentUserId);
  }, []);

  return (
    <div
      style={{
        paddingTop: 30
      }}
    >
      <VerticalCommentsForCardId
        cardId={cardId}
        defaultTribeId={defaultTribeId}
      />
    </div>
  );
}
