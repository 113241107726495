import { Dialog } from "@material-ui/core";
import firebase from "firebase/compat";

import React, { useContext, useState } from "react";
import { GRAY_PROFILE, TRIBE_INFO_BY_ID } from "../../../constants/enums";
import {
  FirestoreCollection,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOPill from "../../utility/OXOPill/OXOPill";
import { getUserDescription, getUserName } from "./UserName";
import UserProfileEdit from "./UserProfileEdit";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import SupporterPercentage from "../../views/HashCardView/Comment/SupporterPercentage";
import MiniOxButtonsForHashCard from "../../foundation/OXOButton/MiniOxButtonsForHashCard";
import { getTribeStandUpImage } from "../../../utils/utils";
import SwipeableViews from "react-swipeable-views";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import UserProfileRenderer from "./ProfilePagesRenderer";
import DetailPagesNavigation from "../../cards/DetailViews/DetailPagesNavigation";

export default function OXOUserProfile({
  profileUserId,
  onClose
}: {
  profileUserId: string;
  onClose?: () => void;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const MENU: string[] = ["정치성향", "댓글", "생각"];

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [windowWidth, windowHeight] = useWindowSize();

  const [showProfileEdit, setShowProfileEdit] = React.useState(false);

  const [questionAnswers] = useFirestoreLiveDocument<QuestionAnswerMapSchema>(
    FirestoreCollection.QUESTION_ANSWER_MAP,
    profileUserId
  );
  const [currentUserMetadata] =
    useFirestoreLiveDocument<UserProfileMetadataSchema>(
      FirestoreCollection.USER_PROFILE_METADATA,
      currentUserId
    );
  const [userProfileMetadataForProfile] =
    useFirestoreLiveDocument<UserProfileMetadataSchema>(
      FirestoreCollection.USER_PROFILE_METADATA,
      profileUserId
    );

  const userName =
    userProfileMetadataForProfile &&
    getUserName(userProfileMetadataForProfile, profileUserId);

  if (
    !profileUserId ||
    !userProfileMetadataForProfile ||
    (currentUserId && !currentUserMetadata) ||
    !dataProps?.mapCoordinates ||
    !questionAnswers
  ) {
    return null;
  }

  const profileTribeInfo =
    userProfileMetadataForProfile.tribeId &&
    TRIBE_INFO_BY_ID[userProfileMetadataForProfile.tribeId];

  return (
    <div
      style={{
        textAlign: "left",
        position: "relative"
      }}
    >
      <Dialog
        open={showProfileEdit}
        onClose={() => setShowProfileEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"cardPopup"}
        PaperProps={{ style: { borderRadius: "20px", width: "330px" } }}
        style={{
          width: "100%",
          margin: "0 auto"
        }}
      >
        <UserProfileEdit />
      </Dialog>

      <div className="center-container">
        <div
          style={{
            backgroundColor: "white"
          }}
        >
          <div>
            <div
              style={{
                backgroundColor: "white",
                padding: "0 15px",
                minWidth: "360px"
              }}
            >
              {profileTribeInfo && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px"
                  }}
                >
                  {userProfileMetadataForProfile.tribeId && (
                    <div style={{ marginBottom: 7 }}>
                      <img
                        style={{ maxWidth: 62, maxHeight: 60 }}
                        src={getTribeStandUpImage(
                          TRIBE_INFO_BY_ID[
                            userProfileMetadataForProfile.tribeId
                          ].image
                        )}
                        alt="profileIcon"
                      />
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: "auto",
                        justifyContent: "flex-start",
                        alignItems: "flex-end"
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          paddingBottom: 10,
                          fontSize: 15
                        }}
                      >
                        {userName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: 10,
                          marginLeft: 5
                        }}
                      >
                        <SupporterPercentage
                          cardId={profileUserId}
                          disableClick
                        />
                      </div>
                      <div
                        style={{
                          maxWidth: "45px"
                        }}
                      >
                        {profileUserId === currentUserId && (
                          <OXOPill
                            onClick={() => {
                              firebase
                                .analytics()
                                .logEvent("click_profile_settings_1", {
                                  event_cartegory: "user_profile"
                                });
                              setShowProfileEdit(true);
                            }}
                            text={"설정"}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: 15,
                        color: GRAY_PROFILE,
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      {getUserDescription(userProfileMetadataForProfile, true)}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div style={{ padding: "0 15px" }}>
              <MiniOxButtonsForHashCard cardId={profileUserId} />
            </div>
          </div>

          <div
            style={{
              marginTop: 8,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div style={{ width: "100%" }}>
              <DetailPagesNavigation
                menuItemArray={MENU}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            </div>
            <div style={{ width: "100%" }}>
              <SwipeableViews
                enableMouseEvents
                index={tabIndex + 1}
                onChangeIndex={(i, prevI) => {
                  if (i === 0) {
                    onClose && onClose();
                  }
                  setTabIndex(i - 1);
                }}
              >
                <div key={"close"} />
                {MENU.map((tabTitle, i) => {
                  if (i === tabIndex) {
                    return (
                      <div
                        style={{ minHeight: windowHeight - 110 }}
                        key={tabTitle + i}
                      >
                        <UserProfileRenderer
                          profileUserId={profileUserId}
                          tab={tabTitle}
                        />
                      </div>
                    );
                  } else {
                    return <div key={tabTitle + i}></div>;
                  }
                })}
              </SwipeableViews>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
