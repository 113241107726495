import firebase from "firebase/compat";
import React, { useEffect } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import UserName from "./UserName";
import HashCommentBubbleForCommunity from "../../views/HashCardView/common/HashCommentBubbleForCommunity";
import HorizontalRuleForTimeline from "../../views/HashCardView/common/HorizontalRuleForTimeline";

const COMMENTS_PER_PAGE = 5;
export default function UserProfilePostsTimeline({
  profileUserId
}: {
  profileUserId: string;
}) {
  const [page, setPage] = React.useState(1);
  const [cards, setCards] = React.useState<CardSchema[]>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("createdBy", "==", profileUserId)
      .where("type", "==", CardType.POST)
      .orderBy("createdAt", "desc")
      .limit(COMMENTS_PER_PAGE * page)
      .get()
      .then(getDocsFromSnapshot)
      .then(setCards);
  }, [page]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {cards && cards.length > 0 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {cards.slice(0, page * COMMENTS_PER_PAGE).map((card, i) => (
            <div
              style={{
                width: "100%"
              }}
              key={card.id}
            >
              <HashCommentBubbleForCommunity cardId={card.id} />
              <HorizontalRuleForTimeline />
            </div>
          ))}
        </div>
      ) : (
        (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "30px",
              paddingBottom: "30px"
            }}
          >
            {/* <imgs src={ox} width={100} alt="ox"/> */}
            <br />
            <div style={{ display: "flex" }}>
              <span style={{ fontWeight: "bolder" }}>
                <UserName profileUserId={profileUserId} />
              </span>
              <span>님의</span>
            </div>
            <div>생각을 기다리고 있어요.</div>
          </div>
        ) || (
          <div style={{ padding: "40px" }}>
            <DocumentSkeleton />
          </div>
        )
      )}
      <div>
        <OXOInfiniteScroll setNextPage={() => setPage(prev => prev + 1)} />
      </div>
    </div>
  );
}
