import React from "react";

export default function StanceTestResultReviewCheckerIcon({
  color
}: {
  color: string;
}) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 7.54762L8.83168 16L17 1" stroke={color} strokeWidth="3" />
    </svg>
  );
}
