import React, { CSSProperties } from "react";
import { GRAY_BACKGROUND } from "../../../../constants/enums";

export default function HorizontalRuleForTimeline({
  style
}: {
  style?: CSSProperties;
}) {
  return (
    <div
      style={{
        marginTop: 20,
        width: "100%",
        height: 8,
        backgroundColor: GRAY_BACKGROUND,
        ...style
      }}
    />
  );
}
