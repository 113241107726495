import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { ROUTES } from "../../../../constants/routes";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot,
  parseDoc,
  useIsAdmin
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import { StanceTestConfiguration } from "../common/hashpage_shared";
import StanceTestIntro from "./StanceTestIntro";
import StanceTestResults from "./StanceTestResults";
import { STANCE_TEST_SUFFIX } from "./StanceTestView";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import firebase from "firebase/compat";

export default function StanceTestHashtagComponent({
  hashtag,
  stanceTestConfiguration
}: {
  hashtag: string;
  stanceTestConfiguration: StanceTestConfiguration;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const history = useHistory();
  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.TEST_QUESTION)
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setStanceTestCards);
  }, [hashtag]);

  const [userCount, setUserCount] = useState<number>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_VIEW_COUNTERS)
      .doc(hashtag + STANCE_TEST_SUFFIX)
      .onSnapshot(snapshot => {
        setUserCount(parseDoc(snapshot).count || 0);
      });
    return () => {
      unsubscriber();
    };
  }, []);

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const unansweredCards = useMemo(() => {
    if (stanceTestCards) {
      return stanceTestCards.filter(
        card => currentUserVector && !currentUserVector[card.id]
      );
    }
  }, [stanceTestCards, currentUserVector]);

  const isAdmin = useIsAdmin();

  if (stanceTestCards === undefined || unansweredCards === undefined) {
    return null;
  }

  if (stanceTestConfiguration.단독사용 && !isAdmin) {
    if (unansweredCards.length > 0) {
      history.push(ROUTES.URL_STACK + `/TEST_${hashtag}`);
    } else {
      history.push(ROUTES.URL_STACK + `/STANCE_TEST_RESULTS_${hashtag}`);
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#F1F1F3",
        display: "flex",
        padding: 30,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <div style={{ fontSize: 24, fontWeight: "bold" }}>
        {stanceTestConfiguration.제목}
      </div>
      {!currentUserVector ||
      unansweredCards.length > 0 ||
      stanceTestCards.length === 0 ? (
        <StanceTestIntro
          hashtag={hashtag}
          stanceTestConfiguration={stanceTestConfiguration}
        />
      ) : (
        <div>
          <StanceTestResults
            hashtag={hashtag}
            stanceTestConfiguration={stanceTestConfiguration}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 20,
          fontFamily: "East Sea Dokdo"
        }}
      >
        <span style={{ fontSize: 36, marginLeft: 4 }}>{userCount || 0}</span>
        명이 참여했어요!!
      </div>
      {isAdmin && (
        <div
          style={{ cursor: "pointer", paddingTop: 10, color: "blue" }}
          onClick={() => {
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, "CARD_EDIT")
            );
          }}
        >
          [관리자] 문제 추가하기
        </div>
      )}
    </div>
  );
}
