/** @jsxImportSource @emotion/react */

import React, { CSSProperties, useContext } from "react";
import { UserAnswer } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import {
  parseYamlDescription,
  StanceTestCategoryConfiguration,
  StanceTestQuestionConfiguration
} from "../common/hashpage_shared";
import DetailsButton from "./DetailsButton";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import {
  ANSWER_DUNNO_BACKGROUND,
  ANSWER_DUNNO_STROKE,
  ANSWER_O_BACKGROUND,
  ANSWER_O_STROKE,
  ANSWER_X_BACKGROUND,
  ANSWER_X_STROKE
} from "../../../../constants/colors";
import { useHistory } from "react-router-dom";
import { addToCardIdStackUrl } from "../../../../utils/utils";

export default function StanceTestResultReviewButton({
  userAnswer,
  categories,
  stanceVectors,
  categoryHashCards,
  testCard
}: {
  userAnswer: string;
  categories: StanceTestCategoryConfiguration;
  stanceVectors: {
    [category: string]: UserVectorSchema;
  };
  categoryHashCards: {
    [hashtag: string]: CardSchema;
  };
  highlightColor: string;
  testCard: CardSchema;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const history = useHistory();
  const testConfiguration = parseYamlDescription(
    testCard.yamlMetadata?.description
  ) as StanceTestQuestionConfiguration;

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  if (
    !categories ||
    !stanceVectors ||
    !categoryHashCards ||
    !currentUserVector
  ) {
    return null;
  }

  const buttonConfiguration =
    userAnswer === UserAnswer.O
      ? testConfiguration.찬성
      : userAnswer === UserAnswer.DUNNO
      ? testConfiguration.세모
      : testConfiguration.반대;

  if (!buttonConfiguration) {
    return null;
  }

  const buttonBackgroundColor: string =
    userAnswer === UserAnswer.O
      ? ANSWER_O_BACKGROUND
      : userAnswer === UserAnswer.DUNNO
      ? ANSWER_DUNNO_BACKGROUND
      : ANSWER_X_BACKGROUND;

  const buttonStrokeColor: string =
    userAnswer === UserAnswer.O
      ? ANSWER_O_STROKE
      : userAnswer === UserAnswer.DUNNO
      ? ANSWER_DUNNO_STROKE
      : ANSWER_X_STROKE;

  const isUserAnswerCorrect: boolean =
    currentUserVector[testCard.id] === userAnswer;

  const buttonStyle: CSSProperties = {
    paddingLeft: 20,
    margin: 30,
    marginTop: 10,
    marginBottom: 0,
    border: "solid 1px " + buttonStrokeColor,
    backgroundColor: isUserAnswerCorrect ? buttonBackgroundColor : "white",
    color: isUserAnswerCorrect ? "black" : "#B0B3B2",
    borderRadius: 10,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    wordBreak: "keep-all",
    position: "relative",
    minHeight: 50,
    fontSize: 14,
    justifyContent: "space-between"
  };

  return (
    <div
      onClick={e => {
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(
              history.location.search,
              testCard.id + "~tab:topic"
            )
        );
      }}
      css={{
        cursor: "pointer",
        ...buttonStyle,
        borderColor: isUserAnswerCorrect ? buttonStrokeColor : "#B0B3B2"
      }}
    >
      <div>
        {Object.keys(categories)
          .filter(category => isUserAnswerCorrect)
          .map(category => {
            return (
              categoryHashCards[category]?.hashtagMetadata?.photos?.[0] && (
                <div
                  key={category}
                  css={{
                    minWidth: 35,
                    height: 35,
                    borderRadius: "50%",
                    overflow: "hidden",
                    marginRight: 10
                  }}
                >
                  <img
                    src={
                      categoryHashCards[category]?.hashtagMetadata?.photos?.[0]
                    }
                    width={35}
                    alt="hasgtag_photo"
                  />
                </div>
              )
            );
          })}
      </div>
      <div
        css={{
          flexGrow: 1,
          fontWeight: isUserAnswerCorrect ? "bold" : "normal"
        }}
      >
        {buttonConfiguration.버튼?.includes("_")
          ? buttonConfiguration.버튼.split("_").map((text, i) => {
              return (
                <div css={{ paddingTop: 3 }} key={text + i}>
                  {text}
                </div>
              );
            })
          : buttonConfiguration.버튼}
      </div>
      <div
        css={{
          width: 40,
          paddingLeft: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {buttonConfiguration.자세히 && (
          <DetailsButton
            text={buttonConfiguration.자세히}
            buttonColor={
              currentUserVector[testCard.id] === userAnswer
                ? "white"
                : "#B0B3B2"
            }
          />
        )}
      </div>
    </div>
  );
}
