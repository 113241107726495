import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import {
  CardSchema,
  CommentSchema,
  FirestoreCollection,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { addCardViewCount } from "../../../lib/card_lib";
import {
  getDocsFromSnapshot,
  parseDoc,
  removeIdKey,
  useIsAdmin
} from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import CommentTabs from "../../topic/Question/Comments/CommentTabs";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import { addPostAnswerNotification } from "../../../lib/notification_internal_lib";
import AnswerMap from "../CardComponents/AnswerMap";
import OxButtonsForCard from "../../foundation/OXOButton/OxButtonsForCard";

export default function OXOCardDetailsForMinicard({
  card
}: {
  card: CardSchema;
}) {
  const [editPost, setEditPost] = React.useState(false);
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const currentUserTribeId = currentUserMetadata?.tribeId || "1";
  const isAdmin = useIsAdmin();

  const [questionAnswers, setQuestionAnswers] =
    React.useState<QuestionAnswerMapSchema>();
  useEffect(() => {
    if (card.id) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
        .doc(card.id)
        .onSnapshot(snapshot => {
          setQuestionAnswers(removeIdKey(parseDoc(snapshot)));
        });
      return () => unsubscribe();
    }
  }, [card.id]);

  const [postUserProfileMetadata, setPostUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();

  useEffect(() => {
    if (card) {
      const unsubscribeUserProfileMetadata = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(card.createdBy)
        .onSnapshot(snapshot =>
          setPostUserProfileMetadata(removeIdKey(parseDoc(snapshot)))
        );

      return () => {
        unsubscribeUserProfileMetadata();
      };
    }
  }, [card]);

  const [comments, setComments] = useState<CommentSchema[]>();
  if (comments && card && currentUserId) {
    if (card.comments !== comments.length) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(card.id)
        .set(
          {
            comments: comments.length
          },
          { merge: true }
        )
        .catch(e => {
          console.log(e);
        });
    }
  }

  const answerCount =
    (questionAnswers &&
      Object.values(questionAnswers).reduce(
        (sum, questionAnswer) =>
          sum +
          questionAnswer.countX +
          questionAnswer.countO +
          questionAnswer.countDunno,
        0
      )) ||
    0;

  useEffect(() => {
    if (card.id) {
      const commentsSubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .where("questionId", "==", card.id)
        .onSnapshot(snapshot => setComments(getDocsFromSnapshot(snapshot)));
      return () => commentsSubscribe();
    }
  }, [card.id]);

  useEffect(() => {
    setComments(undefined);
  }, [card.id]);

  useEffect(() => {
    addCardViewCount(card.id, currentUserId);
  }, []);

  if (
    !card ||
    !postUserProfileMetadata ||
    !dataProps?.mapCoordinates ||
    !questionAnswers
  ) {
    return (
      <div style={{ padding: "20px" }}>
        <DocumentSkeleton />
      </div>
    );
  }

  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {(card.createdBy !== currentUserId || isAdmin) && (
          <div style={{ paddingTop: 20 }}>
            <OxButtonsForCard
              cardId={card.id}
              additionalActionWhenClicked={() => {
                addPostAnswerNotification(card, answerCount + 1);
              }}
            />
          </div>
        )}
      </div>

      <div
        style={{
          paddingTop: "30px"
        }}
      >
        {currentUserTribeId && comments ? (
          <CommentTabs
            comments={comments}
            currentTribeId={currentUserTribeId}
            cardId={card.id}
            authorTribeId={card.createdByTribeId}
          />
        ) : (
          <div style={{ padding: "16px", height: "450px" }}>
            <DocumentSkeleton />
          </div>
        )}
      </div>

      <div>
        <div
          style={{
            padding: "20px 20px",
            marginBottom: "20px",
            minHeight: 400
          }}
        >
          <AnswerMap
            cardId={card.id}
            questionAnswers={questionAnswers}
            currentUserMetadata={currentUserMetadata}
          />
        </div>
      </div>
    </div>
  );
}
