import React from "react";
import UserProfileEdit from "../OXOUserProfile/UserProfileEdit";

export default function ProfileEdit() {
  return (
    <div
      className="profileEditPage"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 14
      }}
    >
      <UserProfileEdit />
    </div>
  );
}
