import firebase from "firebase/compat";
import React, { useContext } from "react";
import { TribeExpression } from "../../../constants/expressions";
import {
  CommentSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import CommentInput from "./CommentInput";
import { SnackbarContext } from "../Contexts/SnackbarContext";

export default function EditCommentInput({
  commentId,
  onInputSubmit,
  questionId
}: {
  commentId: string;
  onInputSubmit?: () => void;
  questionId: string;
}) {
  const displaySnackBar = useContext(SnackbarContext);

  const onSubmit = (comment: string, currentExpression: TribeExpression) => {
    firebase
      .firestore()
      .collection(FirestoreCollection.COMMENTS)
      .doc(commentId)
      .set(
        {
          comment: comment,
          expression: currentExpression
        } as CommentSchema,
        {
          merge: true
        }
      )
      .then(() => {
        displaySnackBar("댓글이 수정되었습니다.");
      });
    onInputSubmit && onInputSubmit();
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "10px"
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          maxWidth: "750px",
          margin: "0 auto"
        }}
      >
        <CommentInput
          onSubmit={onSubmit}
          commentId={commentId}
          questionId={questionId}
        />
      </div>
    </div>
  );
}
