import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TribeExpression } from "../../../constants/expressions";
import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import UserName from "../../profile/OXOUserProfile/UserName";
import UserProfilePhoto from "../../views/StatsView/UserProfilePhoto";
import firebase from "firebase/compat";

export default function UserHeadAndName({
  userId,
  expression
}: {
  userId: string;
  expression?: TribeExpression;
}) {
  const history = useHistory();
  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (userId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(userId)
        .get()
        .then(parseDoc)
        .then(setUserProfileMetadata);
    }
  }, [userId]);

  if (!userProfileMetadata) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <div
        style={{
          width: 34,
          height: 34,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => {
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(history.location.search, userId)
          );
        }}
      >
        {userProfileMetadata.tribeId && (
          <UserProfilePhoto
            tribeId={userProfileMetadata.tribeId}
            photoUrl={userProfileMetadata.photo}
            width={"34px"}
            expression={expression}
          />
        )}
      </div>
      <div
        style={{
          textAlign: "left",
          marginLeft: "5px",
          fontSize: 14,
          height: "100%",
          cursor: "pointer"
        }}
      >
        <UserName profileUserId={userId} />
      </div>
    </div>
  );
}
