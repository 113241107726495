import algoliasearch from "algoliasearch";
import JavascriptTimeAgo from "javascript-time-ago";
import ko from "javascript-time-ago/locale/ko.json";
import React, { useEffect, useMemo, useState } from "react";
import { AlgoliaContext } from "../../views/SearchView/AlgoliaContext";
import { ErrorBoundary } from "./ErrorBoundary";
import Main from "../Main/Main";
import { MINUTES } from "../../../utils/datetime";
import firebase from "firebase/compat";
import { GlobalVariableContext } from "../Contexts/GlobalVariableContext";
import { ROUTES } from "../../../constants/routes";
import { ThemeProvider } from "@emotion/react";
import theme from "../../../constants/theme";

export const VERSION = "5.7.1";
const firebaseConfig = {
  apiKey: "AIzaSyAf_EXOSTqw9lKM-HNQ9jI6hK4QcCBZWBg",
  authDomain: "oxopolitics-korea.firebaseapp.com",
  databaseURL: "https://oxopolitics-korea.firebaseio.com",
  projectId: "oxopolitics-korea",
  storageBucket: "oxopolitics-korea.appspot.com",
  messagingSenderId: "69968226026",
  appId: "1:69968226026:web:50fe3d726f40482182d993",
  measurementId: "G-CZ5CC0F14N"
};

function configureFirebase() {
  const app = firebase.initializeApp(firebaseConfig);

  firebase.firestore().settings({ experimentalForceLongPolling: true }); //add this..

  app.auth().useDeviceLanguage();

  const remoteConfig = app.remoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = MINUTES;
  remoteConfig.defaultConfig = {
    enableLogRocket: false,
    enableDatadog: false,
    enableGenderConflict: false,
    enableGenderConflictForAdmin: false
  };

  app.analytics();
  return app;
}

export default function App() {
  // Initialize App only once
  useMemo(() => {
    JavascriptTimeAgo.addLocale(ko);
    configureFirebase();
  }, []);

  const [remoteConfigLoaded, setRemoteConfigLoaded] = useState<boolean>(false);
  const [loginSuccessUrl, setLoginSuccessUrl] = useState<string>(
    ROUTES.INTRO_LOGGED_IN
  );
  const [testResultUrl, setTestResultUrl] = useState<string>(ROUTES.HOME);

  useEffect(() => {
    if (!remoteConfigLoaded) {
      firebase
        .remoteConfig()
        .fetchAndActivate()
        .then(() => {
          setRemoteConfigLoaded(true);
        })
        .catch(err => {
          console.log("error in remote config activation");
          console.log("err");
          setRemoteConfigLoaded(true);
        });
    }
  }, [remoteConfigLoaded]);

  const algoliaClient = useMemo(() => {
    const ALGOLIA_ID = "5GGIGOXZNF";
    const ALGOLIA_ADMIN_KEY = "e27f4cdfec4e73702458ca9046f8aa67";

    return algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);
  }, []);

  firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
    if (user && user.isAnonymous) {
      console.log("Anonymous user signout.");
      firebase
        .auth()
        .signOut()
        .then(() => window.location.reload());
    }
  });

  if (!remoteConfigLoaded) {
    return null;
  }

  return (
    <div
      className="App"
      style={{
        minHeight: window.innerHeight
      }}
    >
      <AlgoliaContext.Provider value={algoliaClient}>
        <ErrorBoundary>
          <GlobalVariableContext.Provider
            value={{
              testResultUrl,
              setTestResultUrl,
              loginSuccessUrl,
              setLoginSuccessUrl
            }}
          >
            <ThemeProvider theme={theme}>
              <Main />
            </ThemeProvider>
          </GlobalVariableContext.Provider>
        </ErrorBoundary>
      </AlgoliaContext.Provider>
    </div>
  );
}
