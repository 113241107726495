import React, { useState } from "react";
import PoldiSelector from "./PoldiSelector";
import PoldiTimeseries from "./PoldiTimeseries";

export default function PoldiView() {
  const [poldi, setPoldi] = useState<string>();
  return (
    <div>
      <PoldiSelector poldi={poldi} setPoldi={setPoldi} />
      <PoldiTimeseries poldi={poldi} />
    </div>
  );
}
