import { Button } from "@material-ui/core";
import React, { ReactNode } from "react";
import { GRAY_DARKER, oxoColors, UserAnswer } from "../../../constants/enums";
import AnswerDunno from "./AnswerDunno";
import AnswerO from "./AnswerO";
import AnswerX from "./AnswerX";

const INACTIVE_GRAY_COLOR = GRAY_DARKER;
const INACTIVE_BACKGROUND_COLOR = "#ffffff";

function getOxoButton(
  onVote: (answer: string) => void,
  gap: number,
  width: number,
  userAnswer?: string
) {
  return (buttonAnswer: string, activeButton: ReactNode) => {
    const caption =
      buttonAnswer === UserAnswer.O ? (
        <span>지지해요</span>
      ) : buttonAnswer === UserAnswer.X ? (
        <span>반대해요</span>
      ) : (
        <span>글쎄요</span>
      );

    return (
      <Button
        onClick={() => {
          onVote(buttonAnswer);
        }}
        style={{
          marginRight: 10,
          borderRadius: 5,
          width: width,
          borderColor:
            userAnswer === buttonAnswer
              ? buttonAnswer === UserAnswer.O
                ? INACTIVE_BACKGROUND_COLOR
                : buttonAnswer === UserAnswer.X
                ? INACTIVE_BACKGROUND_COLOR
                : INACTIVE_BACKGROUND_COLOR
              : GRAY_DARKER,
          borderStyle: "solid",
          borderWidth: userAnswer === buttonAnswer ? 0 : "1px",
          display: "flex",
          padding: 0,
          flexDirection: "column",
          alignItems: "center",
          backgroundColor:
            userAnswer === buttonAnswer
              ? buttonAnswer === UserAnswer.O
                ? oxoColors.O
                : buttonAnswer === UserAnswer.X
                ? oxoColors.X
                : oxoColors.DUNNO
              : INACTIVE_BACKGROUND_COLOR
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              marginRight: 5,
              cursor: "pointer"
            }}
          >
            {activeButton}
          </div>
          <div
            style={{
              fontSize: "12px",
              color: GRAY_DARKER,
              cursor: "pointer"
            }}
          >
            {caption}
          </div>
        </div>
      </Button>
    );
  };
}

export function OxFlatButtons({
  answer,
  onVote,
  width = 100,
  gap = 0
}: {
  answer?: string;
  onVote: (answer: string) => void;
  width?: number;
  gap?: number;
}) {
  const oxoButton = getOxoButton(onVote, gap, width, answer);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start"
      }}
    >
      {oxoButton(
        UserAnswer.O,
        <AnswerO color={INACTIVE_GRAY_COLOR} width={10} />
      )}
      {oxoButton(
        UserAnswer.DUNNO,
        <AnswerDunno color={INACTIVE_GRAY_COLOR} width={10} />
      )}
      {oxoButton(
        UserAnswer.X,
        <AnswerX color={INACTIVE_GRAY_COLOR} width={10} />
      )}
    </div>
  );
}
