import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import MiniOXOCard from "../MiniCards/MiniOXOCard";

const CARDS_PER_PAGE = 5;

export default function OXOCardTimeline() {
  const [page, setPage] = useState<number>(1);
  const [oxoCards, setOxoCards] = React.useState<CardSchema[]>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.OXO)
      .orderBy("createdAt", "desc")
      .limit(CARDS_PER_PAGE * page)
      .get()
      .then(getDocsFromSnapshot)
      .then(setOxoCards);
  }, [page]);

  return (
    <div>
      {oxoCards && (
        <div>
          {oxoCards.slice(0, page * CARDS_PER_PAGE).map((oxoCard, i) => (
            <div key={oxoCard.id}>
              <MiniOXOCard cardId={oxoCard.id} />
            </div>
          ))}
          <OXOInfiniteScroll
            setNextPage={() => {
              setPage(prev => prev + 1);
            }}
          />
        </div>
      )}
    </div>
  );
}
