import React, { useContext } from "react";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getNewsCircleLogoImage, useIsAdmin } from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";

export default function NewsLink({
  cardId,
  logoSize = 30
}: {
  cardId: string;
  logoSize?: number;
}) {
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const isAdmin = useIsAdmin();
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  if (!card || !cardAnswer || card.type !== CardType.NEWS_TICKER) {
    return null;
  }

  const mediaData = card.newsTickerMetadata?.newsInfo;

  const legacyTypeMediaData = card.topicMetadataV2?.description
    ? card.topicMetadataV2?.description.split("\n").map(link => link.split(" "))
    : [];

  if (card.type === CardType.NEWS_TICKER && !card.displayToUsers && !isAdmin) {
    return null;
  }

  return (
    <div style={{ marginBottom: 10 }}>
      {mediaData &&
        mediaData.map((news, i) => {
          if (Object.keys(news).length !== 0 && news.media !== "") {
            return (
              <div
                key={i}
                style={{
                  padding: "10px 0",
                  cursor: "pointer",
                  display: "flex",
                  fontWeight: "normal",
                  fontSize: 14,
                  alignItems: "center"
                }}
                onClick={() => {
                  window.open(news.address, "_system");
                }}
              >
                {news.media && (
                  <img
                    src={getNewsCircleLogoImage(news.media)}
                    width={logoSize}
                    alt={news.media}
                  />
                )}
                <div style={{ paddingLeft: 10 }}>
                  {news.title && news.title}
                </div>
              </div>
            );
          }
        })}

      {!mediaData &&
        legacyTypeMediaData &&
        legacyTypeMediaData.map((news, i) => {
          const [MEDIA, ADDRESS, ...TITLE] = news;

          return (
            <div
              key={i}
              style={{
                padding: "10px 0",
                cursor: "pointer",
                display: "flex",
                fontWeight: "normal",
                fontSize: 14,
                alignItems: "center"
              }}
              onClick={() => {
                window.open(ADDRESS, "_system");
              }}
            >
              <img src={getNewsCircleLogoImage(MEDIA)} width={30} alt={MEDIA} />
              <div style={{ paddingLeft: 10 }}>{TITLE.join(" ")}</div>
            </div>
          );
        })}
    </div>
  );
}
