import React, { useEffect, useState } from "react";

import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import Last24HBubbleCards from "./hashtag/Last24HBubbleCards";
import HashCommentBubble from "../HashCardView/common/HashCommentBubble";
import firebase from "firebase/compat";

export default function HashtagCardForTodayTimeline({
  hashtag
}: {
  hashtag: string;
}) {
  const [topicCard, setTopicCard] = useState<CardSchema>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [CardType.TOPIC, CardType.POST])
      .where("hashtags", "array-contains", "#" + hashtag)
      .where("displayToUsers", "==", true)
      .orderBy("publishedAt", "desc")
      .limit(1)
      .onSnapshot(snapshot => {
        setTopicCard(getDocsFromSnapshot(snapshot)[0]);
      });

    return () => {
      unsubscriber();
    };
  }, [hashtag]);

  return (
    <div key={hashtag} id={hashtag}>
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 20
        }}
      >
        <div
          style={{
            textAlign: "left",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Last24HBubbleCards
            hashtag={hashtag}
            lastTopicCardId={topicCard?.id}
          />

          {topicCard && (
            // <MiniTodayTopicCardV4 cardId={topicCard.id} key={topicCard.id} />
            <HashCommentBubble cardId={topicCard.id} key={topicCard.id} />
          )}
        </div>
      </div>
    </div>
  );
}
