/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import {
  CardAnswerTribeStatsV2Schema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { listToMap, parseDoc } from "../../../utils/utils";
import { CSS_TD } from "../DataExplorerView/shared";

export default function HashtagTimeSeriesTribeAnswersForCard({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerStat, setCardAnswerStat] =
    useState<CardAnswerTribeStatsV2Schema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2)
      .doc(cardId)
      .get()
      .then(parseDoc)
      .then(setCardAnswerStat);
    return () => {};
  }, [cardId]);

  const [finalStats, totalOs, totalCounts, averageRates]: [
    { [tribeId: string]: number } | undefined,
    number,
    number,
    number
  ] = useMemo(() => {
    if (!cardAnswerStat) {
      return [undefined, 0, 0, 0];
    }
    let totalOs = 0;
    let totalCounts = 0;
    let averageRates = 0;

    const finalStats = listToMap(
      ["1", "2", "3", "4", "5"].map(tribeId => {
        if (!cardAnswerStat) {
          return ["1", "0"];
        }
        const tribeStat = cardAnswerStat.tribeStats?.[tribeId] || {
          o: 0,
          "?": 0,
          x: 0,
          count: 0
        };
        const total =
          (tribeStat.o || 0) + (tribeStat["?"] || 0) + (tribeStat.x || 0);

        totalOs += tribeStat.o || 0;
        totalCounts += total;
        const supportingPercentage = ((tribeStat.o || 0) / (total || 1)) * 100;
        averageRates += supportingPercentage / 5;

        return [tribeId, supportingPercentage];
      }),
      k => k[0] as string,
      k => k[1]
    );
    return [finalStats, totalOs, totalCounts, averageRates];
  }, [cardAnswerStat]);

  if (!finalStats) {
    return null;
  }

  return (
    <>
      {Object.keys(finalStats).map(tribeId => {
        return (
          <td key={tribeId} css={CSS_TD}>
            {finalStats[tribeId].toFixed(1)}%
          </td>
        );
      })}
      <td css={CSS_TD}>
        {((finalStats["1"] + finalStats["2"]) / 2).toFixed(1)}%
      </td>
      <td css={CSS_TD}>{finalStats["3"].toFixed(1)}%</td>
      <td css={CSS_TD}>
        {((finalStats["4"] + finalStats["5"]) / 2).toFixed(1)}%
      </td>
      <td css={CSS_TD}>{averageRates.toFixed(1)}%</td>
      <td css={CSS_TD}>{((totalOs / (totalCounts || 1)) * 100).toFixed(1)}%</td>
    </>
  );
}
