/** @jsxImportSource @emotion/react */

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GRAY_DARK, TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter
} from "../../../../utils/utils";
import HashCommentAvatar from "../Comment/HashCommentAvatar";
import HashCommentName from "../Comment/HashCommentName";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import MiddleDot from "../../../foundation/common/MiddleDot";
import OXOText from "../../../utility/OXOText/OXOText";
import { css } from "@emotion/react";
import SingleRepresentativeComment from "../Comment/SingleRepresentativeComment";

export default function HashCommentBubbleHighlight({
  cardId
}: {
  cardId: string;
}) {
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  const answer = cardAnswer?.answer;

  const history = useHistory();
  const [windowWidth] = useWindowSize();
  const widthMargin = 80;

  if (!card || !cardAnswer || !card.title) {
    return null;
  }

  const SPEAKERS: string[] =
    card.type === CardType.TOPIC
      ? ["옥소"]
      : card.speakerHashTag
          ?.filter(speaker => speaker.length)
          .map(speaker => speaker.replace("#", "")) || ["방랑자"];

  const trimmedTitle: string = card.title?.replace(/["“”]/g, "");
  const speakerFromTitle: string | undefined = trimmedTitle?.split(" ")[0];
  const commentBefore: string =
    trimmedTitle?.slice((speakerFromTitle?.length || 0) + 1) || "";

  const comment: string =
    card.type === CardType.DEBATE && card.debateCommentMetadata
      ? card.debateCommentMetadata.comment
      : card.type === CardType.NEWS_TICKER
      ? card.newsTickerMetadata
        ? card.title
        : commentBefore
      : card.title;

  const publishedAt: string[] | undefined = card.publishedAt
    ? new Date(card.publishedAt).toLocaleString().split(" ")
    : undefined;

  let year, month, day, time;

  const bubbleMaxWidth = Math.min(500 - 62, windowWidth - widthMargin);

  const answeredBubbleStyle = {
    color: "white",
    backgroundColor: GRAY_DARK
  };

  const tribeColor =
    currentUserMetadata?.tribeId &&
    TRIBE_INFO_BY_ID[currentUserMetadata.tribeId]
      ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
      : GRAY_DARK;
  const currentUserTribeColorBubbleStyle = {
    color: "white",
    backgroundColor: tribeColor
  };

  const authorColor =
    card.createdByTribeId && TRIBE_INFO_BY_ID[card.createdByTribeId]
      ? TRIBE_INFO_BY_ID[card.createdByTribeId].heavyColor
      : GRAY_DARK;
  const createdByTribeColorBubbleStyle = {
    color: "white",
    backgroundColor: authorColor
  };

  const bubbleStyle = answer
    ? answeredBubbleStyle
    : card.type === CardType.POST
    ? createdByTribeColorBubbleStyle
    : currentUserTribeColorBubbleStyle;

  return (
    <div
      css={css`
        display: flex;
        padding-bottom: 12px;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div
        css={{
          display: "flex"
        }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90px;
            padding-right: 10px;
            text-align: center;
          `}
        >
          {SPEAKERS &&
            SPEAKERS.map((speaker, i, speakers) => (
              <div
                css={{
                  paddingRight: 5,
                  marginBottom:
                    speakers.length > 1 && i !== speakers.length - 1 ? -20 : 0,
                  position: "relative"
                }}
                key={speaker}
              >
                <HashCommentAvatar speaker={speaker} size={42} />
              </div>
            ))}

          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: 5px;
            `}
          >
            {SPEAKERS && (
              <div>
                <div
                  css={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexShrink: 0,
                    fontSize: 12,
                    color: GRAY_DARK,
                    fontWeight: "normal"
                  }}
                >
                  {SPEAKERS.map((speaker, i, speakers) => (
                    <div css={{ display: "flex" }} key={speaker}>
                      <HashCommentName
                        speaker={speaker}
                        style={{ fontSize: 14, color: "black" }}
                      />
                      {speakers.length > 1 && i !== speakers.length - 1 && (
                        <MiddleDot />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          css={{
            display: "flex",
            width: bubbleMaxWidth,
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <div>
            <div
              css={{
                width: bubbleMaxWidth,
                borderRadius: 14,
                padding: "12px 17px",
                position: "relative",
                cursor: "pointer",
                ...answeredBubbleStyle
              }}
              onClick={e => {
                e.stopPropagation();
                history.push(
                  history.location.pathname +
                    addToCardIdStackUrl(history.location.search, cardId)
                );
              }}
            >
              <div
                css={{
                  minHeight: 20,
                  fontSize: 15,
                  fontWeight: "normal",
                  lineHeight: 1.5
                }}
              >
                <OXOText
                  text={hashtagTextAdapter(comment)}
                  useDivForParagraph
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SingleRepresentativeComment
        cardId={cardId}
        bubbleMaxWidth={bubbleMaxWidth}
      />
    </div>
  );
}
