import firebase from "firebase/compat";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GRAY_DARK, GRAY_MIDDLE } from "../../../../constants/enums";
import {
  CardAnswerStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import oxoFace from "../../../../img/ox/oxo_face.png";
import {
  addToCardIdStackUrl,
  getPartySmallLogo,
  listToMap,
  parseDoc
} from "../../../../utils/utils";
import {
  candidateInfo,
  parseYamlDescription,
  PeopleHashtagsConfiguration,
  ProfileConfiguration
} from "../common/hashpage_shared";
import SupporterPercentage from "../Comment/SupporterPercentage";

export default function HashPeopleInfo({
  participants,
  isDirectionColumn = true,
  election
}: {
  participants: PeopleHashtagsConfiguration | string[];
  isDirectionColumn?: boolean;
  election?: string;
}) {
  const [candidates, setCandidates] = useState<{
    [name: string]: candidateInfo;
  }>();
  const history = useHistory();
  const [sortedNames, setSortedNames] = useState<string[]>();
  useEffect(() => {
    const promises = Object.keys(participants).map(hashtagName =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(hashtagName)
        .get()
        .then(parseDoc)
    );
    Promise.all(promises).then((cardAnswerStats: CardAnswerStatsSchema[]) => {
      const sortedStats = cardAnswerStats.sort((a, b) => {
        const bRate = (b.o || 0) / (b.count || 1);
        const aRate = (a.o || 0) / (a.count || 1);
        if (bRate === aRate) {
          return b.count - a.count;
        }
        return bRate - aRate;
      });
      setSortedNames(
        sortedStats.map(questionAnswerStats => questionAnswerStats.id || "")
      );
    });
    return () => {};
  }, [participants]);

  useEffect(() => {
    let allHashtagCards;
    if (Array.isArray(participants)) {
      allHashtagCards = participants.map((candidateName: string) => {
        return firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(candidateName)
          .get()
          .then(parseDoc);
      });
    } else {
      allHashtagCards = Object.keys(participants).map(
        (candidateName: string) => {
          return firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .doc(candidateName)
            .get()
            .then(parseDoc);
        }
      );
    }

    Promise.all(allHashtagCards).then((hashtagCards: CardSchema[]) => {
      const candidateMap = listToMap(
        hashtagCards,
        (hashtagCard: CardSchema) => hashtagCard.id,
        (hashtagCard: CardSchema) => {
          const profileConfiguration =
            parseYamlDescription<ProfileConfiguration>(
              hashtagCard.hashtagMetadata?.description
            );
          return {
            photoUrl: hashtagCard.hashtagMetadata?.photos?.[0],
            name: hashtagCard.id,
            party: !Array.isArray(participants)
              ? participants[`${hashtagCard.id}`].정당사진
                ? participants[`${hashtagCard.id}`].정당사진
                : profileConfiguration?.정당 || ""
              : profileConfiguration?.정당 || "",
            candidateNumber: Array.isArray(participants)
              ? election
                ? profileConfiguration.선거정보?.[election]?.기호
                : 1
              : participants[hashtagCard.id]["기호"]
          };
        }
      );

      setCandidates(candidateMap);
    });
  }, []);

  if (!candidates || !sortedNames) {
    return null;
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          fontWeight: 400,
          fontSize: 20,
          marginTop: isDirectionColumn ? -4 : 0,
          padding: isDirectionColumn ? "0px 11px" : "0px 33px"
        }}
      >
        <ul
          style={{
            margin: 0,
            padding: 0,
            listStyleType: "none",
            width: "100%",
            display: "flex",
            flexDirection: isDirectionColumn ? "column" : "row",
            justifyContent: "space-around"
          }}
        >
          {sortedNames
            .sort(
              (a, b) =>
                candidates[a].candidateNumber - candidates[b].candidateNumber
            )
            .map(el => {
              return (
                <li
                  key={candidates[el].name}
                  style={{
                    display: "flex",
                    flexDirection: isDirectionColumn ? "row" : "column",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    marginBottom: isDirectionColumn ? 20 : 0,
                    cursor: "pointer"
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    firebase
                      .analytics()
                      .logEvent(`v4_click_hash_people_${candidates[el].name}`, {
                        event_category: "hashcard",
                        event_label: election
                      });
                    history.push(
                      history.location.pathname +
                        addToCardIdStackUrl(
                          history.location.search,
                          `${candidates[el].name}`
                        )
                    );
                  }}
                >
                  {candidates[el].party && candidates[el].party.length > 0 && (
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: isDirectionColumn ? 0 : -10,
                        marginTop: isDirectionColumn ? -3 : 0
                      }}
                    >
                      <img
                        src={getPartySmallLogo(candidates[el].party)}
                        style={{ width: 25 }}
                        alt="partyLogo"
                      />
                    </div>
                  )}
                  <div
                    style={{
                      width: isDirectionColumn ? 55 : 80,
                      height: isDirectionColumn ? 55 : 80,
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: isDirectionColumn ? 0 : -12
                    }}
                  >
                    <img
                      style={{
                        width: isDirectionColumn ? 55 : 80,
                        height: isDirectionColumn ? 55 : 80
                      }}
                      alt={candidates[el].name}
                      src={
                        candidates[el].photoUrl
                          ? candidates[el].photoUrl
                          : oxoFace
                      }
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: isDirectionColumn ? 30 : 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: isDirectionColumn ? "flex-start" : "center"
                    }}
                  >
                    {candidates[el].candidateNumber > 0 && (
                      <div
                        style={{
                          fontSize: isDirectionColumn ? 10 : 12,
                          color: GRAY_MIDDLE,
                          fontWeight: "bold",
                          margin: isDirectionColumn
                            ? "15px 0px 3px 0px"
                            : "13px 0px 0px 0px"
                        }}
                      >{`기호 ${candidates[el].candidateNumber}번`}</div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        fontSize: 12,
                        color: GRAY_DARK
                      }}
                    >
                      <div style={{ marginRight: 5 }}>옥소</div>{" "}
                      <SupporterPercentage
                        cardId={candidates[el].name}
                        showCounts
                      />
                    </div>
                    <div
                      className="후보명"
                      style={{
                        fontSize: 18,
                        color: "#000000",
                        fontWeight: "bolder"
                      }}
                    >
                      {candidates[el].name}{" "}
                      {isDirectionColumn && (
                        <span style={{ fontWeight: "normal" }}> ❯</span>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
}
