import { MenuItem } from "@material-ui/core";
import { ReplyOutlined } from "@material-ui/icons";
import React from "react";

export default function ChatReplyButton({
  onChatReply,
  onClose
}: {
  onChatReply: () => void;
  onClose: () => void;
}) {
  return (
    <MenuItem
      key={"chatReply"}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: 14
      }}
      onClick={e => {
        e.stopPropagation();
        onChatReply();
        onClose();
      }}
    >
      <div style={{ marginRight: 5 }}>
        <ReplyOutlined />
      </div>{" "}
      <div style={{ paddingBottom: 7 }}>댓글 달기</div>
    </MenuItem>
  );
}
