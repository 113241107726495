import React from "react";
import { useGetCard } from "../../../utils/hooks/useFirestoreGetDocument";

function sliceBeforeString(fullString: string, sliceCharacter: string) {
  const startingO = fullString.indexOf(sliceCharacter);

  if (startingO > 0) {
    return fullString.slice(0, startingO);
  }
  return fullString;
}

const SLICE_STRINGS = ["O:", "○:", "동글 :"];

export default function CardIndexLine({
  cardId,
  headerTitle
}: {
  cardId: string;
  headerTitle: string;
}) {
  const card = useGetCard(cardId);

  if (!card) {
    return null;
  }

  const title = SLICE_STRINGS.reduce(
    (p, string) => sliceBeforeString(p, string),
    card.title
  );

  return (
    <div>
      {headerTitle} {title}
    </div>
  );
}
