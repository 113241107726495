import React from "react";

export default function TermsAndConditions() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "750px",
        margin: "auto",
        backgroundColor: "white",
        padding: "40px",
        textAlign: "left"
      }}
    >
      <h1>옥소폴리틱스 서비스 이용약관 v1.0.0</h1>

      <h2>제1장 총칙</h2>

      <h3>제1조 (목적)</h3>
      <p>
        ① 본 약관은 (주)옥소폴리틱스(이하 ‘당사’)가 운영하는 서비스의 이용과
        관련하여 당사와 회원 간의 권리 의무 및 책임사항을 규정함을 목적으로
        합니다.
      </p>
      <h3>제2조 (용어의 정의)</h3>
      <p>① 본 약관에서 사용하는 용어는 다음과 같이 정의합니다.</p>
      <ol>
        <li>
          회원: 당사와 홈페이지(https://oxopolitics.com, 이하 ‘사이트’)
          이용계약을 체결하고 당사의 서비스를 이용하는 자
        </li>
        <li>
          아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 제출하고 당사가
          승인하는 이메일 주소
        </li>
        <li>
          비밀번호: 회원이 부여받은 아이디와 일치되는 회원임을 확인하고
          비밀보호를 위해 회원 스스로 정하여 서비스에 등록한 문자 또는 숫자의
          조합
        </li>
        <li>서비스: 당사가 사이트를 통하여 회원에게 제공하는 서비스 일체</li>
      </ol>
      <p>
        ② 본 약관에서 사용하는 용어의 정의는 전항에서 정하는 것을 제외하고는
        관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한
        것은 일반적인 상관례에 따릅니다.
      </p>
      <h3>제3조 (약관의 공시 및 효력)</h3>
      <p>
        ① 당사는 본 약관의 내용을 사용자가 알 수 있도록 서비스 내 또는 그
        연결화면에 게시하거나 기타의 방법(문자메시지, 전자메일, 메신저, 로그인
        시 동의창 등)으로 회원에게 공지하며, 본 약관은 그 내용에 동의한 사용자가
        회원으로 가입한 경우에 효력이 발생합니다.
      </p>
      <p>
        ② 당사는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호
        등에 관한 법률」 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할
        수 있습니다.
      </p>
      <p>
        ③ 당사가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 개정약관의 적용일자 7일 전부터 적용일자 전일까지 제1항의
        방법으로 공지합니다. 단 회원의 권리, 의무에 중대한 영향을 주는 변경의
        경우에는 적용일자 30일 전에 위 방법으로 공지 또는 통지합니다. 회원이
        가입시 제공한 이메일 주소로 약관의 변경을 공지하였음에도 이메일 주소
        변경 등의 사유로 송달되지 못하거나 반송된 경우, 본 약관에 의한 공지를
        함으로써 개별 통지한 것으로 간주합니다.
      </p>
      <p>
        ④ 회원은 변경된 약관에 대해 거부할 권리가 있습니다. 당사가 제3항의 공지
        또는 통보를 하면서 개정 약관의 적용/시행일까지 회원이 거부 의사를
        표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는 내용을
        고지하였으나, 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지
        아니하면, 당사는 회원이 적용/시행일자 부로 변경 약관에 동의한 것으로
        간주합니다. 개정/변경 약관에 대하여 거부의사를 표시한 회원은 회원 탈퇴를
        선택할 수 있습니다.
      </p>
      <h3>제4조 (약관의 해석)</h3>
      <p>
        본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 전자상거래
        등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
        공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령
        또는 상관례에 따릅니다.
      </p>

      <h2>제2장 회원가입과 관리 및 탈퇴</h2>

      <h3>제5조 (회원가입)</h3>
      <p>
        ① 이용자는 당사가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에
        동의한다는 의사표시를 함으로서 회원가입을 신청하며, 회사가 위 신청을
        승낙함으로써 회원의 자격을 가집니다.
      </p>
      <p>
        ② 당사는 회원가입 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 다음의
        경우에는 회원가입 신청을 거절할 수 있습니다.
      </p>
      <ol>
        <li>
          회원가입을 신청한 자가 자신의 귀책으로 인해 약관에 의하여 이전에
          회원자격을 상실한 적이 있는 경우
        </li>
        <li>
          신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을
          위반하며 신청하는 경우
        </li>
        <li>
          관련 법령에서 금지하는 행위 또는 사회의 안녕과 질서 또는 미풍양속을
          저해할 목적으로 서비스 이용을 신청하는 경우
        </li>
        <li>
          그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
        </li>
      </ol>
      <p>
        ③ 당사가 회원가입을 거절하는 경우 회원가입을 신청한 자에게 이를
        알립니다.
      </p>
      <h3>제6조 (미성년자 회원가입에 대한 특칙)</h3>
      <p>
        ① 만 14세 미만의 이용자는 개인정보의 수집 및 이용목적에 대하여 충분히
        숙지하고 부모 등 법정대리인의 동의를 받은 후 회원가입을 신청하고 본인의
        개인정보를 제공해야 합니다.
      </p>
      <p>
        ② 당사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 만 14세
        미만 이용자에 대하여는 가입을 취소 또는 불허합니다.
      </p>
      <p>
        ③ 만 14세 미만 이용자의 부모 등 법정대리인은 아동에 대한 개인정보의
        열람, 갱신, 정정을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며,
        이러한 경우 당사는 지체 없이 필요한 조치를 취해야 합니다.
      </p>
      <h3>제7조 (회원정보의 변경)</h3>
      <p>
        ① 회원은 회원가입 신청 시 기재한 정보가 변경되었을 경우 온라인으로
        변경사항을 수정하거나, 전자우편 등 기타 방법으로 당사에 이를 알려야
        합니다.
      </p>
      <p>
        ② 당사는 회원이 개인정보 변경사항을 수정하지 않거나, 당사에 알리지 않아
        발생한 불이익에 대해서는 책임지지 않습니다.
      </p>
      <h3>제8조 (회원의 아이디와 비밀번호 관리에 관한 주의의무)</h3>
      <p>
        ① 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를
        제3자가 이용하도록 허락해서는 안 됩니다.
      </p>
      <p>
        ② 회원은 아이디 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을
        인지한 경우에는 이를 즉시 당사에 통지하고 당사의 안내에 따라야 합니다.
      </p>
      <p>
        ③ 회원이 당사에 제3자의 도용사실을 통지하지 않은 경우 또는
        통지하였더라도 당사의 안내에 따르지 않은 경우에는 당사는 이로 인해
        발생한 불이익에 대하여 책임지지 않습니다.
      </p>
      <h3>제9조 (회원 탈퇴와 자격 제한 및 상실)</h3>
      <p>
        ① 회원은 당사에 언제든지 탈퇴를 요청할 수 있으며, 당사는 즉시 회원탈퇴를
        처리합니다.
      </p>
      <p>
        ② 회원이 다음 각 호의 부정이용 행위를 하는 경우, 당사는 회원자격을 제한
        또는 정지시킬 수 있다.
      </p>
      <ol>
        <li>가입 신청 시 타인의 정보를 도용한 경우</li>
        <li>
          도배글 또는 불쾌감을 일으키는 글, 사진 등을 게시하여 타 회원의 서비스
          이용을 방해하거나 그 정보를 도용하는 등 부정한 행위를 한 경우
        </li>
        <li>자신의 아이디를 타인이 이용하도록 하거나 판매·대여하는 경우</li>
        <li>타 회원으로부터 회원의 게시글에 대한 신고가 접수된 경우</li>
        <li>
          사이트에 타 회원 또는 제3자의 명예를 훼손하거나 모욕하는 내용의 글을
          작성한 경우
        </li>
      </ol>
      <p>
        ③ 회원자격의 제한·정지 후에도 회원이 동일한 행위를 반복하거나 30일
        이내에 그 사유를 시정하지 아니하는 경우, 당사는 회원자격을 상실시킬 수
        있습니다.
      </p>
      <p>
        ④ 당사는 회원자격을 상실시키는 경우, 회원등록을 말소하고 아이디를
        삭제합니다. 당사는 회원에게 이를 통지하고, 회원등록 말소 전에 최소한
        30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
      </p>
      <h3>제10조 (개인정보의 보호 및 관리)</h3>
      <p>
        ① 당사는 관계 법령이 정하는 바에 따라 아이디에 관한 정보를 포함한 회원의
        개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보 보호 및 사용에
        대해서는 개인정보처리방침에 따릅니다. 다만, 당사가 제공하는 서비스
        이외에 링크된 사이트에서는 당사의 개인정보보호정책이 적용되지 않는다.
      </p>
      <p>
        ② 당사는 회원의 귀책사유로 인하여 노출된 회원의 아이디에 관한 정보를
        포함한 모든 개인정보의 처리에 대해서는 일절 책임을 지지 않습니다.
      </p>
      <h3>제11조 (회원에 대한 통지)</h3>
      <p>
        ① 당사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한
        회원이 아이디로 사용하는 이메일 주소로 통지할 수 있습니다.
      </p>
      <p>
        ② 당사는 회원 전체에 대한 통지의 경우 7일 이상 당사의 서비스 내 또는 그
        연결화면 또는 홈페이지 공지사항 게시판에 게시함으로써 제1항의 통지에
        갈음할 수 있습니다.
      </p>

      <h2>제3장 서비스 일반</h2>

      <h3>제12조 (서비스의 제공 및 중단)</h3>
      <p>① 당사는 회원의 회원가입 시부터 서비스를 개시합니다.</p>
      <p>
        ② 당사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
        정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.
      </p>
      <p>
        ③ 당사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나
        중지할 수 있습니다.
      </p>
      <ol>
        <li>
          전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는
          경우
        </li>
        <li>
          정전, 제반 설비의 장애 또는 이용량, 트래픽의 폭주 등으로 정상적인
          서비스 이용에 지장이 있는 경우
        </li>
        <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
        <li>기타 당사의 제반 사정으로 서비스를 할 수 없는 경우</li>
      </ol>
      <h3>제13조 (서비스의 변경)</h3>
      <p>
        ① 당사는 당사가 회원에게 제공하는 서비스에 대하여 제작, 변경, 유지,
        보수에 관한 포괄적인 권한을 가집니다.
      </p>
      <p>
        ② 당사는 새로운 서비스 내용, 각종 버그 패치 등 서비스의 운영상 또는
        기술상 필요한 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로
        수정, 추가, 폐지, 종료 등 변경할 수 있습니다. 변경될 서비스의 내용 및
        제공일자 등에 대해서는 변경이 이루어지기 7일 이전에 사이트 초기화면에
        게시함으로써 회원에 대한 통지에 갈음합니다. 다만, 당사가 통제할 수 없는
        사유로 인한 서비스의 중단으로 인하여 사전 통지가 불가능한 경우에는
        그러하지 아니합니다.
      </p>
      <h3>제14조 (정보의 제공 및 광고의 게재)</h3>
      <p>
        ① 당사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 당사의
        서비스 내 또는 그 연결화면에 게시하거나 이메일 등을 이용하여 회원에게
        제공할 수 있습니다. 다만, 회원은 관련 법령에 따른 거래관련 정보,
        고객센터 답변 등을 제외한 정보의 전송에 대하여 언제든지 이메일 등을
        통하여 수신거절의 의사표시를 할 수 있습니다.
      </p>
      <p>
        ② 당사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 회원은
        서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.
      </p>
      <p>
        ③ 당사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이 참여하거나 교신
        또는 거래를 함으로써 발생하는 손실과 손해에 대해서 당사는 어떠한 책임도
        부담하지 않습니다.
      </p>
      <p>
        ④ 당사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을
        메일주소 등을 활용하여 발송할 수 있으며, 회원이 원하지 않는 경우에는
        언제든지 수신을 거부할 수 있습니다.
      </p>

      <h2>제4장 서비스의 이용</h2>

      <h3>제15조 (서비스의 내용)</h3>
      <p>① 당사는 사이트를 통해 회원에게 다음과 같은 서비스를 제공합니다.</p>
      <ol>
        <li>
          투데이 서비스: 특정 정치∙사회 이슈에 대한 요약을 읽고, 찬성 또는
          반대로 의견을 표시하며 해당 이슈에 대한 본인의 의견을 댓글로 작성할 수
          있는 서비스
        </li>
        <li>
          커뮤니티 서비스: 정치∙사회 이슈에 대한 본인의 의견을 글 또는 댓글로
          작성하여 타 회원과 의견을 주고받을 수 있는 서비스
        </li>
        <li>톡 서비스: 타 회원과 채팅으로 대화할 수 있는 서비스</li>
        <li>
          테스트 서비스: 본인의 정치∙사회적 성향을 설문을 통해 테스트할 수 있는
          서비스
        </li>
        <li>상기 각 호에 부수하거나 연계된 업무</li>
      </ol>
      <p>
        ② 당사는 서비스 운영 또는 개선을 위하여 상당한 필요성이 있는 경우
        서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다.
      </p>
      <h3>제16조 (회원의 게시물 및 권리의 귀속)</h3>
      <p>
        ① 회사가 제작한 서비스 내 일체의 콘텐츠에 대한 저작권과 기타
        지적재산권은 회사에 귀속합니다.
      </p>
      <p>
        ② 회원은 서비스 이용 시 사진, 글, 영상물 또는 당사에 대한 의견이나 제안
        등의 콘텐츠(이하 ‘게시물’)를 사이트에 게시할 수 있으며, 이러한 게시물에
        대한 저작권을 포함한 지식재산권은 회원 또는 제3자 등 해당 게시물의
        작성자에게 있습니다.
      </p>
      <p>
        ③ 회원은 사이트에 게시물을 공유, 게시, 업로드함으로써 당사와 타 회원에게
        다음과 같은 라이선스를 부여합니다.
      </p>
      <ol>
        <li>
          회원은 당사에게 게시물을 사용할 권리, 즉 호스팅, 배포, 수정, 실행,
          복사, 공개적 이용 또는 표시, 당사와 연동을 지원하는 서비스 제공업체에
          대한 공유, 번역 및 게시물에 대한 2차적 저작물 제작이 가능한,
          비독점적이고 양도 가능하며, 로열티 없는 전 세계 통용 라이선스를
          부여합니다. 단, 당사는 서비스를 운영, 홍보 및 개선하기 위한 목적으로만
          게시물을 사용할 수 있습니다.
        </li>
        <li>
          회원은 타 회원에게 서비스에서 제공하는 기능을 통해 설정된 바에 따라
          해당 게시물을 복제 또는 배포할 수 있는 권리를 부여합니다. 명확성을
          위하여 부연하면, 타 회원은 서비스에서 제공하는 기능 외에 다른 방식으로
          해당 게시물을 사용할 수 없습니다.
        </li>
      </ol>
      <p>
        ④ 회원은 전항에 따라 당사 및 타 회원이 게시물을 사용하는데 필요한
        범위에서 본인의 저작인격권 행사를 포기하는 것에 합의합니다.
      </p>
      <p>
        ⑤ 회원의 게시물과 찬반 의사표시 등 서비스 이용에 관한 일련의 데이터는
        각종 통계 자료로 재가공 되어 당사의 유∙무료 서비스에 포함될 수 있습니다.
      </p>
      <p>
        ⑥ 회원은 본인의 게시물을 개별적으로 삭제하거나 계정을 삭제하여 게시물
        일체를 일괄적으로 삭제할 수 있으며, 삭제된 게시물은 더 이상 표시되지
        않습니다.
      </p>
      <h3>제17조 (타인의 저작권을 침해하는 게시물)</h3>
      <p>
        ① 회원의 게시물이 본인의 저작권 등을 침해한다고 주장하는 자(이하
        ‘권리주장자’)가 그 사실을 소명하여 당사에게 해당 게시물의 복제∙전송의
        중단을 요구한 경우, 당사는 즉시 그 게시물의 복제∙전송을 중단시키고
        권리주장자 및 해당 게시물의 작성 회원에게 복제∙전송의 중단 사실을
        통보해야 합니다.
      </p>
      <p>
        ② 전항의 통보를 받은 해당 게시물 작성 회원이 자신이 해당 게시물에 대한
        정당한 권리를 가지고 있음을 소명하여 게시물의 복구를 요구하는 경우,
        당사는 권리주장자에게 복구요구사실 및 복구예정일을 통보하고 그 예정일에
        게시물을 복구해야 합니다. 단, 권리주장자가 재개예정일 전에 해당 게시물
        작성에 대하여 소를 제기한 사실을 당사에 통보한 경우에는 그러하지
        아니합니다.
      </p>
      <p>
        ③ 당사는 본 조의 규정에 따른 게시물 복제∙전송의 중단 및 그 복구의 요구를
        받을 자를 지정하여 회원들이 쉽게 알 수 있도록 공지해야 합니다.
      </p>
      <h3>제18조 (공개 게시물의 삭제)</h3>
      <p>
        회원의 공개 게시물의 내용이 다음 각 호에 해당하는 경우, 당사는 해당 공개
        게시물을 삭제할 수 있으며, 해당 회원의 회원 자격을 제한, 정지, 상실시킬
        수 있습니다. 이 경우, 회원은 이의를 제기하거나 해당 공개 게시물을
        시정하는 방법으로 게시물의 복구를 요청할 수 있으며, 당사는 회원의 이의
        또는 시정 내용에 따라 게시물을 복구할 수 있습니다.
      </p>
      <ol>
        <li>음란 또는 청소년에게 유해한 게시물</li>
        <li>타 회원 또는 제3자의 명예를 훼손하거나 모욕하는 게시물</li>
        <li>타 회원 또는 제3자의 저작권 등 기타 권리를 침해하는 게시물</li>
        <li>도배, 광고, 홍보, 스팸성 게시물</li>
        <li>타인을 사칭하는 게시물</li>
        <li>기타 관계 법령에 위배된다고 판단되는 게시물</li>
      </ol>
      <h3>제19조 (유료 서비스의 이용)</h3>
      <p>
        ① 유료서비스의 이용요금을 결제한 후 당사가 제공하는 유료서비스를 이용할
        수 있습니다.
      </p>
      <p>
        ② 유료서비스의 내용, 이용방법, 이용료 및 기타 이용조건을 초기화면이나
        FAQ 등에 이용자가 알기 쉽게 표시합니다.
      </p>
      <p>
        ③ 유료서비스 이용에 관한 기본적인 사항과 결제된 이용대금에 대한
        청약철회, 해지로 인한 환불은 당사가 별도로 정한 유료서비스 정책, 관련
        법령에 따릅니다.
      </p>
      <h3>제20조 (이용요금 및 결제수단)</h3>
      <p>
        회원은 다음 각 호의 방법으로 유료 서비스 이용요금을 지급할 수 있습니다.
      </p>
      <ol>
        <li>직불카드, 신용카드 등 카드 결제</li>
        <li>당사가 제공하는 할인쿠폰, 이용권 등</li>
        <li>기타 당사가 정하는 결제방법</li>
      </ol>
      <h3>제21조 (이용요금의 과오납, 과소 청구와 정산)</h3>
      <p>
        이용자가 지급한 이용요금의 과오납 등 사유가 있는 경우, 당사는 다음 각
        호에 해당하는 조치를 취합니다.
      </p>
      <ol>
        <li>
          과다 납입한 이용요금에 대하여 회사는 과다 납입된 금액을 3영업일 이내에
          이용자에게 환급합니다.
        </li>
        <li>
          이용요금을 과소 청구한 경우, 당사는 이용자에게 과소 청구된 금액을 즉시
          청구합니다.
        </li>
      </ol>
      <h3>제22조 (유료 서비스의 청약철회 등)</h3>
      <p>
        당사가 제공하는 서비스는 전자상거래 등에서의 소비자보호에 관한 법률
        제17조 제2항 제5호의 용역 또는 디지털 콘텐츠에 해당하므로, 이용자는
        청약을 철회할 수 없습니다.
      </p>

      <h2>제5장 계약 당사자의 의무</h2>

      <h3>제23조 (당사의 의무)</h3>
      <p>
        ① 당사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는
        행위를 하지 않으며, 계속적이고 안정적인 서비스의 제공을 위하여 최선을
        다하여 노력합니다.
      </p>
      <p>
        ② 당사는 회원이 안전하게 서비스를 이용할 수 있도록 일체의 개인정보
        보호를 위한 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고
        준수합니다.
      </p>
      <h3>제24조 (회원의 의무)</h3>
      <p>
        ① 회원은 본 약관과 서비스의 개별 정책에 위반되는 행위를 하여서는 안
        됩니다.
      </p>
      <p>
        ② 회원은 서비스를 본래의 이용 목적 외의 용도로 사용하거나 다음 각 호에
        해당하는 행위를 해서는 안 됩니다.{" "}
      </p>
      <ol>
        <li>지식재산권을 포함한 타인의 권리를 침해하거나 위반하는 행위</li>
        <li>
          사이트에 바이러스 또는 악성 코드를 업로드 하는 등 사이트의 작동이나
          외관에 장애, 과부하 또는 손상을 가져올 수 있는 행위
        </li>
        <li>
          당사의 직원이나 운영자를 가장, 사칭하거나 타인의 명의를 도용하는 행위
        </li>
        <li>
          회사 및 제3자의 명예를 훼손하거나 업무를 방해하거나 회사 및 제3자에게
          손해를 가하는 행위
        </li>
        <li>
          회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을
          목적으로 서비스를 이용하는 행위
        </li>
        <li>
          기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에
          위배되는 행위
        </li>
      </ol>

      <h2>제6장 책임 및 손해배상</h2>

      <h3>제25조 손해배상 등</h3>
      <p>
        ① 당사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관계
        법령에 따라 손해를 배상할 책임을 가집니다.
      </p>
      <p>
        ② 당사는 관련 법령상 허용되는 한도 내에서 본 약관에 명시되지 않은 어떠한
        구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한 당사는 회원이
        작성하는 등 게시물에 대한 신뢰도, 정확성 등에 대해서도 보증을 하지
        않습니다.
      </p>
      <p>
        ③ 당사는 회사의 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을
        부담하지 않습니다.
      </p>
      <ol>
        <li>
          천재지변 또는 국가 정책 등 이에 준하는 불가항력의 상태에서 발생한 손해
        </li>
        <li>
          회원의 귀책사유로 인한 서비스 중지 또는 장애 등 서비스가 정상적으로
          제공되지 아니하여 발생한 손해
        </li>
        <li>
          당사가 이용하는 기간통신사업자가 당사의 귀책이 없음에도
          전기통신서비스를 중지하거나 정상적으로 제공하지 아니하는 경우
        </li>
        <li>
          제3자가 불법적으로 당사의 서버에 접속 또는 이용하거나, 당사 서버에
          대한 전송 또는 당사 서버로부터의 전송을 방해하거나, 악성 프로그램을
          전송 또는 유포하는 경우
        </li>
        <li>
          당사의 귀책없이 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해
        </li>
        <li>회원이 아닌 제3자가 서비스를 이용하는 과정에서 발생된 손해</li>
        <li>
          회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한
          경우
        </li>
        <li>
          당사가 무료로 제공하는 서비스의 이용. 단, 관련 법령에 규정이 없는
          경우에 한함
        </li>
        <li>
          회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성이
          문제가 되어 발생한 손해
        </li>
        <li>기타 당사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</li>
      </ol>
      <p>
        ④ 당사는 당사의 과실이 없는 한 회원과 회원 간에 발생한 분쟁에 대해서
        개입할 의무가 없으며, 이로 인한 손해를 배상할 책임이 없습니다.
      </p>
      <p>
        ⑤ 회원이 본 약관의 의무를 위반함으로 인해 당사에 손해를 입힌 경우 회원은
        당사에 대해 손해를 배상하여야 합니다.
      </p>
      <p>
        ⑥ 회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한
        행위로 당사가 당해 회원 외의 제3자로부터 손해배상청구 또는 소송 등 각종
        이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 당사를
        면책시켜야 하며, 당사가 면책되지 못한 경우 당해 회원은 그로 인하여
        당사에 발생한 모든 손해를 배상할 책임이 있습니다.
      </p>
      <h3>제26조 (준거법 및 재판관할)</h3>
      <p>
        ① 당사와 회원 간에 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에
        의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로
        합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
        거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </p>
      <p>② 당사와 회원 간에 제기된 소송에는 대한민국법을 준거법으로 합니다.</p>

      <p>시행일: 2021년 4월 16일</p>
      <p>최종 수정일: 2021년 4월 16일</p>
    </div>
  );
}
