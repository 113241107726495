/** @jsxImportSource @emotion/react */

import React, { ReactNode } from "react";
import { GRAY_DARKER } from "../../../constants/enums";
import oxo_cow_logo from "../../foundation/Navigation/icons/oxo_cow_logo.svg";
import oxo_logo from "../../foundation/Navigation/icons/oxo_new_logo.svg";
import { css } from "@emotion/react";

export default function PortraitPage({ children }: { children: ReactNode }) {
  return (
    <div
      css={css`
        width: 1000px;
        height: 1410px;
        page-break-before: always;
        page-break-after: always;
        padding: 60px 60px 60px 60px;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          flex-grow: 1;
          overflow: hidden;
        `}
      >
        {children}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <div style={{ padding: 5, paddingLeft: 10 }}>
            <img src={oxo_cow_logo} alt="oxo_cow_logo" />
          </div>
          <div>
            <img src={oxo_logo} alt="oxo_logo" />
          </div>
        </div>
        <div style={{ fontSize: 10, textAlign: "right", color: GRAY_DARKER }}>
          contact@oxopolitics.com
          <br />
          Copyright {new Date().getFullYear()} oxopolitics inc. All rights
          reserved.
        </div>
      </div>
    </div>
  );
}
