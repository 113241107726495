import styled from "@emotion/styled";

type TextPillProps = {
  backgroundColor: string;
};

const TextPill = styled.div`
  ${({ backgroundColor }: TextPillProps) => {
    return `
      font-size: 14px;
      color: white;
      border-radius: 12px;
      padding-top:2px;
      width: 60px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${backgroundColor};
    `;
  }}
`;

export default TextPill;
