import { Drawer } from "@material-ui/core";
import firebase from "firebase/compat";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProfileEdit from "../../../components/profile/profilePages/ProfileEdit";
import ProfileMain from "../../../components/profile/profilePages/ProfileMain";
import ProfileNotification from "../../../components/profile/profilePages/ProfileNotification";
import ProfileRanking from "../../../components/profile/profilePages/ProfileRanking";
import ProfileStatistics from "../../../components/profile/profilePages/ProfileStatistics";
import { PROFILE_SIDE_MENU_ITEMS } from "../../../constants/enums";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { listToMap, parseDoc } from "../../../utils/utils";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import whiteModeBackButton from "../../chat/imgs/white_mode_new_back.svg";
import OXOUserProfile from "../../profile/OXOUserProfile/OXOUserProfile";
import HashCommentCardDetails from "../../views/HashCardView/Comment/HashCommentCardDetails";
import HashPageView from "../../views/HashCardView/HashPageView";
import StanceTestResultsDetails from "../../views/HashCardView/StaceTest/StanceTestResultsDetails";
import StanceTestView from "../../views/HashCardView/StaceTest/StanceTestView";
import HashStoryDetails from "../../views/HashCardView/Story/HashStoryDetails";
import CardDetails from "../../views/Today/CardDetails";
import MiniOXOCardDetails from "../MiniCards/MiniOXOCardDetails";
import CardControls from "../DetailViews/CardControls";
import { removeFromIndex } from "../../../lib/search";
import { ROUTES } from "../../../constants/routes";
import { AlgoliaContext } from "../../views/SearchView/AlgoliaContext";
import PrivacyPolicy from "../../foundation/Privacy/PrivacyPolicy";
import TermsAndConditions from "../../foundation/Terms/TermsAndConditions";
import queryString from "querystring";
import WeeklyReviewDetails from "../WeeklyReview/WeeklyReviewDetails";
import ProfileHeader from "../../profile/profilePages/ProfileHeader";
import GenderAgeRegion from "../../views/OnboardingView/genderAgeRegion/GenderAgeRegion";
import { GlobalVariableContext } from "../../foundation/Contexts/GlobalVariableContext";
import MyProfile from "../../profile/profilePages/MyProfile";
import { getUserName } from "../../profile/OXOUserProfile/UserName";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";

const CARD_TYPES_FOR_TOPIC_DETAILS = [
  CardType.TOPIC,
  CardType.CONGRESSMAN,
  CardType.MEDIA,
  CardType.POLITICIAN,
  CardType.PRESIDENT,
  CardType.PARTY,
  CardType.PERSON,
  CardType.TEST_QUESTION,
  CardType.DEBATE,
  CardType.NEWS_TICKER,
  CardType.POST,
  CardType.REAL_NAME_POST
];

export default function UrlCardDrawerWrapper({
  remainingCardStack
}: {
  remainingCardStack?: string[];
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const [windowWidth, windowHeight] = useWindowSize();
  const [card, setCard] = useState<CardSchema>();
  const [isUser, setIsUser] = useState<boolean>();
  const global = useContext(GlobalVariableContext);

  const algoliaClient = useContext(AlgoliaContext);

  const trashClicked = () => {
    if (card && window.confirm(`"${card.title}"를 삭제하시겠습니까?`)) {
      if (algoliaClient) {
        removeFromIndex(algoliaClient, card.id);
      }
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(card.id)
        .delete();
      history.push(ROUTES.HOME);
    }
  };

  // cardId가 없어질 때에도 element가 없어지지 않도록 cache.
  const [detailsNode, setDetailsNode] = useState<ReactNode>();

  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = queryString.parse(location.search.replace("?", ""));
  const cardStack = urlSearchParams["cardStack"]
    ? urlSearchParams["cardStack"].toString().split("+")
    : [];

  // 3 cases:
  //   1. when loaded from URL ( remainingCardStack === undefined ) -> cardStack[0]
  //   2. when remaining is available ( remainingCardStack.length > 0 ) -> remainingCardStack[0]
  //   2. when remaining is empty ( remainingCardStack.length === 0 ) -> undefined cardId
  const currentCardStack = remainingCardStack || cardStack;
  const cardIdAndParams = currentCardStack[0];

  // Stack: CARD_ID~key:value~key:value+CARD_ID~key:value
  const [cardId, ...params] = cardIdAndParams?.split("~") || [undefined];
  const paramsMap: { [key: string]: string } = listToMap(
    params,
    param => param?.split(":")[0],
    param => param?.split(":")[1]
  );

  const wrapperWidth =
    cardId === PROFILE_SIDE_MENU_ITEMS.MAIN
      ? windowWidth >= 750
        ? 750
        : windowWidth
      : Math.min(750, windowWidth);

  const onScrollEvent = (e: any) => {
    const topFixBanner = document.getElementById("topFixBanner");
    if (topFixBanner) {
      if (e.target.scrollTop > 260) {
        topFixBanner.className = "topFixBannerShow";
      } else {
        topFixBanner.className = "topFixBannerHide";
      }
    }
  };

  const [backClicked, setBackClicked] = useState<boolean>(false);

  useEffect(() => {
    setBackClicked(false);
  }, [cardId]);

  useEffect(() => {
    if (cardId) {
      setCard(undefined);
      setIsUser(undefined);
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const card = parseDoc(snapshot) as CardSchema;
            if (
              card.type !== undefined &&
              card.type !== CardType.REAL_NAME_USER_SUPPORTING_CARD
            ) {
              setCard(card);
              return;
            }
          }
          firebase
            .firestore()
            .collection(FirestoreCollection.USER_PROFILE_METADATA)
            .doc(cardId)
            .get()
            .then(parseDoc)
            .then((doc: UserProfileMetadataSchema) => {
              setIsUser(!!doc.tribeId);
            });
        });
      return () => unsubscribe();
    }
  }, [cardId]);

  useEffect(() => {
    if (!card && isUser === undefined) {
      return;
    }

    if (cardId) {
      setDetailsNode(getDetailsPage());
    }
  }, [cardId, card, isUser]);

  if (!card && isUser === undefined) {
    return null;
  }

  const drawerContents = (
    <>
      <UrlCardDrawerWrapper remainingCardStack={currentCardStack.slice(1)} />
      <div
        style={{
          width: wrapperWidth,
          height: "100%",
          overflow: "auto"
        }}
      >
        {card?.id !== PROFILE_SIDE_MENU_ITEMS.MAIN &&
          !(
            cardStack?.toString().startsWith("profile_main") &&
            cardStack?.length == 2
          ) &&
          !cardId?.startsWith("CHAT_") &&
          !cardId?.startsWith("CHAT_") &&
          !cardId?.startsWith("TEST_") && (
            <div
              style={{
                width: wrapperWidth,
                position: "fixed",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
                backgroundColor: "white",
                zIndex: 100,
                height: 54
              }}
            >
              <div
                style={{
                  padding: "15px 20px 10px",
                  cursor: "pointer"
                }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!backClicked) {
                    history.go(-1);
                    setBackClicked(true);
                  }
                }}
              >
                <img
                  src={whiteModeBackButton}
                  width={10}
                  alt="whiteModeBackButton"
                />
              </div>
              <div>
                {card && (
                  <CardControls card={card} trashClicked={trashClicked} />
                )}
              </div>
            </div>
          )}
        <div
          style={{
            paddingTop: 50,
            height: "100%",
            overflow: "auto"
          }}
        >
          {detailsNode}
        </div>
      </div>
    </>
  );
  const onClose = () => {
    if (!backClicked) {
      history.go(-1);
      setBackClicked(true);
    }
  };
  return card &&
    (CARD_TYPES_FOR_TOPIC_DETAILS.includes(card.type) ||
      cardStack.toString().endsWith("politics")) ? (
    <Drawer
      onScroll={e => onScrollEvent(e)}
      onClose={onClose}
      open={!!cardId}
      anchor={"right"}
      style={{ overflow: "auto", height: "100%" }}
    >
      {drawerContents}
    </Drawer>
  ) : (
    <div />
  );

  function getDetailsPage() {
    if (cardId) {
      if (cardId.startsWith("Story_")) {
        return <HashStoryDetails hashtag={cardId.slice(6)} />;
      }
      if (cardId?.startsWith("TEST_")) {
        return <StanceTestView hashtag={cardId?.slice("TEST_".length)} />;
      }

      if (cardId === "TERMS_AND_CONDITIONS") {
        return <TermsAndConditions />;
      }
      if (cardId === "PRIVACY_POLICY") {
        return <PrivacyPolicy />;
      }
      if (cardId.startsWith("STANCE_TEST_RESULTS_")) {
        global?.setTestResultUrl(ROUTES.HOME);
        return (
          <StanceTestResultsDetails
            hashtag={cardId?.slice("STANCE_TEST_RESULTS_".length)}
          />
        );
      }
      if (cardId === "WEEKLY_REVIEW") {
        return <WeeklyReviewDetails />;
      }

      if (cardId === ROUTES.GENDER_AGE_REGION) {
        return <GenderAgeRegion />;
      }

      if (isUser) {
        return <OXOUserProfile onClose={onClose} profileUserId={cardId} />;
      } else if (card && card.type === CardType.OXO) {
        return <MiniOXOCardDetails cardId={cardId} />;
      } else if (card && card.type === CardType.DEBATE) {
        return <HashCommentCardDetails cardId={cardId} />;
      } else if (card && CARD_TYPES_FOR_TOPIC_DETAILS.includes(card.type)) {
        return (
          <CardDetails
            cardId={cardId}
            onClose={onClose}
            tab={paramsMap["tab"]}
            tribeId={paramsMap["tribeId"]}
          />
        );
      } else {
        switch (cardId) {
          case PROFILE_SIDE_MENU_ITEMS.MAIN:
            return (
              <>
                <ProfileHeader />
                <ProfileMain />
              </>
            );
          case PROFILE_SIDE_MENU_ITEMS.POLITICS:
            return (
              <>
                <ProfileHeader
                  title={
                    currentUserMetadata && currentUserId
                      ? getUserName(currentUserMetadata, currentUserId)
                      : ""
                  }
                />
                <MyProfile onClose={onClose} />
              </>
            );
          case PROFILE_SIDE_MENU_ITEMS.RANKING:
            return (
              <>
                <ProfileHeader title={"공감순위표"} />
                <ProfileRanking />
              </>
            );
          case PROFILE_SIDE_MENU_ITEMS.STATISTICS:
            return (
              <>
                <ProfileHeader title={"통계"} />
                <ProfileStatistics />
              </>
            );
          case PROFILE_SIDE_MENU_ITEMS.NOTIFICATION:
            return (
              <>
                <ProfileHeader title={"알림"} />
                <ProfileNotification />
              </>
            );
          case PROFILE_SIDE_MENU_ITEMS.SETTING:
            return (
              <>
                <ProfileHeader title={"설정"} />
                <ProfileEdit />
              </>
            );
          default:
            return <HashPageView hashtag={cardId} />;
        }
      }
    }
  }
}
