/** @jsxImportSource @emotion/react */

import React, { useContext } from "react";
import { UserPrivilege } from "../../../../constants/firestore_schema";
import {
  BACKGROUND_COLOR,
  GRAY,
  GRAY_LIGHT,
  TRIBE_INFO_BY_ID,
  UserAnswer
} from "../../../../constants/enums";
import UserProfilePhoto from "../../StatsView/UserProfilePhoto";
import { TribeExpression } from "../../../../constants/expressions";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import UserName from "../../../profile/OXOUserProfile/UserName";

export default function FakeCommentInput({ answer }: { answer?: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        width: "100%"
      }}
    >
      <div
        style={{
          backgroundColor:
            currentUserMetadata &&
            currentUserMetadata.privileges &&
            currentUserMetadata.privileges.includes(
              UserPrivilege.REAL_NAME_USER
            )
              ? BACKGROUND_COLOR
              : currentUserMetadata?.tribeId
              ? TRIBE_INFO_BY_ID[currentUserMetadata?.tribeId].bgColor
              : "black",
          borderRadius: "50%",
          padding: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <UserProfilePhoto
          tribeId={currentUserMetadata?.tribeId}
          width={"22px"}
          expression={
            answer === UserAnswer.O
              ? TribeExpression.HAPPY
              : answer === UserAnswer.X
              ? TribeExpression.ANGRY
              : TribeExpression.NORMAL
          }
        />
      </div>
      <div
        style={{
          fontSize: 14,
          color: GRAY_LIGHT,
          flex: 1,
          width: "100%",
          paddingLeft: 3,
          paddingBottom: 2,
          marginLeft: 5,
          borderBottom: "solid 1px " + GRAY
        }}
      >
        <UserName profileUserId={currentUserId} enableLink={false} />
        님의 의견을 들려주세요.
      </div>
    </div>
  );
}
