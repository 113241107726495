import {
  CardSchema,
  FirestoreCollection,
  NotificationSubscriptionType,
  NotificationType,
  UserNotificationItemSchema,
  UserNotificationSubscriptionSchema
} from "../constants/firestore_schema";
import { parseDoc, truncateLongSentence } from "../utils/utils";
import { getUserName } from "../components/profile/OXOUserProfile/UserName";
import firebase from "firebase/compat";
import { sendPushToUser } from "./notification_cloud_lib";

const MAX_COMMENT_CUTOFF = 30;

export function addNewQuestionNotification(
  questionId: string,
  title: string,
  userId: string
) {
  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATIONS)
    .doc(userId + questionId + NotificationType.NEW_QUESTION)
    .set(
      {
        type: NotificationType.NEW_QUESTION,
        newQuestionNotification: {
          questionId: questionId,
          title: title
        },
        createdAt: new Date().getTime(),
        userId: userId,
        seen: false,
        notified: false
      } as UserNotificationItemSchema,
      { merge: true }
    );
}

export function addCommentLikeNotification(
  commentId: string,
  commentQuestionId: string,
  userId: string,
  likeCount: number,
  currentUserId: string
) {
  const notification: UserNotificationItemSchema = {
    type: NotificationType.COMMENT_LIKE,
    likeUserNotification: {
      commentId: commentId,
      questionId: commentQuestionId,
      likes: likeCount,
      likeUserId: currentUserId
    },
    seen: false,
    createdAt: new Date().getTime(),
    notified: false,
    userId: userId
  };
  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATIONS)
    .doc(userId + commentId + NotificationType.COMMENT_LIKE)
    .set(notification)
    .then(() => {
      sendPushUserNotification(notification);
    });
}

export function sendPushUserNotification(
  notification: UserNotificationItemSchema
) {
  let notificationType: NotificationSubscriptionType;
  switch (notification.type) {
    case NotificationType.POST_ANSWER:
      notificationType = NotificationSubscriptionType.ANSWER;
      break;
    case NotificationType.POST_COMMENT:
      notificationType = NotificationSubscriptionType.COMMENT;
      break;
    default:
      notificationType = NotificationSubscriptionType.LIKE;
      break;
  }

  firebase
    .firestore()
    .collection(FirestoreCollection.USER_NOTIFICATION_SUBSCRIPTIONS)
    .doc(notification.userId + notificationType)
    .get()
    .then(parseDoc)
    .then(
      (userNotificationSubscription: UserNotificationSubscriptionSchema) => {
        if (userNotificationSubscription.isSubscribed) {
          getNotificationString(notification).then(notificationString => {
            if (notificationString) {
              sendPushToUser(
                notification.type === NotificationType.COMMENT_LIKE
                  ? "댓글 공감"
                  : notification.type === NotificationType.POST_COMMENT
                  ? "생각 댓글"
                  : notification.type === NotificationType.POST_ANSWER
                  ? "생각 응답"
                  : notification.type === NotificationType.POST_LIKE
                  ? "생각 공감"
                  : "옥소폴리틱스",

                notificationString,
                notification.userId,
                window.alert
              );
            }
          });
        }
      }
    );
}

export function addPostLikeNotification(
  post: CardSchema,
  newLikeUserIds: string[],
  currentUserId: string
) {
  const notification: UserNotificationItemSchema = {
    type: NotificationType.POST_LIKE,
    postLikeUserNotification: {
      postId: post.id,
      likes: newLikeUserIds.length,
      likeUserId: currentUserId
    },
    seen: false,
    createdAt: new Date().getTime(),
    notified: false,
    userId: post.createdBy
  };
  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATIONS)
    .doc(post.createdBy + post.id + NotificationType.POST_LIKE)
    .set(notification)
    .then(() => {
      sendPushUserNotification(notification);
    });
}

export function addPostAnswerNotification(
  post: CardSchema,
  answerCount: number
) {
  const notification: UserNotificationItemSchema = {
    type: NotificationType.POST_ANSWER,
    postAnswerNotification: {
      postId: post.id,
      answers: answerCount
    },
    seen: false,
    createdAt: new Date().getTime(),
    notified: false,
    userId: post.createdBy
  };
  firebase
    .firestore()
    .collection(FirestoreCollection.NOTIFICATIONS)
    .doc(post.createdBy + post.id + NotificationType.POST_ANSWER)
    .set(notification)
    .then(() => {
      sendPushUserNotification(notification);
    });
}

export function addPostCommentNotification(
  cardId: string,
  currentUserId: string
) {
  firebase
    .firestore()
    .collection(FirestoreCollection.CARDS)
    .doc(cardId)
    .get()
    .then(parseDoc)
    .then((card: CardSchema) => {
      const notification = {
        type: NotificationType.POST_COMMENT,
        postCommentUserNotification: {
          postId: card.id,
          commentUserId: currentUserId
        },
        seen: false,
        createdAt: new Date().getTime(),
        notified: false,
        userId: card.createdBy
      };
      firebase
        .firestore()
        .collection(FirestoreCollection.NOTIFICATIONS)
        .doc(card.createdBy + card.id + NotificationType.POST_COMMENT)
        .set(notification)
        .then(() => {
          sendPushUserNotification(notification);
        });
    });
}

export function showNotification(title: string, body: string, logo: string) {
  // @ts-ignore
  const cordova = window.cordova;

  if (cordova) {
    // cordova.plugins.notification.local.schedule({
    //   title: title,
    //   text: body,
    //   foreground: true
    // });
    return;
  }

  if (!Notification) {
    return;
  }

  if (
    Notification.permission !== "granted" &&
    Notification.permission !== "denied"
  ) {
    Notification.requestPermission().then(permission => {
      firebase.analytics().logEvent("notification_permission_1", {
        event_category: "notification",
        event_label: permission
      });

      if (permission === "granted") {
        const notification = new Notification(title, {
          icon: logo,
          body: body
        });

        notification.onclick = function () {
          window.open("https://oxopolitics.com/notifications");
        };
      }
    });
  } else if (Notification.permission === "granted") {
    const notification = new Notification(title, {
      icon: logo,
      body: body
    });

    notification.onclick = function () {
      window.open("https://oxopolitics.com/notifications");
    };
  }
}

export function getNotificationString(
  userNotification: UserNotificationItemSchema
): Promise<string | undefined> {
  switch (userNotification.type) {
    case NotificationType.COMMENT_LIKE:
      if (userNotification.likeUserNotification?.commentId) {
        const commentPromise = firebase
          .firestore()
          .collection(FirestoreCollection.COMMENTS)
          .doc(userNotification.likeUserNotification?.commentId)
          .get()
          .then(parseDoc);

        const likeUserProfilePromise = firebase
          .firestore()
          .collection(FirestoreCollection.USER_PROFILE_METADATA)
          .doc(userNotification.likeUserNotification.likeUserId)
          .get()
          .then(parseDoc);

        return Promise.all([commentPromise, likeUserProfilePromise]).then(
          ([comment, likeUserProfile]) => {
            return `${
              userNotification.likeUserNotification &&
              getUserName(
                likeUserProfile,
                userNotification.likeUserNotification.likeUserId
              )
            } 님이 ${
              comment.comment
                ? '"' +
                  truncateLongSentence(comment.comment, MAX_COMMENT_CUTOFF) +
                  '"'
                : "삭제된 "
            }  댓글을 공감했습니다.`;
          }
        );
      }
      break;
    case NotificationType.POST_LIKE:
      if (userNotification.postLikeUserNotification) {
        const postPromise = firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(userNotification.postLikeUserNotification.postId)
          .get()
          .then(parseDoc);

        const likeUserProfilePromise = firebase
          .firestore()
          .collection(FirestoreCollection.USER_PROFILE_METADATA)
          .doc(userNotification.postLikeUserNotification.likeUserId)
          .get()
          .then(parseDoc);

        return Promise.all([postPromise, likeUserProfilePromise]).then(
          ([post, likeUserProfile]) => {
            return `${
              userNotification.likeUserNotification &&
              getUserName(
                likeUserProfile,
                userNotification.likeUserNotification.likeUserId
              )
            } 님이 ${
              post
                ? '"' +
                  truncateLongSentence(post.title, MAX_COMMENT_CUTOFF) +
                  '"'
                : "삭제된 "
            } 생각을 공감했습니다.`;
          }
        );
      }
      break;
    case NotificationType.POST_ANSWER:
      if (userNotification.postAnswerNotification) {
        return firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(userNotification.postAnswerNotification.postId)
          .get()
          .then(parseDoc)
          .then(post => {
            return `${
              post
                ? '"' +
                  truncateLongSentence(post.title, MAX_COMMENT_CUTOFF) +
                  '"'
                : "삭제된 "
            } 생각이 ${
              userNotification.postAnswerNotification?.answers
            }개의 응답을 받았습니다.`;
          });
      }
      break;
    case NotificationType.POST_COMMENT:
      if (userNotification.postCommentUserNotification) {
        const postPromise = firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(userNotification.postCommentUserNotification.postId)
          .get()
          .then(parseDoc);

        const likeUserProfilePromise = firebase
          .firestore()
          .collection(FirestoreCollection.USER_PROFILE_METADATA)
          .doc(userNotification.postCommentUserNotification.commentUserId)
          .get()
          .then(parseDoc);

        return Promise.all([postPromise, likeUserProfilePromise]).then(
          ([post, likeUserProfile]) => {
            return `${
              userNotification.postCommentUserNotification &&
              getUserName(
                likeUserProfile,
                userNotification.postCommentUserNotification.commentUserId
              )
            } 님이 ${
              post
                ? '"' +
                  truncateLongSentence(post.title, MAX_COMMENT_CUTOFF) +
                  '"'
                : "삭제된 "
            } 생각에 댓글을 달았습니다.`;
          }
        );
      }
      break;
    case NotificationType.NEW_QUESTION:
      if (userNotification.newQuestionNotification) {
        return new Promise((resolutionFunc, rejectionFunc) => {
          resolutionFunc(
            `"${userNotification.newQuestionNotification?.title}" 토픽이 추가되었습니다. 의견을 들려주세요.`
          );
        });
      }
      break;
  }
  return new Promise((resolutionFunc, rejectionFunc) => {
    rejectionFunc("Not a suitable type.");
  });
}
