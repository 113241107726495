import React, { CSSProperties } from "react";
import { oxoColors, UserAnswer } from "../../../../constants/enums";

export default function MiniAnswerTag({
  answer,
  style
}: {
  answer: string | undefined;
  style?: CSSProperties;
}) {
  return (
    <div
      style={{
        height: 16,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        fontSize: 10,
        padding: 0,
        color: "white",
        fontWeight: "bold",
        backgroundColor:
          answer === UserAnswer.O
            ? oxoColors.O
            : answer === UserAnswer.X
            ? oxoColors.X
            : answer === UserAnswer.DUNNO
            ? oxoColors.DUNNO
            : "none",
        ...(style ? style : {})
      }}
    >
      {answer === UserAnswer.O ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          좋아요
        </div>
      ) : answer === UserAnswer.X ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          싫어요
        </div>
      ) : answer === UserAnswer.DUNNO ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          글쎄요
        </div>
      ) : null}
    </div>
  );
}
