/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { DATA_PLATFORM_RED_GRAY } from "../../../constants/data_platform_colors";
import { VictoryAxis, VictoryChart, VictoryLine } from "victory";
import { DailyReportsDataSchema } from "./useDailyReportsDataForHashtag";
import {
  CardAnswerTribeStatsSchema,
  CardSchema
} from "../../../constants/firestore_schema";
import { groupBy, mapValues, removeIdKey } from "../../../utils/utils";
import {
  DAYS,
  formatKoreanDateIso,
  todayInKorea
} from "../../../utils/datetime";
import { ChartDataSchema } from "./HashtagPopularityDetails";
import { MiddleCardBox } from "../../foundation/CardBox/MiddleCardBox.styled";
import { PoliticalTribe } from "./TribeStatsSelector";
import { getYRange } from "../../../utils/data_utils";

export default function HashtagCommentsPopularityChart({
  hashtag,
  selectedDate,
  selectedTribe,
  dailyReportsData
}: {
  hashtag: string;
  selectedDate: number;
  selectedTribe: string;
  dailyReportsData: DailyReportsDataSchema;
}) {
  const [data, setData] = useState<ChartDataSchema[]>();
  const [isNull, setIsNull] = useState(false);

  const { cards, cardAnswerTribeStatsMap } = dailyReportsData;

  useEffect(() => {
    if (cards && cards.length && cardAnswerTribeStatsMap) {
      const sortedCards = cards.sort(
        (cardA: CardSchema, cardB: CardSchema) =>
          (cardA.featuredOnNewsTimelineAt || cardA.createdAt) -
          (cardB.featuredOnNewsTimelineAt || cardB.createdAt)
      );

      const cardsPerDay = groupBy(sortedCards, card => {
        return formatKoreanDateIso(
          todayInKorea(card.featuredOnNewsTimelineAt || card.createdAt)
        );
      });

      const oAndCounts = mapValues(cardsPerDay, cards =>
        cards.reduce(
          (p, card) => {
            if (selectedTribe === "AVERAGE") {
              const idKeyRemoved: CardAnswerTribeStatsSchema = removeIdKey(
                cardAnswerTribeStatsMap[card.id]
              );
              if (!idKeyRemoved) {
                return p;
              }
              const averagedCounts = Object.values(idKeyRemoved).reduce(
                (result, answerCount) => {
                  const rate = (answerCount.o || 0) / (answerCount.count || 1);
                  return [result[0] + rate, result[1] + 1];
                },
                [0, 0]
              );

              return [p[0] + averagedCounts[0], p[1] + averagedCounts[1]];
            }

            return [
              cardAnswerTribeStatsMap[card.id]?.[
                PoliticalTribe[selectedTribe].code
              ]?.o || 0,
              cardAnswerTribeStatsMap[card.id]?.[
                PoliticalTribe[selectedTribe].code
              ]?.count || 0
            ];
          },
          [0, 0]
        )
      );
      let prev = [0, 0];
      const testArr = Object.keys(oAndCounts).map(date => {
        const positiveCount = prev[0] + oAndCounts[date][0] || 0;
        const allCount = prev[1] + oAndCounts[date][1] || 1;
        const result = {
          x: date,
          y: (positiveCount / allCount) * 100
        };
        prev = [positiveCount, allCount];
        return result;
      });

      if (testArr.length === 1) {
        const firstDay = formatKoreanDateIso(
          todayInKorea(selectedDate - 14 * DAYS)
        );
        if (!oAndCounts[firstDay]) {
          testArr.unshift({
            x: firstDay,
            y: testArr[0].y
          });
        }
        const lastDay = formatKoreanDateIso(todayInKorea(selectedDate));
        if (!oAndCounts[lastDay]) {
          testArr.push({
            x: lastDay,
            y: testArr[0].y
          });
        }
        oAndCounts[lastDay];
      }

      const weeklyStatsData: { x: string; y: number }[] = [];
      for (let i = -6; i <= 0; i++) {
        const day = formatKoreanDateIso(todayInKorea(selectedDate + i * DAYS));

        const todayData = {
          x: day,
          y: 0
        };

        testArr.forEach(data => {
          if (data.x <= day) {
            todayData.y = data.y;
          }
        });
        weeklyStatsData.push(todayData);
      }

      setData(weeklyStatsData);
      setIsNull(false);
    }
  }, [cards, cardAnswerTribeStatsMap, selectedTribe]);

  const dataDiff =
    (data &&
      [data[0], data[data.length - 1]].reduce((diff, dataB, i) => {
        return diff + dataB.y * (i % 2 === 0 ? -1 : 1);
      }, 0)) ||
    0;

  const range = getYRange(data);

  if (!cards || !cardAnswerTribeStatsMap) {
    return <MiddleCardBox />;
  }

  if (!data) {
    setIsNull(true);
    setData([
      { x: "2021-10-09", y: 50 },
      { x: "2021-10-11", y: 50 },
      { x: "2021-10-13", y: 50 },
      { x: "2021-10-15", y: 50 }
    ]);
  }

  return (
    <div>
      {isNull ? (
        <div
          css={css`
            filter: blur(2px);
            -webkit-filter: blur(2px);
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-bottom: 14px;
            `}
          >
            {data && (
              <div
                css={css`
                  width: 370px;
                  height: 180px;
                `}
              >
                <div
                  css={css`
                    font-size: 14px;
                    width: 10px;
                    height: 10px;
                    margin-bottom: -18px;
                    margin-left: 25px;
                  `}
                >
                  (%)
                </div>
                <VictoryChart width={560}>
                  <VictoryLine
                    categories={{ x: data.map(el => el.x) }}
                    style={{
                      data: { stroke: "#121212", strokeWidth: 2 }
                    }}
                    data={data}
                    domain={{ y: range }}
                  />
                  <VictoryAxis
                    standalone={false}
                    crossAxis
                    fixLabelOverlap
                    style={{
                      axis: { stroke: "#b0b0b0" },
                      tickLabels: { fontSize: 22, fill: "#616161" }
                    }}
                  />
                  <VictoryAxis
                    standalone={false}
                    dependentAxis
                    style={{
                      axis: { stroke: "#b0b0b0" },
                      tickLabels: { fontSize: 22, fill: "#616161" }
                    }}
                  />
                </VictoryChart>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 14px;
          `}
        >
          {data && (
            <div
              css={css`
                width: 370px;
                height: 180px;
              `}
            >
              <div
                css={css`
                  font-size: 14px;
                  width: 10px;
                  height: 10px;
                  margin-bottom: -18px;
                  margin-left: 25px;
                `}
              >
                (%)
              </div>
              <VictoryChart width={560}>
                <VictoryLine
                  categories={{ x: data.map(el => el.x) }}
                  style={{
                    data: { stroke: "#121212", strokeWidth: 2 }
                  }}
                  data={data}
                  domain={{ y: range }}
                />
                <VictoryAxis
                  standalone={false}
                  crossAxis
                  fixLabelOverlap
                  style={{
                    axis: { stroke: "#b0b0b0" },
                    tickLabels: { fontSize: 22, fill: "#616161" }
                  }}
                />
                <VictoryAxis
                  standalone={false}
                  dependentAxis
                  style={{
                    axis: { stroke: "#b0b0b0" },
                    tickLabels: { fontSize: 22, fill: "#616161" }
                  }}
                />
              </VictoryChart>
            </div>
          )}
        </div>
      )}

      <div
        css={css`
          color: ${dataDiff >= 0
            ? DATA_PLATFORM_RED_GRAY
            : DATA_PLATFORM_RED_GRAY};
          font-size: 26px;
          font-weight: 800;
          text-align: center;
          margin-top: 0px;
        `}
      >
        {(dataDiff >= 0 ? "+" : "") + dataDiff.toFixed(2)}
        <span
          css={css`
            color: #000000;
            font-size: 12px;
          `}
        >
          <span
            css={css`
              margin-left: 3px;
            `}
          >
            % (최근 7일)
          </span>
        </span>
      </div>

      <div
        css={css`
          margin-top: 5px;
          font-size: 12px;
          color: #000000;
          text-align: center;
        `}
      >
        {isNull
          ? "데이터가 아직 없습니다."
          : "각 발언 당 지지율을 누적 합산한 결과입니다"}
      </div>
    </div>
  );
}
