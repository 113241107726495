/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_GRAY_COLOR,
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_COLOR
} from "../../../constants/data_platform_colors";
import { VictoryAxis, VictoryChart, VictoryLine } from "victory";
import { DailyReportsDataSchema } from "./useDailyReportsDataForHashtag";
import {
  CardAnswerTribeStatsSchema,
  CardSchema
} from "../../../constants/firestore_schema";
import { groupBy, mapValues, removeIdKey } from "../../../utils/utils";
import {
  DAYS,
  formatKoreanDateIso,
  todayInKorea
} from "../../../utils/datetime";
import { ChartDataSchema } from "./HashtagPopularityDetails";
import { MiddleCardBox } from "../../foundation/CardBox/MiddleCardBox.styled";
import { PoliticalTribe } from "./TribeStatsSelector";
import { getYRange } from "../../../utils/data_utils";

export default function PopularityChartForDashboard({
  hashtag,
  selectedDate,
  selectedTribe,
  dailyReportsData
}: {
  hashtag: string;
  selectedDate: number;
  selectedTribe: string;
  dailyReportsData: DailyReportsDataSchema;
}) {
  const [data, setData] = useState<ChartDataSchema[]>();

  const { cards, cardAnswerTribeStatsMap } = dailyReportsData;

  useEffect(() => {
    if (cards && cards.length && cardAnswerTribeStatsMap) {
      const sortedCards = cards.sort(
        (cardA: CardSchema, cardB: CardSchema) =>
          (cardA.featuredOnNewsTimelineAt || cardA.createdAt) -
          (cardB.featuredOnNewsTimelineAt || cardB.createdAt)
      );

      const cardsPerDay = groupBy(sortedCards, card => {
        return formatKoreanDateIso(
          todayInKorea(card.featuredOnNewsTimelineAt || card.createdAt)
        );
      });

      const oAndCounts = mapValues(cardsPerDay, cards =>
        cards.reduce(
          (p, card) => {
            if (selectedTribe === "AVERAGE") {
              const idKeyRemoved: CardAnswerTribeStatsSchema = removeIdKey(
                cardAnswerTribeStatsMap[card.id]
              );
              if (!idKeyRemoved) {
                return p;
              }
              const averagedCounts = Object.values(idKeyRemoved).reduce(
                (result, answerCount) => {
                  const rate = (answerCount.o || 0) / (answerCount.count || 1);
                  return [result[0] + rate, result[1] + 1];
                },
                [0, 0]
              );

              return [p[0] + averagedCounts[0], p[1] + averagedCounts[1]];
            }

            console.log(cardAnswerTribeStatsMap[card.id]);

            return [
              cardAnswerTribeStatsMap[card.id]?.[
                PoliticalTribe[selectedTribe].code
              ]?.o || 0,
              cardAnswerTribeStatsMap[card.id]?.[
                PoliticalTribe[selectedTribe].code
              ]?.count || 0
            ];
          },
          [0, 0]
        )
      );
      let prev = [0, 0];
      const testArr = Object.keys(oAndCounts).map(date => {
        const positiveCount = prev[0] + oAndCounts[date][0] || 0;
        const allCount = prev[1] + oAndCounts[date][1] || 1;
        const result = {
          x: date,
          y: (positiveCount / allCount) * 100
        };
        prev = [positiveCount, allCount];
        return result;
      });

      if (testArr.length === 1) {
        const firstDay = formatKoreanDateIso(
          todayInKorea(selectedDate - 14 * DAYS)
        );
        if (!oAndCounts[firstDay]) {
          testArr.unshift({
            x: firstDay,
            y: testArr[0].y
          });
        }
        const lastDay = formatKoreanDateIso(todayInKorea(selectedDate));
        if (!oAndCounts[lastDay]) {
          testArr.push({
            x: lastDay,
            y: testArr[0].y
          });
        }
        oAndCounts[lastDay];
      }

      const weeklyStatsData: { x: string; y: number }[] = [];
      for (let i = -6; i <= 0; i++) {
        const day = formatKoreanDateIso(todayInKorea(selectedDate + i * DAYS));

        const todayData = {
          x: day,
          y: 0
        };

        testArr.forEach(data => {
          if (data.x <= day) {
            todayData.y = data.y;
          }
        });

        weeklyStatsData.push(todayData);
      }

      setData(weeklyStatsData);
    }
  }, [cards, cardAnswerTribeStatsMap, selectedTribe]);

  const dataDiff =
    (data &&
      [data[0], data[data.length - 1]].reduce((diff, dataB, i) => {
        return diff + dataB.y * (i % 2 === 0 ? -1 : 1);
      }, 0)) ||
    0;

  const range = getYRange(data);

  if (!cards || !cardAnswerTribeStatsMap) {
    return <MiddleCardBox />;
  }

  return (
    <div>
      <div
        css={css`
          width: 160px;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div
          css={css`
            color: ${dataDiff >= 0
              ? DATA_PLATFORM_GREEN_COLOR
              : DATA_PLATFORM_RED_COLOR};
            font-size: 20px;
            font-weight: 800;
            text-align: center;
            margin-top: 23px;
          `}
        >
          {(dataDiff >= 0 ? "▲ " : "▼ ") + dataDiff.toFixed(2)}
          <span
            css={css`
              color: #121212;
              font-size: 14px;
            `}
          >
            <span
              css={css`
                margin-left: 3px;
              `}
            >
              %
            </span>
          </span>
        </div>

        {data && (
          <div
            css={css`
              width: 140px;
              height: 120px;
              margin-top: -26px;
            `}
          >
            <VictoryChart>
              <VictoryLine
                height={120}
                categories={{ x: data.map(el => el.x) }}
                style={{
                  data: {
                    stroke:
                      dataDiff >= 0
                        ? DATA_PLATFORM_GREEN_COLOR
                        : DATA_PLATFORM_RED_COLOR,
                    strokeWidth: 4
                  }
                }}
                data={data}
                domain={{ y: range }}
              />
              <VictoryAxis
                standalone={false}
                crossAxis
                fixLabelOverlap
                style={{
                  axis: { opacity: 0 },
                  tickLabels: { opacity: 0 }
                }}
              />
            </VictoryChart>
          </div>
        )}
      </div>
    </div>
  );
}
