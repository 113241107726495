/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { getDocsFromSnapshot } from "../../../utils/utils";
import HashCommentBubble from "../HashCardView/common/HashCommentBubble";
import { css } from "@emotion/react";

export function CommunityToday({
  dayOnset,
  dayEnd
}: {
  dayOnset: number;
  dayEnd?: number;
}) {
  const [cards, setCards] = useState<CardSchema[]>();

  useEffect(() => {
    setCards(undefined);

    const query = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [CardType.POST])
      .where("createdAt", ">=", dayOnset);

    const queryWithEnd = dayEnd ? query.where("createdAt", "<", dayEnd) : query;

    queryWithEnd.onSnapshot(snapshot => {
      setCards(getDocsFromSnapshot(snapshot));
    });
  }, [dayOnset, dayEnd]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      {cards
        ?.sort((cardA, cardB) => (cardB.comments || 0) - (cardA.comments || 0))
        .map(card => (
          <div
            css={css`
              margin-top: 10px;
            `}
            key={card.id}
          >
            <HashCommentBubble cardId={card.id} key={card.id} />
          </div>
        ))}
    </div>
  );
}
