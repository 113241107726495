/** @jsxImportSource @emotion/react */

import React from "react";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import {
  CardSchema,
  FirestoreCollection,
  UserVerificationSchema
} from "../../../constants/firestore_schema";

export default function UserDetailedStats({ userId }: { userId: string }) {
  const userVerification = useFirestoreGetDocument<UserVerificationSchema>(
    FirestoreCollection.USER_VERIFICATION,
    userId
  );
  const userJurisdiction = useFirestoreGetDocument<CardSchema>(
    FirestoreCollection.CARDS,
    userVerification?.jurisdictionId
  );

  return (
    <div>
      {userVerification?.userBioInfo?.birthYear}{" "}
      {userVerification?.userBioInfo?.gender === "male"
        ? "남"
        : userVerification?.userBioInfo?.gender === "female"
        ? "여"
        : ""}{" "}
      {userJurisdiction?.jurisdictionMetadata?.locales["ko"]?.fullAddress
        .split(" ")
        .slice(4)
        .join(" ")}
    </div>
  );
}
