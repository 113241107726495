/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardFeatureStatus,
  CardSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  parseDoc,
  removeIdKey,
  sumTwoAnswerCounts
} from "../../utils/utils";
import { DATA_PLATFORM_RED_GRAY } from "../../constants/data_platform_colors";
import { css } from "@emotion/react";
import useFirestoreLiveDocument from "../../utils/hooks/useFirestoreLiveDocument";
import CardAnswerPieChartForReport from "../views/HashCardView/Comment/CardAnswerPieChartForReport";
import MentionAgeChartForReport from "../views/MentionResponseView/MentionAgeChartForReport";

export const CSS_CHART_DIV = css`
  margin-right: 5px;
`;
export default function CardAnswerDailyReportChart({
  supporterData,
  personHashtag,
  addMz = false,
  competitorHashtag,
  text = true
}: {
  personHashtag: string;
  supporterData?: {
    [personHashtag: string]: {
      [answer: string]: string[];
    };
  };
  addMz?: boolean;
  competitorHashtag: string;
  text?: boolean;
}) {
  const [lastFocusCard, setLastFocusCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("featuredOnTodayFocus", "==", CardFeatureStatus.ON)
      .where("featuredOnTodayFocusAt", "<", new Date().valueOf())
      .orderBy("featuredOnTodayFocusAt", "desc")
      .limit(1)
      .get()
      .then(cards => {
        const card = getDocsFromSnapshot(cards)[0];
        setLastFocusCard(card);
      });
    return () => {};
  }, []);

  const [cardAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    lastFocusCard?.id
  );

  const [cardAnswerTribeStats, setCardAnswerTribeStats] =
    useState<CardAnswerTribeStatsSchema>();
  useEffect(() => {
    if (lastFocusCard?.id) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(lastFocusCard?.id)
        .onSnapshot(snapshot => {
          setCardAnswerTribeStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [lastFocusCard?.id]);

  const [cardAnswerAgeGenderState] = addMz
    ? useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
        FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
        lastFocusCard?.id
      )
    : [undefined];

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState || !addMz) {
      return;
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      let ageGroupNumber = Math.floor(
        (Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10) /
          40
      );

      if (ageGroupNumber > 1) {
        ageGroupNumber = 1;
      }

      const ageGroup = ageGroupNumber.toFixed();

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];
      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            (prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema,
            (birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState, addMz]);

  const mzAnswerCounts = useMemo(() => {
    if (!statsByAgeGroup) {
      return undefined;
    }
    return sumTwoAnswerCounts(
      statsByAgeGroup[0]["male"],
      statsByAgeGroup[0]["female"]
    );
  }, [statsByAgeGroup]);

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          display: flex;
          width: 100%;
          height: 220px;
          align-items: flex-end;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 440px;
          `}
        >
          {text && (
            <div
              css={css`
                width: 380px;
                height: 100px;
                margin-left: 40px;
                font-weight: 700;
                font-size: 18px;
                line-height: 27px;
                color: ${DATA_PLATFORM_RED_GRAY};
                filter: gray;
                -webkit-filter: grayscale(100%);
              `}
            >
              Q. {lastFocusCard?.title}
            </div>
          )}
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            {lastFocusCard && (
              <MentionAgeChartForReport
                cardId={lastFocusCard?.id}
                isAgree={true}
                average={false}
                personHashtag={personHashtag}
                competitorHashtag={competitorHashtag}
              />
            )}
          </div>
        </div>
        <div
          css={css`
            width: 440px;
          `}
        >
          {lastFocusCard && (
            <CardAnswerPieChartForReport
              cardId={lastFocusCard?.id}
              showPercentage={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
