/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getStatTds } from "./shared";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";

export default function TribeStatsTds({ cardId }: { cardId: string }) {
  const cardAnswerTribeStats =
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <>
      {["1", "2", "3", "4", "5"].map(tribeId => {
        const cardAnswerTribeStat = cardAnswerTribeStats[tribeId];
        return getStatTds(cardAnswerTribeStat, tribeId + cardId);
      })}
    </>
  );
}
