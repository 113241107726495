/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CommentSchema,
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../../constants/firestore_schema";
import { css } from "@emotion/react";
import { parseDoc, truncateLongSentence } from "../../../../utils/utils";
import { GRAY_EXTRA_DARK, TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import firebase from "firebase/compat";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";

export default function SingleLineCommentForPortrait({
  commentId,
  disableExpressions,
  truncate = 100
}: {
  commentId: string;
  disableExpressions?: boolean;
  truncate?: number;
}) {
  const [comment, setComment] = useState<CommentSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.COMMENTS)
      .doc(commentId)
      .get()
      .then(parseDoc)
      .then(setComment);
  }, [commentId]);

  const [userProfileMetadata] =
    useFirestoreLiveDocument<UserProfileMetadataSchema>(
      FirestoreCollection.USER_PROFILE_METADATA,
      comment?.userId
    );

  if (!comment) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          background-color: #9a9a9a;
          width: 75px;
          height: 25px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          margin-top: 13px;
        `}
      >
        <span
          css={css`
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
          `}
        >
          {comment?.userTribeId &&
            TRIBE_INFO_BY_ID[comment?.userTribeId].shortDescription}
        </span>
      </div>
      <div
        css={css`
          font-size: 16px;
          font-weight: 400;
          color: ${DATA_PLATFORM_RED_GRAY};
          flex: 1;
          overflow: hidden;
          line-height: 160%;
          margin-top: 13px;
          margin-left: 14px;
          display: flex;
        `}
      >
        <div
          css={css`
            margin-left: 6px;
          `}
        >
          {truncateLongSentence(comment?.comment || "", truncate)}
        </div>
      </div>
    </div>
  );
}
