export enum ROUTES {
  HOME = "/",
  DASHBOARD = "/dashboard",
  INTRO = "/intro", // Sign in page
  SUBSCRIBE_INTRO = "/subscribe_intro",
  DATA_EXPLORER = "/data_explorer",
  CARD_DATA = "/card_data",
  CARD_STAT_VIEW = "/card_stat_view",
  CARD_ANSWERS = "/card_answers",
  CARD_ANSWER_TRIBES = "/card_answers_tribes",
  RECENT_ANSWER_TRIBES = "/recent_answers_tribes",

  CARD_GPT = "/card_gpt",
  TODAY = "/today",
  CARD = "/card",
  URL_STACK = "/urlStack",
  ADMIN = "/admin",
  INTRO_LOGGED_IN = "/intro_logged_in",
  INTRO_AGREEMENT = "/intro_agreement",
  ONBOARDING = "/onboarding",
  ENTRANCE = "/entrance",
  TRIBE_TEST_RESULTS_REVISIT = "/tribe_test_results_revisit",
  QUESTIONS = "/questions",
  QUESTION = "/question",
  ANSWERS = "/answers",
  TRIBES = "/tribes",
  STATS = "/stats",
  MY_PROFILE = "/myprofile",
  PROFILE = "/profile",
  POST = "/post",
  PRIVACY = "/privacy",
  NOTIFICATIONS = "/notifications",
  SEARCH = "/search",
  TESTS = "/tests",
  CHAT = "/chat",
  CARD_REPORT = "/card_report",
  DAILY_REPORT = "/daily_report",
  EMAIL_LOGIN = "/email_login",
  CARD_TEXT_REPORT = "/card_text_report",
  HASHTAG_MAP = "/hashtag_map",
  NOTIFICATION_SCHEDULE = "/notification_schedule",
  STOCK_TEST = "/stock_test",
  GENDER_AGE_REGION = "/gender_age_region",
  NEWS = "/news",
  FLUTTER_API = "/flutter_api",
  DATA_EXPORT = "/export",
  AUTH = "/auth",
  POPULAR_ISSUES = "/popular_issues",
  AGENDA = "/agenda",
  MENTION_RESPONSE = "/mention_response",
  POPULARITY_RATES = "/polularity_rates",
  SUPPORTERS = "/supporters",
  POLDI = "/poldi",
  HASHTAG_TIME_SERIES = "/hashtag_time_series",
  MONTH_IN_REVIEW = "/month_in_review",
  MONTH_IN_REVIEW_PUBLISH = "/month_in_review_publish",
  YEAR_IN_REVIEW = "/year_in_review",
  YEAR_IN_REVIEW_PUBLISH = "/year_in_review_publish"
}
