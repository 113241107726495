/** @jsxImportSource @emotion/react */

import React from "react";
import { HashtagPersonOnTimeline } from "../Today/HashtagPersonOnTimeline";
import { css } from "@emotion/react";
import ResultStatistics from "../../cards/DetailViews/ResultStatistics";
import CardStatsGenderAgeChart from "../../cards/DetailViews/CardStatsGenderAgeChart";
import HashtagCardForTodayTimeline from "../Today/HashtagCardForTodayTimeline";
import CardAnswerTribeChart from "../HashCardView/Comment/CardAnswerTribeChart";
import { SupportersContext } from "../../foundation/Contexts/SupportersContext";
import AddHashtagCommentBubble from "./AddHashtagCommentBubble";
import useSupporters from "./useSupporters";

export function TodayPolitician({
  hashtag,
  additionalSupporter
}: {
  hashtag: string;
  additionalSupporter?: string;
}) {
  const supporterData = useSupporters(hashtag, additionalSupporter);

  if (!supporterData) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div>
        <HashtagPersonOnTimeline hashtag={hashtag} />
      </div>
      <div
        css={css`
          flex-grow: 1;
          margin-top: 20px;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            text-align: center;
            padding-bottom: 10px;
          `}
        >
          전체 지지율
        </div>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          <div
            css={css`
              max-width: 400px;
            `}
          >
            <ResultStatistics cardId={hashtag} />
          </div>
          <div
            css={css`
              margin-top: 10px;
              max-width: 400px;
            `}
          >
            <CardAnswerTribeChart cardId={hashtag} />
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <CardStatsGenderAgeChart cardId={hashtag} />
            </div>
          </div>
        </div>

        <div
          css={css`
            flex-direction: column;
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              font-weight: bold;
              padding-top: 20px;
              padding-bottom: 10px;
            `}
          >
            최근 발언들
          </div>
          <SupportersContext.Provider value={supporterData}>
            <HashtagCardForTodayTimeline hashtag={hashtag} />
          </SupportersContext.Provider>
          <div
            css={css`
              width: 100%;
              max-width: 500px;
            `}
          >
            <AddHashtagCommentBubble author={"송영길"} />
          </div>
        </div>
      </div>
    </div>
  );
}
