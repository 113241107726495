import React, { useContext, useEffect, useState } from "react";
import { OxButtons } from "./OxButtons";
import {
  checkAndRedirectForLogin,
  parseDoc,
  useIsAdmin
} from "../../../utils/utils";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection,
  UserVectorSchema
} from "../../../constants/firestore_schema";
import { addAnswer } from "../../../lib/answer_lib";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import { useHistory } from "react-router";
import firebase from "firebase/compat";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import { sendCoinFromOxoTo } from "../../../utils/assets";

export default function OxButtonsForCard({
  cardId,
  additionalActionWhenClicked,
  disableTribeCheck = false
}: {
  cardId: string;
  additionalActionWhenClicked?: (answer: string) => void;
  disableTribeCheck?: boolean;
}) {
  const isAdmin = useIsAdmin();

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const history = useHistory();

  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  const [cardAnswer, setCardAnswer] = useState<CardAnswerSchema>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .doc(currentUserId + cardId)
      .onSnapshot(snapshot => setCardAnswer(parseDoc(snapshot)));

    return () => {
      unsubscriber();
    };
  }, [currentUserId, cardId]);

  const answer = cardAnswer?.answer;
  const [requestedAnswer, setRequestedAnswer] = useState(answer);

  const updateVote = (answer: string) => {
    if (
      !disableTribeCheck &&
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!currentUserId || !answer || !currentUserMetadata) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    if (!requestedAnswer) {
      //질문카드 드로워 상세화면
      sendCoinFromOxoTo(currentUserId, 1, `[1회성${cardId}]OX응답`);
    }

    setRequestedAnswer(answer);

    const answerData: UserVectorSchema = {};
    answerData[cardId] = answer;

    additionalActionWhenClicked && additionalActionWhenClicked(answer);

    addAnswer(cardId, currentUserId, currentUserMetadata, answer);
  };

  if (!card || !cardAnswer) {
    return null;
  }

  // 자추 방지
  if (cardId === currentUserId) {
    return null;
  }

  // 자추 방지
  if (card.createdBy === currentUserId && !isAdmin) {
    return null;
  }

  return (
    <OxButtons
      onVote={vote => updateVote(vote)}
      answer={requestedAnswer || answer}
    />
  );
}
