import React from "react";
import WeeklyReviewSummary from "./WeeklyReviewSummary";

export default function WeeklyReviewDetails() {
  return (
    <div style={{ padding: 10, paddingTop: 10 }}>
      <WeeklyReviewSummary />
    </div>
  );
}
