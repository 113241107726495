import React from "react";

import { ACCENT_COLOR } from "../../../constants/colors";

export default function ButtonD({
  color = ACCENT_COLOR,
  fill = "white"
}: {
  color?: string;
  fill?: string;
}) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.60626 2.57407C9.81798 0.475308 12.8473 0.47531 14.059 2.57408L20.2388 13.2778C21.4505 15.3765 19.9359 18 17.5124 18H5.15284C2.7294 18 1.21475 15.3765 2.42647 13.2778L8.60626 2.57407Z"
        stroke={color}
        strokeWidth="1.3"
      />
    </svg>
  );
}
