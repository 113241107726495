/** @jsxImportSource @emotion/react */

import React from "react";
import {
  AnswerCountSchema,
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { css } from "@emotion/react";

export default function StatsForCardId({
  cardId,
  answerStats
}: {
  cardId: string;
  answerStats?: AnswerCountSchema;
}) {
  const cardAnswerStats = answerStats
    ? answerStats
    : useFirestoreGetDocument<CardAnswerStatsSchema>(
        FirestoreCollection.CARD_ANSWER_STATS,
        cardId
      );

  if (!cardAnswerStats) {
    return null;
  }

  return (
    <table
      css={css`
        width: 500px;
      `}
    >
      <tr>
        <td>O</td>
        <td>?</td>
        <td>X</td>
      </tr>
      <tr>
        <td>{cardAnswerStats.o}</td>
        <td>{cardAnswerStats["?"]}</td>
        <td>{cardAnswerStats.x}</td>
      </tr>
    </table>
  );
}
