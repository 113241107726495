/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";

import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { removeIdKey } from "../../../../utils/utils";

import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import getTribeStandupImage from "../../../../utils/image_utils/getTribeStandupImage";

import TribeColumnBarPlainChartContainer from "../../DataExplorerView/TribeColumnBarPlainChartContainer";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

export default function CardAnswerTribeV1ColumnPlainBarChartSmall({
  cardId,
  tribeStats
}: {
  cardId: string;
  tribeStats?: CardAnswerTribeStatsSchema;
}) {
  const cardAnswerTribeStats =
    tribeStats ??
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 180px;
        margin-top: 57px;
      `}
    >
      {Object.keys(removeIdKey(cardAnswerTribeStats)).map(tribeId => {
        return (
          <div
            key={tribeId}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: -5px;
            `}
          >
            <div
              css={css`
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                margin-bottom: 20px;
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>

            <div
              key={tribeId}
              css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50px;
              `}
            >
              <TribeColumnBarPlainChartContainer
                stats={cardAnswerTribeStats[tribeId]}
                height={150}
              />

              <div
                css={css`
                  position: absolute;
                  top: -19px;
                  right: -5px;
                `}
              >
                <div>
                  <img
                    key={tribeId}
                    src={getTribeStandupImage(tribeId)}
                    alt="부족 이미지"
                    css={css`
                      width: 50px;
                      height: 50px;
                    `}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
