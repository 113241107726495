import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { VIEW_COUNT_START_TIME } from "../../../constants/web_constants";
import { getCommentLikeCount, parseDoc } from "../../../utils/utils";
import KoreanDate from "./KoreanDate";
import firebase from "firebase/compat";

export default function CardLikeStats({ card }: { card: CardSchema }) {
  const [shardedCounterViews, setShardedCounterViews] = useState<number>(0);

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_VIEW_COUNTERS)
      .doc(card.id)
      .onSnapshot(snapshot => {
        const data = parseDoc(snapshot);
        setShardedCounterViews(data.count || 0);
      });

    return () => {
      unsubscriber();
    };
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <KoreanDate timestamp={card.createdAt} />
      <div style={{ margin: "0 2px" }}>·</div>
      <div>댓글 {card && card.comments}</div>
      <div style={{ margin: "0 2px" }}>·</div>
      <div>
        공감{" "}
        {card && card.postMetadata && card.postMetadata.likeUserAndTribes
          ? Object.keys(card.postMetadata.likeUserAndTribes).length
          : getCommentLikeCount(card.postMetadata?.likeUserMap || {})}
      </div>
      {card.publishedAt && card.publishedAt > VIEW_COUNT_START_TIME && (
        <div style={{ margin: "0 2px" }}>·</div>
      )}
      {card.publishedAt && card.publishedAt > VIEW_COUNT_START_TIME && (
        <div>조회 {(card.views || 0) + shardedCounterViews}</div>
      )}
    </div>
  );
}
