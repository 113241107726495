import { CheckCircleOutlineOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BACKGROUND_COLOR, GRAY_DARK } from "../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection,
  UserNotificationItemSchema
} from "../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  parseDoc,
  truncateLongSentence
} from "../../../utils/utils";

const MAX_COMMENT_CUTOFF = 20;

export default function PostAnswerNotification({
  userNotification
}: {
  userNotification: UserNotificationItemSchema;
}) {
  const history = useHistory();
  const [post, setPost] = useState<CardSchema>();
  useEffect(() => {
    if (
      userNotification.postAnswerNotification &&
      userNotification.postAnswerNotification.postId
    ) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(userNotification.postAnswerNotification.postId)
        .get()
        .then(snapshot => {
          setPost(parseDoc(snapshot));
        });
    }
  }, [userNotification.postAnswerNotification]);

  if (!post) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "20px 20px 20px 10px",
        borderBottom: "1px solid " + BACKGROUND_COLOR
      }}
    >
      <div
        style={{
          color: GRAY_DARK,
          minWidth: "60px",
          padding: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "baseline"
        }}
      >
        <div>
          <CheckCircleOutlineOutlined
            style={{
              color: GRAY_DARK,
              fontWeight: "bold"
            }}
          />
          <span
            style={{
              fontSize: "12px",
              marginLeft: "2px",
              verticalAlign: "sub"
            }}
          >
            {userNotification.postAnswerNotification?.answers}
          </span>
        </div>
      </div>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (post) {
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, post?.id)
            );
          }
        }}
      >
        {post
          ? '"' + truncateLongSentence(post.title, MAX_COMMENT_CUTOFF) + '"'
          : "삭제된 "}{" "}
        생각이 {userNotification.postAnswerNotification?.answers}개의 응답을
        받았습니다.
        <div
          style={{
            fontSize: "14px",
            color: GRAY_DARK
          }}
        >
          {new Date(userNotification.createdAt).toLocaleDateString() +
            " " +
            new Date(userNotification.createdAt).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
}
