import React, { SetStateAction, useState } from "react";
import { TextField } from "@material-ui/core";

export default function ChatSearchInput({
  setSearchQuery
}: {
  setSearchQuery: React.Dispatch<SetStateAction<string>>;
}) {
  const [internalText, setInternalText] = useState<string>("");

  return (
    <TextField
      autoFocus
      label="대화 검색"
      margin="normal"
      value={internalText}
      onChange={e => {
        setInternalText(e.target.value);
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          setSearchQuery(internalText);
        }
      }}
      fullWidth
      onBlur={e => {
        setSearchQuery(internalText);
      }}
    />
  );
}
