import React, { useContext } from "react";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import UserPoliticalMap from "../OXOUserProfile/UserPoliticalMap";

export default function MyPoliticsForProfile() {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  return (
    <div>
      <div style={{ width: "100%" }}>
        {currentUserId && <UserPoliticalMap profileUserId={currentUserId} />}
      </div>
      <div style={{ padding: "0 15px" }}>
        <div
          style={{
            width: "100%",
            margin: "10px 0 10px",
            borderBottom: "1px solid #E5E5E5"
          }}
        />
      </div>
    </div>
  );
}
