import React from "react";
import { VictoryPie } from "victory";
import { oxoColors } from "../../../../constants/enums";
import { CardAnswerStatsSchema } from "../../../../constants/firestore_schema";

export default function MiniQuestionPieChart({
  cardChartStats,
  colorScale = [oxoColors.O, oxoColors.DUNNO, oxoColors.X],
  size = 23
}: {
  cardChartStats: CardAnswerStatsSchema;
  colorScale?: string[];
  size?: number;
}) {
  if (!cardChartStats) {
    return null; // cannot be the case.
  }

  const stats = [
    {
      x: 0,
      y: cardChartStats.o || 0
    },
    {
      x: 0,
      y: cardChartStats["?"] || 0
    },
    {
      x: 0,
      y: cardChartStats.x || 0
    }
  ];

  return (
    <div style={{ width: size, height: size }}>
      <VictoryPie
        data={stats}
        colorScale={colorScale}
        style={{ labels: { display: "none" } }}
      />
    </div>
  );
}
