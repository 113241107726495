import React, { useContext, useState } from "react";
import { Switch, TextField } from "@material-ui/core";
import OXOButton from "../../../foundation/OXOButton/OXOButton";
import firebase from "firebase/compat";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { dateToIso } from "../../../../utils/utils";
import { SnackbarContext } from "../../../foundation/Contexts/SnackbarContext";

export default function FeaturedOnTodayFocusAtEditor({
  card
}: {
  card: CardSchema;
}) {
  const snackbar = useContext(SnackbarContext);

  const [featuredOnTodayFocus, setFeaturedOnTodayFocus] = useState<boolean>(
    !!card.featuredOnTodayFocusAt
  );
  const [featuredOnTodayFocusAt, setFeaturedOnTodayFocusAt] = useState<number>(
    card.featuredOnTodayFocusAt || new Date().valueOf()
  );

  return (
    <div>
      <Switch
        checked={featuredOnTodayFocus}
        onChange={(e, checked) => {
          if (!checked) {
            firebase
              .firestore()
              .collection(FirestoreCollection.CARDS)
              .doc(card.id)
              .set(
                {
                  featuredOnTodayFocusAt: firebase.firestore.FieldValue.delete()
                },
                { merge: true }
              );
          }
          setFeaturedOnTodayFocus(checked);
        }}
      />
      {featuredOnTodayFocus && (
        <div>
          <TextField
            id="datetime-local"
            type="datetime-local"
            defaultValue={dateToIso(
              new Date(card.featuredOnTodayFocusAt || new Date().valueOf())
            )}
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              setFeaturedOnTodayFocusAt(new Date(e.target.value).valueOf());
            }}
          />
          <div style={{ marginTop: 10 }}>
            <OXOButton
              label={"예약"}
              style={{ width: 60, height: 30 }}
              onClick={() => {
                firebase
                  .firestore()
                  .collection(FirestoreCollection.CARDS)
                  .doc(card.id)
                  .set(
                    {
                      featuredOnTodayFocusAt: featuredOnTodayFocusAt
                    },
                    { merge: true }
                  )
                  .then(() => {
                    const date = new Date(featuredOnTodayFocusAt);
                    snackbar(
                      date.toLocaleDateString() +
                        " " +
                        date.toLocaleTimeString() +
                        "에 TodayFocus로 예약 되었습니다."
                    );
                  });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
