/** @jsxImportSource @emotion/react */

import React from "react";
import { ROUTES } from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { ListItem } from "@material-ui/core";

export default function NavigationItem({
  name,
  route
}: {
  name: string;
  route: ROUTES;
}) {
  const history = useHistory();

  return (
    <div
      css={css`
        .MuiListItem-gutters {
          padding-left: 0px;
          width: 220px;
          height: 50px;
        }
        background-color: ${history.location.pathname === route
          ? "#505050"
          : null};
      `}
      key={name}
    >
      <ListItem button>
        <div
          onClick={() => {
            history.push(route);
          }}
          css={css`
            width: 220px;
            height: 50px;
            color: ${history.location.pathname === route
              ? "#FFFFFF"
              : "#B0B3B2"};
            font-weight: 700;
            font-size: 16px;
            margin-left: 30px;
            margin-top: 28px;
          `}
        >
          {name}
        </div>
      </ListItem>
    </div>
  );
}
