import { Button, Popover } from "@material-ui/core";
import firebase from "firebase/compat";

import React from "react";
import DetailsIcons from "./DetailsIcons";

const LIGHT_COLOR_MAPPING: { [color: string]: string } = {
  "#CEA055": "#FFEFD6", // 호랑이
  "#AC8DB6": "#F6F3F7", // 하마
  "#699BB0": "#E7F8FF", // 코끼리
  "#AAAF83": "#F6F8E6", // 공룡
  "#B76E6C": "#F7E2E0" // 사자
};

export default function DetailsButton({
  text,
  buttonColor
}: {
  text: string;
  buttonColor: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = (e: Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div
          style={{
            padding: 17,
            fontSize: 16,
            backgroundColor: "#363636",
            maxWidth: 328,
            lineHeight: "1.5em",
            fontWeight: "normal",
            color: LIGHT_COLOR_MAPPING[buttonColor] || "white"
          }}
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          {text.includes("_") ? (
            <div
              style={{
                display: "flex",
                minHeight: 200,
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              {text.split("_").map(el => {
                return <div key={el}>{el}</div>;
              })}
            </div>
          ) : (
            text
          )}
        </div>
      </Popover>
      <Button
        onClick={event => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
          firebase.analytics().logEvent("v4_click_hash_test_detail_button", {
            event_category: "hashcard"
          });
        }}
      >
        <DetailsIcons color={buttonColor} />
      </Button>
    </div>
  );
}
