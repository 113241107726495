import React from "react";

export default function HashPageName({ hashtag }: { hashtag: string }) {
  return (
    <div
      style={{
        fontSize: 44,
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center"
      }}
    >
      {hashtag && hashtag}
    </div>
  );
}
