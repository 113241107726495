/** @jsxImportSource @emotion/react */

import React from "react";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";
import {
  CardAnswerJurisdictionStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import DistrictStatTable from "./DistrictStatTable";
import { css } from "@emotion/react";

export default function DistrictResultsTable({ cardId }: { cardId: string }) {
  const cardAnswerJurisdictionStats =
    useFirestoreGetDocument<CardAnswerJurisdictionStatsSchema>(
      FirestoreCollection.CARD_ANSWER_JURISDICTION_STATS,
      cardId
    );

  return (
    <table
      css={css`
        width: 500px;
      `}
    >
      <tr>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          광역자치도
        </td>

        <td
          css={css`
            font-weight: bold;
          `}
        >
          O
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          △
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          X
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          응답
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          O
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          △
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          X
        </td>
      </tr>
      <DistrictStatTable
        jurisdictionStats={cardAnswerJurisdictionStats?.jurisdictionStats}
      />
    </table>
  );
}
