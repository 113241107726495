import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import firebase from "firebase/compat";
import React, { useContext, useEffect } from "react";
import {
  DeclinedField,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { KOREAN_DISTRICTS } from "./KoreanDistrict";

export default function UserRegion({
  district,
  setDistrict,
  address,
  setAddress,
  declinedFields
}: {
  district: string;
  setDistrict: (p: string) => void;
  address: string;
  setAddress: (p: string) => void;
  declinedFields: DeclinedField[];
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  useEffect(() => {
    if (district === "선거구") {
      return;
    }

    if (currentUserId && currentUserMetadata) {
      if (declinedFields?.includes(DeclinedField.DISTRICT)) {
        declinedFields.splice(
          declinedFields.indexOf(DeclinedField.DISTRICT),
          2
        );
      }

      firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .update({
          userBasicInfo: {
            ...currentUserMetadata.userBasicInfo,
            address,
            district,
            declinedFields
          }
        });
    }
  }, [district]);

  return (
    <Autocomplete
      options={[
        { address: "해외 거주자", district: "해외 거주자" },
        ...KOREAN_DISTRICTS.sort(
          (a, b) => -b.district.localeCompare(a.district)
        )
      ]}
      groupBy={option => option.district}
      getOptionLabel={option => option.address}
      defaultValue={{
        address: address === "secret" ? "" : address,
        district: district === "secret" ? "" : district
      }}
      disableClearable={true}
      onChange={(event, value) => {
        setAddress(value?.address);
        setDistrict(value?.district);
      }}
      popupIcon={false}
      renderInput={params => (
        <TextField
          placeholder="어느 동네에 살고 계신가요?"
          {...params}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "#3E7A99",
            height: "34px",
            width: 280,
            position: "relative",
            textAlign: "center"
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              fontWeight: "bold",
              textAlign: "center",
              fontSize: 16,
              paddingLeft: 30,
              color: district === "선거구" ? "#C5D7E0" : "#3E7A99"
            }
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: {
              height: "28px"
            }
          }}
        />
      )}
    />
  );
}
