import React from "react";

export default function SearchIcon2({
  color = "#808080",
  width = "15",
  height = "15"
}: {
  color?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.81596 0C4.39256 0 0 4.4806 0 9.95921C0 15.4386 4.39329 19.9102 9.81596 19.9102C12.0855 19.9102 14.1783 19.1253 15.8426 17.8121L22.4578 24.5246C22.5886 24.6675 22.7464 24.7832 22.9225 24.8648C23.1037 24.9489 23.3006 24.995 23.5011 24.9996C23.7015 25.0042 23.9003 24.9673 24.0854 24.8916C24.2704 24.816 24.4379 24.7032 24.5777 24.5603C24.7174 24.4175 24.8265 24.2479 24.8987 24.0622C24.971 23.8764 25.0052 23.6779 24.9994 23.4788C24.9935 23.2796 24.9478 23.0835 24.8647 22.9023C24.7844 22.7273 24.6708 22.5693 24.5297 22.4378L17.858 15.668C18.9803 14.0508 19.6401 12.0808 19.6401 9.95921C19.6401 4.47986 15.2386 0 9.81596 0ZM2.92896 9.95921C2.92896 6.07337 6.01062 2.95325 9.81596 2.95325C13.6222 2.95325 16.7111 6.07428 16.7111 9.95921C16.7111 11.7521 16.053 13.3806 14.97 14.615C14.864 14.7013 14.7713 14.8014 14.6937 14.912C13.4458 16.1778 11.7228 16.9569 9.81596 16.9569C6.00973 16.9569 2.92896 13.8441 2.92896 9.95921Z"
        fill={color}
      />
    </svg>
  );
}
