import React from "react";
import { GRAY_BACKGROUND } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import {
  HashtagConfiguration,
  PAGE_COMPONENTS,
  parseYamlDescription
} from "../common/hashpage_shared";
import HashPeopleDescription from "../Profile/HashPeopleDescription";
import HashCardHierarchy from "../Related/HashCardHierarchy";
import HashRelatedCard from "../Related/HashRelatedCard";

import HashPageAbbreviation from "../common/HashPageAbbreviation";
import MembersInfo from "../common/MembersInfo";
import HashPageElectionInfo from "../Election/HashPageElectionInfo";
import HashPagePhoto from "../HashPagePhoto";
import HashMainPolicies from "../Policies/HashMainPolicies";
import HashCareer from "../Profile/HashCareer";
import HashEduBackground from "../Profile/HashEduBackground";
import HashParty from "../Profile/HashParty";
import HashPeople from "../Profile/HashPeople";
import HashElectionResult from "../HashElectionResult/HashElectionResult";

import HashVote from "../HashVote/HashVote";

export default function HashCardDetailPagesInformationTab({
  hashtag
}: {
  hashtag: string;
}) {
  const card = useFirestoreGetDocument<CardSchema>(
    FirestoreCollection.CARDS,
    hashtag
  );

  const hashtagConfiguration: HashtagConfiguration = parseYamlDescription(
    card?.hashtagMetadata?.description
  );
  const [windowWidth] = useWindowSize();

  return (
    <div>
      <div
        style={{
          width: "100%",
          textAlign: "left",
          padding: "30px 15px 10px 15px",
          fontSize: 18,
          fontWeight: "bold"
        }}
      >
        {hashtag.replace(/\_/gi, " ")}
      </div>
      <div
        className="HashCardDetail_Photo "
        style={{
          minHeight: card?.hashtagMetadata?.photos?.[0] ? 213 : 0,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        {card?.hashtagMetadata?.photos?.[0] && (
          <div
            style={{
              width: "100%",
              height: windowWidth > 500 ? 426 : 213,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${card?.hashtagMetadata?.photos[0]})`
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "100%"
        }}
      >
        {hashtagConfiguration.설명 && (
          <HashPeopleDescription description={hashtagConfiguration.설명} />
        )}
        <div
          style={{
            width: "100%",
            borderBottom: `1px solid ${GRAY_BACKGROUND}`
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          padding: "0px 10px"
        }}
      >
        {hashtagConfiguration &&
          card &&
          Object.keys(hashtagConfiguration).map((key: string) => {
            if (
              key === PAGE_COMPONENTS.HASHTAG ||
              key === PAGE_COMPONENTS.PARTY
            ) {
              return null;
            }
            const hashpageComponent = getHashpageComponent(
              card,
              hashtag,
              key,
              hashtagConfiguration
            );
            return key === PAGE_COMPONENTS.STORY_DETAILS ? (
              hashpageComponent
            ) : (
              <div
                style={{
                  marginTop: 6
                }}
                key={key}
              >
                {hashpageComponent}
              </div>
            );
          })}
      </div>

      <div style={{ width: "100%", padding: "0px 10px" }}>
        <HashRelatedCard hashtag={hashtag} />
        <div
          style={{
            width: "100%",
            borderBottom: `1px solid ${GRAY_BACKGROUND}`
          }}
        />
      </div>
      <div style={{ width: "100%", padding: "0px 10px" }}>
        <HashCardHierarchy hashtag={hashtag} />
      </div>
    </div>
  );
}

function getHashpageComponent(
  card: CardSchema,
  hashtag: string,
  key: string,
  hashPageConfiguration: HashtagConfiguration
) {
  switch (key) {
    case PAGE_COMPONENTS.PEOPLE:
      if (!hashPageConfiguration.인물들) {
        return null;
      }
      return (
        hashPageConfiguration.인물들 && (
          <HashPeople
            hashtag={hashtag}
            peopleConfiguration={hashPageConfiguration.인물들}
          />
        )
      );

    // case PAGE_COMPONENTS.DEBATE:
    //   if (!hashPageConfiguration.토론) {
    //     return null;
    //   }
    //   return (
    //     hashPageConfiguration.토론 && (
    //       <HashDebate
    //         debateConfiguration={hashPageConfiguration.토론}
    //         hashtag={hashtag}
    //       />
    //     )
    //   );

    // case PAGE_COMPONENTS.LIVECHAT:
    //   if (!hashPageConfiguration.실시간중계) {
    //     return null;
    //   }
    //   return (
    //     hashPageConfiguration.실시간중계 && (
    //       <HashPageLiveChat
    //         liveChatConfiguration={hashPageConfiguration.실시간중계}
    //         hashtag={hashtag}
    //       />
    //     )
    //   );

    // case PAGE_COMPONENTS.EVENTS:
    //   if (!hashPageConfiguration.이벤트들) {
    //     return null;
    //   }
    //   return (
    //     hashPageConfiguration.이벤트들 && (
    //       <EventList eventsConfiguration={hashPageConfiguration.이벤트들} />
    //     )
    //   );

    // case PAGE_COMPONENTS.POLICIES:
    //   if (!hashPageConfiguration.공약비교) {
    //     return null;
    //   }
    //   return (
    //     hashPageConfiguration.공약비교 && (
    //       <Policies policiesConfiguration={hashPageConfiguration.공약비교} />
    //     )
    //   );

    case PAGE_COMPONENTS.VOTE:
      return <HashVote cardId={hashtag} />;

    case PAGE_COMPONENTS.ELECTION_RESULT:
      if (!hashPageConfiguration.개표) {
        return null;
      }
      return (
        hashPageConfiguration.개표 && (
          <HashElectionResult
            electionResultConfiguration={hashPageConfiguration.개표}
            hashtag={hashtag}
          />
        )
      );

    case PAGE_COMPONENTS.CAREER:
      if (!hashPageConfiguration.경력) {
        return null;
      }
      return (
        hashPageConfiguration.경력 && (
          <HashCareer careerConfiguration={hashPageConfiguration.경력} />
        )
      );
    case PAGE_COMPONENTS.EDUCATION:
      if (!hashPageConfiguration.학력) {
        return null;
      }
      return (
        hashPageConfiguration.학력 && (
          <HashEduBackground eduConfiguration={hashPageConfiguration.학력} />
        )
      );
    case PAGE_COMPONENTS.MAIN_POLICIES:
      if (!hashPageConfiguration.주요공약) {
        return null;
      }
      return (
        hashPageConfiguration.주요공약 && (
          <HashMainPolicies
            mainPromiseConfiguration={hashPageConfiguration.주요공약}
          />
        )
      );
    case PAGE_COMPONENTS.HASHCARD_HIERARCHY:
      if (!hashPageConfiguration.해시지도) {
        return null;
      }
      return <HashCardHierarchy hashtag={hashtag} />;
    case PAGE_COMPONENTS.RELATED_HASHCARD:
      if (!hashPageConfiguration.관련해시태그) {
        return null;
      }
      return <HashRelatedCard hashtag={hashtag} />;
    case PAGE_COMPONENTS.PARTY:
      if (!hashPageConfiguration.정당) {
        return null;
      }
      return (
        hashPageConfiguration.정당 && (
          <HashParty
            hashtag={hashtag}
            partyConfiguration={hashPageConfiguration.정당}
          />
        )
      );

    case PAGE_COMPONENTS.ELECTION_INFO:
      if (!hashPageConfiguration.선거정보) {
        return null;
      }
      return (
        hashPageConfiguration.선거정보 && (
          <HashPageElectionInfo
            hashtag={hashtag}
            electionInfo={hashPageConfiguration.선거정보}
          />
        )
      );

    case PAGE_COMPONENTS.PHOTO:
      if (!hashPageConfiguration.인물사진) {
        return null;
      }
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {card && <HashPagePhoto card={card} />}
        </div>
      );
    case PAGE_COMPONENTS.AGENCY:
      if (!hashPageConfiguration.기관) {
        return null;
      }
      return <MembersInfo hashtag={hashtag} />;
    case PAGE_COMPONENTS.ABBREVIATION:
      if (!hashPageConfiguration.별칭) {
        return null;
      }
      return (
        hashPageConfiguration.별칭 && (
          <HashPageAbbreviation
            hashtag={hashtag}
            abbreviation={hashPageConfiguration.별칭}
          />
        )
      );
  }
}
