import React, { useContext } from "react";
import { useIsAdmin } from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import {
  FirestoreCollection,
  UserNotificationSubscriptionSchema
} from "../../../../constants/firestore_schema";
import { updateNotificationSubscription } from "../../../../lib/notification_settings_lib";
import { GRAY_DARK, TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import NotificationIcon from "../../../foundation/Navigation/icons/NotificationIcon";

export default function HashtagFollowButton({ cardId }: { cardId: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [userNotificationSubscription] =
    useFirestoreLiveDocument<UserNotificationSubscriptionSchema>(
      FirestoreCollection.USER_NOTIFICATION_SUBSCRIPTIONS,
      currentUserId + cardId
    );

  if (!useIsAdmin() || !userNotificationSubscription || !currentUserId) {
    return null;
  }

  const isSubscribed = userNotificationSubscription.isSubscribed;

  return (
    <div
      onClick={e => {
        e.stopPropagation();
        updateNotificationSubscription(cardId, !isSubscribed, currentUserId);
      }}
      style={{
        position: "relative",
        width: 31,
        height: 31,
        borderRadius: "50%",
        backgroundColor:
          isSubscribed && currentUserMetadata && currentUserMetadata.tribeId
            ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
            : GRAY_DARK,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }}
    >
      <NotificationIcon color={"white"} width={"21"} height={"21"} />
    </div>
  );
}
