/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import {
  CardAnswerAgeGenderStatsSchema,
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardFeatureStatus,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  flatten,
  getDocsFromSnapshot,
  listToMap,
  onlyUnique,
  parseDoc
} from "../../../utils/utils";
import { DAY, DAYS } from "../../../utils/datetime";
import { DailyReportsDataSchema } from "./useDailyReportsDataForHashtag";

export default function useDailyReportsDataForDetails({
  hashtag,
  selectedDate,
  days,
  limit,
  sortByAnswersDesc = true
}: {
  hashtag: string;
  selectedDate: number;
  days?: number;
  limit?: number;
  sortByAnswersDesc?: boolean;
}): DailyReportsDataSchema {
  const [cardAnswerStatsMap, setCardAnswerStatsMap] =
    useState<{ [cardId: string]: CardAnswerStatsSchema }>();

  const [relatedHashCards, setRelatedHashCards] = useState<CardSchema[]>();
  useEffect(() => {
    setRelatedHashCards(undefined);
    setCardAnswerStatsMap(undefined);
    setCards(undefined);
    setCardAnswerAgeGenderStatsMap(undefined);

    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [CardType.HASHTAG_CARD])
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setRelatedHashCards);
  }, [hashtag]);

  const [relatedHashtags, setRelatedHashtags] = useState<string[]>();

  const [cards, setCards] = useState<CardSchema[]>();
  useEffect(() => {
    if (!relatedHashCards) {
      return;
    }
    const relatedHashtags = relatedHashCards.map(
      relatedHashCard => relatedHashCard.id
    );

    setRelatedHashtags([...relatedHashtags, hashtag]);
    const promises = [...relatedHashtags, hashtag].map(hashtag => {
      let query = firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .where("type", "in", [CardType.NEWS_TICKER, CardType.REAL_NAME_POST])
        .where("featuredOnNewsTimeline", "==", CardFeatureStatus.ON);

      if (days) {
        query = query.where(
          "featuredOnNewsTimelineAt",
          ">=",
          selectedDate - (days - 1) * DAYS
        );
      } else if (limit) {
        query = query.limit(limit);
      }

      return query
        .where("featuredOnNewsTimelineAt", "<", selectedDate + DAY)
        .where("speakerHashTag", "array-contains", "#" + hashtag)
        .orderBy("featuredOnNewsTimelineAt", "desc")
        .get()
        .then(getDocsFromSnapshot);
    });

    Promise.all(promises).then((hashtagCards: CardSchema[][]) => {
      const cards = flatten(hashtagCards)
        .sort(
          (a, b) =>
            (b.featuredOnNewsTimelineAt || 0) -
            (a.featuredOnNewsTimelineAt || 0)
        )
        .filter(card => !card.speakerHashTag?.includes("#옥소"))
        .filter((v, i, a) =>
          onlyUnique(
            v.id,
            i,
            a.map(c => c.id)
          )
        );

      const cardAnswerStatPromises = cards.map(card => {
        return firebase
          .firestore()
          .collection(FirestoreCollection.CARD_ANSWER_STATS)
          .doc(card.id)
          .get()
          .then(parseDoc);
      });

      Promise.all(cardAnswerStatPromises).then(
        (cardAnswerStats: CardAnswerStatsSchema[]) => {
          const cardAnswerStatMap: { [cardId: string]: CardAnswerStatsSchema } =
            listToMap(
              cardAnswerStats,
              cardAnswerStat => cardAnswerStat.id || "",
              cardAnswerStat => cardAnswerStat
            );

          setCardAnswerStatsMap(cardAnswerStatMap);

          const sortedCards = sortByAnswersDesc
            ? cards.sort((ca, cb) => {
                return cardAnswerStatMap[cb.id].o - cardAnswerStatMap[ca.id].o;
              })
            : cards;
          setCards(sortedCards);
        }
      );
    });
  }, [selectedDate, relatedHashCards, days]);

  const [cardAnswerTribeStatsMap, setCardAnswerTribeStatsMap] =
    useState<{ [cardId: string]: CardAnswerTribeStatsSchema }>();
  useEffect(() => {
    if (!cards) {
      return;
    }
    const promises = cards.map(card =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(card.id)
        .get()
        .then(parseDoc)
    );

    Promise.all(promises).then(
      (cardAnswerTribeStats: CardAnswerTribeStatsSchema[]) => {
        setCardAnswerTribeStatsMap(
          listToMap(
            cardAnswerTribeStats,
            // @ts-ignore
            cardAnswerTribeStat => cardAnswerTribeStat["id"],
            cardAnswerTribeStat => cardAnswerTribeStat
          )
        );
      }
    );
  }, [cards]);

  const [cardAnswerAgeGenderStatsMap, setCardAnswerAgeGenderStatsMap] =
    useState<{ [cardId: string]: CardAnswerAgeGenderStatsSchema }>();
  useEffect(() => {
    if (!cards) {
      return;
    }
    const promises = cards.map(card =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
        .doc(card.id)
        .get()
        .then(parseDoc)
    );

    Promise.all(promises).then(
      (cardAnswerAgeGenderStats: CardAnswerAgeGenderStatsSchema[]) => {
        setCardAnswerAgeGenderStatsMap(
          listToMap(
            cardAnswerAgeGenderStats,
            // @ts-ignore
            cardAnswerTribeStat => cardAnswerTribeStat["id"],
            cardAnswerTribeStat => cardAnswerTribeStat
          )
        );
      }
    );
  }, [cards]);

  return {
    cardAnswerStatsMap,
    relatedHashCards,
    cards,
    cardAnswerTribeStatsMap,
    cardAnswerAgeGenderStatsMap,
    relatedHashtags
  };
}
