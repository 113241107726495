/** @jsxImportSource @emotion/react */

import React from "react";

import { css, SerializedStyles } from "@emotion/react";

import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { removeIdKey } from "../../../../utils/utils";

import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";

import TribeColumnBarChartContainer from "../../DataExplorerView/TribeColumnBarChartContainer";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

export default function CardAnswerStanceV1ColumnBarChart({
  cardId,
  customCss,
  barHeight
}: {
  cardId: string;
  customCss?: SerializedStyles;
  barHeight?: number;
}) {
  const cardAnswerTribeStats =
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      cardId
    );

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <div
      css={[
        css`
          display: flex;
          justify-content: space-between;
          width: 400px;
        `,
        ...(customCss ? [customCss] : [])
      ]}
    >
      {Object.keys(removeIdKey(cardAnswerTribeStats)).map(tribeId => {
        return (
          <div
            key={tribeId}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: -5px;
            `}
          >
            <div
              key={tribeId}
              css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50px;
              `}
            >
              <TribeColumnBarChartContainer
                stats={cardAnswerTribeStats[tribeId]}
                barHeight={barHeight}
              />
            </div>

            <div
              css={css`
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                margin-bottom: 20px;
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>
          </div>
        );
      })}
    </div>
  );
}
