import React, { useEffect, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { getDocsFromSnapshot, useIsAdmin } from "../../../../utils/utils";
import { DAY } from "../../../../utils/datetime";
import HashCommentBubble from "../../HashCardView/common/HashCommentBubble";

export default function Last24HBubbleCards({
  hashtag,
  lastTopicCardId,
  hideTools = false
}: {
  hashtag: string;
  lastTopicCardId: string | undefined;
  hideTools?: boolean;
}) {
  const [newsTickerCards, setNewsTickerCards] = useState<CardSchema[]>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [
        CardType.NEWS_TICKER,
        CardType.TOPIC,
        CardType.POST
      ])
      .where("hashtags", "array-contains", "#" + hashtag)
      .orderBy("publishedAt", "desc")
      .limit(3)
      .onSnapshot(snapshot => {
        const lastCard = getDocsFromSnapshot(snapshot)
          .filter((card: CardSchema) => card.id !== lastTopicCardId)
          .filter(
            (card: CardSchema) =>
              (card.type === CardType.TOPIC && card.displayToUsers) ||
              card.type !== CardType.TOPIC
          )[0] as CardSchema;
        if (lastCard) {
          firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .where("type", "in", [
              CardType.NEWS_TICKER,
              CardType.TOPIC,
              CardType.POST
            ])
            .where("hashtags", "array-contains", "#" + hashtag)
            .where(
              "publishedAt",
              ">",
              (lastCard.publishedAt || lastCard.createdAt) - DAY
            )
            .orderBy("publishedAt", "asc")
            .get()
            .then(getDocsFromSnapshot)
            .then(cards => {
              if (hashtag === "#윤석열_공수처수사") {
                console.log(cards);
              }
              setNewsTickerCards(
                cards
                  .filter((card: CardSchema) => card.id !== lastTopicCardId)
                  .filter(
                    (card: CardSchema) =>
                      (card.type === CardType.TOPIC && card.displayToUsers) ||
                      card.type !== CardType.TOPIC
                  )
              );
            });
        } else {
          setNewsTickerCards([]);
        }
      });

    return () => {
      unsubscriber();
    };
  }, [hashtag, lastTopicCardId]);
  const isAdmin = useIsAdmin();

  return (
    <div>
      {newsTickerCards &&
        newsTickerCards
          .filter(card => {
            return (
              isAdmin || card.displayToUsers || card.type !== CardType.TOPIC
            );
          })
          .filter((card: CardSchema) => card.id !== lastTopicCardId)
          .map(card => {
            return (
              <div key={card.id} style={{ marginBottom: 10 }}>
                <HashCommentBubble
                  cardId={card.id}
                  key={card.id}
                  showDetails={!hideTools}
                />
              </div>
            );
          })}
    </div>
  );
}
