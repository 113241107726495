import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FirestoreCollection } from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  getMapFromSnapshots
} from "../../../../utils/utils";

export default function MembersInfo({ hashtag }: { hashtag: string }) {
  const [members, setMembers] = useState<string[]>();
  const history = useHistory();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("belongToCardIds", "array-contains", hashtag)
      .get()
      .then(getMapFromSnapshots)
      .then(snapshots => setMembers(() => Object.keys(snapshots)));
  }, []);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {members &&
          members.length &&
          members.map(member => {
            return (
              <li
                key={member}
                onClick={e => {
                  e.stopPropagation();
                  history.push(
                    history.location.pathname +
                      addToCardIdStackUrl(history.location.search, `${member}`)
                  );
                }}
              >
                {member}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
