import React from "react";
import { VictoryBar, VictoryGroup, VictoryStack } from "victory";
import { oxoColors } from "../../../../constants/enums";
import { QuestionAnswerStatsSchema } from "../../../../constants/firestore_schema";

export default function MiniQuestionBarChart({
  questionChartStats,
  colorScale = [oxoColors.O, oxoColors.DUNNO, oxoColors.X],
  height = 1
}: {
  questionChartStats: QuestionAnswerStatsSchema;
  colorScale?: string[];
  height?: number;
}) {
  if (!questionChartStats) {
    return null; // cannot be the case.
  }

  const stats = [
    {
      x: 0,
      y: questionChartStats.o || 0
    },
    {
      x: 0,
      y: questionChartStats["?"] || 0
    },
    {
      x: 0,
      y: questionChartStats.x || 0
    }
  ];

  return (
    <VictoryGroup height={height} width={375} padding={0}>
      <VictoryStack horizontal colorScale={colorScale}>
        <VictoryBar data={[stats[0]]} />
        <VictoryBar data={[stats[1]]} />
        <VictoryBar data={[stats[2]]} />
      </VictoryStack>
    </VictoryGroup>
  );
}
