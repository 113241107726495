import React from "react";

export default function TalkIcon({
  color = "#808080",
  width = "25",
  height = "25"
}: {
  color?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.7 12.8C38.9 8.60001 28.3 10.3 18.3 15.4C13 18 8.6 21.8 7 27.9C3.9 39.6 8.79999 46.2 20.8 46.3C21.3 46.3 21.7 46.3 22.2 46.3C24.2 46.3 26.5 45.7 28 46.5C30.1 47.6 28.5 50.2 28.8 52.1C29.2 54.5 30.7 55.5 32.9 54.3C35 53.2 36.5 51.4 37.8 49.5C39.2 47.4 40.9 46.5 43.5 46.3C54.6 45.4 59 41 60.6 30.4C61.7 22.6 56.3 15.3 49.7 12.8ZM22.6 33.4C21 33.7 19.6 33.5 18.4 32.2C17.6 28.5 18.1 27.8 21.8 27.3C22.5 27.8 23.2 28.3 23.9 28.7C24.6 30.6 24 32.1 22.6 33.4ZM33.4 32.8C31.3 32.9 30.2 31.8 30.2 29.7C30.2 27.3 31.6 26.5 33.5 26.3C35.6 26.4 37.3 27 37.2 29.2C37.2 31.5 35.6 32.7 33.4 32.8ZM46.8 32.2C45.1 31.8 42.7 31.7 42.7 29.4C42.6 26.6 45 26 47.3 25.8C49.1 25.7 50.5 26.4 50.6 28.5C50.6 30.9 48.9 31.8 46.8 32.2Z"
        fill={color}
      />
    </svg>
  );
}
