export const UserAnswer: { [answer: string]: string } = {
  O: "o",
  X: "x",
  DUNNO: "?",
  DONT_CARE: "??",
  REPORT: "!",
  UNANSWERED: "unanswered"
};

export const AnswerSymbol: { [answer: string]: string } = {
  O: "◯",
  X: "✕",
  DUNNO: "△",
  DONT_CARE: "?",
  REPORT: "!",
  UNANSWERED: "unanswered"
};

export const THERMOMETER_COLOR: { [answer: string]: string } = {
  O: "#FD8A87",
  X: "#4998F7",
  DUNNO: "#FDE395"
};

export const PRIMARY_COLOR = "#FFE812";
export const PRIMARY_RED = "#FD8A87";
export const PRIMARY_TWO = "#FFD651";
export const SECONDARY = "#FFF6DC";
export const GRAY_BACKGROUND = "#e5e5e5";
export const GRAY = "#9E9E9E";
export const GRAY_DARK = "#6B6B6B";
export const GRAY_DARKER = "#6E6E73";
export const GRAY_DEBATE_DARK = "#545454";
export const GRAY_EXTRA_DARK = "#363636";
export const GRAY_ABSTRACT = "#D0D1D0";
export const GRAY_LIGHT = "#D3D3D3";
export const GRAY_LIGHTER = "#CECECF";
export const GRAY_EXTRA_LIGHT = "#F0F2F5";
export const GRAY_MEDIUM = "#b3b3b3";
export const GRAY_CHAT_PREVIEW = "#808080";
export const GRAY_PROFILE = "#626262";
export const GRAY_HASH_PAGE_BUTTON = "#F2F1F6";
export const GRAY_HASH_PAGE_BUTTON_CLICKED = "B4B8C1";
export const GRAY_COMMENT_BUBBLE = "#F2F2F2";
export const DARK_THEME_BACKGROUND = "#636363";
export const DARK_MODE_BACKGROUND = "#202125";
export const BACKGROUND_COLOR = "#fafafa";
export const BACKGROUND_COLOR_LOADER = "#292A2D";
export const BLACK = "#000000";
export const FACEBOOK_FONT_BLACK = "#050505";
export const LIGHT_GRAY_BACKGROUND = "#F5F5F5";

export const BLUE_PROFILE = "#2f80ed";

export const OXO_ID = "oxo";
export const GRAY_MIDDLE = "#9e9e9f";
export const APPLE_MORE = "#2767C5";
export const TRUMP_RED = "#E42521";
export const DEBATE_ABSTRACT = "#FBFBFD";
export const PROFILE_SIDE_MENU_ITEMS = {
  MAIN: "profile_main",
  POLITICS: "politics",
  TEST: "test",
  RANKING: "ranking",
  STATISTICS: "statistics",
  NOTIFICATION: "notification",
  SETTING: "setting"
};

export const TRIBE_INFO_BY_ID: {
  [tribeId: string]: {
    name: string;
    image: string;
    color: string;
    bgColor: string;
    talkColor: string;
    profileBgColor: string;
    userMapColor: string;
    heavyColor: string;
    darkColor: string;
    sound: string;
    shortDescription: string;
  };
} = {
  "1": {
    name: "호랑이",
    image: "tiger",
    color: "#FFB031",
    bgColor: "#FFEFD6",
    talkColor: "#FADCAD",
    profileBgColor: "#ffb031",
    userMapColor: "#F8CC86",
    heavyColor: "#CEA055",
    darkColor: "#81550E",
    sound: "어흥",
    shortDescription: "진보"
  },
  "2": {
    name: "하마",
    image: "hippo",
    color: "#DDC3E5",
    bgColor: "#F6F3F7",
    talkColor: "#E7DFE9",
    profileBgColor: "#ddc3e5",
    userMapColor: "#DCD0DF",
    heavyColor: "#AC8DB6",
    darkColor: "#6E4A79",
    sound: "하마",
    shortDescription: "중도진보"
  },
  "3": {
    name: "코끼리",
    image: "elephant",
    color: "#89DCFF",
    bgColor: "#E7F8FF",
    talkColor: "#D7F0FA",
    profileBgColor: "#89dcff",
    userMapColor: "#C4E8F7",
    heavyColor: "#699BB0",
    darkColor: "#296179",
    sound: "뿌우",
    shortDescription: "중도"
  },
  "4": {
    name: "공룡",
    image: "dino",
    color: "#d1db83",
    bgColor: "#F6F8E6",
    talkColor: "#ECF0D5",
    profileBgColor: "#d1db83",
    userMapColor: "#E4E8C2",
    heavyColor: "#AAAF83",
    darkColor: "#5A6031",
    sound: "쿠앙",
    shortDescription: "중도보수"
  },
  "5": {
    name: "사자",
    image: "lion",
    color: "#FD8A87",
    bgColor: "#F7E2E0",
    talkColor: "#FAD6D5",
    profileBgColor: "#FD8A87",
    userMapColor: "#F7C3C1",
    heavyColor: "#B76E6C",
    darkColor: "#772C2A",
    sound: "으르렁",
    shortDescription: "보수"
  }
};

export enum SortingOrder {
  CREATED_AT_DESC,
  LIKE_COUNT_DESC
}

export const oxoColors = {
  O: "#2BBB6D",
  DUNNO: "#DDD377",
  X: "#BB5F42",
  DONT_CARE: "#A363D9",
  ME: "#000000",
  OTHERS: "#CCCCCC"
};

/**
 * @deprecated Use CardType
 */
export enum QuestionType {
  TOPIC,
  PRESIDENT,
  MEDIA,
  PARTY,
  PERSON,
  POLITICIAN,
  CONGRESSMAN
}
