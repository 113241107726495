/** @jsxImportSource @emotion/react */

import { List } from "@material-ui/core";

import React from "react";
import { ROUTES } from "../../../constants/routes";
import oxoPoliticsLogoMono from "../../../img/logos/oxopolitics-mono.svg";
import { css } from "@emotion/react";
import NavigationItem from "./NavigationItem";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

export default function LeftNavigationBar() {
  const [windowWidth, windowHeight] = useWindowSize();
  return (
    <div
      css={css`
        background-color: #2f2f2f;
        width: 220px;
        min-height: 100vh;
        height: ${windowHeight};
      `}
    >
      <div css={css``}>
        <div
          css={css`
            height: 60px;
            width: 220px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          `}
        >
          <img
            src={oxoPoliticsLogoMono}
            width={120}
            css={css`
              margin-left: 30px;
              margin-top: 6px;
            `}
          />
        </div>

        {/* <div css={{ padding: "20px 0px" }}>
          <OXOButton
            label={"소통하기"}
            onClick={() => {}}
            style={{ backgroundColor: "#F79412", width: 120, height: 50 }}
          />
          <div css={{ height: 20 }} />
          <OXOButton
            label={"반응보기"}
            onClick={() => {}}
            style={{ backgroundColor: "black", width: 120, height: 50 }}
          />
        </div> */}
        <div css={css``}>
          <List>
            {/*<NavigationItem name={"대시보드"} route={ROUTES.HOME} />*/}
            {/*<NavigationItem*/}
            {/*  name={"해시태그 데이터"}*/}
            {/*  route={ROUTES.DATA_EXPLORER}*/}
            {/*/>*/}
            <NavigationItem name={"카드 데이터"} route={ROUTES.HOME} />
            <NavigationItem name={"폴디 분석"} route={ROUTES.POLDI} />
            <NavigationItem
              name={"대통령 옥소지수 통계"}
              route={ROUTES.HASHTAG_TIME_SERIES}
            />
            <NavigationItem name={"월간 리뷰"} route={ROUTES.MONTH_IN_REVIEW} />
            <NavigationItem name={"연간 리뷰"} route={ROUTES.YEAR_IN_REVIEW} />
            <NavigationItem
              name={"최근 응답 부족 분석"}
              route={ROUTES.RECENT_ANSWER_TRIBES}
            />
            {/*<NavigationItem name={"설문조사 분석"} route={ROUTES.AGENDA} />*/}
            {/*<NavigationItem name={"핫이슈 분석"} route={ROUTES.CHAT} />*/}
            <div
              css={css`
                margin-top: 5px;
                margin-bottom: 5px;
                height: 1px;
                width: 220px;
                background-color: #505050;
              `}
            />
            <NavigationItem name={"로그인"} route={ROUTES.INTRO} />
            <NavigationItem name={"핸드폰 인증"} route={ROUTES.AUTH} />
            {/* <NavigationItem name={"현재 이슈"} route={ROUTES.POPULAR_ISSUES} /> */}
            {/* <div
            css={css`
              height: 30px;
            `}
          />
          <NavigationItem name={"아젠다 분석"} route={ROUTES.AGENDA} />
          <NavigationItem
            name={"발언 반응 분석"}
            route={ROUTES.MENTION_RESPONSE}
          />
          <NavigationItem
            name={"지지율 분석"}
            route={ROUTES.POPULARITY_RATES}
          />
          <NavigationItem name={"지지자 분석"} route={ROUTES.SUPPORTERS} /> */}
          </List>
        </div>
      </div>
    </div>
  );
}
