import React from "react";
import { useHistory } from "react-router-dom";
import { addToCardIdStackUrl } from "../../../../utils/utils";
import HashCommentAvatar from "../Comment/HashCommentAvatar";

const HashPagePortraitWithRating = ({
  cardId,
  allowToRedirect = false
}: {
  cardId: string;
  allowToRedirect?: boolean;
}) => {
  const history = useHistory();

  return (
    <div
      key={cardId}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        textAlign: "center",
        cursor: "pointer",
        padding: 5
      }}
      onClick={e => {
        if (allowToRedirect) {
          e.stopPropagation();
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(history.location.search, cardId)
          );
        }
      }}
    >
      <div
        style={{
          position: "relative",
          width: 90,
          height: 60
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: "white"
          }}
        >
          <HashCommentAvatar speaker={cardId} size={60} />
        </div>
      </div>
    </div>
  );
};

export default HashPagePortraitWithRating;
