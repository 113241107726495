import { Button, Popover } from "@material-ui/core";
import firebase from "firebase/compat";

import React from "react";
import OXOText from "../../utility/OXOText/OXOText";

import DetailsIcons from "../../views/HashCardView/StaceTest/DetailsIcons";

export default function DetailsQuestionMarkButton({
  text,
  buttonColor = "white"
}: {
  text: string;
  buttonColor?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = (e: Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div
          style={{
            padding: 17,
            fontSize: 16,
            backgroundColor: "#363636",
            maxWidth: 328,
            lineHeight: "1.5em",
            fontWeight: "normal",
            color: "white"
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            setAnchorEl(null);
          }}
        >
          <OXOText text={text} />
        </div>
      </Popover>
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
          firebase.analytics().logEvent("v4_click_hash_test_detail_button", {
            event_category: "hashcard"
          });
        }}
      >
        <DetailsIcons color={buttonColor} size={24} />
      </Button>
    </div>
  );
}
