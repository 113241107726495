/** @jsxImportSource @emotion/react */

import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";

import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../constants/firestore_schema";
import { parseDoc } from "../../../utils/utils";
import OxButtonsForCard from "../../foundation/OXOButton/OxButtonsForCard";
import OXOText from "../../utility/OXOText/OXOText";
import { addPostAnswerNotification } from "../../../lib/notification_internal_lib";
import CommentSpeaker from "../../views/HashCardView/common/CommentSpeaker";
import CommentTitleForBubble from "../../views/HashCardView/common/CommentTitleForBubble";
import NewsLink from "../../views/HashCardView/common/NewsLink";
import PhotoViewer from "../CardComponents/PhotoViewer";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import CardAdminTools from "../../topic/CardAdminTools";
import {
  DISPLAY_FLEX,
  DISPLAY_FLEX_COLUMN,
  WIDTH100
} from "../../CSS/cssUtils";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import StanceTestQuestionCardView from "../../views/HashCardView/StaceTest/StanceTestResultQuestionView";

export default function DetailPagesCommentCardTab({
  cardId,
  maxDescriptionChars = 300
}: {
  cardId: string;
  maxDescriptionChars?: number;
}) {
  const [card, setCard] = useState<CardSchema | undefined>();
  const [windowWidth] = useWindowSize();

  const expandContent = true;
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  useEffect(() => {
    const unsucscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => {
      unsucscriber();
    };
  }, [cardId]);

  if (!card || !cardId) {
    if (!cardId) {
      console.log("cardID is missing in TodayTopicCard!");
    }
    return null;
  }

  const maxWidth = windowWidth >= 750;

  const imageAvailable =
    card?.postMetadata?.photos && card?.postMetadata?.photos.length > 0;

  return (
    <div>
      {card.type !== CardType.TEST_QUESTION && (
        <div>
          {imageAvailable && card?.postMetadata?.photos && (
            <PhotoViewer
              photoUrls={card?.postMetadata?.photos}
              height={maxWidth ? 400 : windowWidth * 0.6}
            />
          )}
          <div css={[WIDTH100, `max-width: 750px; padding: 20px;`]}>
            <CommentSpeaker cardId={cardId} />
            <div css={[WIDTH100, DISPLAY_FLEX, { textAlign: "left" }]}>
              <div
                css={[DISPLAY_FLEX_COLUMN, WIDTH100, { alignItems: "center" }]}
              >
                <div
                  css={[
                    WIDTH100,
                    {
                      borderRadius: 14,
                      marginTop: 5,
                      marginBottom: 3,
                      position: "relative"
                    }
                  ]}
                >
                  <CommentTitleForBubble cardId={cardId} color={"black"} />
                  <div
                    className={"smallParagraph"}
                    css={[
                      WIDTH100,
                      { color: "black", marginTop: 20, lineHeight: 1.7 }
                    ]}
                  >
                    {card.type === CardType.POST && (
                      <OXOText
                        text={card.postMetadata?.description || ""}
                        truncateChars={
                          expandContent
                            ? undefined
                            : imageAvailable
                            ? maxDescriptionChars / 2
                            : maxDescriptionChars
                        }
                      />
                    )}
                    {card.type === CardType.NEWS_TICKER && (
                      <NewsLink cardId={cardId} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            css={{
              padding: "30px 0px 10px",
              margin: "auto"
            }}
          >
            <OxButtonsForCard
              cardId={cardId}
              additionalActionWhenClicked={() => {
                if (card.type === CardType.POST) {
                  addPostAnswerNotification(card, card.answers + 1);
                }
              }}
            />
          </div>
          <CardAdminTools cardId={cardId} />
        </div>
      )}

      {card.type === CardType.TEST_QUESTION && currentUserVector && (
        <div>
          <div
            css={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <StanceTestQuestionCardView
              card={card}
              currentUserVector={currentUserVector}
              updateVoteExternal={() => {
                console.log("done");
              }}
            />
          </div>
          <CardAdminTools cardId={cardId} />
        </div>
      )}
    </div>
  );
}
