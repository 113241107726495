import oxo_standup from "../../../src/img/tribe_standup/oxo_standup.png";
import tiger_mark from "../../../src/img/tribe_mark/tiger_mark.png";
import hippo_mark from "../../../src/img/tribe_mark/hippo_mark.png";
import elephant_mark from "../../../src/img/tribe_mark/elephant_mark.png";
import dino_mark from "../../../src/img/tribe_mark/dino_mark.png";
import lion_mark from "../../../src/img/tribe_mark/lion_mark.png";

const getTribeMarkImage = (tribeImageID: string) => {
  switch (tribeImageID) {
    case "1":
      return tiger_mark;
    case "2":
      return hippo_mark;
    case "3":
      return elephant_mark;
    case "4":
      return dino_mark;
    case "5":
      return lion_mark;
    default:
      return oxo_standup;
  }
};

export default getTribeMarkImage;
