import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import { GRAY_DARK, TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import {
  FirestoreCollection,
  UserLastSeenItemSchema
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot,
  parseDoc
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import { useHistory } from "react-router";
import TalkIcon from "../../../foundation/Navigation/icons/TalkIcon";

export default function ChatButtonLight({ cardId }: { cardId: string }) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [lastSeenChat, setLastSeenChat] = useState<UserLastSeenItemSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.USER_LAST_SEEN_ITEMS)
      .doc(currentUserId + cardId)
      .get()
      .then(parseDoc)
      .then(setLastSeenChat);
  }, [currentUserId, cardId]);

  const [chatBadgeCount, setChatBadgeCount] = useState<number>(0);
  useEffect(() => {
    if (currentUserMetadata && lastSeenChat) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.CHATS)
        .where("createdAt", ">", lastSeenChat.lastSeenAt || 0)
        .where("chatRoomId", "==", cardId)
        .limit(1)
        .onSnapshot(snapshot => {
          setChatBadgeCount(getDocsFromSnapshot(snapshot).length);
        });
      return () => {
        unsubscribe();
      };
    }
  }, [currentUserMetadata, lastSeenChat]);

  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, `CHAT_${cardId}`)
        );
      }}
      style={{
        position: "relative",
        width: 31,
        height: 31,
        borderRadius: "50%",
        backgroundColor:
          chatBadgeCount > 0 &&
          currentUserMetadata &&
          currentUserMetadata.tribeId
            ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
            : GRAY_DARK,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }}
    >
      <TalkIcon color={"white"} />
    </div>
  );
}
