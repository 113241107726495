import { Drawer, IconButton, Menu, MenuItem } from "@material-ui/core";
import { EditOutlined, MoreVert } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useContext, useState } from "react";
import {
  CommentSchema,
  FirestoreCollection,
  ReferenceType
} from "../../../constants/firestore_schema";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import CommentTrashButton from "../../foundation/OXOComment/CommentTrashButton";
import EditCommentInput from "../../foundation/OXOComment/EditCommentInput";
import ContentReportButton from "../../utility/ReportButton/ContentReportButton";
import UserBlockButton from "../../utility/ReportButton/UserBlockButton";

export default function CommentControls({
  comment
}: {
  comment: CommentSchema;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [showCommentEditDrawer, setShowCommentEditDrawer] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex", marginRight: 4 }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={"small"}
        style={{ color: "#B0B3B2" }}
      >
        <MoreVert
          style={{
            fontSize: 20
          }}
        />
      </IconButton>

      <Drawer
        open={showCommentEditDrawer}
        onClose={() => setShowCommentEditDrawer(false)}
        anchor={"bottom"}
      >
        <div
          style={{
            maxHeight: window.innerHeight * 0.9,
            overflow: "auto"
          }}
        >
          {showCommentEditDrawer && (
            <EditCommentInput
              commentId={comment.id}
              onInputSubmit={() => setShowCommentEditDrawer(false)}
              questionId={comment.questionId}
            />
          )}
        </div>
      </Drawer>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch"
          }
        }}
      >
        <ContentReportButton
          setBlind={() => {
            firebase
              .firestore()
              .collection(FirestoreCollection.COMMENTS)
              .doc(comment.id)
              .set({ bad: !comment.bad }, { merge: true })
              .then(() =>
                alert("블라인드 상태를 변경 했습니다. " + !comment.bad)
              );
          }}
          referenceId={comment.id}
          referenceType={ReferenceType.COMMENT}
          reportedUserId={comment.userId}
          reportedUserTribe={comment.userTribeId || "0"}
          onClose={handleClose}
        />

        {comment.userId !== currentUserId && (
          <UserBlockButton
            reportedUserId={comment.userId}
            onClose={handleClose}
          />
        )}

        {currentUserId === comment.userId && (
          <MenuItem
            key={"edit"}
            onClick={() => {
              setShowCommentEditDrawer(true);
              handleClose();
            }}
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ marginRight: 5 }}>
                <EditOutlined />
              </div>
              <div style={{ paddingBottom: 7 }}>수정하기</div>
            </div>
          </MenuItem>
        )}

        {currentUserId === comment.userId && (
          <CommentTrashButton comment={comment} />
        )}
      </Menu>
    </div>
  );
}
