import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined
} from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GRAY,
  GRAY_DARKER,
  TRIBE_INFO_BY_ID
} from "../../../../constants/enums";
import {
  BestCommentsForCardSchema,
  CardAnswerSchema,
  CardSchema,
  CommentSchema,
  FirestoreCollection,
  LikeUserAndTribesSchema
} from "../../../../constants/firestore_schema";
import {
  checkAndRedirectForLogin,
  getCommentLikeByTribe,
  getTribeStandUpImage,
  groupBy,
  mapValues,
  parseDoc,
  toHashMap
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import OXOButton from "../../../foundation/OXOButton/OXOButton";
import AddCommentInput from "../../../foundation/OXOComment/AddCommentInput";
import ChatButton from "../../../views/HashCardView/common/ChatButton";
import TribeButtons from "../../../views/StatsView/TribeButtons";
import addComment from "./add_comment.svg";
import MiniCommentCard from "./MiniCommentCard";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { isBadContents } from "../../../../utils/contents";

export default function CommentTabs({
  currentTribeId,
  comments,
  cardId,
  photoUrl,
  authorTribeId,
  debateChatInfo,
  buttonText,
  disableChat,
  placeholderText,
  disableExpressions = false,
  showCommentEditPage = false
}: {
  comments: CommentSchema[];
  cardId: string;
  currentTribeId: string;
  commentsPerPage?: number;
  photoUrl?: string;
  authorTribeId?: string;
  debateChatInfo?: string;
  buttonText?: string;
  disableChat?: boolean;
  placeholderText?: string;
  disableExpressions?: boolean;
  showCommentEditPage?: boolean;
}) {
  const history = useHistory();

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );

  const [cardAnswer, setCardAnswer] = useState<CardAnswerSchema>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .doc(currentUserId + cardId)
      .onSnapshot(snapshot => setCardAnswer(parseDoc(snapshot)));

    return () => {
      unsubscriber();
    };
  }, [currentUserId, cardId]);

  const answer = cardAnswer?.answer;

  const [showCommentEdit, setShowCommentEdit] =
    useState<boolean>(showCommentEditPage);

  const [tabTribeId, setTabTribeId] = useState<string>("1");

  const bestCommentIdPerTribe: { [tribeId: string]: string } = {};

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => unsubscribe();
  }, [cardId]);

  const commentsMap: { [commentId: string]: CommentSchema } = toHashMap("id")(
    comments
  ) as { [commentId: string]: CommentSchema };

  const commentsDataByTribeDistance: {
    [tribeId: string]: CommentSchema[];
  } = groupBy(
    comments
      .filter(
        comment => !currentUserMetadata?.blockedUsers?.includes(comment.userId)
      )
      .filter(comment => !comment.isSpecialUser)
      .filter(comment => comment.userTribeId),
    (comment: CommentSchema) => comment.userTribeId || ""
  );

  useEffect(() => {
    if (!tabTribeId && card && currentUserId) {
      if (
        comments.some(comment => comment.likeUserAndTribes?.[currentUserId])
      ) {
        setTabTribeId("1");
      } else {
        setTabTribeId(currentTribeId);
      }
    }
  }, [card, comments, currentUserId]);

  Object.keys(commentsDataByTribeDistance).forEach(tribeId => {
    const comments = commentsDataByTribeDistance[tribeId];
    const bestComment = comments
      .filter(comment => !comment.isSpecialUser)
      .filter(comment => !comment.bad && !isBadContents(comment.comment))
      .sort(
        (comment1, comment2) =>
          Object.keys(getCommentLikeByTribe(comment2)).length -
          Object.keys(getCommentLikeByTribe(comment1)).length
      )
      .sort(
        (comment1, comment2) =>
          commentLikeCountPerTribe(getCommentLikeByTribe(comment2), tribeId) -
          commentLikeCountPerTribe(getCommentLikeByTribe(comment1), tribeId)
      )[0];

    if (bestComment) {
      bestCommentIdPerTribe[tribeId] = bestComment.id;
    }
  });

  useEffect(() => {
    if (card) {
      if (
        !card.bestComments ||
        Object.values(card.bestComments).toString() !==
          Object.values(bestCommentIdPerTribe).toString()
      ) {
        firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(cardId)
          .update({
            bestComments: bestCommentIdPerTribe
          });
      }
      if (
        !bestComments ||
        Object.values(bestComments).toString() !==
          Object.values(bestCommentIdPerTribe).toString()
      ) {
        firebase
          .firestore()
          .collection(FirestoreCollection.BEST_COMMENTS_FOR_CARD)
          .doc(cardId)
          .set(bestCommentIdPerTribe);
      }
    }
    return () => {};
  }, [card, Object.values(bestCommentIdPerTribe).toString()]);

  function commentLikeCountPerTribe(
    likeUserAndTribes: LikeUserAndTribesSchema,
    tribeId: string
  ) {
    return Object.keys(likeUserAndTribes).filter(
      likeUser => likeUserAndTribes[likeUser] === tribeId
    ).length;
  }

  const commentIdsByTribeDistance: { [tribeId: string]: string[] } = useMemo(
    () =>
      mapValues(
        commentsDataByTribeDistance,
        (comments: CommentSchema[], tribeId: string) => {
          return (
            comments
              // .sort((comment1, comment2) =>
              //   commentSortBy === "best"
              //     ? Object.keys(getCommentLikeByTribe(comment2)).length -
              //       Object.keys(getCommentLikeByTribe(comment1)).length
              //     : comment2.createdAt - comment1.createdAt
              // )
              .sort(
                (comment1, comment2) =>
                  Object.keys(getCommentLikeByTribe(comment2)).length -
                  Object.keys(getCommentLikeByTribe(comment1)).length
              )
              .sort(
                (comment1, comment2) =>
                  commentLikeCountPerTribe(
                    getCommentLikeByTribe(comment2),
                    tribeId
                  ) -
                  commentLikeCountPerTribe(
                    getCommentLikeByTribe(comment1),
                    tribeId
                  )
              )
              .map(comment => comment.id)
          );
        }
      ),
    [comments.length]
  );

  function getTabPane(tribeId: string) {
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            overflow: "auto",
            scrollSnapType: "x mandatory",
            minHeight: "295px",
            scrollSnapMarginLeft: "15px",
            scrollPaddingLeft: "15px",
            scrollBehavior: "smooth"
          }}
          key={"tribe" + tribeId}
          id={"horizontalComments" + tribeId}
        >
          {/*TODO: add pagination or infinite scroll*/}
          {[
            ...(commentIdsByTribeDistance[tribeId]
              ? commentIdsByTribeDistance[tribeId].map((commentId, i) => {
                  const comment = commentsMap[commentId];
                  return (
                    <div
                      style={{
                        marginBottom: "20px",
                        marginTop: "5px",
                        width: "270px",
                        minWidth: "270px",
                        minHeight: "270px",
                        // scrollSnapAlign: "start",
                        // scrollSnapMarginLeft: "10px",
                        // scrollPaddingLeft: "10px",
                        marginLeft: "10px",
                        position: "relative"
                      }}
                      key={commentId + i}
                    >
                      <MiniCommentCard
                        commentId={comment.id}
                        disableExpressions={disableExpressions}
                      />
                    </div>
                  );
                })
              : [
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      textAlign: "center"
                    }}
                    key={"pig" + tribeId}
                  >
                    <div
                      style={{
                        width: "270px",
                        minHeight: "270px",
                        display: "flex",
                        padding: "10px",
                        marginLeft: "20px",
                        flexDirection: "column",
                        position: "relative",
                        backgroundColor: TRIBE_INFO_BY_ID[tribeId].bgColor,
                        borderRadius: 14
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 60
                        }}
                      >
                        <div>
                          <img
                            style={{ width: 65 }}
                            src={getTribeStandUpImage(
                              TRIBE_INFO_BY_ID[tribeId].image
                            )}
                            alt={TRIBE_INFO_BY_ID[tribeId].name}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontFamily: "East Sea Dokdo",
                          fontSize: 18,
                          paddingLeft: 30,
                          paddingRight: 30
                        }}
                      >
                        {placeholderText || (
                          <span>
                            <span style={{ marginTop: 10 }}>
                              {tribeId === currentUserMetadata?.tribeId
                                ? `${currentUserMetadata?.modifier} ${
                                    TRIBE_INFO_BY_ID[
                                      currentUserMetadata?.tribeId
                                    ]?.name
                                  }님의 `
                                : `${TRIBE_INFO_BY_ID[tribeId].name} 부족의 `}
                            </span>
                            <span>댓글을 기다리고 있어요...</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ]),
            <div style={{ minWidth: "20px" }} key={"last"} />
          ]}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        position: "relative"
      }}
    >
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div style={{ display: "flex" }}>
          <TribeButtons
            currentTribeId={tabTribeId}
            setCurrentTribeId={setTabTribeId}
            badges={Object.keys(TRIBE_INFO_BY_ID)
              .map(tribeId =>
                commentIdsByTribeDistance[tribeId]
                  ? commentIdsByTribeDistance[tribeId].length
                  : 0
              )
              .map(a => a.toString())}
          />
        </div>
      </div>
      <div
        style={{
          cursor: "pointer",
          padding: "3px 4px",
          margin: "0 5px",
          backgroundColor: GRAY_DARKER,
          position: "absolute",
          top: 220,
          left: 0,
          borderRadius: 5,
          zIndex: 50,
          opacity: 0.3
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          const horizontalCommentView = document.getElementById(
            "horizontalComments" + tabTribeId
          );
          if (horizontalCommentView) {
            horizontalCommentView.scrollLeft -= 280;
          }
        }}
      >
        <KeyboardArrowLeftOutlined
          style={{
            color: "white",
            fontSize: "20px",
            verticalAlign: "-0.1em"
          }}
        />
      </div>
      <div
        style={{
          cursor: "pointer",
          padding: "3px 4px",
          margin: "0 5px",
          backgroundColor: GRAY_DARKER,
          position: "absolute",
          top: 220,
          right: 0,
          borderRadius: 5,
          zIndex: 50,
          opacity: 0.3
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          const horizontalCommentView = document.getElementById(
            "horizontalComments" + tabTribeId
          );
          if (horizontalCommentView) {
            horizontalCommentView.scrollLeft += 280;
          }
        }}
      >
        <KeyboardArrowRightOutlined
          style={{
            color: "white",
            fontSize: "20px",
            verticalAlign: "-0.1em"
          }}
        />
      </div>

      <div style={{ height: 293 }}>{tabTribeId && getTabPane(tabTribeId)}</div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "0 5px 10px 5px",
          fontSize: "24px",
          color: GRAY,
          alignItems: "center"
        }}
      >
        <div
          style={{
            paddingTop: "8px",
            width: disableChat ? "100%" : "50%",
            display: "flex",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 5
          }}
        >
          <OXOButton
            label={
              showCommentEdit ? (
                <div style={{ color: "#626262", fontSize: 16 }}>
                  {buttonText || "댓글"} 창 닫기
                </div>
              ) : (
                <div style={{ color: "#626262", fontSize: 16 }}>
                  <img
                    src={addComment}
                    alt="addComment"
                    style={{
                      verticalAlign: "text-top",
                      paddingRight: "5px",
                      width: "19px"
                    }}
                  />
                  {(buttonText || "댓글") + " 남기기"}
                </div>
              )
            }
            onClick={() => {
              if (
                !checkAndRedirectForLogin(
                  history,
                  currentUserId,
                  currentUserMetadata
                )
              ) {
                return;
              }
              setShowCommentEdit(prev => !prev);
            }}
            style={{
              border: "solid 1px black",
              backgroundColor: "transparent",
              fontSize: "16px"
            }}
          />
        </div>
        {!disableChat && (
          <div
            style={{
              width: "50%",
              paddingTop: 13,
              paddingLeft: 5,
              paddingRight: 10
            }}
          >
            <ChatButton
              cardId={cardId}
              title={debateChatInfo ? debateChatInfo : card?.title}
              photoUrl={photoUrl}
              authorTribeId={authorTribeId}
            />
          </div>
        )}
      </div>
      {showCommentEdit && (
        <div style={{ backgroundColor: "white" }}>
          <AddCommentInput
            onInputSubmit={() => setShowCommentEdit(false)}
            questionId={cardId}
            answer={answer}
          />
        </div>
      )}
    </div>
  );
}
