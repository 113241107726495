import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat";
import { TribeExpression } from "../constants/expressions";
import {
  CardFeatureStatus,
  CardType,
  CommentSchema,
  FirestoreCollection,
  TopicMetadata
} from "../constants/firestore_schema";

export function addQuestion(title: string, userId: string) {
  if (!title) {
    throw new Error("Description cannot be empty.");
  }

  if (!userId) {
    throw new Error("User ID is required.");
  }

  if (title.length > 1000) {
    throw new Error("Additional description is limited to 1000 characters.");
  }

  const questionId = uuidv4();

  const question = {
    featuredOnNewsTimeline: CardFeatureStatus.OFF,
    featuredOnTodayFocus: CardFeatureStatus.OFF,
    featuredOnTodayTimeline: CardFeatureStatus.OFF,
    featuredOnCommunityTimeline: CardFeatureStatus.OFF,
    cardScore: Date.now(),
    createdByTribeId: "",
    topicMetadata: {
      agree: "",
      disagree: "",
      abstract: "",
      tags: ""
    } as TopicMetadata,
    answers: 0,
    comments: 0,
    createdAt: Date.now(),
    type: CardType.TOPIC,
    title: title,
    createdBy: userId,
    displayInOnboarding: false,
    displayToUsers: false,
    publishedAt: Date.now(),
    views: 0,
    campaigns: []
  };

  return firebase
    .firestore()
    .collection(FirestoreCollection.CARDS)
    .add(question);
}

export function addQuestionComment(
  questionId: string,
  currentUserId: string,
  comment: string,
  tribeId: string,
  isSpecialUser: boolean,
  answer?: string,
  expression?: TribeExpression
) {
  if (!comment) {
    throw new Error("Comment cannot be empty.");
  }

  const commmentId = uuidv4();
  const newComment: CommentSchema = {
    likeUserAndTribes: {},
    comment: comment,
    createdAt: Date.now(),
    id: commmentId,
    likeUserMap: {},
    questionId: questionId,
    userId: currentUserId,
    userTribeId: tribeId,
    expression: expression,
    isSpecialUser: !!isSpecialUser,
    ...(answer ? { answer: answer } : {})
  };

  firebase
    .firestore()
    .collection(FirestoreCollection.COMMENTS)
    .doc(newComment.id)
    .set(newComment);
}
