/** @jsxImportSource @emotion/react */

import React from "react";
import {
  AnswerCountSchema,
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema
} from "../../../../constants/firestore_schema";
import { css } from "@emotion/react";
import TribeResultsInLine from "../TribeResultsInLine";
import { ButtonOx } from "../../../foundation/OXOButton/ButtonOx";
import CardAnswerTribeV1ChartEdited from "../../HashCardView/Comment/CardAnswerTribeV1ChartEdited";
import OxLightBarChartContainer from "../OxLightBarChartContainer";
import legends from "../images/legends.png";
import { cardTitle, chartsLayout, footer, frame } from "../StatsForCardIdChart";

export default function EditedDataSnapshot({
  backgroundColor,
  title,
  cardId,
  cardAnswers,
  dateFormat,
  answerCounts,
  tribeStats,
  averageCardAnswer
}: {
  backgroundColor: string;
  title: string | undefined;
  cardId: string;
  cardAnswers: CardAnswerStatsSchema;
  dateFormat: string;
  answerCounts?: number;
  tribeStats?: CardAnswerTribeStatsSchema;
  averageCardAnswer?: AnswerCountSchema;
}) {
  return (
    <div css={frame}>
      <article
        css={[
          chartsLayout,
          css`
            background-color: ${backgroundColor};
            border: 1px solid #dedede;
          `
        ]}
      >
        <header
          css={[
            cardTitle,
            css`
              margin-bottom: 50px;
            `
          ]}
        >
          {title}
        </header>

        <section
          css={css`
            margin-bottom: 30px;
          `}
        >
          <div>
            <TribeResultsInLine cardId={cardId} />
          </div>
          <div
            css={css`
              margin-top: 10px;
            `}
          >
            <ButtonOx />
          </div>
        </section>

        <section>
          <div
            css={css`
              margin-bottom: 40px;
            `}
          >
            <CardAnswerTribeV1ChartEdited
              cardId={cardId}
              tribeStats={tribeStats}
            />
          </div>
          <OxLightBarChartContainer
            cardId={cardId}
            width={420}
            averageCardAnswer={averageCardAnswer}
          />
        </section>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
          `}
        >
          <p css={answerCounts}>응답 {answerCounts ?? cardAnswers?.count}</p>

          <div>
            <img
              src={legends}
              css={css`
                width: 180px;
                height: 25px;
                margin-top: -3px;
                object-fit: contain;
              `}
            />
          </div>
        </div>

        <footer css={footer}>{dateFormat} 설문조사 | 옥소폴리틱스 제공</footer>
      </article>
    </div>
  );
}
