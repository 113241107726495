import firebase from "firebase/compat";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { addAnswer } from "../../../../lib/answer_lib";
import { checkAndRedirectForLogin, parseDoc } from "../../../../utils/utils";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import TopicDetailsCommentsAndResults from "../../../cards/DetailViews/TopicDetailsCommentsAndResults";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import { AlgoliaContext } from "../../SearchView/AlgoliaContext";
import HashCommentBubble from "../common/HashCommentBubble";
import OxButtonsForCard from "../../../foundation/OXOButton/OxButtonsForCard";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";

export default function HashCommentCardDetails({ cardId }: { cardId: string }) {
  if (
    // @ts-ignore
    window.cordova &&
    // @ts-ignore
    window.cordova.plugins &&
    // @ts-ignore
    window.cordova.plugins.notification
  ) {
    // @ts-ignore
    window.cordova.plugins.notification.badge.set(0);
  }

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .get()
      .then(snapshot => {
        setCard(parseDoc(snapshot));
      });
  }, [cardId]);

  const [windowWidth, windowHeight] = useWindowSize();
  const history = useHistory();
  const cardOutline: CSSProperties = {
    width: "100%",
    minHeight: "429px",
    maxWidth: Math.min(windowWidth, 750),
    display: "flex",
    scrollSnapAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white"
  };
  const algoliaClient = useContext(AlgoliaContext);

  const updateVote = (answer: string) => {
    if (
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!answer || !currentUserMetadata || !currentUserId) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    setRequestedAnswer(answer);

    const answerData: UserVectorSchema = {};
    answerData[cardId] = answer;

    addAnswer(cardId, currentUserId, currentUserMetadata, answer);
  };

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );
  const answer = cardAnswer?.answer;

  const [requestedAnswer, setRequestedAnswer] = useState(answer);

  if (!card || !cardId) {
    if (!cardId) {
      console.log("cardID is missing in TodayTopicCard!");
    }
    return null;
  }
  const debateInfo = `${card.debateCommentMetadata?.speaker}: ${card.debateCommentMetadata?.comment} `;
  return (
    <div
      style={{
        ...cardOutline,
        ...{
          justifyContent: "space-between"
        }
      }}
    >
      <div />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <HashCommentBubble cardId={cardId} />
      </div>
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "20px",
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px"
        }}
      >
        <div style={{ textAlign: "left" }}>
          <div style={{ padding: 20 }}></div>
          {card.title.match(/(?=.*서울)(?=.*특집).*/) ? (
            <div style={{ textAlign: "center", padding: 20 }}>
              2021 재보궐 선거일까지 OX 버튼이 비활성화 됩니다.
            </div>
          ) : (
            <OxButtonsForCard cardId={cardId} />
          )}
          <TopicDetailsCommentsAndResults cardId={cardId} />
        </div>
      </div>
    </div>
  );
}
