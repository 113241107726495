import React, { CSSProperties, useState } from "react";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import DetailPagesNavigation from "../../cards/DetailViews/DetailPagesNavigation";
import DetailPagesRenderer from "../../cards/DetailViews/DetailPagesRenderer";
import SwipeableViews from "react-swipeable-views";
import { CardType } from "../../../constants/firestore_schema";
import { useGetCard } from "../../../utils/hooks/useFirestoreGetDocument";

export default function CardDetails({
  cardId,
  onClose,
  tab,
  tribeId
}: {
  cardId: string;
  onClose: () => void;
  tab?: string;
  tribeId?: string;
}) {
  const card = useGetCard(cardId);
  const MENU: string[] = [
    card && card.type === CardType.TOPIC ? "질문" : "내용",
    "댓글",
    "톡",
    "응답결과"
  ];

  const [tabIndex, setTabIndex] = useState<number>(tab === "comments" ? 1 : 0);

  const [windowWidth, windowHeight] = useWindowSize();

  const cardOutline: CSSProperties = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    scrollSnapAlign: "center",
    maxWidth: Math.min(windowWidth, 750)
  };

  return (
    <div
      style={{
        ...cardOutline,
        ...{
          justifyContent: "space-between"
        }
      }}
    >
      <div
        style={{
          width: Math.min(windowWidth, 750),
          position: "fixed",
          zIndex: 20012,
          top: 50
        }}
      >
        <DetailPagesNavigation
          menuItemArray={MENU}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
      </div>
      <div style={{ paddingTop: 48 }} />
      <div style={{ width: "100%" }}>
        <SwipeableViews
          enableMouseEvents
          index={tabIndex + 1}
          onChangeIndex={(i, prevI) => {
            if (i === 0) {
              onClose && onClose();
            }
            setTabIndex(i - 1);
          }}
        >
          <div key={"close"} />
          {MENU.map((tabTitle, i) => {
            if (i === tabIndex) {
              return (
                <div style={{ minHeight: windowHeight - 110 }} key={tabTitle}>
                  <DetailPagesRenderer
                    cardId={cardId}
                    tab={tabTitle}
                    tribeId={tribeId}
                  />
                </div>
              );
            } else {
              return <div key={tabTitle}></div>;
            }
          })}
        </SwipeableViews>
      </div>
    </div>
  );
}
