import React from "react";
import { useHistory } from "react-router-dom";
import { APPLE_MORE, GRAY_DARKER } from "../../../../constants/enums";
import { addToCardIdStackUrl } from "../../../../utils/utils";
import { MainPoliciesConfiguration } from "../common/hashpage_shared";

export default function HashMainPolicies({
  mainPromiseConfiguration
}: {
  mainPromiseConfiguration: MainPoliciesConfiguration;
}) {
  const history = useHistory();
  return (
    <div
      style={{
        padding: 36,
        backgroundColor: "#F1F1F3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div style={{ fontSize: 24, fontWeight: "bold", paddingBottom: 20 }}>
        주요 공약
      </div>
      {mainPromiseConfiguration.내용 &&
        mainPromiseConfiguration.내용.map((mainPromise, i) => {
          return (
            <div
              key={mainPromise + i}
              style={{
                paddingBottom: 5,
                fontSize: 16,
                fontWeight: "bold",
                color: GRAY_DARKER
              }}
            >
              {mainPromise}
            </div>
          );
        })}
      <div
        onClick={() => {
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(
                history.location.search,
                "2021서울시장선거_공약비교"
              )
          );
        }}
        style={{
          cursor: "pointer",
          padding: "20px 0px 0px",
          fontWeight: "bold",
          color: APPLE_MORE
        }}
      >
        공약 더 자세히 살펴보기 <span style={{ fontWeight: "normal" }}> ❯</span>
      </div>
    </div>
  );
}
