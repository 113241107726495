/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { VictoryBar, VictoryGroup, VictoryLabel, VictoryStack } from "victory";
import { oxoColors } from "../../../constants/enums";
import { CardAnswerStatsSchema } from "../../../constants/firestore_schema";
import TextPill from "../../foundation/Pill/TextPill.styled";

export default function MentionBarChart({
  cardAnswerStats,
  colorScale = [oxoColors.O, oxoColors.DUNNO, oxoColors.X]
}: {
  cardAnswerStats: CardAnswerStatsSchema;
  colorScale?: string[];
}) {
  if (!cardAnswerStats) {
    return null; // cannot be the case.
  }

  const stats = [
    {
      x: 0,
      y: cardAnswerStats.o || 0
    },
    {
      x: 0,
      y: cardAnswerStats["?"] || 0
    },
    {
      x: 0,
      y: cardAnswerStats.x || 0
    }
  ];

  const sum = cardAnswerStats.count;

  const barStyle = { labels: { fill: "white", fontSize: 20 } };
  const generateLabel = (datum: { y: number }) => {
    if ((datum.y / sum) * 100 > 7) {
      return ((datum.y / sum) * 100).toFixed(0) + "%";
    } else {
      return null;
    }
  };
  return (
    <>
      <div
        css={css`
          width: 450px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <TextPill backgroundColor={oxoColors.O}>긍정적</TextPill>
          <div
            css={css`
              padding-left: 8px;
              color: ${oxoColors.O};
            `}
          >
            {generateLabel(stats[0])}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <TextPill backgroundColor={oxoColors.DUNNO}>중립</TextPill>
          <div
            css={css`
              padding-left: 8px;
              color: ${oxoColors.DUNNO};
            `}
          >
            {generateLabel(stats[1])}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <TextPill backgroundColor={oxoColors.X}>부정적</TextPill>
          <div
            css={css`
              padding-left: 8px;
              color: ${oxoColors.X};
            `}
          >
            {generateLabel(stats[2])}
          </div>
        </div>
      </div>
      <div>
        <VictoryGroup height={90} width={750} padding={0}>
          <VictoryStack horizontal colorScale={colorScale}>
            <VictoryBar
              data={[stats[0]]}
              labels={({ datum }) => datum.y}
              style={barStyle}
              labelComponent={<VictoryLabel dy={-12} dx={-34} />}
            />
            <VictoryBar
              data={[stats[1]]}
              labels={({ datum }) => datum.y}
              style={barStyle}
              labelComponent={<VictoryLabel dy={-12} dx={-34} />}
            />
            <VictoryBar
              data={[stats[2]]}
              labels={({ datum }) => datum.y}
              style={barStyle}
              labelComponent={<VictoryLabel dy={-12} dx={-34} />}
            />
          </VictoryStack>
        </VictoryGroup>
      </div>
    </>
  );
}
