import "core-js/stable";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { hydrate, render } from "react-dom";
import ReactPixel from "react-facebook-pixel";
import { HashRouter } from "react-router-dom";
import "regenerator-runtime/runtime";
import App from "./components/foundation/App/App";
import "./utils/CSS/index.css";
import * as serviceWorker from "./serviceWorker";

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
};
ReactPixel.init("2519348554795014", null, options);
ReactPixel.pageView(); // For tracking page view

function startApp() {
  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(
      <HashRouter>
        <App />
      </HashRouter>,
      rootElement
    );
  } else {
    render(
      <HashRouter>
        <App />
      </HashRouter>,
      rootElement
    );
  }
}

startApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
