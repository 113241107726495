import React, { useContext } from "react";
import { AnswerSymbol, oxoColors, UserAnswer } from "../../../constants/enums";
import {
  MapCoordinatesSchema,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import UserMap, {
  SeriesType
} from "../../foundation/Charts/UserMap/UserMap-victory";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import QuestionChart from "../../topic/Question/QuestionChart/QuestionChart";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";

export default function AnswerMap({
  cardId,
  questionAnswers,
  currentUserMetadata
}: {
  cardId: string;
  questionAnswers: QuestionAnswerMapSchema;
  currentUserMetadata: UserProfileMetadataSchema | undefined;
}) {
  let series = undefined;
  const dataProps = useContext(DataPropsContext);

  if (!questionAnswers || !dataProps) {
    return (
      <div style={{ padding: "20px" }}>
        <DocumentSkeleton />
      </div>
    );
  }

  if (questionAnswers && dataProps) {
    series = getMapSeries(
      questionAnswers,
      dataProps.mapCoordinates,
      currentUserMetadata
    );
  }

  const questionChartStats: any = {};
  questionChartStats[UserAnswer.O] = 0;
  questionChartStats[UserAnswer.X] = 0;
  questionChartStats[UserAnswer.DUNNO] = 0;

  let isQuestionChartStatsEmpty = undefined;

  if (questionAnswers) {
    Object.values(questionAnswers).forEach(questionAnswer => {
      questionChartStats[UserAnswer.O] += questionAnswer.countO;
      questionChartStats[UserAnswer.X] += questionAnswer.countX;
      questionChartStats[UserAnswer.DUNNO] += questionAnswer.countDunno;
    });

    isQuestionChartStatsEmpty =
      questionChartStats[UserAnswer.O] === 0 &&
      questionChartStats[UserAnswer.X] === 0 &&
      questionChartStats[UserAnswer.DUNNO] === 0;
  }

  const answerCount =
    (questionAnswers &&
      Object.values(questionAnswers).reduce(
        (sum, questionAnswer) =>
          sum +
          questionAnswer.countX +
          questionAnswer.countO +
          questionAnswer.countDunno,
        0
      )) ||
    0;

  return (
    <div style={{ marginTop: "10px" }}>
      <UserMap series={series} />
      {questionChartStats && !isQuestionChartStatsEmpty && (
        <div style={{ marginTop: "20px" }}>
          <QuestionChart cardId={cardId} />
        </div>
      )}
      =
    </div>
  );
}

function getMapSeries(
  questionAnswers: QuestionAnswerMapSchema,
  mapCoordinates: MapCoordinatesSchema,
  userProfileMetadata: UserProfileMetadataSchema | undefined
) {
  const seriesData: { [seriesName: string]: number[][] } = {
    me: [],
    unanswered: []
  };

  const mapWidth = Math.min(window.screen.width, 750);

  Object.keys(questionAnswers).forEach(coordinateId => {
    seriesData[UserAnswer.O] = [];
    seriesData[UserAnswer.X] = [];
    seriesData[UserAnswer.DUNNO] = [];

    Object.keys(questionAnswers).forEach(coordinateId => {
      const arr = [
        questionAnswers[coordinateId].countO,
        questionAnswers[coordinateId].countX,
        questionAnswers[coordinateId].countDunno
      ];
      const i = arr.indexOf(Math.max(...arr));

      if (i === 0) {
        seriesData[UserAnswer.O].push([
          questionAnswers[coordinateId].x,
          questionAnswers[coordinateId].y
        ]);
      } else if (i === 1) {
        seriesData[UserAnswer.X].push([
          questionAnswers[coordinateId].x,
          questionAnswers[coordinateId].y
        ]);
      } else if (i === 2) {
        seriesData[UserAnswer.DUNNO].push([
          questionAnswers[coordinateId].x,
          questionAnswers[coordinateId].y
        ]);
      }
    });
  });

  const defaultSize = mapWidth / 350;

  Object.keys(mapCoordinates).map(coordinateId => {
    const mapCoordinate = mapCoordinates[coordinateId];
    seriesData["unanswered"].push([mapCoordinate.x, mapCoordinate.y]);
  });

  const series: SeriesType[] = [
    {
      name: AnswerSymbol.O,
      data: seriesData[UserAnswer.O] || [],
      color: oxoColors.O,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.DUNNO,
      data: seriesData[UserAnswer.DUNNO] || [],
      color: oxoColors.DUNNO,
      size: defaultSize + 1
    },
    {
      name: AnswerSymbol.X,
      data: seriesData[UserAnswer.X] || [],
      color: oxoColors.X,
      size: defaultSize + 1
    },
    {
      name: "응답 안 함",
      data: seriesData["unanswered"] || [],
      color: oxoColors.OTHERS,
      size: defaultSize,
      opacity: 0.4
    }
  ];

  if (userProfileMetadata) {
    series.unshift({
      name: "나",
      data: [[userProfileMetadata.x, userProfileMetadata.y]],
      color: "#333333",
      label: "나",
      size: 4
    });
  }

  return series;
}
