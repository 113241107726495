/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import firebase from "firebase/compat";
import {
  FirestoreCollection,
  PoliticianAssetBalanceSchema
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import { MICROS } from "../../../utils/assets";
import { css } from "@emotion/react";
import { BUTTON_CSS } from "./PoldiSelector";

const TIME_PER_PAGE = 100;
export default function PoldiTimeSeries({
  poldi
}: {
  poldi: string | undefined;
}) {
  const [page, setPage] = useState<number>(1);
  const [assetBalances, setAssetBalances] =
    useState<PoliticianAssetBalanceSchema[]>();
  useEffect(() => {
    if (!poldi) {
      return;
    }
    firebase
      .firestore()
      .collection(
        FirestoreCollection.POLITICIAN_ASSET_BALANCE_DAILY_TIME_SERIES
      )
      .where("politicianId", "==", poldi)
      .orderBy("createdAt", "desc")
      .limit(TIME_PER_PAGE * page)
      .get()
      .then(getDocsFromSnapshot)
      .then(setAssetBalances);
  }, [poldi, page]);

  if (!assetBalances) {
    return null;
  }

  return (
    <div>
      <div>{poldi}</div>
      <div>
        <table>
          {assetBalances.map(assetBalance => {
            return (
              <tr key={assetBalance.id}>
                <td
                  css={css`
                    width: 150px;
                  `}
                >
                  {assetBalance.dateInKorea}
                </td>
                <td>{(assetBalance.amountMicros / MICROS) * -1}</td>
              </tr>
            );
          })}
        </table>
      </div>
      <div
        css={css`
          ${BUTTON_CSS};
          display: flex;
          justify-content: center;
        `}
        onClick={() => {
          setPage(p => p + 1);
        }}
      >
        Load 100 more days
      </div>
    </div>
  );
}
