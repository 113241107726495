/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";

import firebase from "firebase/compat";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import HashtagTimeSeriesAnswers from "./HashtagTimeSeriesAnswers";
import { CSS_TD } from "../DataExplorerView/shared";
import HashtagTimeSeriesTribeAnswers from "./HashtagTimeSeriesTribeAnswers";
import HashtagTimeSeriesGenerationAnswers from "./HashtagTimeSeriesGenerationAnswers";
import HashtagTimeSeriesGenderAnswers from "./HashtagTimeSeriesGenderAnswers";

import HashTagTimeSeriesGraphChart from "./HashTagTimeSeriesGraphChart";
import { ACCENT_COLOR } from "../../../constants/colors";

export default function HashtagTimeSeriesView() {
  const [cards, setCards] = useState<CardSchema[]>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("hashtags", "array-contains", "#대통령실_가정통신문")
      .orderBy("createdAt", "asc")
      .get()
      .then(getDocsFromSnapshot)
      .then(setCards);

    return () => {};
  }, []);

  if (!cards) {
    return null;
  }

  return (
    <div
      css={css`
        padding: 0 20px;
      `}
    >
      <h1 css={CSS_TD}>대통령 가정 통신문 통계</h1>

      <div
        css={css`
          width: 1100px;
          padding: 30px;
          border: 2px solid ${ACCENT_COLOR};
        `}
      >
        <HashTagTimeSeriesGraphChart cards={cards} />
      </div>

      <div>
        <h2 css={CSS_TD}>주간 총 응답 통계</h2>
        <HashtagTimeSeriesAnswers cards={cards} />
      </div>

      <div>
        <h2 css={CSS_TD}>부족별 찬성율 통계</h2>
        <HashtagTimeSeriesTribeAnswers cards={cards} />
      </div>

      <div>
        <h2 css={CSS_TD}>세대별 통계</h2>
        <HashtagTimeSeriesGenerationAnswers cards={cards} />
      </div>

      <div>
        <h2 css={CSS_TD}>성별 통계</h2>
        <HashtagTimeSeriesGenderAnswers cards={cards} />
      </div>
    </div>
  );
}
