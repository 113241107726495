/** @jsxImportSource @emotion/react */

import firebase from "firebase/compat";

import React, { CSSProperties, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  GRAY,
  GRAY_DARK,
  GRAY_EXTRA_DARK,
  TRIBE_INFO_BY_ID
} from "../../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter,
  useIsAdmin
} from "../../../../utils/utils";
import KoreanDate from "../../../cards/CardComponents/KoreanDate";
import HashCommentAvatar from "../Comment/HashCommentAvatar";
import HashCommentName from "../Comment/HashCommentName";
import MiniBestComments from "../Comment/MiniBestComments";
import CommentToolsLine from "../Comment/CommentToolsLine";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import UserHead from "../../../cards/MiniCards/UserHead";
import UserName from "../../../profile/OXOUserProfile/UserName";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import SpeakerPosition from "../Comment/SpeakerPosition";
import SupporterPercentage from "../Comment/SupporterPercentage";
import MiniTodayTopicCard from "../../Today/MiniTodayTopicCard";
import MiniHashtagCard from "../../Today/MiniHashtagCard";
import { CardDisplayStatus } from "../../../topic/CardDisplayStatus";
import MiddleDot from "../../../foundation/common/MiddleDot";
import OXOText from "../../../utility/OXOText/OXOText";
import SupportingTribeIcon from "../Comment/SupportingTribeIcon";
import CardAnswerTribeChart from "../Comment/CardAnswerTribeChart";
import { SupportersContext } from "../../../foundation/Contexts/SupportersContext";
import CardAnswerSupporterChart from "../Comment/CardAnswerSupporterChart";
import CardStatsGenderAgeChart from "../../../cards/DetailViews/CardStatsGenderAgeChart";
import { css } from "@emotion/react";

export default function HashCommentBubble({
  cardId,
  showDetails = true,
  showAgeGender = true,
  hideFace = false,
  style
}: {
  cardId: string;
  showDetails?: boolean;
  showAgeGender?: boolean;
  hideFace?: boolean;
  style?: CSSProperties;
}) {
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const supporters = useContext(SupportersContext);
  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  const answer = cardAnswer?.answer;

  const isAdmin = useIsAdmin();
  const history = useHistory();
  const [windowWidth] = useWindowSize();
  const widthMargin = 80;

  if (!card || !cardAnswer || !card.title) {
    return null;
  }

  const SPEAKERS: string[] =
    card.type === CardType.TOPIC
      ? ["옥소"]
      : card.speakerHashTag
          ?.filter(speaker => speaker.length)
          .map(speaker => speaker.replace("#", "")) || ["방랑자"];

  const trimmedTitle: string = card.title?.replace(/["“”]/g, "");
  const speakerFromTitle: string | undefined = trimmedTitle?.split(" ")[0];
  const commentBefore: string =
    trimmedTitle?.slice((speakerFromTitle?.length || 0) + 1) || "";

  const comment: string =
    card.type === CardType.DEBATE && card.debateCommentMetadata
      ? card.debateCommentMetadata.comment
      : card.type === CardType.NEWS_TICKER
      ? card.newsTickerMetadata
        ? card.title
        : commentBefore
      : card.title;

  const publishedAt: string[] | undefined = card.publishedAt
    ? new Date(card.publishedAt).toLocaleString().split(" ")
    : undefined;

  let year, month, day, time;

  if (publishedAt) {
    [year, month, day, ...time] = publishedAt;
  }

  if (card.type === CardType.NEWS_TICKER && !card.displayToUsers && !isAdmin) {
    return null;
  }

  const answeredBubbleStyle = {
    color: "white",
    backgroundColor: GRAY_DARK
  };

  const tribeColor =
    currentUserMetadata?.tribeId &&
    TRIBE_INFO_BY_ID[currentUserMetadata.tribeId]
      ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
      : GRAY_DARK;
  const currentUserTribeColorBubbleStyle = {
    color: "white",
    backgroundColor: tribeColor
  };

  const authorColor =
    card.createdByTribeId && TRIBE_INFO_BY_ID[card.createdByTribeId]
      ? TRIBE_INFO_BY_ID[card.createdByTribeId].heavyColor
      : GRAY_DARK;
  const createdByTribeColorBubbleStyle = {
    color: "white",
    backgroundColor: authorColor
  };

  const bubbleStyle = answer
    ? answeredBubbleStyle
    : card.type === CardType.POST
    ? createdByTribeColorBubbleStyle
    : currentUserTribeColorBubbleStyle;

  return (
    <div
      css={{
        display: "flex",
        paddingBottom: 12,
        justifyContent: "center",
        ...style
      }}
    >
      <div
        css={{
          display: "flex",
          textAlign: "left",
          width: "100%",
          margin: "auto"
        }}
      >
        {!hideFace && (
          <div>
            {card.type === CardType.POST ? (
              <div css={{ paddingRight: 10 }}>
                <UserHead
                  userId={card.createdBy}
                  expression={card.postMetadata?.expression}
                />
              </div>
            ) : (
              SPEAKERS && (
                <div
                  css={{
                    marginRight: 10
                  }}
                >
                  {SPEAKERS.map((speaker, i, speakers) => (
                    <div
                      css={{
                        paddingRight: 5,
                        marginBottom:
                          speakers.length > 1 && i !== speakers.length - 1
                            ? -20
                            : 0,
                        position: "relative"
                      }}
                      key={speaker}
                    >
                      <HashCommentAvatar speaker={speaker} size={42} />
                      <div css={{ position: "absolute", top: 25, right: 0 }}>
                        <SupportingTribeIcon cardId={speaker} size={16} />
                      </div>
                    </div>
                  ))}
                </div>
              )
            )}
          </div>
        )}
        <div
          css={{
            display: "flex",
            flexGrow: 0,
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}
          >
            <div>
              <div
                css={{
                  fontWeight: "bolder",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  paddingTop: 2
                }}
              >
                {card.type === CardType.POST ? (
                  <div css={{ display: "flex", alignItems: "flex-end" }}>
                    <UserName
                      profileUserId={card.createdBy}
                      style={{ marginRight: 5 }}
                    />

                    <div
                      css={{
                        display: "flex",
                        fontWeight: "normal",
                        fontSize: 12,
                        color: GRAY_DARK
                      }}
                    >
                      <KoreanDate
                        timestamp={card.publishedAt || card.createdAt}
                      />
                    </div>
                  </div>
                ) : (
                  SPEAKERS && (
                    <div>
                      <div
                        css={{
                          display: "flex",
                          alignItems: "flex-end",
                          flexShrink: 0,
                          fontSize: 12,
                          color: GRAY_DARK,
                          fontWeight: "normal"
                        }}
                      >
                        {SPEAKERS.map((speaker, i, speakers) => (
                          <div
                            css={{ display: "flex", fontWeight: "bold" }}
                            key={speaker}
                          >
                            <HashCommentName
                              speaker={speaker}
                              style={{ fontSize: 16, color: "black" }}
                            />
                            {speakers.length > 1 &&
                              i !== speakers.length - 1 && <MiddleDot />}
                          </div>
                        ))}

                        {SPEAKERS.length === 1 && (
                          <div>
                            <SpeakerPosition
                              speaker={SPEAKERS[0]}
                              addMiddleDotIfPresent
                            />
                          </div>
                        )}
                        {SPEAKERS.length === 1 && (
                          <div css={{ display: "flex" }}>
                            <SupporterPercentage cardId={SPEAKERS[0]} />
                          </div>
                        )}

                        <CardDisplayStatus card={card} hideOnDisplay />
                        <MiddleDot />
                        <KoreanDate
                          timestamp={card.publishedAt || card.createdAt}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>

              {card.type === CardType.TOPIC ? (
                <div
                  css={{
                    marginTop: 5,
                    cursor: "pointer"
                  }}
                  key={cardId}
                  onClick={e => {
                    firebase
                      .analytics()
                      .logEvent("v4_click_hash_comment_words", {
                        event_category: "hashcard",
                        event_label: card.title
                      });
                    e.stopPropagation();
                    history.push(
                      history.location.pathname +
                        addToCardIdStackUrl(history.location.search, cardId)
                    );
                  }}
                >
                  <MiniTodayTopicCard
                    key={cardId}
                    cardId={cardId}
                    style={{
                      boxShadow: "none",
                      borderRadius: 16
                    }}
                  />
                </div>
              ) : card.type === CardType.HASHTAG_CARD ? (
                <div css={{ marginTop: 10 }} key={cardId}>
                  <MiniHashtagCard key={cardId} cardId={cardId} style={{}} />
                </div>
              ) : (
                <div
                  css={{
                    borderRadius: 14,
                    padding: "12px 17px",
                    marginTop: 5,
                    position: "relative",
                    cursor: "pointer",
                    ...bubbleStyle
                  }}
                  onClick={e => {
                    firebase
                      .analytics()
                      .logEvent("v4_click_hash_comment_words", {
                        event_category: "hashcard",
                        event_label: card.title
                      });
                    e.stopPropagation();
                    history.push(
                      history.location.pathname +
                        addToCardIdStackUrl(history.location.search, cardId)
                    );
                  }}
                >
                  <div
                    css={{
                      minHeight: 20,
                      fontSize: 15,
                      fontWeight: "normal",
                      lineHeight: 1.5
                    }}
                  >
                    <OXOText
                      text={hashtagTextAdapter(comment)}
                      useDivForParagraph
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
            `}
          >
            <CommentToolsLine cardId={cardId} />
            <div
              css={css`
                margin-right: 20px;
                color: ${GRAY_DARK};
              `}
            >
              {card?.hashtags?.join(" ")}
            </div>
          </div>

          {showDetails && (
            <div
              css={{
                marginTop: 2,
                marginBottom: 10,
                width: "100%",
                paddingRight: 5,
                color: GRAY_EXTRA_DARK
              }}
            >
              <CardAnswerTribeChart cardId={cardId} />
            </div>
          )}

          {supporters &&
            Object.keys(supporters).map(supportingHashtag => (
              <div
                css={{
                  marginTop: 10,
                  marginBottom: 10,
                  width: "100%",
                  paddingRight: 5,
                  fontSize: 14,
                  textAlign: "center",
                  color: GRAY_EXTRA_DARK
                }}
                key={supportingHashtag}
              >
                <div>{supportingHashtag} 지지자들</div>
                <CardAnswerSupporterChart
                  cardId={cardId}
                  supporters={supporters[supportingHashtag]}
                />
              </div>
            ))}

          {showAgeGender && (
            <div
              css={{
                marginTop: 10,
                marginBottom: 10,
                width: "100%",
                maxWidth: 450,
                paddingRight: 5,
                color: GRAY_EXTRA_DARK
              }}
            >
              <CardStatsGenderAgeChart cardId={cardId} />
            </div>
          )}

          {showDetails && (
            <div
              css={{}}
              onClick={e => {
                firebase.analytics().logEvent("v4_click_hash_comment_words", {
                  event_category: "hashcard",
                  event_label: card.title
                });
                e.stopPropagation();
                history.push(
                  history.location.pathname +
                    addToCardIdStackUrl(
                      history.location.search,
                      cardId + "~tab:comments"
                    )
                );
              }}
            >
              <div
                css={{
                  fontSize: "13px",
                  color: GRAY,
                  paddingBottom: 2,
                  marginTop: "3px",
                  cursor: "pointer"
                }}
              >
                댓글 {card && card.comments}개 모두 보기
              </div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 4
                }}
              >
                <MiniBestComments cardId={cardId} maxWidth={450} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
