/** @jsxImportSource @emotion/react */

import React, { ElementType } from "react";
import ReactMarkdown from "react-markdown";
import { truncateLongSentence } from "../../../utils/utils";
import MarkdownImageRenderer from "./MarkdownImageRenderer";
// @ts-ignore
import { ReactTinyLink } from "react-tiny-link";
import { ErrorBoundaryIgnore } from "../../foundation/App/ErrorBoundaryIgnore";
import HashtagTaggingHashtag from "../../views/HashCardView/common/HashtagTaggingHashtag";
import HashtagTaggingAtSign from "../../views/HashCardView/common/HashtagTaggingAtSign";
import HashtagTaggingLink from "../../views/HashCardView/common/HashtagTaggingLink";
import gfm from "remark-gfm";

export default function OXOText({
  text,
  truncateChars,
  disableNewLine = false,
  showTrimmedTextIcon = false,
  useDivForParagraph = false
}: {
  text: string | undefined;
  truncateChars?: number;
  disableNewLine?: boolean;
  showTrimmedTextIcon?: boolean;
  useDivForParagraph?: boolean;
}) {
  const renderers: { [nodeType: string]: ElementType } = {
    p: useDivForParagraph ? "div" : "p",
    img: ({
      alt,
      src,
      title
    }: {
      alt?: string;
      src?: string;
      title?: string;
    }) => <MarkdownImageRenderer src={src} />,
    em: ({ node, children }) => {
      const token = children[0];
      if (!token.startsWith) {
        return children;
      }
      if (token.startsWith("#")) {
        return <HashtagTaggingHashtag text={token} />;
      }

      if (token.startsWith("@")) {
        return <HashtagTaggingAtSign text={token} />;
      }

      return <HashtagTaggingLink text={token} />;
    },
    a: url => {
      return (
        <div
          style={{
            wordBreak: "break-all",
            cursor: "pointer"
          }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            window.open(encodeURI(url.href), "_system");
          }}
        >
          {url.href && (
            <span style={{ display: "flex", width: "100%" }}>
              <ErrorBoundaryIgnore
                displayWhenError={<span>{url.children}</span>}
              >
                <ReactTinyLink
                  cardSize="large"
                  showGraphic={true}
                  maxLine={1}
                  minLine={1}
                  url={url.href}
                  proxyUrl={
                    "https://us-central1-oxopolitics-korea.cloudfunctions.net/cors?url="
                  }
                />
              </ErrorBoundaryIgnore>
            </span>
          )}
        </div>
      );
    }
  };

  if (!text || !text.replace) {
    return <div />;
  }

  let processedText: string = text;

  if (truncateChars) {
    processedText = truncateLongSentence(processedText, truncateChars, true);
  }

  if (showTrimmedTextIcon && text !== processedText) {
    processedText = processedText + " 더 보기";
  }

  processedText = processedText
    .replace(/~+/g, "~")
    .split("\n")
    .map((item, i) => {
      return `${item}`;
    })
    .join(disableNewLine ? " " : "  \n");

  return (
    <ReactMarkdown
      className={"markdown_display"}
      components={renderers}
      linkTarget={"_system"}
      remarkPlugins={[gfm]}
    >
      {processedText}
    </ReactMarkdown>
  );
}
