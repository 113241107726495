import React from "react";
import { CardSchema } from "../../../constants/firestore_schema";
import HashCommentBubble from "../../views/HashCardView/common/HashCommentBubble";

export default function HashCommentBubbles({ cards }: { cards: CardSchema[] }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "hidden"
      }}
    >
      {cards.map(card => (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            paddingTop: 10
          }}
          key={card.id}
        >
          <HashCommentBubble cardId={card.id} key={card.id} />
        </div>
      ))}
    </div>
  );
}
