/** @jsxImportSource @emotion/react */

import { AnswerCountsSchema } from "../../../constants/firestore_schema";
import React from "react";
import { css } from "@emotion/react";

export function getStatTds(cardAnswerStat: AnswerCountsSchema, key: string) {
  if (!cardAnswerStat) {
    return (
      <>
        <td key={key + "o"} />
        <td key={key + "d"} />
        <td key={key + "x"} />
        <td key={key + "c"} />
        <td key={key + "cr"} />
      </>
    );
  }
  return (
    <>
      <td key={key + "o"}>{cardAnswerStat["o"]}</td>
      <td key={key + "d"}>{cardAnswerStat["?"]}</td>
      <td key={key + "x"}>{cardAnswerStat["x"]}</td>
      <td key={key + "c"}>{cardAnswerStat["count"]}</td>
      <td key={key + "cr"}>
        {(cardAnswerStat["o"] / (cardAnswerStat["count"] || 1)).toFixed(2)}
      </td>
    </>
  );
}

export const COUNT_BACKGROUND = css`
  background-color: #ffffaa;
`;

export const RATIO_BACKGROUND = css`
  background-color: #aaffaa;
`;

export const CSS_TD = css`
  padding: 10px;
`;

export function getStatTdsFull(
  cardAnswerStat: AnswerCountsSchema,
  key: string
) {
  if (!cardAnswerStat) {
    return (
      <>
        <td key={key + "o"} css={CSS_TD} />
        <td key={key + "d"} css={CSS_TD} />
        <td key={key + "x"} css={CSS_TD} />
        <td key={key + "c"} css={CSS_TD} />
        <td key={key + "cr"} css={CSS_TD} />
        <td key={key + "cd"} css={CSS_TD} />
        <td key={key + "cx"} css={CSS_TD} />
      </>
    );
  }
  return (
    <>
      <td key={key + "o"} css={[COUNT_BACKGROUND, CSS_TD]}>
        {cardAnswerStat["o"]}
      </td>
      <td key={key + "d"} css={[COUNT_BACKGROUND, CSS_TD]}>
        {cardAnswerStat["?"]}
      </td>
      <td key={key + "x"} css={[COUNT_BACKGROUND, CSS_TD]}>
        {cardAnswerStat["x"]}
      </td>
      <td key={key + "c"} css={[COUNT_BACKGROUND, CSS_TD]}>
        {cardAnswerStat["count"]}
      </td>
      <td key={key + "cr"} css={[RATIO_BACKGROUND, CSS_TD]}>
        {((cardAnswerStat["o"] / (cardAnswerStat["count"] || 1)) * 100).toFixed(
          1
        )}
        %
      </td>
      <td key={key + "cd"} css={[RATIO_BACKGROUND, CSS_TD]}>
        {((cardAnswerStat["?"] / (cardAnswerStat["count"] || 1)) * 100).toFixed(
          1
        )}
        %
      </td>
      <td key={key + "cx"} css={[RATIO_BACKGROUND, CSS_TD]}>
        {((cardAnswerStat["x"] / (cardAnswerStat["count"] || 1)) * 100).toFixed(
          1
        )}
        %
      </td>
    </>
  );
}
