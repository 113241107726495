import { ThumbUp, ThumbUpOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GRAY_MEDIUM } from "../../../constants/enums";
import {
  CommentSchema,
  FirestoreCollection,
  LikeCountsSchema,
  LikeSchema,
  PointFieldName,
  ReferenceType,
  UserPrivilege,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { addCommentLikeNotification } from "../../../lib/notification_internal_lib";
import { addUserProfileMetadataCount } from "../../../lib/points";
import {
  checkAndRedirectForLogin,
  getDocsFromSnapshot,
  getMapFromSnapshots,
  groupBy,
  listToMap,
  mapValues,
  parseDoc,
  removeIdKey
} from "../../../utils/utils";
import { DataPropsContext } from "../Contexts/DataPropsContext";

export default function LikeButton({
  referenceId,
  referenceReferenceId,
  referenceParentId,
  referenceCreatedAt,
  referenceType,
  referenceUserId,
  referenceUserTribeId,
  likeButtonSize = 18,
  left = 6,
  disableClick = false,
  disableCount = false,
  likeCountSize = 18
}: {
  referenceId: string;
  referenceReferenceId?: string;
  referenceType: ReferenceType;
  referenceCreatedAt: number;
  referenceUserId: string;
  referenceUserTribeId: string;
  likeButtonSize?: number;
  left?: number;
  disableClick?: boolean;
  disableCount?: boolean;
  likeCountSize?: number;
  marginBottom?: number;
  referenceParentId?: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const history = useHistory();
  const [currentUserMetadata, setCurrentUserMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .onSnapshot(snapshot =>
          setCurrentUserMetadata(removeIdKey(parseDoc(snapshot)))
        );
      return () => unsubscribe();
    }
  }, [currentUserId]);

  const [likes, setLikes] = useState<{
    [likeId: string]: LikeSchema;
  }>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.LIKES)
      .where("referenceId", "==", referenceId)
      .onSnapshot(snapshot => setLikes(getMapFromSnapshots(snapshot)));
    return () => unsubscribe();
  }, []);

  const likeButtonStyle: CSSProperties = {
    color: "#B0B3B2",
    marginLeft: left + "px",
    fontSize: likeButtonSize + "px",
    fontWeight: "bold"
  };

  if (!likes || referenceUserId === currentUserId) {
    return null;
  }

  function onThumbClick(e: React.MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    if (disableClick) {
      return;
    }
    if (
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!currentUserMetadata) {
      return;
    }

    return likeClicked(
      referenceId,
      referenceType,
      referenceUserId,
      referenceUserTribeId,
      currentUserMetadata,
      referenceCreatedAt,
      referenceReferenceId,
      currentUserId,
      referenceParentId
    );
  }

  return (
    <div
      style={{
        width: 25,
        height: "100%",
        display: "flex",
        alignItems: "flex-end",
        marginBottom: -3.5
      }}
      onClick={onThumbClick}
    >
      <span
        style={{
          color: GRAY_MEDIUM,
          display: "inline-block",
          marginRight: 3,

          paddingRight: 0,
          whiteSpace: "nowrap",
          cursor: "pointer"
        }}
      >
        {likes && likes[currentUserId + referenceId] ? (
          <ThumbUp style={likeButtonStyle} />
        ) : (
          <ThumbUpOutlined style={likeButtonStyle} />
        )}
      </span>
      {/* <>
        {!disableCount && (
          <span
            style={{
              cursor: "pointer",
              fontSize: likeCountSize + "px",
              fontWeight: "bolder",
              color: GRAY_PROFILE
            }}
            onClick={onThumbClick}
          >
            {likes && Object.keys(likes).length > 0
              ? Object.keys(likes).length
              : ""}
          </span>
        )}
      </> */}
    </div>
  );
}

function updateLikeCounts(
  likes: any[],
  referenceId: string,
  referenceType: ReferenceType,
  referenceCreatedAt: number,
  referenceParentId?: string
) {
  const likeCountsByTribeId = mapValues(
    groupBy(likes, like => like.likeUserTribeId),
    likes => likes.length
  );
  const likeCountsData: LikeCountsSchema = {
    referenceId: referenceId,
    referenceType: referenceType,
    referenceCreatedAt: referenceCreatedAt,
    likeCounts: likes.length,
    likeCountsPerTribe: likeCountsByTribeId
  };

  if (referenceParentId) {
    likeCountsData.referenceParentId = referenceParentId;
  }

  firebase
    .firestore()
    .collection(FirestoreCollection.LIKE_COUNTS)
    .doc(referenceId)
    .set(likeCountsData);
}

export function likeClicked(
  referenceId: string,
  referenceType: ReferenceType,
  referenceUserId: string,
  referenceUserTribeId: string,
  userProfileMetadata: UserProfileMetadataSchema,
  referenceCreatedAt: number,
  referenceReferenceId?: string,
  currentUserId?: string,
  referenceParentId?: string
) {
  function updateCommentStats(isAddingLike: boolean, likes: LikeSchema[]) {
    firebase
      .firestore()
      .collection(FirestoreCollection.COMMENTS)
      .doc(referenceId)
      .get()
      .then(parseDoc)
      .then(removeIdKey)
      .then((comment: CommentSchema) => {
        comment.likeUserAndTribes = listToMap(
          likes,
          (like: LikeSchema) => like.likeUserId,
          (like: LikeSchema) =>
            like.isSpecialUserLike ? "speical" : like.likeUserTribeId
        );
        firebase
          .firestore()
          .collection(FirestoreCollection.COMMENTS)
          .doc(referenceId)
          .set(comment);
      });

    if (isAddingLike && referenceReferenceId && currentUserId) {
      addCommentLikeNotification(
        referenceId,
        referenceReferenceId,
        referenceUserId,
        likes.length,
        currentUserId
      );
    }
  }

  function updatePostStats(isAddingLike: boolean, likes: LikeSchema[]) {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(referenceId)
      .set(
        {
          postMetadata: {
            likeUserAndTribes: listToMap(
              likes,
              (like: LikeSchema) => like.likeUserId,
              (like: LikeSchema) =>
                like.isSpecialUserLike ? "speical" : like.likeUserTribeId
            )
          }
        },
        { merge: true }
      );

    if (isAddingLike && referenceReferenceId && currentUserId) {
      addCommentLikeNotification(
        referenceId,
        referenceReferenceId,
        referenceUserId,
        likes.length,
        currentUserId
      );
    }
  }

  firebase
    .firestore()
    .collection(FirestoreCollection.LIKES)
    .doc(currentUserId + referenceId)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        firebase
          .firestore()
          .collection(FirestoreCollection.LIKES)
          .doc(currentUserId + referenceId)
          .delete()
          .then(() => {
            firebase
              .firestore()
              .collection(FirestoreCollection.LIKES)
              .where("referenceId", "==", referenceId)
              .get()
              .then(getDocsFromSnapshot)
              .then(likes => {
                updateLikeCounts(
                  likes,
                  referenceId,
                  referenceType,
                  referenceCreatedAt,
                  referenceParentId
                );

                if (referenceType === ReferenceType.COMMENT) {
                  updateCommentStats(false, likes);
                }
                if (referenceType === ReferenceType.CARD) {
                  updatePostStats(false, likes);
                }
              });
          })
          .then(() => {
            addUserProfileMetadataCount(
              PointFieldName.countLikes,
              referenceUserId,
              userProfileMetadata.tribeId,
              -1
            );
          });
      } else {
        firebase
          .firestore()
          .collection(FirestoreCollection.LIKES)
          .doc(currentUserId + referenceId)
          .set({
            likeUserId: currentUserId,
            likeUserTribeId: userProfileMetadata.tribeId,
            likeUserX: userProfileMetadata.x,
            likeUserY: userProfileMetadata.y,
            referenceId: referenceId,
            referenceUserId: referenceUserId,
            referenceUserTribeId: referenceUserTribeId,
            referenceType:
              referenceType === ReferenceType.COMMENT
                ? ReferenceType.COMMENT
                : ReferenceType.CARD,
            createdAt: Date.now(),
            isSpecialUserLike: !!(
              userProfileMetadata.privileges &&
              userProfileMetadata.privileges.includes(
                UserPrivilege.REAL_NAME_USER
              )
            )
          } as LikeSchema)
          .then(() => {
            firebase
              .firestore()
              .collection(FirestoreCollection.LIKES)
              .where("referenceId", "==", referenceId)
              .get()
              .then(getDocsFromSnapshot)
              .then(likes => {
                updateLikeCounts(
                  likes,
                  referenceId,
                  referenceType,
                  referenceCreatedAt,
                  referenceParentId
                );

                if (referenceType === ReferenceType.COMMENT) {
                  updateCommentStats(true, likes);
                }
                if (referenceType === ReferenceType.CARD) {
                  updatePostStats(true, likes);
                }
              });
          })
          .then(() => {
            addUserProfileMetadataCount(
              PointFieldName.countLikes,
              referenceUserId,
              userProfileMetadata.tribeId
            );
          });
      }
    });
}
