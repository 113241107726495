/** @jsxImportSource @emotion/react */

import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection,
  ReportTextSchema
} from "../../../constants/firestore_schema";
import { parseDoc } from "../../../utils/utils";
import tribe0 from "./imgs/tribe0.png";
import tribe1 from "./imgs/tribe1.png";
import tribe2 from "./imgs/tribe2.png";
import tribe3 from "./imgs/tribe3.png";
import tribe4 from "./imgs/tribe4.png";
import tribe5 from "./imgs/tribe5.png";
import PortraitReportPage from "./PortraitReportPage";
import { css } from "@emotion/react";
import DashboardPage from "./DashboardPage";
import DailyDashboardDetailsPerson from "./detail_person/DailyDashboardDetailsPerson";
import DailyDashboardDetailsTopic from "./detail_topic/DailyDashboardDetailsTopic";
import DailyHotIssuesDetails from "./detail_hot_issue/DailyHotIssuesDetails";
import useSupporters from "../DailyReportsView/useSupporters";
import { SupportersContext } from "../../foundation/Contexts/SupportersContext";

export const TRIBE_IMAGES: { [tribeId: string]: string } = {
  "0": tribe0,
  "1": tribe1,
  "2": tribe2,
  "3": tribe3,
  "4": tribe4,
  "5": tribe5
};

export default function DailyReportPortrait({
  personHashtag,
  competitorHashtag
}: {
  personHashtag: string;
  competitorHashtag: string;
}) {
  const [reportText, setReportText] = useState<ReportTextSchema>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.REPORT_TEXT)
      .doc(personHashtag)
      .onSnapshot(snapshot => {
        setReportText(parseDoc(snapshot));
      });
    return () => {
      unsubscriber();
    };
  }, []);

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(personHashtag)
      .get()
      .then(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => {};
  }, [personHashtag]);

  const supporterData = useSupporters(personHashtag, competitorHashtag);

  if (!card || !personHashtag || !reportText || !supporterData) {
    if (!personHashtag) {
      console.log("cardID is missing in Report!");
    }
    return null;
  }

  return (
    <SupportersContext.Provider value={supporterData}>
      <div
        css={css`
          background-color: white;
        `}
      >
        <PortraitReportPage>
          <DashboardPage
            personHashtag={personHashtag}
            competitorHashtag={competitorHashtag}
          />
        </PortraitReportPage>
        <PortraitReportPage>
          <DailyDashboardDetailsPerson
            personHashtag={personHashtag}
            competitorHashtag={competitorHashtag}
          />
        </PortraitReportPage>
        <PortraitReportPage>
          <DailyDashboardDetailsTopic
            personHashtag={personHashtag}
            competitorHashtag={competitorHashtag}
          />
        </PortraitReportPage>

        <DailyHotIssuesDetails personHashtag={personHashtag} />
      </div>
    </SupportersContext.Provider>
  );
}
