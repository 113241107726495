/** @jsxImportSource @emotion/react */

import React, { ReactNode, useMemo } from "react";
import { GRAY_DARKER } from "../../../constants/enums";
import oxo_cow_logo from "../../foundation/Navigation/icons/oxo_cow_logo.svg";
import oxo_logo from "../../foundation/Navigation/icons/oxo_new_logo.svg";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../constants/data_platform_colors";

export default function PortraitReportPage({
  children
}: {
  children: ReactNode;
}) {
  const now = useMemo(() => new Date().valueOf(), []);
  const week = ["일", "월", "화", "수", "목", "금", "토"];

  return (
    <div
      css={css`
        width: 1000px;
        height: 1410px;
        page-break-before: always;
        page-break-after: always;
        padding: 20px 60px 20px 60px;
        display: flex;
        flex-direction: column;
      `}
    >
      <div css={css``}>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            css={css`
              text-align: left;
              font-size: 20px;
              font-weight: 700;
              line-height: 30px;
              color: ${DATA_PLATFORM_YELLOW_GRAY};
              letter-spacing: -0.04em;
            `}
          >
            {new Date(now).toLocaleDateString()}
            <span
              css={css`
                margin-left: 3px;
              `}
            >
              {week[new Date(now).getDay()]}
            </span>
          </div>
          <div
            css={css`
              text-align: right;
              font-size: 40px;
              font-weight: 400;
              color: ${DATA_PLATFORM_GREEN_GRAY};
              letter-spacing: -0.04em;
              font-family: "Lexend Deca", sans-serif;
            `}
          >
            <span>daily</span>
            <span
              css={css`
                color: ${DATA_PLATFORM_YELLOW_GRAY};
              `}
            >
              oxo
            </span>
            <span
              css={css`
                color: ${DATA_PLATFORM_RED_GRAY};
              `}
            >
              report
            </span>
          </div>
        </div>
      </div>

      <div
        css={css`
          flex-grow: 1;
          overflow: hidden;
        `}
      >
        {children}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <div style={{ padding: 5, paddingLeft: 10 }}>
            <img src={oxo_cow_logo} alt="oxo_cow_logo" />
          </div>
          <div>
            <img src={oxo_logo} alt="oxo_logo" />
          </div>
        </div>
        <div style={{ fontSize: 10, textAlign: "right", color: GRAY_DARKER }}>
          contact@oxopolitics.com
          <br />
          Copyright {new Date().getFullYear()} oxopolitics inc. All rights
          reserved.
        </div>
      </div>
    </div>
  );
}
