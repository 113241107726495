/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { getDocsFromSnapshot, groupBy, mapValues } from "../../../utils/utils";

export default function useSupporters(
  hashtag: string,
  additionalSupporter?: string
) {
  const [userAnswers, setUserAnswers] = useState<CardAnswerSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .where("cardId", "==", hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setUserAnswers);
    return () => {};
  }, [hashtag]);

  const [additionalSupporterUserAnswers, setAdditionalSupporterUserAnswers] =
    useState<CardAnswerSchema[]>();
  useEffect(() => {
    if (additionalSupporter) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWERS)
        .where("cardId", "==", additionalSupporter)
        .get()
        .then(getDocsFromSnapshot)
        .then(setAdditionalSupporterUserAnswers);
    }
    return () => {};
  }, [hashtag]);

  if (!userAnswers) {
    return undefined;
  }

  const supporters = mapValues(
    groupBy(userAnswers, cardAnswer => cardAnswer.answer),
    (cardAnswers: CardAnswerSchema[]) =>
      cardAnswers.map(cardAnswer => cardAnswer.userId)
  );

  const supporterData = {
    [hashtag]: supporters
  };

  if (additionalSupporter && additionalSupporterUserAnswers) {
    supporterData[additionalSupporter] = mapValues(
      groupBy(additionalSupporterUserAnswers, cardAnswer => cardAnswer.answer),
      (cardAnswers: CardAnswerSchema[]) =>
        cardAnswers.map(cardAnswer => cardAnswer.userId)
    );
  }

  return supporterData;
}
