/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import HashCommentName from "../HashCardView/Comment/HashCommentName";
import HashPagePortraitWithRating from "../HashCardView/Profile/HashPagePortraitWithRating";
import SpeakerPosition from "../HashCardView/Comment/SpeakerPosition";
import { css } from "@emotion/react";
import {
  CardAnswerStatsTimeSeriesSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { getDocsFromSnapshot } from "../../../utils/utils";
import { ApprovalRateLineArrowChart } from "./ApprovalRateArrowChart";

export function HashtagPersonOnTimeline({
  hashtag,
  hideIfNotPopular = false
}: {
  hashtag: string;
  hideIfNotPopular?: boolean;
}) {
  const [dailyStats, setDailyStats] =
    useState<CardAnswerStatsTimeSeriesSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_STATS_TIME_SERIES)
      .where("cardId", "==", hashtag)
      .orderBy("createdAt", "desc")
      .limit(5)
      .get()
      .then(getDocsFromSnapshot)
      .then(setDailyStats);
    return () => {};
  }, [hashtag]);

  if (hideIfNotPopular && (!dailyStats || dailyStats.length < 2)) {
    return null;
  }

  return (
    <div
      key={hashtag}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 140px;
        width: 100px;
        text-align: center;
        word-break: keep-all;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <HashPagePortraitWithRating cardId={hashtag} allowToRedirect />
        <div style={{ fontSize: 12, paddingTop: 5 }}>
          <HashCommentName speaker={hashtag} style={{ marginRight: 0 }} />
        </div>
        <div>
          <SpeakerPosition speaker={hashtag} style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        css={css`
          padding-top: 5px;
        `}
      >
        {dailyStats && dailyStats.length > 0 && (
          <ApprovalRateLineArrowChart dailyStats={dailyStats} />
        )}
      </div>
    </div>
  );
}
