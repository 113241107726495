export function useIsApp() {
  if (
    window.navigator.userAgent.startsWith("==Android==") ||
    window.navigator.userAgent.startsWith("==iOS==")
  ) {
    return true;
  } else {
    return false;
  }
}
