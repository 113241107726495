import React, { ErrorInfo, ReactElement } from "react";
import { FirestoreCollection } from "../../../constants/firestore_schema";
import firebase from "firebase/compat";

type ErrorProps = {
  // using `interface` is also ok
  displayWhenError: ReactElement;
};
type ErrorState = {
  hasError: boolean;
  error?: Error;
};

export class ErrorBoundaryIgnore extends React.Component<
  ErrorProps,
  ErrorState
> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error
    });
    const errorObject = {
      name: error.name || "",
      error: error.message || "",
      stack: error.stack || "",
      component: errorInfo.componentStack || "",
      userId: firebase.auth().currentUser?.uid || "unknown",
      createdAt: new Date().valueOf()
    };
    firebase
      .firestore()
      .collection(FirestoreCollection.ERROR_LOG)
      .add(errorObject);
    firebase.analytics().logEvent("error", errorObject);
    window.scrollTo(0, 0);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      let error = "";
      // @ts-ignore
      if (this.state.error) {
        // @ts-ignore
        error = this.state.error.message;

        if (error.includes("chunk")) {
          firebase.analytics().logEvent("error_reloaded", {
            // @ts-ignore
            name: this.state.error.name
          });

          window.location.reload();
          return null;
        }
      }
      return <span>{this.props.displayWhenError}</span>;
    }

    return this.props.children;
  }
}
