import firebase from "firebase/compat";
import React, { useEffect } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { parseDoc } from "../../../../utils/utils";
import { PartyConfiguration } from "../common/hashpage_shared";

export default function HashParty({
  partyConfiguration,
  hashtag
}: {
  partyConfiguration: PartyConfiguration;
  hashtag: string;
}) {
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then(data => {
        const AGENCY: string[] | undefined = data.belongToCardIds;
        if (AGENCY) {
          if (AGENCY.includes(String(partyConfiguration))) {
            return;
          } else {
            const belongToCardIds = [...AGENCY, String(partyConfiguration)];
            const data = {
              belongToCardIds
            } as CardSchema;
            firebase
              .firestore()
              .collection(FirestoreCollection.CARDS)
              .doc(hashtag)
              .set(data, { merge: true })
              .then(() => {
                alert("정당이 추가되었습니다.");
              });
          }
        } else {
          const data = {
            belongToCardIds: [`${String(partyConfiguration)}`]
          } as CardSchema;

          firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .doc(hashtag)
            .set(data, { merge: true })
            .then(() => {
              alert("정당이 추가되었습니다.");
            });
        }
      });
  }, []);

  return <></>;
}
