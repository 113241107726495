/** @jsxImportSource @emotion/react */

import React from "react";
import {
  COUNT_BACKGROUND,
  CSS_TD,
  RATIO_BACKGROUND
} from "../../DataExplorerView/shared";

export default function AgeTribeTable({
  ageTribeStats
}: {
  ageTribeStats: { [ageGroup: string]: { [tribeId: string]: number } };
}) {
  if (!ageTribeStats) {
    return null;
  }

  return (
    <table>
      <thead>
        <tr>
          <td css={[CSS_TD]}>연령대</td>
          <td css={[CSS_TD]}>호랑이</td>
          <td css={[CSS_TD]}>하마</td>
          <td css={[CSS_TD]}>코끼리</td>
          <td css={[CSS_TD]}>공룡</td>
          <td css={[CSS_TD]}>사자</td>
          <td css={[COUNT_BACKGROUND, CSS_TD]}>계</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>호랑이%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>하마%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>코끼리%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>공룡%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>사자%</td>
        </tr>
      </thead>
      <tbody>
        {Object.keys(ageTribeStats).map(ageGroup => {
          const sum = Object.values(ageTribeStats[ageGroup]).reduce(
            (a, b) => a + b
          );

          return (
            <tr key={ageGroup}>
              <td css={[CSS_TD]}>{ageGroup}</td>
              <td css={[CSS_TD]}>{ageTribeStats[ageGroup]["1"] ?? 0}</td>
              <td css={[CSS_TD]}>{ageTribeStats[ageGroup]["2"] ?? 0}</td>
              <td css={[CSS_TD]}>{ageTribeStats[ageGroup]["3"] ?? 0}</td>
              <td css={[CSS_TD]}>{ageTribeStats[ageGroup]["4"] ?? 0}</td>
              <td css={[CSS_TD]}>{ageTribeStats[ageGroup]["5"] ?? 0}</td>
              <td css={[COUNT_BACKGROUND, CSS_TD]}>{sum}</td>
              <td css={[RATIO_BACKGROUND, CSS_TD]}>
                {(((ageTribeStats[ageGroup]["1"] ?? 0) / sum) * 100).toFixed(1)}
                %
              </td>
              <td css={[RATIO_BACKGROUND, CSS_TD]}>
                {(((ageTribeStats[ageGroup]["2"] ?? 0) / sum) * 100).toFixed(1)}
                %
              </td>
              <td css={[RATIO_BACKGROUND, CSS_TD]}>
                {(((ageTribeStats[ageGroup]["3"] ?? 0) / sum) * 100).toFixed(1)}
                %
              </td>
              <td css={[RATIO_BACKGROUND, CSS_TD]}>
                {(((ageTribeStats[ageGroup]["4"] ?? 0) / sum) * 100).toFixed(1)}
                %
              </td>
              <td css={[RATIO_BACKGROUND, CSS_TD]}>
                {(((ageTribeStats[ageGroup]["5"] ?? 0) / sum) * 100).toFixed(1)}
                %
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
