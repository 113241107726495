/** @jsxImportSource @emotion/react */

import React, { useContext, useState } from "react";
import {
  DAY,
  toDateOnsetKoreaStandardTime,
  todayInKorea
} from "../../../utils/datetime";
import { css } from "@emotion/react";
import { DATA_PLATFORM_BACKGROUND_COLOR } from "../../../constants/data_platform_colors";
import DateSelectionBar from "./DateSelectionBar";
import PopularityChartForDashboard from "./PopularityChartForDashboard";
import useDailyReportsDataForHashtag from "./useDailyReportsDataForHashtag";
import { getAgreePercentage } from "../../../utils/data_utils";
import { HashtagSummaryForDashboard } from "./HashtagSummaryForDashboard";
import SupporterAnalysisForDashboard from "./SupporterAnalysisForDashboard";
import { useHistory } from "react-router";
import { ROUTES } from "../../../constants/routes";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";

export default function DailyReportsView({
  person,
  setPerson,
  setContender
}: {
  person: string;
  setPerson: (name: string) => void;
  setContender: (name: string) => void;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const dataPlatformUserProfile = dataProps?.dataPlatformUserProfile;

  // if (
  //   dataPlatformUserProfile?.status !== DataPlatformSettingStatus.SUBSCRIBED
  // ) {
  //   console.log("Redirecting to SUBSCRIBE_INTRO");
  //   history.push(ROUTES.SUBSCRIBE_INTRO);
  // }

  const [contenderHashtag, setContenderHashtag] = useState("윤석열");
  const [personInputText, setPersonInputText] = useState("이재명");
  const [contenderInputText, setContenderInputText] = useState("");
  const { day, month, year, dayOfWeek } = todayInKorea(new Date().valueOf());
  const [selectedDate, setSelectedDate] = React.useState<number>(
    toDateOnsetKoreaStandardTime(year, month, day).valueOf()
  );

  const dailyReportsData = useDailyReportsDataForHashtag({
    hashtag: person,
    selectedDate: selectedDate,
    days: 10
  });

  const { cardAnswerStatsMap, cards } = dailyReportsData;

  if (!cardAnswerStatsMap || !cards) {
    return null;
  }

  const yesterdayCards = cards.filter(card => {
    return (card.featuredOnNewsTimelineAt || 0) > selectedDate - DAY * 3;
  });
  const yesterdayCardIds = yesterdayCards.map(
    yesterdayCard => yesterdayCard.id
  );

  const sortedComments = Object.values(cardAnswerStatsMap)
    .filter(cardAnswerStat =>
      yesterdayCardIds.includes(cardAnswerStat.id || "")
    )
    .sort((statA, statB) => {
      return getAgreePercentage(statB) - getAgreePercentage(statA);
    });

  const topMention = sortedComments[0];
  const bottomMention = sortedComments[sortedComments.length - 1];

  const onPersonChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPersonInputText(e.target.value);
  };

  const onContenderChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setContenderInputText(e.target.value);
  };

  return (
    <div
      css={css`
        margin-left: 220px;
        background-color: ${DATA_PLATFORM_BACKGROUND_COLOR};
        height: 1600px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 900px;
          height: 67px;
          position: fixed;
          background-color: ${DATA_PLATFORM_BACKGROUND_COLOR};
          z-index: 100;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            css={css`
              font-weight: 800;
              font-size: 28px;
              text-align: left;
              margin-left: 55px;
              margin-bottom: 14px;
            `}
          >
            대시보드
          </div>
          <div
            css={css`
              margin-right: 150px;
              margin-bottom: 18px;
            `}
          >
            <DateSelectionBar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
        </div>
        <div
          css={css`
            height: 1px;
            width: 100vw;
            /* background-color: #b0b3b2; */
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          `}
        />
      </div>

      <div
        css={css`
          display: flex;
          justify-content: flex-start;
          width: 100%;
        `}
      >
        <div
          css={css`
            width: 900px;
          `}
        >
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: flex-start;
              margin-left: 30px;
              margin-top: 96px;
            `}
          >
            <div
              css={css`
                width: 160px;
                height: 120px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin-left: 20px;
                overflow: hidden;
              `}
            >
              <PopularityChartForDashboard
                hashtag={person}
                selectedDate={selectedDate}
                selectedTribe={"AVERAGE"}
                dailyReportsData={dailyReportsData}
              />
            </div>
            <div
              css={css`
                width: 160px;
                height: 120px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin-left: 20px;
                overflow: hidden;
              `}
            >
              <SupporterAnalysisForDashboard
                hashtag={person}
                selectedDate={selectedDate}
              />
            </div>
            <div
              css={css`
                width: 160px;
                height: 120px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin-left: 20px;
                overflow: hidden;
              `}
            >
              {topMention?.id && (
                <HashtagSummaryForDashboard
                  cardId={topMention.id}
                  showComments={false}
                />
              )}
            </div>
            <div
              css={css`
                width: 160px;
                height: 120px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin-left: 20px;
                overflow: hidden;
              `}
            >
              {bottomMention?.id && (
                <HashtagSummaryForDashboard
                  cardId={bottomMention.id}
                  showComments={false}
                />
              )}
            </div>
          </div>

          <div
            css={css`
              width: 700px;
              height: 330px;
              background-color: #ffffff;
              border-radius: 10px;
              border: 1px solid #dfdfdf;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              margin-left: 50px;
              margin-top: 20px;
              overflow: hidden;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <div
                css={css`
                  margin-top: 30px;
                  font-weight: 700;
                  font-size: 18px;
                `}
              >
                일간 보고서 출력
              </div>
              <div
                css={css`
                  margin-top: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <input
                  placeholder={"정치인"}
                  css={css`
                    width: 90px;
                    height: 20px;
                    border: 1px solid #adadad;
                    &:focus {
                      border: 1px solid #747474;
                    }
                  `}
                  onChange={e => onPersonChange(e)}
                  value={personInputText}
                ></input>
              </div>

              <div
                css={css`
                  width: 146px;
                  height: 24px;
                  font-size: 14px;
                  font-weight: 700;
                  color: #ffffff;
                  background-color: #747474;
                  line-height: 26px;
                  cursor: pointer;
                  margin-top: 12px;
                `}
                onClick={() => {
                  setPerson(personInputText);
                  setContenderHashtag(contenderInputText);
                  history.push(ROUTES.DAILY_REPORT + "/" + personInputText);
                }}
              >
                리포트 출력하기
              </div>
            </div>
          </div>

          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: flex-start;
              margin-left: 20px;
              margin-top: 20px;
            `}
          >
            <div
              css={css`
                width: 338px;
                height: 180px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin-left: 30px;
                overflow: hidden;
              `}
            ></div>
            <div
              css={css`
                width: 338px;
                height: 180px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin-left: 21px;
                overflow: hidden;
              `}
            ></div>
          </div>
        </div>
      </div>

      {/* <div
        css={css`
          margin-top: 40px;
        `}
      >
        <TopSearchBar />
      </div> */}

      {/* <HashtagMentions selectedDate={selectedDate} hashtag={"더불어민주당"} /> */}

      {/* <div
        css={css`
          margin-top: 40px;
        `}
      >
        <HashtagPopularity
          hashtag={"더불어민주당"}
          selectedDate={selectedDate}
        />
      </div>
      <div
        css={css`
          margin-top: 60px;
        `}
      >
        <HashtagSupporters
          hashtag={"더불어민주당"}
          selectedDate={selectedDate}
        />
      </div> */}
    </div>
  );
}
