/** @jsxImportSource @emotion/react */

import React from "react";
import { CardAnswerStatsTimeSeriesSchema } from "../../../constants/firestore_schema";
import { GRAY_DARK, oxoColors } from "../../../constants/enums";
import {
  ArrowDownwardOutlined,
  ArrowRightAltOutlined,
  ArrowUpwardOutlined
} from "@material-ui/icons";
import { css } from "@emotion/react";

export function ApprovalRateLineArrowChart({
  dailyStats
}: {
  dailyStats: CardAnswerStatsTimeSeriesSchema[];
}) {
  const ratios = dailyStats
    .sort((s1, s2) => s1.createdAt - s2.createdAt)
    .map((dailyStat, i) => {
      const ratio = (dailyStat.o || 0) / (dailyStat.count || 1);
      return {
        x: i + 1,
        y: ratio,
        label:
          i === dailyStats.length - 1 || i == 0
            ? (ratio * 100).toFixed() + "%"
            : ""
      };
    });

  const isAsc = ratios[0].y - ratios[ratios.length - 1].y < 0;
  const isDesc = ratios[0].y - ratios[ratios.length - 1].y > 0;

  return (
    <div
      css={css`
        color: ${GRAY_DARK};
      `}
    >
      <div
        css={css`
          display: flex;
          font-size: 12px;
          color: ${isAsc ? oxoColors.O : isDesc ? oxoColors.X : GRAY_DARK};
        `}
      >
        {isAsc ? (
          <ArrowUpwardOutlined style={{ color: oxoColors.O, fontSize: 12 }} />
        ) : isDesc ? (
          <ArrowDownwardOutlined style={{ color: oxoColors.X, fontSize: 12 }} />
        ) : (
          <ArrowRightAltOutlined style={{ color: GRAY_DARK, fontSize: 12 }} />
        )}
        {Math.abs((ratios[ratios.length - 1].y - ratios[0].y) * 100).toFixed(
          0
        ) + "%"}
      </div>
      <div>{ratios[ratios.length - 1].label}</div>
    </div>
  );
}
