/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import {
  toDateOnsetKoreaStandardTime,
  todayInKorea
} from "../../../utils/datetime";
import { css } from "@emotion/react";
import { DATA_PLATFORM_BACKGROUND_COLOR } from "../../../constants/data_platform_colors";
import MentionAnalysis from "./MentionAnalysis";
import MentionList from "./MentionList";

export default function MentionResponseView() {
  const { day, month, year, dayOfWeek } = todayInKorea(new Date().valueOf());
  const [selectedDate, setSelectedDate] = React.useState<number>(
    toDateOnsetKoreaStandardTime(year, month, day).valueOf()
  );
  const [selectedHashtag, setSelectedHashtag] =
    useState<string>("더불어민주당");

  const [selectedCard, setSelectedCard] = useState<string>("");

  return (
    <div
      css={css`
        background-color: ${DATA_PLATFORM_BACKGROUND_COLOR};
        height: 100vh;
        display: flex;
        overflow: auto;
      `}
    >
      <div
        css={css`
          flex-shrink: 0;
        `}
      >
        <MentionList
          selectedDate={selectedDate}
          selectedHashtag={selectedHashtag}
          setSelectedHashtag={setSelectedHashtag}
          setSelectedCard={setSelectedCard}
        />
      </div>
      <div
        css={css`
          height: 100vh;
          overflow: auto;
          justify-content: center;
          padding: 20px;
        `}
      >
        <MentionAnalysis cardId={selectedCard} />
      </div>
    </div>
  );
}
