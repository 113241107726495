import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  getMapFromSnapshots,
  parseDoc
} from "../utils/utils";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  CardAnswerSchema,
  CardAnswerTribeStatsSchema,
  CardSchema,
  CardType,
  FirestoreCollection,
  QuestionAnswerMapSchema,
  QuestionAnswerStatsSchema,
  UserProfileMetadataSchema
} from "../constants/firestore_schema";
import { UserAnswer } from "../constants/enums";

function updateAnswerStats(
  prevAnswer: string | undefined,
  userProfileMetadata: UserProfileMetadataSchema,
  cardId: string,
  newAnswer: string | undefined
) {
  function updateAnswerStatsInternal(answer: string, increment: number) {
    const userBasicInfo = userProfileMetadata.userBasicInfo;

    const data: any = {};
    data["o"] = firebase.firestore.FieldValue.increment(0);
    data["x"] = firebase.firestore.FieldValue.increment(0);
    data["?"] = firebase.firestore.FieldValue.increment(0);
    data[answer] = firebase.firestore.FieldValue.increment(increment);
    data.count = firebase.firestore.FieldValue.increment(increment);
    firebase
      .firestore()
      .collection(FirestoreCollection.QUESTION_ANSWER_STATS)
      .doc(cardId)
      .set(data, { merge: true });
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_STATS)
      .doc(cardId)
      .set(data, { merge: true });

    if (userBasicInfo && userBasicInfo.birthYear && userBasicInfo.gender) {
      const subcollection: any = {};
      const data: any = {};
      data["o"] = firebase.firestore.FieldValue.increment(0);
      data["x"] = firebase.firestore.FieldValue.increment(0);
      data["?"] = firebase.firestore.FieldValue.increment(0);
      data[answer] = firebase.firestore.FieldValue.increment(increment);
      data.count = firebase.firestore.FieldValue.increment(increment);
      subcollection[userBasicInfo.birthYear] = {};
      subcollection[userBasicInfo.birthYear][userBasicInfo.gender] = data;

      firebase
        .firestore()
        .collection(FirestoreCollection.QUESTION_ANSWER_STATS)
        .doc(cardId)
        .set(
          {
            birthYearGenderStats: subcollection
          },
          { merge: true }
        );

      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
        .doc(cardId)
        .set(subcollection, { merge: true });
    }

    if (userProfileMetadata.tribeId) {
      const tribeUpdate: { [tribeId: string]: AnswerCountsSchema } = {};
      const data: any = {};
      data["o"] = firebase.firestore.FieldValue.increment(0);
      data["x"] = firebase.firestore.FieldValue.increment(0);
      data["?"] = firebase.firestore.FieldValue.increment(0);
      data[answer] = firebase.firestore.FieldValue.increment(increment);
      data.count = firebase.firestore.FieldValue.increment(increment);
      tribeUpdate[userProfileMetadata.tribeId] = data;
      firebase
        .firestore()
        .collection(FirestoreCollection.QUESTION_ANSWER_STATS)
        .doc(cardId)
        .set(
          {
            tribeStats: tribeUpdate
          },
          { merge: true }
        );
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(cardId)
        .set(tribeUpdate, { merge: true });
    }
  }

  if (prevAnswer) {
    updateAnswerStatsInternal(prevAnswer, -1);
  }

  if (newAnswer) {
    updateAnswerStatsInternal(newAnswer, 1);
  }
}

export function syncCardAnswerStats(
  cardId: string,
  index = 0,
  lastCreatedAt = new Date().valueOf()
): Promise<any> {
  if (index === 0) {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_STATS)
      .doc(cardId)
      .delete()
      .then(() => {
        console.log("deleted QUESTION_ANSWER_STATS");
      });
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
      .doc(cardId)
      .delete()
      .then(() => {
        console.log("deleted CARD_ANSWER_AGE_GENDER_STATS");
      });
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
      .doc(cardId)
      .delete()
      .then(() => {
        console.log("deleted CARD_ANSWER_TRIBE_STATS");
      });
    firebase
      .firestore()
      .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
      .doc(cardId)
      .delete()
      .then(() => {
        console.log("deleted USER_MAP");
      });
  }
  return firebase
    .firestore()
    .collection(FirestoreCollection.CARD_ANSWERS)
    .where("cardId", "==", cardId)
    .where("createdAt", "<", lastCreatedAt)
    .orderBy("createdAt", "desc")
    .limit(500)
    .get()
    .then(getMapFromSnapshots)
    .then((cardAnswers: { [cardIdUserIdKey: string]: CardAnswerSchema }) => {
      if (Object.keys(cardAnswers).length === 0) {
        console.log("done");
        return;
      }

      let lastCardCreatedAt = lastCreatedAt;

      const questionChartStats: QuestionAnswerStatsSchema = {
        o: 0,
        x: 0,
        "?": 0,
        count: 0,
        version: 3
      };

      Object.values(cardAnswers).forEach(cardAnswer => {
        if (cardAnswer.answer === UserAnswer.O) {
          questionChartStats.o += 1;
        }
        if (cardAnswer.answer === UserAnswer.X) {
          questionChartStats.x += 1;
        }
        if (cardAnswer.answer === UserAnswer.DUNNO) {
          questionChartStats["?"] += 1;
        }
        questionChartStats.count += 1;
        lastCardCreatedAt = cardAnswer.createdAt;
      });

      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .set(
          {
            o: firebase.firestore.FieldValue.increment(questionChartStats.o),
            x: firebase.firestore.FieldValue.increment(questionChartStats.x),
            "?": firebase.firestore.FieldValue.increment(
              questionChartStats["?"]
            ),
            count: firebase.firestore.FieldValue.increment(
              questionChartStats.count
            ),
            version: 3
          },
          { merge: true }
        );

      const promises = Object.values(cardAnswers).map(cardAnswer =>
        firebase
          .firestore()
          .collection(FirestoreCollection.USER_PROFILE_METADATA)
          .doc(cardAnswer.userId)
          .get()
          .then(parseDoc)
      );
      return Promise.all(promises).then(
        (userProfileMetadatas: UserProfileMetadataSchema[]) => {
          const promisesForGenderAge = firebase
            .firestore()
            .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
            .doc(cardId)
            .get()
            .then(parseDoc)
            .then((cardAnswerAgeGenderStat: CardAnswerAgeGenderStatsSchema) => {
              userProfileMetadatas.forEach((userProfileMetadata, i) => {
                const userBasicInfo = userProfileMetadata.userBasicInfo;
                const answer = cardAnswers[userProfileMetadata.id + cardId]
                  .answer as "o" | "x" | "?";

                if (!answer) {
                  console.log(cardAnswers[userProfileMetadata.id + cardId]);
                  return;
                }
                if (
                  userBasicInfo &&
                  userBasicInfo.birthYear &&
                  userBasicInfo.gender
                ) {
                  if (!cardAnswerAgeGenderStat[userBasicInfo.birthYear]) {
                    cardAnswerAgeGenderStat[userBasicInfo.birthYear] = {};
                  }
                  if (
                    !cardAnswerAgeGenderStat[userBasicInfo.birthYear][
                      userBasicInfo.gender
                    ]
                  ) {
                    cardAnswerAgeGenderStat[userBasicInfo.birthYear][
                      userBasicInfo.gender
                    ] = {
                      o: 0,
                      x: 0,
                      "?": 0,
                      count: 0
                    };
                  }
                  cardAnswerAgeGenderStat[userBasicInfo.birthYear][
                    userBasicInfo.gender
                  ].count += 1;
                  cardAnswerAgeGenderStat[userBasicInfo.birthYear][
                    userBasicInfo.gender
                  ][answer] += 1;
                }
              });
              return firebase
                .firestore()
                .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
                .doc(cardId)
                .set(cardAnswerAgeGenderStat);
            });

          const promisesForCardAnswerTribeStats = firebase
            .firestore()
            .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
            .doc(cardId)
            .get()
            .then(parseDoc)
            .then((cardStats: CardAnswerTribeStatsSchema) => {
              userProfileMetadatas.forEach((userProfileMetadata, i) => {
                const answer = cardAnswers[userProfileMetadata.id + cardId]
                  .answer as "o" | "x" | "?";
                if (userProfileMetadata.tribeId) {
                  if (!cardStats[userProfileMetadata.tribeId]) {
                    cardStats[userProfileMetadata.tribeId] = {
                      o: 0,
                      x: 0,
                      "?": 0,
                      count: 0
                    };
                  }
                  cardStats[userProfileMetadata.tribeId][answer] += 1;
                  cardStats[userProfileMetadata.tribeId]["count"] += 1;
                }
              });

              return firebase
                .firestore()
                .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
                .doc(cardId)
                .set(cardStats);
            });

          const promisesForQuestionAnswerMap = firebase
            .firestore()
            .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
            .doc(cardId)
            .get()
            .then(parseDoc)
            .then((questionAnswerMap: QuestionAnswerMapSchema) => {
              userProfileMetadatas.forEach((userProfileMetadata, i) => {
                const answer = cardAnswers[userProfileMetadata.id + cardId]
                  .answer as "o" | "x" | "?";
                if (
                  userProfileMetadata &&
                  userProfileMetadata.x !== undefined &&
                  userProfileMetadata.y !== undefined
                ) {
                  if (
                    userProfileMetadata.x < -20 ||
                    userProfileMetadata.x > 20
                  ) {
                    return;
                  }
                  if (
                    userProfileMetadata.y < -20 ||
                    userProfileMetadata.y > 20
                  ) {
                    return;
                  }

                  const coordKey =
                    userProfileMetadata.x + "-" + userProfileMetadata.y;

                  if (!questionAnswerMap[coordKey]) {
                    questionAnswerMap[coordKey] = {
                      x: userProfileMetadata.x,
                      y: userProfileMetadata.y,
                      countO: 0,
                      countX: 0,
                      countDunno: 0
                    };
                  }

                  switch (answer) {
                    case UserAnswer.O:
                      questionAnswerMap[coordKey].countO += 1;
                      break;
                    case UserAnswer.X:
                      questionAnswerMap[coordKey].countX += 1;

                      break;
                    case UserAnswer.DUNNO:
                      questionAnswerMap[coordKey].countDunno += 1;
                      break;
                  }
                }
              });

              return firebase
                .firestore()
                .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
                .doc(cardId)
                .set(questionAnswerMap);
            });
          return Promise.all([
            promisesForGenderAge,
            promisesForCardAnswerTribeStats,
            promisesForQuestionAnswerMap
          ]).then(() => {
            console.log("Successfully updated:", index || "", cardId);
            return syncCardAnswerStats(cardId, index + 1, lastCardCreatedAt);
          });
        }
      );
    });
}

function syncStatsIfOff(
  answerCount: number,
  questionAnswerMap: QuestionAnswerMapSchema,
  transaction: firebase.firestore.Transaction,
  questionId: string
) {
  if (answerCount > 1000) {
    console.log("Too many items to sync.");
    return;
  }

  firebase
    .firestore()
    .collection(FirestoreCollection.QUESTION_ANSWER_STATS)
    .doc(questionId)
    .get()
    .then(snapshot => {
      const questionAnswerStat: QuestionAnswerStatsSchema = parseDoc(snapshot);
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(questionId)
        .get()
        .then(parseDoc)
        .then((card: CardSchema) => {
          if (
            card.type !== CardType.TEST_QUESTION &&
            (!questionAnswerStat.birthYearGenderStats ||
              !questionAnswerStat.tribeStats)
          ) {
            syncCardAnswerStats(questionId);
          }
        });
    });
}

export function syncStats(cardId: string) {
  console.log("syncing " + cardId);
  syncCardAnswerStats(cardId);
}

function updateMapCoordinate(
  userProfileMetadata: UserProfileMetadataSchema,
  answer: string,
  questionId: string,
  value: number
) {
  const coordKey = userProfileMetadata.x + "-" + userProfileMetadata.y;

  const incrementData: any = {};
  incrementData.countO = firebase.firestore.FieldValue.increment(0);
  incrementData.countX = firebase.firestore.FieldValue.increment(0);
  incrementData.countDunno = firebase.firestore.FieldValue.increment(0);
  incrementData.x = userProfileMetadata.x;
  incrementData.y = userProfileMetadata.y;

  switch (answer) {
    case UserAnswer.O:
      incrementData.countO = firebase.firestore.FieldValue.increment(value);
      break;
    case UserAnswer.X:
      incrementData.countX = firebase.firestore.FieldValue.increment(value);
      break;
    case UserAnswer.DUNNO:
      incrementData.countDunno = firebase.firestore.FieldValue.increment(value);
      break;
  }

  const data: any = {};
  data[coordKey] = incrementData;
  firebase
    .firestore()
    .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
    .doc(questionId)
    .set(data, { merge: true });
}

function updateQuestionAnswerMap(
  cardId: string,
  userProfileMetadata: UserProfileMetadataSchema,
  prevAnswer: string | undefined,
  newAnswer: string | undefined
) {
  // 부족이 없으면 지도에 표시할 수가 없다.
  if (
    userProfileMetadata.x === undefined ||
    userProfileMetadata.y === undefined
  ) {
    return;
  }
  if (prevAnswer) {
    updateMapCoordinate(userProfileMetadata, prevAnswer, cardId, -1);
  }

  if (newAnswer) {
    updateMapCoordinate(userProfileMetadata, newAnswer, cardId, 1);
  }
}

function batchUpdateCommentAnswers(
  userId: string,
  questionId: string,
  answer: string | undefined
) {
  if (!answer) {
    return;
  }
  firebase
    .firestore()
    .collection(FirestoreCollection.COMMENTS)
    .where("userId", "==", userId)
    .where("questionId", "==", questionId)
    .get()
    .then(getMapFromSnapshots)
    .then(comments => {
      let writeBatch = firebase.firestore().batch();
      Object.keys(comments).forEach((commentId, i) => {
        if (i % 300 === 0) {
          writeBatch.commit();
          writeBatch = firebase.firestore().batch();
        }
        writeBatch.set(
          firebase
            .firestore()
            .collection(FirestoreCollection.COMMENTS)
            .doc(commentId),
          { answer: answer },
          { merge: true }
        );
      });
      writeBatch.commit();
    });
}

export function addAnswer(
  questionId: string,
  userId: string,
  currentUserMetadata: UserProfileMetadataSchema,
  answer?: string
) {
  const entry: {
    [questionId: string]: string | firebase.firestore.FieldValue;
  } = {};
  if (answer) {
    entry[questionId] = answer;
  } else {
    entry[questionId] = firebase.firestore.FieldValue.delete();
  }

  /** Update deprecated User Vector */
  firebase
    .firestore()
    .collection(FirestoreCollection.USER_VECTOR)
    .doc(userId)
    .set(entry, { merge: true });

  return firebase
    .firestore()
    .collection(FirestoreCollection.CARD_ANSWERS)
    .doc(userId + questionId)
    .get()
    .then(parseDoc)
    .then((currentCardAnswer: CardAnswerSchema) => {
      const prevAnswer: string | undefined = currentCardAnswer.answer;

      if (answer) {
        const data: CardAnswerSchema = {
          answer: answer,
          cardId: questionId,
          createdAt: Date.now(),
          userId: userId,
          birthYear: currentUserMetadata.userBasicInfo?.birthYear || 0,
          gender: currentUserMetadata.userBasicInfo?.gender || ""
        };

        firebase
          .firestore()
          .collection(FirestoreCollection.CARD_ANSWERS)
          .doc(userId + questionId)
          .set(data);
      } else {
        firebase
          .firestore()
          .collection(FirestoreCollection.CARD_ANSWERS)
          .doc(userId + questionId)
          .delete();
      }

      updateQuestionAnswerMap(
        questionId,
        currentUserMetadata,
        prevAnswer,
        answer
      );

      updateAnswerStats(prevAnswer, currentUserMetadata, questionId, answer);

      batchUpdateCommentAnswers(userId, questionId, answer);
    });
}

export function deleteCardAnswers(
  currentUserId: string,
  cardIds: string[],
  currentUserMetadata: UserProfileMetadataSchema
) {
  cardIds.forEach(cardId => {
    addAnswer(cardId, currentUserId, currentUserMetadata, undefined);
  });
}

export function updateUserInfoInPastAnswerCard(
  userId: string,
  userProfileMetadata: UserProfileMetadataSchema
) {
  firebase
    .firestore()
    .collection(FirestoreCollection.CARD_ANSWERS)
    .where("userId", "==", userId)
    .where("gender", "==", "")
    .limit(250)
    .get()
    .then(getDocsFromSnapshot)
    .then((cardAnswers: CardAnswerSchema[]) => {
      if (cardAnswers.length === 0) {
        return;
      }

      const gender: string = userProfileMetadata.userBasicInfo?.gender || "";
      const birthYear: number =
        userProfileMetadata.userBasicInfo?.birthYear || 0;

      if (gender !== "" && birthYear !== 0) {
        const writeBatch = firebase.firestore().batch();

        cardAnswers.forEach(card => {
          writeBatch.set(
            firebase
              .firestore()
              .collection(FirestoreCollection.CARD_ANSWERS)
              .doc(userId + card.cardId),
            {
              gender: gender,
              birthYear: birthYear
            },
            { merge: true }
          );

          const subcollection: any = {};
          const data: any = {};
          data["o"] = firebase.firestore.FieldValue.increment(0);
          data["x"] = firebase.firestore.FieldValue.increment(0);
          data["?"] = firebase.firestore.FieldValue.increment(0);
          data[`${card.answer}`] = firebase.firestore.FieldValue.increment(1);
          data["count"] = firebase.firestore.FieldValue.increment(1);
          subcollection[birthYear] = {};
          subcollection[birthYear][gender] = data;

          writeBatch.set(
            firebase
              .firestore()
              .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
              .doc(card.cardId),
            subcollection,
            { merge: true }
          );
        });

        writeBatch
          .commit()
          .then(() => console.log("DONE : updateUserInfoInPastAnswerCard"));
      }
    });
}
