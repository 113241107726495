import firebase from "firebase/compat";

import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { GRAY_ABSTRACT } from "../../../../constants/enums";
import OXOText from "../../../utility/OXOText/OXOText";
import { hashtagTextAdapter } from "../../../../utils/utils";

export default function HashPeopleDescription({
  description
}: {
  description?: string;
}) {
  const [showFullText, setShowFullText] = useState<boolean>(false);

  let abstract1, abstract2, fullText;
  if (description) {
    [abstract1, abstract2, ...fullText] = description?.split("\n\n");
  }
  return (
    <div
      className="hashcard_people_description"
      style={{
        padding: "10px 15px",
        color: "black",
        width: "100%",
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: 450,
        fontSize: 15,
        lineHeight: 1.7
      }}
    >
      {description && (
        <div>
          <OXOText
            text={hashtagTextAdapter(`${[abstract1, abstract2].join("\n\n")}`)}
          />
          <Collapse isOpened={showFullText}>
            <OXOText text={`${fullText?.join("\n\n")}`} />{" "}
          </Collapse>
        </div>
      )}
      <span
        style={{ cursor: "pointer", color: GRAY_ABSTRACT }}
        onClick={() => {
          firebase.analytics().logEvent("v4_click_hash_people_desc_collapse", {
            event_category: "hashcard"
          });
          setShowFullText(prev => !prev);
        }}
      >
        {fullText && fullText.length ? (
          showFullText ? (
            "줄이기"
          ) : (
            "더보기"
          )
        ) : (
          <></>
        )}
      </span>
    </div>
  );
}
