/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import {
  getAgreePercentage,
  getDisagreePercentage
} from "../../../../utils/data_utils";
import { css } from "@emotion/react";
import { VictoryPie } from "victory";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";

export default function CardAnswerPieChartForHotIssue({
  cardId,
  size,
  showPercentage = false,
  colorScale = [
    DATA_PLATFORM_RED_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_GREEN_GRAY
  ],
  isAgree = true
}: {
  cardId: string;
  size?: number;
  showPercentage?: boolean;
  colorScale?: [string, string, string];
  isAgree?: boolean;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats || cardAnswerStats.count === 0) {
    return null;
  }

  const stats = [
    {
      x: 0,
      y: cardAnswerStats.x || 0
    },
    {
      x: 0,
      y: cardAnswerStats["?"] || 0
    },
    {
      x: 0,
      y: cardAnswerStats.o || 0
    }
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        position: relative;
      `}
    >
      {isAgree ? (
        <div
          css={css`
            font-size: 18px;
            font-weight: 700;
            color: ${DATA_PLATFORM_RED_GRAY};
            position: absolute;
            width: 200px;
            height: 165px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          찬성 {getAgreePercentage(cardAnswerStats).toFixed()}%
        </div>
      ) : (
        <div
          css={css`
            font-size: 18px;
            font-weight: 700;
            color: ${DATA_PLATFORM_RED_GRAY};
            position: absolute;
            width: 200px;
            height: 165px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          반대 {getDisagreePercentage(cardAnswerStats).toFixed()}%
        </div>
      )}
      <div
        css={css`
          width: 164px;
          height: 164px;
        `}
      >
        <VictoryPie
          startAngle={360}
          endAngle={0}
          innerRadius={126}
          data={stats}
          colorScale={colorScale}
          style={{ labels: { display: "none" } }}
        />
      </div>
      {showPercentage && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100px;
            margin-top: -2px;
          `}
        >
          <div
            css={css`
              font-size: 14px;
              font-weight: 700;
              text-align: left;
              color: #bebebe;
            `}
          >
            찬성
          </div>
          <div
            css={css`
              font-size: 14px;
              font-weight: 700;
              text-align: center;
              color: ${DATA_PLATFORM_YELLOW_GRAY};
            `}
          >
            중립
          </div>
          <div
            css={css`
              font-size: 14px;
              font-weight: 700;
              text-align: right;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            반대
          </div>
        </div>
      )}
    </div>
  );
}
