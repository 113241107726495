/** @jsxImportSource @emotion/react */

import React from "react";
import { CardSchema } from "../../../constants/firestore_schema";
import { todayInKorea } from "../../../utils/datetime";
import { CSS_TD } from "../DataExplorerView/shared";
import HashtagTimeSeriesGenderAnswersForCard from "./HashtagTimeSeriesGenderAnswersForCard";

export default function HashtagTimeSeriesGenderAnswers({
  cards
}: {
  cards: CardSchema[];
}) {
  if (!cards) {
    return null;
  }

  return (
    <table>
      <tr>
        <td css={CSS_TD}>날짜</td>
        <td css={CSS_TD}>남</td>
        <td css={CSS_TD}>여</td>
      </tr>
      {cards.map(card => {
        const koreanDate = todayInKorea(card.featuredOnNewsTimelineAt);

        return (
          <tr key={card.id}>
            <td css={CSS_TD}>
              {koreanDate.year +
                "." +
                koreanDate.month +
                "." +
                koreanDate.day +
                "."}
            </td>
            <HashtagTimeSeriesGenderAnswersForCard cardId={card.id} />
          </tr>
        );
      })}
    </table>
  );
}
