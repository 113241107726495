import { SearchClient } from "algoliasearch";
import { CardType } from "../constants/firestore_schema";

export function addToIndex(
  algoliaClient: SearchClient,
  cardType: string,
  id: string,
  object: any,
  indexName = "prod_cards"
) {
  if (id.endsWith("_스토리")) {
    return;
  }

  if (
    object.cardType === CardType.HASHTAG_CARD &&
    (object.id.includes("_") || !object.id.includes(" "))
  ) {
    return;
  }

  const index = algoliaClient.initIndex(indexName);
  object.objectID = id;
  object.id = id;
  object.cardType = cardType;
  index.saveObject(object);
}

export function removeFromIndex(
  algoliaClient: SearchClient,
  id: string,
  indexName = "prod_cards"
) {
  const index = algoliaClient.initIndex(indexName);
  index.deleteObject(id);
}

export function search(algoliaClient: SearchClient, query: string) {
  const index = algoliaClient.initIndex("prod_cards");
  return index.search(query);
}
