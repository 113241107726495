/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardFeatureStatus,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  listToMap,
  onlyUnique,
  parseDoc
} from "../../../utils/utils";
import { DAYS } from "../../../utils/datetime";
import { DailyReportsDataSchema } from "./useDailyReportsDataForHashtag";

export default function useDailyReportsDataForPeriod({
  selectedDate,
  days,
  limit,
  sortByAnswersDesc = false
}: {
  selectedDate: number;
  days?: number;
  limit?: number;
  sortByAnswersDesc?: boolean;
}): DailyReportsDataSchema {
  const [cardAnswerStatsMap, setCardAnswerStatsMap] =
    useState<{ [cardId: string]: CardAnswerStatsSchema }>();

  const [cards, setCards] = useState<CardSchema[]>();
  useEffect(() => {
    console.log("Fetching data..");
    let query = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [CardType.NEWS_TICKER, CardType.REAL_NAME_POST])
      .where("featuredOnNewsTimeline", "==", CardFeatureStatus.ON);

    if (days) {
      query = query.where(
        "featuredOnNewsTimelineAt",
        ">=",
        selectedDate - days * DAYS
      );
    } else if (limit) {
      query = query.limit(limit);
    }

    query
      .where("featuredOnNewsTimelineAt", "<", selectedDate)
      .orderBy("featuredOnNewsTimelineAt", "desc")
      .get()
      .then(getDocsFromSnapshot)
      .then((hashtagCards: CardSchema[]) => {
        const cards = hashtagCards
          .sort(
            (a, b) =>
              (b.featuredOnNewsTimelineAt || 0) -
              (a.featuredOnNewsTimelineAt || 0)
          )
          .filter(card => !card.speakerHashTag?.includes("#옥소"))
          .filter((v, i, a) =>
            onlyUnique(
              v.id,
              i,
              a.map(c => c.id)
            )
          );
        const cardAnswerStatPromises = cards.map(card => {
          return firebase
            .firestore()
            .collection(FirestoreCollection.CARD_ANSWER_STATS)
            .doc(card.id)
            .get()
            .then(parseDoc);
        });

        Promise.all(cardAnswerStatPromises).then(
          (cardAnswerStats: CardAnswerStatsSchema[]) => {
            const cardAnswerStatMap: {
              [cardId: string]: CardAnswerStatsSchema;
            } = listToMap(
              cardAnswerStats,
              cardAnswerStat => cardAnswerStat.id || "",
              cardAnswerStat => cardAnswerStat
            );

            setCardAnswerStatsMap(cardAnswerStatMap);

            const sortedCards = sortByAnswersDesc
              ? cards.sort((ca, cb) => {
                  return (
                    cardAnswerStatMap[cb.id].count -
                    cardAnswerStatMap[ca.id].count
                  );
                })
              : cards;
            setCards(sortedCards);
          }
        );
      });
  }, [selectedDate, days]);

  const [cardAnswerTribeStatsMap, setCardAnswerTribeStatsMap] =
    useState<{ [cardId: string]: CardAnswerTribeStatsSchema }>();
  useEffect(() => {
    if (!cards) {
      return;
    }
    const promises = cards.map(card =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(card.id)
        .get()
        .then(parseDoc)
    );

    Promise.all(promises).then(
      (cardAnswerTribeStats: CardAnswerTribeStatsSchema[]) => {
        setCardAnswerTribeStatsMap(
          listToMap(
            cardAnswerTribeStats,
            // @ts-ignore
            cardAnswerTribeStat => cardAnswerTribeStat["id"],
            cardAnswerTribeStat => cardAnswerTribeStat
          )
        );
      }
    );
  }, [cards]);

  return {
    cardAnswerStatsMap: cardAnswerStatsMap,
    cards: cards,
    cardAnswerTribeStatsMap: cardAnswerTribeStatsMap
  };
}
