/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import firebase from "firebase/compat";
import {
  CardAnswerSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";

const H2_CSS = css`
  padding-top: 20px;
`;

export default function CardAnswersView({ cardId }: { cardId: string }) {
  const [cardAnswers, setCardAnswers] = useState<CardAnswerSchema[]>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .where("cardId", "==", cardId)
      .orderBy("createdAt")
      .get()
      .then(getDocsFromSnapshot)
      .then(setCardAnswers);
  }, [cardId]);

  if (!cardAnswers) {
    return null;
  }

  return (
    <div
      css={css`
        width: 100%;
        padding: 20px;
      `}
    >
      <table>
        <thead>
          <tr>
            <td>사용자 ID</td>
            <td>응답</td>
            <td>성별</td>
            <td>생년</td>
            <td>부족</td>
          </tr>
        </thead>
        <tbody>
          {cardAnswers.map(cardAnswer => {
            return (
              <tr key={cardAnswer.id}>
                <td>{cardAnswer.userId}</td>
                <td>{cardAnswer.answer}</td>
                <td>{cardAnswer.gender}</td>
                <td>{cardAnswer.birthYear}</td>
                <td>{cardAnswer.tribeV2Id}</td>
                <td>
                  {cardAnswer.jurisdictionAddress?.replace(
                    "세계 아시아 동북아시아 대한민국 ",
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
