/** @jsxImportSource @emotion/react */

import React, { ReactNode, useContext, useEffect } from "react";
import { SessionTimeContext } from "../Contexts/SessionTimeContext";
import { TIME_TO_UPDATE } from "./Main";
import { useHistory } from "react-router";
import LeftNavigationBar from "../Navigation/LeftNavigationBar";
import { css } from "@emotion/react";

export default function MainViewWithNavigation({
  child
}: {
  child: ReactNode;
}) {
  const history = useHistory();
  const sessionTime = useContext(SessionTimeContext);

  useEffect(() => {
    if (sessionTime < new Date().valueOf() - TIME_TO_UPDATE) {
      console.log("Expired version. Reloading.");
      window.location.reload();
    }
  }, [history.location.pathname]);

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <LeftNavigationBar />
      <div
        css={css`
          width: 100%;
        `}
      >
        <div>{child}</div>
      </div>
    </div>
  );
}
