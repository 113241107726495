/** @jsxImportSource @emotion/react */

import React, { CSSProperties, useContext } from "react";
import { GRAY } from "../../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { hashtagTextAdapter } from "../../../../utils/utils";
import KoreanDate from "../../../cards/CardComponents/KoreanDate";
import HashCommentAvatar from "../Comment/HashCommentAvatar";
import HashCommentName from "../Comment/HashCommentName";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import SpeakerPosition from "../Comment/SpeakerPosition";
import SupporterPercentage from "../Comment/SupporterPercentage";
import MiddleDot from "../../../foundation/common/MiddleDot";
import OXOText from "../../../utility/OXOText/OXOText";
import { SupportersContext } from "../../../foundation/Contexts/SupportersContext";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_BACKGROUND_COLOR,
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_COLOR,
  DATA_PLATFORM_RED_GRAY
} from "../../../../constants/data_platform_colors";
import CardAnswerTribeBarChart from "../Comment/CardAnswerTribeBarChart";
import CardAnswerSupporterDoubleBarChart from "../Comment/CardAnswerSupporterDoubleBarChart";
import MiniBestCommentsForPortrait from "../Comment/MiniBestCommentsForPortrait";
import CardAnswerTribeBarChartVertical from "../Comment/CardAnswerTribeBarChartVertical";
import CardAnswerPieChartForReport from "../Comment/CardAnswerPieChartForReport";
import CardAnswerPieChartForHotIssue from "../Comment/CardAnswerPieChartForHotIssue";

export default function HashCommentBar({
  cardId,
  showDetails = true,
  style,
  isAgree = true
}: {
  cardId: string;
  showDetails?: boolean;
  style?: CSSProperties;
  isAgree?: boolean;
}) {
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const supporters = useContext(SupportersContext);
  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  if (!card || !cardAnswer || !card.title) {
    return null;
  }

  const SPEAKERS: string[] =
    card.type === CardType.TOPIC
      ? ["옥소"]
      : card.speakerHashTag
          ?.filter(speaker => speaker.length)
          .map(speaker => speaker.replace("#", "")) || ["방랑자"];

  const trimmedTitle: string = card.title?.replace(/["“”]/g, "");
  const speakerFromTitle: string | undefined = trimmedTitle?.split(" ")[0];
  const commentBefore: string =
    trimmedTitle?.slice((speakerFromTitle?.length || 0) + 1) || "";

  const comment: string =
    card.type === CardType.DEBATE && card.debateCommentMetadata
      ? card.debateCommentMetadata.comment
      : card.type === CardType.NEWS_TICKER
      ? card.newsTickerMetadata
        ? card.title
        : commentBefore
      : card.title;

  return (
    <div
      css={{
        display: "flex",
        paddingBottom: 12,
        width: "100%",
        ...style
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            height: 120px;
          `}
        >
          {SPEAKERS && (
            <div
              css={{
                marginRight: 10
              }}
            >
              {SPEAKERS.map((speaker, i, speakers) => (
                <div
                  css={{
                    paddingRight: 5,
                    marginBottom:
                      speakers.length > 1 && i !== speakers.length - 1
                        ? -20
                        : 0,
                    position: "relative"
                  }}
                  key={speaker}
                >
                  <HashCommentAvatar speaker={speaker} size={42} />
                </div>
              ))}
            </div>
          )}

          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <div>
              <div
                css={css`
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  color: ${GRAY};
                `}
              >
                {SPEAKERS && (
                  <div>
                    <div
                      css={css`
                        display: flex;
                        align-items: flex-end;
                        font-size: 12;
                      `}
                    >
                      {SPEAKERS.map((speaker, i, speakers) => (
                        <div
                          css={{ display: "flex", fontWeight: "bold" }}
                          key={speaker}
                        >
                          <HashCommentName
                            speaker={speaker}
                            style={{ fontSize: 16, color: "black" }}
                          />
                          {speakers.length > 1 && i !== speakers.length - 1 && (
                            <MiddleDot />
                          )}
                        </div>
                      ))}
                      {SPEAKERS.length === 1 && (
                        <>
                          <div>
                            <SpeakerPosition
                              speaker={SPEAKERS[0]}
                              addMiddleDotIfPresent
                            />
                          </div>
                          <div
                            css={css`
                              display: flex;
                            `}
                          >
                            <SupporterPercentage cardId={SPEAKERS[0]} />
                          </div>
                        </>
                      )}
                      <div
                        css={css`
                          display: flex;
                          font-size: 12px;
                        `}
                      >
                        <MiddleDot />
                        <KoreanDate timestamp={card.createdAt} />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                css={css`
                  border-radius: 14px;
                  padding: 12px 17px;
                  margin-top: 5px;
                  background-color: ${DATA_PLATFORM_BACKGROUND_COLOR};
                `}
              >
                <div
                  css={css`
                    min-height: 20px;
                    font-size: 15px;
                    line-height: 1.5;
                    letter-spacing: -0.02em;
                  `}
                >
                  <OXOText
                    text={hashtagTextAdapter(comment)}
                    useDivForParagraph
                    truncateChars={87}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showDetails && (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
              width: 100%;
              height: 210px;
            `}
          >
            <div
              css={css`
                width: 50%;
                margin-top: 30px;
              `}
            >
              <CardAnswerTribeBarChartVertical
                cardId={cardId}
                isAgree={isAgree}
              />
            </div>
            <div
              css={css`
                width: 50%;
              `}
            >
              <CardAnswerPieChartForHotIssue
                cardId={cardId}
                showPercentage={true}
                isAgree={isAgree}
              />
            </div>
          </div>
        )}
        {showDetails && (
          <div
            css={css`
              margin-top: 14px;
              margin-left: 10px;
            `}
          >
            <div
              css={css`
                font-size: 16px;
                font-weight: 700;
                color: ${DATA_PLATFORM_RED_GRAY};
                margin-bottom: -6px;
                margin-left: 3px;
              `}
            >
              베스트 댓글
            </div>
            <div
              css={css`
                font-size: 10px;
              `}
            >
              <MiniBestCommentsForPortrait
                cardId={cardId}
                length={1}
                truncate={47}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
