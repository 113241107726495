/** @jsxImportSource @emotion/react */

import React from "react";
import { useCardSubscription } from "../../../utils/hooks/useFirestoreLiveDocument";
import { css } from "@emotion/react";
import { addToCardIdStackUrl } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import CardAnswerWordForDashboard from "../HashCardView/Comment/CardAnswerWordForDashboard";

export function HashtagSummaryForDashboard({
  cardId,
  showComments = true,
  showName = false
}: {
  cardId: string;
  showComments?: boolean;
  showName?: boolean;
}) {
  const [card] = useCardSubscription(cardId);
  const history = useHistory();

  if (!card) {
    return null;
  }

  return (
    <div
      css={css`
        cursor: pointer;
      `}
      onClick={() => {
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, cardId)
        );
      }}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 120px;
        `}
      >
        <div
          css={css`
            width: 130px;
            font-size: 14px;
            font-weight: 700;
            color: #505050;
            text-align: center;
          `}
        >
          {card?.hashtags &&
            card?.hashtags.map(
              (hashtag, i) =>
                i < 2 &&
                (i !== 0 && hashtag !== "" ? (
                  <span
                    css={css`
                      margin-right: 5;
                      word-break: keep-all;
                    `}
                    key={hashtag + i}
                  >
                    {hashtag.replace(/_/g, " ")}{" "}
                  </span>
                ) : (
                  <span
                    css={css`
                      margin-right: 5;
                      word-break: keep-all;
                    `}
                    key={hashtag + i}
                  >
                    {hashtag.replace(/_/g, " ")}{" "}
                  </span>
                ))
            )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-top: -5px;
            p {
              font-size: 14px;
              margin-bottom: 0px;
            }
          `}
        ></div>
        <CardAnswerWordForDashboard cardId={cardId} />
      </div>
    </div>
  );
}
