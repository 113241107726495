import firebase from "firebase/compat";

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  getDocsFromSnapshot
} from "../../../../utils/utils";

const HASHTAG_COUNT_LIMIT = 25;

export default function HashRelatedCard({ hashtag }: { hashtag: string }) {
  const [cards, setCards] = useState<CardSchema[]>();

  const history = useHistory();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("hashtags", "array-contains", "#" + hashtag)
      .orderBy("publishedAt", "desc")
      .get()
      .then(getDocsFromSnapshot)
      .then(setCards);
  }, []);

  const entireHashtag: Array<string[]> = [];

  if (cards) {
    cards.map((card, i) => {
      card.hashtags && entireHashtag.push(card.hashtags);
    });
  }

  const sortedHashtagsObject = _.flatten(entireHashtag).reduce((acc, cur) => {
    acc[cur] = (acc[cur] || 0) + 1;
    return acc;
  }, {} as { [key: string]: number });

  const sortedHashtagArray = Object.keys(sortedHashtagsObject);

  sortedHashtagArray.splice(sortedHashtagArray.indexOf("#" + hashtag), 1);

  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          maxWidth: 400
        }}
      >
        {sortedHashtagArray &&
          sortedHashtagArray.map((relatedHashtag, i) => {
            return (
              <div
                key={relatedHashtag}
                onClick={() => {
                  firebase
                    .analytics()
                    .logEvent("v4_click_hash_related_hashtag", {
                      event_category: "hashcard",
                      event_label: relatedHashtag.replace("#", "")
                    });
                  history.push(
                    history.location.pathname +
                      addToCardIdStackUrl(
                        history.location.search,
                        relatedHashtag.replace("#", "")
                      )
                  );
                }}
              >
                {i < HASHTAG_COUNT_LIMIT && (
                  <div
                    style={{
                      padding: 10,
                      fontSize: 15,
                      color: "#3E7A99",
                      cursor: "pointer"
                    }}
                  >
                    {relatedHashtag}{" "}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
