/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsTimeSeriesSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  mapValues,
  sumTwoAnswerCounts
} from "../../../utils/utils";
import { formatKoreanDateIso, todayInKorea } from "../../../utils/datetime";
import {
  DATA_PLATFORM_GREEN_COLOR,
  DATA_PLATFORM_RED_COLOR
} from "../../../constants/data_platform_colors";

export default function SupporterAnalysisForDashboard({
  hashtag,
  selectedDate
}: {
  hashtag: string;
  selectedDate: number;
}) {
  const [ageGenderStats, setAgeGenderStats] =
    useState<CardAnswerAgeGenderStatsTimeSeriesSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS_TIME_SERIES)
      .where("cardId", "==", hashtag)
      .where(
        "dateInKorea",
        "<=",
        formatKoreanDateIso(todayInKorea(selectedDate))
      )
      .orderBy("dateInKorea", "desc")
      .limit(5)
      .get()
      .then(getDocsFromSnapshot)
      .then(setAgeGenderStats);
  }, [hashtag]);

  const statsByAgeGroups = useMemo(() => {
    if (!ageGenderStats || ageGenderStats.length === 0) {
      return;
    }
    return [ageGenderStats[0], ageGenderStats[ageGenderStats.length - 1]].map(
      cardAnswerAgeGenderStat => {
        return Object.keys(cardAnswerAgeGenderStat).reduce<{
          [ageGroup: string]: {
            [gender: string]: {
              o: number;
              "?": number;
              x: number;
              count: number;
            };
          };
        }>((prev, birthYear) => {
          const ageGroupNumber =
            Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) *
            10;
          if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
            return prev;
          }

          const ageGroup = ageGroupNumber.toFixed();

          const birthYearGenderStat =
            cardAnswerAgeGenderStat?.[Number(birthYear)];
          if (birthYearGenderStat) {
            if (prev[ageGroup]) {
              prev[ageGroup]["male"] = sumTwoAnswerCounts(
                (prev[ageGroup]["male"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                }) as AnswerCountsSchema,
                (birthYearGenderStat["male"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                }) as AnswerCountsSchema
              );

              prev[ageGroup]["female"] = sumTwoAnswerCounts(
                prev[ageGroup]["female"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                },
                birthYearGenderStat["female"] || {
                  o: 0,
                  x: 0,
                  "?": 0,
                  count: 0
                }
              ) as {
                o: number;
                "?": number;
                x: number;
                count: number;
              };
            } else {
              prev[ageGroup] = birthYearGenderStat;
            }
          }
          return prev;
        }, {});
      }
    );
  }, [ageGenderStats]);

  const ageGenderRatios = useMemo(() => {
    if (!statsByAgeGroups) {
      return;
    }
    return statsByAgeGroups.map(statsByAgeGroup =>
      mapValues(statsByAgeGroup, genderGroups =>
        mapValues(genderGroups, genderGroup =>
          genderGroup.count < 5
            ? 0
            : (genderGroup.o || 0) / (genderGroup.count || 1)
        )
      )
    );
  }, [statsByAgeGroups]);

  const gainAgeGenders = useMemo(() => {
    if (!ageGenderRatios) {
      return undefined;
    }
    const gainAgeGender: {
      age: string;
      gender: string;
      gain: number;
    }[] = [];
    const currentRatio = ageGenderRatios[0];
    const lastRatio = ageGenderRatios[1];
    mapValues(currentRatio, (genderGroups, ageGroup) => {
      return mapValues(genderGroups, (value: number, gender) => {
        const diffRatio =
          (value / (lastRatio[ageGroup]?.[gender] || 1) || 1) - 1;
        if (diffRatio !== 0) {
          gainAgeGender.push({
            age: ageGroup,
            gender: gender,
            gain: diffRatio
          });
        }
        return diffRatio;
      });
    });
    return gainAgeGender;
  }, [ageGenderRatios]);

  const sortedGain =
    gainAgeGenders && gainAgeGenders.sort((ga, gb) => gb.gain - ga.gain);

  return (
    <div
      css={css`
        width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <div>
        <div css={css``}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            {sortedGain && sortedGain[0] ? (
              <div
                css={css`
                  font-size: 20px;
                  font-weight: 700;
                  height: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 4px;
                  margin-bottom: -2px;
                  color: ${sortedGain[0].gain > 0
                    ? DATA_PLATFORM_GREEN_COLOR
                    : DATA_PLATFORM_RED_COLOR};
                `}
              >
                {sortedGain[0].age}대{" "}
                {sortedGain[0].gender === "male" ? "남" : "여"}성
                {/* {Math.abs(gainAgeGender.gain * 100).toFixed()}%{" "} */}
                {sortedGain[0].gain > 0 ? " 👍" : " 👎"}
              </div>
            ) : (
              <div
                css={css`
                  font-size: 20px;
                  font-weight: 700;
                  height: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #afafaf;
                `}
              >
                -
              </div>
            )}

            <div
              css={css`
                width: 110px;
                height: 1px;
                background-color: #b0b3b2;
              `}
            ></div>

            {sortedGain &&
            gainAgeGenders.length - 1 !== 0 &&
            sortedGain[gainAgeGenders.length - 1] ? (
              <div
                css={css`
                  font-size: 20px;
                  font-weight: 700;
                  height: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 4px;
                  margin-top: -4px;
                  color: ${sortedGain[sortedGain.length - 1].gain > 0
                    ? DATA_PLATFORM_GREEN_COLOR
                    : DATA_PLATFORM_RED_COLOR};
                `}
              >
                {sortedGain[sortedGain.length - 1].age}대{" "}
                {sortedGain[sortedGain.length - 1].gender === "male"
                  ? "남"
                  : "여"}
                성{/* {Math.abs(gainAgeGender.gain * 100).toFixed()}%{" "} */}
                {sortedGain[sortedGain.length - 1].gain > 0 ? " 👍" : " 👎"}
              </div>
            ) : (
              <div
                css={css`
                  font-size: 20px;
                  font-weight: 700;
                  height: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #afafaf;
                `}
              >
                -
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
