import React from "react";
import { DEBATE_ABSTRACT, GRAY_EXTRA_DARK } from "../../../../constants/enums";
import { PeopleConfiguration } from "../common/hashpage_shared";
import HashPeopleInfo from "./HashPeopleInfo";

export default function HashPeople({
  peopleConfiguration,
  hashtag
}: {
  peopleConfiguration: PeopleConfiguration;
  hashtag: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: DEBATE_ABSTRACT
      }}
    >
      {peopleConfiguration.해시태그 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            style={{
              fontSize: 24,
              fontWeight: "bold",
              padding: 10,
              paddingTop: 30
            }}
          >
            {peopleConfiguration.제목}
          </div>
          <div
            style={{
              fontSize: 18,
              color: GRAY_EXTRA_DARK,
              paddingBottom: 30,
              fontWeight: "bold"
            }}
          >
            {peopleConfiguration.설명}
          </div>
          <div style={{ paddingBottom: 20 }}>
            <HashPeopleInfo
              participants={peopleConfiguration.해시태그}
              isDirectionColumn={true}
              election={hashtag}
            />
          </div>
        </div>
      )}
    </div>
  );
}
