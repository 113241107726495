import { MenuItem } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useContext } from "react";
import {
  CommentSchema,
  FirestoreCollection,
  PointFieldName
} from "../../../constants/firestore_schema";
import { addUserProfileMetadataCount } from "../../../lib/points";
import { useIsAdmin } from "../../../utils/utils";
import { DataPropsContext } from "../Contexts/DataPropsContext";

export default function CommentTrashButton({
  comment
}: {
  comment: CommentSchema;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  if (!useIsAdmin() && currentUserId !== comment.userId) {
    return null;
  }
  const trashClicked = () => {
    if (
      comment.id &&
      window.confirm(`"${comment.comment}" 의견을 삭제하시겠습니까?`)
    ) {
      firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .doc(comment.id)
        .delete()
        .then(() => {
          addUserProfileMetadataCount(
            PointFieldName.countComments,
            comment.userId,
            comment.userTribeId,
            -1
          );
        });
    }
  };

  return (
    <MenuItem onClick={trashClicked}>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div style={{ marginRight: 5 }}>
          <DeleteOutlined />
        </div>
        <div style={{ paddingBottom: 7 }}>삭제하기</div>
      </div>
    </MenuItem>
  );
}
