import { ThumbUp } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  BACKGROUND_COLOR,
  GRAY_EXTRA_LIGHT,
  GRAY_LIGHT,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import {
  FirestoreCollection,
  LikeSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import {
  getDocsFromSnapshot,
  getMapFromSnapshots,
  getTribeImage,
  groupBy,
  mapValues
} from "../../../utils/utils";
import TribeSelector from "../../cards/CardComponents/TribeSelector";
import UserName from "../../profile/OXOUserProfile/UserName";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";

export default function TribeLeaderBoard({
  tribeId,
  mode
}: {
  tribeId?: string;
  mode?: "all" | "week";
}) {
  const [likeLeaders, setLikeLeaders] = useState<{
    [userId: string]: UserProfileMetadataSchema;
  }>();

  const [currentTribeId, setCurrentTribeId] = useState<string | undefined>(
    tribeId
  );

  useEffect(() => {
    setLikeLeaders(undefined);
    if (mode === "all") {
      let query = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA);

      if (currentTribeId) {
        // @ts-ignore
        query = query.where("tribeId", "==", currentTribeId);
      }
      query
        .orderBy("countLikes", "desc")
        .limit(6)
        .get()
        .then(getMapFromSnapshots)
        .then(setLikeLeaders);
    } else {
      let query = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA);

      if (currentTribeId) {
        // @ts-ignore
        query = query.where("tribeId", "==", currentTribeId);
      }
      query
        .orderBy("countLikes", "desc")
        .limit(20)
        .get()
        .then(getMapFromSnapshots)
        .then(userProfileMetadatas => {
          const promises = [
            Object.keys(userProfileMetadatas).slice(0, 10),
            Object.keys(userProfileMetadatas).slice(10, 20)
          ].map(userIds => {
            if (userIds.length === 0) {
              return undefined;
            }
            let query = firebase
              .firestore()
              .collection(FirestoreCollection.LIKES);

            if (currentTribeId) {
              // @ts-ignore
              query = query.where("likeUserTribeId", "==", currentTribeId);
            }
            return query
              .where(
                "createdAt",
                ">",
                new Date().valueOf() - 3600 * 1000 * 24 * 5
              )
              .where("referenceUserId", "in", userIds)
              .get()
              .then(getDocsFromSnapshot)
              .then(likes => {
                return mapValues(
                  groupBy(likes, (like: LikeSchema) => {
                    return like.referenceUserId;
                  }),
                  (likes: LikeSchema[]) => {
                    return {
                      countLikes: likes.length,
                      tribeId: likes[0].referenceUserTribeId
                    };
                  }
                );
              });
          });
          Promise.all(promises).then(likesArray => {
            setLikeLeaders(
              likesArray.reduce((result, current) => {
                return { ...result, ...current };
              }) as {
                [userId: string]: UserProfileMetadataSchema;
              }
            );
          });
        });
    }
  }, [mode, currentTribeId]);

  if (!likeLeaders) {
    return (
      <div style={{ padding: "20px" }}>
        <DocumentSkeleton />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: BACKGROUND_COLOR,
        borderRadius: "20px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "10px",
        padding: "10px 20px",
        textAlign: "center"
      }}
    >
      <div>
        <TribeSelector
          currentTribeId={currentTribeId}
          setCurrentTribeId={setCurrentTribeId}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around"
        }}
      >
        {Object.keys(likeLeaders)
          .sort((userId1, userId2) => {
            return (
              likeLeaders[userId2].countLikes - likeLeaders[userId1].countLikes
            );
          })
          .slice(0, 6)
          .map((userId, i) => {
            const leaderTribeId = likeLeaders[userId].tribeId;
            return (
              <div
                key={userId}
                style={{
                  display: "flex",
                  borderRadius: "50px",
                  backgroundColor: GRAY_EXTRA_LIGHT,
                  padding: "5px",
                  width: "300px",

                  marginLeft: "5px",
                  marginRight: "5px",
                  marginBottom: "10px",
                  alignItems: "center",
                  flexShrink: 0
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    backgroundColor: BACKGROUND_COLOR,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {currentTribeId
                    ? i + 1
                    : leaderTribeId && (
                        <img
                          src={getTribeImage(
                            TRIBE_INFO_BY_ID[leaderTribeId].image
                          )}
                          height={30}
                          alt="tribeImg"
                        />
                      )}
                </div>

                <div
                  style={{
                    flexGrow: 1,
                    paddingLeft: "10px",
                    textAlign: "left",
                    cursor: "pointer"
                  }}
                >
                  <UserName profileUserId={userId} />
                </div>
                <div
                  style={{
                    borderRadius: "20px",
                    width: "80px",
                    height: "30px",
                    backgroundColor: BACKGROUND_COLOR,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 10px"
                  }}
                >
                  <ThumbUp
                    style={{
                      color: GRAY_LIGHT,
                      fontWeight: "bold",
                      marginRight: "10px",
                      fontSize: 14
                    }}
                  />

                  <div>{likeLeaders[userId].countLikes}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
