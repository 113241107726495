import styled from "@emotion/styled";

const MiniBorderCardBox = styled.div`
  width: 391px;
  height: 163px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #dfdfdf;
`;

export default MiniBorderCardBox;
