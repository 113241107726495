/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  CardAnswerTribeStatsV2Schema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc } from "../../../utils/utils";
import { CSS_TD, RATIO_BACKGROUND } from "../DataExplorerView/shared";
import { getAverageTribeCardAnswerData } from "../DataExplorerView/getAverageTribeCardAnswerData";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";

export default function HashtagTimeSeriesAnswersForCard({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerStat, setCardAnswerStat] = useState<CardAnswerStatsSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_STATS)
      .doc(cardId)
      .get()
      .then(parseDoc)
      .then(setCardAnswerStat);
    return () => {};
  }, [cardId]);

  const cardAnswerTribeStatsV2 =
    useFirestoreGetDocument<CardAnswerTribeStatsV2Schema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2,
      cardId
    );

  function getPercentage(answerCounts: number | undefined) {
    return (((answerCounts || 0) / (cardAnswerStat?.count || 1)) * 100).toFixed(
      1
    );
  }

  if (!cardAnswerTribeStatsV2) {
    return null;
  }
  const averageAnswers = getAverageTribeCardAnswerData(cardAnswerTribeStatsV2);

  return (
    <>
      <td css={CSS_TD}>{cardAnswerStat?.o || 0}</td>
      <td css={CSS_TD}>{cardAnswerStat?.["?"] || 0}</td>
      <td css={CSS_TD}>{cardAnswerStat?.x || 0}</td>
      <td css={CSS_TD}>{cardAnswerStat?.count || 0}</td>
      <td css={CSS_TD}>{getPercentage(cardAnswerStat?.o)}%</td>
      <td css={CSS_TD}>{getPercentage(cardAnswerStat?.["?"])}%</td>
      <td css={CSS_TD}>{getPercentage(cardAnswerStat?.x)}%</td>
      <td css={[CSS_TD, RATIO_BACKGROUND]}>{averageAnswers.o}%</td>
      <td css={[CSS_TD, RATIO_BACKGROUND]}>{averageAnswers["?"]}%</td>
      <td css={[CSS_TD, RATIO_BACKGROUND]}>{averageAnswers.x}%</td>
    </>
  );
}
