import { MenuItem, Select } from "@material-ui/core";
import firebase from "firebase/compat";

import React, { useContext, useEffect, useState } from "react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import {
  getPartySmallLogo,
  listToMap,
  parseDoc
} from "../../../../utils/utils";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import OXOText from "../../../utility/OXOText/OXOText";
import {
  parseYamlDescription,
  PartyConfiguration,
  PoliciesDetailConfiguration
} from "../common/hashpage_shared";

export default function PoliciesDetail({
  policiesDetailConfiguration
}: {
  policiesDetailConfiguration: PoliciesDetailConfiguration;
}) {
  const selTextWidthSmall = 80;
  const photoSizeSmall = 40;
  const photoSize = 90;
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const [windowWidth, windowHeight] = useWindowSize();
  const [userColorHeavy, setUserColorHeavy] = useState<string>("#545454");
  const [keyword, setKeyword] = useState<string | undefined>(
    policiesDetailConfiguration.키워드?.[0]
  );
  const [candidates, setCandidates] = useState<{
    [name: string]: {
      photoUrl: string;
      candidateNumber: string;
      name: string;
      color: string;
      party: string;
    };
  }>();

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const candidateTotalCount = policiesDetailConfiguration.후보
    ? Object.keys(policiesDetailConfiguration.후보).length
    : 0;

  const randNum = getRandomInt(
    0,
    policiesDetailConfiguration.후보
      ? Object.keys(policiesDetailConfiguration.후보).length - 1
      : 1
  );

  const [selName1, setSelName1] = useState<string>(
    policiesDetailConfiguration.후보
      ? Object.keys(policiesDetailConfiguration.후보)[0]
      : ""
  );

  const [selName2, setSelName2] = useState<string>(
    policiesDetailConfiguration.후보
      ? Object.keys(policiesDetailConfiguration.후보)[1]
      : ""
  );

  const setKey = (key: string) => {
    setKeyword(key);
    const autoScroll = document.getElementById("autoScroll");
    if (autoScroll) {
      autoScroll.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (policiesDetailConfiguration.후보) {
      const allHashtagCards = Object.keys(policiesDetailConfiguration.후보).map(
        (candidateName: string) => {
          return firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .doc(candidateName)
            .get()
            .then(parseDoc);
        }
      );

      Promise.all(allHashtagCards).then((hashtagCards: CardSchema[]) => {
        const candidateMap = listToMap(
          hashtagCards,
          (hashtagCard: CardSchema) => hashtagCard.id,
          (hashtagCard: CardSchema) => {
            return {
              photoUrl: hashtagCard.hashtagMetadata?.photos?.[1],
              name: hashtagCard.id,
              color:
                policiesDetailConfiguration.후보?.[hashtagCard.id]?.["컬러"],
              candidateNumber:
                policiesDetailConfiguration.후보?.[hashtagCard.id]?.["기호"],
              party: !Array.isArray(policiesDetailConfiguration.후보)
                ? policiesDetailConfiguration.후보?.[hashtagCard.id].정당사진
                  ? policiesDetailConfiguration.후보?.[hashtagCard.id].정당사진
                  : parseYamlDescription<PartyConfiguration>(
                      hashtagCard.hashtagMetadata?.description
                    )?.정당 || "무소속"
                : parseYamlDescription<PartyConfiguration>(
                    hashtagCard.hashtagMetadata?.description
                  )?.정당 || "무소속"
            };
          }
        );

        setCandidates(candidateMap);

        if (currentUserMetadata && currentUserMetadata.tribeId) {
          setUserColorHeavy(
            TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
          );
        }
      });
    }
  }, []);

  if (!candidates || !keyword) {
    return null;
  }
  return (
    <div style={{ marginBottom: 300 }}>
      <div
        id="topFixBanner"
        className="topFixBannerHide"
        style={{
          width: "100%",
          maxWidth: 750,
          backgroundColor: "white",
          zIndex: 1,
          position: "fixed",
          padding: 10,
          top: 45
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >
          {policiesDetailConfiguration.키워드?.map((key: string) => {
            return (
              <div
                key={key}
                onClick={() => {
                  firebase
                    .analytics()
                    .logEvent("v4_click_hash_policies_keyword", {
                      event_category: "hashcard"
                    });
                  setKey(key);
                }}
                style={{
                  float: "left",
                  border:
                    key == keyword ? "none" : "1px solid " + userColorHeavy,
                  borderRadius: "40px",
                  letterSpacing: "-2%",
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: key === keyword ? "bold" : "normal",
                  backgroundColor: key === keyword ? userColorHeavy : "white",
                  color: key === keyword ? "white" : userColorHeavy,
                  padding: windowWidth > 600 ? "0px 20px" : "0px 10px",
                  margin: windowWidth > 600 ? "5px 7px" : "5px 5px",
                  cursor: "pointer"
                }}
              >
                {key}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          <div
            key={selName1}
            style={{
              padding: 10,
              fontSize: 20,
              color: "#000000",
              fontWeight: "bold"
            }}
          >
            {candidates[selName1].photoUrl ? (
              <img
                src={candidates[selName1].photoUrl}
                style={{
                  width: photoSizeSmall,
                  height: photoSizeSmall,
                  borderRadius: "50%",
                  marginBottom: -10
                }}
                alt="candidate1_photo"
              />
            ) : (
              <img
                style={{
                  width: photoSizeSmall,
                  height: photoSizeSmall,
                  borderRadius: "50%",
                  border: "1px solid grey",
                  marginBottom: -10
                }}
                alt="candidate1_photo"
              />
            )}
            &nbsp;&nbsp;&nbsp;
            {candidateTotalCount < 3 ? (
              <span>{selName1}</span>
            ) : (
              <Select
                value={selName1}
                onChange={e => {
                  setSelName1(e.target.value as string);
                }}
                style={{
                  width: selTextWidthSmall,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 18
                }}
              >
                {Object.keys(candidates).map((candidate: string) => {
                  if (selName2 != candidate)
                    return <MenuItem value={candidate}>{candidate}</MenuItem>;
                })}
              </Select>
            )}
          </div>
          <div
            key={selName2}
            style={{
              padding: 10,
              fontSize: 20,
              color: "#000000",
              fontWeight: "bold"
            }}
          >
            {candidateTotalCount < 3 ? (
              <span>{selName2}</span>
            ) : (
              <Select
                value={selName2}
                onChange={e => {
                  setSelName2(e.target.value as string);
                }}
                style={{
                  width: selTextWidthSmall,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 18
                }}
              >
                {Object.keys(candidates).map((candidate: string) => {
                  if (selName1 != candidate)
                    return <MenuItem value={candidate}>{candidate}</MenuItem>;
                })}
              </Select>
            )}
            &nbsp;&nbsp;&nbsp;
            {candidates[selName2].photoUrl ? (
              <img
                src={candidates[selName2].photoUrl}
                style={{
                  width: photoSizeSmall,
                  height: photoSizeSmall,
                  borderRadius: "50%",
                  marginBottom: -10
                }}
                alt="candidate2_photo"
              />
            ) : (
              <img
                style={{
                  width: photoSizeSmall,
                  height: photoSizeSmall,
                  borderRadius: "50%",
                  border: "1px solid grey",
                  marginBottom: -10
                }}
                alt="candidate2_photo"
              />
            )}
          </div>
        </div>
        <div
          style={{
            margin: "0px 20px",
            borderBottom: candidateTotalCount < 3 ? "1px solid #E5E5E5" : "0px"
          }}
        ></div>
      </div>
      <div
        style={{
          padding: 10,
          textAlign: "center",
          fontWeight: "bold",
          margin: "auto",
          marginTop: 5
        }}
      >
        <div
          style={{
            fontSize: 18,
            letterSpacing: "-1%",
            marginBottom: 11
          }}
        >
          {policiesDetailConfiguration.제목1}
        </div>
        <div
          style={{
            fontSize: 44,
            letterSpacing: "-1%",
            marginBottom: 24
          }}
        >
          {policiesDetailConfiguration.제목2}
        </div>
        <div id="autoScroll" style={{ position: "relative", top: 145 }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >
          {policiesDetailConfiguration.키워드?.map((key: string) => {
            return (
              <div
                key={key}
                onClick={() => {
                  firebase
                    .analytics()
                    .logEvent("v4_click_hash_policies_keyword", {
                      event_category: "hashcard"
                    });
                  setKey(key);
                }}
                style={{
                  float: "left",
                  border:
                    key == keyword ? "none" : "1px solid " + userColorHeavy,
                  borderRadius: "40px",
                  letterSpacing: "-2%",
                  height: 32,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: key == keyword ? "bold" : "normal",
                  backgroundColor: key == keyword ? userColorHeavy : "white",
                  color: key == keyword ? "white" : userColorHeavy,
                  padding: windowWidth > 600 ? "0px 20px" : "0px 10px",
                  margin: windowWidth > 600 ? "5px 7px" : "5px 5px",
                  cursor: "pointer"
                }}
              >
                {key}
              </div>
            );
          })}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          <div
            style={{
              width: 139,
              height: 192,
              paddingTop: 42,
              marginBottom: 22
            }}
          >
            <div>
              {candidates[selName1].photoUrl ? (
                <img
                  src={candidates[selName1].photoUrl}
                  style={{
                    width: photoSize,
                    height: photoSize,
                    borderRadius: "50%"
                  }}
                  alt="candidate1_photo"
                />
              ) : (
                <img
                  style={{
                    width: photoSize,
                    height: photoSize,
                    borderRadius: "50%",
                    border: "1px solid grey"
                  }}
                  alt="candidate1_photo"
                />
              )}
              <div
                style={{
                  marginTop: 10,
                  height: 32,
                  fontSize: 20,
                  lineHeight: "32px"
                }}
              >
                {candidateTotalCount < 3 ? (
                  selName1
                ) : (
                  <Select
                    value={selName1}
                    onChange={e => {
                      setSelName1(e.target.value as string);
                    }}
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: 20
                    }}
                  >
                    {Object.keys(candidates).map((candidate: string) => {
                      if (selName2 != candidate)
                        return (
                          <MenuItem value={candidate}>{candidate}</MenuItem>
                        );
                    })}
                  </Select>
                )}
              </div>
              <img
                src={getPartySmallLogo(candidates[selName1].party)}
                style={{
                  position: "relative",
                  top: -145,
                  left: -40,
                  width: 25
                }}
                alt="partyLogo"
              />
            </div>
          </div>

          <div
            style={{
              width: 139,
              height: 192,
              paddingTop: 42,
              marginBottom: 22
            }}
          >
            <div>
              {candidates[selName2].photoUrl ? (
                <img
                  src={candidates[selName2].photoUrl}
                  style={{
                    width: photoSize,
                    height: photoSize,
                    borderRadius: "50%"
                  }}
                  alt="candidates_photo"
                />
              ) : (
                <img
                  style={{
                    width: photoSize,
                    height: photoSize,
                    borderRadius: "50%",
                    border: "1px solid grey"
                  }}
                  alt="candidates_photo"
                />
              )}
              <div
                style={{
                  marginTop: 10,
                  height: 32,
                  fontSize: 20,
                  lineHeight: "32px"
                }}
              >
                {candidateTotalCount < 3 ? (
                  selName2
                ) : (
                  <Select
                    value={selName2}
                    onChange={e => {
                      setSelName2(e.target.value as string);
                    }}
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: 20
                    }}
                  >
                    {Object.keys(candidates).map((candidate: string) => {
                      if (selName1 != candidate)
                        return (
                          <MenuItem value={candidate}>{candidate}</MenuItem>
                        );
                    })}
                  </Select>
                )}
              </div>
              <img
                src={getPartySmallLogo(candidates[selName2].party)}
                style={{
                  position: "relative",
                  top: -145,
                  left: -40,
                  width: 25
                }}
                alt="partyLogo"
              />
            </div>
          </div>
        </div>

        {policiesDetailConfiguration.공약 &&
          policiesDetailConfiguration.공약[keyword] &&
          Object.keys(policiesDetailConfiguration.공약[keyword]).map(
            (title: string) => {
              const policies =
                policiesDetailConfiguration.공약?.[keyword]?.[title];
              return (
                <>
                  <div
                    style={{
                      width: "80%",
                      margin: "auto",
                      marginTop: 15,
                      marginBottom: 8,
                      lineHeight: "150%",
                      fontSize: 17
                    }}
                  >
                    {title}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      minHeight: 100,
                      marginBottom: 60
                    }}
                  >
                    <div
                      key="policy1"
                      style={{
                        width: "90%",
                        textAlign: "left",
                        padding: "8px 16px",
                        borderRadius: 10,
                        margin: 3,
                        fontSize: 14,
                        fontWeight: "normal",
                        backgroundColor: candidates[selName1]?.color,
                        lineHeight: "150%",
                        color: "#808080"
                      }}
                    >
                      {policies &&
                        Object.keys(policies).map(
                          (name: string, index: number) => {
                            if (name == selName1) {
                              const policy = policies?.[name];
                              return <OXOText text={policy} key={index} />;
                            }
                          }
                        )}
                    </div>
                    <div
                      style={{
                        borderRight: "1px solid #E5E5E5",
                        marginTop: 12,
                        marginBottom: 12
                      }}
                    ></div>
                    <div
                      key="policy2"
                      style={{
                        width: "90%",
                        textAlign: "left",
                        padding: "8px 16px",
                        borderRadius: 10,
                        margin: 3,
                        fontSize: 14,
                        fontWeight: "normal",
                        backgroundColor: candidates[selName2]?.color,
                        lineHeight: "150%",
                        color: "#808080"
                      }}
                    >
                      {policies &&
                        Object.keys(policies).map(
                          (name: string, index: number) => {
                            if (name == selName2) {
                              const policy = policies?.[name];
                              return <OXOText text={policy} key={index} />;
                            }
                          }
                        )}
                    </div>
                  </div>
                </>
              );
            }
          )}
      </div>
    </div>
  );
}
