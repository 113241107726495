import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { DeleteOutlined, EditOutlined, MoreVert } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  ReferenceType
} from "../../../constants/firestore_schema";
import { useIsAdmin } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import ContentReportButton from "../../utility/ReportButton/ContentReportButton";
import UserBlockButton from "../../utility/ReportButton/UserBlockButton";
import ReportPopupButton from "../../views/ReportView/ReportPopupButton";
import TextReportPopupButton from "../../views/ReportView/TextReportPopupButton";
import ShareButton from "../CardComponents/ShareButtons";

export default function CardControls({
  card,
  trashClicked,
  enableReportingButton = false
}: {
  card: CardSchema;
  trashClicked: () => void;
  enableReportingButton?: boolean;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [showEditCardDrawer, setShowEditCardDrawer] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isAdmin = useIsAdmin();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isAdmin ? (
        <>
          {currentUserId === card.createdBy && (
            <div
              style={{
                padding: "15px 3px 10px",
                cursor: "pointer"
              }}
              onClick={() => {
                setShowEditCardDrawer(true);
                handleClose();
              }}
            >
              <EditOutlined />
            </div>
          )}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size={"small"}
            style={{
              color: "black",
              padding: "15px 10px 15px 3px",
              cursor: "pointer"
            }}
          >
            <MoreVert />
          </IconButton>
        </>
      ) : (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size={"small"}
          style={{
            color: "black",
            padding: "15px 5px 15px 15px",
            cursor: "pointer"
          }}
        >
          <MoreVert />
        </IconButton>
      )}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch"
          }
        }}
      >
        {enableReportingButton && (
          <ContentReportButton
            setBlind={() => {
              firebase
                .firestore()
                .collection(FirestoreCollection.CARDS)
                .doc(card.id)
                .set({ bad: !card.bad }, { merge: true })
                .then(() =>
                  alert("블라인드 상태를 변경 했습니다. " + !card.bad)
                );
            }}
            referenceId={card.id}
            referenceType={ReferenceType.CARD}
            reportedUserId={card.createdBy}
            reportedUserTribe={card.createdByTribeId || "0"}
            onClose={handleClose}
          />
        )}
        {(currentUserId === card.createdBy || isAdmin) && (
          <MenuItem
            key={"edit"}
            onClick={() => {
              setShowEditCardDrawer(true);
              handleClose();
            }}
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ marginRight: 5 }}>
                <EditOutlined />
              </div>
              <div style={{ paddingBottom: 7 }}>수정하기</div>
            </div>
          </MenuItem>
        )}
        {(currentUserId === card.createdBy || isAdmin) && (
          <MenuItem
            key={"delete"}
            onClick={() => {
              trashClicked();
              handleClose();
            }}
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ marginRight: 5 }}>
                <DeleteOutlined />
              </div>

              <div style={{ paddingBottom: 7 }}>삭제하기</div>
            </div>
          </MenuItem>
        )}

        {card.type === CardType.POST && card.createdBy !== currentUserId && (
          <UserBlockButton
            reportedUserId={card.createdBy}
            onClose={handleClose}
          />
        )}

        {isAdmin && (
          <MenuItem>
            <ReportPopupButton cardId={card.id} />
          </MenuItem>
        )}

        {isAdmin && (
          <MenuItem>
            <TextReportPopupButton cardId={card.id} />
          </MenuItem>
        )}

        <div style={{ padding: "5px 20px", outline: "none" }}>
          <ShareButton
            cardId={card.id}
            facebookTitle={`${card.title}\n\n${
              card.topicMetadataV2?.description ||
              card.postMetadata?.description ||
              card.id
            }`}
            twitterTitle={card.title || card.id}
            thumbnailUrl={
              card.topicMetadataV2?.photos?.[0] ||
              card.hashtagMetadata?.photos?.[0] ||
              "https://oxopolitics.com/og_image.jpg"
            }
          />
        </div>
      </Menu>
    </div>
  );
}
