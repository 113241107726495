import React from "react";

export default function KakaoTalkButton({
  color,
  width = 25,
  height = 26
}: {
  color: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3462 13.9098H14.3704V11.2201C14.3622 10.9741 14.1654 10.7854 13.9194 10.769C13.6652 10.7608 13.4437 10.9577 13.4355 11.2201V14.328C13.4437 14.5658 13.6406 14.7544 13.8702 14.7462H15.338C15.3544 14.7462 15.3708 14.7462 15.3872 14.7462C15.6168 14.7298 15.7972 14.533 15.7808 14.3034C15.7726 14.0738 15.5758 13.8934 15.3462 13.9098Z"
        fill={color}
      />
      <path
        d="M12.0413 11.2118C11.9429 10.9493 11.7051 10.7771 11.4345 10.7607C11.1557 10.7771 10.9179 10.9493 10.8277 11.2118L9.72882 14.0901C9.58941 14.5247 9.71242 14.6805 9.83542 14.7379C9.92563 14.7789 10.024 14.8035 10.1224 14.8035C10.311 14.8035 10.4586 14.7297 10.5078 14.5985L10.7375 14.0081H12.1315L12.3611 14.5985C12.4021 14.7215 12.5497 14.8035 12.7465 14.8035C12.8449 14.8035 12.9433 14.7789 13.0417 14.7379C13.1647 14.6805 13.2877 14.5165 13.1483 14.0901L12.0413 11.2118ZM10.9753 13.2044L11.4345 11.9088L11.8937 13.2044H10.9753Z"
        fill={color}
      />
      <path
        d="M9.88368 11.6628C10.1543 11.6464 10.3347 11.4742 10.3265 11.22C10.3183 10.9658 10.1789 10.7771 9.87548 10.7771C9.16205 10.7689 8.27642 10.7689 7.5548 10.7771C7.25139 10.7853 7.11198 10.9658 7.10378 11.22C7.09558 11.4742 7.27599 11.6464 7.5466 11.6628C7.74341 11.671 8.03862 11.6546 8.25182 11.6628C8.26002 12.5484 8.24362 13.4832 8.26002 14.3525C8.26822 14.6641 8.46503 14.7461 8.71104 14.7953C8.73564 14.8035 8.76024 14.7953 8.78484 14.7871C8.99805 14.7051 9.16205 14.6231 9.17025 14.3443C9.18665 13.475 9.17025 12.5402 9.17845 11.6546C9.39166 11.6546 9.69507 11.671 9.88368 11.6628Z"
        fill={color}
      />
      <path
        d="M13.3778 0.00210025C5.76794 -0.129104 0.158955 5.9063 0.00314975 12.6961C-0.160856 20.265 6.10415 26.1528 13.1154 25.997C20.0938 26.1364 26.1866 20.4208 26.2194 13.1061C26.2522 5.8079 20.43 0.125104 13.3778 0.00210025ZM13.1072 18.8873C12.6398 18.8873 12.1642 18.8545 11.7049 18.7889C11.2457 19.1087 8.59704 20.962 8.35103 21.003C8.28543 21.0276 8.21983 21.0194 8.16243 20.9948C8.07222 20.9456 8.08862 20.8062 8.08862 20.8062C8.11322 20.6258 8.77745 18.3461 8.89225 17.9279C6.66177 16.8209 5.17752 14.861 5.17752 12.6305C5.17752 9.17821 8.72824 6.37371 13.1072 6.37371C17.4861 6.37371 21.0369 9.17001 21.0369 12.6305C21.0369 16.091 17.4943 18.8873 13.1072 18.8873Z"
        fill={color}
      />
      <path
        d="M18.8396 14.0655L17.7162 12.573L18.7576 11.5316C18.8314 11.4578 18.8642 11.3594 18.856 11.2528C18.8396 11.015 18.6428 10.8345 18.405 10.8263C18.3066 10.8263 18.2164 10.8591 18.1508 10.9247L16.8716 12.204V11.22C16.8716 10.9657 16.6665 10.7607 16.4123 10.7607C16.1581 10.7607 15.9531 10.9657 15.9531 11.22V14.3525C15.9531 14.6067 16.1581 14.8117 16.4123 14.8117C16.6665 14.8117 16.8716 14.6067 16.8716 14.3525V13.352L17.0274 13.1962L18.1016 14.6149C18.1754 14.7133 18.2902 14.7789 18.405 14.7953C18.6592 14.8281 18.8888 14.6559 18.9216 14.4017C18.938 14.2869 18.9134 14.1639 18.8396 14.0655Z"
        fill={color}
      />
    </svg>
  );
}
