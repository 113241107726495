import React from "react";
import CharacterLoader from "../../utility/OXOLoader/CharacterLoader";
import DetailPagesTopicTab from "../../views/Today/DetailPagesTopicTab";
import DetailPagesTalkTab from "./DetailPagesTalkTab";
import DetailPagesUsermapTab from "./DetailPagesUsermapTab";
import DetailPagesCommentCardTab from "./DetailPagesCommentCardTab";
import DetailPagesReplyTab from "./DetailPagesReplyTab";

export default function DetailPagesRenderer({
  cardId,
  tab,
  tribeId
}: {
  cardId: string;
  tab: string;
  tribeId?: string;
}) {
  switch (tab) {
    case "질문":
      return <DetailPagesTopicTab cardId={cardId} />;
    case "내용":
      return <DetailPagesCommentCardTab cardId={cardId} />;
    case "댓글":
      return <DetailPagesReplyTab cardId={cardId} defaultTribeId={tribeId} />;
    case "톡":
      return <DetailPagesTalkTab cardId={cardId} />;
    case "응답결과":
      return <DetailPagesUsermapTab cardId={cardId} />;
    default:
      return <CharacterLoader />;
  }
}
