/** @jsxImportSource @emotion/react */

import React from "react";
import PortraitPage from "../PortraitReportView/PortraitPage";
import useFirestoreGetDocument, {
  useGetCard
} from "../../../utils/hooks/useFirestoreGetDocument";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { css } from "@emotion/react";
import { OXO_GRAY700, OXO_GRAY900 } from "../../../constants/colors";
import legends from "../../charts/images/legends.png";
import AgeBarChartV2Container from "../DataExplorerView/gender_age_chart/AgeBarChartV2Container";
import BestComments from "../DataExplorerView/BestComments";
import TribeChartV1OrV2 from "./TribeChartV1OrV2";
import OxLightBarChartContainer from "../DataExplorerView/OxLightBarChartContainer";

export default function PortraitCardView({
  cardId,
  headerTitle
}: {
  cardId: string;
  headerTitle?: string;
}) {
  const cardAnswers = useFirestoreGetDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  const card = useGetCard(cardId);

  if (!cardAnswers?.count) {
    return <p>응답이 아직 없습니다.</p>;
  }

  if (!card) {
    return null;
  }

  const date = new Date(card?.publishedAt || "0");
  const releasedDate = `${date.getFullYear()}년 ${
    date.getMonth() + 1
  }월 ${date.getDate()}일`;

  return (
    <PortraitPage>
      <header
        css={css`
          font-weight: 800;
          font-size: 30px;
          color: ${OXO_GRAY700};
          margin-bottom: 10px;
        `}
      >
        {headerTitle && <span>[{headerTitle}]</span>} {card.title}
      </header>
      <div
        css={css`
          display: flex;
          align-items: center;
          color: ${OXO_GRAY900};
          font-weight: 400;
          font-size: 20px;
        `}
      >
        <span>
          {releasedDate}, {cardAnswers?.count}명 응답, 단위: %
        </span>
        <span
          css={css`
            margin: 0 24px;
          `}
        >
          |
        </span>
        <span>
          <img
            src={legends}
            css={css`
              width: 190px;
              height: 25px;
              object-fit: contain;
            `}
          />
        </span>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            width: 400px;
          `}
        >
          <TribeChartV1OrV2 cardId={cardId} />
          <div
            css={css`
              font-size: 18px;
              font-weight: 600;
              margin-top: 20px;
              margin-bottom: 5px;
            `}
          >
            부족 응답 평균
          </div>
          <OxLightBarChartContainer cardId={card.id} width={445} />
        </div>
        <div
          css={css`
            margin-top: 56px;
          `}
        >
          <AgeBarChartV2Container cardId={card.id} />
        </div>
      </div>
      <div
        css={css`
          margin-top: 30px;
        `}
      >
        <BestComments cardId={cardId} maxLen={5000} />
      </div>
    </PortraitPage>
  );
}
