/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import {
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import { sumTwoAnswerCounts } from "../../../utils/utils";
import { css } from "@emotion/react";

const MIN_CUT_OFF_GROUPS = 3;

const POPULATION_RATIO: { [gender: string]: { [age: number]: number } } = {
  male: {
    10: 0.049,
    20: 0.074,
    30: 0.075,
    40: 0.086,
    50: 0.09,
    60: 0.124
  },
  female: {
    10: 0.046,
    20: 0.066,
    30: 0.068,
    40: 0.083,
    50: 0.089,
    60: 0.149
  }
};

export default function GenderAgeChartNormalized({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerAgeGenderState, exists] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      const ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
      if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
        return prev;
      }

      const ageGroup = ageGroupNumber <= 60 ? ageGroupNumber.toFixed() : 60;

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];
      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            // @ts-ignore
            birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            // @ts-ignore
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState]);

  if (
    ((statsByAgeGroup && Object.keys(statsByAgeGroup).length) || 0) <
    MIN_CUT_OFF_GROUPS
  ) {
    return null;
  }

  if (!statsByAgeGroup) {
    return null;
  }

  let totalO = 0;
  let totalD = 0;
  let totalX = 0;
  let totalGRAY = 0;

  Object.keys(statsByAgeGroup).forEach((ageGroup: string) => {
    function addGenderStats(gender: string) {
      if (!statsByAgeGroup) {
        return;
      }
      const genderAnswers = statsByAgeGroup[ageGroup][gender];
      if (genderAnswers && genderAnswers.count < 5) {
        totalGRAY += POPULATION_RATIO[gender][parseInt(ageGroup)];
      } else {
        totalO +=
          ((statsByAgeGroup[ageGroup][gender]?.o || 0) /
            (statsByAgeGroup[ageGroup][gender]?.count || 1)) *
          POPULATION_RATIO[gender][parseInt(ageGroup)];
        totalD +=
          ((statsByAgeGroup[ageGroup][gender]?.["?"] || 0) /
            (statsByAgeGroup[ageGroup][gender]?.count || 1)) *
          POPULATION_RATIO[gender][parseInt(ageGroup)];
        totalX +=
          ((statsByAgeGroup[ageGroup][gender]?.x || 0) /
            (statsByAgeGroup[ageGroup][gender]?.count || 1)) *
          POPULATION_RATIO[gender][parseInt(ageGroup)];
      }
    }

    addGenderStats("male");
    addGenderStats("female");
  });

  const total = totalO + totalD + totalX + totalGRAY || 1;

  return (
    <div style={{ paddingTop: 0 }}>
      <table
        css={css`
          width: 200px;
        `}
      >
        <tr>
          <td>O</td>
          <td>{((totalO / total) * 100).toFixed(1)}%</td>
        </tr>
        <tr>
          <td>?</td>
          <td>{((totalD / total) * 100).toFixed(1)}%</td>
        </tr>
        <tr>
          <td>X</td>
          <td>{((totalX / total) * 100).toFixed(1)}%</td>
        </tr>
        <tr>
          <td>응답수 미달</td>
          <td>{((totalGRAY / total) * 100).toFixed(1)}%</td>
        </tr>
      </table>
    </div>
  );
}
