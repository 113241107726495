import React, { useEffect, useState } from "react";
import { THERMOMETER_COLOR } from "../../../../constants/enums";
import thermometer from "./thermometer.png";

export default function StanceTestTypeScoreResult({
  score
}: {
  score: number;
}) {
  const [countNumber, setCountNumber] = useState<number>(0);
  const SCOREBOARD_LENGTH = 200;
  const SCOREBOARD_WIDTH = 120;
  const THERMOMETER_CENTER = 75;
  const COLOR: string =
    countNumber <= -33.4
      ? THERMOMETER_COLOR.X
      : countNumber <= 33.4
      ? THERMOMETER_COLOR.DUNNO
      : THERMOMETER_COLOR.O;

  useEffect(() => {
    const countdown = setTimeout(() => {
      if (Math.abs(score) - 1 >= Math.abs(countNumber)) {
        if (score > 0) {
          setCountNumber(prev => prev + 1);
        } else if (score < 0) {
          setCountNumber(prev => prev - 1);
        }
      }
    }, 10);

    return () => clearTimeout(countdown);
  }, [countNumber]);

  return (
    <div>
      <div
        style={{
          paddingTop: 20,
          width: 120,
          height: 80,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 70,
          borderRadius: 15,
          color: COLOR
        }}
      >
        {`${countNumber}℃`}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center"
        }}
      >
        <div
          style={{
            marginTop: 50,
            width: SCOREBOARD_WIDTH,
            height: SCOREBOARD_LENGTH,
            borderRadius: 30,
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "center",
            backgroundImage: `url(${thermometer})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            paddingBottom: 14
          }}
        >
          <div
            style={{
              width: 36,
              height: 36,
              backgroundColor: COLOR,
              borderRadius: "50%"
            }}
          ></div>
        </div>
        <div
          style={{
            position: "relative",
            top: 13,
            left: -64,
            width: 9,
            height: 125,
            display: "flex",
            alignItems: "flex-end"
          }}
        >
          <div
            className="stanceTestResultBall_goUp"
            style={{
              width: "100%",
              height: THERMOMETER_CENTER + Math.floor(score / 2), //score === 0
              backgroundColor: COLOR,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
