import React from "react";
import { PRIMARY_RED } from "../../../constants/enums";

export default function NewBadge() {
  return (
    <div
      style={{
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        backgroundColor: PRIMARY_RED,
        position: "absolute",
        right: -10,
        top: -8,
        fontSize: "10px",
        color: "black"
      }}
    >
      <div>N</div>
    </div>
  );
}
