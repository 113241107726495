/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import MiniQuestionPieChart from "../../../topic/Question/QuestionChart/MiniQuestionPieChart";
import { TribeExpression } from "../../../../constants/expressions";
import UserProfilePhoto from "../../StatsView/UserProfilePhoto";
import { css } from "@emotion/react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";

export default function CardAnswerTribeChart({ cardId }: { cardId: string }) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerTribeStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      {Object.keys(cardAnswerStats).map(tribeId => {
        const ratio =
          (cardAnswerStats[tribeId].o || 0) /
          (cardAnswerStats[tribeId].count || 1);
        return (
          <div
            key={tribeId}
            style={{
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <MiniQuestionPieChart
              cardChartStats={cardAnswerStats[tribeId]}
              size={60}
            />

            <div style={{ position: "absolute", top: 32, right: 3 }}>
              <UserProfilePhoto
                tribeId={tribeId}
                expression={
                  ratio > 0.6
                    ? TribeExpression.HAPPY
                    : ratio > 0.3
                    ? TribeExpression.NORMAL
                    : TribeExpression.ANGRY
                }
                width={"30px"}
              />
            </div>
            <div
              css={css`
                margin-top: 10px;
                font-size: 12px;
                text-align: center;
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>
          </div>
        );
      })}
    </div>
  );
}
