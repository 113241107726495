/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { removeIdKey } from "../../../../utils/utils";
import {
  BestCommentsForCardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import TribeCommentsForPortrait from "../../../topic/Question/Comments/TribeCommentsForPortrait";

export default function MiniBestCommentsForPortrait({
  cardId,
  length = 3,
  truncate = 100
}: {
  cardId: string;
  tribe?: string;
  length?: number;
  disableExpressions?: boolean;
  truncate?: number;
}) {
  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );

  removeIdKey(bestComments);

  return (
    <div style={{ width: "100%", textAlign: "left" }}>
      <TribeCommentsForPortrait
        cardId={cardId}
        disableExpressions={false}
        length={length}
        truncate={truncate}
      />
    </div>
  );
}
