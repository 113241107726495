import { FormControl, MenuItem, Select } from "@material-ui/core";
import firebase from "firebase/compat";
import React, { useContext, useEffect } from "react";
import {
  DeclinedField,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";

import _ from "lodash";
import { sendCoinFromOxoTo } from "../../../utils/assets";

export default function UserAge({
  birthYear,
  setBirthYear,
  declinedFields
}: {
  birthYear: number;
  setBirthYear: (p: number) => void;
  declinedFields: DeclinedField[];
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  useEffect(() => {
    if (currentUserId && birthYear) {
      sendCoinFromOxoTo(currentUserId, 10, "[1회성]나이입력");

      firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .set(
          {
            id: currentUserId,
            userBasicInfo: {
              birthYear: birthYear,
              lastUpdated: new Date().valueOf()
            }
          },
          { merge: true }
        );
    }
  }, [birthYear]);

  return (
    <FormControl>
      <Select
        disableUnderline={true}
        value={birthYear}
        displayEmpty
        style={{
          width: "280px",
          paddingLeft: 20,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "16px",
          color: birthYear ? "#3E7A99" : "#c5d7e0"
        }}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
          setBirthYear(e.target.value as number);
        }}
      >
        <MenuItem value={birthYear ? birthYear : 0}>
          {!birthYear && birthYear !== 0
            ? `${birthYear}년`
            : "태어나신 년도를 선택해주세요"}
        </MenuItem>
        {_.range(
          new Date().getFullYear() - 5,
          new Date().getFullYear() - 120,
          -1
        ).map(year => (
          <MenuItem key={year} value={year}>
            {year}년
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
