import { MenuItem } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ContentReport,
  ContentReportResolution,
  FirestoreCollection,
  ReferenceType
} from "../../../constants/firestore_schema";
import { checkAndRedirectForLogin, useIsAdmin } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";

export default function ContentReportButton({
  setBlind,
  reportedUserId,
  reportedUserTribe,
  referenceId,
  referenceType,
  onClose
}: {
  setBlind: () => void;
  reportedUserId: string;
  reportedUserTribe: string;
  referenceId: string;
  referenceType: ReferenceType;
  onClose: () => void;
}) {
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;
  const isAdmin = useIsAdmin();

  return (
    <MenuItem
      key={"report"}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: 14
      }}
      onClick={() => {
        if (isAdmin) {
          if (window.confirm("[관리자] 블라인드 처리 하시겠습니까?")) {
            setBlind();
          }
        } else if (currentUserId) {
          const reportingReason = window.prompt(
            "문제있는 내용에 대해 신고합니다. 신고하시는 이유를 알려주세요. 24시간내에 옥소폴리틱스 팀에서 조치를 취하겠습니다."
          );

          if (reportingReason) {
            firebase
              .firestore()
              .collection(FirestoreCollection.CONTENT_REPORTS)
              .add({
                reportingUserId: currentUserId,
                reportingUserTribe: currentUserMetadata?.tribeId,
                reportedUserId: reportedUserId,
                reportedUserTribe: reportedUserTribe,
                referenceId: referenceId,
                referenceType: referenceType,
                reportingReason: reportingReason,
                createdAt: new Date().valueOf(),
                resolution: ContentReportResolution.R1_REVIEW_REQUESTED
              } as ContentReport)
              .then(() => {
                window.alert(
                  "신고가 접수되었습니다. 24시간 이내에 조치하도록 하겠습니다."
                );
              });
          }
        } else {
          if (
            !checkAndRedirectForLogin(
              history,
              currentUserId,
              currentUserMetadata
            )
          ) {
            return null;
          }
        }
        onClose();
      }}
    >
      <div style={{ marginRight: 5 }}>
        <WarningOutlined />
      </div>{" "}
      <div style={{ paddingBottom: 7 }}>신고하기</div>
    </MenuItem>
  );
}
