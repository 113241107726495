import React, { CSSProperties, useContext } from "react";
import { CardSchema } from "../../constants/firestore_schema";
import { DataPropsContext } from "../foundation/Contexts/DataPropsContext";
import { useIsAdmin } from "../../utils/utils";
import Chip from "@material-ui/core/Chip";

export function CardDisplayStatus({
  card,
  hideOnDisplay = false
}: {
  card: CardSchema;
  hideOnDisplay?: boolean;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  if (!useIsAdmin()) {
    return null;
  }

  const style: CSSProperties = { fontSize: 12, fontWeight: "bold" };
  return (
    <div>
      {card.displayToUsers &&
      (card.publishedAt || card.createdAt) <= new Date().valueOf() ? (
        !hideOnDisplay && (
          <Chip color={"primary"} label={"공개"} size={"small"} style={style} />
        )
      ) : card.displayToUsers ? (
        <Chip
          color={"default"}
          label={"공개 예약됨"}
          size={"small"}
          style={style}
        />
      ) : (
        <Chip
          color={"secondary"}
          label={"비공개"}
          size={"small"}
          style={style}
        />
      )}
    </div>
  );
}
