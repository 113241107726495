/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { DATA_PLATFORM_YELLOW_GRAY } from "../../../constants/data_platform_colors";
import CardAnswerDailyReportChart from "../../charts/CardAnswerDailyReportChart";
import useSupporters from "../DailyReportsView/useSupporters";
import DailyHotIssues from "./DailyHotIssues";
import DailyPersonReport from "./DailyPersonReport";

export const CSS_TITLE = css`
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  color: ${DATA_PLATFORM_YELLOW_GRAY};
`;

export default function DashboardPage({
  personHashtag,
  competitorHashtag
}: {
  personHashtag: string;
  competitorHashtag: string;
}) {
  const supporterData = useSupporters(personHashtag, competitorHashtag);

  return (
    <div
      css={css`
        margin-top: 30px;
      `}
    >
      <div css={[CSS_TITLE]}>1 오늘의 {personHashtag}</div>
      <div
        css={css`
          margin-top: 10px;
        `}
      >
        <DailyPersonReport personHashtag={personHashtag} />
      </div>
      <div
        css={css`
          margin-top: 40px;
          width: 100%;
          height: 1px;
          background-color: ${DATA_PLATFORM_YELLOW_GRAY};
        `}
      ></div>

      <div
        css={css`
          height: 40px;
        `}
      />
      <div css={[CSS_TITLE]}>2 오늘의 설문조사</div>
      <div
        css={css`
          margin-top: 50px;
        `}
      >
        <CardAnswerDailyReportChart
          addMz
          supporterData={supporterData}
          personHashtag={personHashtag}
          competitorHashtag={competitorHashtag}
        />
      </div>
      <div
        css={css`
          margin-top: 50px;
          width: 100%;
          height: 1px;
          background-color: ${DATA_PLATFORM_YELLOW_GRAY};
        `}
      ></div>
      <div
        css={css`
          height: 40px;
        `}
      />
      <div css={[CSS_TITLE]}>3 오늘의 핫이슈</div>
      <div>
        <DailyHotIssues personHashtag={personHashtag} />
      </div>
    </div>
  );
}
