import firebase from "firebase/compat";

import React, { Dispatch, SetStateAction } from "react";
import { GRAY_EXTRA_LIGHT, TRIBE_INFO_BY_ID } from "../../../constants/enums";
import UserProfilePhoto from "../../views/StatsView/UserProfilePhoto";

export default function TribeSelector({
  currentTribeId,
  setCurrentTribeId
}: {
  currentTribeId: string | undefined;
  setCurrentTribeId: Dispatch<SetStateAction<string | undefined>>;
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      {[...Object.keys(TRIBE_INFO_BY_ID)].map((tribeId, i) => (
        <div style={{ position: "relative" }} key={tribeId}>
          <div
            key={tribeId}
            style={{
              borderRadius: "30px",
              padding: "5px",
              margin: "5px",
              width: "45px",
              height: "45px",
              backgroundColor: GRAY_EXTRA_LIGHT,
              opacity: tribeId === currentTribeId ? 1 : 0.2,
              cursor: "pointer",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px"
            }}
            onClick={() => {
              firebase.analytics().logEvent("v4_click_select_tribe_button", {
                event_category: "tribe_selector",
                event_label: tribeId
              });
              if (tribeId === currentTribeId) {
                setCurrentTribeId(undefined);
              } else {
                setCurrentTribeId(tribeId);
              }
            }}
          >
            <div style={{ display: "inline-block" }}>
              <UserProfilePhoto width={"35px"} tribeId={tribeId} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
