/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";
import {
  DIVIDER_COLOR,
  OXO_GRAY700,
  OXO_GRAY900
} from "../../constants/colors";
import CardAnswerStanceV1ColumnBarChart from "../views/HashCardView/Comment/CardAnswerStanceV1ColumnBarChart";
import Logo from "../views/HashtagTimeSeriesView/img/Logo";

import {
  AnswerCountSchema,
  AnswerCountsSchema,
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";
import useFirestoreLiveDocument from "../../utils/hooks/useFirestoreLiveDocument";

import legends from "./images/legends.png";
import useFirestoreGetDocument from "../../utils/hooks/useFirestoreGetDocument";
import AgeBarChartV2Container from "../views/DataExplorerView/gender_age_chart/AgeBarChartV2Container";
import OxLightBarChartContainer from "../views/DataExplorerView/OxLightBarChartContainer";

export default function CardAnswerComplexStanceAndTribeChart({
  card,
  answerStats,
  tribeStats,
  averageCardAnswer
}: {
  card: CardSchema;
  answerStats?: AnswerCountSchema;
  tribeStats?: CardAnswerTribeStatsSchema;
  averageCardAnswer?: AnswerCountsSchema;
}) {
  const [cardAnswers] = answerStats
    ? [answerStats]
    : useFirestoreLiveDocument<CardAnswerStatsSchema>(
        FirestoreCollection.CARD_ANSWER_STATS,
        card.id
      );

  const cardAnswerTribeStats =
    tribeStats ??
    useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
      FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
      card.id
    );

  const date = new Date(
    card.featuredOnNewsTimelineAt ||
      card.featuredOnTodayFocusAt ||
      card.featuredOnTodayTimelineAt ||
      card?.publishedAt ||
      "0"
  );
  const releasedDate = `${date.getFullYear()}년 ${
    date.getMonth() + 1
  }월 ${date.getDate()}일`;

  if (!cardAnswers?.count) {
    return <p>응답이 없어서, 보여드릴 차트가 준비되지 않았어요</p>;
  }

  if (!cardAnswerTribeStats) {
    return null;
  }
  return (
    <article
      css={css`
        padding: 30px 40px;
        border: solid 1px ${DIVIDER_COLOR};
        border-radius: 20px;
        box-sizing: border-box;
        background-color: #f9f9f9;
      `}
    >
      <header
        css={css`
          font-weight: 800;
          font-size: 30px;
          color: ${OXO_GRAY700};
          margin-bottom: 10px;
        `}
      >
        {card.title}
      </header>
      <div
        css={css`
          display: flex;
          align-items: center;
          color: ${OXO_GRAY900};
          font-weight: 400;
          font-size: 20px;
        `}
      >
        <span>
          {releasedDate}, {cardAnswers?.count}명 응답옥소폴리틱스 제공 (단위: %)
        </span>
        <span
          css={css`
            margin: 0 24px;
          `}
        >
          |
        </span>
        <span>
          <img
            src={legends}
            css={css`
              width: 190px;
              height: 25px;
              object-fit: contain;
            `}
          />
        </span>
      </div>
      <div
        css={css`
          margin-top: 30px;
        `}
      >
        <OxLightBarChartContainer
          cardId={card.id}
          width={900}
          averageCardAnswer={averageCardAnswer}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-top: -50px;
        `}
      >
        <div
          css={css`
            margin-right: 90px;
          `}
        >
          <CardAnswerStanceV1ColumnBarChart
            cardId={card.id}
            customCss={css`
              width: 350px;
            `}
            barHeight={300}
          />
        </div>

        <div
          css={css`
            margin-top: -0px;
          `}
        >
          <AgeBarChartV2Container cardId={card.id} />
        </div>
      </div>
      <footer
        css={css`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Logo width={125} height={25} />
      </footer>
    </article>
  );
}
