import React, { useContext, useEffect, useState } from "react";
import {
  DeclinedField,
  FirestoreCollection,
  UserBasicInfo,
  UserProfileMetadataSchema
} from "../../../../constants/firestore_schema";
import SwipeableViews from "react-swipeable-views";
import gender_oxo from "../img/gender_oxo.png";
import age_oxo from "../img/age_oxo.png";
import { ROUTES } from "../../../../constants/routes";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import UserRegion from "../../../profile/OXOUserProfile/UserRegion";
import UserAge from "../../../profile/OXOUserProfile/UserAge";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import { parseDoc } from "../../../../utils/utils";
import { useHistory } from "react-router";
import { GlobalVariableContext } from "../../../foundation/Contexts/GlobalVariableContext";
import firebase from "firebase/compat";
import { sendCoinFromOxoTo } from "../../../../utils/assets";
import { updateUserInfoInPastAnswerCard } from "../../../../lib/answer_lib";

interface optionInfoSchema {
  name: string;
  value: string;
}

interface questionInfoSchema {
  question: string;
  photo: string;
  type: string;
  options?: optionInfoSchema[];
  issue?: string;
}

export default function GenderAgeRegion() {
  const history = useHistory();
  const global = useContext(GlobalVariableContext);
  const testResultUrl = global?.testResultUrl || ROUTES.HOME;
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  if (!currentUserId) {
    history.push(ROUTES.HOME);
  }

  const [swipeIndex, setSwipeIndex] = useState<number>(0);
  const [windowWidth, windowHeight] = useWindowSize();
  const [gender, setGender] = useState<string>("");

  const firebaseMetadata = firebase
    .firestore()
    .collection(FirestoreCollection.USER_PROFILE_METADATA);

  const [birthYear, setBirthYear] = useState(
    currentUserMetadata?.userBasicInfo?.birthYear || 0
  );
  const [district, setDistrict] = useState(
    currentUserMetadata?.userBasicInfo?.district || "선거구"
  );
  const [address, setAddress] = useState(
    currentUserMetadata?.userBasicInfo?.address || ""
  );

  const [declinedFields, setDeclinedFields] = useState<DeclinedField[]>([]);

  const questionsInfo: questionInfoSchema[] = [
    {
      question: "성별이 \n 어떻게 되세요?",
      photo: gender_oxo,
      type: "mixUp",
      options: [
        { name: "남자", value: "male" },
        { name: "여자", value: "female" }
      ]
    },
    {
      question: "몇 년도에 \n 태어나셨나요?",
      photo: age_oxo,
      type: "select",
      issue: "birthYear"
    }
  ];

  useEffect(() => {
    if (currentUserId) {
      firebaseMetadata
        .doc(currentUserId)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            const unsubscribe = firebaseMetadata
              .doc(currentUserId)
              .onSnapshot(snapshot => {
                const userData: UserProfileMetadataSchema = parseDoc(snapshot);

                setDeclinedFields(userData.userBasicInfo?.declinedFields || []);
              });
            return () => unsubscribe();
          }
        });
    }
  }, [currentUserId]);

  //Migration
  useEffect(() => {
    if (currentUserId && currentUserMetadata) {
      firebaseMetadata
        .doc(currentUserId)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            const userData: UserBasicInfo = {};

            if (
              currentUserMetadata?.userGender &&
              currentUserMetadata?.userBirthYear &&
              currentUserMetadata?.userDistrict &&
              currentUserMetadata?.userAddress
            ) {
              userData.gender = currentUserMetadata?.userGender;
              userData.birthYear = currentUserMetadata?.userBirthYear;
              userData.district = currentUserMetadata?.userDistrict;
              userData.address = currentUserMetadata?.userAddress;
              userData.lastUpdated = new Date().valueOf();

              firebaseMetadata.doc(currentUserId).update({
                userGender: firebase.firestore.FieldValue.delete(),
                userBirthYear: firebase.firestore.FieldValue.delete(),
                userDistrict: firebase.firestore.FieldValue.delete(),
                userAddress: firebase.firestore.FieldValue.delete()
              });
            } else {
              if (currentUserMetadata?.userGender) {
                userData.gender = currentUserMetadata?.userGender;
                firebaseMetadata.doc(currentUserId).update({
                  userGender: firebase.firestore.FieldValue.delete()
                });
              }

              if (currentUserMetadata?.userBirthYear) {
                userData.birthYear = currentUserMetadata?.userBirthYear;
                firebaseMetadata.doc(currentUserId).update({
                  userBirthYear: firebase.firestore.FieldValue.delete()
                });
              }

              if (
                currentUserMetadata?.userDistrict &&
                currentUserMetadata?.userAddress
              ) {
                userData.district = currentUserMetadata?.userDistrict;
                userData.address = currentUserMetadata?.userAddress;

                firebaseMetadata.doc(currentUserId).update({
                  userDistrict: firebase.firestore.FieldValue.delete(),
                  userAddress: firebase.firestore.FieldValue.delete()
                });
              }
            }

            firebaseMetadata.doc(currentUserId).update({
              userBasicInfo: {
                ...currentUserMetadata?.userBasicInfo,
                ...userData
              }
            });
          }
        });
    }
  }, []);

  useEffect(() => {
    if (currentUserId && gender) {
      sendCoinFromOxoTo(currentUserId, 10, "[1회성]성별입력");

      firebaseMetadata.doc(currentUserId).set(
        {
          id: currentUserId,
          userBasicInfo: {
            gender: gender,
            lastUpdated: new Date().valueOf()
          }
        },
        { merge: true }
      );
    }
  }, [gender]);

  const selectTypeChecker = (type: string) => {
    switch (type) {
      case "birthYear":
        return (
          <UserAge
            birthYear={birthYear}
            setBirthYear={setBirthYear}
            declinedFields={declinedFields}
          />
        );

      case "region":
        return (
          declinedFields && (
            <UserRegion
              district={district}
              setDistrict={setDistrict}
              address={address}
              setAddress={setAddress}
              declinedFields={declinedFields}
            />
          )
        );
    }
  };

  const updateLastUpdate = (last?: boolean) => {
    const newUserInfo = currentUserMetadata?.userBasicInfo;

    const userData: UserBasicInfo = {};
    userData.lastUpdated = new Date().valueOf();

    if (currentUserId) {
      firebaseMetadata.doc(currentUserId).set(
        {
          userBasicInfo: {
            ...newUserInfo,
            ...userData
          }
        },
        { merge: true }
      );

      if (currentUserMetadata) {
        updateUserInfoInPastAnswerCard(currentUserId, currentUserMetadata);
      }
    }

    const pushUrl = testResultUrl;
    global?.setTestResultUrl(ROUTES.HOME);
    history.push(pushUrl);
  };

  useEffect(() => {
    if (
      currentUserMetadata?.userBasicInfo?.lastUpdated &&
      currentUserMetadata?.userBasicInfo.gender &&
      currentUserMetadata?.userBasicInfo.birthYear
    ) {
      history.push(testResultUrl);
    } else if (
      currentUserMetadata?.userBasicInfo?.lastUpdated &&
      currentUserMetadata?.userBasicInfo.gender
    ) {
      setSwipeIndex(1);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        height: windowHeight
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: windowHeight,
          paddingTop: 10
        }}
      >
        <div
          style={{
            paddingTop: 85
          }}
        >
          <SwipeableViews enableMouseEvents index={swipeIndex}>
            {questionsInfo.map((item, i) => {
              return (
                <div key={i}>
                  <div>
                    <div>
                      <img
                        src={item.photo}
                        alt={item.question}
                        height={120}
                        width={120}
                      />
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: 37,
                        fontSize: 18,
                        display: "flex",
                        height: 60,
                        flexDirection: "column",
                        justifyContent: "space-around"
                      }}
                    >
                      {item.question.split("\n").map((text, index1) => {
                        return <span key={index1}>{text}</span>;
                      })}
                    </div>
                    <div
                      style={{
                        height: 230,
                        paddingTop: 44,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        color: "#3E7A99"
                      }}
                    >
                      {item.type === "mixUp" && item.options ? (
                        item.options.map((option, index2) => {
                          return (
                            <div
                              key={index2}
                              style={{
                                width: 297,
                                height: 73,
                                borderRadius: 10,
                                border: `1px solid #3E7A99`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                fontWeight: "bold",
                                backgroundColor:
                                  gender === option.value ? "#3E7A99" : "white",
                                color:
                                  gender === option.value ? "white" : "#3E7A99"
                              }}
                              onClick={e => {
                                e.preventDefault();
                                setGender(option.value);
                                setSwipeIndex(i + 1);
                              }}
                            >
                              {option.name}
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div
                            style={{
                              width: 297,
                              height: 73,
                              borderRadius: 10,
                              border: `1px solid #3E7A99`,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              fontWeight: "bold"
                            }}
                          >
                            {item.type === "select" &&
                              item.issue &&
                              selectTypeChecker(item.issue)}
                          </div>

                          <div
                            style={{
                              width: 297,
                              height: 73,
                              borderRadius: 10,
                              backgroundColor: "#3E7A99",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              color: "white"
                            }}
                            onClick={() => {
                              if (swipeIndex === 1) {
                                if (birthYear === 0) {
                                  return alert("태어나신 년도를 선택해주세요");
                                }
                              } else if (swipeIndex === 2) {
                                if (district === "선거구") {
                                  return alert("살고 계신 동을 입력해주세요");
                                }
                              }

                              if (swipeIndex === questionsInfo.length - 1) {
                                updateLastUpdate();
                              }
                              setSwipeIndex(i + 1);
                            }}
                          >
                            다음
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
}
