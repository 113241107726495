import { Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";

export default function MarkdownImageRenderer({ src }: { src?: string }) {
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);

  const [fitScreen, setFitScreen] = useState<boolean>(true);

  if (!src) {
    return null;
  }
  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Dialog
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"cardPopup"}
      >
        <DialogContent
          onClick={e => {
            setFitScreen(prev => !prev);
          }}
        >
          <img
            src={src}
            style={fitScreen ? { maxWidth: "100%" } : {}}
            alt="src"
          />
        </DialogContent>
      </Dialog>
      <div
        style={{ cursor: "pointer" }}
        onClick={e => {
          setOpenImageModal(true);
        }}
      >
        <img
          src={src}
          style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: 5 }}
          alt="src"
        />
      </div>
    </div>
  );
}
