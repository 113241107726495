import React from "react";

import { ApexOptions } from "apexcharts";

import ReactApexChart from "react-apexcharts";
import {
  ANSWER_O_STROKE,
  ANSWER_DUNNO_STROKE,
  ANSWER_X_STROKE
} from "../../../constants/colors";

import { AnswerCountSchema } from "../../../constants/firestore_schema";
import { apexChartBasicOptions } from "./fixtures";

export default function TribePieChartContainer({
  stats,
  colorScale = [ANSWER_X_STROKE, ANSWER_DUNNO_STROKE, ANSWER_O_STROKE]
}: {
  stats: AnswerCountSchema;
  colorScale?: string[];
}) {
  if (!stats) {
    return null;
  }

  const data = [stats.o || 0, stats["?"] || 0, stats.x || 0];

  const options: ApexOptions = {
    ...apexChartBasicOptions,
    series: data.reverse(),
    states: {
      active: {
        filter: {
          type: "none"
        }
      }
    },
    labels: ["찬성", "중립", "반대"],
    chart: {
      type: "pie",
      toolbar: {
        show: false
      }
    },
    colors: colorScale,
    plotOptions: {
      pie: {
        expandOnClick: false,
        dataLabels: {
          offset: -5
        },
        donut: {
          size: "20"
        }
      }
    }
  };

  return (
    <ReactApexChart
      options={options}
      series={data}
      type="pie"
      width={82.5}
      height={85}
    />
  );
}
