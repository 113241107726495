import { TribeExpression } from "./expressions";

// key => asset
export interface AssetValueSchema {
  createdAt: number;
  asset: string;
  amountMicros: number;
  yesterdayAmountMicros?: number;
}

// key => asset+20210101(yyyymmdd)
export interface AssetValueSchema {
  createdAt: number;
  asset: string;
  amountMicros: number;
}

// key => hash
export interface AssetTransactionSchema {
  id?: string;
  createdAt: number;
  fromUserId: string;
  toUserId: string;
  fromAssetType: string;
  toAssetType: string;
  fromAssetTypeAmountMicros: number;
  toAssetTypeAmountMicros: number;
  reason: string;
}

// key => userId + assetType
export interface AssetBalanceSchema {
  id?: string;
  userId: string;
  assetType: string;
  updatedAt: number;
  amountMicros?: number;
  popupText?: string;
}

export interface UserVectorsSchema {
  [userId: string]: UserVectorSchema;
}

/**
 * @deprecated Fetch from CardAnswers
 */
export interface UserVectorSchema {
  [questionId: string]: string;
}

export interface ErrorLog {
  message: string;
  stack: string;
  userId: string;
  createdAt: number;
  name: string;
  component: string;
}

export interface UserVectorWithTimestampSchema {
  [questionId: string]: {
    answer: string;
    firstUpdate: number;
    updates: [
      {
        answer: string;
        lastUpdate: number;
      }
    ];
  };
}

/**
 * Plan: simplify
 * {
 *   t: number,
 *   x: number,
 *   y: number
 * }
 */
export interface UserMapSchema {
  [userId: string]: {
    clusterId: number;
    coordinates: [number, number];
  };
}

export interface CardsSchema {
  [cardId: string]: CardSchema;
}

export enum CardSubType {
  HASHTAG_PERSON = "인물",
  HASHTAG_ORGANIZATION = "조직",
  HASHTAG_EVENT = "이벤트",
  HASHTAG_ISSUE = "이슈",
  HASHTAG_ELECTION = "선거",
  HASHTAG_STANCETEST = "성향테스트"
}

// ---- Card Schema Start ----
export interface CardSchema {
  id: string;
  type: CardType;
  subType?: CardSubType;
  title: string;
  /** photos와 description 최상위로 이동 */
  description?: string;
  photos?: string[];
  createdBy: string;
  createdByTribeId: string;
  createdAt: number;
  debateTitle?: string;
  // 부적절한 내용 신고시 블라인드 처리
  bad?: boolean;
  belongToCardIds?: string[];
  abbreviation?: string;

  /** Aggregation fields */
  comments: number;

  /** Timeline control fields */
  featuredOnTodayFocus: CardFeatureStatus;
  featuredOnTodayFocusAt?: number;
  featuredOnNewsTimeline: CardFeatureStatus;
  featuredOnNewsTimelineAt?: number;
  featuredOnTodayTimeline: CardFeatureStatus;
  featuredOnTodayTimelineAt?: number;
  featuredOnCommunityTimeline: CardFeatureStatus;
  featuredOnCommunityTimelineAt?: number;

  /** Metadata fields */
  topicMetadataV2?: TopicMetadataV2;
  congressmanMetadata?: CongressmanMetadata;
  postMetadata?: PostMetadata;
  storyMetadata?: StoryMetadata;
  hashtagMetadata?: YamlMetadata;
  yamlMetadata?: YamlMetadata;
  debateCommentMetadata?: DebateCommentMetadata;
  weeklyReviewMetadata?: WeeklyReviewMetadata;
  newsTickerMetadata?: NewsTickerMetadata;
  realNamePostMetadata?: RealNamePostMetadata;
  betaTesterMetadata?: BetaTesterMetadata;
  sponsoredContentMetadata?: SponsoredContentMetadata;
  jurisdictionMetadata?: JurisdictionMetadata;

  /**
   * 성향 테스트 필드
   */
  parentCardId?: string;
  testMetadata?: TestMetadata;
  testQuestionMetadata?: TestQuestionMetadata;

  hashtags?: string[];
  speakerHashTag?: string[];

  /** @deprecated */
  displayToUsers: boolean;

  /** @deprecated */
  publishedAt?: number;

  /** @deprecated */
  displayInOnboarding: boolean;

  /** @deprecated **/
  campaigns: Campaign[];

  /** @deprecated */
  bestComments?: { [tribeId: string]: string };

  /** @deprecated */
  topicMetadata?: TopicMetadata;

  /** @deprecated Use CARD_VIEW_COUNTERS **/
  views?: number;

  /** @deprecated */
  cardScore?: number;

  /** @deprecated use questionAnswerStats*/
  answers: number;
}

export interface JurisdictionMetadata {
  // World 0, Asia 1, South Korea 2, 서울특별시 3, 종로구 4, 평창동 5
  // World 0, North East Asia 1, South Korea 2, 서울특별시 3, 종로구 4, 평창동 5
  // World 0, North America 1, USA 2, CA 3, San Mateo County 4, San Mateo 5

  level: number;
  isLeafNode: boolean;
  defaultLocale: string;
  locales: {
    [locale: string]: {
      name: string;
      fullAddress: string;
    };
  };
  parentJurisdictionId?: string;
  belongingJurisdictionIds?: string[];
}

export enum CardFeatureStatus {
  ON = "on",
  DRAFT = "draft",
  OFF = "off"
}

export interface BestCommentsForCardSchema {
  [tribeId: string]: string;
}

export interface BetaTesterMetadata {
  betaTestHashtag: string;
  betaTestCount: number;
}

export interface SponsoredContentMetadata {
  type: SponsorContentsType;
  sponsorName: string;
  sponsorContentLink?: string;
}

export enum SponsorContentsType {
  BOOK = "book"
}

export interface ReportTextSchema {
  id: string;
  reportText: string;
}

export interface YamlMetadata {
  description: string;
  photos?: string[];
}

/**
 * 성향테스트용 메타데이터
 */
export interface TestMetadata {
  introDescription: string;
  resultDescription: string;
  photos?: string[];
  testResultVectors: UserVectorsSchema;
}

export interface TestQuestionMetadata {
  description: string;
  photos?: string[];
  tags: string;
}

/**
 * @deprecated Use parentCardId instead.
 */
export enum Campaign {
  US_2020_PRESIDENTIAL = "2020 미국 대선"
}

export enum CardType {
  TOPIC = 0,
  PRESIDENT = 1,
  MEDIA = 2,
  PARTY = 3,

  /** @deprecated */
  PERSON = 4,
  /** @deprecated */
  POLITICIAN = 5,
  /** @deprecated */
  CONGRESSMAN = 6,

  POST = 7,
  OXO = 8, // OXO Card uses PostMetadata
  STORY = 9,
  TEST = 10,
  TEST_QUESTION = 11,
  HASHTAG_CARD = 12,
  REAL_NAME_USER_SUPPORTING_CARD = 13,
  NEWS_TICKER = 14, // USES topicMetadataV2
  DEBATE = 15,
  STORY_MESSAGE = 16,

  /** @deprecated */
  TRIBE_CHAT = 17,
  REAL_NAME_POST = 18,
  EVENT = 19,
  POST_GROUP = 20,

  /** @deprecated. RSS로 데이터는 쌓이고 있음. Populated by Cloud Function scheduledNewsRss. */
  NEWS_ARTICLE = 21,

  /** 지역구 데이터 */
  JURISDICTION = 22,
  ORGANIZATION = 23, // Reserved for future (초딩 프로젝트)
  FEEDBACK = 24,

  /** Populated by Cloud Function news-scrapNews */
  NEWS_SCRAP = 25,

  SURVEY = 26,
  SURVEY_QUESTION = 27,
  SURVEY_RESULT = 28,

  WITHDRAWAL = 29,
  SCRAP = 30,
  USER_SURVEY = 31
}

export interface StoryMetadata {
  description: string;
  linkedCards: string[];
  photos?: string[];
  likeUserAndMetadata: LikeUserAndMetadataSchema;
}

/**
 * @deprecated Use TopicMetadataV2
 */
export interface TopicMetadata {
  agree: string;
  disagree: string;
  abstract: string;
  tags: string;
}

export interface DebateCommentMetadata {
  speaker: string;
  comment: string;
}

export interface NewsInfo {
  media?: string;
  address?: string;
  title?: string;
}

export interface NewsTickerMetadata {
  title?: string;
  newsInfo?: NewsInfo[];
  photos?: string[];
  tags?: string;
}

export interface RealNamePostMetadata {
  description?: string;
  descriptionJson?: string;
  likeUserAndTribes: LikeUserAndTribesSchema;
  photos?: string[];
}

export interface TopicMetadataV2 {
  description: string;
  photos?: string[];

  agreeOptionName?: string;
  agreeOptionDetails?: string;
  dunnoOptionName?: string;
  dunnoOptionDetails?: string;
  disagreeOptionName?: string;
  disagreeOptionDetails?: string;

  /**
   * @deprecated Use hashtags
   */
  tags?: string;
}

export interface WeeklyReviewMetadata {
  description: string;
}

export interface PostMetadata {
  description?: string;
  descriptionJson?: string;
  /**
   * @deprecated Use likeUserAndTribes
   */
  likeUserMap: LikeUserMap;
  likeUserAndTribes: LikeUserAndTribesSchema;
  photos?: string[];
  expression?: TribeExpression;
}

// ---- Card Schema End ----

/**
 * @deprecated Use CardSchema
 */
export interface PostSchema {
  id: string;
  title: string;
  description?: string;
  createdByUserId: string;
  createdByTribeId: string;
  createdAt: number;
  updatedAt?: number;
  photo?: string;
  answers: number;
  comments: number;
  postScore: number;
  tags?: string;
  likeUserMap: LikeUserMap;
}

export interface CongressmanMetadata {
  district: string;
  party: string;
  name: string;
  nameHanja: string;
  gender: string;
  birthdate: string;
  address: string;
  job: string;
  education: string;
  career: string;
  votes?: number;
}

export interface QuestionAnswersSchema {
  [questionId: string]: UserAnswerSchema;
}

export interface UserAnswerSchema {
  [userId: string]: string;
}

export interface UserProfileMetadataMapSchema {
  [userId: string]: UserProfileMetadataSchema;
}

export enum DeclinedField {
  GENDER = "gender",
  BIRTHYEAR = "birthYear",
  ADDRESS = "address",
  DISTRICT = "district"
}

export interface UserBasicInfo {
  declinedFields?: DeclinedField[];
  lastUpdated?: number;
  gender?: string;
  birthYear?: number;
  address?: string;
  district?: string;
}

// OXO ID: HGBQzYwruudhK9R50PXXVmtaGWm2
export interface UserProfileMetadataSchema {
  id?: string;

  modifier: string;
  x: number;
  y: number;
  /** 로그인 후 성향 테스트 안할 수 있게 되어서 ? 추가*/
  tribeId?: string;
  isAgreeWithTerms?: boolean;
  /** Stats */
  countPosts: number;
  countLikes: number;
  countAnswers: number;
  countTribeLikes: {
    [tribeId: string]: number;
  };
  countComments: number;

  /** @deprecated */
  points: number;

  /** User Info */

  userBasicInfo?: UserBasicInfo;
  /** @deprecated Use userBasicInfo */
  userGender?: string;
  /** @deprecated Use userBasicInfo */
  userBirthYear?: number;
  /** @deprecated Use userBasicInfo */
  userAddress?: string;
  /** @deprecated Use userBasicInfo */
  userDistrict?: string;

  /** Agreement fields */
  privacyPolicy?: string;
  termsOfService?: string;

  /** System fields */
  /**
   * @deprecated Use UserLastSeenChatsSchema
   */
  lastSeenChats?: {
    [chatroomId: string]: number;
  };
  pinnedChatRooms?: string[];
  blockedUsers?: string[];
  messageTokens?: string[];

  /** Admin related fields */
  privileges: UserPrivilege[];
  adminUserIds: string[];
  description?: string;
  connectedCard?: string;
  photo?: string;
  userChosenName?: string;
  testResults?: TestResultsSchema;
  finishedTests?: string[];
  adminName?: string;
}

export interface CommentStatsSchema {
  createdAt: number;
  cardId: string;
  comments: number;
  commentCountsPerTribe: {
    [tribeId: string]: number;
  };
}

/**
 * ID: userId + chatroomId
 */
export interface UserLastSeenItemSchema {
  userId: string;
  chatroomId: string;
  lastSeenAt: number;
}

export interface TestResultsSchema {
  [testId: string]: TestResultSchema;
}

export interface TestResultSchema {
  topCategory: string;
  testAt: number;
}

export enum UserPrivilege {
  ADMIN = "admin",
  SUPER_USER = "super_user",
  CONTENTS_CONTRIBUTOR = "contents_contributor",
  REAL_NAME_USER = "real_name_user"
}

export interface UserNotificationsSchema {
  [id: string]: UserNotificationItemSchema;
}

export interface UserNotificationItemSchema {
  id?: string;
  type: NotificationType;
  userId: string;
  likeUserNotification?: LikeUserNotification;
  postLikeUserNotification?: PostLikeUserNotification;
  postAnswerNotification?: PostAnswerNotification;
  newQuestionNotification?: NewQuestionNotification;
  postCommentUserNotification?: PostCommentUserNotification;
  seen: boolean;
  notified: boolean;
  createdAt: number;
}

export interface NewQuestionNotification {
  questionId: string;
  title: string;
}

export interface CardViewCountersSchema {
  id: string;
  count: number;
}

export interface LikeUserNotification {
  commentId: string;
  questionId: string;
  likes: number;
  likeUserId: string;
}

export interface PostLikeUserNotification {
  postId: string;
  likes: number;
  likeUserId: string;
}

export interface PostAnswerNotification {
  postId: string;
  answers: number;
}

export interface PostCommentUserNotification {
  postId: string;
  commentUserId: string;
}

export enum NotificationType {
  COMMENT_LIKE = "like",
  POST_LIKE = "post_like",
  POST_ANSWER = "post_answer",
  NEW_QUESTION = "question",
  POST_COMMENT = "post_comment"
}

export interface CommentSchema {
  id: string;
  userId: string;
  questionId: string;
  comment: string;
  createdAt: number;
  /** @deprecated Use likeUserAndTribes */
  likeUserMap: LikeUserMap;
  userTribeId?: string;
  answer?: string;
  bad?: boolean;
  likeUserAndTribes: LikeUserAndTribesSchema;
  recencyLikeScore?: number;
  expression?: TribeExpression;
  isSpecialUser: boolean;
}

export interface LikeUserAndTribesSchema {
  [userId: string]: string; // tribeId
}

export interface LikeUserAndMetadataSchema {
  [userId: string]: UserProfileMetadataSchema;
}

export interface LikeUserMap {
  [userId: string]: UserCoordinateAndTribeSchema | false;
}

export interface UserCoordinateAndTribeSchema {
  x: number;
  y: number;
  tribeId: string;
  count?: number;
}

export interface MapCoordinatesSchema {
  [coordinateId: string]: UserCoordinateAndTribeSchema;
}

export interface QuestionAnswerMapSchema {
  [coordinateId: string]: QuestionAnswerMapItemSchema;
}

export interface QuestionAnswerMapItemSchema {
  x: number;
  y: number;
  countO: number;
  countX: number;
  countDunno: number;
}

export interface QuestionSupporters {
  [questionId: string]: QuestionSupportersItem;
}

export interface QuestionSupportersItem {
  o: QuestionSupportersCoordinate;
  x: QuestionSupportersCoordinate;
  dunno: QuestionSupportersCoordinate;
}

export interface QuestionSupportersCoordinate {
  x: number;
  y: number;
  count: number;
}

export interface LikeSchema {
  likeUserId: string;
  likeUserTribeId: string;
  likeUserX?: number;
  likeUserY?: number;
  referenceId: string;
  referenceType: ReferenceType;
  referenceUserId: string;
  referenceUserTribeId: string;
  referenceUserX?: number;
  referenceUserY?: number;
  createdAt: number;
  isSpecialUserLike?: boolean;
}

export enum ReferenceType {
  CARD,
  COMMENT,
  CHAT
}

/**
 * ID: userId + cardId
 */
export interface CardAnswerSchema {
  id?: string;
  cardId: string;
  userId: string;
  answer: string;
  createdAt: number;
  birthYear?: number;
  gender?: string;
  jurisdictionId?: string;
  jurisdictionAddress?: string;
  jurisdictionIds?: string[];
  prevAnswer?: string;
  tribeId?: string;
  lastAnswerPerTribe?: {
    [tribeId: string]: string;
  };
  lastMapCoordinatePerTribe?: {
    [tribeId: string]: number[];
  };
  stanceTestScore?: number;
  stanceTestUnderRetest?: boolean;
  updateCountsInBackend?: boolean;
  // V2 Coordinate
  coordinateV2?: {
    x: number;
    y: number;
  };
  tribeV2Id?: string;
  v2_repreated?: boolean;
  v2Answers?: {
    v2AnswerEligible: boolean;
    x: string;
    y: string;
  };
}

export interface PointTransactionSchema {
  fromUserId: string;
  toUserId: string;
  createdAt: number;
  pointFieldName: PointFieldName;
  points: number;
  note?: string;
}

export enum PointFieldName {
  countAnswers = "countAnswers",
  countComments = "countComments",
  countLikes = "countLikes",
  countPosts = "countPosts",
  admin = "admin"
}

/**
 * ID: currentUserId + cardId
 */
export interface CardViewsSchema {
  userId: string;
  cardId: string;
  createdAt: number;
}

export interface ChatSchema {
  id?: string;
  userId: string;
  tribeId: string;
  x: number;
  y: number;
  createdAt: number;
  message: string;
  chatRoomId: string;
  parentId: string;
  block?: boolean;
  profilePhotoUrl?: string;
  counter?: number;
}

export interface ChatRoomSchema {
  id?: string;
  type?: CardType;
  title: string;
  lastChatCreatedAt: number;
  photo?: string;
  authorTribeId?: string;
}

export interface HashWiki {
  hashtag: string;
  tribeId: string;
  latestVersionId: string;
}

export interface LikeCountsSchema {
  id?: string;
  referenceId: string;
  referenceType: ReferenceType;
  referenceCreatedAt: number;
  referenceParentId?: string;
  likeCounts: number;
  likeCountsPerTribe: {
    [tribeId: string]: number;
  };
}

export interface HashWikiVersion {
  id?: string;
  hashtag: string;
  tribeId: string;
  createdAt: number;
  editedBy: string;
  content: string;
}

export interface ContentReport {
  id?: string;
  reportingUserId: string;
  reportingUserTribe: string;
  reportedUserId: string;
  reportedUserTribe: string;
  referenceId: string;
  referenceType: ReferenceType;
  reportingReason: string;
  createdAt: number;
  resolution: ContentReportResolution;
  resolvedBy: string;
}

/**
 * @deprecated User UserDeviceTokenSchema
 */
export interface DeviceTokenSchema {
  isAdmin: boolean;
  userId: string;
  userName: string;
  deviceId: string;
  deviceToken: string;
  deviceType: string;
  lastRefreshAt: number;
  lastRefreshAtKorean: string;
}

/**
 * This is used in the place of cardId of UserNotificationSubscriptionsSchema
 * when the the subscription is a setting.
 */
export enum NotificationSubscriptionType {
  COMMENT = "SETTING_COMMENT",
  LIKE = "SETTING_LIKE",
  HASHTAG = "SETTING_HASHTAG",
  NEW_QUESTION = "SETTING_NEW_QUESTION",
  ANSWER = "SETTING_NEW_ANSWER",
  ALL_NOTIFICATIONS = "SETTING_ALL_NOTIFICATIONS"
}

/**
 * ID: userId + cardId
 */
export interface UserNotificationSubscriptionSchema {
  userId: string;
  cardId: string;
  updatedAt: number;
  isSubscribed: boolean;
}

/**
 * ID: userId + token.slice(0,5)
 */
export interface UserDeviceTokenSchema {
  userId: string;
  token: string;
  updatedAt: number;
  isActive: boolean;
}

export interface NotificationScheduleSchema {
  id?: string;
  title: string;
  body?: string;
  sendAt: number;
  sendComplete: boolean;
  condition?: string;
  recipientUserId?: string;
}

export enum ContentReportResolution {
  R1_REVIEW_REQUESTED = "R1_REVIEW_REQUESTED",
  R2_NO_ACTION = "R2_NO_ACTION",
  R3_BLINDED = "R3_BLINDED",
  R4_DELETED = "R4_DELETED"
}

export enum ContentReportReason {
  PROFANITY,
  ABUSE,
  IRRELEVANT,
  ETC
}

export interface AnswerCountsSchema {
  o: number;
  "?": number;
  x: number;
  count: number;
}

export interface QuestionAnswerStatsSchema {
  id?: string;
  o: number;
  "?": number;
  x: number;
  version?: number;
  count: number;
  birthYearGenderStats?: {
    [birthYear: number]: {
      [gender: string]: AnswerCountsSchema;
    };
  };
  tribeStats?: {
    [tribeId: string]: AnswerCountsSchema;
  };
}

export interface CardAnswerStatsSchema {
  id?: string;
  o: number;
  "?": number;
  x: number;
  count: number;
  version?: number;
}

export interface CardAnswerAgeGenderStatsSchema {
  id?: string;

  [birthYear: number]: {
    [gender: string]: AnswerCountsSchema;
  };
}

export interface CardAnswerTribeStatsSchema {
  [tribeId: string]: AnswerCountsSchema;
}

export interface CardAnswerTribeStatsV2Schema {
  id?: string;
  tribeStats: CardAnswerTribeStatsSchema;
  totalCount: number;
}

export interface CardAnswerStatsTimeSeriesSchema extends CardAnswerStatsSchema {
  createdAt: number;
  dateInKorea: string;
  cardId: string;
}

export interface CardAnswerAgeGenderStatsTimeSeriesSchema
  extends CardAnswerAgeGenderStatsSchema {
  createdAt: number;
  dateInKorea: string;
  cardId: string;
}

export interface CardAnswerTribeStatsTimeSeriesSchema {
  createdAt: number;
  dateInKorea: string;
  cardId: string;
  answerCounts: CardAnswerTribeStatsSchema;
}

/**
 * Key: controlling hashtag.
 * @deprecated
 */
export interface DataPlatformTeamSchema {
  id?: string;
  createdAt: number;
  configurationStatus: DataPlatformSettingStatus;
  subscriptionExpiresAt?: number;
  subscriptionType?: DataPlatformSubscriptionType;
  controllingHashtag?: string;
  politicianHashtags?: string[];
  issueHashtags?: string[];
  partyHashtags?: string[];
}

/**
 * @deprecated
 * Key: userId + teamId
 */
export interface DataPlatformAdminSchema {
  userId: string;
  teamId: string;
  adminType: DataPlatformAdminType;
}

/**
 * Schema for user tribe.
 */
export interface UserTribeSchema {
  id?: string;
  tribeId: string;
  userId: string;
  createdAt: number;
  x: number;
  y: number;
  snackbarDisplayed: boolean;
}

/**
 * @deprecated
 * Key: userId
 * TODO: Add firestore rules for added security
 */
export interface DataPlatformAdminProfileSchema {
  id?: string;
  email?: string;
  phone?: string;
}

/**
 * @deprecated
 */
export enum DataPlatformAdminType {
  ADMIN = "admin",
  ADMIN_APPLIED = "admin_applies",
  READ_ONLY = "read_only"
}

/**
 * @deprecated
 */
export interface DataPlatformSubscriptionTransactionSchema {
  createdAt: number;
  userId: string;
  subscriptionType: DataPlatformSubscriptionType;
  amountMicros?: number;
  paidAt?: number;
  paymentMethod?: string;
}

export enum DataPlatformSubscriptionType {
  FREE_USER = "free_user",
  FREE_TRIAL = "free_trial",
  MONTHLY = "monthly",
  ANNUAL = "annual"
}

export enum DataPlatformSubscriptionTier {
  BASIC = "basic",
  STANDARD = "standard",
  PREMIUM = "premium"
}

export enum DataPlatformSettingStatus {
  NOT_INITIATED = "not_initiated",
  IN_PROGRESS = "in_progress",
  SUBSCRIBED = "subscribed",
  SUSPENDED = "suspended",
  CANCELED = "canceled"
}

/**
 * TODO (will.ryu): Firebase Rule로 보안 설정
 */
export interface DataPlatformUserProfile {
  id?: string;
  email?: string;
  phone?: string;
  subscriptionType: DataPlatformSubscriptionType;
  status: DataPlatformSettingStatus;
  subscriptionTier: DataPlatformSubscriptionTier;
}

/**
 * id: userId + jurisdictionCardId
 */
export interface UserJurisdictionSchema {
  userId: string;
  jurisdictionId: string;
  userJurisdictionType: UserJurisdictionType;
  createdAt: number;
}

export enum UserJurisdictionType {
  MAIN_JURISDICTION,
  INTEREST_JURISDICTION
}

export interface UserVerificationSchema {
  id?: string;
  createdAt?: number;
  updatedAt: number;
  userId: string;
  jurisdictionId?: string;
  phoneId?: string;
  userBioInfo?: UserBioInfo;
  userAgreements?: UserAgreements;
}

export interface UserBioInfo {
  gender?: string;
  birthYear?: string;
}

export interface UserAgreements {
  privacyPolicy: string; // ex> "1.0.1"
  termsOfService: string; // ex> "1.0.1"
  aboveLegalAge: string;
  marketingSubscription: boolean;
}

/**
 * ID: userId + cardId
 */
export interface CardAnswerJurisdictionStatsSchema {
  id: string;
  jurisdictionStats: CardAnswerJurisdictionStatItemSchema;
}

export interface CardAnswerJurisdictionStatItemSchema {
  answerCounts: AnswerCountSchema;
  next: {
    [jurisdiction: string]: CardAnswerJurisdictionStatItemSchema;
  };
}

export interface AnswerCountSchema {
  o: number;
  "?": number;
  x: number;
  count: number;
}

export interface PoliticianAssetBalanceSchema {
  id?: string;
  politicianId: string;
  amountMicros: number;
  createdAt: number;
  dateInKorea: string;
}

/** OXO_QUIZ */
export interface OxoQuizSchema {
  id: string;
  createdAt: number;
  title: string;
  dateInKorea: string;
  subTitle?: string;

  showOnQuiz: boolean;
  showOnQuizStartAt?: number;

  showOnMainContent: boolean;
  showOnMainStartAt?: number;
  showOnMainEndAt?: number;

  reviewTitle?: string;
  reviewText?: string;

  reviewLinkTitle?: string;
  reviewLinkUrl?: string;

  finishedUserCount: number;
}

export interface OxoQuizUserResultSchema {
  id: string;
  quizId: string;
  userId: string;
  userTribeId?: string;
  userGender?: string;
  userBirthYear?: number;
  createdAt: number;
  userTotalScore: number;
  userTotalRewardCoin: number;
}

export interface oxoQuizStatSchema {
  quizId: string;
  userTribeId?: string;
  userTotalScore: number;
  userGender?: string;
  userBirthYear?: number;
}

export interface AgeGroupSchema {
  [age: string]: number;
}

export interface GenderDataSchema {
  [gender: string]: number;
}

export interface TribeObjectSchema {
  [tribeId: string]: number;
}

export enum TransactionType {
  TEST = "TEST",
  REPEATING_ACTIVITY = "REPEATING_ACTIVITY",
  BASIC_ACTIVITY = "BASIC_ACTIVITY",
  SELL_ASSET = "SELL_ASSET",
  BUY_HASHTAG_COIN = "BUY_HASHTAG_COIN",
  GIVE_HASHTAG_COIN = "GIVE_HASHTAG_COIN",
  SELL_HASHTAG_COIN = "SELL_HASHTAG_COIN",
  GIVE_OXO_COIN = "GIVE_OXO_COIN"
}

export enum TransactionSubType {
  REPEATING_ACTIVITY_OX_ANSWER = "OX_ANSWER",
  REPEATING_ACTIVITY_OXO_QUIZ = "OXO_QUIZ",
  REPEATING_ACTIVITY_OXO_ETC = "OXO_ETC",

  SELL_ASSET = "SELL_ASSET",
  BUY_ASSET = "BUY_ASSET",

  BUY_HASHTAG_COIN = "BUY_HASHTAG_COIN",
  GIVE_HASHTAG_COIN = "GIVE_HASHTAG_COIN",
  SELL_HASHTAG_COIN = "SELL_HASHTAG_COIN",
  GIVE_OXO_COIN = "GIVE_OXO_COIN",

  BASIC_ACTIVITY_TRIBE_TEST = "TRIBE_TEST",
  BASIC_ACTIVITY_STANCE_TEST = "STANCE_TEST",
  BASIC_ACTIVITY_INPUT_AGE = "INPUT_AGE",
  BASIC_ACTIVITY_INPUT_GENDER = "INPUT_GENDER",
  BASIC_ACTIVITY_BONUS = "BONUS",
  BASIC_ACTIVITY_APP_LOGIN = "APP_LOGIN",
  BASIC_ACTIVITY_MEMBER_JOIN = "MEMBER_JOIN",

  USER_VERIFICATION = "USER_VERIFICATION",

  OXO_QUIZ = "OXO_QUIZ"
}

export interface OxoQuizResultStatSchema {
  quizId: string;
  userTotalScore: number;
  tribeCount: TribeObjectSchema;
  tribeTotalScore: TribeObjectSchema;
  genderCount?: GenderDataSchema;
  genderTotalScore?: GenderDataSchema;
  ageGroupCount?: AgeGroupSchema;
  ageGroupTotalScore?: AgeGroupSchema;
}

export interface oxoQuizQuestionStatSchema {
  questionId: string;
  userAnswer: string;
  userTribeId?: string;
  userGender?: string;
  userBirthYear?: number;
}

export enum QuestionType {
  QuestionTypeTextPick = "questionTypeTextPick",
  QuestionTypePhotoPick = "questionTypePhotoPick",
  QuestionTypeOxPick = "questionTypeOxPick",
  QuestionTypeHashtagWordPick = "questionTypeHashtagWordPick",
  QuestionTypeTextWrite = "questionTypeTextWrite",
  QuestionTypeCardWordPick = "questionTypeCardWordPick",
  QuestionTypeImageDescriptionPick = "questionTypeImageDescriptionPick"
}

export interface OxoQuizQuestionSchema {
  id: string;
  quizId: string;
  createdAt: number;
  questionType: QuestionType;
  order: number;
  title: string;
  subTitle?: string;

  option1?: string;
  option1Description?: string;
  option2?: string;
  option2Description?: string;
  option3?: string;
  option3Description?: string;
  optionPhotos?: string[];
  photoProvider?: string;

  descriptionHashtag?: string;
  descriptionCardId?: string;
  isOptionImageVertical?: boolean;

  answer: string;
  answerDescription?: string;

  rewardCoin: number;
  score: number;
}

export interface OxoQuizQuestionUserAnswerSchema {
  id: string;
  oxoQuizQuestionId: string;
  userId: string;
  createdAt: number;
  userAnswer: string;
  userTribeId?: string;
  userGender?: string;
  userBirthyear?: string;
}

export interface UserDistrictSchema {
  createdAt: number;
  jurisdictionId: string;
  userId: string;
  userJurisdictionType: number;
}

/**
 * ID: cardId
 */
export interface CardAnswerJurisdictionRegionalStatsSchema {
  id: string;
  regionalStats: {
    [regionId: string]: AnswerCountSchema;
  };
  updatedAt: number;
}

export interface CardGptSchema {
  id: string;
  updatedAt: number;
  cardTitle?: string;
  description?: string;
  ageStats?: string;
  ageComments?: Record<string, string>;
  genderComments?: Record<string, string>;

  regionStats?: string;
  jurisdictionComments?: Record<string, string>;

  tribeStats?: string;
  tribeComments?: Record<string, string>;
}

export interface CardGptInputSchema {
  id: string;
  updatedAt: number;
  description?: string;
  ageStats?: string;
  ageComments?: Record<string, string>;
  genderComments?: Record<string, string>;

  regionStats?: string;
  jurisdictionComments?: Record<string, string>;

  tribeStats?: string;
  tribeComments?: Record<string, string>;
}

export enum FirestoreCollection {
  CARDS = "cards",
  USER_PROFILE_METADATA = "user_profile_metadata",
  STOCK_TEST = "stock_test",

  CONFIG_DATA = "config_data",
  /** @deprecated use user_device_tokens */
  DEVICE_TOKEN = "device_token",
  USER_DEVICE_TOKENS = "user_device_tokens",
  USER_NOTIFICATION_SUBSCRIPTIONS = "user_notification_subscriptions",
  NOTIFICATION_SCHEDULE = "notification_schedule",

  ASSET_TRANSACTIONS = "asset_transactions",
  ASSET_BALANCES = "asset_balances",
  ASSET_VALUES = "asset_values",
  ASSET_VALUES_HISTORY = "asset_values_history",

  /**
   * @deprecated User CARD_ANSWERS
   *
   * TODO (will.ryu): calculateTribe still depends on this.
   */
  USER_VECTOR = "user_vector",

  CARD_ANSWERS = "card_answers",

  BEST_COMMENTS_FOR_CARD = "best_comments_for_card",

  COMMENTS = "comments",
  COMMENT_STATS = "comment_stats",

  LIKES = "likes",
  LIKE_COUNTS = "like_counts",

  NOTIFICATIONS = "notifications",

  CARD_VIEWS = "card_views",
  CARD_VIEW_COUNTERS = "card_view_counters",

  MAP_COORDINATES_SHARDED = "map_coordinates_sharded",
  QUESTION_ANSWER_MAP = "question_answer_map",
  /** @deprecated Use CARD_ANSWER_STATS */
  QUESTION_ANSWER_STATS = "question_answer_stats",
  CARD_ANSWER_TRIBE_STATS_V2 = "card_answer_tribe_stats_v2",

  CARD_ANSWER_STATS = "card_answer_stats",
  CARD_ANSWER_AGE_GENDER_STATS = "card_answer_age_gender_stats",
  CARD_ANSWER_TRIBE_STATS = "card_answer_tribe_stats",
  CARD_ANSWER_JURISDICTION_STATS = "card_answer_jurisdiction_stats",
  CARD_ANSWER_JURISDICTION_REGIONAL_STATS = "card_answer_jurisdiction_regional_stats",

  CARD_ANSWER_STATS_TIME_SERIES = "card_answer_stats_time_series",
  CARD_ANSWER_AGE_GENDER_STATS_TIME_SERIES = "card_answer_age_gender_stats_time_series",
  CARD_ANSWER_TRIBE_STATS_TIME_SERIES = "card_answer_tribe_stats_time_series",

  CHATS = "chats",
  CHATROOMS = "chatRooms",
  LIVE_CHATROOMS = "live_chatrooms",

  /** 신고 버튼 */
  CONTENT_REPORTS = "content_reports",

  HASH_WIKIS = "hash_wikis",
  HASH_WIKI_VERSIONS = "hash_wiki_versions",

  ERROR_LOG = "error",

  REPORT_TEXT = "report_text",

  USER_LAST_SEEN_ITEMS = "user_last_seen_chats",

  /** Date Platform 관련 테이블 */
  /** @deprecated */
  DATA_PLATFORM_TEAMS = "data_platform_teams",
  /** @deprecated */
  DATA_PLATFORM_ADMINS = "data_platform_admins",
  /** @deprecated */
  DATA_PLATFORM_ADMIN_PROFILES = "data_platform_admin_profiles",
  /** @deprecated */
  DATA_PLATFORM_SUBSCRIPTION_TRANSACTIONS = "data_platform_subscription_transactions",

  DATA_PLATFORM_USER_PROFILES = "data_platform_user_profiles",

  USER_TRIBES = "user_tribes",
  USER_TRIBES_HISTORY = "user_tribes_history",
  USER_TRIBES_HISTORY_PER_DAY = "user_tribes_history_per_day",
  USER_TRIBE_STATS = "user_tribe_stats",
  TRIBE_MAP_V2 = "tribe_map_v2",

  USER_JURISDICTION = "user_jurisdiction",
  USER_VERIFICATION = "user_verification",

  POLITICIAN_ASSET_BALANCE_DAILY_TIME_SERIES = "politician_asset_balance_daily_time_series",

  /** OXO_QUIZ */
  OXO_QUIZZES = "oxo_quizzes",
  OXO_QUIZ_USER_RESULTS = "oxo_quiz_user_result",
  OXO_QUIZ_QUESTIONS = "oxo_quiz_questions",
  OXO_QUIZ_QUESTION_USER_ANSWER = "oxo_quiz_question_user_answer",

  OXO_QUIZ_STAT = "oxo_quiz_stat",

  USER_DISTRICT = "user_district",

  CARD_GPTS = "card_gpts",
  CARD_GPT_INPUTS = "card_gpt_inputs"
}
