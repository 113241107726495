/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import TribeBarChart from "./TribeBarChart";

export default function CardAnswerTribeBarChart({
  cardId,
  isAgree = true
}: {
  cardId: string;
  isAgree?: boolean;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerTribeStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats) {
    return null;
  }

  return (
    <TribeBarChart cardAnswerTribeStats={cardAnswerStats} isAgree={isAgree} />
  );
}
