/** @jsxImportSource @emotion/react */
import { Button } from "@material-ui/core";
import firebase from "firebase/compat";

import React, { ReactNode } from "react";
import { UserAnswer } from "../../../constants/enums";
import MiniO from "./icon/MiniO";
import MiniDunno from "./icon/MiniDunno";
import MiniX from "./icon/MiniX";
import {
  ANSWER_DUNNO_STROKE,
  ANSWER_O_STROKE,
  ANSWER_X_STROKE,
  UNSELECTED_COLOR_LIGHT
} from "../../../constants/colors";

function getOxoButton(onVote: (answer: string) => void, userAnswer?: string) {
  return (
    buttonAnswer: string,
    activeButton: ReactNode,
    inactiveButton: ReactNode
  ) => {
    return (
      <Button
        onClick={() => {
          onVote(buttonAnswer);
        }}
        style={{
          minWidth: 22,
          width: 22,
          cursor: "pointer",
          margin: 0,
          padding: 5,
          borderRadius: "50%",
          justifyContent: "center",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {userAnswer === buttonAnswer ? activeButton : inactiveButton}
          </div>
        </div>
      </Button>
    );
  };
}

export function MiniOxButtons({
  answer,
  onVote
}: {
  answer?: string;
  onVote: (answer: string) => void;
}) {
  const oxoButton = getOxoButton(onVote, answer);

  return (
    <div
      css={{
        width: 90,
        display: "flex",
        height: 20,
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "30px",
        backgroundColor: "white"
      }}
      onClick={() => {
        firebase.analytics().logEvent("v4_click_mini_ox_button", {
          event_category: "hashcard"
        });
      }}
    >
      {oxoButton(
        UserAnswer.O,
        <MiniO fill={ANSWER_O_STROKE} />,
        <MiniO fill={UNSELECTED_COLOR_LIGHT} />
      )}
      {oxoButton(
        UserAnswer.DUNNO,
        <MiniDunno fill={ANSWER_DUNNO_STROKE} />,
        <MiniDunno fill={UNSELECTED_COLOR_LIGHT} />
      )}
      {oxoButton(
        UserAnswer.X,
        <MiniX fill={ANSWER_X_STROKE} />,
        <MiniX fill={UNSELECTED_COLOR_LIGHT} />
      )}
    </div>
  );
}
