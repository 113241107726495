/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { getDocsFromSnapshot } from "../../../utils/utils";

export function HashtagPeriod({ hashtag }: { hashtag: string }) {
  const [firstCard, setFirstCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("hashtags", "array-contains", "#" + hashtag)
      .orderBy("createdAt", "asc")
      .limit(1)
      .get()
      .then(getDocsFromSnapshot)
      .then((cards: CardSchema[]) => {
        setFirstCard(cards[0]);
      });
  }, [hashtag]);

  const [lastCard, setLastCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("hashtags", "array-contains", "#" + hashtag)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then(getDocsFromSnapshot)
      .then((cards: CardSchema[]) => {
        setLastCard(cards[0]);
      });
  }, [hashtag]);

  if (!firstCard || !lastCard) {
    return null;
  }

  return (
    <div>
      {new Date(firstCard?.createdAt).toLocaleDateString()} ~{" "}
      {new Date(lastCard?.createdAt).toLocaleDateString()}
    </div>
  );
}
