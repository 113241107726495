import React, { CSSProperties } from "react";
import { GRAY_BACKGROUND } from "../../../constants/enums";
import alltribes_for_loading from "../../../img/tribes2/alltribes_for_loading.gif";

export default function CharacterLoader({ style }: { style?: CSSProperties }) {
  return (
    <div
      className="timeline_center_container"
      style={{
        minHeight: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: GRAY_BACKGROUND,
        ...style
      }}
    >
      <div>
        <img
          src={alltribes_for_loading}
          style={{
            width: 94,
            height: 94
          }}
          alt="alltribes_for_loading"
        />
      </div>

      {/*<div*/}
      {/*  style={{ width: "300px", paddingBottom: "50px", paddingTop: "10px" }}*/}
      {/*>*/}
      {/*  <BarLoader width={300} color={GRAY} />*/}
      {/*</div>*/}
    </div>
  );
}
