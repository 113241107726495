/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { removeIdKey } from "../../../../utils/utils";
import {
  BestCommentsForCardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import SingleLineCommentForPortrait from "./SingleLineCommentForPortrait";

export default function MiniBestCommentsForDailyReport({
  cardId,
  maxWidth = 335,
  margin = 78,
  disableExpressions
}: {
  cardId: string;
  maxWidth?: number;
  margin?: number;
  disableExpressions?: boolean;
}) {
  const [bestComments] = useFirestoreLiveDocument<BestCommentsForCardSchema>(
    FirestoreCollection.BEST_COMMENTS_FOR_CARD,
    cardId
  );
  removeIdKey(bestComments);

  return (
    <div
      css={css`
        width: 100%;
        margin-top: 15px;
      `}
    >
      {bestComments &&
        Object.keys(bestComments).map(
          tribeId =>
            bestComments && (
              <div
                css={css`
                  margin-top: 10px;
                `}
                key={cardId + "bc" + tribeId}
              >
                <SingleLineCommentForPortrait
                  commentId={bestComments[tribeId]}
                  key={tribeId}
                  disableExpressions={disableExpressions}
                  truncate={150}
                />
              </div>
            )
        )}
    </div>
  );
}
