/** @jsxImportSource @emotion/react */

import React from "react";
import PartyPersonSelector from "../DailyReportsView/PartyPersonSelector";

export function PopularityRateSelector({
  selectedDate,
  selectedHashtag,
  setSelectedHashtag,
  setSelectedCard
}: {
  selectedDate: number;
  selectedHashtag: string;
  setSelectedHashtag: (hashtag: string) => void;
  setSelectedCard: (cardId: string) => void;
}) {
  return (
    <div>
      <PartyPersonSelector
        hashtag={selectedHashtag}
        selectedDate={selectedDate}
        setSelectedHashtag={setSelectedHashtag}
      />
      <div>
        <div>투표 지지율</div>
        <div>지지도 조사를 통한 결과입니다.</div>
      </div>
      <div>
        <div>발언별 지지율</div>
        <div>각 발언당 지지율을 합산한 결과입니다.</div>
      </div>
    </div>
  );
}
