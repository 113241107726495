import React from "react";

export default function FacebookButton({
  color,
  width = 27,
  height = 26
}: {
  color: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4855 0.00210025C5.93937 -0.129104 0.37735 5.9063 0.22285 12.6961C0.0602179 20.265 6.27276 26.1528 13.2253 25.997C20.1453 26.1364 26.1871 20.4208 26.2196 13.1061C26.2521 5.8079 20.4787 0.125104 13.4855 0.00210025ZM16.4373 12.4009C16.4047 12.6797 16.3397 12.9667 16.3234 13.2455C16.3072 13.467 16.2177 13.549 16.0063 13.5408C15.5509 13.5326 15.0956 13.5408 14.6483 13.5408C14.1442 13.5408 14.1442 13.5408 14.1442 14.0328C14.1442 15.7958 14.1442 17.5507 14.1442 19.3138C14.1442 19.4532 14.1442 19.5844 14.1442 19.7894C13.9409 19.7894 13.7701 19.7894 13.5912 19.7894C12.9895 19.7894 12.3877 19.773 11.786 19.7976C11.5095 19.8058 11.4201 19.7238 11.4201 19.4368C11.4282 17.6081 11.4201 15.7794 11.4363 13.9508C11.4363 13.6392 11.3631 13.5244 11.0379 13.5408C10.5825 13.5654 10.1271 13.5408 9.67991 13.549C9.43596 13.5572 9.32211 13.4752 9.33025 13.2209C9.33838 12.5485 9.33838 11.8679 9.33025 11.1955C9.33025 10.9659 9.40343 10.8675 9.64738 10.8757C10.1434 10.8921 10.6476 10.8757 11.1436 10.8839C11.3713 10.8921 11.4445 10.8019 11.4363 10.5805C11.4282 9.97364 11.4282 9.36682 11.4363 8.76C11.4363 7.16914 12.2007 6.21791 13.7376 5.8735C14.5751 5.68489 15.4208 5.70129 16.2665 5.8161C16.4942 5.8489 16.6162 5.9391 16.608 6.20971C16.5836 6.84933 16.5999 7.48895 16.5999 8.16958C16.4617 8.17778 16.3478 8.18598 16.2258 8.19418C15.7949 8.21878 15.3639 8.22698 14.9411 8.25978C14.445 8.30898 14.1523 8.64519 14.1523 9.15361C14.1523 9.62102 14.1604 10.0884 14.1523 10.5559C14.1442 10.7937 14.2336 10.8757 14.4694 10.8757C15.0468 10.8593 15.6241 10.8757 16.2015 10.8757C16.3234 10.8757 16.4454 10.8757 16.6243 10.8757C16.5511 11.4169 16.5023 11.9089 16.4373 12.4009Z"
        fill={color}
      />
    </svg>
  );
}
