/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  CardAnswerStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { mapValues, parseDoc, sumTwoAnswerCounts } from "../../../utils/utils";
import { css } from "@emotion/react";
import HashCommentBubble from "../HashCardView/common/HashCommentBubble";

export function AgeGenderIssues({ cards }: { cards: CardSchema[] }) {
  const [ageGenderStats, setAgeGenderStats] =
    useState<CardAnswerAgeGenderStatsSchema[]>();
  useEffect(() => {
    const promises = cards.map(card =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS)
        .doc(card.id)
        .get()
        .then(parseDoc)
    );
    Promise.all(promises).then(
      (cardAnswerAgeGenderStats: CardAnswerAgeGenderStatsSchema[]) => {
        setAgeGenderStats(cardAnswerAgeGenderStats);
      }
    );
  }, [cards]);

  const [cardStats, setCardStats] = useState<number[]>();
  useEffect(() => {
    const promises = cards.map(card =>
      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(card.id)
        .get()
        .then(parseDoc)
    );
    Promise.all(promises).then(
      (cardAnswerAgeGenderStats: CardAnswerStatsSchema[]) => {
        setCardStats(
          cardAnswerAgeGenderStats.map(
            cardAnswerAgeGenderStat =>
              cardAnswerAgeGenderStat.o / (cardAnswerAgeGenderStat.count || 1)
          )
        );
      }
    );
  }, [cards]);

  const statsByAgeGroups = useMemo(() => {
    if (!ageGenderStats) {
      return;
    }
    return ageGenderStats.map(cardAnswerAgeGenderState => {
      return Object.keys(cardAnswerAgeGenderState).reduce<{
        [ageGroup: string]: {
          [gender: string]: {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        };
      }>((prev, birthYear) => {
        const ageGroupNumber =
          Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
        if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
          return prev;
        }

        const ageGroup = ageGroupNumber.toFixed();

        const birthYearGenderStat =
          cardAnswerAgeGenderState?.[Number(birthYear)];
        if (birthYearGenderStat) {
          if (prev[ageGroup]) {
            prev[ageGroup]["male"] = sumTwoAnswerCounts(
              (prev[ageGroup]["male"] || {
                o: 0,
                x: 0,
                "?": 0,
                count: 0
              }) as AnswerCountsSchema,
              (birthYearGenderStat["male"] || {
                o: 0,
                x: 0,
                "?": 0,
                count: 0
              }) as AnswerCountsSchema
            );

            prev[ageGroup]["female"] = sumTwoAnswerCounts(
              prev[ageGroup]["female"] || {
                o: 0,
                x: 0,
                "?": 0,
                count: 0
              },
              birthYearGenderStat["female"] || {
                o: 0,
                x: 0,
                "?": 0,
                count: 0
              }
            ) as {
              o: number;
              "?": number;
              x: number;
              count: number;
            };
          } else {
            prev[ageGroup] = birthYearGenderStat;
          }
        }
        return prev;
      }, {});
    });
  }, [ageGenderStats]);

  const ageGenderRatios = useMemo(() => {
    if (!statsByAgeGroups || !cardStats) {
      return;
    }
    return statsByAgeGroups.map((statsByAgeGroup, i) =>
      mapValues(statsByAgeGroup, genderGroups =>
        mapValues(genderGroups, genderGroup => genderGroup.count)
      )
    );
  }, [statsByAgeGroups, cardStats]);

  console.log(ageGenderRatios);
  const maxDiffAgeGenderRatios = useMemo(() => {
    if (!ageGenderRatios) {
      return undefined;
    }

    const maxDiffAgeGenderRatios: {
      [age: string]: { [gender: string]: number };
    } = {};
    ageGenderRatios.forEach((ageGenderRatio, i) => {
      Object.keys(ageGenderRatio).forEach(ageGroup => {
        Object.keys(ageGenderRatio[ageGroup]).forEach(gender => {
          if (
            ageGenderRatio[ageGroup][gender] >
            (maxDiffAgeGenderRatios[ageGroup]?.[gender] || 0)
          ) {
            if (!maxDiffAgeGenderRatios[ageGroup]) {
              maxDiffAgeGenderRatios[ageGroup] = {};
            }
            maxDiffAgeGenderRatios[ageGroup][gender] = i;
          }
        });
      });
    });
    return maxDiffAgeGenderRatios;
  }, [ageGenderRatios]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      {maxDiffAgeGenderRatios &&
        Object.keys(maxDiffAgeGenderRatios).map(ageGroup =>
          Object.keys(maxDiffAgeGenderRatios[ageGroup]).map(gender => (
            <div
              key={ageGroup}
              css={css`
                display: flex;
              `}
            >
              <div>{ageGroup}대</div>
              <div
                key={gender}
                css={css`
                  margin-left: 5px;
                `}
              >
                {gender === "male" ? "남" : "여"}
              </div>
              <div
                css={css`
                  margin-left: 10px;
                `}
              >
                <HashCommentBubble
                  cardId={cards[maxDiffAgeGenderRatios[ageGroup][gender]].id}
                />
              </div>
            </div>
          ))
        )}
    </div>
  );
}
