/** @jsxImportSource @emotion/react */

import React from "react";

import { ApexOptions } from "apexcharts";

import ReactApexChart from "react-apexcharts";
import {
  ACCENT_COLOR,
  ANSWER_DUNNO_STROKE,
  ANSWER_O_STROKE,
  ANSWER_X_STROKE
} from "../../../constants/colors";

import { AnswerCountSchema } from "../../../constants/firestore_schema";
import { apexChartBasicOptions } from "./fixtures";
import { isArray } from "lodash";

export default function TribeColumnBarChartContainer({
  stats,
  colorScale = [ANSWER_O_STROKE, ANSWER_DUNNO_STROKE, ANSWER_X_STROKE],
  barHeight = 330
}: {
  stats: AnswerCountSchema;
  colorScale?: string[];
  barHeight?: number;
}) {
  if (!stats) {
    return null;
  }

  const data: ApexAxisChartSeries = [
    {
      name: "찬성",
      data: [
        {
          x: 0,
          y: stats.o
        }
      ]
    },
    {
      name: "중립",
      data: [
        {
          x: 0,
          y: stats["?"]
        }
      ]
    },
    {
      name: "반대",
      data: [
        {
          x: 0,
          y: stats.x
        }
      ]
    }
  ];

  const options: ApexOptions = {
    ...apexChartBasicOptions,
    series: data,
    states: {
      active: {
        filter: {
          type: "none"
        }
      }
    },
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false
      }
    },
    colors: colorScale,
    plotOptions: {
      bar: {
        barHeight: "60"
      }
    },
    dataLabels: {
      enabled: true,
      distributed: true,
      offsetY: 3,
      style: {
        fontSize: "18px",
        fontWeight: "400",
        colors: [ACCENT_COLOR],
        fontFamily: "SUIT"
      },
      formatter: val => {
        if (isArray(val) || typeof val === "string" || val < 7) {
          return "";
        }

        return val.toFixed(1);
      },
      enabledOnSeries: [0, 1, 2]
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false
      },
      marker: {
        show: false
      },
      fillSeriesColor: true,
      shared: false,
      x: {
        show: false
      },
      y: {
        formatter: val => {
          return Math.abs(val) + "명";
        }
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: 100,
      reversed: true
    }
  };

  return (
    <ReactApexChart
      options={options}
      series={data}
      type="bar"
      height={barHeight}
      width={115}
    />
  );
}
