import React from "react";

export default function AnswerO({
  color,
  width
}: {
  color: string;
  width: number;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 51.8182C42.0498 51.8182 51.8182 42.0498 51.8182 30C51.8182 17.9502 42.0498 8.18182 30 8.18182C17.9502 8.18182 8.18182 17.9502 8.18182 30C8.18182 42.0498 17.9502 51.8182 30 51.8182ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill={color}
      />
    </svg>
  );
}
