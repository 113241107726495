/** @jsxImportSource @emotion/react */

import React from "react";
import { VictoryBar, VictoryGroup, VictoryStack } from "victory";
import { CardAnswerStatsSchema } from "../../../../constants/firestore_schema";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";

export default function MiniQuestionStackChartVertical({
  cardChartStats,
  colorScale = [
    DATA_PLATFORM_GREEN_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_RED_GRAY
  ]
}: {
  cardChartStats: CardAnswerStatsSchema;
  colorScale?: string[];
  size?: number;
}) {
  if (!cardChartStats) {
    return null; // cannot be the case.
  }

  const stats = [
    {
      x: 0,
      y: cardChartStats.o || 0
    },
    {
      x: 0,
      y: cardChartStats["?"] || 0
    },
    {
      x: 0,
      y: cardChartStats.x || 0
    }
  ];

  return (
    <div
      css={css`
        width: 130px;
        padding-left: 12px;
        margin-bottom: 4px;
      `}
    >
      <VictoryGroup horizontal height={18} width={130} padding={0}>
        <VictoryStack colorScale={colorScale}>
          <VictoryBar data={[stats[0]]} alignment="start" />
          <VictoryBar data={[stats[1]]} alignment="start" />
          <VictoryBar data={[stats[2]]} alignment="start" />
        </VictoryStack>
      </VictoryGroup>
    </div>
  );
}
