import React, { useContext, useEffect, useState } from "react";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { getTribeExpressionImage } from "../../../utils/utils";
import { DataPropsContext } from "../Contexts/DataPropsContext";

export default function MiniOxExpression({
  userCurrentAnswer,
  userNewAnswer
}: {
  userCurrentAnswer: string;
  userNewAnswer: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const [visibility, setElementVisibility] = useState(false);

  const changeEffect = () => {
    setElementVisibility(true);

    setTimeout(() => {
      setElementVisibility(false);
    }, 1000);
  };

  useEffect(() => {
    if (userNewAnswer !== userCurrentAnswer) {
      changeEffect();
    }
  }, [userNewAnswer]);

  return (
    <div
      style={{
        position: "relative",
        top: -120,
        left: 7,
        width: 80,
        display: visibility ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {userNewAnswer && currentUserMetadata && currentUserMetadata.tribeId && (
        <img
          className={visibility ? "showOxExpression" : "hideOxExpression"}
          src={getTribeExpressionImage(
            TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].image,
            userNewAnswer
          )}
          style={{ width: 80, height: 80 }}
          alt="tribe_expression"
        />
      )}
    </div>
  );
}
