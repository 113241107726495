import React from "react";
import { VictoryBar, VictoryGroup, VictoryLabel, VictoryStack } from "victory";
import { oxoColors } from "../../../../constants/enums";
import { CardAnswerStatsSchema } from "../../../../constants/firestore_schema";

export default function OXOBarChart({
  cardAnswerStats,
  colorScale = [oxoColors.O, oxoColors.DUNNO, oxoColors.X]
}: {
  cardAnswerStats: CardAnswerStatsSchema;
  colorScale?: string[];
}) {
  if (!cardAnswerStats) {
    return null; // cannot be the case.
  }

  const stats = [
    {
      x: 0,
      y: cardAnswerStats.o || 0
    },
    {
      x: 0,
      y: cardAnswerStats["?"] || 0
    },
    {
      x: 0,
      y: cardAnswerStats.x || 0
    }
  ];

  const sum = cardAnswerStats.count;

  const barStyle = { labels: { fill: "white", fontSize: 20 } };
  const generateLabel = (datum: { y: number }) => {
    if ((datum.y / sum) * 100 > 7) {
      return ((datum.y / sum) * 100).toFixed(0) + "%";
    } else {
      return null;
    }
  };
  return (
    <div>
      <VictoryGroup height={120} width={750} padding={0}>
        <VictoryStack horizontal colorScale={colorScale}>
          <VictoryBar
            data={[stats[0]]}
            labels={({ datum }) => generateLabel(datum)}
            style={barStyle}
            labelComponent={<VictoryLabel dy={-12} dx={-34} />}
          />
          <VictoryBar
            data={[stats[1]]}
            labels={({ datum }) => generateLabel(datum)}
            style={barStyle}
            labelComponent={<VictoryLabel dy={-12} dx={-34} />}
          />
          <VictoryBar
            data={[stats[2]]}
            labels={({ datum }) => generateLabel(datum)}
            style={barStyle}
            labelComponent={<VictoryLabel dy={-12} dx={-34} />}
          />
        </VictoryStack>
      </VictoryGroup>
    </div>
  );
}
