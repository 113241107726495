import firebase from "firebase/compat";
import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../../utils/utils";
import { HashtagConfiguration, parseYamlDescription } from "./hashpage_shared";

export default function HashtagTaggingLink({
  text,
  highlightColor,
  linkStyle = {}
}: {
  text: string;
  highlightColor?: string;
  linkStyle?: CSSProperties;
}) {
  const history = useHistory();
  const [abbreviation, setAbbreviation] = useState<string>("");

  useEffect(() => {
    if (text.replace("#", "").length > 0) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(text.replace("*", ""))
        .get()
        .then(parseDoc)
        .then((snapshot: CardSchema) => {
          const hashtagYamlDescription: HashtagConfiguration =
            parseYamlDescription(snapshot.hashtagMetadata?.description);

          setAbbreviation(hashtagYamlDescription.별칭 || "");
        });
    }
  }, []);

  if (text.replace("#", "").length === 0) {
    return null;
  }

  return (
    <span
      style={{
        color: highlightColor,
        textDecoration: "underline",
        cursor: "pointer",
        ...linkStyle
      }}
      onClick={event => {
        event.stopPropagation();
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, text.replace("*", ""))
        );
      }}
    >
      {abbreviation ? abbreviation : text.replace("*", "").replace(/_/gi, " ")}
    </span>
  );
}
