import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from "react";
import { ThumbUp } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {
  FirestoreCollection,
  LikeCountsSchema
} from "../../constants/firestore_schema";

import {
  flatten,
  getDocsFromSnapshot,
  groupBy,
  mapValues
} from "../../utils/utils";
import { Popover } from "@material-ui/core";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import HighlightChatlineWrapper from "./HighlightChatlineWrapper";
import OXOInfiniteScroll from "../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import { DARK_THEME_BACKGROUND } from "../../constants/enums";
import createPersistedState from "use-persisted-state";
import { DAY } from "../../utils/datetime";
import firebase from "firebase/compat";

const CHATS_PER_DAY = 10;
export default function ChatHighlights({
  chatRoomId,
  setMainCursor
}: {
  chatRoomId: string;
  setMainCursor: Dispatch<SetStateAction<number | undefined>>;
}) {
  const useIsDarkPersisted = createPersistedState<boolean>("isDarkChat");
  const [isDark, setIsDark] = useIsDarkPersisted(false);

  const [windowWidth, windowHeight] = useWindowSize();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [page, setPage] = useState<number>(3);

  const [highlightLikeCounts, setHighlightLikeCounts] =
    useState<LikeCountsSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.LIKE_COUNTS)
      .where("referenceParentId", "==", chatRoomId)
      .where("referenceCreatedAt", ">", new Date().valueOf() - DAY * (page + 1))
      .orderBy("referenceCreatedAt", "desc")
      .get()
      .then(snapshot => {
        setHighlightLikeCounts(getDocsFromSnapshot(snapshot));
      });
  }, [chatRoomId, page]);

  const topLikes = useMemo(() => {
    if (!highlightLikeCounts) {
      return;
    }
    const likeCountsByDay = groupBy(highlightLikeCounts, likeCount =>
      new Date(likeCount.referenceCreatedAt).toLocaleDateString()
    );
    const dailyTopLikes = mapValues(likeCountsByDay, likeCounts =>
      likeCounts
        .sort(
          (likeCountA, likeCountB) =>
            likeCountB.likeCounts - likeCountA.likeCounts
        )
        .slice(0, CHATS_PER_DAY)
    );

    return flatten(Object.values(dailyTopLikes)).sort(
      (likeCountA, likeCountB) =>
        likeCountB.referenceCreatedAt - likeCountA.referenceCreatedAt
    );
  }, [highlightLikeCounts]);

  if (!topLikes) {
    return null;
  }

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <div
          style={{
            backgroundColor: isDark ? "#212025" : "white"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              padding: 20,
              color: isDark ? "white" : "black"
            }}
          >
            주요 대화 바로가기
          </div>
          <div
            style={{
              width: Math.min(500, windowWidth - 50),
              height: Math.min(windowHeight - 200, 800),
              display: "flex",
              flexDirection: "column-reverse",
              overflow: "auto",
              padding: 20
            }}
          >
            {topLikes.slice(0, page * CHATS_PER_DAY).map((likeCount, i) => {
              const previousLikeCount = topLikes[i - 1];
              const previousDate =
                previousLikeCount &&
                new Date(previousLikeCount.referenceCreatedAt);

              return (
                <div key={likeCount.referenceId}>
                  {previousLikeCount &&
                    previousDate.toLocaleDateString() !==
                      new Date(
                        likeCount.referenceCreatedAt
                      ).toLocaleDateString() && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            padding: "5px 20px",
                            marginTop: 15,
                            marginBottom: 15,
                            borderRadius: "20px",
                            color: "white",
                            backgroundColor: isDark
                              ? DARK_THEME_BACKGROUND
                              : "#b3b3b3"
                          }}
                        >
                          {`${previousDate.getFullYear()}년 ${
                            previousDate.getMonth() + 1
                          }월 ${previousDate.getDate()}일 ${previousDate.toLocaleDateString(
                            "ko-KR",
                            { weekday: "long" }
                          )}`}
                        </div>
                      </div>
                    )}
                  <div
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      cursor: "pointer"
                    }}
                    key={likeCount.referenceId}
                    onClick={() => {
                      handleClose();
                      setMainCursor(likeCount.referenceCreatedAt);
                    }}
                  >
                    <HighlightChatlineWrapper
                      chatId={likeCount.referenceId}
                      onChatBubbleClick={() => {
                        handleClose();
                        setMainCursor(likeCount.referenceCreatedAt);
                      }}
                      isDark={isDark}
                    />
                  </div>
                </div>
              );
            })}
            <OXOInfiniteScroll setNextPage={() => setPage(prev => prev + 1)} />
          </div>
        </div>
      </Popover>
      {topLikes && topLikes.length > 0 && (
        <Button
          style={{
            borderRadius: "50%",
            width: 33,
            height: 33,
            minWidth: 33,
            backgroundColor: "#e0e0e0"
          }}
          onClick={handleClick}
        >
          <ThumbUp style={{ width: 17, height: 17 }} />
        </Button>
      )}
    </div>
  );
}
