/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import PartySelect from "./PartySelect";
import PartyPeopleSelect from "./PartyPeopleSelect";
import useDailyReportsDataForHashtag from "./useDailyReportsDataForHashtag";

export default function PartyPersonSelector({
  hashtag,
  selectedDate,
  setSelectedHashtag
}: {
  hashtag: string;
  selectedDate: number;
  setSelectedHashtag: (hashtag: string) => void;
}) {
  const [partyHashtag, setPartyHashtag] = useState<string>(hashtag);
  const [personHashtag, setPersonHashtag] = useState<string>("전체");

  const dailyReportsData = useDailyReportsDataForHashtag({
    hashtag: partyHashtag,
    selectedDate: selectedDate,
    days: 14
  });

  useEffect(() => {
    setPersonHashtag("전체");
  }, [partyHashtag]);

  useEffect(() => {
    setSelectedHashtag(personHashtag === "전체" ? partyHashtag : personHashtag);
  }, [partyHashtag, personHashtag]);

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <div>
        <PartySelect
          selectedHashtag={partyHashtag}
          setSelectedHashtag={setPartyHashtag}
        />
      </div>
      <div
        css={css`
          margin-left: 10px;
        `}
      >
        <PartyPeopleSelect
          personHashtag={personHashtag}
          setPersonHashtag={setPersonHashtag}
          dailyReportsData={dailyReportsData}
        />
      </div>
    </div>
  );
}
