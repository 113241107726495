import React, { useContext, useEffect, useState } from "react";
import { BACKGROUND_COLOR } from "../../../constants/enums";
import { addCardViewCount } from "../../../lib/card_lib";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import CommentsForCardId from "../WeeklyReview/CommentsForCardId";
import ResultStatistics from "./ResultStatistics";
import CardStatsGenderAgeChart from "./CardStatsGenderAgeChart";

export default function TopicDetailsCommentsAndResults({
  cardId
}: {
  cardId: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [showResults, setShowResults] = useState<boolean>(true);

  useEffect(() => {
    addCardViewCount(cardId, currentUserId);
  }, []);

  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <div
        style={{
          minHeight: "430px",
          paddingTop: "30px"
        }}
      >
        <CommentsForCardId cardId={cardId} />
      </div>
      {!showResults && (
        <div style={{ padding: "20px 10px" }}>
          <OXOButton
            label={"결과보기"}
            onClick={() => setShowResults(true)}
            style={{ backgroundColor: BACKGROUND_COLOR }}
          />
        </div>
      )}
      {showResults && <ResultStatistics cardId={cardId} />}
      {showResults && (
        <div>
          <CardStatsGenderAgeChart cardId={cardId} />
        </div>
      )}
    </div>
  );
}
