/** @jsxImportSource @emotion/react */

import React from "react";
import { CardSchema } from "../../../constants/firestore_schema";
import { todayInKorea } from "../../../utils/datetime";
import { CSS_TD } from "../DataExplorerView/shared";
import HashtagTimeSeriesTribeAnswersForCard from "./HashtagTimeSeriesTribeAnswersForCard";

export default function HashtagTimeSeriesTribeAnswers({
  cards
}: {
  cards: CardSchema[];
}) {
  if (!cards) {
    return null;
  }

  return (
    <table>
      <tr>
        <td css={CSS_TD}>날짜</td>
        <td css={CSS_TD}>호랑이</td>
        <td css={CSS_TD}>하마</td>
        <td css={CSS_TD}>코끼리</td>
        <td css={CSS_TD}>공룡</td>
        <td css={CSS_TD}>사자</td>
        <td css={CSS_TD}>진보</td>
        <td css={CSS_TD}>중도</td>
        <td css={CSS_TD}>보수</td>
        <td css={CSS_TD}>부족간 평균</td>
        <td css={CSS_TD}>합계</td>
      </tr>
      {cards.map(card => {
        const koreanDate = todayInKorea(card.featuredOnNewsTimelineAt);

        return (
          <tr key={card.id}>
            <td css={CSS_TD}>
              {koreanDate.year +
                "." +
                koreanDate.month +
                "." +
                koreanDate.day +
                "."}
            </td>
            <HashtagTimeSeriesTribeAnswersForCard cardId={card.id} />
          </tr>
        );
      })}
    </table>
  );
}
