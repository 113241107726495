/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import React from "react";

import { ApexOptions } from "apexcharts";
import { isArray } from "lodash";
import { ACCENT_COLOR, DIVIDER_COLOR_LIGHT } from "../../../constants/colors";
import { apexChartBasicOptions } from "./fixtures";
import ReactApexChart from "react-apexcharts";

const chartWrapper = css`
  margin-left: -30px;
  margin-top: -43px;

  .apexcharts-tooltip .apexcharts-active {
    outline: none !important;
    color: white !important;
  }

  .apexcharts-tooltip .apexcharts-theme-light {
    border: 1px solid ${DIVIDER_COLOR_LIGHT} !important;
  }
`;

export default function OxLightBarChart({
  data,
  colorScale,
  width = 460
}: {
  data: ApexAxisChartSeries;
  colorScale: string[];
  width?: number;
}) {
  const options: ApexOptions = {
    ...apexChartBasicOptions,
    series: data,
    states: {
      active: {
        filter: {
          type: "none"
        }
      }
    },
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false
      }
    },
    colors: colorScale,
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "60"
      }
    },
    dataLabels: {
      enabled: true,
      distributed: true,
      style: {
        fontSize: "16px",
        fontWeight: "400",
        colors: [ACCENT_COLOR],
        fontFamily: "SUIT"
      },
      formatter: val => {
        if (isArray(val) || typeof val === "string" || val < 12) {
          return "";
        }

        return val.toFixed(1) + "%";
      },
      enabledOnSeries: [0, 1, 2]
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false
      },
      marker: {
        show: false
      },
      fillSeriesColor: true,
      shared: false,
      x: {
        show: false
      },
      y: {
        formatter: val => {
          return val.toFixed(1) + "%";
        }
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: 100
    }
  };

  return (
    <div>
      <section css={chartWrapper}>
        <ReactApexChart
          options={options}
          series={data}
          type="bar"
          height={110}
          width={width}
        />
      </section>
    </div>
  );
}
