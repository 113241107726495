/** @jsxImportSource @emotion/react */

import { ApexOptions } from "apexcharts";
import firebase from "firebase/compat";
import { isArray } from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { OXO_RED } from "../../../constants/colors";

import {
  CardAnswerTribeStatsV2Schema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getWeekNumber, todayInKorea } from "../../../utils/datetime";
import { parseDoc } from "../../../utils/utils";
import { getAverageTribeCardAnswerData } from "../DataExplorerView/getAverageTribeCardAnswerData";

interface timeSeriesLineChart {
  name: string;
  positive: number;
  negative: number;
}

export default function HashTagTimeSeriesAnswersLineChart({
  cards
}: {
  cards: CardSchema[];
}) {
  const [cardAnswerStat, setCardAnswerStat] =
    useState<CardAnswerTribeStatsV2Schema[]>();

  const [data, setData] = useState<timeSeriesLineChart[]>();

  const positives = data
    ?.map(card => {
      return parseFloat(card.positive.toFixed(1));
    })
    .slice(-6);

  const negatives = data
    ?.map(card => {
      return parseFloat(card.negative.toFixed(1));
    })
    .slice(-6);

  const weeks = data
    ?.map(card => {
      return card.name;
    })
    .slice(-6);

  useEffect(() => {
    Promise.all(
      cards.map(card => {
        return firebase
          .firestore()
          .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS_V2)
          .doc(card.id)
          .get()
          .then(parseDoc);
      })
    ).then(setCardAnswerStat);
  }, [cards]);

  useEffect(() => {
    if (!cardAnswerStat) {
      return;
    }

    setData(
      cardAnswerStat.map((cardAnswerStat, i) => {
        const koreanDate = todayInKorea(cards[i].featuredOnNewsTimelineAt);
        const currentDate = new Date(
          `${koreanDate.year}-${koreanDate.month}-${koreanDate.day}`
        );

        const averageAnswers = getAverageTribeCardAnswerData(cardAnswerStat);

        return {
          name: `${koreanDate.month}월  ${getWeekNumber(currentDate)}주차`,
          positive: averageAnswers.o,
          negative: averageAnswers.x
        };
      })
    );
  }, [cardAnswerStat]);

  const series = [
    {
      data: negatives || []
    },
    {
      data: positives || []
    }
  ];

  const options: ApexOptions = {
    chart: {
      height: 400,
      toolbar: {
        show: false
      }
    },
    colors: [OXO_RED, "#429CD9"],
    dataLabels: {
      enabled: true,
      offsetY: 2,
      textAnchor: "middle",
      style: {
        fontSize: "20",
        fontWeight: "700",
        fontFamily: "SUIT"
      },
      background: {
        enabled: true,
        borderColor: "none",
        borderWidth: 20,
        padding: 10,
        opacity: 1,
        borderRadius: 10,
        dropShadow: {
          enabled: false
        }
      },
      formatter: val => {
        if (isArray(val) || typeof val === "string" || val < 10) {
          return ` ${val}`;
        }

        return val;
      }
    },
    legend: {
      show: false
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5
      }
    },
    markers: {
      size: 0
    },
    xaxis: {
      categories: weeks,
      labels: {
        style: {
          fontSize: "11"
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        offsetX: -20,
        style: {
          fontSize: "13"
        }
      },
      tickAmount: 4,
      decimalsInFloat: 0
    }
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        width={800}
        height={350}
      />
    </div>
  );
}
