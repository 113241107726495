/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";

export const PoliticalTribe: {
  [tribeKey: string]: { code: string; displayText: string };
} = {
  AVERAGE: {
    code: "average",
    displayText: "평균"
  },
  LEFT: {
    code: "1",
    displayText: "진보"
  },
  MID_LEFT: {
    code: "2",
    displayText: "중도진보"
  },
  MID: {
    code: "3",
    displayText: "중도"
  },
  MID_RIGHT: {
    code: "4",
    displayText: "중도보수"
  },
  RIGHT: {
    code: "5",
    displayText: "보수"
  }
};

export default function TribeStatsSelector({
  selectedTribe,
  setSelectedTribe
}: {
  selectedTribe: string;
  setSelectedTribe: (tribeKey: string) => void;
}) {
  return (
    <div>
      <select
        value={selectedTribe}
        onChange={e => {
          setSelectedTribe(e.target.value);
        }}
        css={css`
          width: 210px;
          height: 44px;
          left: 373px;
          top: 756px;
          padding-left: 10px;
          padding-right: 10px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          box-sizing: border-box;
          border-radius: 10px;
        `}
      >
        {Object.keys(PoliticalTribe).map(tribeKey => (
          <option value={tribeKey} key={tribeKey}>
            {PoliticalTribe[tribeKey].displayText}
          </option>
        ))}
      </select>
    </div>
  );
}
