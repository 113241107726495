import CardMetadata from "../CardComponents/CardMetadata";

export const MediaMetadata: CardMetadata = {
  "3e4472ba-7c97-4f27-b8d4-0db5483bc7d5": {
    name: "한겨레신문",
    color: "#1b9796",
    tier: 1
  },

  "45ad8b74-9469-4290-9545-71cee2821546": {
    name: "경향신문",
    color: "#18407D",
    tier: 1
  },
  "fb2ad863-1f6c-4dcf-9529-b62e8078955e": {
    name: "오마이뉴스",
    color: "#da3e2a",
    tier: 1
  },

  "60f6dddf-fa20-403a-a9bb-5fb1ecfe2d17": {
    name: "세계일보",
    color: "#3a68b7",
    tier: 1
  },

  "4522774e-13bb-4f16-a5e5-1669ab53b311": {
    name: "서울신문",
    color: "#f14401",
    tier: 1
  },

  "06db5245-4d27-4fe1-b396-b797b2ef33c1": {
    name: "한국일보",
    color: "#004935",
    tier: 1
  },

  "ab49f4a1-281f-49b3-9394-72172e2e877c": {
    name: "국민일보",
    color: "#fe9914",
    tier: 1
  },

  "7dac72b9-e0b3-461b-85ce-89cd78fc8b3c": {
    name: "한국경제",
    color: "#324678",
    tier: 1
  },

  "499607c8-18c6-4e5d-8fe3-6cae99c14dbd": {
    name: "매일경제",
    color: "#e67600",
    tier: 1
  },

  "abd9a221-71e8-4be1-8a8e-48d46453ee3f": {
    name: "문화일보",
    color: "#f3dcd6",
    tier: 1
  },

  "d2839d99-3aa6-4e70-8f90-4ce4b058f669": {
    name: "동아일보",
    color: "#037B82",
    tier: 1
  },

  "4e660bf3-dfc1-42a5-984f-b17331170400": {
    name: "중앙일보",
    color: "#F6891E",
    tier: 1
  },

  "524413e2-335d-4d07-af2e-548a971d0951": {
    name: "조선일보",
    color: "#E10011",
    tier: 1
  },
  // "24de829b-749a-4213-a87d-adf552ae5dbf": {
  //   name: "펜앤드마이크",
  //   color: "#0289c3",
  //   tier: 1
  // },

  "531279cb-b942-4f9b-bd0b-96b2b4daa315": {
    name: "TBS",
    color: "#01bacd",
    tier: 2
  },
  "78c177f3-420d-4ab1-b1a3-b7466be3c800": {
    name: "미디어다음",
    color: "#fdbb00",
    tier: 2
  },
  "d9fac41a-b74f-4f63-adae-f779aab2ccda": {
    name: "MBC",
    color: "#DC0D3E",
    tier: 2
  },
  "8238938e-b106-42dd-87ec-d05563888808": {
    name: "JTBC",
    color: "#EED03A",
    tier: 2
  },
  "1e67cc3b-b5d0-4bf4-80b2-ca189860eeb6": {
    name: "KBS",
    color: "#0085ca",
    tier: 2
  },

  "41490c93-0bf0-4c49-b936-3eb67eb2ff60": {
    name: "SBS",
    color: "#0086cd",
    tier: 2
  },

  "4e94fa6c-de22-4fb4-a1a1-38dff0d15b0d": {
    name: "YTN",
    color: "#0000ff",
    tier: 2
  },

  "0938e964-7b8b-4978-9ff9-30373802760e": {
    name: "MBN",
    color: "#ed6d00",
    tier: 2
  },

  "66fe5494-c3c9-4ed1-8fc8-bcfbbc0e9d99": {
    name: "채널A",
    color: "#009bda",
    tier: 2
  },

  "96a40eff-19d8-48fd-a852-b6b300596426": {
    name: "네이버뉴스",
    color: "#03cf5b",
    tier: 2
  },

  "a672213e-513d-40d4-b876-6cd1beb14cb5": {
    name: "TV조선",
    color: "#e60314",
    tier: 2
  }
};
