import React, { CSSProperties, useContext } from "react";
import { THERMOMETER_COLOR, UserAnswer } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import {
  parseYamlDescription,
  StanceTestCategoryConfiguration,
  StanceTestQuestionConfiguration
} from "../common/hashpage_shared";
import StanceTestResultReviewCheckerIcon from "./imgs/StanceTestResultReviewCheckerIcon";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import DetailsButton from "./DetailsButton";
import thermometer_red from "./imgs/thermometer_red.png";
import thermometer_blue from "./imgs/thermometer_blue.png";
import thermometer_yellow from "./imgs/thermometer_yellow.png";

export default function StanceTestResultReviewButtonTypeScore({
  buttonType,
  categories,
  stanceVectors,
  categoryHashCards,
  testCard,
  btnColor = THERMOMETER_COLOR.DUNNO
}: {
  buttonType: string;
  categories: StanceTestCategoryConfiguration;
  stanceVectors: {
    [category: string]: UserVectorSchema;
  };
  categoryHashCards: {
    [hashtag: string]: CardSchema;
  };

  testCard: CardSchema;
  btnColor?: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const testConfiguration = parseYamlDescription(
    testCard.yamlMetadata?.description
  ) as StanceTestQuestionConfiguration;

  if (
    !categories ||
    !stanceVectors ||
    !categoryHashCards ||
    !currentUserVector
  ) {
    return null;
  }

  const buttonConfiguration =
    buttonType === UserAnswer.O
      ? testConfiguration.찬성
      : buttonType === UserAnswer.DUNNO
      ? testConfiguration.세모
      : testConfiguration.반대;

  if (!buttonConfiguration) {
    return null;
  }

  const userAnswer = currentUserVector[testCard.id];

  const buttonStyle: CSSProperties = {
    padding: 20,
    margin: 30,
    marginTop: 10,
    marginBottom: 0,
    backgroundColor: userAnswer === buttonType ? btnColor : "white",
    color: userAnswer === buttonType ? "white" : btnColor,
    borderRadius: 10,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    wordBreak: "keep-all",
    position: "relative",
    height: 70,
    fontSize: 14,
    justifyContent: "space-between"
  };

  return (
    <div
      style={{
        ...buttonStyle,
        border: `1px solid ${btnColor}`
      }}
    >
      <div
        style={{
          marginLeft: -10,

          width: 30,
          height: 30,
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          style={{ width: 30, height: 30 }}
          src={
            btnColor === THERMOMETER_COLOR.DUNNO
              ? thermometer_yellow
              : btnColor === THERMOMETER_COLOR.O
              ? thermometer_red
              : thermometer_blue
          }
          alt="thermometer"
        />
      </div>
      <div style={{ flexGrow: 1, paddingLeft: 15 }}>
        {buttonConfiguration.버튼}
      </div>
      <div
        style={{
          width: 40,
          paddingLeft: buttonConfiguration.자세히 ? 20 : 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: buttonConfiguration.자세히 ? 0 : 13
        }}
      >
        {userAnswer === buttonType && (
          <StanceTestResultReviewCheckerIcon color={"white"} />
        )}
      </div>
      <div style={{ marginRight: -15 }}>
        {buttonConfiguration.자세히 && (
          <DetailsButton
            text={buttonConfiguration.자세히}
            buttonColor={userAnswer === buttonType ? "white" : btnColor}
          />
        )}
      </div>
    </div>
  );
}
