/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import firebase from "firebase/compat";
import {
  CardAnswerSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../../utils/utils";
import { DotLoader } from "react-spinners";
import AgeTribeTable from "./AgeTribeTable";
import RegionTribeTable from "./RegionTribeTable";

export default function RecentAnswerTribesView() {
  const [cardAnswers, setCardAnswers] = useState<CardAnswerSchema[]>();

  const [ageTribeStats, setAgeTribeStats] = useState<{
    [ageGroup: string]: { [tribeId: string]: number };
  }>({});
  const [ageGenderTribeStats, setAgeGenderTribeStats] = useState<{
    [gender: string]: { [ageGroup: string]: { [tribeId: string]: number } };
  }>({});
  const [regionTribeStats, setRegionTribeStats] = useState<{
    [region: string]: { [tribeId: string]: number };
  }>({});

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .orderBy("createdAt", "desc")
      .limit(10000)
      .get()
      .then(getDocsFromSnapshot)
      .then((cardAnswers: CardAnswerSchema[]) => {
        const seenUserIds = new Set();
        setCardAnswers(
          cardAnswers.filter(cardAnswer => {
            if (seenUserIds.has(cardAnswer.userId)) {
              return false;
            } else {
              seenUserIds.add(cardAnswer.userId);
              return true;
            }
          })
        );
      });
  }, []);

  useEffect(() => {
    if (!cardAnswers) {
      return;
    }
    const ageTribes: { [ageGroup: string]: { [tribeId: string]: number } } = {};
    const ageGenderTribes: {
      [gender: string]: { [ageGroup: string]: { [tribeId: string]: number } };
    } = {};
    const regionTribes: { [region: string]: { [tribeId: string]: number } } =
      {};

    cardAnswers.forEach(cardAnswer => {
      if (!cardAnswer.tribeV2Id) {
        return;
      }
      const birthYear = cardAnswer.birthYear;
      let ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;

      if (ageGroupNumber > 60) {
        ageGroupNumber = 60;
      }

      const ageGroup =
        ageGroupNumber > 60
          ? 60
          : ageGroupNumber <= 0
          ? undefined
          : ageGroupNumber;

      if (ageGroup) {
        const ageGroupString = ageGroup.toFixed();
        if (!ageTribes[ageGroupString]) {
          ageTribes[ageGroupString] = {};
        }
        if (!ageTribes[ageGroupString][cardAnswer.tribeV2Id]) {
          ageTribes[ageGroupString][cardAnswer.tribeV2Id] = 0;
        }
        ageTribes[ageGroupString][cardAnswer.tribeV2Id] += 1;
      }

      if (ageGroup && cardAnswer.gender) {
        const ageGroupString = ageGroup.toFixed();
        if (!ageGenderTribes[cardAnswer.gender]) {
          ageGenderTribes[cardAnswer.gender] = {};
        }
        if (!ageGenderTribes[cardAnswer.gender][ageGroupString]) {
          ageGenderTribes[cardAnswer.gender][ageGroupString] = {};
        }
        if (
          !ageGenderTribes[cardAnswer.gender][ageGroupString][
            cardAnswer.tribeV2Id
          ]
        ) {
          ageGenderTribes[cardAnswer.gender][ageGroupString][
            cardAnswer.tribeV2Id
          ] = 0;
        }
        ageGenderTribes[cardAnswer.gender][ageGroupString][
          cardAnswer.tribeV2Id
        ] += 1;
      }

      if (cardAnswer.jurisdictionAddress) {
        const region = cardAnswer.jurisdictionAddress
          ?.replace("세계 아시아 동북아시아 대한민국 ", "")
          .split(" ")[0];

        if (!regionTribes[region]) {
          regionTribes[region] = {};
        }
        if (!regionTribes[region][cardAnswer.tribeV2Id]) {
          regionTribes[region][cardAnswer.tribeV2Id] = 0;
        }
        regionTribes[region][cardAnswer.tribeV2Id] += 1;
      }
    });

    setAgeTribeStats(ageTribes);
    setAgeGenderTribeStats(ageGenderTribes);
    setRegionTribeStats(regionTribes);
  }, [cardAnswers]);

  if (!cardAnswers) {
    return (
      <div>
        <DotLoader />
        <div>
          최근 10000개의 응답을 분석하여, 중복 사용자를 제거한 결과를 계산
          중입니다. 데이터를 많이 불러오는 만큼 돈도 많이 나올 수 있어요.
          최소한으로 사용 해 주세요.
        </div>
      </div>
    );
  }

  return (
    <div
      css={css`
        width: 100%;
        padding: 20px;
      `}
    >
      <div>
        최근 응답 10000개에서 찾은 {cardAnswers.length}명의 사용자 분석 결과
      </div>

      <h2>연령대별 부족 분포</h2>
      <AgeTribeTable ageTribeStats={ageTribeStats} />

      <h2>남성 연령대별 부족 분포</h2>
      <AgeTribeTable ageTribeStats={ageGenderTribeStats["male"]} />
      <h2>여성 연령대별 부족 분포</h2>
      <AgeTribeTable ageTribeStats={ageGenderTribeStats["female"]} />

      <h2>지역별 부족 분포</h2>
      <RegionTribeTable regionTribes={regionTribeStats} />
    </div>
  );
}
