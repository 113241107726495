/** @jsxImportSource @emotion/react */

import React from "react";
import back from "./images/back.jpeg";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const A4PageWithNoSpace = styled.div`
  width: 1000px;
  height: 1410px;
  page-break-before: always;
  page-break-after: always;
  display: flex;
  flex-direction: column;
`;

export default function YearInReviewBack() {
  return (
    <A4PageWithNoSpace>
      <img
        src={back}
        css={css`
          width: 100%;
          height: 100%;
        `}
      />
    </A4PageWithNoSpace>
  );
}
