import firebase from "firebase/compat";
import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import hash from "./images/hash.svg";

export default function MiniHashtagCard({
  cardId,
  style
}: {
  cardId: string;
  style?: CSSProperties;
}) {
  const history = useHistory();
  const expandCard = false;

  const [card, setCard] = useState<CardSchema | undefined>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => unsubscribe();
  }, [cardId]);

  const [windowWidth, windowHeight] = useWindowSize();
  const cardOutline: CSSProperties = {
    width: windowWidth - 40,
    maxWidth: 400,
    minHeight: "110px",
    borderRadius: expandCard ? 0 : "28px",
    display: "flex",
    scrollSnapAlign: "center",
    justifyContent: "left",
    flexDirection: "column",
    backgroundColor: "white",
    textAlign: "left"
  };

  if (!card || !cardId) {
    if (!cardId) {
      console.log("cardID is missing in TodayTopicCard!");
    }
    return null;
  }

  return (
    <div
      style={{
        ...cardOutline,
        ...{
          justifyContent: "space-between"
        },
        ...style
      }}
    >
      <div
        style={{
          flexGrow: 1,
          minHeight: 100,
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
          position: "relative",
          ...(card?.hashtagMetadata?.photos &&
            card?.hashtagMetadata?.photos.length > 0 && {
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${card?.hashtagMetadata?.photos[0]})`
            }),
          boxShadow: "inset 0 0 0 50vw rgba(49,81,118,0.9)",
          cursor: "pointer",
          fontWeight: "bold",
          color: "white"
        }}
        onClick={e => {
          e.stopPropagation();
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(history.location.search, cardId)
          );
        }}
      >
        <div style={{ marginTop: 0, marginLeft: -3, opacity: "50%" }}>
          <img src={hash} alt="hash" />
        </div>
        <div style={{ fontSize: 30, marginTop: 20, padding: "0 20px 20px" }}>
          {cardId.replace(/\_/gi, " ")}
        </div>
      </div>
    </div>
  );
}
