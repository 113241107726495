/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import MiniQuestionPieChart from "../../../topic/Question/QuestionChart/MiniQuestionPieChart";
import { getAgreePercentage } from "../../../../utils/data_utils";
import { css } from "@emotion/react";

export default function CardAnswerPieChartForCardId({
  cardId,
  size,
  showPercentage = false
}: {
  cardId: string;
  size?: number;
  showPercentage?: boolean;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats || cardAnswerStats.count === 0) {
    return null;
  }

  return (
    <div>
      <MiniQuestionPieChart cardChartStats={cardAnswerStats} size={size} />
      {showPercentage && (
        <div
          css={css`
            font-size: 12px;
            text-align: center;
          `}
        >
          찬성 {getAgreePercentage(cardAnswerStats).toFixed()}%
        </div>
      )}
    </div>
  );
}
