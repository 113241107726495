/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import {
  DAY,
  DAYS,
  formatKoreanDate,
  toDateOnsetKoreaStandardTime,
  todayInKorea
} from "../../../utils/datetime";
import DateSelectionBar from "../DailyReportsView/DateSelectionBar";
import { css } from "@emotion/react";
import { DailyReportsDataSchema } from "../DailyReportsView/useDailyReportsDataForHashtag";
import useDailyReportsDataForDetails from "../DailyReportsView/useDailyReportsDataForDetails";
import { Input } from "@material-ui/core";
import AgeGenderStats from "./AgeGenderStats";
import TribeStatsTds from "./TribeStatsTds";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

export default function DataExplorerView() {
  const [startDateTime, setStartDateTime] = useState<number>(
    Date.now() - 7 * DAYS
  );

  const history = useHistory();
  const [endDateTime, setEndDateTime] = useState<number>(Date.now());

  const [hashtag, setHashtag] = useState<string>("이재명");

  const data: DailyReportsDataSchema = useDailyReportsDataForDetails({
    hashtag: hashtag,
    selectedDate:
      toDateOnsetKoreaStandardTime(
        new Date(endDateTime).getFullYear(),
        new Date(endDateTime).getMonth() + 1,
        new Date(endDateTime).getDate()
      ).valueOf() + DAY,
    days: (endDateTime - startDateTime) / DAYS
  });

  console.log(data);

  return (
    <div
      css={css`
        padding: 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div>
          #<Input onChange={e => setHashtag(e.target.value)} value={hashtag} />
        </div>
        <div>
          <DateSelectionBar
            selectedDate={startDateTime}
            setSelectedDate={setStartDateTime}
          />
        </div>
        <div>~</div>
        <div>
          <DateSelectionBar
            selectedDate={endDateTime}
            setSelectedDate={setEndDateTime}
          />
        </div>
      </div>
      <div>
        <div
          css={css`
            margin-top: 10px;
          `}
        >
          Spreadsheet에 복사해서 붙여서 사용하세요~!
        </div>
        <table
          css={css`
            margin-top: 10px;
            font-size: 10px;
            white-space: nowrap;
          `}
        >
          <tr>
            <td>ID</td>
            <td>제목</td>
            <td>메인 해시태그</td>
            <td>서브 해시태그</td>
            <td>발언자</td>
            <td>발언일</td>
            <td>timestamp</td>
            {["호랑이", "하마", "코끼리", "공룡", "사자"].map(tribeId => {
              return (
                <>
                  {["O", "?", "X", "total", "찬성율"].map(answer => {
                    return (
                      <td key={tribeId + answer}>{tribeId + " " + answer}</td>
                    );
                  })}
                </>
              );
            })}
            {["10대", "20대", "30대", "40대", "50대", "60대+"].map(ageGroup => {
              return ["male", "female"].map(gender => (
                <>
                  {["O", "?", "X", "total", "찬성율"].map(answer => {
                    return (
                      <td key={ageGroup + gender + answer}>
                        {ageGroup + " " + gender + " " + answer}
                      </td>
                    );
                  })}
                </>
              ));
            })}
          </tr>
          {data.cards
            ?.sort(
              (cardA, cardB) =>
                (cardB.featuredOnNewsTimelineAt || 0) -
                (cardA.featuredOnNewsTimelineAt || 0)
            )
            .map(card => {
              return (
                <tr key={card.id}>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                    onClick={() => {
                      history.push(ROUTES.CARD + "/" + card.id);
                    }}
                  >
                    {card.id}
                  </td>
                  <td>{card.title}</td>
                  <td>{card.hashtags?.[0]}</td>
                  <td>
                    {card.hashtags
                      ?.slice(1)
                      .filter(hashtag => hashtag.length > 0)
                      .join(", ")}
                  </td>
                  <td>
                    {card.speakerHashTag
                      ?.filter(hashtag => hashtag.length > 0)
                      .join(", ")}
                  </td>
                  <td>{formatKoreanDate(todayInKorea(card.publishedAt))}</td>
                  <td>{card.publishedAt}</td>
                  {card.id && <TribeStatsTds cardId={card.id} />}
                  {card.id && <AgeGenderStats cardId={card.id} />}
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
}
