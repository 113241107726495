import firebase from "firebase/compat";

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  CardAnswerSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter,
  useIsAdmin
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import HashtagPill from "./HashtagPill";
import OXOText from "../../../utility/OXOText/OXOText";

export default function CommentTitleForBubble({
  cardId,
  color = "white"
}: {
  cardId: string;
  color?: string;
}) {
  const history = useHistory();
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const isAdmin = useIsAdmin();

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  if (!card || !cardAnswer) {
    return null;
  }

  const trimmedTitle = card.title?.replace(/["“”]/g, "") || "";

  const speakerFromTitle = trimmedTitle.split(" ")[0];

  const commentAfter: string = card.title;

  const commentBefore: string | undefined =
    card.type === CardType.DEBATE
      ? card.debateCommentMetadata?.comment
      : card.type === CardType.NEWS_TICKER
      ? trimmedTitle.slice(speakerFromTitle.length + 1)
      : trimmedTitle;

  const comment =
    card.type === CardType.NEWS_TICKER && card.newsTickerMetadata
      ? commentAfter
      : commentBefore;

  if (card.type === CardType.NEWS_TICKER && !card.displayToUsers && !isAdmin) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          minHeight: 20,
          color: color,
          fontSize: 18,
          fontWeight: "bold",
          paddingTop: 10,
          lineHeight: 1.5
        }}
        onClick={e => {
          firebase.analytics().logEvent("v4_click_hash_comment_words", {
            event_category: "hashcard",
            event_label: card.title
          });
          e.stopPropagation();
          history.push(
            history.location.pathname +
              addToCardIdStackUrl(history.location.search, cardId)
          );
        }}
      >
        {comment && <OXOText text={hashtagTextAdapter(comment)} />}

        {card.hashtags?.[0] && (
          <HashtagPill
            hashtag={card.hashtags?.[0].replace("#", "")}
            style={{
              padding: 0,
              border: 0,
              fontSize: 14,
              paddingTop: 20,
              fontWeight: "normal",
              margin: 0,
              color: color,
              textDecoration: "underline"
            }}
          />
        )}
      </div>
    </div>
  );
}
