import { Button } from "@material-ui/core";
import React, { CSSProperties, ReactNode, useContext } from "react";
import { GRAY_DARK, TRIBE_INFO_BY_ID } from "../../../constants/enums";
import { DataPropsContext } from "../Contexts/DataPropsContext";

export default function OXOButton({
  label,
  onClick,
  style,
  disabled = false,
  className = "",
  id = ""
}: {
  label: string | ReactNode;
  onClick: (e: React.MouseEvent) => void;
  style?: CSSProperties;
  disabled?: boolean;
  className?: string;
  id?: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  return (
    <Button
      style={{
        backgroundColor:
          currentUserMetadata && currentUserMetadata.tribeId
            ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
            : GRAY_DARK,
        color: "white",
        fontWeight: "bold",
        borderRadius: "10px",
        height: "41px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        flex: "0 0 100%",
        paddingTop: "5px",
        paddingBottom: "5px",
        ...(style ? style : {})
      }}
      onClick={onClick}
      disabled={disabled}
      className={className}
      id={id}
    >
      {label}
    </Button>
  );
}
