/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import PartyPersonSelector from "../DailyReportsView/PartyPersonSelector";
import { HashtagMentionsColumn } from "./HashtagMentionsColumn";

export default function MentionList({
  selectedDate,
  selectedHashtag,
  setSelectedHashtag,
  setSelectedCard
}: {
  selectedDate: number;
  selectedHashtag: string;
  setSelectedHashtag: (hashtag: string) => void;
  setSelectedCard: (cardId: string) => void;
}) {
  return (
    <div
      css={css`
        background-color: white;
        width: 415px;
        height: 100%;
        padding-top: 30px;
        padding-left: 10px;
        text-align: left;
        overflow: auto;
      `}
    >
      <div
        css={css`
          font-weight: bold;
          font-size: 24px;
          padding-bottom: 10px;
        `}
      >
        발언 반응 분석
      </div>
      <PartyPersonSelector
        hashtag={selectedHashtag}
        selectedDate={selectedDate}
        setSelectedHashtag={setSelectedHashtag}
      />

      <div
        css={css`
          width: 100%;
        `}
      >
        <HashtagMentionsColumn
          selectedDate={selectedDate}
          hashtag={selectedHashtag}
          setSelectedCard={setSelectedCard}
        />
      </div>
    </div>
  );
}
