import firebase from "firebase/compat";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BACKGROUND_COLOR,
  GRAY,
  oxoColors,
  UserAnswer
} from "../../../constants/enums";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection,
  UserVectorSchema
} from "../../../constants/firestore_schema";
import { addAnswer } from "../../../lib/answer_lib";
import {
  addToCardIdStackUrl,
  checkAndRedirectForLogin,
  parseDoc
} from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import CardLoader from "../CardComponents/CardLoader";
import CardTag from "../CardComponents/CardTag";
import OxButtonsForCard from "../../foundation/OXOButton/OxButtonsForCard";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";

export default function PartyPresidentCard({ cardId }: { cardId: string }) {
  const history = useHistory();
  const [question, setQuestion] = useState<CardSchema>();

  useEffect(() => {
    if (cardId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(cardId)
        .onSnapshot(snapshot => setQuestion(parseDoc(snapshot)));
      return () => unsubscribe();
    }
  }, []);

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );
  const answer = cardAnswer?.answer;
  const cardRef = useRef<HTMLDivElement>(null);

  const updateVote = (answer: string) => {
    if (
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!currentUserId) {
      return;
    }

    if (!answer || !dataProps?.currentUserMetadata) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    setRequestedAnswer(answer);

    const answerData: UserVectorSchema = {};
    answerData[cardId] = answer;

    return addAnswer(
      cardId,
      currentUserId,
      dataProps.currentUserMetadata,
      answer
    );
  };

  const [requestedAnswer, setRequestedAnswer] = useState(answer);

  if (!question) {
    return <CardLoader />;
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "340px",
        minWidth: "340px",
        height: "210px",
        borderRadius: "20px",
        scrollSnapAlign: "start",
        scrollMarginLeft: "8px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border:
          "solid 2px " +
          (answer
            ? answer === UserAnswer.O
              ? oxoColors.O
              : answer === UserAnswer.X
              ? oxoColors.X
              : oxoColors.DUNNO
            : BACKGROUND_COLOR)
      }}
      key={cardId}
      ref={cardRef}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "14px",
          marginLeft: "12px",
          marginTop: "12px"
        }}
      >
        <CardTag title={"정치"} />
      </div>
      <div
        style={{
          textAlign: "left",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingRight: "20px",
          paddingLeft: "20px",
          wordBreak: "keep-all",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            cursor: "pointer",
            fontWeight: "bold"
          }}
          onClick={() => {
            firebase.analytics().logEvent("click_politics_1", {
              event_category: "politics_card",
              event_label: question.title
            });
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, cardId)
            );
          }}
        >
          {question.title}
        </div>
        <div
          style={{
            cursor: "pointer",
            flexGrow: 0,
            fontSize: "14px",
            display: "flex",
            color: GRAY
          }}
          onClick={() => {
            firebase.analytics().logEvent("click_politics_1", {
              event_category: "politics_card",
              event_label: question.title
            });
            history.push(
              history.location.pathname +
                addToCardIdStackUrl(history.location.search, cardId)
            );
          }}
        >
          <div>댓글 {question.comments}</div>
          <div style={{ margin: "0 5px" }}>|</div>
          <div>응답 {question.answers}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <OxButtonsForCard cardId={cardId} />
      </div>
    </div>
  );
}
