/** @jsxImportSource @emotion/react */
import React, { useContext, useMemo } from "react";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { addToCardIdStackUrl } from "../../../utils/utils";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack } from "victory";
import { VictoryLabel } from "victory-core";
import {
  GRAY,
  oxoColors,
  PROFILE_SIDE_MENU_ITEMS
} from "../../../constants/enums";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import OXOButton from "../../foundation/OXOButton/OXOButton";

import { useHistory } from "react-router";
import { css } from "@emotion/react";
import { getAgeGenderStatsByAgeGroup } from "../../../utils/ageGender";

const BAR_WIDTH = 5;
const MIN_CUT_OFF_GROUPS = 3;

export default function CardStatsGenderAgeChart({
  cardId,
  title,
  colors = [oxoColors.O, oxoColors.DUNNO, oxoColors.X],
  showCounts
}: {
  cardId: string;
  title?: string;
  colors?: [string, string, string];
  showCounts?: boolean;
}) {
  const history = useHistory();
  const [cardAnswerAgeGenderState] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    return getAgeGenderStatsByAgeGroup(cardAnswerAgeGenderState);
  }, [cardAnswerAgeGenderState]);

  if (
    ((statsByAgeGroup && Object.keys(statsByAgeGroup).length) || 0) <
    MIN_CUT_OFF_GROUPS
  ) {
    return null;
  }

  if (!statsByAgeGroup) {
    return null;
  }

  const maleData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];
  const femaleData: {
    x: string;
    ao: number;
    ad: number;
    ax: number;
    count: number;
  }[] = [];

  Object.keys(statsByAgeGroup).forEach(ageGroup => {
    maleData.push({
      x: ageGroup + "대" + (ageGroup === "60" ? "+" : ""),

      ao:
        ((statsByAgeGroup[ageGroup]["male"]?.o || 0) /
          (statsByAgeGroup[ageGroup]["male"]?.count || 1)) *
        100,
      ad:
        ((statsByAgeGroup[ageGroup]["male"]?.["?"] || 0) /
          (statsByAgeGroup[ageGroup]["male"]?.count || 1)) *
        100,
      ax:
        ((statsByAgeGroup[ageGroup]["male"]?.x || 0) /
          (statsByAgeGroup[ageGroup]["male"]?.count || 1)) *
        100,
      count: statsByAgeGroup[ageGroup]["male"]?.count || 0
    });
    femaleData.push({
      x: ageGroup + "대" + (ageGroup === "60" ? "+" : ""),

      ao:
        ((statsByAgeGroup[ageGroup]["female"]?.o || 0) /
          (statsByAgeGroup[ageGroup]["female"]?.count || 1)) *
        100,
      ad:
        ((statsByAgeGroup[ageGroup]["female"]?.["?"] || 0) /
          (statsByAgeGroup[ageGroup]["female"]?.count || 1)) *
        100,
      ax:
        ((statsByAgeGroup[ageGroup]["female"]?.x || 0) /
          (statsByAgeGroup[ageGroup]["female"]?.count || 1)) *
        100,
      count: statsByAgeGroup[ageGroup]["female"]?.count || 0
    });
  });

  const width = 400;
  const height = 500;

  return (
    <div style={{ paddingTop: 0 }}>
      {title && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 16,
            fontWeight: "bold",
            paddingBottom: 20
          }}
        >
          {title}
        </div>
      )}

      {(!currentUserMetadata?.userBasicInfo?.gender ||
        !currentUserMetadata?.userBasicInfo?.birthYear) &&
        !window.navigator.userAgent.startsWith("==Flutter==") && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10
            }}
          >
            <div>
              <OXOButton
                id="nextBtn"
                label={"나이, 성별 입력하기"}
                onClick={() => {
                  history.push(
                    history.location.pathname +
                      addToCardIdStackUrl(
                        history.location.search,
                        PROFILE_SIDE_MENU_ITEMS.SETTING
                      )
                  );
                }}
                style={{
                  width: 200,
                  height: 40,
                  borderRadius: 10,
                  backgroundColor: "white",
                  border: `1px solid #3E7A99`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3E7A99",
                  fontSize: 16
                }}
              />
            </div>
          </div>
        )}
      <div
        style={{
          width: "100%",
          display: "flex"
        }}
      >
        <div
          style={{
            marginRight: -15
          }}
        >
          <div style={{ textAlign: "center", color: GRAY, fontSize: 14 }}>
            남
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            {showCounts && (
              <div
                css={css`
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  font-size: 10px;
                  text-align: right;
                  padding-top: 9%;
                  padding-bottom: 12%;
                `}
              >
                {maleData
                  .map(data => <div key={data.x}>{data.count}</div>)
                  .reverse()}
              </div>
            )}
            <VictoryChart horizontal height={height} width={width}>
              <VictoryStack
                domain={{ y: [0, 100] }}
                style={{
                  data: { width: 25 },
                  labels: { fontSize: 15, fill: GRAY }
                }}
              >
                <VictoryBar
                  barWidth={BAR_WIDTH}
                  width={width / 2 - 20}
                  style={{
                    data: {
                      fill: colors[0]
                    }
                  }}
                  data={maleData}
                  y={data => Math.abs(data.ao)}
                />
                <VictoryBar
                  barWidth={BAR_WIDTH}
                  width={width / 2 - 20}
                  style={{
                    data: {
                      fill: colors[1]
                    }
                  }}
                  data={maleData}
                  y={data => Math.abs(data.ad)}
                />
                <VictoryBar
                  barWidth={BAR_WIDTH}
                  width={width / 2 - 20}
                  style={{
                    data: {
                      fill: colors[2]
                    }
                  }}
                  data={maleData}
                  y={data => Math.abs(data.ax)}
                />
              </VictoryStack>

              <VictoryAxis
                style={{
                  axis: { stroke: "transparent" },
                  ticks: { stroke: "transparent" },
                  tickLabels: { fill: "transparent" }
                }}
                tickLabelComponent={<VictoryLabel x={0} textAnchor="start" />}
                tickValues={maleData.map(point => point.x).reverse()}
                key={"axis1"}
              />
            </VictoryChart>
          </div>
        </div>

        <div>
          <div style={{ textAlign: "center", color: GRAY, fontSize: 14 }}>
            여
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            {showCounts && (
              <div
                css={css`
                  position: absolute;
                  right: 3%;
                  top: 0;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  font-size: 10px;
                  text-align: left;
                  padding-top: 9%;
                  padding-bottom: 12%;
                `}
              >
                {femaleData
                  .map(data => <div key={data.x}>{data.count}</div>)
                  .reverse()}
              </div>
            )}
            <VictoryChart horizontal height={height} width={width}>
              <VictoryStack
                domain={{ y: [0, 100] }}
                style={{
                  data: { width: 25 },
                  labels: { fontSize: 15, fill: "black" }
                }}
              >
                <VictoryBar
                  barWidth={BAR_WIDTH}
                  width={width / 2 - 20}
                  style={{
                    data: {
                      fill: colors[0]
                    }
                  }}
                  data={femaleData}
                  y={data => Math.abs(data.ao)}
                />
                <VictoryBar
                  barWidth={BAR_WIDTH}
                  width={width / 2 - 20}
                  style={{
                    data: {
                      fill: colors[1]
                    }
                  }}
                  data={femaleData}
                  y={data => Math.abs(data.ad)}
                />
                <VictoryBar
                  barWidth={BAR_WIDTH}
                  width={width / 2 - 20}
                  style={{
                    data: {
                      fill: colors[2]
                    }
                  }}
                  data={femaleData}
                  y={data => Math.abs(data.ax)}
                />
              </VictoryStack>

              <VictoryAxis
                style={{
                  axis: { stroke: "transparent" },
                  ticks: { stroke: "transparent" },
                  tickLabels: { fontSize: 14, fill: GRAY }
                }}
                tickLabelComponent={<VictoryLabel x={0} textAnchor="start" />}
                tickValues={maleData.map((point, i) => point.x).reverse()}
                key={"axis1"}
              />
            </VictoryChart>
          </div>
        </div>
      </div>
    </div>
  );
}
