/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import HashtagCommentsPopularityChart from "../DailyReportsView/HashtagCommentsPopularityChart";
import useDailyReportsDataForHashtag from "../DailyReportsView/useDailyReportsDataForHashtag";
import { DATA_PLATFORM_RED_GRAY } from "../../../constants/data_platform_colors";
import { css } from "@emotion/react";
import { DataMentionHashtagSummaryCard } from "../DailyReportsView/DataMentionHashtagSummaryCard";

export default function DailyPersonReport({
  personHashtag
}: {
  personHashtag: string;
}) {
  const selectedDate = useMemo(() => new Date().valueOf(), []);
  const dailyReportsData = useDailyReportsDataForHashtag({
    hashtag: personHashtag,
    selectedDate: selectedDate,
    limit: 10
  });

  const { cardAnswerStatsMap, cards } = dailyReportsData;

  if (!cardAnswerStatsMap || !cards) {
    return null;
  }

  const yesterdayCards = cards;
  const yesterdayCardIds = yesterdayCards.map(
    yesterdayCard => yesterdayCard.id
  );

  const sortedComments = Object.values(cardAnswerStatsMap)
    .filter(cardAnswerStat =>
      yesterdayCardIds.includes(cardAnswerStat.id || "")
    )
    .sort((statA, statB) => {
      return statB.count - statA.count;
    });

  const bottomMention = sortedComments[sortedComments.length - 1];
  const topMention = sortedComments[0];
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        height: 240px;
        margin-top: 52px;
      `}
    >
      <div
        css={css`
          width: 440px;
        `}
      >
        <HashtagCommentsPopularityChart
          hashtag={personHashtag}
          selectedDate={selectedDate}
          selectedTribe={"AVERAGE"}
          dailyReportsData={dailyReportsData}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 440px;
          height: 280px;
        `}
      >
        {topMention?.id ? (
          <div
            css={css`
              margin-top: 40px;
            `}
          >
            <div
              css={css`
                color: ${DATA_PLATFORM_RED_GRAY};
                font-weight: 700;
                font-size: 20px;
                text-align: left;
              `}
            >
              영향을 끼친 발언
            </div>
            <div
              css={css`
                margin-bottom: 10px;
              `}
            >
              <div
                css={css`
                  margin-top: 20px;
                `}
              >
                <DataMentionHashtagSummaryCard
                  cardId={topMention.id}
                  showComments={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div css={css``}>
            <div
              css={css`
                height: 200px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <div
                css={css`
                  color: ${DATA_PLATFORM_RED_GRAY};
                `}
              >
                발언이 아직 없습니다.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
