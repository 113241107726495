import { WarningOutlined } from "@material-ui/icons";
import firebase from "firebase/compat";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import {
  BACKGROUND_COLOR,
  TRIBE_INFO_BY_ID,
  UserAnswer
} from "../../../../constants/enums";
import {
  CommentSchema,
  FirestoreCollection,
  ReferenceType,
  UserPrivilege,
  UserProfileMetadataSchema
} from "../../../../constants/firestore_schema";
import blockOx from "../../../../img/ox/oxoxo.svg";
import {
  parseDoc,
  truncateLongSentence,
  useIsAdmin
} from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import LikeButton from "../../../foundation/OXOComment/LikeButton";
import LikeStatsByRow from "../../../foundation/OXOComment/LikeStatsByRow";
import UserName from "../../../profile/OXOUserProfile/UserName";
import UserProfilePhoto from "../../../views/StatsView/UserProfilePhoto";
import CommentPopup from "./CommentPopup";
import { TribeExpression } from "../../../../constants/expressions";
import { isBadContents } from "../../../../utils/contents";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";

export default function MiniCommentCard({
  commentId,
  style = {},
  disableExpressions = false
}: {
  commentId: string;
  style?: CSSProperties;
  disableExpressions?: boolean;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [openComment, setOpenComment] = useState<boolean>(false);

  const [hideBadComment, setHideBadComment] = useState<boolean>(false);

  const [comment] = useFirestoreLiveDocument<CommentSchema>(
    FirestoreCollection.COMMENTS,
    commentId
  );

  useEffect(() => {
    const isBad =
      comment &&
      !!comment.comment &&
      (!!comment.bad || isBadContents(comment.comment));
    if (isBad !== undefined && isBad !== hideBadComment) {
      setHideBadComment(isBad);
    }
  }, [comment]);

  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (comment && comment.isSpecialUser) {
      firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(comment?.userId)
        .get()
        .then(parseDoc)
        .then(setUserProfileMetadata);
    }
  }, [comment]);
  const isAdmin = useIsAdmin();

  if (!comment || (comment.isSpecialUser && !userProfileMetadata)) {
    return null;
  }

  const adminBlock = isAdmin && (
    <span
      style={{ marginLeft: "5px", cursor: "pointer" }}
      onClick={() => {
        if (comment.id) {
          firebase
            .firestore()
            .collection(FirestoreCollection.COMMENTS)
            .doc(comment.id)
            .set({ bad: !comment.bad }, { merge: true })
            .then(() =>
              alert(
                "블라인드: " +
                  !comment.bad +
                  " 처리 했습니다. 새로고침 해야 보일걸요? 바로 보여야 하는데 이상하지만 나중에 고칠게요."
              )
            );
        }
      }}
    >
      <WarningOutlined />
    </span>
  );

  return (
    <div>
      <div
        style={{
          width: "270px",
          minHeight: "270px",
          borderRadius: "20px",
          backgroundColor:
            comment.isSpecialUser &&
            userProfileMetadata &&
            userProfileMetadata.privileges &&
            userProfileMetadata.privileges.includes(
              UserPrivilege.REAL_NAME_USER
            )
              ? BACKGROUND_COLOR
              : comment.userTribeId
              ? TRIBE_INFO_BY_ID[comment.userTribeId].bgColor
              : "black",
          ...style
        }}
        key={comment.id}
      >
        <div
          style={{
            width: "270px",
            minHeight: "270px",
            display: "flex",
            padding: "20px",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative"
          }}
        >
          <CommentPopup
            commentId={commentId}
            openComment={openComment}
            setOpenComment={setOpenComment}
            disableExpressions={disableExpressions}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "5px",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => setOpenComment(true)}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%"
              }}
            >
              <div>
                {comment.userTribeId && (
                  <UserProfilePhoto
                    tribeId={comment.userTribeId}
                    photoUrl={userProfileMetadata?.photo}
                    width={"34px"}
                    expression={
                      disableExpressions
                        ? TribeExpression.NORMAL
                        : comment.answer === UserAnswer.O
                        ? TribeExpression.HAPPY
                        : comment.answer === UserAnswer.X
                        ? TribeExpression.ANGRY
                        : TribeExpression.NORMAL
                    }
                  />
                )}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "flex-end"
                }}
              >
                <UserName profileUserId={comment.userId} enableLink={false} />
                {/* <SupporterPercentage cardId={comment.userId} /> */}
              </div>
            </div>
          </div>

          <div
            key={comment.id}
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
              cursor: "pointer",
              marginTop: 5
            }}
            onClick={() => setOpenComment(true)}
          >
            <div
              style={{
                textAlign: "left",
                width: "100%",
                flexGrow: 1
              }}
            >
              <div>
                {hideBadComment ? (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setHideBadComment(false);
                    }}
                  >
                    <div>
                      <img src={blockOx} width={70} alt="blockOx" />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      욕설이나 비하가 포함된 의견입니다. 클릭하시면 내용을 보실
                      수 있습니다.
                      {adminBlock}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      textOverflow: "hidden",
                      fontSize: "14px",
                      lineHeight: "20px"
                    }}
                  >
                    {truncateLongSentence(comment.comment || "", 120)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 18
            }}
          >
            <div />
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 6 }}>
                <LikeStatsByRow referenceId={comment.id} />
              </div>
              {comment.userTribeId && (
                <>
                  <LikeButton
                    referenceId={comment.id}
                    referenceCreatedAt={comment.createdAt}
                    referenceType={ReferenceType.COMMENT}
                    referenceUserId={comment.userId}
                    referenceUserTribeId={comment.userTribeId}
                    referenceReferenceId={comment.questionId}
                    likeButtonSize={20}
                    likeCountSize={22}
                    left={0}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
