import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import {
  CardSchema,
  CardType,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { ROUTES } from "../../../../constants/routes";
import {
  addToUrlQuery,
  getDocsFromSnapshot,
  parseDoc,
  removeFromUrlQuery
} from "../../../../utils/utils";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import {
  DEFAULT_HASH_PAGE_CONFIGURATION,
  DEFAULT_HASH_STORY_CONFIGURATION,
  HashtagConfiguration,
  parseYamlDescription
} from "../common/hashpage_shared";
import BackButton from "./imgs/BackButton";
import StanceTestQuestion from "./StanceTestQuestion";
import StanceTestQuestionsIntro1 from "./StanceTestQuestionsIntro1";
import StanceTestQuestionsIntro2 from "./StanceTestQuestionsIntro2";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { GlobalVariableContext } from "../../../foundation/Contexts/GlobalVariableContext";
import firebase from "firebase/compat";
import { SnackbarContext } from "../../../foundation/Contexts/SnackbarContext";

export const STANCE_TEST_SUFFIX = "_STANCE_TEST";

export default function StanceTestView({ hashtag }: { hashtag: string }) {
  const history = useHistory();
  const global = useContext(GlobalVariableContext);
  const [windowWidth, windowHeight] = useWindowSize();
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const snackBar = useContext(SnackbarContext);
  const [currentUserVector] = useFirestoreLiveDocument<UserVectorSchema>(
    FirestoreCollection.USER_VECTOR,
    currentUserId
  );

  const [swipeIndex, setSwipeIndex] = useState<number>(0);

  const [stanceTestCard, setStanceTestCard] = useState<CardSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then(setStanceTestCard);
  }, [hashtag]);

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      firebase.analytics().logEvent("v4_stance_test_login_ask", {
        event_category: "hashcard"
      });

      snackBar(
        "성향 테스트를 하기 위해 로그인이 필요합니다. 로그인 부탁드려요!"
      );
      let successUrlQuery = addToUrlQuery(
        window.location.search,
        "successUrl",
        ROUTES.URL_STACK + "/" + hashtag + "+" + "TEST_" + hashtag
      );
      successUrlQuery = removeFromUrlQuery(successUrlQuery, "cardStack");

      global?.setLoginSuccessUrl(
        ROUTES.URL_STACK + "/" + hashtag + "+" + "TEST_" + hashtag
      );

      history.push(ROUTES.INTRO + successUrlQuery);
    }
  }, [firebase.auth().currentUser]);

  const [stanceTestCards, setStanceTestCards] = useState<CardSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "==", CardType.TEST_QUESTION)
      .where("hashtags", "array-contains", "#" + hashtag)
      .get()
      .then(getDocsFromSnapshot)
      .then(setStanceTestCards);
  }, [hashtag]);

  const unansweredCards = useMemo(() => {
    if (stanceTestCards) {
      return stanceTestCards.filter(
        card => currentUserVector && !currentUserVector[card.id]
      );
    }
  }, [stanceTestCards, currentUserVector]);

  const hashPageConfiguration: HashtagConfiguration | undefined =
    useMemo(() => {
      if (stanceTestCard) {
        const yaml = hashtag.endsWith("_스토리")
          ? DEFAULT_HASH_STORY_CONFIGURATION
          : stanceTestCard.hashtagMetadata?.description ||
            DEFAULT_HASH_PAGE_CONFIGURATION;
        return parseYamlDescription(yaml);
      }
    }, [stanceTestCard]);

  const stanceTestConfiguration =
    hashPageConfiguration && hashPageConfiguration.성향테스트;

  useEffect(() => {
    if (currentUserId && unansweredCards && unansweredCards.length == 0) {
      firebase.analytics().logEvent("v4_stance_test_success", {
        event_category: "hashcard",
        event_label: hashtag
      });

      firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .update({
          finishedTests: firebase.firestore.FieldValue.arrayUnion(hashtag)
        });

      firebase
        .firestore()
        .collection(FirestoreCollection.CARD_VIEW_COUNTERS)
        .doc(hashtag + STANCE_TEST_SUFFIX)
        .set(
          {
            count: firebase.firestore.FieldValue.increment(1)
          },
          { merge: true }
        );

      if (hashtag === "부족성향테스트") {
        global?.setTestResultUrl(ROUTES.ENTRANCE);
      } else {
        global?.setTestResultUrl(
          "/?cardStack=" + "STANCE_TEST_RESULTS_" + hashtag
        );
      }
      history.push(ROUTES.GENDER_AGE_REGION);
    }
  }, [unansweredCards]);

  if (
    stanceTestCards === undefined ||
    unansweredCards === undefined ||
    !currentUserVector ||
    !stanceTestConfiguration ||
    !stanceTestCard
  ) {
    return null;
  }

  const stanceTestMainColor = stanceTestConfiguration.메인컬러
    ? stanceTestConfiguration.메인컬러
    : "#3E7A99";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <SwipeableViews enableMouseEvents index={swipeIndex}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: windowHeight
          }}
        >
          <StanceTestQuestionsIntro1
            stanceTestCard={stanceTestCard}
            stanceTestConfiguration={stanceTestConfiguration}
            onNext={() => {
              if (hashtag === "젠더갈등조사") {
                setSwipeIndex(2);
              } else {
                setSwipeIndex(1);
              }
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: windowHeight,
            position: "relative"
          }}
        >
          <div style={{ position: "absolute", top: 15, left: 3 }}>
            <Button
              onClick={() => {
                setSwipeIndex(prev => prev - 1);
              }}
            >
              <BackButton color={stanceTestMainColor} />
            </Button>
          </div>
          <StanceTestQuestionsIntro2
            stanceTestConfiguration={stanceTestConfiguration}
            onNext={() => {
              setSwipeIndex(2);
            }}
          />
        </div>

        {stanceTestCards.map((stanceTestCard, i) => (
          <div
            key={stanceTestCard.id}
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative"
            }}
          >
            <StanceTestQuestion
              card={stanceTestCard}
              currentUserVector={currentUserVector}
              key={stanceTestCard.id}
              progress={i / (stanceTestCards.length || 1)}
              updateVoteExternal={() => {
                setSwipeIndex(i + 3);
              }}
              onBackButton={() => {
                setSwipeIndex(prev => prev - 1);
              }}
            />
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
}
