import firebase from "firebase/compat";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import {
  addToCardIdStackUrl,
  hashtagTextAdapter,
  parseDoc
} from "../../../utils/utils";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { useHistory } from "react-router";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { GRAY_DARK, TRIBE_INFO_BY_ID } from "../../../constants/enums";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import OXOText from "../../utility/OXOText/OXOText";

export default function MiniTodayTopicCard({
  cardId,
  style,
  borderRadius = 16,
  photoHeight = 150,
  disableClick = false
}: {
  cardId: string;
  style?: CSSProperties;
  borderRadius?: number;
  photoHeight?: number;
  disableClick?: boolean;
}) {
  const history = useHistory();
  const [card, setCard] = useState<CardSchema | undefined>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => {
        setCard(parseDoc(snapshot));
      });
    return () => unsubscribe();
  }, [cardId]);

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );

  const [windowWidth, windowHeight] = useWindowSize();
  const cardOutline: CSSProperties = {
    width: windowWidth - 80,
    maxWidth: 400,
    borderRadius: "28px",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.10)",
    display: "flex",
    scrollSnapAlign: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    marginBottom: "3px"
  };

  if (!card || !cardId) {
    if (!cardId) {
      console.log("cardID is missing in TodayTopicCard!");
    }
    return null;
  }

  return (
    <div
      style={{
        ...cardOutline,
        ...{
          justifyContent: "space-between"
        },
        ...style
      }}
    >
      {card?.topicMetadataV2?.photos &&
        card?.topicMetadataV2?.photos.length > 0 && (
          <div
            style={{
              flexGrow: 1,
              minHeight: photoHeight,
              display: "flex",
              flexDirection: "column",
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
              position: "relative",
              ...(card?.topicMetadataV2?.photos &&
                card?.topicMetadataV2?.photos.length > 0 && {
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${card?.topicMetadataV2?.photos[0]})`
                }),
              cursor: disableClick ? "default" : "pointer"
            }}
            onClick={e => {
              if (!disableClick) {
                e.stopPropagation();
                history.push(
                  history.location.pathname +
                    addToCardIdStackUrl(history.location.search, cardId)
                );
              }
            }}
          />
        )}

      <div
        style={{
          backgroundColor: cardAnswer?.answer
            ? GRAY_DARK
            : currentUserMetadata && currentUserMetadata.tribeId
            ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].heavyColor
            : GRAY_DARK,
          color: "white",
          fontSize: 15,
          fontWeight: "bold",
          lineHeight: 1.5,
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
          padding: "12px 17px",
          ...(card?.topicMetadataV2?.photos &&
          card?.topicMetadataV2?.photos.length > 0
            ? {}
            : { borderTopLeftRadius: "15px", borderTopRightRadius: "15px" })
        }}
      >
        <div
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: disableClick ? "default" : "pointer",
            position: "relative"
          }}
          onClick={e => {
            if (!disableClick) {
              e.stopPropagation();
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(history.location.search, cardId)
              );
            }
          }}
        >
          <div
            style={{
              flexGrow: 1,
              minHeight: 20,
              color: "white",
              fontSize: 15,
              fontWeight: "normal",
              lineHeight: 1.5
            }}
          >
            {card.title && (
              <OXOText
                text={hashtagTextAdapter(card.title)}
                useDivForParagraph
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
