/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { HashtagMiniFace } from "./HashtagMiniFace";
import { CardSchema } from "../../../constants/firestore_schema";
import { groupBy } from "../../../utils/utils";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";

export default function HashtagMiniFaces({
  hashtags,
  cards
}: {
  hashtags: string[];
  cards: CardSchema[];
}) {
  const speakerCards = groupBy(
    cards,
    (card: CardSchema) =>
      card.speakerHashTag
        ?.filter(t => t.length > 0)
        .map(t => {
          return t.replace("#", "");
        })[0] || ""
  );

  return (
    <div
      css={css`
        display: flex;
        padding: 20px;
        justify-content: center;
        flex-wrap: wrap;
      `}
    >
      {hashtags
        .sort(
          (a, b) =>
            (speakerCards[b]?.length || 0) - (speakerCards[a]?.length || 0)
        )
        .map(name => (
          <div
            key={name}
            css={css`
              min-width: 150px;
              position: relative;
            `}
          >
            <HashtagMiniFace
              hashtag={name}
              key={name}
              speakerCards={speakerCards[name]}
            />
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 38px;
                background-color: ${TRIBE_INFO_BY_ID["5"].heavyColor};
                color: white;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
              `}
            >
              {speakerCards[name]?.length || 0}
            </div>
          </div>
        ))}
    </div>
  );
}
