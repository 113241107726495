import React, { useContext } from "react";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import TribeLeaderBoard from "../../views/Community/TribeLeaderBoard";
import goodIcon from "../icons/profileSidebarIcon_ranking.svg";
import UserLikeStats from "../OXOUserProfile/UserLikeStats";

export default function ProfileRanking() {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 14
      }}
    >
      <div
        style={{
          marginTop: 26,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <div style={{ marginBottom: 5 }}>
          <img
            src={goodIcon}
            style={{ width: 30, height: 30 }}
            alt="rankingIcon"
          />
        </div>

        {currentUserId && <UserLikeStats userId={currentUserId} />}
      </div>

      <div
        style={{
          marginTop: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div>
          <div style={{ paddingLeft: 36, fontSize: 14, fontWeight: "bold" }}>
            전체 공감순위
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                marginTop: 14,
                width: "90%"
              }}
            >
              <TribeLeaderBoard mode={"all"} />
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              marginTop: 20,
              paddingLeft: 36,
              fontSize: 14,
              fontWeight: "bold"
            }}
          >
            최근 5일 간 공감 순위
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                marginTop: 14,
                width: "90%"
              }}
            >
              <TribeLeaderBoard mode={"week"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
