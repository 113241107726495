/** @jsxImportSource @emotion/react */

import React from "react";
import { GRAY_DEBATE_DARK, UserAnswer } from "../../../constants/enums";
import DetailsQuestionMarkButton from "./DetailsQuestionMarkButton";
import OXOText from "../../utility/OXOText/OXOText";
import { hashtagTextAdapter } from "../../../utils/utils";
import { css } from "@emotion/react";
import {
  ANSWER_DUNNO_BACKGROUND,
  ANSWER_DUNNO_STROKE,
  ANSWER_O_BACKGROUND,
  ANSWER_O_STROKE,
  ANSWER_X_BACKGROUND,
  ANSWER_X_STROKE
} from "../../../constants/colors";

export default function OxButtonWithDescription({
  btnType,
  answer,
  onVote,
  gap = 0,
  btnName,
  btnDetails
}: {
  btnType: string;
  answer?: string;
  onVote: (answer: string) => void;
  gap?: number;
  btnName: string;
  btnDetails: string;
}) {
  const buttonStyle = css`
    color: ${GRAY_DEBATE_DARK};
    border-radius: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    word-break: keep-all;
    position: relative;
    height: 50px;
    font-size: 14px;
    justify-content: space-between;
    cursor: pointer;
    font-weight: ${answer === btnType ? "bold" : "normal"};
    background-color: ${answer === btnType
      ? btnType === UserAnswer.O
        ? ANSWER_O_BACKGROUND
        : btnType === UserAnswer.X
        ? ANSWER_X_BACKGROUND
        : ANSWER_DUNNO_BACKGROUND
      : "white"};
    border: 1px solid
      ${answer === btnType
        ? btnType === UserAnswer.O
          ? ANSWER_O_STROKE
          : btnType === UserAnswer.X
          ? ANSWER_X_STROKE
          : ANSWER_DUNNO_STROKE
        : GRAY_DEBATE_DARK};
  `;

  return (
    <div
      onClick={() => {
        onVote(btnType);
      }}
      css={[
        buttonStyle,
        {
          width: "100%",
          height: 50,
          marginBottom: 10
        }
      ]}
    >
      <div
        style={{
          paddingLeft: 15,
          cursor: "pointer",
          fontSize: 15
        }}
      >
        <OXOText text={hashtagTextAdapter(btnName)} useDivForParagraph />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ paddingRight: 0, marginRight: -5, cursor: "pointer" }}>
          {btnDetails && (
            <DetailsQuestionMarkButton
              text={btnDetails}
              buttonColor={GRAY_DEBATE_DARK}
            />
          )}
        </div>
      </div>
    </div>
  );
}
