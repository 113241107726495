import React from "react";
import OxButtonsForCard from "../../../foundation/OXOButton/OxButtonsForCard";
import TopicDetailsCommentsAndResults from "../../../cards/DetailViews/TopicDetailsCommentsAndResults";

export default function HashVote({ cardId }: { cardId: string }) {
  return (
    <div>
      <OxButtonsForCard cardId={cardId} />
      <TopicDetailsCommentsAndResults cardId={cardId} />
    </div>
  );
}
