import React, { useContext, useEffect, useState } from "react";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  checkAndRedirectForLogin,
  parseDoc,
  useIsAdmin
} from "../../../utils/utils";
import { addAnswer } from "../../../lib/answer_lib";
import { useHistory } from "react-router";
import { MiniOxButtons } from "./MiniOxButtons";
import { SnackbarContext } from "../Contexts/SnackbarContext";
import MiniOxExpression from "./MiniOxExpression";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import { sendCoinFromOxoTo } from "../../../utils/assets";

export default function MiniOxButtonsForCard({
  cardId,
  additionalActionWhenClicked
}: {
  cardId: string;
  additionalActionWhenClicked?: () => void;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const displaySnackbar = useContext(SnackbarContext);
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  const [cardAnswer, setCardAnswer] = useState<CardAnswerSchema>();
  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .doc(currentUserId + cardId)
      .onSnapshot(snapshot => setCardAnswer(parseDoc(snapshot)));
    return () => {
      unsubscriber();
    };
  }, [currentUserId, cardId]);

  const [requestedAnswer, setRequestedAnswer] = useState(cardAnswer?.answer);

  useEffect(() => {
    setRequestedAnswer(cardAnswer?.answer);
  }, [cardAnswer]);

  const updateVote = (answer: string) => {
    if (
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!answer || !currentUserMetadata || !currentUserId) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    if (!requestedAnswer) {
      //메인페이지 말풍선응답
      sendCoinFromOxoTo(currentUserId, 1, `[1회성${cardId}]OX응답`);
    }

    setRequestedAnswer(answer);

    additionalActionWhenClicked && additionalActionWhenClicked();
    addAnswer(cardId, currentUserId, currentUserMetadata, answer);
  };

  const history = useHistory();
  const isAdmin = useIsAdmin();

  if (!card) {
    return null;
  }

  // 자추 방지
  if (cardId === currentUserId) {
    return null;
  }

  // 자추 방지
  if (card.createdBy === currentUserId && !isAdmin) {
    return null;
  }

  return (
    <div>
      <MiniOxButtons onVote={updateVote} answer={cardAnswer?.answer} />
      {requestedAnswer && (
        <div style={{ position: "absolute" }}>
          <MiniOxExpression
            userCurrentAnswer={cardAnswer?.answer || "notRespond"}
            userNewAnswer={requestedAnswer}
          />
        </div>
      )}
    </div>
  );
}
