import { Popover } from "@material-ui/core";
import firebase from "firebase/compat";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { UserAnswer } from "../../../../constants/enums";
import {
  CardSchema,
  FirestoreCollection,
  UserVectorSchema
} from "../../../../constants/firestore_schema";
import { ROUTES } from "../../../../constants/routes";
import { addAnswer } from "../../../../lib/answer_lib";
import {
  checkAndRedirectForLogin,
  hashtagTextAdapter,
  useIsAdmin
} from "../../../../utils/utils";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import CharacterLoader from "../../../utility/OXOLoader/CharacterLoader";
import {
  parseYamlDescription,
  StanceTestQuestionConfiguration
} from "../common/hashpage_shared";
import DetailsButton from "./DetailsButton";
import OXOText from "../../../utility/OXOText/OXOText";

export default function StanceTestQuestionCardView({
  card,
  currentUserVector,
  updateVoteExternal
}: {
  card: CardSchema;
  currentUserVector: UserVectorSchema;
  updateVoteExternal?: (answer: string) => void;
}) {
  const [expandAdmin, setExpandAdmin] = React.useState(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const history = useHistory();
  const answer = currentUserVector && currentUserVector[card.id];

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [requestedAnswer, setRequestedAnswer] = useState(answer);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [popoverDetails, setPopoverDetails] = useState<string>("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  }, [card.id]);

  const updateVote = (answer: string) => {
    if (
      !checkAndRedirectForLogin(
        history,
        currentUserId,
        currentUserMetadata,
        true
      )
    ) {
      return;
    }

    if (!answer || !currentUserMetadata || !currentUserId) {
      return;
    }

    updateVoteExternal && updateVoteExternal(answer);

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    console.log("Updating answer.");

    setRequestedAnswer(answer);

    const answerData: UserVectorSchema = {};
    answerData[card.id] = answer;

    return addAnswer(card.id, currentUserId, currentUserMetadata, answer);
  };

  const stanceTestConfiguration: StanceTestQuestionConfiguration | undefined =
    useMemo(() => {
      if (card) {
        return parseYamlDescription(card.yamlMetadata?.description);
      }
    }, [card]);

  const trashClicked = () => {
    if (card && window.confirm(`"${card.title}" 질문을 삭제하시겠습니까?`)) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(card.id)
        .delete();
      history.push(ROUTES.HOME);
    }
  };

  const isAdmin = useIsAdmin();

  if (!card || (currentUserId && !currentUserVector)) {
    return <CharacterLoader />;
  }

  const highlightColor = stanceTestConfiguration?.색 || "#699BB0";

  const buttonStyle: CSSProperties = {
    paddingLeft: 20,
    marginTop: 16,
    border: "solid 1px " + highlightColor,
    color: highlightColor,
    borderRadius: 10,
    cursor: "pointer",
    textAlign: "left",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: 73,
    width: 297
  };

  return (
    <div
      style={{
        maxWidth: 400,
        width: "100%",
        paddingBottom: "40px",
        position: "relative"
      }}
    >
      <div style={{ padding: "0px 30px 30px 30px" }} />
      <div
        style={{
          display: "flex",
          height: 200,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          style={{ maxHeight: 118 }}
          src={
            card.yamlMetadata &&
            card.yamlMetadata.photos &&
            card.yamlMetadata.photos[0]
          }
          alt="testImg"
        />
      </div>
      <div
        style={{
          marginTop: 3,
          paddingRight: 30,
          paddingLeft: 30,
          textAlign: "center",
          fontWeight: "bold",
          wordBreak: "keep-all",
          fontSize: 18,
          height: 90,
          lineHeight: "200%"
        }}
      >
        {card.title.split("|").map(text => {
          return <OXOText key={text} text={hashtagTextAdapter(text)} />;
        })}
      </div>
      <div
        style={{
          paddingTop: 48,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <div style={{ padding: 20, fontSize: 14 }}>{popoverDetails}</div>
        </Popover>

        {stanceTestConfiguration?.찬성 && (
          <div
            style={{
              ...buttonStyle,
              ...(answer === UserAnswer.O
                ? {
                    backgroundColor: highlightColor,
                    color: "white"
                  }
                : {})
            }}
            onClick={() => updateVote(UserAnswer.O)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: 16
              }}
            >
              {stanceTestConfiguration?.찬성.버튼?.includes("_")
                ? stanceTestConfiguration?.찬성.버튼?.split("_").map(el => {
                    return (
                      <div key={el} style={{ paddingTop: 5 }}>
                        {el}
                      </div>
                    );
                  })
                : stanceTestConfiguration?.찬성.버튼}
            </div>

            {stanceTestConfiguration?.찬성?.자세히 ? (
              <DetailsButton
                text={stanceTestConfiguration?.찬성?.자세히}
                buttonColor={answer === UserAnswer.O ? "white" : highlightColor}
              />
            ) : (
              <div style={{ width: 20 }} />
            )}
          </div>
        )}

        {stanceTestConfiguration?.세모 && (
          <div
            style={{
              ...buttonStyle,
              ...(answer === UserAnswer.DUNNO
                ? {
                    backgroundColor: highlightColor,
                    color: "white"
                  }
                : {})
            }}
            onClick={() => updateVote(UserAnswer.DUNNO)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: 16
              }}
            >
              {stanceTestConfiguration?.세모.버튼?.includes("_")
                ? stanceTestConfiguration?.세모.버튼?.split("_").map(el => {
                    return (
                      <div key={el} style={{ paddingTop: 5 }}>
                        {el}
                      </div>
                    );
                  })
                : stanceTestConfiguration?.세모.버튼}
            </div>
            {stanceTestConfiguration?.세모?.자세히 ? (
              <DetailsButton
                text={stanceTestConfiguration?.세모?.자세히}
                buttonColor={
                  answer === UserAnswer.DUNNO ? "white" : highlightColor
                }
              />
            ) : (
              <div style={{ width: 20 }} />
            )}
          </div>
        )}

        {stanceTestConfiguration?.반대 && (
          <div
            style={{
              ...buttonStyle,
              ...(answer === UserAnswer.X
                ? {
                    backgroundColor: highlightColor,
                    color: "white"
                  }
                : {})
            }}
            onClick={() => updateVote(UserAnswer.X)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: 16
              }}
            >
              {stanceTestConfiguration?.반대.버튼?.includes("_")
                ? stanceTestConfiguration?.반대.버튼?.split("_").map(el => {
                    return (
                      <div key={el} style={{ paddingTop: 5 }}>
                        {el}
                      </div>
                    );
                  })
                : stanceTestConfiguration?.반대.버튼}
            </div>
            {stanceTestConfiguration?.반대?.자세히 ? (
              <DetailsButton
                text={stanceTestConfiguration?.반대?.자세히}
                buttonColor={answer === UserAnswer.X ? "white" : highlightColor}
              />
            ) : (
              <div style={{ width: 20 }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
