/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CardAnswerJurisdictionStatItemSchema,
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

export default function DistrictStatTable({
  jurisdictionStats,
  cardId,
  level = 0
}: {
  jurisdictionStats?: CardAnswerJurisdictionStatItemSchema;
  cardId?: string;
  level?: number;
}) {
  const card = useFirestoreGetDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  if (!jurisdictionStats) {
    return null;
  }

  const o = jurisdictionStats.answerCounts["o"] || 0;
  const d = jurisdictionStats.answerCounts["?"] || 0;
  const x = jurisdictionStats.answerCounts["x"] || 0;
  const total = o + d + x;
  return (
    <>
      {jurisdictionStats.answerCounts.count !== 0 && level === 5 && (
        <tr>
          <td>{card?.title}</td>

          <td>{o}</td>
          <td>{d}</td>
          <td>{x}</td>
          <td>{total}</td>
          <td>{((o / (total || 1)) * 100).toFixed(1)}%</td>
          <td>{((d / (total || 1)) * 100).toFixed(1)}%</td>
          <td>{((x / (total || 1)) * 100).toFixed(1)}%</td>
        </tr>
      )}
      {level <= 4 &&
        jurisdictionStats.next &&
        Object.keys(jurisdictionStats.next).map(jurisdictionId => (
          <DistrictStatTable
            jurisdictionStats={jurisdictionStats.next[jurisdictionId]}
            cardId={jurisdictionId}
            level={level + 1}
            key={jurisdictionId}
          />
        ))}
    </>
  );
}
