import React from "react";

import { ACCENT_COLOR } from "../../../constants/colors";

export default function ButtonO({
  color = ACCENT_COLOR,
  fill = "white"
}: {
  color?: string;
  fill?: string;
}) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 19 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
        stroke={color}
        strokeWidth="1.3"
      />
    </svg>
  );
}
