import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema
} from "../constants/firestore_schema";
import { sumTwoAnswerCounts } from "./utils";

export function getAgeGenderStatsByAgeGroup(
  cardAnswerAgeGenderState: CardAnswerAgeGenderStatsSchema
) {
  return Object.keys(cardAnswerAgeGenderState).reduce<{
    [ageGroup: string]: {
      [gender: string]: {
        o: number;
        "?": number;
        x: number;
        count: number;
      };
    };
  }>((prev, birthYear) => {
    let ageGroupNumber =
      Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;

    if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
      return prev;
    }

    if (ageGroupNumber > 60) {
      ageGroupNumber = 60;
    }

    const ageGroup = ageGroupNumber.toFixed();

    const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];

    if (birthYearGenderStat) {
      if (prev[ageGroup]) {
        prev[ageGroup]["male"] = sumTwoAnswerCounts(
          (prev[ageGroup]["male"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }) as AnswerCountsSchema,
          (birthYearGenderStat["male"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }) as AnswerCountsSchema
        );

        prev[ageGroup]["female"] = sumTwoAnswerCounts(
          prev[ageGroup]["female"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          },
          birthYearGenderStat["female"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }
        ) as {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      } else {
        prev[ageGroup] = birthYearGenderStat;
      }
    }
    return prev;
  }, {});
}

export function getAgeStatsByAgeGroup(
  cardAnswerAgeGenderState: CardAnswerAgeGenderStatsSchema
) {
  return Object.keys(cardAnswerAgeGenderState).reduce<{
    [ageGroup: string]: {
      o: number;
      "?": number;
      x: number;
      count: number;
    };
  }>((prev, birthYear) => {
    let ageGroupNumber =
      Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;

    if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
      return prev;
    }

    if (ageGroupNumber > 60) {
      ageGroupNumber = 60;
    }

    const ageGroup = ageGroupNumber.toFixed();

    const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];

    if (birthYearGenderStat) {
      if (prev[ageGroup]) {
        prev[ageGroup] = sumTwoAnswerCounts(
          (prev[ageGroup] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }) as AnswerCountsSchema,
          (birthYearGenderStat["male"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }) as AnswerCountsSchema
        );

        prev[ageGroup] = sumTwoAnswerCounts(
          prev[ageGroup] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          },
          birthYearGenderStat["female"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }
        ) as {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      } else {
        prev[ageGroup] = sumTwoAnswerCounts(
          birthYearGenderStat["male"],
          birthYearGenderStat["female"]
        );
      }
    }

    return prev;
  }, {});
}
