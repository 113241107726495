import React, { useContext, useEffect, useState } from "react";
import OxButtonWithDescription from "./OxButtonWithDescription";
import { checkAndRedirectForLogin, parseDoc } from "../../../utils/utils";
import {
  CardAnswerSchema,
  CardSchema,
  FirestoreCollection,
  TopicMetadataV2,
  UserVectorSchema
} from "../../../constants/firestore_schema";
import { addAnswer } from "../../../lib/answer_lib";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import { useHistory } from "react-router";
import firebase from "firebase/compat";
import { UserAnswer } from "../../../constants/enums";
import { sendCoinFromOxoTo } from "../../../utils/assets";

export default function OxButtonsForCardWithDescription({
  cardId,
  additionalActionWhenClicked,
  disableTribeCheck = false
}: {
  cardId: string;
  additionalActionWhenClicked?: (answer: string) => void;
  disableTribeCheck?: boolean;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const history = useHistory();
  const [cardAnswer, setCardAnswer] = useState<CardAnswerSchema>();
  const [topicMetadata, setTopicMetadata] = useState<TopicMetadataV2>();
  const [agreeDetails, setAgreeDetails] = useState<string>();
  const [disagreeDetails, setDisagreeDetails] = useState<string>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .get()
      .then(snapshot => {
        const cardData: CardSchema = parseDoc(snapshot);
        if (cardData.topicMetadataV2) {
          setTopicMetadata(cardData.topicMetadataV2);
        }
        if (cardData.postMetadata) {
          setTopicMetadata(cardData.postMetadata as TopicMetadataV2);
        }
      });
  }, []);

  useEffect(() => {
    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWERS)
      .doc(currentUserId + cardId)
      .onSnapshot(snapshot => setCardAnswer(parseDoc(snapshot)));

    return () => {
      unsubscriber();
    };
  }, [currentUserId, cardId]);

  useEffect(() => {
    if (topicMetadata && topicMetadata.description) {
      const [topicCardDetailScript, ...btnDetails] =
        topicMetadata.description.split("```agree");

      if (btnDetails[0]) {
        const [agreeDetails, disagreeDetails] =
          btnDetails[0].split("```disagree");

        setAgreeDetails(agreeDetails.split("```")[1]);
        setDisagreeDetails(disagreeDetails.split("```")[1]);
      }
    }
  }, [topicMetadata]);

  const answer = cardAnswer?.answer;
  const [requestedAnswer, setRequestedAnswer] = useState(answer);

  const updateVote = (answer: string) => {
    if (
      !disableTribeCheck &&
      !checkAndRedirectForLogin(history, currentUserId, currentUserMetadata)
    ) {
      return;
    }

    if (!currentUserId || !answer || !currentUserMetadata) {
      return;
    }

    if (answer === requestedAnswer) {
      console.log("Already requested the same vote.");
      return;
    }

    if (!requestedAnswer) {
      //오늘의 질문카드 응답
      sendCoinFromOxoTo(currentUserId, 1, `[1회성${cardId}]OX응답`);
    }

    setRequestedAnswer(answer);

    const answerData: UserVectorSchema = {};
    answerData[cardId] = answer;

    additionalActionWhenClicked && additionalActionWhenClicked(answer);

    addAnswer(cardId, currentUserId, currentUserMetadata, answer);
  };

  if (!topicMetadata) {
    return null;
  }

  return (
    <div>
      <OxButtonWithDescription
        btnType={UserAnswer.O}
        onVote={vote => updateVote(vote)}
        answer={requestedAnswer || answer}
        btnName={topicMetadata.agreeOptionName || "찬성해요."}
        btnDetails={topicMetadata.agreeOptionDetails || agreeDetails || ""}
      />
      <OxButtonWithDescription
        btnType={UserAnswer.DUNNO}
        onVote={vote => updateVote(vote)}
        answer={requestedAnswer || answer}
        btnName={topicMetadata.dunnoOptionName || "글쎄요."}
        btnDetails={topicMetadata.dunnoOptionDetails || ""}
      />
      <OxButtonWithDescription
        btnType={UserAnswer.X}
        onVote={vote => updateVote(vote)}
        answer={requestedAnswer || answer}
        btnName={topicMetadata.disagreeOptionName || "반대해요."}
        btnDetails={
          topicMetadata.disagreeOptionDetails || disagreeDetails || ""
        }
      />
    </div>
  );
}
