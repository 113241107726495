import firebase from "firebase/compat";

import React, { useContext, useEffect, useState } from "react";
import {
  GRAY,
  GRAY_DARK,
  GRAY_EXTRA_LIGHT,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import {
  FirestoreCollection,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { listToMap, parseDoc, removeIdKey } from "../../../utils/utils";
import TribeSelectorWithAll from "../../cards/CardComponents/TribeSelectorWithAll";
import { MediaMetadata } from "../../cards/metadata/media_metadata";
import { PartiesMetadata } from "../../cards/metadata/parties_metadata";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { OXOMedia } from "../../foundation/OXOMaps/OXOMedia";
import { OXOParties } from "../../foundation/OXOMaps/OXOParties";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import MediaTimeline from "./MediaTimeline";
import PoliticsTimeline from "./PoliticsTimeline";

export default function PoliticalStats() {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [currentChartIndex, setCurrentChartIndex] = useState<number>(0);
  const [currentTribeId, setCurrentTribeId] = useState("0");

  const [questionAnswerMaps, setQuestionAnswerMaps] = useState<{
    [questionId: string]: QuestionAnswerMapSchema;
  }>();

  const [currentUserMetadata, setCurrentUserMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .onSnapshot(snapshot =>
          setCurrentUserMetadata(removeIdKey(parseDoc(snapshot)))
        );
      return () => unsubscribe();
    }
  }, [currentUserId]);

  useEffect(() => {
    Promise.all(
      [...Object.keys(PartiesMetadata), ...Object.keys(MediaMetadata)].map(
        questionId =>
          firebase
            .firestore()
            .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
            .doc(questionId)
            .get()
            .then(parseDoc)
      )
    )
      .then(
        questionAnswerMaps =>
          listToMap(
            questionAnswerMaps,
            (questionAnswerMap: any) => questionAnswerMap.id,
            (questionAnswerMap: any) => removeIdKey(questionAnswerMap)
          ) as {
            [questionId: string]: QuestionAnswerMapSchema;
          }
      )
      .then(setQuestionAnswerMaps);
  }, []);

  if (!questionAnswerMaps || (currentUserId && !currentUserMetadata)) {
    return (
      <div style={{ padding: "20px" }}>
        <DocumentSkeleton />
      </div>
    );
  }

  return (
    <div style={{}}>
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "solid 1px " + GRAY_EXTRA_LIGHT
          }}
        >
          {["정당", "언론", "미디어"].map((tabTitle: string, i) => {
            return (
              <div
                style={{
                  color: GRAY_DARK,
                  fontSize: "14px",
                  cursor: "pointer",
                  padding: "5px",
                  width: "80px",
                  fontWeight: "bold",
                  borderBottomWidth: currentChartIndex === i ? 2 : 0,
                  borderBottomStyle: "solid",
                  borderBottomColor: GRAY
                }}
                key={tabTitle}
                onClick={() => {
                  firebase.analytics().logEvent("click_category_1", {
                    event_category: "tribe_view",
                    event_label: tabTitle
                  });
                  setCurrentChartIndex(i);
                }}
              >
                {tabTitle}
              </div>
            );
          })}
        </div>

        {currentChartIndex === 0 && (
          <div>
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  fontWeight: "bold",

                  marginLeft: "20px",
                  marginRight: "20px",
                  wordBreak: "keep-all",
                  marginBottom: "10px"
                }}
              >
                {currentTribeId === "0"
                  ? "전체"
                  : TRIBE_INFO_BY_ID[currentTribeId].name}{" "}
                부족의 대통령과 정당에 대한 지지도예요.
              </div>

              <div style={{ marginTop: "20px" }}>
                <TribeSelectorWithAll
                  currentTribeId={currentTribeId}
                  setCurrentTribeId={setCurrentTribeId}
                />
              </div>
            </div>

            <OXOParties questionAnswerMaps={questionAnswerMaps} />
            <div style={{ marginTop: "40px", paddingBottom: "20px" }}>
              <div
                style={{
                  fontWeight: "bold",

                  marginLeft: "10px",
                  marginBottom: "10px"
                }}
              >
                아래 카드에 응답해 생각을 알려주세요.
              </div>

              <PoliticsTimeline />
            </div>
          </div>
        )}
        {currentChartIndex === 1 && (
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontWeight: "bold",

                marginLeft: "20px",
                marginRight: "20px",
                wordBreak: "keep-all"
              }}
            >
              {currentTribeId === "0"
                ? "전체"
                : TRIBE_INFO_BY_ID[currentTribeId].name}{" "}
              부족이 많이 보는 주요 언론이에요.
            </div>

            <div style={{ marginTop: "20px" }}>
              <TribeSelectorWithAll
                currentTribeId={currentTribeId}
                setCurrentTribeId={setCurrentTribeId}
              />
            </div>

            <OXOMedia questionAnswerMaps={questionAnswerMaps} tier={1} />
            <div style={{ marginTop: "40px", paddingBottom: "20px" }}>
              <div
                style={{
                  fontWeight: "bold",

                  marginLeft: "10px",
                  marginBottom: "10px"
                }}
              >
                아래 카드에 응답해 생각을 알려주세요.
              </div>
              <MediaTimeline tier={1} />
            </div>
          </div>
        )}
        {currentChartIndex === 2 && (
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                fontWeight: "bold",

                marginLeft: "20px",
                marginRight: "20px",
                wordBreak: "keep-all",
                marginBottom: "10px"
              }}
            >
              {currentTribeId === "0"
                ? "전체"
                : TRIBE_INFO_BY_ID[currentTribeId].name}{" "}
              부족이 많이 보는 주요 미디어에요.
            </div>

            <div style={{ marginTop: "20px" }}>
              <TribeSelectorWithAll
                currentTribeId={currentTribeId}
                setCurrentTribeId={setCurrentTribeId}
              />
            </div>

            <OXOMedia questionAnswerMaps={questionAnswerMaps} tier={2} />
            <div style={{ marginTop: "40px", paddingBottom: "20px" }}>
              <div
                style={{
                  fontWeight: "bold",

                  marginLeft: "10px",
                  marginBottom: "10px"
                }}
              >
                아래 카드에 응답해 생각을 알려주세요.
              </div>
              <MediaTimeline tier={2} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
