/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import TribeResultsInLine from "../TribeResultsInLine";
import { ButtonOx } from "../../../foundation/OXOButton/ButtonOx";
import Divider from "../../../ui/commons/Divider";
import legends from "../images/legends.png";
import OxBarChartContainer from "../OxBarChartContainer";
import {
  ACCENT_COLOR,
  DIVIDER_COLOR,
  PRIMARY_COLOR
} from "../../../../constants/colors";
import CardAnswerTribeV1Chart from "../../HashCardView/Comment/CardAnswerTribeV1Chart";
import {
  AnswerCountsSchema,
  CardAnswerTribeStatsSchema
} from "../../../../constants/firestore_schema";

const summaryWrapper = (BackgroundColor: string) => {
  return css`
    padding: 20px;
    background-color: ${BackgroundColor};
    border-radius: 12px;
  `;
};

const frame = css`
  width: 520px;
  padding: 20px;
  border: 2px solid ${ACCENT_COLOR};
`;

const chartsWrapper = css`
  width: 480px;
  display: flex;
  flex-direction: column;
  padding: 20px;

  border: solid 1px ${DIVIDER_COLOR};
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
`;

const cardTitle = css`
  margin: 0 auto;

  font-weight: 600;
  font-size: 26px;
  text-align: center;
  line-height: 44px;

  white-space: pre-line;
`;

const footer = css`
  color: ${PRIMARY_COLOR};
  font-size: 16px;
  text-align: center;
`;

export default function DefaultDataSnapshot({
  backgroundColor,
  title,
  cardId,
  dateFormat,
  answerCounts,
  tribeStats,
  ageGenderStats,
  averageCardAnswer
}: {
  backgroundColor: string;
  title: string | undefined;
  cardId: string;
  dateFormat: string;
  answerCounts?: number;
  tribeStats?: CardAnswerTribeStatsSchema;
  ageGenderStats?: {
    [birthYear: number]: {
      [gender: string]: AnswerCountsSchema;
    };
  };
  averageCardAnswer?: AnswerCountsSchema;
}) {
  return (
    <div css={frame}>
      <article css={chartsWrapper}>
        <section css={summaryWrapper(backgroundColor)}>
          <header
            css={[
              cardTitle,
              css`
                margin-bottom: 20px;
              `
            ]}
          >
            {title}
          </header>
          <TribeResultsInLine cardId={cardId} tribeStats={tribeStats} />
          <div
            css={css`
              margin-top: 11px;
            `}
          >
            <ButtonOx />
          </div>
        </section>

        <section
          css={css`
            padding: 20px;
          `}
        >
          <h3>정치성향별 응답 결과</h3>
          <CardAnswerTribeV1Chart cardId={cardId} />
        </section>

        <Divider />

        <section
          css={css`
            padding: 0 20px;
          `}
        >
          <h3
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            정치 성향 평균 (%)
            <img
              src={legends}
              css={css`
                width: 150px;
                object-fit: contain;
              `}
            />
          </h3>
          <OxBarChartContainer
            cardId={cardId}
            width={440}
            answerCounts={answerCounts}
          />
        </section>

        <footer css={footer}>{dateFormat} 설문조사 | 옥소폴리틱스 제공</footer>
      </article>
    </div>
  );
}
