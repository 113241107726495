import React, { useContext } from "react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import { getTribeWaitingImage } from "../../../../utils/utils";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";

export default function NoContentForTribe({ tribeId }: { tribeId?: string }) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;

  const waitingImageTribeId = tribeId || currentUserMetadata?.tribeId;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 60
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "East Sea Dokdo",
          fontSize: 30,
          padding: 30
        }}
      >
        {
          <div
            style={{
              marginTop: 10
            }}
          >
            <div>
              <span>
                {currentUserMetadata?.tribeId &&
                (!tribeId || tribeId === currentUserMetadata?.tribeId)
                  ? `${currentUserMetadata?.modifier} ${
                      TRIBE_INFO_BY_ID[currentUserMetadata?.tribeId]?.name
                    }님의 `
                  : tribeId
                  ? `${TRIBE_INFO_BY_ID[tribeId].name} 부족의 `
                  : "당신의 "}
              </span>
              <span>생각,</span>
            </div>
            <div>살짝만 엿보게 해주세요</div>
          </div>
        }
      </div>
      <div>
        {waitingImageTribeId && (
          <img
            style={{ width: 150, height: 150 }}
            src={getTribeWaitingImage(
              TRIBE_INFO_BY_ID[waitingImageTribeId].image
            )}
            alt={TRIBE_INFO_BY_ID[waitingImageTribeId].name}
          />
        )}
      </div>
    </div>
  );
}
