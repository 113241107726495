import firebase from "firebase/compat";

import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../../utils/utils";

export default function HashCommentName({
  speaker,
  displayPosition = true,
  style = {}
}: {
  speaker: string;
  displayPosition?: boolean;
  style?: CSSProperties;
}) {
  const [hashtagSpeaker, setHashtagSpeaker] = useState<string>();
  const [position, setPosition] = useState<string>();
  const history = useHistory();

  useEffect(() => {
    if (speaker === "옥소") {
      setHashtagSpeaker(speaker);
    } else {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(speaker)
        .get()
        .then(snapshot => {
          const data: CardSchema = parseDoc(snapshot);
          data.abbreviation
            ? setHashtagSpeaker(data.abbreviation)
            : setHashtagSpeaker(data.id);
          data.hashtags && data.hashtags.length
            ? setPosition(data.hashtags?.[0])
            : setPosition("");
        });
    }
  }, []);
  return (
    <span
      style={{
        cursor: "pointer",
        fontSize: displayPosition ? 12 : 20,
        marginRight: 5,
        ...style
      }}
      onClick={e => {
        firebase.analytics().logEvent("v4_click_hash_comment_name", {
          event_category: "hashcard",
          event_label: speaker
        });
        e.stopPropagation();
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, `${speaker}`)
        );
      }}
    >
      {hashtagSpeaker &&
        (displayPosition
          ? hashtagSpeaker.replace(/\_/gi, " ")
          : "@" + hashtagSpeaker.replace(/\_/gi, " "))}
    </span>
  );
}
