/** @jsxImportSource @emotion/react */

import React, { useEffect, useMemo, useState } from "react";
import { CSS_TITLE } from "../DashboardPage";
import { css } from "@emotion/react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardFeatureStatus,
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getDocsFromSnapshot,
  parseDoc,
  removeIdKey,
  sumTwoAnswerCounts
} from "../../../../utils/utils";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import useSupporters from "../../DailyReportsView/useSupporters";
import {
  DATA_PLATFORM_GRAY_COLOR,
  DATA_PLATFORM_RED_GRAY
} from "../../../../constants/data_platform_colors";
import MiniBestCommentsForDailyReport from "../../HashCardView/Comment/MiniBestCommentsForDailyReport";
import CardAnswerTribeBarChart from "../../HashCardView/Comment/CardAnswerTribeBarChart";
import { DataMentionHashtagSummaryCard } from "../../DailyReportsView/DataMentionHashtagSummaryCard";
import MentionAgeChartForReport from "../../MentionResponseView/MentionAgeChartForReport";
import CardStatsGenderAgeChartForPortrait from "../../../cards/DetailViews/CardStatsGenderAgeChartForPortrait";

export default function DailyDashboardDetailsTopic({
  personHashtag,
  competitorHashtag
}: {
  personHashtag: string;
  competitorHashtag: string;
}) {
  const addMz = true;
  const supporterData = useSupporters(personHashtag, competitorHashtag);

  const [lastFocusCard, setLastFocusCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("featuredOnTodayFocus", "==", CardFeatureStatus.ON)
      .where("featuredOnTodayFocusAt", "<", new Date().valueOf())
      .orderBy("featuredOnTodayFocusAt", "desc")
      .limit(1)
      .get()
      .then(cards => {
        const card = getDocsFromSnapshot(cards)[0];
        setLastFocusCard(card);
      });
    return () => {};
  }, []);

  const [cardAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    lastFocusCard?.id
  );

  const [cardAnswerTribeStats, setCardAnswerTribeStats] =
    useState<CardAnswerTribeStatsSchema>();
  useEffect(() => {
    if (lastFocusCard?.id) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(lastFocusCard?.id)
        .onSnapshot(snapshot => {
          setCardAnswerTribeStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [lastFocusCard?.id]);

  const [cardAnswerAgeGenderState] = addMz
    ? useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
        FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
        lastFocusCard?.id
      )
    : [undefined];

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState || !addMz) {
      return;
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      let ageGroupNumber = Math.floor(
        (Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10) /
          40
      );

      if (ageGroupNumber > 1) {
        ageGroupNumber = 1;
      }

      const ageGroup = ageGroupNumber.toFixed();

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];
      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            (prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema,
            (birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState, addMz]);

  const mzAnswerCounts = useMemo(() => {
    if (!statsByAgeGroup) {
      return undefined;
    }
    return sumTwoAnswerCounts(
      statsByAgeGroup[0]["male"],
      statsByAgeGroup[0]["female"]
    );
  }, [statsByAgeGroup]);

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <div
      css={css`
        margin-top: 40px;
        display: flex;
        flex-direction: column;
      `}
    >
      <div css={[CSS_TITLE]}>2 오늘의 설문조사</div>
      <div
        css={css`
          margin-top: 60px;
          font-size: 20px;
          font-weight: 700;
          color: ${DATA_PLATFORM_RED_GRAY};
        `}
      >
        응답 결과
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            정치 성향 평균
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            연령성별 응답 결과
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          align-items: flex-start;
          margin-top: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 420px;
            height: 280px;
            margin-top: 10px;
          `}
        >
          <div
            css={css`
              margin-left: 8px;
              width: 370px;
              margin-top: 40px;
              height: 60px;
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              text-align: left;
              color: ${DATA_PLATFORM_RED_GRAY};
              word-break: keep-all;
              filter: gray;
              -webkit-filter: grayscale(100%);
            `}
          >
            Q. {lastFocusCard?.title}
          </div>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            {lastFocusCard && (
              <DataMentionHashtagSummaryCard
                cardId={lastFocusCard?.id}
                showComments={false}
                showName={false}
              />
            )}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 420px;
            height: 280px;
          `}
        >
          <div
            css={css`
              margin-left: 70px;
              margin-top: -20px;
            `}
          >
            {lastFocusCard?.id && (
              <CardStatsGenderAgeChartForPortrait
                cardId={lastFocusCard.id}
                showCounts
              />
            )}
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            정치성향별 응답 결과
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            지지자별 응답 결과
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          >
            <div
              css={css`
                margin-top: 52px;
              `}
            >
              {lastFocusCard && (
                <MentionAgeChartForReport
                  cardId={lastFocusCard?.id}
                  isAgree={true}
                  average={false}
                  personHashtag={personHashtag}
                  competitorHashtag={competitorHashtag}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            width: 420px;
            height: 140px;
            margin-top: 60px;
          `}
        >
          {lastFocusCard?.id && (
            <CardAnswerTribeBarChart cardId={lastFocusCard?.id} />
          )}
        </div>
        <div
          css={css`
            width: 420px;
            height: 140px;
            margin-top: 60px;
          `}
        ></div>
      </div>

      {lastFocusCard?.id && (
        <div
          css={css`
            margin-top: -30px;
          `}
        >
          <div
            css={css`
              margin-top: 60px;
              font-size: 20px;
              font-weight: 700;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            베스트 댓글
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  margin-top: 6px;
                  font-size: 18px;
                  font-weight: 400;
                  color: ${DATA_PLATFORM_RED_GRAY};
                `}
              >
                정치성향별 베스트 댓글
              </div>
              <div
                css={css`
                  margin-top: 6px;
                  width: 880px;
                  height: 1px;
                  background-color: ${DATA_PLATFORM_GRAY_COLOR};
                `}
              ></div>
            </div>
          </div>
          <div
            css={css`
              margin-top: 10px;
            `}
          >
            <MiniBestCommentsForDailyReport cardId={lastFocusCard?.id} />
          </div>
        </div>
      )}
    </div>
  );
}
