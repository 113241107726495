/** @jsxImportSource @emotion/react */
import React from "react";
import OXOBarChart from "../../topic/Question/QuestionChart/OXOBarChart";
import { css } from "@emotion/react";

import { GRAY, oxoColors } from "../../../constants/enums";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import MentionBarChart from "./MentionBarChart";
import StatisticsPosition from "./StatisticsPosition";

export default function MentionQuestionChart({
  cardId,
  colorScale = [oxoColors.O, oxoColors.DUNNO, oxoColors.X]
}: {
  cardId: string;
  colorScale?: string[];
}) {
  const [cardAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  if (!cardAnswerStats) {
    return null; // cannot be the case.
  }

  return (
    <div>
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <MentionBarChart
          cardAnswerStats={cardAnswerStats}
          colorScale={colorScale}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 40,
          paddingRight: 40,
          color: GRAY
        }}
      >
        <div
          css={css`
            width: 100%;
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
          `}
        >
          <div
            style={{
              textAlign: "right",
              color: GRAY,
              display: "flex"
            }}
          >
            총 응답 수 {cardAnswerStats.count}
          </div>
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                padding-right: 5px;
              `}
            >
              <span>단위(명)</span>
            </div>
            <StatisticsPosition />
          </div>
        </div>
      </div>
    </div>
  );
}
