/** @jsxImportSource @emotion/react */

import React from "react";
import { useCardSubscription } from "../../../utils/hooks/useFirestoreLiveDocument";
import {
  CardAnswerStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { css } from "@emotion/react";
import { addToCardIdStackUrl } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import MiniBestCommentsForPortrait from "../HashCardView/Comment/MiniBestCommentsForPortrait";
import MentionAgeChartForPortrait from "../MentionResponseView/MentionAgeChartForPortrait";
import { DATA_PLATFORM_RED_GRAY } from "../../../constants/data_platform_colors";

export function DataMentionCard({
  cardId,
  isAgree,
  personHashtag,
  competitorHashtag,
  showComments = true
}: {
  cardId: string;
  isAgree: boolean;
  personHashtag: string;
  competitorHashtag: string;
  showComments?: boolean;
}) {
  const [card] = useCardSubscription(cardId);

  const cardStats = useFirestoreGetDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  const history = useHistory();
  const popularity = (cardStats?.o || 0) / (cardStats?.count || 1);

  return (
    <div
      css={css`
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
          width: 420px;
        `}
      >
        <div
          css={css`
            height: 130px;
          `}
        >
          <div
            css={css`
              font-size: 16px;
              font-weight: 700;
              color: ${DATA_PLATFORM_RED_GRAY};
              align-self: flex-start;
            `}
          >
            {card?.hashtags &&
              card?.hashtags.map((hashtag, i) => (
                <span
                  style={{
                    marginRight: 5
                  }}
                  key={hashtag + i}
                >
                  {hashtag.replace(/\_/gi, " ")}
                </span>
              ))}
          </div>
          <p
            css={css`
              margin-top: 15px;
              font-size: 16px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
              line-height: 24px;
            `}
          >
            {card?.title.replaceAll("*", "")}
          </p>
        </div>
        <div
          css={css`
            display: flex;
            margin-top: 30px;
          `}
        >
          <MentionAgeChartForPortrait
            cardId={cardId}
            isAgree={isAgree}
            personHashtag={personHashtag}
            competitorHashtag={competitorHashtag}
          />
        </div>
        <span
          css={css`
            margin-top: 50px;
            align-self: flex-start;
            font-size: 16px;
            font-weight: 700;
            color: ${DATA_PLATFORM_RED_GRAY};
          `}
        >
          베스트 댓글
        </span>

        <MiniBestCommentsForPortrait cardId={cardId} truncate={55} />
      </div>
    </div>
  );
}
