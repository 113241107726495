/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";

import {
  OXO_GRAY900,
  SCAFFOLD_BACKGROUND_COLOR
} from "../../../constants/colors";

import { CardSchema } from "../../../constants/firestore_schema";
import HashTagTimeSeriesAnswersLineChart from "./HashTagTimeSeriesAnswersLineChart";
import { todayInKorea } from "../../../utils/datetime";

import Logo from "./img/Logo";
import line_chart_legend from "./img/line_chart_legend.png";

export default function HashTagTimeSeriesGraphChart({
  cards
}: {
  cards: CardSchema[];
}) {
  function getDateRange() {
    const recentCards = cards?.slice(-6);

    const beginningDate = todayInKorea(
      (recentCards || [])[0].featuredOnNewsTimelineAt
    );

    const endingDate = todayInKorea(
      (recentCards || [])[5].featuredOnNewsTimelineAt
    );

    const start = `${beginningDate.month}월 ${beginningDate.day}일`;
    const end = `${endingDate.month}월 ${endingDate.day}일`;

    return `${new Date().getFullYear()}년 ${start} ~ ${end}`;
  }

  return (
    <article
      css={css`
        padding: 30px 40px;
        background-color: #f9f9f9;
      `}
    >
      <header
        css={css`
          padding: 20px 0;
          font-size: 20px;
          font-weight: 400;
          color: ${OXO_GRAY900};
        `}
      >
        <span
          css={css`
            display: block;
            font-size: 30px;
            font-weight: 800;
            margin-bottom: 10px;
          `}
        >
          대통령실 가정통신문📝 옥소지수 : 최근 6주 추이
        </span>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <span>{getDateRange()} 옥소폴리틱스 제공</span>
          <span
            css={css`
              padding: 0 27px;
            `}
          >
            |
          </span>
          <span
            css={css`
              display: inline-flex;
              align-items: center;
            `}
          >
            <img
              css={css`
                width: 125px;
                height: 25px;
              `}
              src={line_chart_legend}
              alt="positive_lgend"
            />
          </span>
        </div>
      </header>
      <section
        css={css`
          padding: 0 50px;
          background-color: ${SCAFFOLD_BACKGROUND_COLOR};
          border: 2px solid #dedede;
          border-radius: 15px;
        `}
      >
        <figure
          css={css`
            margin-left: 50px;
          `}
        >
          <HashTagTimeSeriesAnswersLineChart cards={cards} />
        </figure>
      </section>
      <footer
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
        `}
      >
        <span
          css={css`
            font-size: 15px;
            font-weight: 400;
            color: ${OXO_GRAY900};
          `}
        >
          자료: 옥소폴리틱스
        </span>
        <Logo width={140} height={40} />
      </footer>
    </article>
  );
}
