import firebase from "firebase/compat";

import React, { CSSProperties, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  GRAY,
  GRAY_DARKER,
  GRAY_HASH_PAGE_BUTTON,
  TRIBE_INFO_BY_ID
} from "../../../../constants/enums";
import {
  CardAnswerSchema,
  CardType,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { addToCardIdStackUrl, useIsAdmin } from "../../../../utils/utils";
import MiniBestComments from "../Comment/MiniBestComments";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../../foundation/Contexts/DataPropsContext";
import useFirestoreLiveDocument, {
  useCardSubscription
} from "../../../../utils/hooks/useFirestoreLiveDocument";
import MiniHashtagCard from "../../Today/MiniHashtagCard";
import OXOText from "../../../utility/OXOText/OXOText";
import PhotoViewer from "../../../cards/CardComponents/PhotoViewer";
import CommentTitleForBubble from "./CommentTitleForBubble";
import CommentSpeaker from "./CommentSpeaker";
import NewsLink from "./NewsLink";
import CommentToolsLineForCommunity from "../Comment/CommentToolsLineForCommunity";
import MiniTodayTopicCardForToday from "../../Today/MiniTodayTopicCardForToday";
import HorizontalRuleForTimeline from "./HorizontalRuleForTimeline";

export default function HashCommentBubbleForCommunity({
  cardId,
  showDetails = true,
  style,
  widthMargin = 30,
  showHorizontalRule
}: {
  cardId: string;
  showDetails?: boolean;
  style?: CSSProperties;
  widthMargin?: number;
  showHorizontalRule?: boolean;
}) {
  const history = useHistory();
  const [windowWidth, windowHeight] = useWindowSize();
  const [card] = useCardSubscription(cardId);
  const isAdmin = useIsAdmin();

  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const [cardAnswer] = useFirestoreLiveDocument<CardAnswerSchema>(
    FirestoreCollection.CARD_ANSWERS,
    currentUserId + cardId
  );
  const answer = cardAnswer?.answer;

  if (!card || !cardAnswer) {
    return null;
  }

  const imageAvailable =
    card?.postMetadata?.photos && card?.postMetadata?.photos.length > 0;

  const trimmedTitle = card.title?.replace(/["“”]/g, "") || "";

  const speakerFromTitle = trimmedTitle.split(" ")[0];
  const speakers: string[] =
    (card.speakerHashTag && card.speakerHashTag.length > 0
      ? card.speakerHashTag?.map(speaker => speaker.replace("#", ""))
      : card.type === CardType.NEWS_TICKER
      ? speakerFromTitle && speakerFromTitle.split("・")
      : ["옥소"]) || [];

  const commentAfter: string = card.title;

  const commentBefore: string | undefined =
    card.type === CardType.DEBATE
      ? card.debateCommentMetadata?.comment
      : card.type === CardType.NEWS_TICKER
      ? trimmedTitle.slice(speakerFromTitle.length + 1)
      : trimmedTitle;

  const comment =
    card.type === CardType.NEWS_TICKER && card.newsTickerMetadata
      ? commentAfter
      : commentBefore;

  const shouldCommentsSideBySide = windowWidth >= 750 && showDetails;

  const bubbleMaxWidth = Math.min(
    375 - (shouldCommentsSideBySide ? widthMargin : 30),
    windowWidth - widthMargin
  );

  if (card.type === CardType.NEWS_TICKER && !card.displayToUsers && !isAdmin) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ...style
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          {speakers[0] === "사회자" ? (
            <div
              style={{
                display: "flex",
                padding: "13px 0px",
                justifyContent: "flex-end"
              }}
              key={cardId}
            >
              <div
                style={{
                  maxWidth: 250,
                  borderRadius: 14,
                  lineHeight: "130%",
                  backgroundColor: GRAY_DARKER,
                  marginTop: 5,
                  minHeight: 20,
                  display: "flex",
                  alignItems: "center",
                  color: "white"
                }}
              >
                {comment}
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "left",
                width: "100%",
                color: "black",
                backgroundColor:
                  card.type === CardType.NEWS_TICKER
                    ? "#EBEDED"
                    : card.type === CardType.TOPIC
                    ? "#EBEDED"
                    : card.type === CardType.POST
                    ? TRIBE_INFO_BY_ID[card.createdByTribeId].bgColor
                    : currentUserMetadata && currentUserMetadata.tribeId
                    ? TRIBE_INFO_BY_ID[currentUserMetadata.tribeId].bgColor
                    : GRAY_HASH_PAGE_BUTTON,
                position: "relative"
              }}
              key={cardId}
            >
              <div style={{ padding: "20px 20px 0", width: "100%" }}>
                <CommentSpeaker
                  cardId={cardId}
                  key={cardId}
                  color={"black"}
                  nameColor={"black"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                {card.type === CardType.TOPIC ? (
                  <div
                    style={{
                      width: "100%"
                    }}
                    key={cardId}
                  >
                    <MiniTodayTopicCardForToday key={cardId} cardId={cardId} />
                  </div>
                ) : card.type === CardType.HASHTAG_CARD ? (
                  <div style={{ marginTop: 10 }} key={cardId}>
                    <MiniHashtagCard
                      key={cardId}
                      cardId={cardId}
                      style={{
                        width: bubbleMaxWidth,
                        minWidth: bubbleMaxWidth
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        color: "black",
                        marginTop: 5,
                        position: "relative",
                        cursor: "pointer"
                      }}
                    >
                      <div style={{ padding: "0 20px", width: "100%" }}>
                        <CommentTitleForBubble
                          cardId={cardId}
                          color={"black"}
                        />
                      </div>
                      <div
                        className={"smallParagraph"}
                        style={{
                          color: "black",
                          fontSize: 14,
                          padding: "0 20px",
                          width: "100%"
                        }}
                      >
                        <div
                          onClick={e => {
                            firebase
                              .analytics()
                              .logEvent("v4_click_hash_comment_words", {
                                event_category: "hashcard",
                                event_label: card.title
                              });
                            e.stopPropagation();
                            history.push(
                              history.location.pathname +
                                addToCardIdStackUrl(
                                  history.location.search,
                                  cardId
                                )
                            );
                          }}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            fontSize: 15,
                            marginTop: 10,
                            paddingBottom: 10,
                            lineHeight: 1.7
                          }}
                        >
                          <OXOText
                            text={
                              card.postMetadata?.description ||
                              card.realNamePostMetadata?.description ||
                              ""
                            }
                            truncateChars={imageAvailable ? 70 : 150}
                            disableNewLine={false}
                            showTrimmedTextIcon={true}
                          />
                        </div>
                        <NewsLink cardId={cardId} />
                      </div>
                    </div>
                    {imageAvailable && card?.postMetadata?.photos && (
                      <div
                        style={{
                          width: Math.min(750, windowWidth),
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          cursor: "pointer",
                          padding: "0 20px 20px"
                        }}
                        key={cardId}
                      >
                        <PhotoViewer
                          photoUrls={card?.postMetadata?.photos}
                          height={windowWidth >= 750 ? 400 : windowWidth * 0.6}
                          borderRadius={"5px"}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <CommentToolsLineForCommunity cardId={cardId} card={card} />
        </div>
        <div>
          {card.comments > 0 ? (
            <div
              style={{
                cursor: "pointer",
                width: "100%"
              }}
              onClick={e => {
                firebase.analytics().logEvent("v4_click_hash_comment_words", {
                  event_category: "hashcard",
                  event_label: card.title
                });
                e.stopPropagation();
                history.push(
                  history.location.pathname +
                    addToCardIdStackUrl(
                      history.location.search,
                      cardId + "~tab:comments"
                    )
                );
              }}
            >
              <div
                style={{
                  fontSize: "13px",
                  color: GRAY,
                  padding: "10px 20px 2px",
                  textAlign: "left"
                }}
              >
                댓글 {card && card.comments}개 모두 보기
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 4,
                  padding: "0 20px",
                  width: "100%"
                }}
              >
                <MiniBestComments cardId={cardId} maxWidth={700} margin={40} />
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%"
              }}
            />
          )}
        </div>
      </div>
      {showHorizontalRule && <HorizontalRuleForTimeline />}
    </div>
  );
}
