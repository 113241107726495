/** @jsxImportSource @emotion/react */

import { CardSchema } from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, listToMap } from "../../../utils/utils";
import { css } from "@emotion/react";
import IssueCard from "./HashtagIssueCard";
import React, { useMemo, useState } from "react";
import OXOInfiniteScroll from "../../utility/OXOInfiniteScroll/OXOInfiniteScroll";
import ReactWordcloud, { CallbacksProp, OptionsProp } from "react-wordcloud";
import { useHistory } from "react-router";

const ISSUES_PER_PAGE = 5;
interface WordCloudDataSchema {
  text: string;
  value: number;
}

const WORD_CLOUD_OPTIONS: OptionsProp = {
  colors: ["#AC8DB6", "#CEA055", "#699BB0", "#B76E6C", "#AAAF83"],
  enableTooltip: false,
  fontFamily: "Noto Sans KR, sans-serif",
  fontSizes: [14, 30] as [number, number],
  fontWeight: "bold",
  deterministic: true,
  padding: 2,
  rotations: 0
};

export function PopularIssues({
  cardsByHashtags
}: {
  cardsByHashtags: { [p: string]: CardSchema[] };
}) {
  const [page, setPage] = useState<number>(1);
  const history = useHistory();

  const hashtagCounts: { [hashtag: string]: number } = listToMap(
    Object.keys(cardsByHashtags),
    hashtag => hashtag,
    hashtag => cardsByHashtags[hashtag].length
  );

  const sortedHashtags = Object.keys(cardsByHashtags).sort(
    (hashtagA, hashtagB) => hashtagCounts[hashtagB] - hashtagCounts[hashtagA]
  );

  const wordCloudData: WordCloudDataSchema[] = sortedHashtags.map(hashtag => {
    return {
      text: hashtag.replace(/_/gi, " "),
      value: cardsByHashtags[hashtag].length + 200
    };
  });

  const callbacks: CallbacksProp = useMemo(() => {
    return {
      onWordClick: (word: WordCloudDataSchema) => {
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(
              history.location.search,
              word.text.replace(/\s/g, "_")
            )
        );
      }
    };
  }, []);

  return (
    <div>
      <div
        css={css`
          height: 200px;
        `}
      >
        <ReactWordcloud
          words={wordCloudData.slice(0, 20)}
          callbacks={callbacks}
          options={WORD_CLOUD_OPTIONS}
        />
      </div>
      {sortedHashtags.slice(0, page * ISSUES_PER_PAGE).map(hashtag => (
        <IssueCard
          hashtag={hashtag}
          hashtagCards={cardsByHashtags[hashtag]}
          key={hashtag}
        />
      ))}
      <OXOInfiniteScroll setNextPage={() => setPage(p => p + 1)} />
    </div>
  );
}
