/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { range } from "lodash";
import { css } from "@emotion/react";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import { useHistory } from "react-router";
import { ROUTES } from "../../../constants/routes";

const yearRange = range(2021, new Date().getFullYear() + 1);

const pageCss = css`
  padding: 20px;
`;
export default function YearInReview() {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const history = useHistory();
  return (
    <div css={pageCss}>
      <Select
        value={year}
        onChange={selectYear =>
          setYear(parseInt(selectYear.target.value as string))
        }
      >
        {yearRange.map(yearOption => {
          return (
            <MenuItem value={yearOption} key={yearOption}>
              {yearOption}
            </MenuItem>
          );
        })}
      </Select>

      <div
        css={css`
          width: 200px;
          margin-top: 10px;
        `}
      >
        <OXOButton
          label={"보고서 출력"}
          onClick={() => {
            history.push(ROUTES.YEAR_IN_REVIEW_PUBLISH + "/" + year);
          }}
        />
      </div>
    </div>
  );
}
