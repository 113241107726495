import React, { useEffect, useState } from "react";
import { listToMap, multiGroupBy, parseDoc } from "../../../utils/utils";
import {
  CardSchema,
  CardsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";

export function hashtagGroupCardCount(
  hashtag: string,
  groupedHashtags: { [p: string]: string[] },
  cardsByHashtags: { [p: string]: CardSchema[] }
) {
  return groupedHashtags[hashtag]
    .map(hashtag => cardsByHashtags[hashtag].length)
    .reduce((a, b) => a + b);
}

export default function useGetHashtagHierarchy(hashtags: string[] | undefined) {
  const [hashtagCards, setHashtagCards] = useState<CardsSchema>();
  useEffect(() => {
    if (!hashtags) {
      return;
    }
    setHashtagCards(undefined);
    setGroupedHashtags(undefined);

    const hashtagCardPromises = hashtags
      .filter(hashtag => hashtag)
      .map(hashtag => {
        return firebase
          .firestore()
          .collection(FirestoreCollection.CARDS)
          .doc(hashtag)
          .get()
          .then(parseDoc);
      });

    Promise.all(hashtagCardPromises).then(hashtagCards => {
      setHashtagCards(
        listToMap(
          hashtagCards,
          hashtagCard => hashtagCard.id,
          hashtagCard => hashtagCard
        )
      );
    });
    return () => {};
  }, [hashtags]);

  // Group by higher hashtags
  const [groupedHashtags, setGroupedHashtags] =
    useState<{ [hashtag: string]: string[] }>();
  useEffect(() => {
    if (!hashtags || !hashtagCards) {
      return;
    }
    setGroupedHashtags(
      multiGroupBy(hashtags, hashtag => {
        if (hashtagCards && hashtagCards[hashtag]) {
          return (
            hashtagCards[hashtag].hashtags
              ?.map(hashtag => hashtag.replace("#", ""))
              .filter(hashtag => hashtag.length > 0) || [hashtag]
          );
        }
        return [hashtag];
      })
    );
  }, [hashtagCards, hashtags]);

  return groupedHashtags;
}
