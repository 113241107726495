import React from "react";
import { useHistory } from "react-router-dom";
import gif404 from "../../../img/airbnb404.gif";
import { GRAY_LIGHT, PRIMARY_COLOR } from "../../../constants/enums";
import { ROUTES } from "../../../constants/routes";

export default function InvalidRoute() {
  const history = useHistory();
  return (
    <div>
      <div
        className={"timeline_center_container"}
        style={{
          backgroundColor: "white",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p>
            <img src={gif404} alt={"Cannot find the page."} />
          </p>
          <p style={{ fontSize: "18px" }}>잘못된 주소입니다.</p>
          <p style={{ color: GRAY_LIGHT }}>In homage to the team Airbnb.</p>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              style={{
                width: "80%",
                height: "50px",
                backgroundColor: PRIMARY_COLOR,
                color: "white",
                fontWeight: "bold",
                border: 0,
                cursor: "pointer"
              }}
              onClick={() => {
                history.push(ROUTES.HOME);
              }}
            >
              OXOpolitics 홈으로 가기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
