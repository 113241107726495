/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { sumTwoAnswerCounts } from "../../../utils/utils";
import { CSS_TD } from "../DataExplorerView/shared";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";

export default function HashtagTimeSeriesGenderAnswersForCard({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerAgeGenderState] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    if (
      cardAnswerAgeGenderState.id &&
      cardAnswerAgeGenderState.id.length < 5 &&
      Object.keys(cardAnswerAgeGenderState).length < 2
    ) {
      return {};
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [gender: string]: {
        o: number;
        "?": number;
        x: number;
        count: number;
      };
    }>((prev, birthYear) => {
      let ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
      if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
        return prev;
      }

      if (ageGroupNumber > 60) {
        ageGroupNumber = 60;
      }

      const ageGroup = ageGroupNumber.toFixed();

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];
      if (birthYearGenderStat) {
        prev["male"] = sumTwoAnswerCounts(
          (prev["male"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }) as AnswerCountsSchema,
          (birthYearGenderStat["male"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }) as AnswerCountsSchema
        );

        prev["female"] = sumTwoAnswerCounts(
          prev["female"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          },
          birthYearGenderStat["female"] || {
            o: 0,
            x: 0,
            "?": 0,
            count: 0
          }
        ) as {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState]);

  if (!statsByAgeGroup) {
    return null;
  }

  return (
    <>
      {Object.keys(statsByAgeGroup).map(gender => {
        return (
          <td key={gender} css={CSS_TD}>
            {(
              ((statsByAgeGroup[gender]?.o || 0) /
                (statsByAgeGroup[gender]?.count || 1)) *
              100
            ).toFixed(1)}
            %
          </td>
        );
      })}
    </>
  );
}
