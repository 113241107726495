/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";
import {
  AnswerCountSchema,
  CardAnswerJurisdictionStatItemSchema,
  CardAnswerJurisdictionStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { css } from "@emotion/react";
import {
  mapValues,
  parseDoc,
  sumTwoAnswerCounts
} from "../../../../utils/utils";
import firebase from "firebase/compat";

const DISTRICT_GROUPS: { [name: string]: string[] } = {
  서울: ["서울특별시"],
  경인권: ["인천광역시", "경기도"],
  수도권: ["서울특별시", "인천광역시", "경기도"],
  충청권: ["충청남도", "충청북도", "대전광역시", "세종특별자치시"],
  호남권: ["전라북도", "전라남도", "광주광역시"],
  경북권: ["경상북도", "대구광역시"],
  경남권: ["경상남도", "부산광역시", "울산광역시"],
  제주도: ["제주특별자치도"],
  강원권: ["강원도"]
};

function getFirstNonSingularLevel(
  data: { [p: string]: CardAnswerJurisdictionStatItemSchema } | undefined
): { [p: string]: CardAnswerJurisdictionStatItemSchema } | undefined {
  if (!data) {
    return;
  }
  if (Object.keys(data).length === 1) {
    return getFirstNonSingularLevel(data[Object.keys(data)[0]].next);
  } else {
    return data;
  }
}

export default function DistrictGroupResultsTable({
  cardId
}: {
  cardId: string;
}) {
  const [districtGroupResultState, setDistrictGroupResultState] = useState<{
    [name: string]: AnswerCountSchema;
  }>({});

  const cardAnswerJurisdictionStats =
    useFirestoreGetDocument<CardAnswerJurisdictionStatsSchema>(
      FirestoreCollection.CARD_ANSWER_JURISDICTION_STATS,
      cardId
    );

  useEffect(() => {
    if (!cardAnswerJurisdictionStats?.jurisdictionStats) {
      return;
    }

    const topLevelData = getFirstNonSingularLevel(
      cardAnswerJurisdictionStats?.jurisdictionStats.next
    );

    if (!topLevelData) {
      return;
    }

    const districtNames = mapValues(topLevelData, async (stat, cardId) => {
      return await firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(cardId)
        .get()
        .then(parseDoc)
        .then((card: CardSchema) => {
          return card.title;
        });
    });

    const districtGroupResult: { [name: string]: AnswerCountSchema } = {};
    Promise.all(
      Object.keys(topLevelData).map(async cardId => {
        const districtName = await districtNames[cardId];

        Object.keys(DISTRICT_GROUPS).forEach(districtGroupName => {
          if (DISTRICT_GROUPS[districtGroupName].includes(districtName)) {
            if (!districtGroupResult[districtGroupName]) {
              districtGroupResult[districtGroupName] = {
                o: 0,
                "?": 0,
                x: 0,
                count: 0
              };
            }

            const sum = sumTwoAnswerCounts(
              districtGroupResult[districtGroupName],
              topLevelData[cardId].answerCounts
            );

            sum.count = sum.o + sum.x + sum["?"];
            districtGroupResult[districtGroupName] = sum;
          }
        });
      })
    ).then(() => {
      setDistrictGroupResultState(districtGroupResult);
    });
  }, [
    cardAnswerJurisdictionStats,
    cardAnswerJurisdictionStats?.jurisdictionStats,
    cardAnswerJurisdictionStats?.jurisdictionStats?.next
  ]);

  return (
    <table
      css={css`
        width: 500px;
      `}
    >
      <tr>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          광역자치도
        </td>

        <td
          css={css`
            font-weight: bold;
          `}
        >
          O
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          △
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          X
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          응답
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          O
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          △
        </td>
        <td
          css={css`
            font-weight: bold;
          `}
        >
          X
        </td>
      </tr>
      {Object.keys(districtGroupResultState).map(district => {
        const jurisdictionStats = districtGroupResultState[district];
        return (
          <tr key={district}>
            <td>{district}</td>

            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {jurisdictionStats["o"] || 0}
            </td>
            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {jurisdictionStats["?"] || 0}
            </td>
            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {jurisdictionStats["x"] || 0}
            </td>
            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {jurisdictionStats["count"] || 0}
            </td>

            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {(
                ((jurisdictionStats["o"] || 0) /
                  (jurisdictionStats["count"] || 1)) *
                100
              ).toFixed(1)}
              %
            </td>
            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {(
                ((jurisdictionStats["?"] || 0) /
                  (jurisdictionStats["count"] || 1)) *
                100
              ).toFixed(1)}
              %
            </td>
            <td
              css={css`
                padding: 0 5px;
              `}
            >
              {(
                ((jurisdictionStats["x"] || 0) /
                  (jurisdictionStats["count"] || 1)) *
                100
              ).toFixed(1)}
              %
            </td>
          </tr>
        );
      })}
    </table>
  );
}
