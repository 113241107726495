/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import firebase from "firebase/compat";
import {
  AssetBalanceSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { getDocsFromSnapshot } from "../../../utils/utils";
import { css } from "@emotion/react";

const POLITICIAN_FOR_ONE_PAGE = 10;
export const BUTTON_CSS = css`
  cursor: pointer;
  padding: 10px;
  border: solid 1px black;
  border-radius: 20px;
  margin: 10px;
  flex-shrink: 0;
`;

const ACTIVE_BUTTON_CSS = css`
  background-color: #2f80ed;
  color: white;
`;

export default function PoldiSelector({
  poldi,
  setPoldi
}: {
  poldi: string | undefined;
  setPoldi: (name: string) => void;
}) {
  const [page, setPage] = useState<number>(1);
  const [assetBalances, setAssetBalances] = useState<AssetBalanceSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.ASSET_BALANCES)
      .where("isPoliticianDirectorPolitician", "==", true)
      .where("isActive", "==", true)
      .limit(POLITICIAN_FOR_ONE_PAGE * page)
      .orderBy("amountMicros", "asc")
      .get()
      .then(getDocsFromSnapshot)
      .then(setAssetBalances);
  }, [page]);

  if (!assetBalances) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
      `}
    >
      {assetBalances.map(assetBalance => {
        return (
          <div
            css={
              poldi === assetBalance.assetType
                ? css`
                    ${BUTTON_CSS};
                    ${ACTIVE_BUTTON_CSS};
                  `
                : BUTTON_CSS
            }
            key={assetBalance.id}
            onClick={() => {
              setPoldi(assetBalance.assetType);
            }}
          >
            {assetBalance.assetType}
          </div>
        );
      })}
      <div
        css={BUTTON_CSS}
        onClick={() => {
          setPage(p => p + 1);
        }}
      >
        next &gt;&gt;
      </div>
    </div>
  );
}
