import firebase from "firebase/compat";
import TimeAgo from "javascript-time-ago";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BACKGROUND_COLOR,
  GRAY,
  TRIBE_INFO_BY_ID,
  UserAnswer
} from "../../../../constants/enums";
import {
  CardSchema,
  CommentSchema,
  FirestoreCollection,
  ReferenceType,
  UserPrivilege,
  UserProfileMetadataSchema
} from "../../../../constants/firestore_schema";
import blockOx from "../../../../img/ox/oxoxo.svg";
import {
  addToCardIdStackUrl,
  parseDoc,
  removeIdKey,
  truncateLongSentence
} from "../../../../utils/utils";
import CommentControls from "../../../cards/DetailViews/CommentControls";
import UserHeadAndName from "../../../cards/MiniCards/UserHeadAndName";
import LikeButton from "../../../foundation/OXOComment/LikeButton";
import LikeStatsByRow from "../../../foundation/OXOComment/LikeStatsByRow";
import DocumentSkeleton from "../../../utility/OXOLoader/DocumentSkeleton";
import OXOText from "../../../utility/OXOText/OXOText";
import { TribeExpression } from "../../../../constants/expressions";
import { isBadContents } from "../../../../utils/contents";

export default function CommentCard({
  commentId,
  showOriginalQuestion = false,
  showFullText = false,
  externalComment,
  disableExpressions = false
}: {
  commentId: string;
  showOriginalQuestion?: boolean;
  showFullText?: boolean;
  externalComment?: CommentSchema;
  disableExpressions?: boolean;
}) {
  const [comment, setComment] = useState<CommentSchema | undefined>(
    externalComment
  );
  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();

  const history = useHistory();

  const [hideBadComment, setHideBadComment] = useState<boolean>(false);
  const [originalCard, setOriginalCard] = useState<CardSchema>();

  useEffect(() => {
    if (comment && comment.questionId && showOriginalQuestion) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(comment.questionId)
        .onSnapshot(snapshot => {
          setOriginalCard(parseDoc(snapshot));
        });

      return () => {
        unsubscribe();
      };
    }
  }, [comment]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.COMMENTS)
      .doc(commentId)
      .onSnapshot(snapshot => {
        if (snapshot.exists) {
          const comment = parseDoc(snapshot);

          if (comment) {
            setComment(comment);
            setHideBadComment(!!comment.bad || isBadContents(comment.comment));
          }
        }
      });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (comment && comment.userId) {
      const unsubscribeUserProfileMetadata = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(comment.userId)
        .onSnapshot(snapshot =>
          setUserProfileMetadata(removeIdKey(parseDoc(snapshot)))
        );

      return () => {
        unsubscribeUserProfileMetadata();
      };
    }
  }, [comment]);

  if (!comment) {
    return (
      <div
        style={{
          width: showFullText ? "100%" : "300px",
          minWidth: "300px",
          minHeight: "423px",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "white",
          position: "relative",
          alignItems: "center"
        }}
      >
        <DocumentSkeleton />
      </div>
    );
  }

  const timeAgo = new TimeAgo("ko");

  return (
    <div
      style={{
        width: showFullText ? "100%" : "300px",
        minWidth: "300px",
        minHeight: "423px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor:
          comment.isSpecialUser &&
          userProfileMetadata &&
          userProfileMetadata.privileges &&
          userProfileMetadata.privileges.includes(UserPrivilege.REAL_NAME_USER)
            ? BACKGROUND_COLOR
            : comment.userTribeId
            ? TRIBE_INFO_BY_ID[comment.userTribeId].bgColor
            : "black",
        position: "relative"
      }}
      key={comment.id}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px"
        }}
      >
        {userProfileMetadata && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserHeadAndName
              userId={comment.userId}
              expression={
                disableExpressions
                  ? TribeExpression.NORMAL
                  : comment.answer === UserAnswer.O
                  ? TribeExpression.HAPPY
                  : comment.answer === UserAnswer.X
                  ? TribeExpression.ANGRY
                  : TribeExpression.NORMAL
              }
            />
            {/* <SupporterPercentage cardId={comment.userId} /> */}
          </div>
        )}

        <div
          style={{
            marginBottom: 8
          }}
        >
          <CommentControls comment={comment} />
        </div>
      </div>

      <div
        key={comment.id}
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            textAlign: "left",
            padding: "20px 20px"
          }}
        >
          {showOriginalQuestion && originalCard && (
            <div
              style={{
                textAlign: "left",
                fontWeight: "bold",
                lineHeight: "160%",
                paddingBottom: "20px",
                cursor: "pointer"
              }}
              onClick={() => {
                history.push(
                  history.location.pathname +
                    addToCardIdStackUrl(
                      history.location.search,
                      originalCard?.id
                    )
                );
              }}
            >
              {truncateLongSentence(originalCard.title, 80)}
            </div>
          )}
          <div>
            {hideBadComment ? (
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column"
                }}
                onClick={() => setHideBadComment(false)}
              >
                <div>
                  <img src={blockOx} width={70} alt="blockOx" />
                </div>
                <div style={{ marginLeft: "5px" }}>
                  욕설이나 비하가 포함된 의견입니다. 클릭하시면 내용을 보실 수
                  있습니다.
                </div>
              </div>
            ) : (
              <div
                style={{
                  lineHeight: 1.7,
                  fontSize: "16px"
                }}
              >
                {showFullText ? (
                  <OXOText text={comment.comment} />
                ) : (
                  <OXOText
                    text={comment.comment}
                    truncateChars={showOriginalQuestion ? 130 : 160}
                    disableNewLine
                  />
                )}
              </div>
            )}
          </div>
          <div
            style={{
              color: GRAY,
              fontSize: "12px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div style={{ paddingBottom: 6 }}>
              {timeAgo.format(new Date(comment.createdAt))}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 18,
          padding: "10px 20px 30px"
        }}
      >
        <div />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 6 }}>
            <LikeStatsByRow referenceId={comment.id} />
          </div>
          {comment.userTribeId && (
            <>
              <LikeButton
                referenceId={comment.id}
                referenceCreatedAt={comment.createdAt}
                referenceType={ReferenceType.COMMENT}
                referenceUserId={comment.userId}
                referenceUserTribeId={comment.userTribeId}
                referenceReferenceId={comment.questionId}
                likeButtonSize={20}
                likeCountSize={22}
                left={0}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
