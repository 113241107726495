/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { ApprovalRateLineChart } from "./ApprovalRateLineChart";
import {
  CardAnswerStatsTimeSeriesSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { DAYS } from "../../../utils/datetime";
import { getDocsFromSnapshot } from "../../../utils/utils";

export function ApprovalRateChartForHashtag({ hashtag }: { hashtag: string }) {
  const [dailyStats, setDailyStats] =
    useState<CardAnswerStatsTimeSeriesSchema[]>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARD_ANSWER_STATS_TIME_SERIES)
      .where("cardId", "==", hashtag)
      .where("createdAt", ">=", new Date().valueOf() - 7 * DAYS)
      .orderBy("createdAt", "desc")
      .get()
      .then(getDocsFromSnapshot)
      .then(setDailyStats);
    return () => {};
  }, [hashtag]);

  if (!dailyStats || dailyStats.length < 2) {
    return null;
  }

  return <ApprovalRateLineChart dailyStats={dailyStats} />;
}
