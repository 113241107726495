import React from "react";

export default function MiniO({
  fill = "#ABD9A6",
  width = 19,
  height = 19
}: {
  fill?: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.63636C7.00831 8.63636 8.63636 7.00831 8.63636 5C8.63636 2.99169 7.00831 1.36364 5 1.36364C2.99169 1.36364 1.36364 2.99169 1.36364 5C1.36364 7.00831 2.99169 8.63636 5 8.63636ZM5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
        fill={fill}
      />
    </svg>
  );
}
