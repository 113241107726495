import React, { useEffect, useState } from "react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import {
  getMostSupportingTribe,
  parseDoc,
  removeIdKey
} from "../../../../utils/utils";
import UserProfilePhoto from "../../StatsView/UserProfilePhoto";
import { TribeExpression } from "../../../../constants/expressions";

export default function SupportingTribeIcon({
  cardId,
  size = 28
}: {
  cardId: string;
  size?: number;
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerTribeStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats) {
    return null;
  }

  const topTribeId = getMostSupportingTribe(cardAnswerStats);

  return (
    <div>
      {topTribeId !== "0" && (
        <UserProfilePhoto
          tribeId={topTribeId}
          width={size + "px"}
          expression={TribeExpression.HAPPY}
        />
      )}
    </div>
  );
}
