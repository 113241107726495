import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { addToCardIdStackUrl, parseDoc } from "../../../utils/utils";
import MiniAnswerTag from "../../topic/Question/Comments/MiniAnswerTag";

export default function AnswerRow({
  cardId,
  currentUserAnswer,
  profileUserAnswer
}: {
  cardId: string;
  currentUserAnswer: string;
  profileUserAnswer: string;
}) {
  const history = useHistory();
  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .get()
      .then(parseDoc)
      .then(setCard);
  }, [cardId]);

  const [windowWidth, windowHeight] = useWindowSize();

  if (!card || !card.title) {
    return null;
  }

  return (
    <tr>
      <td style={{ padding: 5, textAlign: "left" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              cursor: "pointer",
              marginLeft: 5,
              fontSize: 14,
              width: windowWidth >= 750 ? 400 : windowWidth - 175
            }}
            onClick={() => {
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(history.location.search, cardId)
              );
            }}
          >
            {card.title}
          </div>
        </div>
      </td>
      <td width={24} style={{ padding: 5, verticalAlign: "top" }}>
        <div>
          <MiniAnswerTag
            answer={profileUserAnswer}
            style={{
              padding: "2px 0px 1px",
              fontSize: 10,
              color: "black",
              fontWeight: "normal"
            }}
          />
        </div>
      </td>
      <td width={24} style={{ padding: 5, verticalAlign: "top" }}>
        <div>
          <MiniAnswerTag
            answer={currentUserAnswer}
            style={{
              padding: "2px 0px 1px",
              fontSize: 10,
              color: "black",
              fontWeight: "normal"
            }}
          />
        </div>
      </td>
    </tr>
  );
}
