import React, { CSSProperties } from "react";
import { GRAY_DARKER } from "../../../constants/enums";

export default function OXOPill({
  onClick,
  text,
  style = {}
}: {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  text: string;
  style?: CSSProperties;
}) {
  return (
    <div
      onClick={e => onClick(e)}
      style={{
        fontSize: "12px",
        color: "white",
        backgroundColor: GRAY_DARKER,
        border: "solid 1px " + GRAY_DARKER,
        cursor: "pointer",
        borderRadius: "20px",
        padding: "2px 5px 1px 5px",
        marginLeft: "5px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        ...style
      }}
    >
      <div>{text}</div>
    </div>
  );
}
