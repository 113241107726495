/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { CardSchema } from "../../../constants/firestore_schema";
import { addToCardIdStackUrl, onlyUnique } from "../../../utils/utils";
import HashtagMiniFaces from "../Today/HashtagMiniFaces";
import { HashtagPeriod } from "./HashtagPeriod";
import { ApprovalRateChartForHashtag } from "../Today/ApprovalRateChartForHashtag";
import HashCommentBubbleHighlight from "../HashCardView/common/HashCommentBubbleHighlight";
import { useHistory } from "react-router";

export default function HashtagIssueCard({
  hashtag,
  hashtagCards
}: {
  hashtag: string;
  hashtagCards: CardSchema[];
}) {
  const history = useHistory();
  const speakers = hashtagCards
    .flatMap(
      card =>
        card.speakerHashTag
          ?.filter(t => t.length > 0)
          .map(t => {
            return t.replace("#", "");
          }) || []
    )
    .filter(onlyUnique);

  const topCommentCard = hashtagCards.sort(
    (cardA, cardB) => cardB.comments - cardA.comments
  )[0];

  return (
    <div
      key={hashtag}
      css={css`
        font-size: 0.9em;
        padding: 10px;
        border-radius: 20px;
        border: solid 1px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        width: 100%;
      `}
      onClick={() => {
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, hashtag)
        );
      }}
    >
      <div
        css={css`
          text-align: left;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            font-weight: bold;
          `}
        >
          {hashtag.replace(/_/g, " ")}
        </div>
        <ApprovalRateChartForHashtag hashtag={hashtag} />
        <HashtagPeriod hashtag={hashtag} />
      </div>
      <HashtagMiniFaces hashtags={speakers.slice(0, 12)} cards={hashtagCards} />
      {topCommentCard && (
        <HashCommentBubbleHighlight cardId={topCommentCard.id} />
      )}
    </div>
  );
}
