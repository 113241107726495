import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import {
  GRAY,
  GRAY_EXTRA_DARK,
  TRIBE_INFO_BY_ID
} from "../../../constants/enums";
import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { parseDoc, removeIdKey } from "../../../utils/utils";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";

export default function UserLikeStats({
  center = true,
  userId
}: {
  center?: boolean;
  userId: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [userProfileMetadata, setUserProfileMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(userId)
        .onSnapshot(snapshot =>
          setUserProfileMetadata(removeIdKey(parseDoc(snapshot)))
        );
      return () => unsubscribe();
    }
  }, [userId]);

  if (!userProfileMetadata) {
    return <DocumentSkeleton />;
  }

  let maxTribeId = "1";
  let maxCount = 0;
  userProfileMetadata.countTribeLikes &&
    Object.keys(userProfileMetadata.countTribeLikes).forEach(tribeId => {
      if (userProfileMetadata.countTribeLikes[tribeId] > maxCount) {
        maxCount = userProfileMetadata.countTribeLikes[tribeId];
        maxTribeId = tribeId;
      }
    });

  return (
    <div>
      <div
        style={{
          marginBottom: "15px",
          textAlign: "center",
          fontWeight: "bold",
          color: GRAY_EXTRA_DARK,
          lineHeight: "150%"
        }}
      >
        {`${userProfileMetadata.countLikes || 0}번의 공감을 받았습니다.`}

        {maxCount > 0 && (
          <span>
            <br />
            공감한 사람 중{" "}
            {((maxCount / userProfileMetadata.countLikes) * 100).toFixed(
              1
            )}%가{" "}
            {maxTribeId === "rogue" || !maxTribeId
              ? "방랑자"
              : TRIBE_INFO_BY_ID[maxTribeId].name}{" "}
            부족이었습니다.
          </span>
        )}
      </div>
      <div
        style={{
          color: GRAY,
          display: "flex",
          justifyContent: "center",
          maxWidth: "300px",
          margin: center ? "0 auto" : "0"
        }}
      >
        {[...Object.keys(TRIBE_INFO_BY_ID)].map((tribeId, i) => {
          return (
            <div
              style={{
                ...{ textAlign: "center" },
                ...(i === 0 ? {} : { marginLeft: "5px" })
              }}
              key={tribeId}
            >
              <div
                style={{
                  backgroundColor: TRIBE_INFO_BY_ID[tribeId].color,
                  color: "white",
                  borderRadius: "50%",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 30,
                  height: 30,
                  fontWeight: "bold"
                }}
              >
                {(userProfileMetadata.countTribeLikes &&
                  userProfileMetadata.countTribeLikes[tribeId]) ||
                  0}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
