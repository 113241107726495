/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { formatKoreanDateIso, todayInKorea } from "../../../utils/datetime";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import LargeCardBox from "../../foundation/CardBox/LargeCardBox.styled";
import OXOText from "../../utility/OXOText/OXOText";
import CardAnswerTribeChart from "../HashCardView/Comment/CardAnswerTribeChart";
import MentionAgeChart from "./MentionAgeChart";
import MentionStatistics from "./MentionStatistics";
import MentionStatsGenderAgeChart from "./MentionStatsGenderAgeChart";
import StatisticsPosition from "./StatisticsPosition";

export default function MentionAnalysis({ cardId }: { cardId: string }) {
  const card = useFirestoreGetDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  const isoDate = formatKoreanDateIso(todayInKorea(card?.createdAt));

  const [year, month, day] = isoDate.split("-");

  if (!card) {
    return null;
  }

  return (
    <LargeCardBox css={css``}>
      <div
        css={css`
          width: 100%;
          height: 150px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        `}
      >
        <div
          css={css`
            font-size: 16px;
          `}
        >
          <span
            css={css`
              font-weight: 700;
              padding-right: 10px;
            `}
          >
            발언자
          </span>
          <span>{card?.speakerHashTag?.[0].replace("#", "")}</span>
        </div>
        <div
          css={css`
            padding-top: 10px;
            p {
              text-align: left;
              color: #515151;
            }
          `}
        >
          <OXOText text={card?.title} />
        </div>
        <div
          css={css`
            color: #515151;
            font-size: 12px;
          `}
        >
          옥소 게시 일시 {`${year}년 ${month}월 ${day}일`}
        </div>
      </div>

      <div
        css={css`
          margin-bottom: 50px;
        `}
      >
        <div
          css={css`
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
          `}
        >
          찬반 여론 보기
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <MentionStatistics cardId={cardId} />
        </div>

        <div
          css={css`
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
          `}
        >
          <div>연령별 비교</div> <StatisticsPosition />
        </div>
        <div style={{ paddingTop: 60 }}>
          <MentionAgeChart cardId={cardId} />
        </div>

        <div
          css={css`
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
          `}
        >
          <div>성별 비교</div> <StatisticsPosition />
        </div>
        <div style={{ paddingTop: 60 }}>
          <MentionStatsGenderAgeChart cardId={cardId} />
        </div>

        <div
          css={css`
            width: 100%;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
            margin-top: 40px;
          `}
        >
          부족별 비교
        </div>
        <div
          css={css`
            padding-top: 20px;
          `}
        >
          <CardAnswerTribeChart cardId={cardId} />
        </div>
      </div>
    </LargeCardBox>
  );
}
