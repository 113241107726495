import React, { useContext } from "react";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import UserProfilePostsTimeline from "../OXOUserProfile/UserProfilePostsTimeline";

export default function MyPostForProfile() {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;
  return (
    <div>
      {currentUserId && (
        <div style={{ width: "100%" }}>
          <UserProfilePostsTimeline profileUserId={currentUserId} />
        </div>
      )}
    </div>
  );
}
