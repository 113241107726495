export const KOREAN_DISTRICTS = [
  { address: "서울 종로구 일원", district: "종로구" },
  { address: "서울 성동구 왕십리2동", district: "중구성동구 갑" },
  { address: "서울 성동구 왕십리도선동", district: "중구성동구 갑" },
  { address: "서울 성동구 마장동", district: "중구성동구 갑" },
  { address: "서울 성동구 사근동", district: "중구성동구 갑" },
  { address: "서울 성동구 행당1동", district: "중구성동구 갑" },
  { address: "서울 성동구 행당2동", district: "중구성동구 갑" },
  { address: "서울 성동구 응봉동", district: "중구성동구 갑" },
  { address: "서울 성동구 성수1가1동", district: "중구성동구 갑" },
  { address: "서울 성동구 성수1가2동", district: "중구성동구 갑" },
  { address: "서울 성동구 성수2가1동", district: "중구성동구 갑" },
  { address: "서울 성동구 성수2가3동", district: "중구성동구 갑" },
  { address: "서울 성동구 송정동", district: "중구성동구 갑" },
  { address: "서울 성동구 용답동", district: "중구성동구 갑" },
  { address: "서울 성동구 금호1가동", district: "중구성동구 을" },
  { address: "서울 성동구 금호2·3가동", district: "중구성동구 을" },
  { address: "서울 성동구 금호4가동", district: "중구성동구 을" },
  { address: "서울 성동구 옥수동", district: "중구성동구 을" },
  { address: "서울 중구 일원", district: "중구성동구 을" },
  { address: "서울 용산구 일원", district: "용산구" },
  { address: "서울 광진구 중곡1동", district: "광진구 갑" },
  { address: "서울 광진구 중곡2동", district: "광진구 갑" },
  { address: "서울 광진구 중곡3동", district: "광진구 갑" },
  { address: "서울 광진구 중곡4동", district: "광진구 갑" },
  { address: "서울 광진구 능동", district: "광진구 갑" },
  { address: "서울 광진구 구의2동", district: "광진구 갑" },
  { address: "서울 광진구 광장동", district: "광진구 갑" },
  { address: "서울 광진구 군자동", district: "광진구 갑" },
  { address: "서울 광진구 구의1동", district: "광진구 을" },
  { address: "서울 광진구 구의3동", district: "광진구 을" },
  { address: "서울 광진구 자양1동", district: "광진구 을" },
  { address: "서울 광진구 자양2동", district: "광진구 을" },
  { address: "서울 광진구 자양3동", district: "광진구 을" },
  { address: "서울 광진구 자양4동", district: "광진구 을" },
  { address: "서울 광진구 화양동", district: "광진구 을" },
  { address: "서울 동대문구 용신동", district: "동대문구 갑" },
  { address: "서울 동대문구 제기동", district: "동대문구 갑" },
  { address: "서울 동대문구 청량리동", district: "동대문구 갑" },
  { address: "서울 동대문구 회기동", district: "동대문구 갑" },
  { address: "서울 동대문구 휘경1동", district: "동대문구 갑" },
  { address: "서울 동대문구 휘경2동", district: "동대문구 갑" },
  { address: "서울 동대문구 이문1동", district: "동대문구 갑" },
  { address: "서울 동대문구 이문2동", district: "동대문구 갑" },
  { address: "서울 동대문구 전농1동", district: "동대문구 을" },
  { address: "서울 동대문구 전농2동", district: "동대문구 을" },
  { address: "서울 동대문구 답십리1동", district: "동대문구 을" },
  { address: "서울 동대문구 답십리2동", district: "동대문구 을" },
  { address: "서울 동대문구 장안1동", district: "동대문구 을" },
  { address: "서울 동대문구 장안2동", district: "동대문구 을" },
  { address: "서울 중랑구 면목본동", district: "중랑구 갑" },
  { address: "서울 중랑구 면목2동", district: "중랑구 갑" },
  { address: "서울 중랑구 면목3·8동", district: "중랑구 갑" },
  { address: "서울 중랑구 면목4동", district: "중랑구 갑" },
  { address: "서울 중랑구 면목5동", district: "중랑구 갑" },
  { address: "서울 중랑구 면목7동", district: "중랑구 갑" },
  { address: "서울 중랑구 상봉2동", district: "중랑구 갑" },
  { address: "서울 중랑구 망우3동", district: "중랑구 갑" },
  { address: "서울 중랑구 상봉1동", district: "중랑구 을" },
  { address: "서울 중랑구 중화1동", district: "중랑구 을" },
  { address: "서울 중랑구 중화2동", district: "중랑구 을" },
  { address: "서울 중랑구 묵1동", district: "중랑구 을" },
  { address: "서울 중랑구 묵2동", district: "중랑구 을" },
  { address: "서울 중랑구 망우본동", district: "중랑구 을" },
  { address: "서울 중랑구 신내1동", district: "중랑구 을" },
  { address: "서울 중랑구 신내2동", district: "중랑구 을" },
  { address: "서울 성북구 성북동", district: "성북구 갑" },
  { address: "서울 성북구 삼선동", district: "성북구 갑" },
  { address: "서울 성북구 동선동", district: "성북구 갑" },
  { address: "서울 성북구 돈암2동", district: "성북구 갑" },
  { address: "서울 성북구 안암동", district: "성북구 갑" },
  { address: "서울 성북구 보문동", district: "성북구 갑" },
  { address: "서울 성북구 정릉1동", district: "성북구 갑" },
  { address: "서울 성북구 정릉2동", district: "성북구 갑" },
  { address: "서울 성북구 정릉3동", district: "성북구 갑" },
  { address: "서울 성북구 정릉4동", district: "성북구 갑" },
  { address: "서울 성북구 길음1동", district: "성북구 갑" },
  { address: "서울 성북구 돈암1동", district: "성북구 을" },
  { address: "서울 성북구 길음2동", district: "성북구 을" },
  { address: "서울 성북구 종암동", district: "성북구 을" },
  { address: "서울 성북구 월곡1동", district: "성북구 을" },
  { address: "서울 성북구 월곡2동", district: "성북구 을" },
  { address: "서울 성북구 장위1동", district: "성북구 을" },
  { address: "서울 성북구 장위2동", district: "성북구 을" },
  { address: "서울 성북구 장위3동", district: "성북구 을" },
  { address: "서울 성북구 석관동", district: "성북구 을" },
  { address: "서울 강북구 번1동", district: "강북구 갑" },
  { address: "서울 강북구 번2동", district: "강북구 갑" },
  { address: "서울 강북구 수유1동", district: "강북구 갑" },
  { address: "서울 강북구 수유2동", district: "강북구 갑" },
  { address: "서울 강북구 수유3동", district: "강북구 갑" },
  { address: "서울 강북구 우이동", district: "강북구 갑" },
  { address: "서울 강북구 인수동", district: "강북구 갑" },
  { address: "서울 강북구 삼양동", district: "강북구 을" },
  { address: "서울 강북구 미아동", district: "강북구 을" },
  { address: "서울 강북구 송중동", district: "강북구 을" },
  { address: "서울 강북구 송천동", district: "강북구 을" },
  { address: "서울 강북구 삼각산동", district: "강북구 을" },
  { address: "서울 강북구 번3동", district: "강북구 을" },
  { address: "서울 도봉구 쌍문1동", district: "도봉구 갑" },
  { address: "서울 도봉구 쌍문3동", district: "도봉구 갑" },
  { address: "서울 도봉구 창1동", district: "도봉구 갑" },
  { address: "서울 도봉구 창2동", district: "도봉구 갑" },
  { address: "서울 도봉구 창3동", district: "도봉구 갑" },
  { address: "서울 도봉구 창4동", district: "도봉구 갑" },
  { address: "서울 도봉구 창5동", district: "도봉구 갑" },
  { address: "서울 도봉구 쌍문2동", district: "도봉구 을" },
  { address: "서울 도봉구 쌍문4동", district: "도봉구 을" },
  { address: "서울 도봉구 방학1동", district: "도봉구 을" },
  { address: "서울 도봉구 방학2동", district: "도봉구 을" },
  { address: "서울 도봉구 방학3동", district: "도봉구 을" },
  { address: "서울 도봉구 도봉1동", district: "도봉구 을" },
  { address: "서울 도봉구 도봉2동", district: "도봉구 을" },
  { address: "서울 노원구 월계1동", district: "노원구 갑" },
  { address: "서울 노원구 월계2동", district: "노원구 갑" },
  { address: "서울 노원구 월계3동", district: "노원구 갑" },
  { address: "서울 노원구 공릉1동", district: "노원구 갑" },
  { address: "서울 노원구 공릉2동", district: "노원구 갑" },
  { address: "서울 노원구 하계1동", district: "노원구 을" },
  { address: "서울 노원구 하계2동", district: "노원구 을" },
  { address: "서울 노원구 중계본동", district: "노원구 을" },
  { address: "서울 노원구 중계1동", district: "노원구 을" },
  { address: "서울 노원구 중계2·3동", district: "노원구 을" },
  { address: "서울 노원구 중계4동", district: "노원구 을" },
  { address: "서울 노원구 상계6·7동", district: "노원구 을" },
  { address: "서울 노원구 상계1동", district: "노원구 병" },
  { address: "서울 노원구 상계2동", district: "노원구 병" },
  { address: "서울 노원구 상계3·4동", district: "노원구 병" },
  { address: "서울 노원구 상계5동", district: "노원구 병" },
  { address: "서울 노원구 상계8동", district: "노원구 병" },
  { address: "서울 노원구 상계9동", district: "노원구 병" },
  { address: "서울 노원구 상계10동", district: "노원구 병" },
  { address: "서울 은평구 녹번동", district: "은평구 갑" },
  { address: "서울 은평구 응암1동", district: "은평구 갑" },
  { address: "서울 은평구 응암2동", district: "은평구 갑" },
  { address: "서울 은평구 응암3동", district: "은평구 갑" },
  { address: "서울 은평구 역촌동", district: "은평구 갑" },
  { address: "서울 은평구 신사1동", district: "은평구 갑" },
  { address: "서울 은평구 신사2동", district: "은평구 갑" },
  { address: "서울 은평구 증산동", district: "은평구 갑" },
  { address: "서울 은평구 수색동", district: "은평구 갑" },
  { address: "서울 은평구 불광1동", district: "은평구 을" },
  { address: "서울 은평구 불광2동", district: "은평구 을" },
  { address: "서울 은평구 갈현1동", district: "은평구 을" },
  { address: "서울 은평구 갈현2동", district: "은평구 을" },
  { address: "서울 은평구 구산동", district: "은평구 을" },
  { address: "서울 은평구 대조동", district: "은평구 을" },
  { address: "서울 은평구 진관동", district: "은평구 을" },
  { address: "서울 서대문구 충현동", district: "서대문구 갑" },
  { address: "서울 서대문구 천연동", district: "서대문구 갑" },
  { address: "서울 서대문구 북아현동", district: "서대문구 갑" },
  { address: "서울 서대문구 신촌동", district: "서대문구 갑" },
  { address: "서울 서대문구 연희동", district: "서대문구 갑" },
  { address: "서울 서대문구 홍제1동", district: "서대문구 갑" },
  { address: "서울 서대문구 홍제2동", district: "서대문구 갑" },
  { address: "서울 서대문구 홍제3동", district: "서대문구 을" },
  { address: "서울 서대문구 홍은1동", district: "서대문구 을" },
  { address: "서울 서대문구 홍은2동", district: "서대문구 을" },
  { address: "서울 서대문구 남가좌1동", district: "서대문구 을" },
  { address: "서울 서대문구 남가좌2동", district: "서대문구 을" },
  { address: "서울 서대문구 북가좌1동", district: "서대문구 을" },
  { address: "서울 서대문구 북가좌2동", district: "서대문구 을" },
  { address: "서울 마포구 공덕동", district: "마포구 갑" },
  { address: "서울 마포구 아현동", district: "마포구 갑" },
  { address: "서울 마포구 도화동", district: "마포구 갑" },
  { address: "서울 마포구 용강동", district: "마포구 갑" },
  { address: "서울 마포구 대흥동", district: "마포구 갑" },
  { address: "서울 마포구 염리동", district: "마포구 갑" },
  { address: "서울 마포구 신수동", district: "마포구 갑" },
  { address: "서울 마포구 서강동", district: "마포구 을" },
  { address: "서울 마포구 서교동", district: "마포구 을" },
  { address: "서울 마포구 합정동", district: "마포구 을" },
  { address: "서울 마포구 망원1동", district: "마포구 을" },
  { address: "서울 마포구 망원2동", district: "마포구 을" },
  { address: "서울 마포구 연남동", district: "마포구 을" },
  { address: "서울 마포구 성산1동", district: "마포구 을" },
  { address: "서울 마포구 성산2동", district: "마포구 을" },
  { address: "서울 마포구 상암동", district: "마포구 을" },
  { address: "서울 양천구 목1동", district: "양천구 갑" },
  { address: "서울 양천구 목2동", district: "양천구 갑" },
  { address: "서울 양천구 목3동", district: "양천구 갑" },
  { address: "서울 양천구 목4동", district: "양천구 갑" },
  { address: "서울 양천구 목5동", district: "양천구 갑" },
  { address: "서울 양천구 신정1동", district: "양천구 갑" },
  { address: "서울 양천구 신정2동", district: "양천구 갑" },
  { address: "서울 양천구 신정6동", district: "양천구 갑" },
  { address: "서울 양천구 신정7동", district: "양천구 갑" },
  { address: "서울 양천구 신월1동", district: "양천구 을" },
  { address: "서울 양천구 신월2동", district: "양천구 을" },
  { address: "서울 양천구 신월3동", district: "양천구 을" },
  { address: "서울 양천구 신월4동", district: "양천구 을" },
  { address: "서울 양천구 신월5동", district: "양천구 을" },
  { address: "서울 양천구 신월6동", district: "양천구 을" },
  { address: "서울 양천구 신월7동", district: "양천구 을" },
  { address: "서울 양천구 신정3동", district: "양천구 을" },
  { address: "서울 양천구 신정4동", district: "양천구 을" },
  { address: "서울 강서구 화곡1동", district: "강서구 갑" },
  { address: "서울 강서구 화곡2동", district: "강서구 갑" },
  { address: "서울 강서구 화곡3동", district: "강서구 갑" },
  { address: "서울 강서구 화곡8동", district: "강서구 갑" },
  { address: "서울 강서구 발산1동", district: "강서구 갑" },
  { address: "서울 강서구 우장산동", district: "강서구 갑" },
  { address: "서울 강서구 등촌3동", district: "강서구 을" },
  { address: "서울 강서구 가양1동", district: "강서구 을" },
  { address: "서울 강서구 가양2동", district: "강서구 을" },
  { address: "서울 강서구 공항동", district: "강서구 을" },
  { address: "서울 강서구 방화1동", district: "강서구 을" },
  { address: "서울 강서구 방화2동", district: "강서구 을" },
  { address: "서울 강서구 방화3동", district: "강서구 을" },
  { address: "서울 강서구 염창동", district: "강서구 병" },
  { address: "서울 강서구 등촌1동", district: "강서구 병" },
  { address: "서울 강서구 등촌2동", district: "강서구 병" },
  { address: "서울 강서구 화곡4동", district: "강서구 병" },
  { address: "서울 강서구 화곡본동", district: "강서구 병" },
  { address: "서울 강서구 화곡6동", district: "강서구 병" },
  { address: "서울 강서구 가양3동", district: "강서구 병" },
  { address: "서울 구로구 고척1동", district: "구로구 갑" },
  { address: "서울 구로구 고척2동", district: "구로구 갑" },
  { address: "서울 구로구 개봉1동", district: "구로구 갑" },
  { address: "서울 구로구 개봉2동", district: "구로구 갑" },
  { address: "서울 구로구 개봉3동", district: "구로구 갑" },
  { address: "서울 구로구 오류1동", district: "구로구 갑" },
  { address: "서울 구로구 오류2동", district: "구로구 갑" },
  { address: "서울 구로구 수궁동", district: "구로구 갑" },
  { address: "서울 구로구 신도림동", district: "구로구 을" },
  { address: "서울 구로구 구로1동", district: "구로구 을" },
  { address: "서울 구로구 구로2동", district: "구로구 을" },
  { address: "서울 구로구 구로3동", district: "구로구 을" },
  { address: "서울 구로구 구로4동", district: "구로구 을" },
  { address: "서울 구로구 구로5동", district: "구로구 을" },
  { address: "서울 구로구 가리봉동", district: "구로구 을" },
  { address: "서울 금천구 일원", district: "금천구" },
  { address: "서울 영등포구 영등포본동", district: "영등포구 갑" },
  { address: "서울 영등포구 영등포동", district: "영등포구 갑" },
  { address: "서울 영등포구 당산1동", district: "영등포구 갑" },
  { address: "서울 영등포구 당산2동", district: "영등포구 갑" },
  { address: "서울 영등포구 도림동", district: "영등포구 갑" },
  { address: "서울 영등포구 문래동", district: "영등포구 갑" },
  { address: "서울 영등포구 양평1동", district: "영등포구 갑" },
  { address: "서울 영등포구 양평2동", district: "영등포구 갑" },
  { address: "서울 영등포구 신길3동", district: "영등포구 갑" },
  { address: "서울 영등포구 여의동", district: "영등포구 을" },
  { address: "서울 영등포구 신길1동", district: "영등포구 을" },
  { address: "서울 영등포구 신길4동", district: "영등포구 을" },
  { address: "서울 영등포구 신길5동", district: "영등포구 을" },
  { address: "서울 영등포구 신길6동", district: "영등포구 을" },
  { address: "서울 영등포구 신길7동", district: "영등포구 을" },
  { address: "서울 영등포구 대림1동", district: "영등포구 을" },
  { address: "서울 영등포구 대림2동", district: "영등포구 을" },
  { address: "서울 영등포구 대림3동", district: "영등포구 을" },
  { address: "서울 동작구 노량진1동", district: "동작구 갑" },
  { address: "서울 동작구 노량진2동", district: "동작구 갑" },
  { address: "서울 동작구 상도2동", district: "동작구 갑" },
  { address: "서울 동작구 상도3동", district: "동작구 갑" },
  { address: "서울 동작구 상도4동", district: "동작구 갑" },
  { address: "서울 동작구 대방동", district: "동작구 갑" },
  { address: "서울 동작구 신대방1동", district: "동작구 갑" },
  { address: "서울 동작구 신대방2동", district: "동작구 갑" },
  { address: "서울 동작구 상도1동", district: "동작구 을" },
  { address: "서울 동작구 흑석동", district: "동작구 을" },
  { address: "서울 동작구 사당1동", district: "동작구 을" },
  { address: "서울 동작구 사당2동", district: "동작구 을" },
  { address: "서울 동작구 사당3동", district: "동작구 을" },
  { address: "서울 동작구 사당4동", district: "동작구 을" },
  { address: "서울 동작구 사당5동", district: "동작구 을" },
  { address: "서울 관악구 보라매동", district: "관악구 갑" },
  { address: "서울 관악구 은천동", district: "관악구 갑" },
  { address: "서울 관악구 성현동", district: "관악구 갑" },
  { address: "서울 관악구 중앙동", district: "관악구 갑" },
  { address: "서울 관악구 청림동", district: "관악구 갑" },
  { address: "서울 관악구 행운동", district: "관악구 갑" },
  { address: "서울 관악구 청룡동", district: "관악구 갑" },
  { address: "서울 관악구 낙성대동", district: "관악구 갑" },
  { address: "서울 관악구 인헌동", district: "관악구 갑" },
  { address: "서울 관악구 남현동", district: "관악구 갑" },
  { address: "서울 관악구 신림동", district: "관악구 갑" },
  { address: "서울 관악구 신사동", district: "관악구 을" },
  { address: "서울 관악구 조원동", district: "관악구 을" },
  { address: "서울 관악구 미성동", district: "관악구 을" },
  { address: "서울 관악구 난곡동", district: "관악구 을" },
  { address: "서울 관악구 난향동", district: "관악구 을" },
  { address: "서울 관악구 서원동", district: "관악구 을" },
  { address: "서울 관악구 신원동", district: "관악구 을" },
  { address: "서울 관악구 서림동", district: "관악구 을" },
  { address: "서울 관악구 삼성동", district: "관악구 을" },
  { address: "서울 관악구 대학동", district: "관악구 을" },
  { address: "서울 서초구 잠원동", district: "서초구 갑" },
  { address: "서울 서초구 반포본동", district: "서초구 갑" },
  { address: "서울 서초구 반포1동", district: "서초구 갑" },
  { address: "서울 서초구 반포2동", district: "서초구 갑" },
  { address: "서울 서초구 반포3동", district: "서초구 갑" },
  { address: "서울 서초구 반포4동", district: "서초구 갑" },
  { address: "서울 서초구 방배본동", district: "서초구 갑" },
  { address: "서울 서초구 방배1동", district: "서초구 갑" },
  { address: "서울 서초구 방배4동", district: "서초구 갑" },
  { address: "서울 서초구 서초1동", district: "서초구 을" },
  { address: "서울 서초구 서초2동", district: "서초구 을" },
  { address: "서울 서초구 서초3동", district: "서초구 을" },
  { address: "서울 서초구 서초4동", district: "서초구 을" },
  { address: "서울 서초구 방배2동", district: "서초구 을" },
  { address: "서울 서초구 방배3동", district: "서초구 을" },
  { address: "서울 서초구 양재1동", district: "서초구 을" },
  { address: "서울 서초구 양재2동", district: "서초구 을" },
  { address: "서울 서초구 내곡동", district: "서초구 을" },
  { address: "서울 강남구 신사동", district: "강남구 갑" },
  { address: "서울 강남구 논현1동", district: "강남구 갑" },
  { address: "서울 강남구 논현2동", district: "강남구 갑" },
  { address: "서울 강남구 압구정동", district: "강남구 갑" },
  { address: "서울 강남구 청담동", district: "강남구 갑" },
  { address: "서울 강남구 역삼1동", district: "강남구 갑" },
  { address: "서울 강남구 역삼2동", district: "강남구 갑" },
  { address: "서울 강남구 개포1동", district: "강남구 을" },
  { address: "서울 강남구 개포2동", district: "강남구 을" },
  { address: "서울 강남구 개포4동", district: "강남구 을" },
  { address: "서울 강남구 세곡동", district: "강남구 을" },
  { address: "서울 강남구 일원본동", district: "강남구 을" },
  { address: "서울 강남구 일원1동", district: "강남구 을" },
  { address: "서울 강남구 일원2동", district: "강남구 을" },
  { address: "서울 강남구 수서동", district: "강남구 을" },
  { address: "서울 강남구 삼성1동", district: "강남구 병" },
  { address: "서울 강남구 삼성2동", district: "강남구 병" },
  { address: "서울 강남구 대치1동", district: "강남구 병" },
  { address: "서울 강남구 대치2동", district: "강남구 병" },
  { address: "서울 강남구 대치4동", district: "강남구 병" },
  { address: "서울 강남구 도곡1동", district: "강남구 병" },
  { address: "서울 강남구 도곡2동", district: "강남구 병" },
  { address: "서울 송파구 풍납1동", district: "송파구 갑" },
  { address: "서울 송파구 풍납2동", district: "송파구 갑" },
  { address: "서울 송파구 방이1동", district: "송파구 갑" },
  { address: "서울 송파구 방이2동", district: "송파구 갑" },
  { address: "서울 송파구 오륜동", district: "송파구 갑" },
  { address: "서울 송파구 송파1동", district: "송파구 갑" },
  { address: "서울 송파구 송파2동", district: "송파구 갑" },
  { address: "서울 송파구 잠실4동", district: "송파구 갑" },
  { address: "서울 송파구 잠실6동", district: "송파구 갑" },
  { address: "서울 송파구 석촌동", district: "송파구 을" },
  { address: "서울 송파구 삼전동", district: "송파구 을" },
  { address: "서울 송파구 가락1동", district: "송파구 을" },
  { address: "서울 송파구 문정2동", district: "송파구 을" },
  { address: "서울 송파구 잠실본동", district: "송파구 을" },
  { address: "서울 송파구 잠실2동", district: "송파구 을" },
  { address: "서울 송파구 잠실3동", district: "송파구 을" },
  { address: "서울 송파구 잠실7동", district: "송파구 을" },
  { address: "서울 송파구 거여1동", district: "송파구 병" },
  { address: "서울 송파구 거여2동", district: "송파구 병" },
  { address: "서울 송파구 마천1동", district: "송파구 병" },
  { address: "서울 송파구 마천2동", district: "송파구 병" },
  { address: "서울 송파구 오금동", district: "송파구 병" },
  { address: "서울 송파구 가락본동", district: "송파구 병" },
  { address: "서울 송파구 가락2동", district: "송파구 병" },
  { address: "서울 송파구 문정1동", district: "송파구 병" },
  { address: "서울 송파구 장지동", district: "송파구 병" },
  { address: "서울 송파구 위례동", district: "송파구 병" },
  { address: "서울 강동구 강일동", district: "강동구 갑" },
  { address: "서울 강동구 상일동", district: "강동구 갑" },
  { address: "서울 강동구 명일1동", district: "강동구 갑" },
  { address: "서울 강동구 명일2동", district: "강동구 갑" },
  { address: "서울 강동구 고덕1동", district: "강동구 갑" },
  { address: "서울 강동구 고덕제2동", district: "강동구 갑" },
  { address: "서울 강동구 암사1동", district: "강동구 갑" },
  { address: "서울 강동구 암사2동", district: "강동구 갑" },
  { address: "서울 강동구 암사3동", district: "강동구 갑" },
  { address: "서울 강동구 길동", district: "강동구 갑" },
  { address: "서울 강동구 천호1동", district: "강동구 을" },
  { address: "서울 강동구 천호2동", district: "강동구 을" },
  { address: "서울 강동구 천호3동", district: "강동구 을" },
  { address: "서울 강동구 성내1동", district: "강동구 을" },
  { address: "서울 강동구 성내2동", district: "강동구 을" },
  { address: "서울 강동구 성내3동", district: "강동구 을" },
  { address: "서울 강동구 둔촌1동", district: "강동구 을" },
  { address: "서울 강동구 둔촌2동", district: "강동구 을" },
  { address: "부산 중구 일원", district: "중구 영도구" },
  { address: "부산 영도구 일원", district: "중구 영도구" },
  { address: "부산 서구 일원", district: "서구 동구" },
  { address: "부산 동구 일원", district: "서구 동구" },
  { address: "부산 부산진구 부전1동", district: "부산진구 갑" },
  { address: "부산 부산진구 연지동", district: "부산진구 갑" },
  { address: "부산 부산진구 초읍동", district: "부산진구 갑" },
  { address: "부산 부산진구 양정1동", district: "부산진구 갑" },
  { address: "부산 부산진구 양정2동", district: "부산진구 갑" },
  { address: "부산 부산진구 부암1동", district: "부산진구 갑" },
  { address: "부산 부산진구 부암3동", district: "부산진구 갑" },
  { address: "부산 부산진구 당감1동", district: "부산진구 갑" },
  { address: "부산 부산진구 당감2동", district: "부산진구 갑" },
  { address: "부산 부산진구 당감4동", district: "부산진구 갑" },
  { address: "부산 부산진구 부전2동", district: "부산진구 을" },
  { address: "부산 부산진구 전포1동", district: "부산진구 을" },
  { address: "부산 부산진구 전포2동", district: "부산진구 을" },
  { address: "부산 부산진구 가야1동", district: "부산진구 을" },
  { address: "부산 부산진구 가야2동", district: "부산진구 을" },
  { address: "부산 부산진구 개금1동", district: "부산진구 을" },
  { address: "부산 부산진구 개금2동", district: "부산진구 을" },
  { address: "부산 부산진구 개금3동", district: "부산진구 을" },
  { address: "부산 부산진구 범천1동", district: "부산진구 을" },
  { address: "부산 부산진구 범천2동", district: "부산진구 을" },
  { address: "부산 동래구 일원", district: "동래구" },
  { address: "부산 남구 대연1동", district: "남구 갑" },
  { address: "부산 남구 대연3동", district: "남구 갑" },
  { address: "부산 남구 대연4동", district: "남구 갑" },
  { address: "부산 남구 대연5동", district: "남구 갑" },
  { address: "부산 남구 대연6동", district: "남구 갑" },
  { address: "부산 남구 문현1동", district: "남구 갑" },
  { address: "부산 남구 문현2동", district: "남구 갑" },
  { address: "부산 남구 문현3동", district: "남구 갑" },
  { address: "부산 남구 문현4동", district: "남구 갑" },
  { address: "부산 남구 용호1동", district: "남구 을" },
  { address: "부산 남구 용호2동", district: "남구 을" },
  { address: "부산 남구 용호3동", district: "남구 을" },
  { address: "부산 남구 용호4동", district: "남구 을" },
  { address: "부산 남구 용당동", district: "남구 을" },
  { address: "부산 남구 감만1동", district: "남구 을" },
  { address: "부산 남구 감만2동", district: "남구 을" },
  { address: "부산 남구 우암동", district: "남구 을" },
  { address: "부산 북구 구포1동", district: "북구강서구 갑" },
  { address: "부산 북구 구포2동", district: "북구강서구 갑" },
  { address: "부산 북구 구포3동", district: "북구강서구 갑" },
  { address: "부산 북구 덕천1동", district: "북구강서구 갑" },
  { address: "부산 북구 덕천2동", district: "북구강서구 갑" },
  { address: "부산 북구 덕천3동", district: "북구강서구 갑" },
  { address: "부산 북구 만덕1동", district: "북구강서구 갑" },
  { address: "부산 북구 만덕2동", district: "북구강서구 갑" },
  { address: "부산 북구 만덕3동", district: "북구강서구 갑" },
  { address: "부산 북구 금곡동", district: "북구강서구 을" },
  { address: "부산 북구 화명1동", district: "북구강서구 을" },
  { address: "부산 북구 화명2동", district: "북구강서구 을" },
  { address: "부산 북구 화명3동", district: "북구강서구 을" },
  { address: "부산 강서구 일원", district: "북구강서구 을" },
  { address: "부산 해운대구 우1동", district: "해운대구 갑" },
  { address: "부산 해운대구 우2동", district: "해운대구 갑" },
  { address: "부산 해운대구 우3동", district: "해운대구 갑" },
  { address: "부산 해운대구 중1동", district: "해운대구 갑" },
  { address: "부산 해운대구 중2동", district: "해운대구 갑" },
  { address: "부산 해운대구 좌1동", district: "해운대구 갑" },
  { address: "부산 해운대구 좌2동", district: "해운대구 갑" },
  { address: "부산 해운대구 좌3동", district: "해운대구 갑" },
  { address: "부산 해운대구 좌4동", district: "해운대구 갑" },
  { address: "부산 해운대구 송정동", district: "해운대구 갑" },
  { address: "부산 해운대구 반여1동", district: "해운대구 을" },
  { address: "부산 해운대구 반여2동", district: "해운대구 을" },
  { address: "부산 해운대구 반여3동", district: "해운대구 을" },
  { address: "부산 해운대구 반여4동", district: "해운대구 을" },
  { address: "부산 해운대구 반송1동", district: "해운대구 을" },
  { address: "부산 해운대구 반송2동", district: "해운대구 을" },
  { address: "부산 해운대구 재송1동", district: "해운대구 을" },
  { address: "부산 해운대구 재송2동", district: "해운대구 을" },
  { address: "부산 사하구 괴정1동", district: "사하구 갑" },
  { address: "부산 사하구 괴정2동", district: "사하구 갑" },
  { address: "부산 사하구 괴정3동", district: "사하구 갑" },
  { address: "부산 사하구 괴정4동", district: "사하구 갑" },
  { address: "부산 사하구 당리동", district: "사하구 갑" },
  { address: "부산 사하구 하단1동", district: "사하구 갑" },
  { address: "부산 사하구 하단2동", district: "사하구 갑" },
  { address: "부산 사하구 신평1동", district: "사하구 을" },
  { address: "부산 사하구 신평2동", district: "사하구 을" },
  { address: "부산 사하구 장림1동", district: "사하구 을" },
  { address: "부산 사하구 장림2동", district: "사하구 을" },
  { address: "부산 사하구 다대1동", district: "사하구 을" },
  { address: "부산 사하구 다대2동", district: "사하구 을" },
  { address: "부산 사하구 구평동", district: "사하구 을" },
  { address: "부산 사하구 감천1동", district: "사하구 을" },
  { address: "부산 사하구 감천2동", district: "사하구 을" },
  { address: "부산 금정구 일원", district: "금정구" },
  { address: "부산 연제구 일원", district: "연제구" },
  { address: "부산 수영구 일원", district: "수영구" },
  { address: "부산 사상구 일원", district: "사상구" },
  { address: "부산 기장군 일원", district: "기장구" },
  { address: "대구 중구 일원", district: "중구 남구" },
  { address: "대구 남구 일원", district: "중구 남구" },
  { address: "대구 동구 신암1동", district: "동구 갑" },
  { address: "대구 동구 신암2동", district: "동구 갑" },
  { address: "대구 동구 신암3동", district: "동구 갑" },
  { address: "대구 동구 신암4동", district: "동구 갑" },
  { address: "대구 동구 신암5동", district: "동구 갑" },
  { address: "대구 동구 신천1·2동", district: "동구 갑" },
  { address: "대구 동구 신천3동", district: "동구 갑" },
  { address: "대구 동구 신천4동", district: "동구 갑" },
  { address: "대구 동구 효목1동", district: "동구 갑" },
  { address: "대구 동구 효목2동", district: "동구 갑" },
  { address: "대구 동구 지저동", district: "동구 갑" },
  { address: "대구 동구 동촌동", district: "동구 갑" },
  { address: "대구 동구 도평동", district: "동구 을" },
  { address: "대구 동구 불로·봉무동", district: "동구 을" },
  { address: "대구 동구 방촌동", district: "동구 을" },
  { address: "대구 동구 해안동", district: "동구 을" },
  { address: "대구 동구 안심1동", district: "동구 을" },
  { address: "대구 동구 안심2동", district: "동구 을" },
  { address: "대구 동구 안심3·4동", district: "동구 을" },
  { address: "대구 동구 공산동", district: "동구 을" },
  { address: "대구 서구 일원", district: "서구" },
  { address: "대구 북구 고성동", district: "북구 갑" },
  { address: "대구 북구 칠성동", district: "북구 갑" },
  { address: "대구 북구 침산1동", district: "북구 갑" },
  { address: "대구 북구 침산2동", district: "북구 갑" },
  { address: "대구 북구 침산3동", district: "북구 갑" },
  { address: "대구 북구 산격1동", district: "북구 갑" },
  { address: "대구 북구 산격2동", district: "북구 갑" },
  { address: "대구 북구 산격3동", district: "북구 갑" },
  { address: "대구 북구 산격4동", district: "북구 갑" },
  { address: "대구 북구 대현동", district: "북구 갑" },
  { address: "대구 북구 복현1동", district: "북구 갑" },
  { address: "대구 북구 복현2동", district: "북구 갑" },
  { address: "대구 북구 검단동", district: "북구 갑" },
  { address: "대구 북구 노원동", district: "북구 갑" },
  { address: "대구 북구 무태조야동", district: "북구 을" },
  { address: "대구 북구 관문동", district: "북구 을" },
  { address: "대구 북구 태전1동", district: "북구 을" },
  { address: "대구 북구 태전2동", district: "북구 을" },
  { address: "대구 북구 구암동", district: "북구 을" },
  { address: "대구 북구 관음동", district: "북구 을" },
  { address: "대구 북구 읍내동", district: "북구 을" },
  { address: "대구 북구 동천동", district: "북구 을" },
  { address: "대구 북구 국우동", district: "북구 을" },
  { address: "대구 수성구 범어1동", district: "수성구 갑" },
  { address: "대구 수성구 범어2동", district: "수성구 갑" },
  { address: "대구 수성구 범어3동", district: "수성구 갑" },
  { address: "대구 수성구 범어4동", district: "수성구 갑" },
  { address: "대구 수성구 만촌1동", district: "수성구 갑" },
  { address: "대구 수성구 만촌2동", district: "수성구 갑" },
  { address: "대구 수성구 만촌3동", district: "수성구 갑" },
  { address: "대구 수성구 황금1동", district: "수성구 갑" },
  { address: "대구 수성구 황금2동", district: "수성구 갑" },
  { address: "대구 수성구 고산1동", district: "수성구 갑" },
  { address: "대구 수성구 고산2동", district: "수성구 갑" },
  { address: "대구 수성구 고산3동", district: "수성구 갑" },
  { address: "대구 수성구 수성1가동", district: "수성구 을" },
  { address: "대구 수성구 수성2·3가동", district: "수성구 을" },
  { address: "대구 수성구 수성4가동", district: "수성구 을" },
  { address: "대구 수성구 중동", district: "수성구 을" },
  { address: "대구 수성구 상동", district: "수성구 을" },
  { address: "대구 수성구 파동", district: "수성구 을" },
  { address: "대구 수성구 두산동", district: "수성구 을" },
  { address: "대구 수성구 지산1동", district: "수성구 을" },
  { address: "대구 수성구 지산2동", district: "수성구 을" },
  { address: "대구 수성구 범물1동", district: "수성구 을" },
  { address: "대구 수성구 범물2동", district: "수성구 을" },
  { address: "대구 달서구 죽전동", district: "달서구 갑" },
  { address: "대구 달서구 장기동", district: "달서구 갑" },
  { address: "대구 달서구 용산1동", district: "달서구 갑" },
  { address: "대구 달서구 용산2동", district: "달서구 갑" },
  { address: "대구 달서구 이곡1동", district: "달서구 갑" },
  { address: "대구 달서구 이곡2동", district: "달서구 갑" },
  { address: "대구 달서구 신당동", district: "달서구 갑" },
  { address: "대구 달서구 월성1동", district: "달서구 을" },
  { address: "대구 달서구 월성2동", district: "달서구 을" },
  { address: "대구 달서구 진천동", district: "달서구 을" },
  { address: "대구 달서구 상인1동", district: "달서구 을" },
  { address: "대구 달서구 상인2동", district: "달서구 을" },
  { address: "대구 달서구 상인3동", district: "달서구 을" },
  { address: "대구 달서구 도원동", district: "달서구 을" },
  { address: "대구 달서구 성당동", district: "달서구 병" },
  { address: "대구 달서구 두류1·2동", district: "달서구 병" },
  { address: "대구 달서구 두류3동", district: "달서구 병" },
  { address: "대구 달서구 본리동", district: "달서구 병" },
  { address: "대구 달서구 감삼동", district: "달서구 병" },
  { address: "대구 달서구 송현1동", district: "달서구 병" },
  { address: "대구 달서구 송현2동", district: "달서구 병" },
  { address: "대구 달서구 본동", district: "달서구 병" },
  { address: "대구 달성군 일원", district: "달성군" },
  { address: "인천 중구 일원", district: "중구동구강화군옹진군" },
  { address: "인천 동구 일원", district: "중구동구강화군옹진군" },
  { address: "인천 강화군 일원", district: "중구동구강화군옹진군" },
  { address: "인천 옹진군 일원", district: "중구동구강화군옹진군" },
  { address: "인천 미추홀구 도화1동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 도화2·3동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안1동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안2동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안3동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안4동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안5동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안6동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안7동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 주안8동", district: "미추홀구 갑" },
  { address: "인천 미추홀구 숭의1·3동", district: "미추홀구 을" },
  { address: "인천 미추홀구 숭의2동", district: "미추홀구 을" },
  { address: "인천 미추홀구 숭의4동", district: "미추홀구 을" },
  { address: "인천 미추홀구 용현1·4동", district: "미추홀구 을" },
  { address: "인천 미추홀구 용현2동", district: "미추홀구 을" },
  { address: "인천 미추홀구 용현3동", district: "미추홀구 을" },
  { address: "인천 미추홀구 용현5동", district: "미추홀구 을" },
  { address: "인천 미추홀구 학익1동", district: "미추홀구 을" },
  { address: "인천 미추홀구 학익2동", district: "미추홀구 을" },
  { address: "인천 미추홀구 관교동", district: "미추홀구 을" },
  { address: "인천 미추홀구 문학동", district: "미추홀구 을" },
  { address: "인천 연수구 옥련2동", district: "연수구 갑" },
  { address: "인천 연수구 선학동", district: "연수구 갑" },
  { address: "인천 연수구 연수1동", district: "연수구 갑" },
  { address: "인천 연수구 연수2동", district: "연수구 갑" },
  { address: "인천 연수구 연수3동", district: "연수구 갑" },
  { address: "인천 연수구 청학동", district: "연수구 갑" },
  { address: "인천 연수구 동춘3동", district: "연수구 갑" },
  { address: "인천 연수구 옥련1동", district: "연수구 을" },
  { address: "인천 연수구 동춘1동", district: "연수구 을" },
  { address: "인천 연수구 동춘2동", district: "연수구 을" },
  { address: "인천 연수구 송도1동", district: "연수구 을" },
  { address: "인천 연수구 송도2동", district: "연수구 을" },
  { address: "인천 연수구 송도3동", district: "연수구 을" },
  { address: "인천 남동구 구월1동", district: "남동구 갑" },
  { address: "인천 남동구 구월3동", district: "남동구 갑" },
  { address: "인천 남동구 구월4동", district: "남동구 갑" },
  { address: "인천 남동구 간석1동", district: "남동구 갑" },
  { address: "인천 남동구 간석4동", district: "남동구 갑" },
  { address: "인천 남동구 남촌도림동", district: "남동구 갑" },
  { address: "인천 남동구 논현1동", district: "남동구 갑" },
  { address: "인천 남동구 논현2동", district: "남동구 갑" },
  { address: "인천 남동구 논현고잔동", district: "남동구 갑" },
  { address: "인천 남동구 구월2동", district: "남동구 을" },
  { address: "인천 남동구 간석2동", district: "남동구 을" },
  { address: "인천 남동구 간석3동", district: "남동구 을" },
  { address: "인천 남동구 만수1동", district: "남동구 을" },
  { address: "인천 남동구 만수2동", district: "남동구 을" },
  { address: "인천 남동구 만수3동", district: "남동구 을" },
  { address: "인천 남동구 만수4동", district: "남동구 을" },
  { address: "인천 남동구 만수5동", district: "남동구 을" },
  { address: "인천 남동구 만수6동", district: "남동구 을" },
  { address: "인천 남동구 장수서창동", district: "남동구 을" },
  { address: "인천 부평구 부평1동", district: "부평구 갑" },
  { address: "인천 부평구 부평2동", district: "부평구 갑" },
  { address: "인천 부평구 부평3동", district: "부평구 갑" },
  { address: "인천 부평구 부평4동", district: "부평구 갑" },
  { address: "인천 부평구 부평5동", district: "부평구 갑" },
  { address: "인천 부평구 부평6동", district: "부평구 갑" },
  { address: "인천 부평구 산곡3동", district: "부평구 갑" },
  { address: "인천 부평구 산곡4동", district: "부평구 갑" },
  { address: "인천 부평구 부개1동", district: "부평구 갑" },
  { address: "인천 부평구 일신동", district: "부평구 갑" },
  { address: "인천 부평구 십정1동", district: "부평구 갑" },
  { address: "인천 부평구 십정2동", district: "부평구 갑" },
  { address: "인천 부평구 산곡1동", district: "부평구 을" },
  { address: "인천 부평구 산곡2동", district: "부평구 을" },
  { address: "인천 부평구 청천1동", district: "부평구 을" },
  { address: "인천 부평구 청천2동", district: "부평구 을" },
  { address: "인천 부평구 갈산1동", district: "부평구 을" },
  { address: "인천 부평구 갈산2동", district: "부평구 을" },
  { address: "인천 부평구 삼산1동", district: "부평구 을" },
  { address: "인천 부평구 삼산2동", district: "부평구 을" },
  { address: "인천 부평구 부개2동", district: "부평구 을" },
  { address: "인천 부평구 부개3동", district: "부평구 을" },
  { address: "인천 계양구 효성1동", district: "계양구 갑" },
  { address: "인천 계양구 효성2동", district: "계양구 갑" },
  { address: "인천 계양구 작전1동", district: "계양구 갑" },
  { address: "인천 계양구 작전2동", district: "계양구 갑" },
  { address: "인천 계양구 작전서운동", district: "계양구 갑" },
  { address: "인천 계양구 계산1동", district: "계양구 을" },
  { address: "인천 계양구 계산2동", district: "계양구 을" },
  { address: "인천 계양구 계산3동", district: "계양구 을" },
  { address: "인천 계양구 계산4동", district: "계양구 을" },
  { address: "인천 계양구 계양1동", district: "계양구 을" },
  { address: "인천 계양구 계양2동", district: "계양구 을" },
  { address: "인천 계양구 계양3동", district: "계양구 을" },
  { address: "인천 서구 청라1동", district: "서구 갑" },
  { address: "인천 서구 청라2동", district: "서구 갑" },
  { address: "인천 서구 가정1동", district: "서구 갑" },
  { address: "인천 서구 가정2동", district: "서구 갑" },
  { address: "인천 서구 가정3동", district: "서구 갑" },
  { address: "인천 서구 석남1동", district: "서구 갑" },
  { address: "인천 서구 석남2동", district: "서구 갑" },
  { address: "인천 서구 석남3동", district: "서구 갑" },
  { address: "인천 서구 신현원창동", district: "서구 갑" },
  { address: "인천 서구 가좌1동", district: "서구 갑" },
  { address: "인천 서구 가좌2동", district: "서구 갑" },
  { address: "인천 서구 가좌3동", district: "서구 갑" },
  { address: "인천 서구 가좌4동", district: "서구 갑" },
  { address: "인천 서구 검암경서동", district: "서구 을" },
  { address: "인천 서구 연희동", district: "서구 을" },
  { address: "인천 서구 검단1동", district: "서구 을" },
  { address: "인천 서구 검단2동", district: "서구 을" },
  { address: "인천 서구 검단3동", district: "서구 을" },
  { address: "인천 서구 검단5동", district: "서구 을" },
  { address: "인천 서구 검암경서동", district: "서구 을" },
  { address: "인천 서구 연희동", district: "서구 을" },
  { address: "인천 서구 검단1동", district: "서구 을" },
  { address: "인천 서구 검단2동", district: "서구 을" },
  { address: "인천 서구 검단3동", district: "서구 을" },
  { address: "인천 서구 검단4동", district: "서구 을" },
  { address: "인천 서구 검단5동", district: "서구 을" },
  { address: "광주 남구 봉선1동", district: "동구남구 갑" },
  { address: "광주 남구 봉선2동", district: "동구남구 갑" },
  { address: "광주 남구 월산동", district: "동구남구 갑" },
  { address: "광주 남구 월산4동", district: "동구남구 갑" },
  { address: "광주 남구 월산5동", district: "동구남구 갑" },
  { address: "광주 남구 주월1동", district: "동구남구 갑" },
  { address: "광주 남구 주월2동", district: "동구남구 갑" },
  { address: "광주 남구 효덕동", district: "동구남구 갑" },
  { address: "광주 남구 송암동", district: "동구남구 갑" },
  { address: "광주 남구 대촌동", district: "동구남구 갑" },
  { address: "광주 남구 양림동", district: "동구남구 을" },
  { address: "광주 남구 방림1동", district: "동구남구 을" },
  { address: "광주 남구 방림2동", district: "동구남구 을" },
  { address: "광주 남구 사직동", district: "동구남구 을" },
  { address: "광주 남구 백운1동", district: "동구남구 을" },
  { address: "광주 남구 백운2동", district: "동구남구 을" },
  { address: "광주 동구 일원", district: "동구남구 을" },
  { address: "광주 서구 양동", district: "서구 갑" },
  { address: "광주 서구 양3동", district: "서구 갑" },
  { address: "광주 서구 농성1동", district: "서구 갑" },
  { address: "광주 서구 농성2동", district: "서구 갑" },
  { address: "광주 서구 광천동", district: "서구 갑" },
  { address: "광주 서구 유덕동", district: "서구 갑" },
  { address: "광주 서구 치평동", district: "서구 갑" },
  { address: "광주 서구 상무1동", district: "서구 갑" },
  { address: "광주 서구 화정1동", district: "서구 갑" },
  { address: "광주 서구 화정2동", district: "서구 갑" },
  { address: "광주 서구 동천동", district: "서구 갑" },
  { address: "광주 서구 화정3동", district: "서구 을" },
  { address: "광주 서구 화정4동", district: "서구 을" },
  { address: "광주 서구 서창동", district: "서구 을" },
  { address: "광주 서구 금호1동", district: "서구 을" },
  { address: "광주 서구 금호2동", district: "서구 을" },
  { address: "광주 서구 풍암동", district: "서구 을" },
  { address: "광주 서구 상무2동", district: "서구 을" },
  { address: "광주 북구 중흥1동", district: "북구 갑" },
  { address: "광주 북구 중흥2동", district: "북구 갑" },
  { address: "광주 북구 중흥3동", district: "북구 갑" },
  { address: "광주 북구 중앙동", district: "북구 갑" },
  { address: "광주 북구 임동", district: "북구 갑" },
  { address: "광주 북구 신안동", district: "북구 갑" },
  { address: "광주 북구 우산동", district: "북구 갑" },
  { address: "광주 북구 풍향동", district: "북구 갑" },
  { address: "광주 북구 문화동", district: "북구 갑" },
  { address: "광주 북구 문흥1동", district: "북구 갑" },
  { address: "광주 북구 문흥2동", district: "북구 갑" },
  { address: "광주 북구 두암1동", district: "북구 갑" },
  { address: "광주 북구 두암2동", district: "북구 갑" },
  { address: "광주 북구 두암3동", district: "북구 갑" },
  { address: "광주 북구 오치1동", district: "북구 갑" },
  { address: "광주 북구 오치2동", district: "북구 갑" },
  { address: "광주 북구 석곡동", district: "북구 갑" },
  { address: "광주 북구 용봉동", district: "북구 을" },
  { address: "광주 북구 운암1동", district: "북구 을" },
  { address: "광주 북구 운암2동", district: "북구 을" },
  { address: "광주 북구 운암3동", district: "북구 을" },
  { address: "광주 북구 동림동", district: "북구 을" },
  { address: "광주 북구 삼각동", district: "북구 을" },
  { address: "광주 북구 일곡동", district: "북구 을" },
  { address: "광주 북구 매곡동", district: "북구 을" },
  { address: "광주 북구 건국동", district: "북구 을" },
  { address: "광주 북구 양산동", district: "북구 을" },
  { address: "광주 광산구 송정1동", district: "광산구 갑" },
  { address: "광주 광산구 송정2동", district: "광산구 갑" },
  { address: "광주 광산구 도산동", district: "광산구 갑" },
  { address: "광주 광산구 신흥동", district: "광산구 갑" },
  { address: "광주 광산구 어룡동", district: "광산구 갑" },
  { address: "광주 광산구 우산동", district: "광산구 갑" },
  { address: "광주 광산구 월곡1동", district: "광산구 갑" },
  { address: "광주 광산구 월곡2동", district: "광산구 갑" },
  { address: "광주 광산구 운남동", district: "광산구 갑" },
  { address: "광주 광산구 동곡동", district: "광산구 갑" },
  { address: "광주 광산구 평동", district: "광산구 갑" },
  { address: "광주 광산구 삼도동", district: "광산구 갑" },
  { address: "광주 광산구 본량동", district: "광산구 갑" },
  { address: "광주 광산구 비아동", district: "광산구 을" },
  { address: "광주 광산구 첨단1동", district: "광산구 을" },
  { address: "광주 광산구 첨단2동", district: "광산구 을" },
  { address: "광주 광산구 신가동", district: "광산구 을" },
  { address: "광주 광산구 신창동", district: "광산구 을" },
  { address: "광주 광산구 수완동", district: "광산구 을" },
  { address: "광주 광산구 하남동", district: "광산구 을" },
  { address: "광주 광산구 임곡동", district: "광산구 을" },
  { address: "대전 동구 일원", district: "동구" },
  { address: "대전 중구 일원", district: "중구" },
  { address: "대전 서구 복수동", district: "서구 갑" },
  { address: "대전 서구 도마1동", district: "서구 갑" },
  { address: "대전 서구 도마2동", district: "서구 갑" },
  { address: "대전 서구 정림동", district: "서구 갑" },
  { address: "대전 서구 변동", district: "서구 갑" },
  { address: "대전 서구 괴정동", district: "서구 갑" },
  { address: "대전 서구 가장동", district: "서구 갑" },
  { address: "대전 서구 내동", district: "서구 갑" },
  { address: "대전 서구 가수원동", district: "서구 갑" },
  { address: "대전 서구 관저1동", district: "서구 갑" },
  { address: "대전 서구 관저2동", district: "서구 갑" },
  { address: "대전 서구 기성동", district: "서구 갑" },
  { address: "대전 서구 용문동", district: "서구 을" },
  { address: "대전 서구 탄방동", district: "서구 을" },
  { address: "대전 서구 둔산1동", district: "서구 을" },
  { address: "대전 서구 둔산2동", district: "서구 을" },
  { address: "대전 서구 둔산3동", district: "서구 을" },
  { address: "대전 서구 갈마1동", district: "서구 을" },
  { address: "대전 서구 갈마2동", district: "서구 을" },
  { address: "대전 서구 월평1동", district: "서구 을" },
  { address: "대전 서구 월평2동", district: "서구 을" },
  { address: "대전 서구 월평3동", district: "서구 을" },
  { address: "대전 서구 만년동", district: "서구 을" },
  { address: "대전 유성구 진잠동", district: "유성구 갑" },
  { address: "대전 유성구 온천1동", district: "유성구 갑" },
  { address: "대전 유성구 온천2동", district: "유성구 갑" },
  { address: "대전 유성구 노은1동", district: "유성구 갑" },
  { address: "대전 유성구 원신흥동", district: "유성구 갑" },
  { address: "대전 유성구 노은2동", district: "유성구 을" },
  { address: "대전 유성구 노은3동", district: "유성구 을" },
  { address: "대전 유성구 신성동", district: "유성구 을" },
  { address: "대전 유성구 전민동", district: "유성구 을" },
  { address: "대전 유성구 구즉동", district: "유성구 을" },
  { address: "대전 유성구 관평동", district: "유성구 을" },
  { address: "대전 대덕구 일원", district: "대덕구" },
  { address: "울산 중구 일원", district: "중구" },
  { address: "울산 남구 신정1동", district: "남구 갑" },
  { address: "울산 남구 신정2동", district: "남구 갑" },
  { address: "울산 남구 신정3동", district: "남구 갑" },
  { address: "울산 남구 신정4동", district: "남구 갑" },
  { address: "울산 남구 신정5동", district: "남구 갑" },
  { address: "울산 남구 삼호동", district: "남구 갑" },
  { address: "울산 남구 무거동", district: "남구 갑" },
  { address: "울산 남구 옥동", district: "남구 갑" },
  { address: "울산 남구 달동", district: "남구 을" },
  { address: "울산 남구 삼산동", district: "남구 을" },
  { address: "울산 남구 야음장생포동", district: "남구 을" },
  { address: "울산 남구 대현동", district: "남구 을" },
  { address: "울산 남구 수암동", district: "남구 을" },
  { address: "울산 남구 선암동", district: "남구 을" },
  { address: "울산 동구 일원", district: "동구" },
  { address: "울산 북구 일원", district: "북구" },
  { address: "울산 울주군 일원", district: "울주군" },
  { address: "세종 일원", district: "세종" },
  { address: "수원 장안구 일원(율천동 제외)", district: "수원시 갑" },
  { address: "수원 장안구 율천동", district: "수원시 을" },
  { address: "수원 권선구 권선구 평동", district: "수원시 을" },
  { address: "수원 권선구 서둔동", district: "수원시 을" },
  { address: "수원 권선구 구운동", district: "수원시 을" },
  { address: "수원 권선구 금곡동", district: "수원시 을" },
  { address: "수원 권선구 호매실동", district: "수원시 을" },
  { address: "수원 권선구 입북동", district: "수원시 을" },
  { address: "수원 팔달구 일원", district: "수원시 병" },
  { address: "수원 영통구 일원(영통2동 태장동 제외)", district: "수원시 정" },
  { address: "수원 권선구 세류1동", district: "수원시 무" },
  { address: "수원 권선구 세류2동", district: "수원시 무" },
  { address: "수원 권선구 세류3동", district: "수원시 무" },
  { address: "수원 권선구 권선1동", district: "수원시 무" },
  { address: "수원 권선구 권선2동", district: "수원시 무" },
  { address: "수원 권선구 곡선동", district: "수원시 무" },
  { address: "수원 영통구 영통2동", district: "수원시 무" },
  { address: "수원 영통구 태장동", district: "수원시 무" },
  { address: "성남 수정구 일원", district: "수정구" },
  { address: "성남 중원구 일원", district: "중원구" },
  { address: "성남 분당구 서현1동", district: "분당구 갑" },
  { address: "성남 분당구 서현2동", district: "분당구 갑" },
  { address: "성남 분당구 이매1동", district: "분당구 갑" },
  { address: "성남 분당구 이매2동", district: "분당구 갑" },
  { address: "성남 분당구 야탑1동", district: "분당구 갑" },
  { address: "성남 분당구 야탑2동", district: "분당구 갑" },
  { address: "성남 분당구 야탑3동", district: "분당구 갑" },
  { address: "성남 분당구 판교동", district: "분당구 갑" },
  { address: "성남 분당구 삼평동", district: "분당구 갑" },
  { address: "성남 분당구 백현동", district: "분당구 갑" },
  { address: "성남 분당구 운중동", district: "분당구 갑" },
  { address: "성남 분당구 분당동", district: "분당구 을" },
  { address: "성남 분당구 수내1동", district: "분당구 을" },
  { address: "성남 분당구 수내2동", district: "분당구 을" },
  { address: "성남 분당구 수내3동", district: "분당구 을" },
  { address: "성남 분당구 정자동", district: "분당구 을" },
  { address: "성남 분당구 정자1동", district: "분당구 을" },
  { address: "성남 분당구 정자2동", district: "분당구 을" },
  { address: "성남 분당구 정자3동", district: "분당구 을" },
  { address: "성남 분당구 금곡동", district: "분당구 을" },
  { address: "성남 분당구 구미1동", district: "분당구 을" },
  { address: "성남 분당구 구미동", district: "분당구 을" },
  { address: "의정부 의정부1동", district: "의정부시 갑" },
  { address: "의정부 의정부2동", district: "의정부시 갑" },
  { address: "의정부 의정부3동", district: "의정부시 갑" },
  { address: "의정부 호원1동", district: "의정부시 갑" },
  { address: "의정부 호원2동", district: "의정부시 갑" },
  { address: "의정부 가능1동", district: "의정부시 갑" },
  { address: "의정부 가능2동", district: "의정부시 갑" },
  { address: "의정부 가능3동", district: "의정부시 갑" },
  { address: "의정부 녹양동", district: "의정부시 갑" },
  { address: "의정부 장암동", district: "의정부시 을" },
  { address: "의정부 신곡1동", district: "의정부시 을" },
  { address: "의정부 신곡2동", district: "의정부시 을" },
  { address: "의정부 송산1동", district: "의정부시 을" },
  { address: "의정부 송산2동", district: "의정부시 을" },
  { address: "의정부 자금동", district: "의정부시 을" },
  { address: "안양 만안구 일원", district: "만안구" },
  { address: "안양 동안구 비산1동", district: "동안구 갑" },
  { address: "안양 동안구 비산2동", district: "동안구 갑" },
  { address: "안양 동안구 비산3동", district: "동안구 갑" },
  { address: "안양 동안구 부흥동", district: "동안구 갑" },
  { address: "안양 동안구 달안동", district: "동안구 갑" },
  { address: "안양 동안구 관양1동", district: "동안구 갑" },
  { address: "안양 동안구 관양2동", district: "동안구 갑" },
  { address: "안양 동안구 부림동", district: "동안구 갑" },
  { address: "안양 동안구 평촌동", district: "동안구 을" },
  { address: "안양 동안구 평안동", district: "동안구 을" },
  { address: "안양 동안구 귀인동", district: "동안구 을" },
  { address: "안양 동안구 호계1동", district: "동안구 을" },
  { address: "안양 동안구 호계2동", district: "동안구 을" },
  { address: "안양 동안구 호계3동", district: "동안구 을" },
  { address: "안양 동안구 범계동", district: "동안구 을" },
  { address: "안양 동안구 신촌동", district: "동안구 을" },
  { address: "안양 동안구 갈산동", district: "동안구 을" },
  { address: "부천 심곡1동", district: "부천시 갑" },
  { address: "부천 심곡2동", district: "부천시 갑" },
  { address: "부천 심곡3동", district: "부천시 갑" },
  { address: "부천 원미1동", district: "부천시 갑" },
  { address: "부천 원미2동", district: "부천시 갑" },
  { address: "부천 소사동", district: "부천시 갑" },
  { address: "부천 역곡1동", district: "부천시 갑" },
  { address: "부천 역곡2동", district: "부천시 갑" },
  { address: "부천 춘의동", district: "부천시 갑" },
  { address: "부천 도당동", district: "부천시 갑" },
  { address: "부천 약대동", district: "부천시 을" },
  { address: "부천 중동", district: "부천시 을" },
  { address: "부천 중1동", district: "부천시 을" },
  { address: "부천 중2동", district: "부천시 을" },
  { address: "부천 중3동", district: "부천시 을" },
  { address: "부천 중4동", district: "부천시 을" },
  { address: "부천 상동", district: "부천시 을" },
  { address: "부천 상1동", district: "부천시 을" },
  { address: "부천 상2동", district: "부천시 을" },
  { address: "부천 상3동 ", district: "부천시 을" },
  { address: "부천 심곡본1동", district: "부천시 병" },
  { address: "부천 심곡본동", district: "부천시 병" },
  { address: "부천 소사본동", district: "부천시 병" },
  { address: "부천 소사본3동", district: "부천시 병" },
  { address: "부천 범박동", district: "부천시 병" },
  { address: "부천 괴안동", district: "부천시 병" },
  { address: "부천 역곡3동", district: "부천시 병" },
  { address: "부천 송내1동", district: "부천시 병" },
  { address: "부천 송내2동", district: "부천시 병" },
  { address: "부천 성곡동", district: "부천시 정" },
  { address: "부천 원종1동", district: "부천시 정" },
  { address: "부천 원종2동", district: "부천시 정" },
  { address: "부천 고강본동", district: "부천시 정" },
  { address: "부천 고강1동", district: "부천시 정" },
  { address: "부천 오정동", district: "부천시 정" },
  { address: "부천 신흥동", district: "부천시 정" },
  { address: "광명 광명1동", district: "광명시 갑" },
  { address: "광명 광명2동", district: "광명시 갑" },
  { address: "광명 광명3동", district: "광명시 갑" },
  { address: "광명 광명4동", district: "광명시 갑" },
  { address: "광명 광명5동", district: "광명시 갑" },
  { address: "광명 광명6동", district: "광명시 갑" },
  { address: "광명 광명7동", district: "광명시 갑" },
  { address: "광명 철산1동", district: "광명시 갑" },
  { address: "광명 철산2동", district: "광명시 갑" },
  { address: "광명 철산4동", district: "광명시 갑" },
  { address: "광명 철산3동", district: "광명시 을" },
  { address: "광명 하안1동", district: "광명시 을" },
  { address: "광명 하안2동", district: "광명시 을" },
  { address: "광명 하안3동", district: "광명시 을" },
  { address: "광명 하안4동", district: "광명시 을" },
  { address: "광명 소하1동", district: "광명시 을" },
  { address: "광명 소하2동", district: "광명시 을" },
  { address: "광명 학온동", district: "광명시 을" },
  { address: "평택 진위면", district: "평택시 갑" },
  { address: "평택 서탄면", district: "평택시 갑" },
  { address: "평택 중앙동", district: "평택시 갑" },
  { address: "평택 서정동", district: "평택시 갑" },
  { address: "평택 송탄동", district: "평택시 갑" },
  { address: "평택 지산동", district: "평택시 갑" },
  { address: "평택 송북동", district: "평택시 갑" },
  { address: "평택 신장1동", district: "평택시 갑" },
  { address: "평택 신장2동", district: "평택시 갑" },
  { address: "평택 통복동", district: "평택시 갑" },
  { address: "평택 세교동", district: "평택시 갑" },
  { address: "평택 팽성읍", district: "평택시 을" },
  { address: "평택 안중읍", district: "평택시 을" },
  { address: "평택 포승읍", district: "평택시 을" },
  { address: "평택 청북읍", district: "평택시 을" },
  { address: "평택 고덕면", district: "평택시 을" },
  { address: "평택 오성면", district: "평택시 을" },
  { address: "평택 현덕면", district: "평택시 을" },
  { address: "평택 신평동", district: "평택시 을" },
  { address: "평택 원평동", district: "평택시 을" },
  { address: "평택 비전1동", district: "평택시 을" },
  { address: "평택 비전2동", district: "평택시 을" },
  { address: "동두천 일원", district: "동두천시 연천군" },
  { address: "연천군 일원", district: "동두천시 연천군" },
  { address: "안산 상록구 사1동", district: "상록구 갑" },
  { address: "안산 상록구 사2동", district: "상록구 갑" },
  { address: "안산 상록구 사3동", district: "상록구 갑" },
  { address: "안산 상록구 본오1동", district: "상록구 갑" },
  { address: "안산 상록구 본오2동", district: "상록구 갑" },
  { address: "안산 상록구 본오3동", district: "상록구 갑" },
  { address: "안산 상록구 반월동", district: "상록구 갑" },
  { address: "안산 상록구 일동", district: "상록구 을" },
  { address: "안산 상록구 이동", district: "상록구 을" },
  { address: "안산 상록구 부곡동", district: "상록구 을" },
  { address: "안산 상록구 월피동", district: "상록구 을" },
  { address: "안산 상록구 성포동", district: "상록구 을" },
  { address: "안산 상록구 안산동", district: "상록구 을" },
  { address: "안산 단원구 와동", district: "단원구 갑" },
  { address: "안산 단원구 원곡본동", district: "단원구 갑" },
  { address: "안산 단원구 원곡1동", district: "단원구 갑" },
  { address: "안산 단원구 원곡2동", district: "단원구 갑" },
  { address: "안산 단원구 선부1동", district: "단원구 갑" },
  { address: "안산 단원구 선부2동", district: "단원구 갑" },
  { address: "안산 단원구 선부3동", district: "단원구 갑" },
  { address: "안산 단원구 고잔1동", district: "단원구 을" },
  { address: "안산 단원구 고잔2동", district: "단원구 을" },
  { address: "안산 단원구 호수동", district: "단원구 을" },
  { address: "안산 단원구 초지동", district: "단원구 을" },
  { address: "안산 단원구 대부동", district: "단원구 을" },
  { address: "고양 덕양구 주교동", district: "고양시 갑" },
  { address: "고양 덕양구 원신동", district: "고양시 갑" },
  { address: "고양 덕양구 흥도동", district: "고양시 갑" },
  { address: "고양 덕양구 성사1동", district: "고양시 갑" },
  { address: "고양 덕양구 성사2동", district: "고양시 갑" },
  { address: "고양 덕양구 고양동", district: "고양시 갑" },
  { address: "고양 덕양구 관산동", district: "고양시 갑" },
  { address: "고양 덕양구 화정1동", district: "고양시 갑" },
  { address: "고양 덕양구 화정2동", district: "고양시 갑" },
  { address: "고양 일산동구 식사동", district: "고양시 갑" },
  { address: "고양 덕양구 효자동", district: "고양시 을" },
  { address: "고양 덕양구 신도동", district: "고양시 을" },
  { address: "고양 덕양구 창릉동", district: "고양시 을" },
  { address: "고양 덕양구 능곡동", district: "고양시 을" },
  { address: "고양 덕양구 행주동", district: "고양시 을" },
  { address: "고양 덕양구 행신1동", district: "고양시 을" },
  { address: "고양 덕양구 행신2동", district: "고양시 을" },
  { address: "고양 덕양구 행신3동", district: "고양시 을" },
  { address: "고양 덕양구 화전동", district: "고양시 을" },
  { address: "고양 덕양구 대덕동", district: "고양시 을" },
  { address: "고양 일산동구 일원", district: "고양시 병" },
  { address: "고양 일산서구 일산2동(식사동 제외)", district: "고양시 병" },
  { address: "고양 일산서구 일원(일산2동 제외)", district: "고양시 정" },
  { address: "의왕 의왕시 일원", district: "의왕시과천시" },
  { address: "과천 과천시 일원", district: "의왕시과천시" },
  { address: "구리 구리시 일원", district: "구리시" },
  { address: "남양주 화도읍", district: "남양주시 갑" },
  { address: "남양주 수동면", district: "남양주시 갑" },
  { address: "남양주 호평동", district: "남양주시 갑" },
  { address: "남양주 평내동", district: "남양주시 갑" },
  { address: "남양주 진접읍", district: "남양주시 을" },
  { address: "남양주 오남읍", district: "남양주시 을" },
  { address: "남양주 별내면", district: "남양주시 을" },
  { address: "남양주 별내동", district: "남양주시 을" },
  { address: "남양주 와부읍", district: "남양주시 병" },
  { address: "남양주 진건읍", district: "남양주시 병" },
  { address: "남양주 퇴계원읍", district: "남양주시 병" },
  { address: "남양주 조안면", district: "남양주시 병" },
  { address: "남양주 금곡동", district: "남양주시 병" },
  { address: "남양주 양정동", district: "남양주시 병" },
  { address: "남양주 지금동", district: "남양주시 병" },
  { address: "남양주 도농동", district: "남양주시 병" },
  { address: "오산 오산시 일원", district: "오산시" },
  { address: "시흥 대야동", district: "시흥시 갑" },
  { address: "시흥 신천동", district: "시흥시 갑" },
  { address: "시흥 신현동", district: "시흥시 갑" },
  { address: "시흥 은행동", district: "시흥시 갑" },
  { address: "시흥 매화동", district: "시흥시 갑" },
  { address: "시흥 목감동", district: "시흥시 갑" },
  { address: "시흥 과림동", district: "시흥시 갑" },
  { address: "시흥 연성동", district: "시흥시 갑" },
  { address: "시흥 장곡동", district: "시흥시 갑" },
  { address: "시흥 능곡동", district: "시흥시 갑" },
  { address: "시흥 군자동", district: "시흥시 을" },
  { address: "시흥 월곶동", district: "시흥시 을" },
  { address: "시흥 정왕본동", district: "시흥시 을" },
  { address: "시흥 정왕1동", district: "시흥시 을" },
  { address: "시흥 정왕2동", district: "시흥시 을" },
  { address: "시흥 정왕3동", district: "시흥시 을" },
  { address: "시흥 정왕4동", district: "시흥시 을" },
  { address: "군포 군포1동", district: "군포시 갑" },
  { address: "군포 군포2동", district: "군포시 갑" },
  { address: "군포 산본1동", district: "군포시 갑" },
  { address: "군포 금정동", district: "군포시 갑" },
  { address: "군포 대야동", district: "군포시 갑" },
  { address: "군포 산본2동", district: "군포시 을" },
  { address: "군포 재궁동", district: "군포시 을" },
  { address: "군포 오금동", district: "군포시 을" },
  { address: "군포 수리동", district: "군포시 을" },
  { address: "군포 궁내동", district: "군포시 을" },
  { address: "군포 광정동", district: "군포시 을" },
  { address: "하남 하남시 일원", district: "하남시" },
  { address: "용인 처인구 일원", district: "용인시 갑" },
  { address: "용인 기흥구 일원(구성동 보정동 제외)", district: "용인시 을" },
  { address: "용인 기흥구 마북동", district: "용인시 을" },
  { address: "용인 기흥구 동백동", district: "용인시 을" },
  { address: "용인 수지구 일원(죽전1,2동 제외)", district: "용인시 병" },
  { address: "용인 기흥구 구성동", district: "용인시 정" },
  { address: "용인 기흥구 마북동", district: "용인시 정" },
  { address: "용인 기흥구 동백동", district: "용인시 정" },
  { address: "용인 기흥구 보정동", district: "용인시 정" },
  { address: "용인 수지구 죽전1동", district: "용인시 정" },
  { address: "용인 수지구 죽전2동", district: "용인시 정" },
  { address: "파주 조리읍", district: "파주시 갑" },
  { address: "파주 광탄면", district: "파주시 갑" },
  { address: "파주 탄현면", district: "파주시 갑" },
  { address: "파주 교하동", district: "파주시 갑" },
  { address: "파주 운정1동", district: "파주시 갑" },
  { address: "파주 운정2동", district: "파주시 갑" },
  { address: "파주 운정3동", district: "파주시 갑" },
  { address: "파주 문산읍", district: "파주시 을" },
  { address: "파주 법원읍", district: "파주시 을" },
  { address: "파주 파주읍", district: "파주시 을" },
  { address: "파주 월롱면", district: "파주시 을" },
  { address: "파주 적성면", district: "파주시 을" },
  { address: "파주 파평면", district: "파주시 을" },
  { address: "파주 군내면", district: "파주시 을" },
  { address: "파주 진동면", district: "파주시 을" },
  { address: "파주 금촌1동", district: "파주시 을" },
  { address: "파주 금촌2동", district: "파주시 을" },
  { address: "파주 금촌3동", district: "파주시 을" },
  { address: "이천 이천시 일원", district: "이천시" },
  { address: "안성 안성시 일원", district: "안성시" },
  { address: "김포 고촌읍", district: "김포시 갑" },
  { address: "김포 김포1동", district: "김포시 갑" },
  { address: "김포 사우동", district: "김포시 갑" },
  { address: "김포 풍무동", district: "김포시 갑" },
  { address: "김포 장기동", district: "김포시 갑" },
  { address: "김포 통진읍", district: "김포시 을" },
  { address: "김포 양촌읍", district: "김포시 을" },
  { address: "김포 대곶면", district: "김포시 을" },
  { address: "김포 월곶면", district: "김포시 을" },
  { address: "김포 하성면", district: "김포시 을" },
  { address: "김포 김포2동", district: "김포시 을" },
  { address: "김포 구래동", district: "김포시 을" },
  { address: "김포 운양동", district: "김포시 을" },
  { address: "화성 우정읍", district: "화성시 갑" },
  { address: "화성 향남읍", district: "화성시 갑" },
  { address: "화성 남양읍", district: "화성시 갑" },
  { address: "화성 매송면", district: "화성시 갑" },
  { address: "화성 비봉면", district: "화성시 갑" },
  { address: "화성 마도면", district: "화성시 갑" },
  { address: "화성 송산면", district: "화성시 갑" },
  { address: "화성 서신면", district: "화성시 갑" },
  { address: "화성 팔탄면", district: "화성시 갑" },
  { address: "화성 장안면", district: "화성시 갑" },
  { address: "화성 양감면", district: "화성시 갑" },
  { address: "화성 정남면", district: "화성시 갑" },
  { address: "화성 동탄면", district: "화성시 을" },
  { address: "화성 동탄1동", district: "화성시 을" },
  { address: "화성 동탄2동", district: "화성시 을" },
  { address: "화성 동탄3동", district: "화성시 을" },
  { address: "화성 동탄4동", district: "화성시 을" },
  { address: "화성 봉담읍", district: "화성시 병" },
  { address: "화성 진안동", district: "화성시 병" },
  { address: "화성 병점1동", district: "화성시 병" },
  { address: "화성 병점2동", district: "화성시 병" },
  { address: "화성 반월동", district: "화성시 병" },
  { address: "화성 기배동", district: "화성시 병" },
  { address: "화성 화산동", district: "화성시 병" },
  { address: "광주 퇴촌면", district: "광주시 갑" },
  { address: "광주 남종면", district: "광주시 갑" },
  { address: "광주 남한산성면", district: "광주시 갑" },
  { address: "광주 경안동", district: "광주시 갑" },
  { address: "광주 송정동", district: "광주시 갑" },
  { address: "광주 광남동", district: "광주시 갑" },
  { address: "광주 오포읍", district: "광주시 을" },
  { address: "광주 초월읍", district: "광주시 을" },
  { address: "광주 곤지암읍", district: "광주시 을" },
  { address: "광주 도척면", district: "광주시 을" },
  { address: "양주 양주시 일원", district: "양주시" },
  { address: "포천 포천시 일원", district: "포천시가평군" },
  { address: "가평 가평군 일원", district: "포천시가평군" },
  { address: "여주 여주시 일원", district: "여주시양평군" },
  { address: "양평 양평군 일원", district: "여주시양평군" },
  { address: "춘천 춘천시 일원", district: "춘천시" },
  { address: "원주 문막읍", district: "원주시 갑" },
  { address: "원주 호저면", district: "원주시 갑" },
  { address: "원주 지정면", district: "원주시 갑" },
  { address: "원주 부론면", district: "원주시 갑" },
  { address: "원주 귀래면", district: "원주시 갑" },
  { address: "원주 중앙동", district: "원주시 갑" },
  { address: "원주 원인동", district: "원주시 갑" },
  { address: "원주 일산동", district: "원주시 갑" },
  { address: "원주 학성동", district: "원주시 갑" },
  { address: "원주 단계동", district: "원주시 갑" },
  { address: "원주 우산동", district: "원주시 갑" },
  { address: "원주 태장1동", district: "원주시 갑" },
  { address: "원주 태장2동", district: "원주시 갑" },
  { address: "원주 무실동", district: "원주시 갑" },
  { address: "원주 소초면", district: "원주시 을" },
  { address: "원주 흥업면", district: "원주시 을" },
  { address: "원주 판부면", district: "원주시 을" },
  { address: "원주 신림면", district: "원주시 을" },
  { address: "원주 개운동", district: "원주시 을" },
  { address: "원주 명륜1동", district: "원주시 을" },
  { address: "원주 명륜2동", district: "원주시 을" },
  { address: "원주 단구동", district: "원주시 을" },
  { address: "원주 봉산동", district: "원주시 을" },
  { address: "원주 행구동", district: "원주시 을" },
  { address: "원주 반곡관설동", district: "원주시 을" },
  { address: "강릉 강릉시 일원", district: "강릉시" },
  { address: "동해 동해시 일원", district: "동해시삼척시" },
  { address: "삼척 삼척시 일원", district: "동해시삼척시" },
  { address: "태백 태백시 일원", district: "태백/횡성/영월/평창/정선군" },
  { address: "횡성 횡성군 일원", district: "태백/횡성/영월/평창/정선군" },
  { address: "영월 영월군 일원", district: "태백/횡성/영월/평창/정선군" },
  { address: "평창 평창군 일원", district: "태백/횡성/영월/평창/정선군" },
  { address: "정선 정선군 일원", district: "태백/횡성/영월/평창/정선군" },
  { address: "속초 속초시 일원", district: "속초/고성/양양군" },
  { address: "고성 고성군 일원", district: "속초/고성/양양군" },
  { address: "양양 양양군 일원", district: "속초/고성/양양군" },
  { address: "홍천 홍천군 일원", district: "홍천/철원/화천/양구/인제군" },
  { address: "철원 철원군 일원", district: "홍천/철원/화천/양구/인제군" },
  { address: "화천 화천군 일원", district: "홍천/철원/화천/양구/인제군" },
  { address: "양구 양구군 일원", district: "홍천/철원/화천/양구/인제군" },
  { address: "인제 인제군 일원", district: "홍천/철원/화천/양구/인제군" },
  {
    address: "천안 동남구 일원(풍세면,광덕면,신방동,청룡동 제외)",
    district: "천안시 갑"
  },
  { address: "천안 서북구 성정1동", district: "천안시 갑" },
  { address: "천안 서북구 성정2동", district: "천안시 갑" },
  {
    address: "천안 서북구 일원(성정1동,성정2동,쌍용1동,쌍용2동,쌍용3동 제외)",
    district: "천안시 을"
  },
  { address: "천안 동남구 풍세면", district: "천안시 병" },
  { address: "천안 동남구 광덕면", district: "천안시 병" },
  { address: "천안 동남구 신방동", district: "천안시 병" },
  { address: "천안 동남구 청룡동", district: "천안시 병" },
  { address: "천안 서북구 쌍용1동", district: "천안시 병" },
  { address: "천안 서북구 쌍용2동", district: "천안시 병" },
  { address: "천안 서북구 쌍용3동", district: "천안시 병" },
  { address: "공주 공주시 일원", district: "공주시/부여군/청양군" },
  { address: "부여 부여군 일원", district: "공주시/부여군/청양군" },
  { address: "청양 청양군 일원", district: "공주시/부여군/청양군" },
  { address: "보령 보령시 일원", district: "보령시/서천군" },
  { address: "서천 서천군 일원", district: "보령시/서천군" },
  { address: "아산 선장면", district: "아산시 갑" },
  { address: "아산 도고면", district: "아산시 갑" },
  { address: "아산 신창면", district: "아산시 갑" },
  { address: "아산 온양1동", district: "아산시 갑" },
  { address: "아산 온양2동", district: "아산시 갑" },
  { address: "아산 온양3동", district: "아산시 갑" },
  { address: "아산 온양4동", district: "아산시 갑" },
  { address: "아산 온양5동", district: "아산시 갑" },
  { address: "아산 온양6동", district: "아산시 갑" },
  { address: "아산 염치읍", district: "아산시 을" },
  { address: "아산 배방읍", district: "아산시 을" },
  { address: "아산 송악면", district: "아산시 을" },
  { address: "아산 탕정면", district: "아산시 을" },
  { address: "아산 음봉면", district: "아산시 을" },
  { address: "아산 둔포면", district: "아산시 을" },
  { address: "아산 영인면", district: "아산시 을" },
  { address: "아산 인주면", district: "아산시 을" },
  { address: "서산 서산시 일원", district: "서산시/태안군" },
  { address: "태안 태안군 일원", district: "서산시/태안군" },
  { address: "논산 논산시 일원", district: "논산시/계룡시/금산군" },
  { address: "계룡 계룡시 일원", district: "논산시/계룡시/금산군" },
  { address: "금산 금산군 일원", district: "논산시/계룡시/금산군" },
  { address: "당진 당진시 일원", district: "당진시" },
  { address: "홍성 홍성군 일원", district: "홍성군/예산군" },
  { address: "예산 예산군 일원", district: "홍성군/예산군" },
  { address: "전주 완산구 중앙동", district: "전주시 갑" },
  { address: "전주 완산구 풍남동", district: "전주시 갑" },
  { address: "전주 완산구 노송동", district: "전주시 갑" },
  { address: "전주 완산구 완산동", district: "전주시 갑" },
  { address: "전주 완산구 동서학동", district: "전주시 갑" },
  { address: "전주 완산구 서서학동", district: "전주시 갑" },
  { address: "전주 완산구 중화산1동", district: "전주시 갑" },
  { address: "전주 완산구 중화산2동", district: "전주시 갑" },
  { address: "전주 완산구 평화1동", district: "전주시 갑" },
  { address: "전주 완산구 평화2동", district: "전주시 갑" },
  { address: "전주 덕진구 인후3동", district: "전주시 갑" },
  { address: "전주 완산구 서신동", district: "전주시 을" },
  { address: "전주 완산구 삼천1동", district: "전주시 을" },
  { address: "전주 완산구 삼천2동", district: "전주시 을" },
  { address: "전주 완산구 삼천3동", district: "전주시 을" },
  { address: "전주 완산구 효자1동", district: "전주시 을" },
  { address: "전주 완산구 효자2동", district: "전주시 을" },
  { address: "전주 완산구 효자3동", district: "전주시 을" },
  { address: "전주 완산구 효자4동", district: "전주시 을" },
  { address: "전주 덕진구 일원(인후3동 제외)", district: "전주시 병" },
  { address: "군산 군산시 일원", district: "군산시" },
  { address: "익산 익산시 함열읍", district: "익산시 갑" },
  { address: "익산 오산면", district: "익산시 갑" },
  { address: "익산 황등면", district: "익산시 갑" },
  { address: "익산 함라면", district: "익산시 갑" },
  { address: "익산 웅포면", district: "익산시 갑" },
  { address: "익산 성당면", district: "익산시 갑" },
  { address: "익산 용안면", district: "익산시 갑" },
  { address: "익산 용동면", district: "익산시 갑" },
  { address: "익산 중앙동", district: "익산시 갑" },
  { address: "익산 평화동", district: "익산시 갑" },
  { address: "익산 남중동", district: "익산시 갑" },
  { address: "익산 모현동", district: "익산시 갑" },
  { address: "익산 송학동", district: "익산시 갑" },
  { address: "익산 신동", district: "익산시 갑" },
  { address: "익산 인화동", district: "익산시 갑" },
  { address: "익산 마동", district: "익산시 갑" },
  { address: "익산 낭산면", district: "익산시 을" },
  { address: "익산 망성면", district: "익산시 을" },
  { address: "익산 여산면", district: "익산시 을" },
  { address: "익산 금마면", district: "익산시 을" },
  { address: "익산 왕궁면", district: "익산시 을" },
  { address: "익산 춘포면", district: "익산시 을" },
  { address: "익산 삼기면", district: "익산시 을" },
  { address: "익산 동산동", district: "익산시 을" },
  { address: "익산 영등1동", district: "익산시 을" },
  { address: "익산 영등2동", district: "익산시 을" },
  { address: "익산 어양동", district: "익산시 을" },
  { address: "익산 팔봉동", district: "익산시 을" },
  { address: "익산 삼성동", district: "익산시 을" },
  { address: "정읍 정읍시 일원", district: "정읍시/고창군" },
  { address: "고창 고창군 일원", district: "정읍시/고창군" },
  { address: "남원 남원시 일원", district: "남원시/임실군/순창군" },
  { address: "임실 임실군 일원", district: "남원시/임실군/순창군" },
  { address: "순창 순창군 일원", district: "남원시/임실군/순창군" },
  { address: "김제 김제시 일원", district: "김제시/부안군" },
  { address: "부안 부안군 일원", district: "김제시/부안군" },
  { address: "완주 완주군 일원", district: "완주/진안/무주/장수군" },
  { address: "진안 진안군 일원", district: "완주/진안/무주/장수군" },
  { address: "무주 무주군 일원", district: "완주/진안/무주/장수군" },
  { address: "장수 장수군 일원", district: "완주/진안/무주/장수군" },
  { address: "목포 목포시 일원", district: "목포시" },
  { address: "여수 돌산읍", district: "여수시 갑" },
  { address: "여수 남면", district: "여수시 갑" },
  { address: "여수 삼산면", district: "여수시 갑" },
  { address: "여수 동문동", district: "여수시 갑" },
  { address: "여수 한려동", district: "여수시 갑" },
  { address: "여수 중앙동", district: "여수시 갑" },
  { address: "여수 충무동", district: "여수시 갑" },
  { address: "여수 광림동", district: "여수시 갑" },
  { address: "여수 서강동", district: "여수시 갑" },
  { address: "여수 대교동", district: "여수시 갑" },
  { address: "여수 국동", district: "여수시 갑" },
  { address: "여수 월호동", district: "여수시 갑" },
  { address: "여수 여서동", district: "여수시 갑" },
  { address: "여수 문수동", district: "여수시 갑" },
  { address: "여수 미평동", district: "여수시 갑" },
  { address: "여수 만덕동", district: "여수시 갑" },
  { address: "여수 소라면", district: "여수시 을" },
  { address: "여수 율촌면", district: "여수시 을" },
  { address: "여수 화양면", district: "여수시 을" },
  { address: "여수 화정면", district: "여수시 을" },
  { address: "여수 둔덕동", district: "여수시 을" },
  { address: "여수 쌍봉동", district: "여수시 을" },
  { address: "여수 시전동", district: "여수시 을" },
  { address: "여수 여천동", district: "여수시 을" },
  { address: "여수 주삼동", district: "여수시 을" },
  { address: "여수 삼일동", district: "여수시 을" },
  { address: "여수 묘도동", district: "여수시 을" },
  { address: "순천 순천시 일원", district: "순천시" },
  { address: "나주 나주시 일원", district: "나주시/화순군" },
  { address: "화순 화순군 일원", district: "나주시/화순군" },
  { address: "광양 광양시 일원", district: "광양시/곡성군/구례군" },
  { address: "곡성 곡성군 일원", district: "광양시/곡성군/구례군" },
  { address: "구례 구례군 일원", district: "광양시/곡성군/구례군" },
  { address: "담양 담양군 일원", district: "담양/함평/영광/장성군" },
  { address: "함평 함평군 일원", district: "담양/함평/영광/장성군" },
  { address: "영광 영광군 일원", district: "담양/함평/영광/장성군" },
  { address: "장성 장성군 일원", district: "담양/함평/영광/장성군" },
  { address: "고흥 고흥군 일원", district: "고흥/보성/장흥/강진군" },
  { address: "보성 보성군 일원", district: "고흥/보성/장흥/강진군" },
  { address: "장흥 장흥군 일원", district: "고흥/보성/장흥/강진군" },
  { address: "강진 강진군 일원", district: "고흥/보성/장흥/강진군" },
  { address: "해남 해남군 일원", district: "해남/완도/진도군" },
  { address: "완도 완도군 일원", district: "해남/완도/진도군" },
  { address: "진도 진도군 일원", district: "해남/완도/진도군" },
  { address: "영암 영암군 일원", district: "영암/무안/신안군" },
  { address: "무안 무안군 일원", district: "영암/무안/신안군" },
  { address: "신안 신안군 일원", district: "영암/무안/신안군" },
  { address: "김천 김천시 일원", district: "김천시" },
  { address: "안동 안동시 일원", district: "안동시" },
  { address: "구미 송정동", district: "구미시 갑" },
  { address: "구미 원평1동", district: "구미시 갑" },
  { address: "구미 원평2동", district: "구미시 갑" },
  { address: "구미 지산동", district: "구미시 갑" },
  { address: "구미 도량동", district: "구미시 갑" },
  { address: "구미 선주원남동", district: "구미시 갑" },
  { address: "구미 형곡1동", district: "구미시 갑" },
  { address: "구미 형곡2동", district: "구미시 갑" },
  { address: "구미 공단1동", district: "구미시 갑" },
  { address: "구미 공단2동", district: "구미시 갑" },
  { address: "구미 광평동", district: "구미시 갑" },
  { address: "구미 상모사곡동", district: "구미시 갑" },
  { address: "구미 임오동", district: "구미시 갑" },
  { address: "구미 신평1동", district: "구미시 갑" },
  { address: "구미 신평2동", district: "구미시 갑" },
  { address: "구미 비산동", district: "구미시 갑" },
  { address: "구미 선산읍", district: "구미시 을" },
  { address: "구미 고아읍", district: "구미시 을" },
  { address: "구미 무을면", district: "구미시 을" },
  { address: "구미 옥성면", district: "구미시 을" },
  { address: "구미 도개면", district: "구미시 을" },
  { address: "구미 해평면", district: "구미시 을" },
  { address: "구미 산동면", district: "구미시 을" },
  { address: "구미 장천면", district: "구미시 을" },
  { address: "구미 인동동", district: "구미시 을" },
  { address: "구미 진미동", district: "구미시 을" },
  { address: "구미 양포동", district: "구미시 을" },
  { address: "영주 영주시 일원", district: "영주시/문경시/예천군" },
  { address: "문경 문경시 일원", district: "영주시/문경시/예천군" },
  { address: "예천 예천군 일원", district: "영주시/문경시/예천군" },
  { address: "영천 영천시 일원", district: "영천시/청도군" },
  { address: "청도 청도군 일원", district: "영천시/청도군" },
  { address: "상주 상주시 일원", district: "상주시/군위군/의성군/청송군" },
  { address: "군위 군위군 일원", district: "상주시/군위군/의성군/청송군" },
  { address: "의성 의성군 일원", district: "상주시/군위군/의성군/청송군" },
  { address: "청송 청송군 일원", district: "상주시/군위군/의성군/청송군" },
  { address: "경산 경산시 일원", district: "경산시" },
  { address: "영양 영양군 일원", district: "영양/영덕/봉화/울진군" },
  { address: "영덕 영덕군 일원", district: "영양/영덕/봉화/울진군" },
  { address: "봉화 봉화군 일원", district: "영양/영덕/봉화/울진군" },
  { address: "울진 울진군 일원", district: "영양/영덕/봉화/울진군" },
  { address: "고령 고령군 일원", district: "고령/성주/칠곡군" },
  { address: "성주 성주군 일원", district: "고령/성주/칠곡군" },
  { address: "칠곡 칠곡군 일원", district: "고령/성주/칠곡군" },
  { address: "창원 의창구 일원", district: "의창구" },
  { address: "창원 성산구 일원", district: "성산구" },
  { address: "창원 마산합포구 일원", district: "마산합포구" },
  { address: "창원 마산회원구 일원", district: "마산회원구" },
  { address: "창원 진해구 일원", district: "진해구" },
  { address: "진주 문산읍", district: "진주시 갑" },
  { address: "진주 내동면", district: "진주시 갑" },
  { address: "진주 정촌면", district: "진주시 갑" },
  { address: "진주 금곡면", district: "진주시 갑" },
  { address: "진주 명석면", district: "진주시 갑" },
  { address: "진주 대평면", district: "진주시 갑" },
  { address: "진주 수곡면", district: "진주시 갑" },
  { address: "진주 천전동", district: "진주시 갑" },
  { address: "진주 성북동", district: "진주시 갑" },
  { address: "진주 평거동", district: "진주시 갑" },
  { address: "진주 신안동", district: "진주시 갑" },
  { address: "진주 이현동", district: "진주시 갑" },
  { address: "진주 판문동", district: "진주시 갑" },
  { address: "진주 가호동", district: "진주시 갑" },
  { address: "진주 충무공동", district: "진주시 갑" },
  { address: "진주 진성면", district: "진주시 을" },
  { address: "진주 일반성면", district: "진주시 을" },
  { address: "진주 이반성면", district: "진주시 을" },
  { address: "진주 사봉면", district: "진주시 을" },
  { address: "진주 지수면", district: "진주시 을" },
  { address: "진주 대곡면", district: "진주시 을" },
  { address: "진주 금산면", district: "진주시 을" },
  { address: "진주 집현면", district: "진주시 을" },
  { address: "진주 미천면", district: "진주시 을" },
  { address: "진주 중앙동", district: "진주시 을" },
  { address: "진주 상봉동", district: "진주시 을" },
  { address: "진주 상대1동", district: "진주시 을" },
  { address: "진주 상대2동", district: "진주시 을" },
  { address: "진주 하대1동", district: "진주시 을" },
  { address: "진주 하대2동", district: "진주시 을" },
  { address: "진주 상평동", district: "진주시 을" },
  { address: "진주 초장동", district: "진주시 을" },
  { address: "통영 통영시 일원", district: "통영시/고성군" },
  { address: "고성 고성군 일원", district: "통영시/고성군" },
  { address: "사천 사천시 일원", district: "사천시/남해군/하동군" },
  { address: "남해 남해군 일원", district: "사천시/남해군/하동군" },
  { address: "하동 하동군 일원", district: "사천시/남해군/하동군" },
  { address: "김해 진영읍", district: "김해시 갑" },
  { address: "김해 한림면", district: "김해시 갑" },
  { address: "김해 생림면", district: "김해시 갑" },
  { address: "김해 상동면", district: "김해시 갑" },
  { address: "김해 대동면", district: "김해시 갑" },
  { address: "김해 동상동", district: "김해시 갑" },
  { address: "김해 회현동", district: "김해시 갑" },
  { address: "김해 부원동", district: "김해시 갑" },
  { address: "김해 북부동", district: "김해시 갑" },
  { address: "김해 활천동", district: "김해시 갑" },
  { address: "김해 삼안동", district: "김해시 갑" },
  { address: "김해 불암동", district: "김해시 갑" },
  { address: "김해 주촌면", district: "김해시 을" },
  { address: "김해 진례면", district: "김해시 을" },
  { address: "김해 내외동", district: "김해시 을" },
  { address: "김해 칠산서부동", district: "김해시 을" },
  { address: "김해 장유1동", district: "김해시 을" },
  { address: "김해 장유2동", district: "김해시 을" },
  { address: "김해 장유3동", district: "김해시 을" },
  { address: "밀양 밀양시 일원", district: "밀양시/의령군/함안군/창녕군" },
  { address: "의령 의령군 일원", district: "밀양시/의령군/함안군/창녕군" },
  { address: "함안 함안군 일원", district: "밀양시/의령군/함안군/창녕군" },
  { address: "창녕 창녕군 일원", district: "밀양시/의령군/함안군/창녕군" },
  { address: "거제 거제시 일원", district: "거제시" },
  { address: "양산 물금읍", district: "양산시 갑" },
  { address: "양산 원동면", district: "양산시 갑" },
  { address: "양산 상북면", district: "양산시 갑" },
  { address: "양산 하북면", district: "양산시 갑" },
  { address: "양산 중앙동", district: "양산시 갑" },
  { address: "양산 삼성동", district: "양산시 갑" },
  { address: "양산 강서동", district: "양산시 갑" },
  { address: "양산 동면", district: "양산시 을" },
  { address: "양산 양주동", district: "양산시 을" },
  { address: "양산 서창동", district: "양산시 을" },
  { address: "양산 소주동", district: "양산시 을" },
  { address: "양산 평산동", district: "양산시 을" },
  { address: "양산 덕계동", district: "양산시 을" },
  { address: "산청 일원", district: "산청/함양/거창/합천군" },
  { address: "함양 함양군 일원", district: "산청/함양/거창/합천군" },
  { address: "거창 거창군 일원", district: "산청/함양/거창/합천군" },
  { address: "합천 합천군 일원", district: "산청/함양/거창/합천군" },
  { address: "제주 한림읍", district: "제주시 갑" },
  { address: "제주 애월읍", district: "제주시 갑" },
  { address: "제주 한경면", district: "제주시 갑" },
  { address: "제주 추자면", district: "제주시 갑" },
  { address: "제주 삼도1동", district: "제주시 갑" },
  { address: "제주 삼도2동", district: "제주시 갑" },
  { address: "제주 용담1동", district: "제주시 갑" },
  { address: "제주 용담2동", district: "제주시 갑" },
  { address: "제주 오라동", district: "제주시 갑" },
  { address: "제주 연동", district: "제주시 갑" },
  { address: "제주 노형동", district: "제주시 갑" },
  { address: "제주 외도동", district: "제주시 갑" },
  { address: "제주 이호동", district: "제주시 갑" },
  { address: "제주 도두동", district: "제주시 갑" },
  { address: "제주 구좌읍", district: "제주시 을" },
  { address: "제주 조천읍", district: "제주시 을" },
  { address: "제주 우도면", district: "제주시 을" },
  { address: "제주 일도1동", district: "제주시 을" },
  { address: "제주 일도2동", district: "제주시 을" },
  { address: "제주 이도1동", district: "제주시 을" },
  { address: "제주 이도2동", district: "제주시 을" },
  { address: "제주 건입동", district: "제주시 을" },
  { address: "제주 화북동", district: "제주시 을" },
  { address: "제주 삼양동", district: "제주시 을" },
  { address: "제주 봉개동", district: "제주시 을" },
  { address: "제주 아라동", district: "제주시 을" },
  { address: "제주 서귀포시 일원", district: "서귀포시" }
];
