import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../constants/firestore_schema";

import { parseDoc } from "../utils";
import firebase from "firebase/compat";

/**
 * Subscribe to a document's live updates in firestore.
 *
 * When a document does not exists, this only returns `{ id: document_id }`.
 *
 * @param table Table name on FirestoreCollection.
 * @param id Document ID.
 * @param externalDocument Optional external document. When this is set,
 *        live data will not be fetched.
 * @param debug If true, a console message will be logged every time the doc is updated.
 */
export default function useFirestoreLiveDocument<T>(
  table: FirestoreCollection,
  id: string | undefined,
  externalDocument?: T,
  debug = false
): [T | undefined, boolean | undefined] {
  const [value, setValue] = useState<T>();
  const [exists, setExists] = useState<boolean>();
  useEffect(() => {
    if (externalDocument) {
      setValue(externalDocument);
      setExists(true);
    }

    if (!id) {
      setValue(undefined);
      setExists(undefined);
      return;
    }
    const unsubscriber = firebase
      .firestore()
      .collection(table)
      .doc(id)
      .onSnapshot(snapshot => {
        setExists(snapshot.exists);
        if (debug) {
          console.log("Updating", table);
        }
        setValue(parseDoc(snapshot));
      });
    return () => {
      unsubscriber();
    };
  }, [table, id, externalDocument, debug]);

  if (externalDocument) {
    return [externalDocument, !!externalDocument];
  }

  return [value, exists];
}

export function useCardSubscription(cardId: string) {
  return useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
}
