import React from "react";

export default function BackButton({ color }: { color: string }) {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62129 14.7071C8.23077 15.0976 7.5976 15.0976 7.20708 14.7071L-2.95639e-05 7.5L7.20708 0.292893C7.5976 -0.0976315 8.23077 -0.0976315 8.62129 0.292893C9.01182 0.683417 9.01182 1.31658 8.62129 1.70711L2.8284 7.5L8.62129 13.2929C9.01182 13.6834 9.01182 14.3166 8.62129 14.7071Z"
        fill={color}
      />
    </svg>
  );
}
