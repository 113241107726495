import firebase from "firebase/compat";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { addToCardIdStackUrl, parseDoc } from "../../../../utils/utils";
import { StanceTestConfiguration } from "../common/hashpage_shared";

export default function StanceTestIntro({
  hashtag,
  stanceTestConfiguration
}: {
  hashtag: string;
  stanceTestConfiguration: StanceTestConfiguration;
}) {
  const history = useHistory();

  const [stanceTestCard, setStanceTestCard] = useState<CardSchema>();

  useEffect(() => {
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .get()
      .then(parseDoc)
      .then(setStanceTestCard);
  }, [hashtag]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        cursor: "pointer"
      }}
      onClick={() => {
        firebase.analytics().logEvent("v4_click_hash_stance_test_button", {
          event_category: "hashcard",
          event_label: hashtag
        });
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, "TEST_" + hashtag)
        );
      }}
    >
      <div
        style={{
          whiteSpace: "pre",
          textAlign: "center",
          fontSize: 18,
          lineHeight: "1.5em",
          marginTop: 10
        }}
      >
        {stanceTestConfiguration.소개}
      </div>
      <div style={{ padding: 20 }}>
        {stanceTestCard?.hashtagMetadata?.photos?.[0] && (
          <img
            style={{ maxWidth: 200 }}
            src={stanceTestCard.hashtagMetadata.photos[0]}
            alt="hashtag_photo"
          />
        )}
      </div>
      <div
        style={{
          textAlign: "center",
          paddingTop: 10,
          paddingBottom: 20,
          color: "#2767C5",
          fontWeight: "bold",
          fontSize: 18
        }}
      >
        {stanceTestConfiguration.시작버튼}{" "}
        <span style={{ fontWeight: "normal" }}> ❯</span>
      </div>
    </div>
  );
}
