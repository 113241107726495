import { padZerosForTwoDigits } from "./utils";

export const SECOND = 1000;
export const SECONDS = SECOND;
export const MINUTE = 60 * SECONDS;
export const MINUTES = MINUTE;
export const HOUR = 60 * MINUTES;
export const HOURS = HOUR;
export const DAY = 24 * HOURS;
export const DAYS = DAY;
export const WEEK = DAY * 7;
export const WEEKS = WEEK;
export const MONTH = DAY * 30;
export const MONTHS = MONTH;

export const KOREA_TIMEZONE_DIFF = 9 * 3600 * 1000;

export function datetime(dateStr: string) {
  // SAMPLE
  // 2020-01-01
  // 2020-01-01 00:00
  // 2020-01-01 00:00:00
  // 2020/01/01
  // 2020/01/01 00:00
  // 2020/01/01 00:00:00
  // 2020/01/01T00:00:00

  const dateArr = dateStr
    .trim()
    .replace(/\//g, ",")
    .replace(/-/g, ",")
    .replace(/:/g, ",")
    .replace(/T/g, ",")
    .replace(/ /g, ",")
    .split(",");

  return new Date(
    dateArr[0] ? parseInt(dateArr[0]) : 2021,
    dateArr[1] ? parseInt(dateArr[1]) - 1 : 0,
    dateArr[2] ? parseInt(dateArr[2]) : 1,
    dateArr[3] ? parseInt(dateArr[3]) : 0,
    dateArr[4] ? parseInt(dateArr[4]) : 0,
    dateArr[5] ? parseInt(dateArr[5]) : 0
  );
}

export function toDateOnsetKoreaStandardTime(
  year: number,
  month: number,
  day: number
) {
  return new Date(
    Date.UTC(year, month - 1, day).valueOf() - KOREA_TIMEZONE_DIFF
  );
}

export function getKoreaStandardTimeWeekBoundary(timestamp: number) {
  const currentDate = todayInKorea(timestamp);
  const beginningOfToday = toDateOnsetKoreaStandardTime(
    currentDate.year,
    currentDate.month,
    currentDate.day
  );

  let dateDelta;
  switch (currentDate.dayOfWeek) {
    case "월":
      dateDelta = 0;
      break;
    case "화":
      dateDelta = 1;
      break;
    case "수":
      dateDelta = 2;
      break;
    case "목":
      dateDelta = 3;
      break;
    case "금":
      dateDelta = 4;
      break;
    case "토":
      dateDelta = 5;
      break;
    default:
      dateDelta = 6;
  }
  const beginningOfWeek = beginningOfToday.valueOf() - dateDelta * DAYS;

  return [beginningOfWeek, beginningOfWeek + WEEK];
}

export function formatKoreanDate({
  year,
  month,
  day,
  dayOfWeek
}: {
  year?: number;
  month?: number;
  day: number;
  dayOfWeek?: string;
}) {
  return `${year ? year + "년 " : ""}${month}월 ${day}일${
    dayOfWeek ? ` (${dayOfWeek})` : ""
  }`;
}

export function formatKoreanDateIso({
  year,
  month,
  day
}: {
  year: number;
  month: number;
  day: number;
}) {
  return (
    year + "-" + padZerosForTwoDigits(month) + "-" + padZerosForTwoDigits(day)
  );
}

export function todayInKorea(timestamp?: number) {
  const thisDay = timestamp ? new Date(timestamp) : new Date();

  const koreanISOThisDay = thisDay.toLocaleDateString("sv", {
    timeZone: "Asia/Seoul"
  });

  const koreanWeekOfDay = thisDay.toLocaleDateString("ko", {
    weekday: "short",
    timeZone: "Asia/Seoul"
  });

  return {
    year: parseInt(koreanISOThisDay.slice(0, 4)),
    month: parseInt(koreanISOThisDay.slice(5, 7)),
    day: parseInt(koreanISOThisDay.slice(8, 10)),
    dayOfWeek: koreanWeekOfDay
  };
}

export function getWeekNumber(dateFrom = new Date()) {
  const currentDate = dateFrom.getDate();
  const startOfMonth = new Date(dateFrom.setDate(1));
  const weekDay = startOfMonth.getDay();

  return Math.floor((weekDay - 1 + currentDate) / 7);
}

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  timeZone: "Asia/Seoul",
  hour12: false
};
