import React, { useEffect, useState } from "react";
import firebase from "firebase/compat";
import {
  CardAnswerStatsSchema,
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { toDateOnsetKoreaStandardTime } from "../../../utils/datetime";
import { getDocsFromSnapshot, listToMap, parseDoc } from "../../../utils/utils";
import { DotLoader } from "react-spinners";
import PortraitCardView from "../PortraitCardView/PortraitCardView";
import YearInReviewBack from "./YearInReviewBack";

export default function MonthInReviewPublish({
  year,
  month,
  count
}: {
  year: number;
  month: number;
  count: number;
}) {
  const [cardIds, setCardIds] = useState<string[]>();
  useEffect(() => {
    console.log("running");
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where(
        "featuredOnTodayFocusAt",
        ">=",
        toDateOnsetKoreaStandardTime(year, month, 1).valueOf()
      )
      .where(
        "featuredOnTodayFocusAt",
        "<",
        toDateOnsetKoreaStandardTime(year, month + 1, 1).valueOf()
      )
      .get()
      .then(getDocsFromSnapshot)
      .then(async (cards: CardSchema[]) => {
        console.log("cards", cards);
        const filteredCards = cards.filter(card =>
          [CardType.TOPIC, CardType.SURVEY_QUESTION, CardType.POST].includes(
            card.type
          )
        );

        const cardStatsMap: { [cardId: string]: number } = await Promise.all(
          filteredCards.map(card =>
            firebase
              .firestore()
              .collection(FirestoreCollection.CARD_ANSWER_STATS)
              .doc(card.id)
              .get()
              .then(parseDoc)
          )
        ).then((cardStats: CardAnswerStatsSchema[]) => {
          return listToMap(
            cardStats,
            cardStat => cardStat.id || "",
            cardStat => cardStat.count
          );
        });

        const cardByMonthSelected = filteredCards
          .sort((a, b) => cardStatsMap[b.id] - cardStatsMap[a.id])
          .slice(0, count)
          .map(card => card.id);

        setCardIds(cardByMonthSelected);
      });
  }, [year, month, count]);

  if (!cardIds) {
    return <DotLoader />;
  }

  console.log(cardIds);

  return (
    <div>
      {cardIds.map((cardId, i) => (
        <PortraitCardView
          cardId={cardId}
          key={cardId}
          headerTitle={`${month}월 ${i + 1}위`}
        />
      ))}

      <YearInReviewBack />
    </div>
  );
}
