import { Dialog, DialogContent } from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import CommentCard from "./CommentCard";

export default function CommentPopup({
  commentId,
  openComment,
  setOpenComment,
  disableExpressions = false
}: {
  commentId: string;

  openComment: boolean;
  setOpenComment: Dispatch<SetStateAction<boolean>>;
  disableExpressions?: boolean;
}) {
  const handleClose = () => {
    setOpenComment(false);
  };
  return (
    <div>
      <Dialog
        open={openComment}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"transparentPaperPlease"}
      >
        <DialogContent>
          {openComment && (
            <CommentCard
              commentId={commentId}
              showOriginalQuestion
              showFullText
              disableExpressions={disableExpressions}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
