/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import { padZerosForTwoDigits } from "../../../utils/utils";
import { todayInKorea } from "../../../utils/datetime";

export default function DateSelectionBar({
  selectedDate,
  setSelectedDate
}: {
  selectedDate: number;
  setSelectedDate: (dateValue: number) => void;
}) {
  const selected = todayInKorea(selectedDate);

  const { day, month, year, dayOfWeek } = todayInKorea(new Date().valueOf());

  return (
    <div
      css={css`
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        cursor: pointer;
      `}
    >
      <input
        id="date"
        type="date"
        defaultValue={
          year +
          "-" +
          padZerosForTwoDigits(month) +
          "-" +
          padZerosForTwoDigits(day)
        }
        value={
          selected.year +
          "-" +
          padZerosForTwoDigits(selected.month) +
          "-" +
          padZerosForTwoDigits(selected.day)
        }
        onChange={e => {
          setSelectedDate(new Date(e.target.value).valueOf());
        }}
        css={css`
          border: 0;
          width: 150px;
          font-size: 18px;
          font-weight: bold;
          background-color: #ffffff;
          font-family: "Noto Sans KR", sans-serif;
          border-radius: 10px;
          padding: 3px 3px 3px 10px;
        `}
      />
    </div>
  );
}
