/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import useDailyReportsDataForPeriod from "../../DailyReportsView/useDailyReportsDataForPeriod";
import {
  groupBy,
  mapValues,
  sumTwoAnswerCounts
} from "../../../../utils/utils";
import DailyReportHashtagIssueCardDetails from "./DailyReportHashtagIssueCardDetails";
import PortraitReportPage from "../PortraitReportPage";
import { DAYS } from "../../../../utils/datetime";

export default function DailyHotIssuesDetails({
  personHashtag
}: {
  personHashtag: string;
}) {
  const now = useMemo(() => {
    return new Date().valueOf();
  }, []);

  const { cardAnswerStatsMap, cards, cardAnswerTribeStatsMap } =
    useDailyReportsDataForPeriod({
      selectedDate: now,
      days: 3
    });

  if (!cards || !cardAnswerStatsMap) {
    return null;
  }

  const hashtagGroups = groupBy(
    cards,
    card => card.hashtags?.filter(hashtag => hashtag.length > 0)[0] || ""
  );

  const hashtagPopularity = mapValues(hashtagGroups, cards => {
    const filteredCards = cards
      .filter(card => (card.featuredOnNewsTimelineAt || 0) > now - 2 * DAYS)
      .map(card => cardAnswerStatsMap[card.id]);
    return filteredCards.reduce(sumTwoAnswerCounts, {
      o: 0,
      x: 0,
      "?": 0,
      count: 0
    });
  });

  const sortedHashtags = Object.keys(hashtagPopularity).sort(
    (hashtagA, hashtagB) =>
      (hashtagPopularity[hashtagB]?.count || 0) -
      (hashtagPopularity[hashtagA]?.count || 0)
  );

  return (
    <div>
      {sortedHashtags.slice(0, 2).map((hashtag, i) => (
        <PortraitReportPage key={hashtag}>
          <DailyReportHashtagIssueCardDetails
            hashtag={hashtag.replace("#", "")}
            hashtagCards={hashtagGroups[hashtag]}
            cardAnswerStatsMap={cardAnswerStatsMap}
            index={i}
            key={hashtag}
          />
        </PortraitReportPage>
      ))}
    </div>
  );
}
