export enum TribeExpression {
  HAPPY = "HAPPY",
  SAD = "SAD",
  NORMAL = "NORMAL",
  SERIOUS = "SERIOUS",
  ANGRY = "ANGRY"
}

export const TribeExpressionKoreanName: {
  [tribeExpression: string]: string;
} = {
  NORMAL: "쿨",
  ANGRY: "확그냥",
  HAPPY: "오오",
  SAD: "흑흑",
  SERIOUS: "진지"
};
