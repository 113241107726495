import firebase from "firebase/compat";
import React, { useContext, useEffect, useState } from "react";
import {
  AnswerSymbol,
  oxoColors,
  THERMOMETER_COLOR,
  TRIBE_INFO_BY_ID,
  UserAnswer
} from "../../../../../constants/enums";
import {
  CardSchema,
  CommentSchema,
  FirestoreCollection,
  MapCoordinatesSchema,
  QuestionAnswerMapSchema,
  UserProfileMetadataSchema
} from "../../../..//../constants/firestore_schema";
import { addCardViewCount } from "../../../../../lib/card_lib";
import {
  getDocsFromSnapshot,
  parseDoc,
  removeIdKey
} from "../../../../../utils/utils";
import UserMap, {
  SeriesType
} from "../../../../foundation/Charts/UserMap/UserMap-victory";
import { DataPropsContext } from "../../../../foundation/Contexts/DataPropsContext";
import CommentTabs from "../../../../topic/Question/Comments/CommentTabs";
import QuestionChart from "../../../../topic/Question/QuestionChart/QuestionChart";
import CardStatsGenderAgeChart from "../../../../cards/DetailViews/CardStatsGenderAgeChart";
import {
  HashtagConfiguration,
  parseYamlDescription,
  StanceTestConfiguration
} from "../../common/hashpage_shared";

export default function CommentsAndResultsForStanceTestResults({
  topCategoryName,
  cardId,
  highlightColor
}: {
  topCategoryName?: string;
  cardId: string;
  highlightColor: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [card, setCard] = useState<CardSchema>();
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(cardId)
      .onSnapshot(snapshot => setCard(parseDoc(snapshot)));
    return () => unsubscribe();
  }, [cardId]);

  const [comments, setComments] = useState<CommentSchema[]>();

  if (comments && card && currentUserId) {
    if (cardId && card.comments !== comments.length) {
      firebase
        .firestore()
        .collection(FirestoreCollection.CARDS)
        .doc(cardId)
        .set(
          {
            comments: comments.length
          },
          { merge: true }
        );
    }
  }
  useEffect(() => {
    if (cardId) {
      const commentsSubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.COMMENTS)
        .where("questionId", "==", cardId)
        .onSnapshot(snapshot => setComments(getDocsFromSnapshot(snapshot)));
      return () => commentsSubscribe();
    }
  }, [cardId]);

  const [questionAnswers, setQuestionAnswers] =
    React.useState<QuestionAnswerMapSchema>();

  useEffect(() => {
    setQuestionAnswers(undefined);
    setComments(undefined);
  }, [cardId]);

  useEffect(() => {
    if (cardId) {
      firebase
        .firestore()
        .collection(FirestoreCollection.QUESTION_ANSWER_MAP)
        .doc(cardId)
        .get()
        .then(snapshot => {
          setQuestionAnswers(removeIdKey(parseDoc(snapshot)));
        });
    }
  }, [cardId]);

  const currentUserMetadata = dataProps?.currentUserMetadata;

  useEffect(() => {
    addCardViewCount(cardId, currentUserId);
  }, []);

  if (
    !card ||
    !dataProps?.mapCoordinates ||
    (currentUserId && !currentUserMetadata)
  ) {
    return null;
  }
  const currentUserTribeId = currentUserMetadata?.tribeId || "1";

  const hashtagData: HashtagConfiguration = parseYamlDescription(
    card.hashtagMetadata?.description
  );

  const stanceTestConfiguration: StanceTestConfiguration | undefined =
    hashtagData.성향테스트;

  let series = undefined;

  if (questionAnswers) {
    series = getMapSeries(
      questionAnswers,
      dataProps.mapCoordinates,
      currentUserMetadata
    );
  }
  const isQuestionChartStatsEmpty = undefined;

  function getMapSeries(
    questionAnswers: QuestionAnswerMapSchema,
    mapCoordinates: MapCoordinatesSchema,
    userProfileMetadata: UserProfileMetadataSchema | undefined
  ) {
    const seriesData: { [seriesName: string]: number[][] } = {
      me: [],
      unanswered: []
    };

    const mapWidth = Math.min(window.screen.width, 750);

    seriesData[UserAnswer.O] = [];
    seriesData[UserAnswer.X] = [];
    seriesData[UserAnswer.DUNNO] = [];

    Object.keys(questionAnswers).forEach(coordinateId => {
      const arr = [
        questionAnswers[coordinateId].countO,
        questionAnswers[coordinateId].countX,
        questionAnswers[coordinateId].countDunno
      ];
      const i = arr.indexOf(Math.max(...arr));

      if (
        questionAnswers[coordinateId].x < -20 ||
        questionAnswers[coordinateId].x > 20 ||
        questionAnswers[coordinateId].y < -20 ||
        questionAnswers[coordinateId].y > 20
      ) {
        return;
      }
      if (i === 0) {
        seriesData[UserAnswer.O].push([
          questionAnswers[coordinateId].x,
          questionAnswers[coordinateId].y
        ]);
      } else if (i === 1) {
        seriesData[UserAnswer.X].push([
          questionAnswers[coordinateId].x,
          questionAnswers[coordinateId].y
        ]);
      } else if (i === 2) {
        seriesData[UserAnswer.DUNNO].push([
          questionAnswers[coordinateId].x,
          questionAnswers[coordinateId].y
        ]);
      }
    });

    const defaultSize = mapWidth / 350;

    Object.keys(mapCoordinates).map(coordinateId => {
      const mapCoordinate = mapCoordinates[coordinateId];
      seriesData["unanswered"].push([mapCoordinate.x, mapCoordinate.y]);
    });

    const series: SeriesType[] = [
      {
        name: AnswerSymbol.O,
        data: seriesData[UserAnswer.O] || [],
        color: "#FD8A87",
        size: defaultSize + 1
      },
      {
        name: AnswerSymbol.DUNNO,
        data: seriesData[UserAnswer.DUNNO] || [],
        color: "#FDE395",
        size: defaultSize + 1
      },
      {
        name: AnswerSymbol.X,
        data: seriesData[UserAnswer.X] || [],
        color: "#4998F7",
        size: defaultSize + 1
      },
      {
        name: "응답 안 함",
        data: seriesData["unanswered"] || [],
        color: oxoColors.OTHERS,
        size: defaultSize,
        opacity: 0.4
      }
    ];

    if (userProfileMetadata) {
      series.unshift({
        name: "나",
        data: [[userProfileMetadata.x, userProfileMetadata.y]],
        color: "black",
        label: userProfileMetadata.tribeId
          ? `${userProfileMetadata.modifier} ${
              TRIBE_INFO_BY_ID[userProfileMetadata.tribeId].name
            }`
          : "나",
        size: 4
      });
    }

    return series;
  }

  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <div
        style={{
          minHeight: "430px",
          paddingTop: "30px"
        }}
      >
        {currentUserTribeId && comments && (
          <CommentTabs
            comments={comments}
            currentTribeId={currentUserTribeId}
            cardId={cardId}
            disableExpressions
          />
        )}
      </div>

      <div>
        {topCategoryName && (
          <div
            style={{
              marginTop: "50px",
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "20px"
            }}
          >
            {stanceTestConfiguration?.결과지도명
              ? stanceTestConfiguration.결과지도명
              : `성향별 ${topCategoryName} 결과`}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {stanceTestConfiguration?.결과지도카테고리 && (
            <>
              <div style={{ color: THERMOMETER_COLOR.O, margin: 5 }}>
                {stanceTestConfiguration?.결과지도카테고리?.[0]}
              </div>

              <div style={{ color: TRIBE_INFO_BY_ID[1].heavyColor, margin: 5 }}>
                {stanceTestConfiguration?.결과지도카테고리?.[1]}
              </div>
              {topCategoryName && (
                <div style={{ color: THERMOMETER_COLOR.X, margin: 5 }}>
                  {stanceTestConfiguration?.결과지도카테고리?.[2]}
                </div>
              )}
            </>
          )}

          {!stanceTestConfiguration?.결과지도카테고리 && topCategoryName && (
            <>
              <div style={{ color: THERMOMETER_COLOR.O, margin: 5 }}>
                {`친${topCategoryName} 성향`}
              </div>

              <div style={{ color: THERMOMETER_COLOR.DUNNO, margin: 5 }}>
                중립
              </div>
              <div style={{ color: THERMOMETER_COLOR.X, margin: 5 }}>
                {`반${topCategoryName} 성향`}
              </div>
            </>
          )}
        </div>

        <div style={{ marginBottom: "60px", maxHeight: 700 }}>
          {series && (
            <div style={{ marginTop: "10px" }}>
              <UserMap series={series} />
              <div style={{ marginTop: "20px" }}>
                <QuestionChart
                  cardId={cardId}
                  colorScale={["#FD8A87", "#FDE395", "#4998F7"]}
                />
              </div>
            </div>
          )}
        </div>

        <CardStatsGenderAgeChart
          cardId={cardId}
          title={"나이대별 테스트 결과"}
          colors={[
            THERMOMETER_COLOR.O,
            THERMOMETER_COLOR.DUNNO,
            THERMOMETER_COLOR.X
          ]}
        />
      </div>
    </div>
  );
}
