import { Button, Popover } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { StanceTestConfiguration } from "../common/hashpage_shared";
import DetailsButton from "./DetailsButton";

export default function StanceTestQuestionsIntro2({
  onNext,

  stanceTestConfiguration
}: {
  onNext: () => void;
  stanceTestConfiguration: StanceTestConfiguration;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  const stanceTestMainColor = stanceTestConfiguration.메인컬러
    ? stanceTestConfiguration.메인컬러
    : "#3E7A99";

  const buttonStyle: CSSProperties = {
    paddingLeft: 20,
    marginTop: 16,
    border: "solid 1px " + stanceTestMainColor,
    color: stanceTestMainColor,
    borderRadius: 10,
    cursor: "pointer",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    height: 73,
    width: 297
  };

  const detailExpanation = "이렇게 자세한 내용이 나와요";

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = (e: Event) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 22
      }}
    >
      <div style={{ textAlign: "center", fontSize: 18, fontWeight: "bold" }}>
        <div>자세한 내용이 궁금하시면</div>
        <div style={{ paddingTop: 10 }}>
          <span
            style={{
              fontWeight: "bold",
              color: stanceTestMainColor,
              cursor: "pointer",
              borderBottom: `0.5px solid ${stanceTestMainColor}`
            }}
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <div
                style={{
                  padding: 17,
                  fontSize: 16,
                  backgroundColor: "#363636",
                  maxWidth: 328,
                  lineHeight: "1.5em",
                  fontWeight: "normal",
                  color: "white"
                }}
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                단어를 설명해드려요
              </div>
            </Popover>
            밑줄이 있는 단어
          </span>
          를 눌러보세요.
        </div>
      </div>
      <div
        style={{
          ...buttonStyle
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: 50,
            fontSize: 16
          }}
        >
          <div>자세한 내용이 궁금하시면</div>
          <div>물음표를 눌러보세요~~!!!</div>
        </div>

        <DetailsButton
          text={detailExpanation}
          buttonColor={stanceTestMainColor}
        />
      </div>
      <Button
        style={{
          display: "flex",
          backgroundColor: stanceTestMainColor,
          color: "white",
          borderRadius: 12,
          padding: "10px 20px",
          width: 297,
          marginTop: 190,
          height: 73
        }}
        onClick={() => onNext()}
      >
        <div>
          <div style={{ fontWeight: "bold" }}>다음</div>
        </div>
      </Button>
    </div>
  );
}
