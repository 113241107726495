/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { range } from "lodash";
import { css } from "@emotion/react";
import OXOButton from "../../foundation/OXOButton/OXOButton";
import { useHistory } from "react-router";
import { ROUTES } from "../../../constants/routes";

const yearRange = range(2021, new Date().getFullYear() + 1);

const pageCss = css`
  padding: 20px;
`;
export default function MonthInReview() {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [count, setCount] = useState<number>(3);
  const history = useHistory();
  return (
    <div css={pageCss}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Select
          value={year}
          onChange={selectYear =>
            setYear(parseInt(selectYear.target.value as string))
          }
        >
          {yearRange.map(yearOption => {
            return (
              <MenuItem value={yearOption} key={yearOption}>
                {yearOption}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          value={month}
          css={css`
            margin-left: 10px;
          `}
          onChange={selectMonth =>
            setMonth(parseInt(selectMonth.target.value as string))
          }
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(monthOption => {
            return (
              <MenuItem value={monthOption} key={monthOption}>
                {monthOption}
              </MenuItem>
            );
          })}
        </Select>

        <div
          css={css`
            margin-left: 10px;
          `}
        >
          상위{" "}
          <input
            css={css`
              width: 50px;
            `}
            type="number"
            value={count}
            onChange={v => setCount(parseInt(v.target.value))}
          />
          개
        </div>
      </div>
      <div
        css={css`
          width: 200px;
          margin-top: 10px;
        `}
      >
        <OXOButton
          label={"보고서 출력"}
          onClick={() => {
            history.push(
              ROUTES.MONTH_IN_REVIEW_PUBLISH +
                "/" +
                year +
                "/" +
                month +
                "/" +
                count
            );
          }}
        />
      </div>
    </div>
  );
}
