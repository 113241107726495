/** @jsxImportSource @emotion/react */

import React from "react";
import {
  COUNT_BACKGROUND,
  CSS_TD,
  RATIO_BACKGROUND
} from "../../DataExplorerView/shared";

export default function RegionTribeTable({
  regionTribes
}: {
  regionTribes: { [region: string]: { [tribeId: string]: number } };
}) {
  if (!regionTribes) {
    return null;
  }

  return (
    <table>
      <thead>
        <tr>
          <td css={[CSS_TD]}>지역</td>
          <td css={[CSS_TD]}>호랑이</td>
          <td css={[CSS_TD]}>하마</td>
          <td css={[CSS_TD]}>코끼리</td>
          <td css={[CSS_TD]}>공룡</td>
          <td css={[CSS_TD]}>사자</td>
          <td css={[COUNT_BACKGROUND, CSS_TD]}>계</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>호랑이%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>하마%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>코끼리%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>공룡%</td>
          <td css={[RATIO_BACKGROUND, CSS_TD]}>사자%</td>
        </tr>
      </thead>
      <tbody>
        {Object.keys(regionTribes)
          .sort()
          .map(region => {
            const sum = Object.values(regionTribes[region]).reduce(
              (a, b) => a + b
            );

            return (
              <tr key={region}>
                <td css={[CSS_TD]}>{region}</td>
                <td css={[CSS_TD]}>{regionTribes[region]["1"] ?? 0}</td>
                <td css={[CSS_TD]}>{regionTribes[region]["2"] ?? 0}</td>
                <td css={[CSS_TD]}>{regionTribes[region]["3"] ?? 0}</td>
                <td css={[CSS_TD]}>{regionTribes[region]["4"] ?? 0}</td>
                <td css={[CSS_TD]}>{regionTribes[region]["5"] ?? 0}</td>
                <td css={[CSS_TD, COUNT_BACKGROUND]}>{sum}</td>
                <td css={[RATIO_BACKGROUND, CSS_TD]}>
                  {(((regionTribes[region]["1"] ?? 0) / sum) * 100).toFixed(1)}%
                </td>
                <td css={[RATIO_BACKGROUND, CSS_TD]}>
                  {(((regionTribes[region]["2"] ?? 0) / sum) * 100).toFixed(1)}%
                </td>
                <td css={[RATIO_BACKGROUND, CSS_TD]}>
                  {(((regionTribes[region]["3"] ?? 0) / sum) * 100).toFixed(1)}%
                </td>
                <td css={[RATIO_BACKGROUND, CSS_TD]}>
                  {(((regionTribes[region]["4"] ?? 0) / sum) * 100).toFixed(1)}%
                </td>
                <td css={[RATIO_BACKGROUND, CSS_TD]}>
                  {(((regionTribes[region]["5"] ?? 0) / sum) * 100).toFixed(1)}%
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
