import { Button } from "@material-ui/core";
import firebase from "firebase/compat";

import React from "react";
import {
  FACEBOOK_FONT_BLACK,
  GRAY_DARKER,
  UserAnswer
} from "../../../constants/enums";
import MiniO from "./icon/MiniO";
import MiniDunno from "./icon/MiniDunno";
import MiniX from "./icon/MiniX";
import {
  ANSWER_DUNNO_BACKGROUND,
  ANSWER_DUNNO_STROKE,
  ANSWER_O_BACKGROUND,
  ANSWER_O_STROKE,
  ANSWER_X_BACKGROUND,
  ANSWER_X_STROKE
} from "../../../constants/colors";

function getOxoButton(onVote: (answer: string) => void, userAnswer?: string) {
  return (buttonAnswer: string, activeColor: string, strokeColor: string) => {
    return (
      <Button
        onClick={() => {
          onVote(buttonAnswer);
        }}
        style={{
          width: "100%",
          height: 35,
          border: `1px solid ${
            userAnswer === buttonAnswer ? strokeColor : GRAY_DARKER
          }`,
          borderRadius: 5,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          backgroundColor: userAnswer === buttonAnswer ? activeColor : "white",
          margin: "0px 5px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "center"
          }}
        >
          {buttonAnswer === UserAnswer.O && (
            <MiniO width={14} height={14} fill={FACEBOOK_FONT_BLACK} />
          )}
          {buttonAnswer === UserAnswer.DUNNO && (
            <MiniDunno width={14} height={14} fill={FACEBOOK_FONT_BLACK} />
          )}
          {buttonAnswer === UserAnswer.X && (
            <MiniX width={14} height={14} fill={FACEBOOK_FONT_BLACK} />
          )}
          <span
            style={{
              fontSize: 15,
              paddingLeft: 10
            }}
          >
            {buttonAnswer === UserAnswer.O && "좋아요"}
            {buttonAnswer === UserAnswer.DUNNO && "글쎄요"}
            {buttonAnswer === UserAnswer.X && "싫어요"}
          </span>
        </div>
      </Button>
    );
  };
}

export function MiniOxButtonForHashCard({
  answer,
  onVote
}: {
  answer?: string;
  onVote: (answer: string) => void;
}) {
  const oxoButton = getOxoButton(onVote, answer);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        height: 35,
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "30px",
        backgroundColor: "white"
      }}
      onClick={() => {
        firebase.analytics().logEvent("v4_click_mini_ox_button", {
          event_category: "hashcard"
        });
      }}
    >
      {oxoButton(UserAnswer.O, ANSWER_O_BACKGROUND, ANSWER_O_STROKE)}
      {oxoButton(
        UserAnswer.DUNNO,
        ANSWER_DUNNO_BACKGROUND,
        ANSWER_DUNNO_STROKE
      )}
      {oxoButton(UserAnswer.X, ANSWER_X_BACKGROUND, ANSWER_X_STROKE)}
    </div>
  );
}
