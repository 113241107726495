import React, { CSSProperties } from "react";

export default function OXOProgressBar({
  color,
  progress
}: {
  color: string;
  progress: number;
}) {
  const containerStyles: CSSProperties = {
    height: 3,
    width: "100%",
    backgroundColor: "#F2F2F2"
  };

  const fillerStyles: CSSProperties = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: color,
    borderRadius: "inherit",
    textAlign: "right"
  };

  const labelStyles: CSSProperties = {
    padding: 3,
    color: "white",
    fontWeight: "bold"
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles} />
      </div>
    </div>
  );
}
