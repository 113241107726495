/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CommentStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import { css } from "@emotion/react";

export default function CommentStatsForCardId({ cardId }: { cardId: string }) {
  const commentStats = useFirestoreGetDocument<CommentStatsSchema>(
    FirestoreCollection.COMMENT_STATS,
    cardId
  );

  if (!commentStats) {
    return null;
  }

  return (
    <table
      css={css`
        width: 500px;
      `}
    >
      <tr>
        <td>호</td>
        <td>하</td>
        <td>코</td>
        <td>공</td>
        <td>사</td>
        <td>계</td>
      </tr>
      <tr>
        <td>{commentStats.commentCountsPerTribe?.["1"] || 0}</td>
        <td>{commentStats.commentCountsPerTribe?.["2"] || 0}</td>
        <td>{commentStats.commentCountsPerTribe?.["3"] || 0}</td>
        <td>{commentStats.commentCountsPerTribe?.["4"] || 0}</td>
        <td>{commentStats.commentCountsPerTribe?.["5"] || 0}</td>
        <td>
          {commentStats.commentCountsPerTribe &&
            Object.values(commentStats.commentCountsPerTribe).reduce(
              (a, b) => a + b,
              0
            )}
        </td>
      </tr>
    </table>
  );
}
