import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardFeatureStatus,
  CardSchema,
  CardSubType,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { addToIndex } from "../../../lib/search";
import { parseDoc, useIsAdmin } from "../../../utils/utils";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import { DataPropsContext } from "../../foundation/Contexts/DataPropsContext";
import { AlgoliaContext } from "../SearchView/AlgoliaContext";

import {
  getDefaultHashtagYaml,
  HashtagConfiguration,
  parseYamlConfigurationWithDefault
} from "./common/hashpage_shared";

import firebase from "firebase/compat";
import { ROUTES } from "../../../constants/routes";
import HashPageSubTypePeople from "./Profile/HashPageSubTypePeople";
import DetailPagesNavigation from "../../cards/DetailViews/DetailPagesNavigation";
import SwipeableViews from "react-swipeable-views";
import HashCardDetailPagesRenderer from "./HashCardDetailPages/HashCardDetailPagesRenderer";

export default function HashPageView({ hashtag }: { hashtag: string }) {
  const [windowWidth, windowHeight] = useWindowSize();
  const history = useHistory();
  const dataProps = useContext(DataPropsContext);

  const [tabIndex, setTabIndex] = useState<number>(1);

  const currentUserMetadata = dataProps?.currentUserMetadata;

  const [card, setCard] = useState<CardSchema>();
  const algoliaClient = useContext(AlgoliaContext);
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (hashtag.startsWith("#")) {
      window.alert("잘못된 해시태그입니다.");
      history.go(-1);
      return;
    }

    if (hashtag === "부족성향테스트" && !isAdmin) {
      history.go(-1);
      return;
    }

    const unsubscriber = firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .doc(hashtag)
      .onSnapshot(snapshot => {
        const cardSnapshot: CardSchema = parseDoc(snapshot);
        setCard(cardSnapshot);
        if (
          cardSnapshot.type === undefined &&
          !hashtag.match("^[0-9a-zA-Z-]+$")
        ) {
          const cardSnapshot = {
            displayInOnboarding: false,
            displayToUsers: true,
            featuredOnNewsTimeline: CardFeatureStatus.OFF,
            featuredOnTodayFocus: CardFeatureStatus.OFF,
            featuredOnTodayTimeline: CardFeatureStatus.OFF,
            featuredOnCommunityTimeline: CardFeatureStatus.OFF,
            type: CardType.HASHTAG_CARD,
            id: hashtag,
            answers: 0,
            comments: 0,
            createdByTribeId: currentUserMetadata?.tribeId || "1",
            createdBy: "oxo",
            cardScore: new Date().getTime(),
            title: hashtag,
            createdAt: new Date().getTime(),
            publishedAt: new Date().getTime(),
            views: 0,
            campaigns: []
          } as CardSchema;
          firebase
            .firestore()
            .collection(FirestoreCollection.CARDS)
            .doc(hashtag)
            .set(cardSnapshot, { merge: true });
          if (algoliaClient) {
            addToIndex(algoliaClient, "해시태그", hashtag, cardSnapshot);
          }
          setCard(cardSnapshot);
        }
      });
    return () => {
      unsubscriber();
    };
  }, []);

  const [backClicked, setBackClicked] = useState<boolean>(false);

  useEffect(() => {
    setBackClicked(false);
  }, [hashtag]);

  const onClose = () => {
    if (!backClicked) {
      history.go(-1);
      setBackClicked(true);
    }
  };

  const hashPageConfiguration: HashtagConfiguration | undefined =
    useMemo(() => {
      return (
        card &&
        parseYamlConfigurationWithDefault(
          card?.hashtagMetadata?.description,
          hashtag,
          getDefaultHashtagYaml(card.subType, hashtag)
        )
      );
    }, [card, hashtag]);

  useEffect(() => {
    if (
      hashPageConfiguration?.성향테스트 &&
      hashPageConfiguration.성향테스트.단독사용
    ) {
      if (isAdmin) {
        const result = window.confirm(
          "[admin only] 성향테스트 관리페이지로 이동할까요?"
        );
        if (!result) {
          history.push(ROUTES.URL_STACK + `/TEST_${hashtag}`);
        }
      } else {
        history.push(ROUTES.URL_STACK + `/TEST_${hashtag}`);
      }
    }
  }, [hashPageConfiguration]);

  if (!card || !hashtag) {
    if (!hashtag) {
      console.log("cardID is missing in TodayTopicCard!");
    }
    return null;
  }

  if (hashtag.match("^[0-9a-zA-Z-]+$")) {
    history.push(ROUTES.HOME);
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        width: Math.min(750, windowWidth),
        textAlign: "left",
        height: "100%",
        overflow: "auto"
      }}
    >
      {card.subType === CardSubType.HASHTAG_PERSON ||
      card.subType === CardSubType.HASHTAG_ORGANIZATION ? (
        <div>
          <div
            style={{
              width: "100%",
              height: 150,
              padding: "10px 0px 20px 0px"
            }}
          >
            <HashPageSubTypePeople cardId={card.id} />
          </div>

          <div
            style={{
              width: Math.min(windowWidth, 750)
            }}
          >
            {hashPageConfiguration && (
              <DetailPagesNavigation
                menuItemArray={getHashPageMenu(card, hashPageConfiguration)}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            )}
          </div>
          <div style={{ width: Math.min(windowWidth, 750) }}>
            <SwipeableViews
              enableMouseEvents
              index={tabIndex + 1}
              onChangeIndex={(i, prevI) => {
                if (i === 0) {
                  onClose && onClose();
                }
                setTabIndex(i - 1);
              }}
            >
              <div
                key={"close"}
                style={{
                  width: Math.min(windowWidth, 750),
                  marginTop: 140
                }}
              />
              {hashPageConfiguration &&
                getHashPageMenu(card, hashPageConfiguration).map(
                  (tabTitle, i) => {
                    if (i === tabIndex) {
                      return (
                        <div
                          style={{
                            maxHeight: windowHeight - 250
                          }}
                          key={tabTitle}
                        >
                          <HashCardDetailPagesRenderer
                            cardId={card.id}
                            tab={tabTitle}
                          />
                        </div>
                      );
                    } else {
                      return <div key={tabTitle}></div>;
                    }
                  }
                )}
            </SwipeableViews>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              width: Math.min(windowWidth, 750),
              position: "fixed",
              zIndex: 20012,
              top: 50
            }}
          >
            {hashPageConfiguration && (
              <DetailPagesNavigation
                menuItemArray={getHashPageMenu(card, hashPageConfiguration)}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            )}
          </div>
          <div style={{ paddingTop: 48 }} />
          <div style={{ width: "100%" }}>
            <SwipeableViews
              enableMouseEvents
              index={tabIndex + 1}
              onChangeIndex={(i, prevI) => {
                if (i === 0) {
                  onClose && onClose();
                }
                setTabIndex(i - 1);
              }}
            >
              <div
                key={"close"}
                style={{ width: Math.min(windowWidth, 750), marginTop: 140 }}
              />
              {hashPageConfiguration &&
                getHashPageMenu(card, hashPageConfiguration).map(
                  (tabTitle, i) => {
                    if (i === tabIndex) {
                      return (
                        <div
                          style={{
                            minHeight: windowHeight - 110
                          }}
                          key={tabTitle}
                        >
                          <HashCardDetailPagesRenderer
                            cardId={card.id}
                            tab={tabTitle}
                          />
                        </div>
                      );
                    } else {
                      return <div key={tabTitle}></div>;
                    }
                  }
                )}
            </SwipeableViews>
          </div>
        </div>
      )}
    </div>
  );
}

function getHashPageMenuTemplate(
  cardSubType: CardSubType = CardSubType.HASHTAG_PERSON
): string[] {
  switch (cardSubType) {
    case CardSubType.HASHTAG_PERSON:
      return ["스토리", "댓글", "톡", "지지율"];
    case CardSubType.HASHTAG_ORGANIZATION:
      return ["스토리", "댓글", "톡", "지지율"];
    case CardSubType.HASHTAG_ISSUE:
      return ["내용", "스토리", "댓글", "톡"];
    case CardSubType.HASHTAG_ELECTION:
      return ["내용", "맥락", "댓글", "톡"];
    case CardSubType.HASHTAG_STANCETEST:
      return ["테스트", "댓글", "톡", "지지율"];
    default:
      return ["스토리", "댓글", "톡", "지지율"];
  }
}

function getHashPageMenu(
  card: CardSchema,
  hashPageConfiguration: HashtagConfiguration
): string[] {
  let defaultMenu: string[] = getHashPageMenuTemplate(card.subType);

  const HashCardYamlData: string[] = Object.keys(hashPageConfiguration);

  if (
    HashCardYamlData.includes("성향테스트") &&
    hashPageConfiguration.성향테스트 &&
    !hashPageConfiguration.성향테스트.단독사용
  ) {
    defaultMenu = ["테스트", ...defaultMenu];
  }

  if (HashCardYamlData.includes("공약비교")) {
    defaultMenu = [...defaultMenu, "공약비교"];
  }

  return defaultMenu;
}
