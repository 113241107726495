/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import { CardSchema } from "../../../constants/firestore_schema";
import useGetHashtagHierarchy from "./useGetHashtagHierarchy";
import HashCommentBubble from "../HashCardView/common/HashCommentBubble";
import { css } from "@emotion/react";
import { TodayPolitician } from "./TodayPolitician";

export function DailyPartyCard({
  hashtag,
  cards
}: {
  hashtag: string;
  cards: CardSchema[];
}) {
  const hashtags = useMemo(
    () =>
      cards.flatMap(
        card => card.hashtags?.map(hashtag => hashtag.replace("#", "")) || []
      ),
    [cards]
  );
  const hashtagHierarchy = useGetHashtagHierarchy(hashtags);

  const relatedCards = useMemo(() => {
    if (!hashtagHierarchy) {
      return undefined;
    }

    const topLevelHashtags = hashtagHierarchy[hashtag] || [];

    const secondLevelHashtags =
      topLevelHashtags?.flatMap(hashtag => hashtagHierarchy[hashtag]) || [];

    const allHashtags: string[] = [
      ...topLevelHashtags,
      ...secondLevelHashtags
    ].filter(a => a);

    return cards.filter(card =>
      card.hashtags?.some(hashtag =>
        allHashtags.includes(hashtag.replace("#", ""))
      )
    );
  }, [hashtagHierarchy]);

  if (!hashtagHierarchy || !relatedCards) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          font-weight: bold;
          margin-top: 10px;
        `}
      >
        #{hashtag}
      </div>
      <TodayPolitician hashtag={hashtag} additionalSupporter={"송영길"} />
      <div
        css={css`
          font-weight: bold;
          margin-top: 10px;
        `}
      >
        오늘의 #{hashtag} 사람들
      </div>

      <div
        css={css`
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        {relatedCards.map(card => (
          <div
            css={css`
              padding-top: 10px;
            `}
            key={card.id}
          >
            <HashCommentBubble cardId={card.id} key={card.id} />
          </div>
        ))}
      </div>
    </div>
  );
}
