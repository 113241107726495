import {
  FirestoreCollection,
  UserProfileMetadataSchema
} from "../constants/firestore_schema";
import { getUserName } from "../components/profile/OXOUserProfile/UserName";
import firebase from "firebase/compat";

export function blockOrUnblockUser(
  currentUserMetadata: UserProfileMetadataSchema,
  referenceUserMetadata: UserProfileMetadataSchema | undefined,
  reportedUserId: string
) {
  const blockedUsers = currentUserMetadata.blockedUsers || [];
  const alreadyBlockedReportedUser = blockedUsers.includes(reportedUserId);

  if (
    referenceUserMetadata &&
    window.confirm(
      getUserName(referenceUserMetadata, reportedUserId) +
        "를 차단" +
        (alreadyBlockedReportedUser
          ? " 해제 하시겠습니까?"
          : "하여 생각과 댓글은 숨기고 채팅은 동물소리로 표시하시겠습니까?")
    )
  ) {
    const newBlockedUsers = alreadyBlockedReportedUser
      ? blockedUsers.filter(userId => userId !== reportedUserId)
      : [...blockedUsers, reportedUserId];

    firebase
      .firestore()
      .collection(FirestoreCollection.USER_PROFILE_METADATA)
      .doc(currentUserMetadata.id)
      .set(
        {
          blockedUsers: newBlockedUsers
        } as UserProfileMetadataSchema,
        { merge: true }
      );
  }
}
