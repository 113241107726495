/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import { CSS_TITLE } from "../DashboardPage";
import { css } from "@emotion/react";
import {
  DATA_PLATFORM_GRAY_COLOR,
  DATA_PLATFORM_RED_GRAY
} from "../../../../constants/data_platform_colors";
import { getAgreePercentage } from "../../../../utils/data_utils";
import SupporterAnalysisReportDetails from "./SupporterAnalysisReportDetails";
import { DataMentionCard } from "../../DailyReportsView/DataMentionCard";
import CardStatsGenderAgeChartForPortrait from "../../../cards/DetailViews/CardStatsGenderAgeChartForPortrait";
import useDailyReportsDataForDetails from "../../DailyReportsView/useDailyReportsDataForDetails";

export default function DailyDashboardDetailsPerson({
  personHashtag,
  competitorHashtag
}: {
  personHashtag: string;
  competitorHashtag: string;
}) {
  const selectedDate = useMemo(() => new Date().valueOf(), []);
  const dailyReportsData = useDailyReportsDataForDetails({
    hashtag: personHashtag,
    selectedDate: selectedDate,
    limit: 10
  });

  const { cardAnswerStatsMap, cards } = dailyReportsData;

  if (!cardAnswerStatsMap || !cards) {
    return null;
  }

  const yesterdayCards = cards;
  const yesterdayCardIds = yesterdayCards.map(
    yesterdayCard => yesterdayCard.id
  );

  const sortedComments = Object.values(cardAnswerStatsMap)
    .filter(cardAnswerStat =>
      yesterdayCardIds.includes(cardAnswerStat.id || "")
    )
    .sort((statA, statB) => {
      return getAgreePercentage(statB) - getAgreePercentage(statA);
    });

  const mostPopularCards = sortedComments.slice(0, 1);
  const leastPopularCards = sortedComments
    .slice(sortedComments.length - 1, sortedComments.length)
    .filter(card => !mostPopularCards.map(card => card.id).includes(card.id));

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <div css={[CSS_TITLE]}> 1 오늘의 {personHashtag}</div>
      <div
        css={css`
          margin-top: 60px;
          font-size: 20px;
          font-weight: 700;
          color: ${DATA_PLATFORM_RED_GRAY};
        `}
      >
        지지율
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            정치성향별 지지율
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            연령성별 지지율
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          margin-top: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            width: 420px;
            height: 280px;
            margin-top: 10px;
          `}
        >
          <SupporterAnalysisReportDetails
            hashtag={personHashtag}
            selectedDate={selectedDate}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 420px;
            height: 280px;
          `}
        >
          <div
            css={css`
              margin-left: 70px;
            `}
          >
            <CardStatsGenderAgeChartForPortrait
              cardId={personHashtag}
              showCounts
            />
          </div>
        </div>
      </div>

      <div
        css={css`
          margin-top: 40px;
          font-size: 20px;
          font-weight: 700;
          color: ${DATA_PLATFORM_RED_GRAY};
        `}
      >
        영향을 끼친 발언
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            지지를 많이 받은 발언
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              margin-top: 6px;
              font-size: 18px;
              font-weight: 400;
              color: ${DATA_PLATFORM_RED_GRAY};
            `}
          >
            반대를 많이 받은 발언
          </div>
          <div
            css={css`
              margin-top: 6px;
              width: 420px;
              height: 1px;
              background-color: ${DATA_PLATFORM_GRAY_COLOR};
            `}
          ></div>
        </div>
      </div>

      <div
        css={css`
          margin-top: 10px;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 420px;
            `}
          >
            <div
              css={css`
                margin-top: 40px;
              `}
            >
              {mostPopularCards.length === 0 && (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    padding: 20px;
                    width: 420px;
                    color: ${DATA_PLATFORM_RED_GRAY};
                    margin-top: 50px;
                  `}
                >
                  발언이 아직 없습니다.
                </div>
              )}
              {mostPopularCards.map(
                (card, i) =>
                  card.id && (
                    <div
                      key={i}
                      css={css`
                        margin-bottom: 20px;
                        break-inside: avoid;
                      `}
                    >
                      <DataMentionCard
                        key={card.id}
                        cardId={card.id}
                        isAgree={true}
                        personHashtag={personHashtag}
                        competitorHashtag={competitorHashtag}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <div
              css={css`
                margin-top: 40px;
              `}
            >
              {leastPopularCards.length === 0 && (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    padding: 20px;
                    width: 420px;
                    color: ${DATA_PLATFORM_RED_GRAY};
                    margin-top: 50px;
                  `}
                >
                  발언이 아직 없습니다.
                </div>
              )}
              {leastPopularCards.map(
                (card, i) =>
                  card.id && (
                    <div
                      key={i}
                      css={css`
                        display: flex;
                        justify-content: flex-start;
                        width: 420px;
                      `}
                    >
                      <DataMentionCard
                        key={card.id}
                        cardId={card.id}
                        isAgree={false}
                        personHashtag={personHashtag}
                        competitorHashtag={competitorHashtag}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
