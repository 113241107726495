import firebase from "firebase/compat";

import React, { useContext } from "react";
import { GRAY_HASH_PAGE_BUTTON, GRAY_MEDIUM } from "../../../constants/enums";
import ProfileSidebarOpener from "../../profile/ProfileSidebarOpener";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import { Link, useHistory } from "react-router-dom";
import SearchIcon2 from "../Navigation/icons/SearchIcon2";
import { ROUTES } from "../../../constants/routes";
import { addToCardIdStackUrl } from "../../../utils/utils";
import addComment from "../../topic/Question/Comments/add_comment.svg";
import { GlobalVariableContext } from "../../foundation/Contexts/GlobalVariableContext";

export default function TitleAndUserProfile({
  title,
  desc
}: {
  title: string;
  desc: string;
}) {
  const global = useContext(GlobalVariableContext);
  const dataProps = useContext(DataPropsContext);
  const history = useHistory();
  const currentUserId = dataProps?.currentUserId;

  function getPageEnglishTitle(title: string) {
    switch (title) {
      case "투데이":
        return "today";
      case "커뮤니티":
        return "community";
      case "톡":
        return "talk";
      case "검색":
        return "search";
      case "테스트":
        return "test";
    }
  }

  return (
    <div
      style={{
        textAlign: "left",
        padding: "0 20px"
      }}
    >
      <div
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          color: GRAY_MEDIUM,
          paddingTop: 20,
          marginBottom: 6
        }}
      >
        {desc}
      </div>
      <div
        style={{
          fontWeight: "bold",
          fontSize: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          maxHeight: "32px",
          position: "relative"
        }}
      >
        <div style={{ paddingTop: 3 }}>{title}</div>

        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            right: 0
          }}
          onClick={() => {
            firebase.analytics().logEvent("v4_click_profile", {
              event_category: getPageEnglishTitle(title),
              event_label: currentUserId ? "login_status" : "logout_status"
            });
          }}
        >
          {title !== "톡" && (
            <div>
              <Link
                to={ROUTES.SEARCH}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  right: 45,
                  position: "absolute",
                  zIndex: 2
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: 32,
                    height: 32,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: GRAY_HASH_PAGE_BUTTON
                  }}
                >
                  <SearchIcon2 color={"#626262"} />
                </div>
              </Link>
              <div
                style={{
                  cursor: "pointer",
                  width: 32,
                  height: 32,
                  fontSize: 13,
                  marginRight: 58,
                  backgroundColor: GRAY_HASH_PAGE_BUTTON,
                  borderRadius: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => {
                  if (currentUserId) {
                    history.push(
                      history.location.pathname +
                        addToCardIdStackUrl(
                          history.location.search,
                          "CARD_EDIT"
                        )
                    );
                  } else {
                    if (
                      window.confirm(
                        "로그인이 필요한 기능입니다. 로그인을 하러 갈까요?"
                      )
                    ) {
                      global?.setLoginSuccessUrl("/?cardStack=profile_main");
                      history.push(
                        ROUTES.INTRO + "?successUrl=/urlStack/profile_main"
                      );
                    }
                  }
                }}
              >
                <img
                  src={addComment}
                  alt="addComment"
                  style={{
                    width: "17px"
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ width: 32, height: 32, cursor: "pointer" }}>
            <ProfileSidebarOpener />
          </div>
        </div>
      </div>
    </div>
  );
}
