/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import React from "react";
import { oxoColors } from "../../../constants/enums";

export default function StatisticsPosition() {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        font-size: 13px;
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            padding-right: 10px;
          `}
        >
          <div
            css={css`
              width: 13px;
              height: 13px;
              background-color: ${oxoColors.O};
            `}
          />
          <span>찬성</span>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            padding-right: 10px;
          `}
        >
          <div
            css={css`
              width: 13px;
              height: 13px;
              background-color: ${oxoColors.DUNNO};
            `}
          />
          <span>중립</span>
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 13px;
              height: 13px;
              background-color: ${oxoColors.X};
            `}
          />
          <span>반대</span>
        </div>
      </div>
    </div>
  );
}
