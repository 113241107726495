/** @jsxImportSource @emotion/react */

import firebase from "firebase/compat";
import React, { useEffect, useState } from "react";
import {
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { getMediaLogoImage, parseDoc } from "../../../../utils/utils";
import { StanceTestConfiguration } from "../common/hashpage_shared";
import logo from "./imgs/logo.svg";
import by from "./imgs/by.svg";
import { STANCE_TEST_SUFFIX } from "./StanceTestView";
import { Button } from "@material-ui/core";
import HashAnswerCount from "../common/HashAnswerCount";

export default function StanceTestQuestionsIntro1({
  stanceTestCard,
  stanceTestConfiguration,
  onNext
}: {
  stanceTestConfiguration: StanceTestConfiguration;
  stanceTestCard: CardSchema;
  onNext: () => void;
}) {
  const [userCount, setUserCount] = useState<number>();
  const formerTribeTestUserCount = 53906;

  useEffect(() => {
    if (stanceTestCard.id === "부족성향테스트") {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_VIEW_COUNTERS)
        .doc(stanceTestCard.id + STANCE_TEST_SUFFIX)
        .onSnapshot(snapshot => {
          setUserCount(
            parseDoc(snapshot).count + formerTribeTestUserCount || 0
          );
        });
      return () => {
        unsubscriber();
      };
    }
  }, []);

  const stanceTestMainColor = stanceTestConfiguration.메인컬러
    ? stanceTestConfiguration.메인컬러
    : "#3E7A99";

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 90
      }}
    >
      <div
        css={{
          fontWeight: "bolder",
          fontSize: 30,
          lineHeight: "45px",
          color: stanceTestMainColor,
          whiteSpace: "pre",
          textAlign: "center"
        }}
      >
        {stanceTestConfiguration.테스트소개}
      </div>

      <div css={{ paddingTop: 53 }}>
        {stanceTestCard.hashtagMetadata?.photos?.[0] && (
          <img
            src={stanceTestCard.hashtagMetadata.photos[0]}
            alt="stanceTestImg"
            css={{ maxWidth: 226 }}
          />
        )}
      </div>

      <Button
        style={{
          display: "flex",
          backgroundColor: stanceTestMainColor,
          color: "white",
          borderRadius: 12,
          padding: "10px 20px",
          width: 297,
          height: 73,
          marginTop: 54
        }}
        onClick={() => onNext()}
      >
        <div>
          <div css={{ fontWeight: "bold" }}>시작하기</div>
          <div css={{ fontSize: 12, display: "flex" }}>
            {userCount && stanceTestCard.id === "부족성향테스트" ? (
              <div>{userCount.toLocaleString("en-US")}</div>
            ) : (
              <HashAnswerCount
                hashtag={stanceTestCard.id}
                stanceTestLastQuestionCount
              />
            )}
            <span>명이 참여했어요!!</span>
          </div>
        </div>
      </Button>
      <div
        css={{
          width: "100%",
          marginTop: 70,
          padding: "0px 40px",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {stanceTestConfiguration.콜라보 ? (
          <div
            css={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around"
            }}
          >
            <div>
              <img
                style={{ width: 77 }}
                src={getMediaLogoImage(stanceTestConfiguration.콜라보)}
                alt="oXoLogo"
              />
            </div>
            <div>
              <img src={by} alt="byLogo" />
            </div>
            <div>
              <img style={{ width: 104 }} src={logo} alt="oXoLogo" />
            </div>
          </div>
        ) : (
          <img src={logo} alt="oXoLogo" />
        )}
      </div>
    </div>
  );
}
