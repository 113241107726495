import {
  CardAnswerTribeStatsSchema,
  CardAnswerTribeStatsV2Schema
} from "../../../constants/firestore_schema";
import { removeIdKey } from "../../../utils/utils";

export function getAverageTribeCardAnswerData(
  cardAnswers: CardAnswerTribeStatsV2Schema
) {
  if (!cardAnswers || !cardAnswers.tribeStats) {
    return {
      o: 0,
      "?": 0,
      x: 0,
      count: 0
    };
  }
  const averageSum = Object.keys(cardAnswers.tribeStats).reduce(
    (prev, tribeId) => {
      const count =
        (cardAnswers.tribeStats[tribeId].o || 0) +
        (cardAnswers.tribeStats[tribeId]["?"] || 0) +
        (cardAnswers.tribeStats[tribeId].x || 0);
      return {
        o:
          prev.o +
          ((cardAnswers.tribeStats[tribeId].o || 0) / (count || 1)) * 100,
        "?":
          prev["?"] +
          ((cardAnswers.tribeStats[tribeId]["?"] || 0) / (count || 1)) * 100,
        x:
          prev.x +
          ((cardAnswers.tribeStats[tribeId].x || 0) / (count || 1)) * 100,
        count: prev.count + count
      };
    },
    {
      o: 0,
      "?": 0,
      x: 0,
      count: 0
    }
  );

  const totalAverageSum = averageSum.o + averageSum["?"] + averageSum.x;
  return {
    o: Math.round((averageSum.o / totalAverageSum) * 1000) / 10,
    "?": Math.round((averageSum["?"] / totalAverageSum) * 1000) / 10,
    x: Math.round((averageSum.x / totalAverageSum) * 1000) / 10,
    count: 100
  };
}

export function getAverageTribeCardAnswerDataV1(
  cardAnswers: CardAnswerTribeStatsSchema
) {
  const averageSum = Object.keys(removeIdKey(cardAnswers)).reduce(
    (prev, tribeId) => {
      const count =
        (cardAnswers[tribeId].o || 0) +
        (cardAnswers[tribeId]["?"] || 0) +
        (cardAnswers[tribeId].x || 0);
      return {
        o: prev.o + ((cardAnswers[tribeId].o || 0) / (count || 1)) * 100,
        "?":
          prev["?"] + ((cardAnswers[tribeId]["?"] || 0) / (count || 1)) * 100,
        x: prev.x + ((cardAnswers[tribeId].x || 0) / (count || 1)) * 100,
        count: prev.count + count
      };
    },
    {
      o: 0,
      "?": 0,
      x: 0,
      count: 0
    }
  );
  const totalAverageSum = averageSum.o + averageSum["?"] + averageSum.x;
  return {
    o: Math.round((averageSum.o / totalAverageSum) * 1000),
    "?": Math.round((averageSum["?"] / totalAverageSum) * 1000),
    x: Math.round((averageSum.x / totalAverageSum) * 1000),
    count: 1000
  };
}
