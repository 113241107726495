import firebase from "firebase/compat";

import React, { useContext, useEffect, useState } from "react";
import {
  BACKGROUND_COLOR,
  GRAY_DARK,
  oxoColors
} from "../../../constants/enums";
import {
  FirestoreCollection,
  QuestionAnswerMapItemSchema,
  QuestionAnswerMapSchema,
  QuestionSupportersCoordinate,
  UserProfileMetadataSchema
} from "../../../constants/firestore_schema";
import { parseDoc, removeIdKey } from "../../../utils/utils";
import { PartiesMetadata } from "../../cards/metadata/parties_metadata";
import DocumentSkeleton from "../../utility/OXOLoader/DocumentSkeleton";
import UserMap, { SeriesType } from "../Charts/UserMap/UserMap-victory";
import { DataPropsContext } from "../Contexts/DataPropsContext";

export function OXOParties({
  questionAnswerMaps
}: {
  questionAnswerMaps?: { [questionId: string]: QuestionAnswerMapSchema };
}) {
  const seriesData: { [clusterId: string]: number[][] } = {
    나: [],
    더불어민주당: [],
    미래통합당: [],
    민생당: [],
    정의당: [],
    우리공화당: [],
    민중당: [],
    국민의당: [],
    국민의힘: [],
    "다른 사람들": []
  };

  const dataProps = useContext(DataPropsContext);
  const currentUserId = dataProps?.currentUserId;

  const [selectedParty, setSelectedParty] = useState<string>("대통령");

  const [currentUserMetadata, setCurrentUserMetadata] =
    useState<UserProfileMetadataSchema>();
  useEffect(() => {
    if (currentUserId) {
      const unsubscribe = firebase
        .firestore()
        .collection(FirestoreCollection.USER_PROFILE_METADATA)
        .doc(currentUserId)
        .onSnapshot(snapshot =>
          setCurrentUserMetadata(removeIdKey(parseDoc(snapshot)))
        );
      return () => unsubscribe();
    }
  }, [currentUserId]);

  if (!dataProps?.mapCoordinates || (currentUserId && !currentUserMetadata)) {
    return <DocumentSkeleton />;
  }

  Object.keys(dataProps.mapCoordinates).map(coordinateId => {
    const mapCoordinate = dataProps.mapCoordinates[coordinateId];
    seriesData["다른 사람들"].push([mapCoordinate.x, mapCoordinate.y, 2]);
  });

  if (currentUserMetadata) {
    seriesData["나"] = [[currentUserMetadata.x, currentUserMetadata.y]];
  }

  const mapWidth = Math.min(window.screen.width, 750);
  const series: SeriesType[] = [
    {
      name: "나",
      data: seriesData["나"],
      color: oxoColors.ME,
      label: "나",
      size: 4,
      opacity: 1.0
    }
  ];

  if (questionAnswerMaps) {
    Object.keys(questionAnswerMaps)
      .reverse()
      .forEach(questionId => {
        const data = Object.values(questionAnswerMaps[questionId])
          .filter(
            questionAnswerCoordinates => questionAnswerCoordinates.countO > 0
          )
          .map(questionAnswerCoordinates => {
            return [questionAnswerCoordinates.x, questionAnswerCoordinates.y];
          });

        const partiesMetadata = PartiesMetadata[questionId];
        if (
          !selectedParty ||
          (partiesMetadata && selectedParty == PartiesMetadata[questionId].name)
        ) {
          series.push({
            name: partiesMetadata.name,
            data: data,
            color: partiesMetadata.color,
            size: mapWidth / 400,
            allowOverlap: true,
            opacity: 1.0
          });
        }
      });

    Object.keys(questionAnswerMaps)
      .reverse()
      .forEach(questionId => {
        const questionAnswerMap = questionAnswerMaps[questionId];
        const oCentroid: QuestionSupportersCoordinate = {
          x: 0,
          y: 0,
          count: 0
        };

        Object.values(questionAnswerMap).forEach(
          (questionAnswerStats: QuestionAnswerMapItemSchema) => {
            if (isNaN(questionAnswerStats.x) || isNaN(questionAnswerStats.y)) {
              return;
            }

            oCentroid.x += questionAnswerStats.x * questionAnswerStats.countO;
            oCentroid.y += questionAnswerStats.y * questionAnswerStats.countO;
            oCentroid.count += questionAnswerStats.countO;
          }
        );

        oCentroid.x = oCentroid.x / oCentroid.count;
        oCentroid.y = oCentroid.y / oCentroid.count;
      });
  }

  series.push({
    name: "다른 사람들",
    data: seriesData["다른 사람들"],
    color: oxoColors.OTHERS,
    size: mapWidth / 400,
    opacity: 0.4
  });

  return (
    <div>
      <div
        style={{
          fontWeight: "bold",
          marginLeft: "20px",
          marginRight: "20px",
          wordBreak: "keep-all",
          marginTop: "10px",
          marginBottom: "10px"
        }}
      >
        {selectedParty} 지지자들의 위치에요.
      </div>
      <UserMap series={series} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0px",
          flexWrap: "wrap"
        }}
      >
        {Object.values(PartiesMetadata).map(partyMetadata => (
          <div
            style={{
              color:
                !selectedParty || selectedParty == partyMetadata.name
                  ? "white"
                  : GRAY_DARK,
              backgroundColor:
                !selectedParty || selectedParty == partyMetadata.name
                  ? GRAY_DARK
                  : BACKGROUND_COLOR,
              margin: "3px",
              fontSize: "12px",
              cursor: "pointer",
              padding: "5px",
              borderRadius: "10px",
              width: "90px"
            }}
            onClick={() => {
              firebase.analytics().logEvent("click_party_1", {
                event_category: "stats",
                event_label: partyMetadata.name
              });
              setSelectedParty(partyMetadata.name);
            }}
            key={partyMetadata.name}
          >
            {partyMetadata.name}
          </div>
        ))}
      </div>
    </div>
  );
}
