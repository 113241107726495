/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";

import legends from "./images/legends.png";

import {
  AnswerCountSchema,
  AnswerCountsSchema,
  CardAnswerStatsSchema,
  CardAnswerTribeStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";

import useFirestoreGetDocument from "../../../utils/hooks/useFirestoreGetDocument";
import {
  ACCENT_COLOR,
  DIVIDER_COLOR,
  PRIMARY_COLOR
} from "../../../constants/colors";
import { padZerosForTwoDigits } from "../../../utils/utils";

import AgeBarChartContainer from "./gender_age_chart/AgeBarChartContainer";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";
import CardAnswerTribeV1ColumnPlainBarChart from "../HashCardView/Comment/CardAnswerTribeV1ColumnPlainBarChart";
import CardAnswerComplexAgeChart from "../../charts/CardAnswerComplexAgeChart";
import CardAnswerComplexStanceChart from "../../charts/CardAnswerComplexStanceChart";
import DefaultDataSnapshot from "./DataSnapshots/DefaultDataSnapshot";
import EditedDataSnapshot from "./DataSnapshots/EditedDataSnapshot";
import CardAnswerTribeV1ColumnPlainBarChartSmall from "../HashCardView/Comment/CardAnswerTribeV1ColumnPlainBarChartSmall";
import CardAnswerTribeV1ColumnBarChart from "../HashCardView/Comment/CardAnswerTribeV1ColumnBarChart";
import CardAnswerComplexStanceAndTribeChart from "../../charts/CardAnswerComplexStanceAndTribeChart";

const backgroundSelectorWrapper = css`
  padding: 10px 0;

  label {
    display: block;
  }
`;

export const frame = css`
  width: 520px;
  padding: 20px;
  border: 2px solid ${ACCENT_COLOR};
`;

export const chartsLayout = css`
  width: 480px;
  display: flex;
  flex-direction: column;
  padding: 53px 49px 36px;

  border: solid 1px ${DIVIDER_COLOR};
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
`;

export const cardTitle = css`
  margin: 0 auto;

  font-weight: 600;
  font-size: 26px;
  text-align: center;
  line-height: 44px;

  white-space: pre-line;
`;

export const answerCounts = css`
  font-size: 16px;
  font-weight: 400;
  color: ${PRIMARY_COLOR};
`;

export const footer = css`
  color: ${PRIMARY_COLOR};
  font-size: 16px;
  text-align: center;
`;

export default function StatsForCardIdChart({
  cardId,
  answerStats,
  tribeStats,
  ageGenderStats,
  averageCardAnswer
}: {
  cardId: string;
  answerStats?: AnswerCountSchema;
  tribeStats?: CardAnswerTribeStatsSchema;
  ageGenderStats?: {
    [birthYear: number]: {
      [gender: string]: AnswerCountsSchema;
    };
  };
  averageCardAnswer?: AnswerCountsSchema;
}) {
  const card = useFirestoreGetDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );

  const [cardAnswers] =
    [answerStats] ??
    useFirestoreLiveDocument<CardAnswerStatsSchema>(
      FirestoreCollection.CARD_ANSWER_STATS,
      cardId
    );

  const date = new Date(
    card?.featuredOnNewsTimelineAt ||
      card?.featuredOnCommunityTimelineAt ||
      card?.featuredOnTodayTimelineAt ||
      card?.createdAt ||
      0
  );
  const dateFormat = `${date.getFullYear()}${padZerosForTwoDigits(
    date.getMonth() + 1
  )}${padZerosForTwoDigits(date.getDate())}`;

  const BACKGROUND_COLORS = ["#FEF2EA", "#E8F6FB", "#EEF2F8", "#FFFFFF"];

  const [backgroundColor, setBackgroundColor] = useState<string>(
    BACKGROUND_COLORS[0]
  );

  const [title, setTitle] = useState<string | undefined>(card?.title);

  const handleChangeBackgroundColor = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBackgroundColor = parseInt(event.target.value);

    setBackgroundColor(BACKGROUND_COLORS[selectedBackgroundColor]);
  };

  const handleChangeCustomBackgroundColor = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedBackgroundColor = event.target.value;

    setBackgroundColor(selectedBackgroundColor);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;

    setTitle(text);
  };

  useEffect(() => {
    setTitle(card?.title);
  }, [card?.title]);

  if (!card) {
    return <></>;
  }

  if (!cardAnswers?.count) {
    return <p>응답이 없어서, 보여드릴 차트가 준비되지 않았어요</p>;
  }

  return (
    <>
      <div css={backgroundSelectorWrapper}>
        <label htmlFor="select-backgroundColor">배경 색깔 변경하기</label>
        <select
          name="backgroundColor"
          id="select-backgroundColor"
          onChange={handleChangeBackgroundColor}
        >
          <option value="0">베이지</option>
          <option value="1">하늘</option>
          <option value="2">회색</option>
          <option value="3">흰색</option>
        </select>

        <label htmlFor="color-picker">
          현재 색깔(커스텀 가능해요. 아래 색깔 클릭해주세요)
        </label>
        <input
          type="color"
          name="color-picker"
          id="color-picker"
          value={backgroundColor}
          onChange={handleChangeCustomBackgroundColor}
        />

        <label htmlFor="title">제목 수정</label>
        <div>
          <textarea
            value={title}
            id="title"
            onChange={handleChangeTitle}
            rows={5}
            cols={33}
          />
        </div>
      </div>

      <DefaultDataSnapshot
        title={title}
        cardId={cardId}
        dateFormat={dateFormat}
        backgroundColor={backgroundColor}
        answerCounts={answerStats?.count ?? cardAnswers?.count}
        tribeStats={tribeStats}
        averageCardAnswer={averageCardAnswer}
      />

      <div>
        <p>Edited Version</p>
      </div>
      <EditedDataSnapshot
        backgroundColor={backgroundColor}
        title={title}
        cardId={cardId}
        cardAnswers={answerStats ?? cardAnswers}
        dateFormat={dateFormat}
        answerCounts={answerStats?.count ?? cardAnswers?.count}
        tribeStats={tribeStats}
      />

      <span>정치성향별 응답 결과 바 차트</span>
      <div css={frame}>
        <article css={chartsLayout}>
          <section>
            <header css={cardTitle}>{title}</header>
            <CardAnswerTribeV1ColumnBarChart
              cardId={cardId}
              tribeStats={tribeStats}
            />
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-top: 35px;
                margin-bottom: 20px;
              `}
            >
              <div>
                <p css={[answerCounts]}>응답 {cardAnswers?.count}</p>
              </div>

              <div>
                <img
                  src={legends}
                  css={css`
                    width: 180px;
                    height: 25px;
                    margin-top: -3px;
                    object-fit: contain;
                  `}
                />
              </div>
            </div>
          </section>
          <footer css={footer}>
            {dateFormat} 설문조사 | 옥소폴리틱스 제공
          </footer>
        </article>
      </div>

      <span>정치성향별 응답 결과 바 차트 (그래프 숫자 없는 버전)</span>
      <div css={frame}>
        <article css={chartsLayout}>
          <section>
            <header css={cardTitle}>{title}</header>
            <CardAnswerTribeV1ColumnPlainBarChart
              cardId={cardId}
              tribeStats={tribeStats}
            />
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-top: 35px;
                margin-bottom: 20px;
              `}
            >
              <div>
                <p css={[answerCounts]}>응답 {cardAnswers?.count}</p>
              </div>

              <div>
                <img
                  src={legends}
                  css={css`
                    width: 180px;
                    height: 25px;
                    margin-top: -3px;
                    object-fit: contain;
                  `}
                />
              </div>
            </div>
          </section>
          <footer css={footer}>
            {dateFormat} 설문조사 | 옥소폴리틱스 제공
          </footer>
        </article>
      </div>

      <span>
        정치성향별 응답 결과 바 차트 (그래프 숫자 없는 버전 - 작은 버전)
      </span>
      <div css={frame}>
        <article css={chartsLayout}>
          <section>
            <header css={cardTitle}>{title}</header>
            <CardAnswerTribeV1ColumnPlainBarChartSmall
              cardId={cardId}
              tribeStats={tribeStats}
            />
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-top: 35px;
                margin-bottom: 20px;
              `}
            >
              <div>
                <p css={[answerCounts]}>응답 {cardAnswers?.count}</p>
              </div>

              <div>
                <img
                  src={legends}
                  css={css`
                    width: 180px;
                    height: 25px;
                    margin-top: -3px;
                    object-fit: contain;
                  `}
                />
              </div>
            </div>
          </section>
          <footer css={footer}>
            {dateFormat} 설문조사 | 옥소폴리틱스 제공
          </footer>
        </article>
      </div>

      <span>연령 차트</span>
      <div css={frame}>
        <article css={chartsLayout}>
          <header css={[cardTitle]}>{title}</header>
          <AgeBarChartContainer cardId={cardId} />
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 35px;
              margin-bottom: 20px;
            `}
          >
            <p css={answerCounts}>응답 {cardAnswers?.count}</p>

            <div>
              <img
                src={legends}
                css={css`
                  width: 180px;
                  height: 25px;
                  margin-top: -3px;
                  object-fit: contain;
                `}
              />
            </div>
          </div>
          <footer css={footer}>
            {dateFormat} 설문조사 | 옥소폴리틱스 제공
          </footer>
        </article>
      </div>

      <span>정치 성향 + 연령 그래프</span>
      <div
        css={[
          frame,
          css`
            width: 1000px;
          `
        ]}
      >
        <CardAnswerComplexStanceAndTribeChart
          card={card}
          answerStats={answerStats}
          tribeStats={tribeStats}
          averageCardAnswer={averageCardAnswer}
        />
      </div>

      <span>파이 그래프 + 정치 성향별 그래프</span>
      <div
        css={[
          frame,
          css`
            width: 1000px;
          `
        ]}
      >
        <CardAnswerComplexStanceChart
          card={card}
          answerStats={answerStats}
          tribeStats={tribeStats}
          averageCardAnswer={averageCardAnswer}
        />
      </div>
      <span>파이 그래프 + 연령 그래프</span>
      <div
        css={[
          frame,
          css`
            width: 1000px;
          `
        ]}
      >
        <CardAnswerComplexAgeChart
          card={card}
          answerStats={answerStats}
          tribeStats={tribeStats}
        />
      </div>
    </>
  );
}
