import YAML from "yaml";
import { CardSubType } from "../../../../constants/firestore_schema";

export enum PAGE_COMPONENTS {
  HASHTAG = "해시태그",
  DESCRIPTION = "설명",
  PEOPLE = "인물들",
  HASHCARD_HIERARCHY = "해시지도",
  EVENTS = "이벤트들",
  TRIBE_DESCRIPTION = "부족별설명",
  STORY_PREVIEW = "발언들미리보기",
  STORY_DETAILS = "발언들세부내용",
  DEBATE = "토론",
  POLICIES = "공약비교",
  POLICIES_DETAIL = "공약비교내용",
  STANCE_TEST = "성향테스트",
  CAREER = "경력",
  EDUCATION = "학력",
  MAIN_POLICIES = "주요공약",
  RELATED_HASHCARD = "관련해시태그",
  PARTY = "정당",
  PROFILE = "프로필",
  ELECTION_INFO = "선거정보",
  PHOTO = "인물사진",
  AGENCY = "기관",
  ABBREVIATION = "별칭",
  LIVECHAT = "실시간중계",
  ELECTION_RESULT = "개표",
  IGNORE_DEFAULT = "기본값무시",
  BANNER = "배너",
  VOTE = "투표"
}

export interface HashtagConfiguration {
  해시태그?: boolean;
  설명?: string;
  인물들?: PeopleConfiguration;
  해시지도?: boolean;
  이벤트들?: EventsConfiguration;
  부족별설명?: boolean;
  발언들미리보기?: boolean;
  발언들세부내용?: boolean;
  토론?: DebateConfiguration;
  공약비교?: PoliciesConfiguration;
  공약비교내용?: PoliciesDetailConfiguration;
  성향테스트?: StanceTestConfiguration;
  경력?: CareerConfiguration;
  학력?: EducationConfiguration;
  주요공약?: MainPoliciesConfiguration;
  관련해시태그?: boolean;
  정당?: PartyConfiguration;
  프로필?: ProfileConfiguration;
  선거정보?: ElectionInfoConfiguration;
  인물사진?: boolean;
  기관?: string;
  별칭?: string;
  실시간중계?: LiveChatConfiguration;
  개표?: ElectionResultConfiguration;
  기본값무시?: boolean;
  배너?: BannerConfiguration;
}

export interface ElectionInfoConfiguration {
  [election: string]: {
    기호?: number;
  };
}

export interface PeopleConfiguration {
  제목?: string;
  설명?: string;
  해시태그?: PeopleHashtagsConfiguration;
}

export interface ProfileConfiguration {
  기호?: number;
  정당?: string;
  선거정보?: ElectionInfoConfiguration;
}

export interface PartyConfiguration {
  정당: string;
}

export interface StanceTestCategoryConfiguration {
  [category: string]: {
    URL?: string;
    color?: string;
    colorHeavy?: string;
  };
}

export const STANCE_TEST_GRAY = "#9E9E9F";

export enum StanceTestType {
  SCORE = "점수형",
  SIMILARITY = "유사도형",
  LIST = "리스트"
}

export interface StanceTestConfiguration {
  제목?: string;
  소개?: string;
  카테고리?: StanceTestCategoryConfiguration;
  결과링크?: string;
  결과?: string;
  콜라보?: string;
  결과점수?: string;
  테스트소개?: string;
  시작버튼?: string;
  메인컬러?: string;
  타입?: StanceTestType;
  단독사용?: boolean;
  결과지도명?: string;
  안내메세지?: string;
  결과지도카테고리?: string[];
}

export interface EventsConfiguration {
  제목: string;
  설명: string;
  내용: {
    [title: string]: {
      언론사: string;
      날짜: string;
      설명: string;
      해시태그: string;
    };
  };
}

export interface CareerConfiguration {
  내용?: string[];
}

export interface EducationConfiguration {
  내용?: string[];
}

export interface MainPoliciesConfiguration {
  내용?: string[];
}

export interface StanceTestQuestionConfiguration {
  색?: string;
  찬성?: {
    버튼?: string;
    자세히?: string;
  };
  세모?: {
    버튼?: string;
    자세히?: string;
  };
  반대?: {
    버튼?: string;
    자세히?: string;
  };
}

export interface PoliciesConfiguration {
  제목?: string;
  바로가기?: string;
  바로가기_해시태그?: string;
  후보?: string[];
  키워드?: string[];
}

export interface PoliciesDetailConfiguration {
  제목1?: string;
  제목2?: string;
  후보?: PeopleHashtagsConfiguration;
  키워드?: string[];
  공약?: {
    [keyword: string]: {
      [title: string]: {
        [candidate: string]: string;
      };
    };
  };
}

export enum DebateState {
  BEFORE_DEBATE = "토론전",
  IN_DEBATE = "토론중",
  AFTER_DEBATE = "요약중",
  AFTER_SUMMARY = "요약완료"
}

export enum ElectionResultStatus {
  BEFORE_COUNT = "집계전",
  COUNTING = "집계중",
  AFTER_COUNT = "집계후"
}

export interface ElectionResultConfiguration {
  집계?: ElectionResultStatus;
  참가자?: PeopleHashtagsConfiguration;
  개표일?: string;
  당선?: string;
  개표해시태그ID?: string;
}

export interface DebateMedia {
  [hashtag: string]: unknown;
}

export interface DebateConfiguration {
  토론중?: DebateState;
  부제목?: string;
  토론명?: string;
  참가자?: PeopleHashtagsConfiguration;
  방송사?: string;
  방송일?: string;
  런타임?: number;
  지역?: string;
  배너컬러?: string;
  설명?: string;
  채팅방ID?: string;
}

// TODO (will -> hanbyul): 배너 타입이 정의가 되어 있지 않습니다.
export interface BannerConfiguration {
  방송일?: string;
  런타임?: number;
  이름?: string;
}

export interface LiveChatConfiguration {
  유튜브?: string;
  채팅방ID?: string;
}

export interface PeopleHashtagsConfiguration {
  [name: string]: {
    기호?: number;
    정당사진?: string;
    컬러?: string;
  };
}

export interface candidateInfo {
  photoUrl: string;
  name: string;
  party: string;
  candidateNumber: number;
}

export interface bannerMedia {
  title?: string;
  hashtags?: string[];
}

/**
 YAML 예시 (선거 페이지)
 https://www.notion.so/Scheme-ef07ab21706e4d299cf6c095508b75d5
 */
export const DEFAULT_HASH_PAGE_CONFIGURATION = `해시태그: true
인물사진: false
설명: false
투표: true
부족별설명: true
관련해시태그: true
발언들미리보기: true
해시지도: true
`;

export const DEFAULT_HASH_PAGE_CONFIGURATION_PERSON = `해시태그: true
인물사진: true
해시지도: true
설명: false
투표: true
부족별설명: false
관련해시태그: true
발언들미리보기: true
`;

export const DEFAULT_HASH_PAGE_CONFIGURATION_ORGANIZATION = `해시태그: true
인물사진: false
설명: false
투표: true
부족별설명: true
관련해시태그: true
발언들미리보기: true
해시지도: true
`;

export const DEFAULT_HASH_PAGE_CONFIGURATION_EVENT = `해시태그: true
인물사진: false
설명: false
인물들: false
투표: true
부족별설명: true
관련해시태그: true
발언들미리보기: true
해시지도: true
`;

export const DEFAULT_HASH_PAGE_CONFIGURATION_ISSUE = `해시태그: true
인물사진: false
설명: false
투표: true
부족별설명: true
관련해시태그: true
발언들미리보기: true
해시지도: true
`;

export const DEFAULT_HASH_STORY_CONFIGURATION = `해시태그: false
발언들세부내용: true`;

export function getDefaultHashtagYaml(
  subType: CardSubType | undefined,
  hashtag?: string
) {
  if (hashtag && hashtag.endsWith("_스토리")) {
    return DEFAULT_HASH_STORY_CONFIGURATION;
  }

  return subType === CardSubType.HASHTAG_PERSON
    ? DEFAULT_HASH_PAGE_CONFIGURATION_PERSON
    : subType === CardSubType.HASHTAG_ISSUE
    ? DEFAULT_HASH_PAGE_CONFIGURATION_ISSUE
    : subType === CardSubType.HASHTAG_EVENT
    ? DEFAULT_HASH_PAGE_CONFIGURATION_EVENT
    : subType === CardSubType.HASHTAG_ORGANIZATION
    ? DEFAULT_HASH_PAGE_CONFIGURATION_ORGANIZATION
    : DEFAULT_HASH_PAGE_CONFIGURATION;
}

export function parseYamlDescription<T>(
  yamlDescription: string | undefined
): T {
  let hashPageConfiguration = {};
  try {
    if (yamlDescription && yamlDescription.length > 0) {
      hashPageConfiguration = YAML.parse(yamlDescription);
    }
  } catch (e) {
    console.log("Invalid yaml: " + yamlDescription);
  }

  return hashPageConfiguration as T;
}

export function parseYamlConfigurationWithDefault(
  yaml: string | undefined,
  hashtag?: string,
  defaultYaml?: string
) {
  const defaultYamlParsed = parseYamlDescription<HashtagConfiguration>(
    defaultYaml ||
      (hashtag && hashtag.endsWith("_스토리")
        ? DEFAULT_HASH_STORY_CONFIGURATION
        : DEFAULT_HASH_PAGE_CONFIGURATION)
  );
  const customYaml: HashtagConfiguration =
    hashtag && hashtag.endsWith("_스토리")
      ? {}
      : parseYamlDescription<HashtagConfiguration>(yaml);

  return {
    ...(customYaml.기본값무시 ? {} : defaultYamlParsed),
    ...customYaml
  };
}
