import React from "react";
import MediaCard from "../../cards/Cards/MediaCard";
import { MediaMetadata } from "../../cards/metadata/media_metadata";

export default function MediaTimeline({ tier }: { tier: number }) {
  const cards = Object.keys(MediaMetadata)
    .filter(key => MediaMetadata[key].tier === tier)
    .map((questionId, i) => {
      return (
        <div key={questionId} style={{ margin: "8px" }}>
          <MediaCard cardId={questionId} key={questionId} />
        </div>
      );
    });

  return (
    <div
      style={{
        display: "flex",
        overflow: "auto",
        paddingBottom: "10px",
        justifyContent: "center",
        flexWrap: "wrap"
        // scrollSnapType: "x mandatory"
      }}
    >
      {cards}
    </div>
  );
}
