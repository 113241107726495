import React, { CSSProperties } from "react";
import firebase from "firebase/compat";

import { addToCardIdStackUrl } from "../../../../utils/utils";
import { useHistory } from "react-router";
import { GRAY_DARK } from "../../../../constants/enums";

export default function HashtagPill({
  hashtag,
  style,
  onClick
}: {
  hashtag: string;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  const history = useHistory();
  return (
    <div
      style={{
        padding: "6px 10px 4px",
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10,
        fontWeight: "bold",
        cursor: "pointer",
        border: "solid 1px black",
        borderRadius: 20,
        color: GRAY_DARK,
        ...style
      }}
      key={hashtag}
      onClick={
        onClick
          ? onClick
          : e => {
              e.stopPropagation();
              firebase.analytics().logEvent("v4_click_hash_related_hashtag", {
                event_category: "hashcard",
                event_label: hashtag.replace("#", "")
              });
              history.push(
                history.location.pathname +
                  addToCardIdStackUrl(
                    history.location.search,
                    hashtag.replace("#", "")
                  )
              );
            }
      }
    >
      #{hashtag.replace(/\_/gi, " ")}
    </div>
  );
}
