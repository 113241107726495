import TimeAgo from "javascript-time-ago";
import React from "react";
import { todayInKorea } from "../../../utils/datetime";

const TIME_AGO_DAYS = 7;
export default function KoreanDate({
  timestamp,
  isLocaleString = false
}: {
  timestamp: number;
  isLocaleString?: boolean;
}) {
  const date = new Date(timestamp);
  const timeAgo = new TimeAgo("ko");
  const koreanDate = todayInKorea(date.valueOf());
  return (
    <>
      {isLocaleString
        ? date.toLocaleString()
        : new Date().getTime() - date.getTime() <
          1000 * 3600 * 24 * TIME_AGO_DAYS
        ? timeAgo.format(date)
        : `${koreanDate.year.toFixed().slice(2, 4)}${
            koreanDate.month < 10 ? "0" : ""
          }${koreanDate.month}${koreanDate.day < 10 ? "0" : ""}${
            koreanDate.day
          }`}
    </>
  );
}
