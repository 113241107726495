import React, { useContext } from "react";
import { TribeExpression } from "../../../constants/expressions";
import {
  PointFieldName,
  UserPrivilege
} from "../../../constants/firestore_schema";
import { addPostCommentNotification } from "../../../lib/notification_internal_lib";
import { addUserProfileMetadataCount } from "../../../lib/points";
import { addQuestionComment } from "../../../lib/question_lib";
import { DataPropsContext } from "../Contexts/DataPropsContext";
import CommentInput from "./CommentInput";
import { SnackbarContext } from "../Contexts/SnackbarContext";

export default function AddCommentInput({
  questionId,
  onInputSubmit,
  answer
}: {
  questionId: string;
  onInputSubmit?: (comment: string) => void;
  answer?: string;
}) {
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  const currentUserId = dataProps?.currentUserId;

  const displaySnackBar = useContext(SnackbarContext);

  if (!questionId || !currentUserId || !currentUserMetadata) {
    return null;
  }

  const onSubmit = (comment: string, currentExpression: TribeExpression) => {
    if (!currentUserId || !currentUserMetadata.tribeId) {
      window.alert("로그인과 정치 성향 테스트가 필요합니다.");
      return;
    }

    addQuestionComment(
      questionId,
      currentUserId,
      comment,
      currentUserMetadata.tribeId,
      !!(
        currentUserMetadata.privileges &&
        currentUserMetadata.privileges.includes(UserPrivilege.REAL_NAME_USER)
      ),
      answer,
      currentExpression
    );

    addPostCommentNotification(questionId, currentUserId);

    displaySnackBar("댓글이 추가되었습니다.");

    addUserProfileMetadataCount(PointFieldName.countComments, currentUserId);

    onInputSubmit && onInputSubmit(comment);
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "0 15px"
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          maxWidth: "750px",
          margin: "0 auto"
        }}
      >
        <CommentInput onSubmit={onSubmit} questionId={questionId} />
      </div>
    </div>
  );
}
