/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import firebase from "firebase/compat";
import { parseDoc, removeIdKey } from "../../../../utils/utils";
import {
  DATA_PLATFORM_GREEN_GRAY,
  DATA_PLATFORM_RED_GRAY,
  DATA_PLATFORM_YELLOW_GRAY
} from "../../../../constants/data_platform_colors";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import MiniQuestionStackChart from "../../../topic/Question/QuestionChart/MiniQuestionStackChart";
import { getAgreePercentage } from "../../../../utils/data_utils";
import { VictoryBar, VictoryGroup, VictoryStack } from "victory";
import MiniQuestionStackChartVertical from "../../../topic/Question/QuestionChart/MiniQuestionStackChartVertical";

export default function CardAnswerTribeBarChartVertical({
  cardId,
  isAgree = true,
  colorScale = [
    DATA_PLATFORM_RED_GRAY,
    DATA_PLATFORM_YELLOW_GRAY,
    DATA_PLATFORM_GREEN_GRAY
  ]
}: {
  cardId: string;
  isAgree?: boolean;
  colorScale?: string[];
}) {
  const [cardAnswerStats, setCardAnswerStats] =
    useState<CardAnswerTribeStatsSchema>();
  useEffect(() => {
    if (cardId) {
      const unsubscriber = firebase
        .firestore()
        .collection(FirestoreCollection.CARD_ANSWER_TRIBE_STATS)
        .doc(cardId)
        .onSnapshot(snapshot => {
          setCardAnswerStats(removeIdKey(parseDoc(snapshot)));
        });
      return () => {
        unsubscriber();
      };
    }
  }, [cardId]);

  if (!cardAnswerStats) {
    return null;
  }

  const stats = [
    cardAnswerStats.o || 0,
    cardAnswerStats["?"] || 0,
    cardAnswerStats.x || 0
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      {Object.keys(cardAnswerStats).map(tribeId => {
        return (
          <div
            key={tribeId}
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={css`
                width: 50px;
                font-size: 14px;
                margin-top: 2px;
                text-align: left;
                font-weight: 700;
                color: ${DATA_PLATFORM_RED_GRAY};
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>
            <div
              css={css`
                margin-left: 10px;
              `}
            >
              <MiniQuestionStackChartVertical
                cardChartStats={cardAnswerStats[tribeId]}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
