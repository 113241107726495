import passwordValidator from "password-validator";
import tigerImage from "../img/tribes2/tiger.png";
import tigerStandUpImage from "../img/tribes2/tiger_standup.png";
import tigerWatingImage from "../img/tribes2/tribeWating/tiger_wating.png";
import hippoImage from "../img/tribes2/hippo.png";
import hippoStandUpImage from "../img/tribes2/hippo_standup.png";
import hippoWatingImage from "../img/tribes2/tribeWating/hippo_wating.png";
import elephantImage from "../img/tribes2/elephant.png";
import elephantStandUpImage from "../img/tribes2/elephant_standup.png";
import elephantWatingImage from "../img/tribes2/tribeWating/elephant_wating.png";
import dinoImage from "../img/tribes2/dino.png";
import dinoStandUpImage from "../img/tribes2/dino_standup.png";
import dinoWatingImage from "../img/tribes2/tribeWating/dino_wating.png";
import lionImage from "../img/tribes2/lion.png";
import lionStandUpImage from "../img/tribes2/lion_standup.png";
import lionWatingImage from "../img/tribes2/tribeWating/lion_wating.png";

import tigerNormalFace from "../img/tribes2/tribeExpression/tiger_NORMAL.png";
import tigerHappyFace from "../img/tribes2/tribeExpression/tiger_HAPPY.png";
import tigerAngryFace from "../img/tribes2/tribeExpression/tiger_ANGRY.png";

import hippoNormalFace from "../img/tribes2/tribeExpression/hippo_NORMAL.png";
import hippoHappyFace from "../img/tribes2/tribeExpression/hippo_HAPPY.png";
import hippoAngryFace from "../img/tribes2/tribeExpression/hippo_ANGRY.png";

import elephantNormalFace from "../img/tribes2/tribeExpression/elephant_NORMAL.png";
import elephantHappyFace from "../img/tribes2/tribeExpression/elephant_HAPPY.png";
import elephantAngryFace from "../img/tribes2/tribeExpression/elephant_ANGRY.png";

import dinoNormalFace from "../img/tribes2/tribeExpression/dino_NORMAL.png";
import dinoHappyFace from "../img/tribes2/tribeExpression/dino_HAPPY.png";
import dinoAngryFace from "../img/tribes2/tribeExpression/dino_ANGRY.png";

import lionNormalFace from "../img/tribes2/tribeExpression/lion_NORMAL.png";
import lionHappyFace from "../img/tribes2/tribeExpression/lion_HAPPY.png";
import lionAngryFace from "../img/tribes2/tribeExpression/lion_ANGRY.png";

import googleIcon from "../components/profile/icons/social_google.svg";
import emailIcon from "../components/profile/icons/social_email.svg";
import kakaoIcon from "../components/profile/icons/social_kakao.svg";
import facebookIcon from "../components/profile/icons/social_facebook.svg";
import appleIcon from "../components/profile/icons/social_apple.svg";
import jtbcRectangle from "../img/media/media_Rectangle/JTBC.png";
import mbcRectangle from "../img/media/media_Rectangle/MBC.png";
import sbsRectangle from "../img/media/media_Rectangle/SBS.png";
import ytnRectangle from "../img/media/media_Rectangle/YTN.png";
import kbsRectangle from "../img/media/media_Rectangle/KBS.png";
import 한국경제 from "../img/media/koreaEco.png";

import bs from "../img/newsMediaCircleLogo/bs.png";
import cs from "../img/newsMediaCircleLogo/cs.png";
import da from "../img/newsMediaCircleLogo/da.png";
import fb from "../img/newsMediaCircleLogo/fb.png";
import hg from "../img/newsMediaCircleLogo/hg.png";
import hgr from "../img/newsMediaCircleLogo/hgr.png";
import ja from "../img/newsMediaCircleLogo/ja.png";
import kh from "../img/newsMediaCircleLogo/kh.png";
import sg from "../img/newsMediaCircleLogo/sg.png";
import yh from "../img/newsMediaCircleLogo/yh.png";
import ki from "../img/newsMediaCircleLogo/국민일보.png";
import np from "../img/newsMediaCircleLogo/국민청원.png";

import 더불어민주당 from "../img/parties/parties_small/더불어민주당.png";
import 국민의당 from "../img/parties/parties_small/국민의당.png";
import 국민의힘 from "../img/parties/parties_small/국민의힘.png";
import 민생당 from "../img/parties/parties_small/민생당.png";
import partyDefault from "../img/parties/parties_small/party_small_default.png";

import mediaDefault from "../img/media/media_default.png";
import {
  AnswerCountsSchema,
  CardAnswerTribeStatsSchema,
  CardsSchema,
  CommentSchema,
  LikeUserAndTribesSchema,
  LikeUserMap,
  UserCoordinateAndTribeSchema,
  UserPrivilege,
  UserProfileMetadataSchema,
  UserVectorSchema
} from "../constants/firestore_schema";
import { QuestionType, TRIBE_INFO_BY_ID, UserAnswer } from "../constants/enums";
import { ROUTES } from "../constants/routes";
import * as H from "history";
import queryString from "querystring";
import { DataPropsContext } from "../components/foundation/Contexts/DataPropsContext";
import { useContext, useEffect, useState } from "react";

export function hyperLinkText(txt: string) {
  const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return txt.replace(reg, "<a href='$1$2' target='_blank'>$1$2</a>");
}

/**
 * Returns "1"-"5" for most supporting tribe ID. "0" if there is no top supporting tribe.
 *
 * @param cardAnswerTribeStats
 */
export function getMostSupportingTribe(
  cardAnswerTribeStats: CardAnswerTribeStatsSchema
) {
  return (
    Object.keys(removeIdKey(cardAnswerTribeStats))
      .filter(
        tribeId =>
          (cardAnswerTribeStats[tribeId]?.count || 0) > 0 &&
          (cardAnswerTribeStats[tribeId]?.o || 0) /
            (cardAnswerTribeStats[tribeId]?.count || 1) >
            0
      )
      .sort((tribeA, tribeB) => {
        const tribeAPercentage =
          (cardAnswerTribeStats[tribeA]?.o || 0) /
          (cardAnswerTribeStats[tribeA]?.count || 1);
        const tribeBPercentage =
          (cardAnswerTribeStats[tribeB]?.o || 0) /
          (cardAnswerTribeStats[tribeB]?.count || 1);
        return tribeAPercentage === tribeBPercentage
          ? (cardAnswerTribeStats[tribeB]?.count || 0) -
              (cardAnswerTribeStats[tribeA]?.count || 0)
          : tribeBPercentage - tribeAPercentage;
      })[0] || "0"
  );
}

export function hashtagTextAdapter(text: string): string {
  if (text.includes("#") || text.includes("@") || text.includes("*")) {
    const tokens = text.split(" ").map((token, i) => {
      if (token.startsWith("#") || token.startsWith("@")) {
        return `*${token}*_removeTrailingSpace_`;
      } else if (token.startsWith("*")) {
        if (token.slice(1).includes("*")) {
          return token;
        } else {
          return token + "*_removeTrailingSpace_";
        }
      }
      return token;
    });

    return tokens.join(" ").replace(/_removeTrailingSpace_ ?/g, "");
  }
  return text;
}

export function padZerosForTwoDigits(num: number) {
  const norm = Math.floor(Math.abs(num));
  return (norm < 10 ? "0" : "") + norm;
}

export function dateToIso(date: Date, dateOnly = false) {
  const dateString =
    date.getFullYear() +
    "-" +
    padZerosForTwoDigits(date.getMonth() + 1) +
    "-" +
    padZerosForTwoDigits(date.getDate());

  if (dateOnly) {
    return dateString;
  }

  return (
    dateString +
    "T" +
    padZerosForTwoDigits(date.getHours()) +
    ":" +
    padZerosForTwoDigits(date.getMinutes()) +
    ":" +
    padZerosForTwoDigits(date.getSeconds())
  );
}

export function truncateLongSentence(
  str: string,
  maxLen: number,
  dontCountUrl = false
) {
  if (!str) {
    return "";
  }
  let trimmedString: string = str;

  let urlRemovedString = str;
  if (dontCountUrl) {
    urlRemovedString = trimmedString
      .replace(/(?:https?|ftp):\/\/[\n\S]+/g, "")
      .trim();
  }

  if (trimmedString.length > urlRemovedString.length) {
    maxLen = maxLen + (trimmedString.length - urlRemovedString.length);
  }

  //Trim and re-trim only when necessary (prevent re-trim when string is shorted than maxLength, it causes last word cut)
  if (trimmedString.length > maxLen) {
    //trim the string to the maximum length
    trimmedString = trimmedString.substr(0, maxLen);

    //re-trim if we are in the middle of a word and
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );

    trimmedString += "...";
  }

  return trimmedString;
}

export function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function groupByTyped<T>(
  lst: T[],
  func: (key: T) => string
): { [key: string]: T[] } {
  return lst.reduce((resultMap, x) => {
    (resultMap[func(x)] = resultMap[func(x)] || []).push(x);
    return resultMap;
  }, {} as { [key: string]: T[] });
}

export function getMostFrequentElement<T>(arr: T[]) {
  return arr
    .sort(
      (a, b) =>
        arr.filter(v => v === a).length - arr.filter(v => v === b).length
    )
    .pop();
}

export function getSortedTopicQuestionIds(questions: CardsSchema) {
  const questionByType: {
    [questionType: string]: string[];
  } = groupBy(Object.keys(questions), (questionId: string) =>
    QuestionType[questions[questionId].type].toString()
  );

  const filteredQuestions = questionByType["TOPIC"]
    ? questionByType["TOPIC"]
        .filter(questionId => questions[questionId].displayToUsers)
        // @ts-ignore
        .filter(questionId => !questions[questionId].title.includes("#투표"))
        .filter(
          // @ts-ignore
          questionId => !questions[questionId].title.includes("2020년 총선에서")
        )
    : [];

  const sorted = filteredQuestions.sort(
    (questionId1, questionId2) =>
      questions[questionId2].createdAt - questions[questionId1].createdAt
  );
  return sorted;
}

export function getCommentLikeCount(likeUserMap: LikeUserMap) {
  return likeUserMap
    ? Object.keys(likeUserMap).filter(a => likeUserMap[a]).length
    : 0;
}

/**
 * One like is worth 3 hours. 4 Likes = 1 day
 *
 * @param createdAt
 * @param commentLikeCount
 */
export function getRecencyLikeScore(
  createdAt: number,
  commentLikeCount: number
) {
  return createdAt + commentLikeCount * 1000 * 3600 * 3;
}

export function getSortedOnboardingTopicQuestionIds(questions: CardsSchema) {
  const questionByType: {
    [questionType: string]: string[];
  } = groupBy(Object.keys(questions), (questionId: string) =>
    QuestionType[questions[questionId].type].toString()
  );

  const filteredQuestions = questionByType["TOPIC"]
    ? questionByType["TOPIC"]
        .filter(questionId => questions[questionId].displayToUsers)
        .filter(questionId => questions[questionId].displayInOnboarding)
    : [];

  const sorted = filteredQuestions.sort((questionId1, questionId2) => {
    const q1 = questions[questionId1];
    const q2 = questions[questionId2];
    return q1.publishedAt && q2.publishedAt
      ? q1.publishedAt - q2.publishedAt
      : 0;
  });
  return sorted;
}

export function groupBy<T>(lst: Array<T>, func: (item: T) => string) {
  return lst.reduce((rv: { [item: string]: T[] }, x: T) => {
    (rv[func(x)] = rv[func(x)] || []).push(x);
    return rv;
  }, {});
}

export function multiGroupBy<T>(lst: Array<T>, func: (item: T) => string[]) {
  return lst.reduce((rv: { [item: string]: T[] }, x: T) => {
    func(x).forEach(key => {
      (rv[key] = rv[key] || []).push(x);
    });
    return rv;
  }, {});
}

export function toHashMap(key: string) {
  // @ts-ignore
  return list => {
    const map = {};
    // @ts-ignore
    list.forEach(item => (map[item[key]] = item));
    return map;
  };
}

export function getUserShard(userId: string) {
  return userId.charAt(0).toLowerCase();
}

export function getShardWithMod(idString: string, m: number) {
  return idString.charCodeAt(0) % m;
}

export function listToMap<T>(
  list: T[],
  mapFunc: (item: T) => string,
  valueFunc: (item: T) => any
) {
  const map = {};
  list.forEach(
    // @ts-ignore
    item => (map[mapFunc(item)] = valueFunc(item))
  );
  return map;
}

export function mapValues<T, R>(
  map: { [key: string]: T },
  func: (value: T, key: string) => R
): { [key: string]: R } {
  return Object.entries(map).reduce((a: { [key: string]: R }, [key, value]) => {
    a[key] = func(value, key);
    return a;
  }, {});
}

export function flatten<T>(list: T[][]) {
  return list.reduce((acc: T[], val: T[]) => acc.concat(val), []);
}

// @ts-ignore
export function parseDoc(doc) {
  return {
    id: doc.id,
    ...doc.data()
  };
}

// @ts-ignore
export function getDocsFromSnapshot(snapshot) {
  return snapshot.docs.map(parseDoc);
}

// @ts-ignore
export function zip(a, b) {
  // @ts-ignore
  return a.map((e, i) => [e, b[i]]);
}

// @ts-ignore
export function intersect(arrayA, arrayB) {
  // @ts-ignore
  return arrayA.filter(x => arrayB.includes(x));
}

export function removeIdKey(map: any) {
  if (map && map.id) {
    delete map.id;
  }
  return map;
}

export function getUserVectorInNumbers(userVector: UserVectorSchema) {
  // @ts-ignore
  return mapValues(removeIdKey(userVector), answer =>
    answer === "o" ? 1 : -1
  );
}

// @ts-ignore
export function getMapFromSnapshots(snapshots) {
  return mapValues(toHashMap("id")(snapshots.docs.map(parseDoc)), removeIdKey);
}

export function useIsAdmin() {
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const dataProps = useContext(DataPropsContext);
  const currentUserMetadata = dataProps?.currentUserMetadata;
  if (!currentUserMetadata) {
    return undefined;
  }
  useEffect(() => {
    setIsAdmin(
      currentUserMetadata &&
        currentUserMetadata.privileges &&
        currentUserMetadata.privileges.includes(UserPrivilege.ADMIN)
    );
  }, [currentUserMetadata]);

  return isAdmin;
}

// @ts-ignore
export function answerToNumber(answer) {
  if (answer === UserAnswer.O) {
    return 1;
  }
  if (answer === UserAnswer.X) {
    return -1;
  }
  return 0;
}

// @ts-ignore
export function numberToAnswer(score) {
  if (isNaN(score)) {
    return "";
  }
  if (score > 0.4) {
    return UserAnswer.O;
  }
  if (score < -0.4) {
    return UserAnswer.X;
  }
  return UserAnswer.DUNNO;
}

export function getTribeImage(tribeImageID: string) {
  if (tribeImageID === "hippo") {
    return hippoImage;
  } else if (tribeImageID === "elephant") {
    return elephantImage;
  } else if (tribeImageID === "lion") {
    return lionImage;
  } else if (tribeImageID === "tiger") {
    return tigerImage;
  } else if (tribeImageID === "dino") {
    return dinoImage;
  }
}

export function getTribeStandUpImage(tribeImageID: string) {
  if (tribeImageID === "hippo") {
    return hippoStandUpImage;
  } else if (tribeImageID === "elephant") {
    return elephantStandUpImage;
  } else if (tribeImageID === "lion") {
    return lionStandUpImage;
  } else if (tribeImageID === "tiger") {
    return tigerStandUpImage;
  } else if (tribeImageID === "dino") {
    return dinoStandUpImage;
  }
}

export function getTribeWaitingImage(tribeImageID: string) {
  if (tribeImageID === "hippo") {
    return hippoWatingImage;
  } else if (tribeImageID === "elephant") {
    return elephantWatingImage;
  } else if (tribeImageID === "lion") {
    return lionWatingImage;
  } else if (tribeImageID === "tiger") {
    return tigerWatingImage;
  } else if (tribeImageID === "dino") {
    return dinoWatingImage;
  }
}

export function getTribeExpressionImage(tribeImageID: string, answer: string) {
  switch (tribeImageID) {
    case "tiger":
      if (answer === "o") {
        return tigerHappyFace;
      } else if (answer === "x") {
        return tigerAngryFace;
      } else {
        return tigerNormalFace;
      }
    case "hippo":
      if (answer === "o") {
        return hippoHappyFace;
      } else if (answer === "x") {
        return hippoAngryFace;
      } else {
        return hippoNormalFace;
      }
    case "elephant":
      if (answer === "o") {
        return elephantHappyFace;
      } else if (answer === "x") {
        return elephantAngryFace;
      } else {
        return elephantNormalFace;
      }
    case "dino":
      if (answer === "o") {
        return dinoHappyFace;
      } else if (answer === "x") {
        return dinoAngryFace;
      } else {
        return dinoNormalFace;
      }
    case "lion":
      if (answer === "o") {
        return lionHappyFace;
      } else if (answer === "x") {
        return lionAngryFace;
      } else {
        return lionNormalFace;
      }
  }
}

export function getMediaLogoImage(media: string) {
  if (media.startsWith("http")) {
    return media;
  } else {
    switch (media) {
      case "JTBC":
        return jtbcRectangle;
      case "MBC":
        return mbcRectangle;
      case "SBS":
        return sbsRectangle;
      case "YTN":
        return ytnRectangle;
      case "KBS":
        return kbsRectangle;
      case "한국경제":
        return 한국경제;
      default:
        return mediaDefault;
    }
  }
}

export function getNewsCircleLogoImage(newsMedia: string) {
  switch (newsMedia) {
    case "조선":
      return cs;
    case "한겨레":
      return hgr;
    case "중앙":
      return ja;
    case "경향":
      return kh;
    case "동아":
      return da;
    case "한국":
      return hg;
    case "세계":
      return sg;
    case "연합":
      return yh;
    case "부산일보":
      return bs;
    case "페이스북":
      return fb;
    case "국민일보":
      return ki;
    case "국민청원":
      return np;
    default:
      return mediaDefault;
  }
}

export function getPartySmallLogo(party: string) {
  if (party.startsWith("http")) {
    return party;
  } else {
    switch (party) {
      case "더불어민주당":
        return 더불어민주당;
      case "국민의힘":
        return 국민의힘;
      case "국민의당":
        return 국민의당;
      case "민생당":
        return 민생당;
      case "무소속":
        return partyDefault;
      default:
        return partyDefault;
    }
  }
}

export function getDateInfo(date: string, type: string) {
  const WEEK: string[] = [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일"
  ];

  const dateInfo: string[] = date.split(" ");

  const getDate: number[] = dateInfo[0].split("-").map(el => Number(el));

  const dayOfWeek =
    WEEK[new Date(getDate[0], getDate[1] - 1, getDate[2], 0, 0, 0).getDay()];

  dateInfo[0] = dateInfo[0].replace(/-/gi, ".");

  const timeInfo = dateInfo[1].split(":");

  timeInfo[0] = `${Number(timeInfo[0]) > 12 ? "오후" : "오전"} ${
    Number(timeInfo[0]) >= 13 ? Number(timeInfo[0]) - 12 : Number(timeInfo[0])
  }`;

  switch (type) {
    case "날짜":
      return dateInfo[0];
    case "시간":
      if (Number(timeInfo[1])) {
        return `${dayOfWeek} ${timeInfo[0]}시 ${Number(timeInfo[1])}분`;
      } else return `${dayOfWeek} ${timeInfo[0]}시`;
  }
}

export function socialSignProviderIcon(socialSignProvider: any) {
  switch (socialSignProvider) {
    case "google.com":
      return googleIcon;
    case "facebook.com":
      return facebookIcon;
    case "apple.com":
      return appleIcon;
    case "kakao.kakao":
      return kakaoIcon;
    case "password":
      return emailIcon;
  }
}

export function convertLikeUserMapToLikeUserAndTribes(
  likeUserMap: LikeUserMap
) {
  if (!likeUserMap) return {};
  return listToMap(
    Object.keys(likeUserMap).filter(userId => likeUserMap[userId]),
    (userId: string) => userId,
    (userId: string) =>
      (likeUserMap[userId] as UserCoordinateAndTribeSchema).tribeId
  );
}

export function convertLikeUserMetadataToLikeUserAndTribes(
  likeUserMap: LikeUserMap
) {
  if (!likeUserMap) return {};
  return listToMap(
    Object.keys(likeUserMap).filter(userId => likeUserMap[userId]),
    (userId: string) => userId,
    (userId: string) =>
      (likeUserMap[userId] as UserProfileMetadataSchema).tribeId
  );
}

export function getCommentLikeByTribe(
  comment: CommentSchema
): LikeUserAndTribesSchema {
  return (
    comment.likeUserAndTribes ||
    convertLikeUserMapToLikeUserAndTribes(comment.likeUserMap)
  );
}

export function sleep(milliseconds: number) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export function checkAndRedirectForLogin(
  history: H.History,
  currentUserId: string | undefined,
  currentUserMetadata: UserProfileMetadataSchema | undefined,
  isTribeTest = false,
  successUrl: string = ROUTES.INTRO_LOGGED_IN
) {
  if (!currentUserId) {
    if (
      window.confirm(
        "정치 부족이 아직 없으시군요! 로그인 후 부족테스트를 진행해주시면 옥소에서 활동할 수 있어요!"
      )
    ) {
      const params = addToUrlQuery(
        window.location.search,
        "successUrl",
        successUrl
      );

      history.push(ROUTES.INTRO + params);
    }
    return false;
  } else if (
    (!currentUserMetadata || !currentUserMetadata.tribeId) &&
    !isTribeTest
  ) {
    if (
      window.confirm(
        "정치 부족이 아직 없으시군요! 부족테스트를 진행해주시면 옥소에서 활동할 수 있어요!"
      )
    ) {
      history.push(ROUTES.INTRO_LOGGED_IN);
    }
    return false;
  } else if (
    (!currentUserMetadata || !currentUserMetadata?.termsOfService) &&
    !isTribeTest
  ) {
    history.push(ROUTES.INTRO_LOGGED_IN);
    return false;
  }
  return true;
}

export function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function addToCardIdStackUrl(search: string, newCardId: string) {
  const qs = queryString.parse(search.replace("?", ""));

  const cardStack: string[] = qs["cardStack"]
    ? (qs["cardStack"] as string).split("+")
    : [];

  if (cardStack[cardStack.length - 1] !== newCardId) {
    cardStack.push(newCardId);
  }

  qs["cardStack"] = cardStack;

  qs["cardStack"] = qs["cardStack"].join("+");

  delete qs["cardId"];
  return "?" + queryString.stringify(qs);
}

export function parseUrlQuery(search: string): {
  [key: string]: string | undefined;
} {
  return queryString.parse(search.replace("?", "")) as {
    [key: string]: string | undefined;
  };
}

export function addToUrlQuery(search: string, key: string, value: string) {
  const qs = parseUrlQuery(search);
  qs[key] = value;
  return "?" + queryString.stringify(qs);
}

export function removeFromUrlQuery(search: string, key: string) {
  const qs = parseUrlQuery(search);
  delete qs[key];
  return "?" + queryString.stringify(qs);
}

export function sumTwoMaps<T extends { [key: string]: number }>(
  map1: T,
  map2: T
): { [key: string]: number } {
  return listToMap(
    [...Object.keys(map1), ...Object.keys(map2)].filter(onlyUnique),
    key => key,
    key => (map1[key] || 0) + (map2[key] || 0)
  );
}

export function sumTwoAnswerCounts(
  map1: AnswerCountsSchema,
  map2: AnswerCountsSchema
): AnswerCountsSchema {
  if (!map1 && !map2) {
    return {
      o: 0,
      x: 0,
      "?": 0,
      count: 0
    };
  }

  if (!map1) {
    return map2;
  }

  if (!map2) {
    return map1;
  }

  return {
    o: Math.max(map1.o, 0) + Math.max(map2?.o || 0, 0),
    x: Math.max(map1.x, 0) + Math.max(map2?.x || 0, 0),
    "?": Math.max(map1["?"], 0) + Math.max(map2?.["?"] || 0, 0),
    count: Math.max(map1.count, 0) + Math.max(map2?.count || 0, 0)
  };
}

export function sumTwoTribeAnswerCounts(
  map1: CardAnswerTribeStatsSchema,
  map2: CardAnswerTribeStatsSchema
) {
  return mapValues(TRIBE_INFO_BY_ID, (value, tribeId) => {
    return sumTwoAnswerCounts(
      map1[tribeId] as AnswerCountsSchema,
      map2[tribeId] as AnswerCountsSchema
    );
  });
}

export function hexToRGB(hex: string, alpha: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export function passwordValidationChecker(
  password1: string,
  password2: string
): string[] {
  let validationResult: string[];

  const schema = new passwordValidator();

  schema
    .is()
    .min(8)
    .is()
    .max(15)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .symbols(1)
    .has()
    .not()
    .spaces();

  if (password1 !== password2) {
    validationResult = ["discord"];
  } else {
    validationResult = schema.validate(password1, { list: true }) as string[];
  }
  const validateResultInterpreter = (errName: string): string => {
    switch (errName) {
      case "discord":
        return "비밀번호가 일치하지 않습니다.";
      case "min":
        return "비밀번호는 최소 8자리 이상 작성해야 합니다.";
      case "max":
        return "비밀번호는 최대 15자리 이하 작성해야 합니다.";
      case "uppercase":
        return "비밀번호는 최소 1개 이상 영 대문자를 포함해야 합니다.";
      case "lowercase":
        return "비밀번호는 최소 1개 이상 영 소문자를 포함해야 합니다.";
      case "digits":
        return "비밀번호는 최소 1개 이상 숫자를 포함해야 합니다.";
      case "symbols":
        return "비밀번호는 최소 1개 이상 특수문자를 포함해야 합니다.";
      case "spaces":
        return "비밀번호에는 스페이스(공백)가 들어갈 수 없습니다.";
      default:
        return "정확한 비밀번호를 입력해주세요";
    }
  };

  if (!validationResult.length) {
    return validationResult;
  } else {
    return [validateResultInterpreter(validationResult[0])];
  }
}
