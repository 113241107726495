/** @jsxImportSource @emotion/react */

import React, { CSSProperties } from "react";
import {
  CardAnswerStatsSchema,
  CardSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";
import { GRAY_DARK } from "../../../../constants/enums";
import firebase from "firebase/compat";

import { addToCardIdStackUrl } from "../../../../utils/utils";
import { useHistory } from "react-router";

export default function SupporterPercentage({
  cardId,
  disableClick,
  addOxoModifier = false,
  style = {},
  numberSize = 12,
  showCounts = false,
  replaceTextWithIcon = false
}: {
  cardId: string;
  disableClick?: boolean;
  addOxoModifier?: boolean;
  style?: CSSProperties;
  numberSize?: number;
  showCounts?: boolean;
  replaceTextWithIcon?: boolean;
}) {
  const history = useHistory();
  const [card] = useFirestoreLiveDocument<CardSchema>(
    FirestoreCollection.CARDS,
    cardId
  );
  const [questionAnswerStats] = useFirestoreLiveDocument<CardAnswerStatsSchema>(
    FirestoreCollection.CARD_ANSWER_STATS,
    cardId
  );

  if (!questionAnswerStats || !card) {
    return null;
  }

  return (
    <div
      style={{
        color: GRAY_DARK,
        fontSize: 12,
        fontWeight: "normal",
        ...(disableClick ? {} : { cursor: "pointer" }),
        ...style
      }}
      onClick={e => {
        if (disableClick) {
          return;
        }
        firebase.analytics().logEvent("v4_click_hash_comment_name_support", {
          event_category: "hashcard",
          event_label: cardId
        });
        e.stopPropagation();
        history.push(
          history.location.pathname +
            addToCardIdStackUrl(history.location.search, `${cardId}`)
        );
      }}
    >
      {addOxoModifier && <>옥소</>}
      {replaceTextWithIcon ? (
        <span css={{ marginRight: 5, fontSize: 9 }}>❤️</span>
      ) : (
        "지지율 "
      )}
      <span style={{ fontSize: numberSize }}>
        {(
          ((questionAnswerStats.o || 0) / (questionAnswerStats.count || 1)) *
          100
        ).toFixed()}
      </span>
      {"%"}
      {showCounts && " (" + `${questionAnswerStats.count || 0}` + "표)"}
    </div>
  );
}
