import firebase from "firebase/compat";

import React, { Dispatch, SetStateAction } from "react";
import { TRIBE_INFO_BY_ID } from "../../../constants/enums";
import UserProfilePhoto from "./UserProfilePhoto";

export default function TribeButtons({
  currentTribeId,
  setCurrentTribeId,
  badges
}: {
  currentTribeId: string;
  setCurrentTribeId: Dispatch<SetStateAction<string>>;
  badges?: string[];
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      {Object.keys(TRIBE_INFO_BY_ID).map((tribeId, i) => (
        <div style={{ display: "flex", flexDirection: "column" }} key={tribeId}>
          <div style={{ position: "relative" }} key={tribeId}>
            <div
              style={{
                width: 45,
                height: 45,
                padding: 3,
                margin: 5,
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",

                border: `2px solid ${
                  tribeId === currentTribeId
                    ? TRIBE_INFO_BY_ID[tribeId].heavyColor
                    : "white"
                }`
              }}
            >
              <div
                key={tribeId}
                style={{
                  width: 40,
                  height: 35,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: 1,
                  cursor: "pointer",
                  position: "relative",
                  backgroundColor: TRIBE_INFO_BY_ID[tribeId].bgColor
                }}
                onClick={() => {
                  firebase.analytics().logEvent("select_tribe", {
                    event_category: "tribe_selector",
                    event_label: tribeId
                  });

                  setCurrentTribeId(tribeId);
                }}
              >
                <UserProfilePhoto width={"22px"} tribeId={tribeId} />
              </div>
            </div>
            {badges && (
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  borderRadius: "50%",
                  width: 20,
                  height: 20,
                  fontSize: 10,
                  backgroundColor: TRIBE_INFO_BY_ID[tribeId].heavyColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold"
                }}
              >
                {badges[i]}
              </div>
            )}
          </div>
          {tribeId === currentTribeId && (
            <div style={{ fontSize: 10 }}>{TRIBE_INFO_BY_ID[tribeId].name}</div>
          )}
        </div>
      ))}
    </div>
  );
}
