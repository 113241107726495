import React from "react";
import CardStatsGenderAgeChart from "./CardStatsGenderAgeChart";
import ResultStatistics from "./ResultStatistics";
import CardAnswerTribeChart from "../../views/HashCardView/Comment/CardAnswerTribeChart";

export default function DetailPagesUsermapTab({ cardId }: { cardId: string }) {
  return (
    <div
      style={{
        paddingBottom: 70,
        margin: "auto",
        maxWidth: 450
      }}
    >
      <div style={{ paddingTop: 20 }} />
      <ResultStatistics cardId={cardId} />

      <div style={{ paddingTop: 20 }}>
        <CardAnswerTribeChart cardId={cardId} />
      </div>
      <div style={{ paddingTop: 60 }}>
        <CardStatsGenderAgeChart cardId={cardId} />
      </div>
    </div>
  );
}
