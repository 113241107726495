import React from "react";
import HashPageStoryTimeline from "./HashPageStoryTimeline";

export default function HashStoryDetails({ hashtag }: { hashtag: string }) {
  if (hashtag.endsWith("_스토리")) {
    hashtag = hashtag.replace("_스토리", "");
  }
  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <HashPageStoryTimeline hashtags={[hashtag]} />
    </div>
  );
}
