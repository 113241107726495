import { Button } from "@material-ui/core";
import React, { ReactNode } from "react";
import { UserAnswer } from "../../../constants/enums";
import Dunno_selected from "./icon/dunno_selected.svg";
import Dunno_unselected from "./icon/dunno.svg";
import O_selected from "./icon/like_selected.svg";
import O_unselected from "./icon/like.svg";
import X_selected from "./icon/dislike_selected.svg";
import X_unselected from "./icon/dislike.svg";

function getOxoButton(
  onVote: (answer: string) => void,
  gap: number,
  userAnswer?: string
) {
  return (
    buttonAnswer: string,
    activeButton: ReactNode,
    inactiveButton: ReactNode
  ) => {
    return (
      <Button
        onClick={() => {
          onVote(buttonAnswer);
        }}
        style={{
          minWidth: 64,
          cursor: "pointer",
          margin: "10px 5px",
          padding: 0,
          borderRadius: "50%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 88
            }}
          >
            {userAnswer === buttonAnswer ? activeButton : inactiveButton}
          </div>
        </div>
      </Button>
    );
  };
}

export function OxButtons({
  answer,
  onVote,
  gap = 0
}: {
  answer?: string;
  onVote: (answer: string) => void;
  gap?: number;
}) {
  const oxoButton = getOxoButton(onVote, gap, answer);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start"
      }}
    >
      {oxoButton(
        UserAnswer.O,
        <img src={O_selected} alt={UserAnswer.O} />,
        <img src={O_unselected} alt={UserAnswer.O} />
      )}
      {oxoButton(
        UserAnswer.DUNNO,
        <img src={Dunno_selected} alt={UserAnswer.DUNNO} />,
        <img src={Dunno_unselected} alt={UserAnswer.DUNNO} />
      )}
      {oxoButton(
        UserAnswer.X,
        <img src={X_selected} alt={UserAnswer.X} />,
        <img src={X_unselected} alt={UserAnswer.X} />
      )}
    </div>
  );
}
