/** @jsxImportSource @emotion/react */

import React, { useMemo } from "react";
import {
  AnswerCountsSchema,
  CardAnswerAgeGenderStatsSchema,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import { sumTwoAnswerCounts } from "../../../utils/utils";
import { CSS_TD } from "../DataExplorerView/shared";
import useFirestoreLiveDocument from "../../../utils/hooks/useFirestoreLiveDocument";

export default function HashtagTimeSeriesGenerationAnswersForCard({
  cardId
}: {
  cardId: string;
}) {
  const [cardAnswerAgeGenderState] =
    useFirestoreLiveDocument<CardAnswerAgeGenderStatsSchema>(
      FirestoreCollection.CARD_ANSWER_AGE_GENDER_STATS,
      cardId
    );

  const statsByAgeGroup = useMemo(() => {
    if (!cardAnswerAgeGenderState) {
      return;
    }
    if (
      cardAnswerAgeGenderState.id &&
      cardAnswerAgeGenderState.id.length < 5 &&
      Object.keys(cardAnswerAgeGenderState).length < 2
    ) {
      return {};
    }
    return Object.keys(cardAnswerAgeGenderState).reduce<{
      [ageGroup: string]: {
        [gender: string]: {
          o: number;
          "?": number;
          x: number;
          count: number;
        };
      };
    }>((prev, birthYear) => {
      let ageGroupNumber =
        Math.floor((new Date().getFullYear() - Number(birthYear)) / 10) * 10;
      if (ageGroupNumber === 0 || ageGroupNumber >= 100) {
        return prev;
      }

      if (ageGroupNumber > 60) {
        ageGroupNumber = 60;
      }

      const ageGroup = ageGroupNumber.toFixed();

      const birthYearGenderStat = cardAnswerAgeGenderState?.[Number(birthYear)];
      if (birthYearGenderStat) {
        if (prev[ageGroup]) {
          prev[ageGroup]["male"] = sumTwoAnswerCounts(
            (prev[ageGroup]["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema,
            (birthYearGenderStat["male"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }) as AnswerCountsSchema
          );

          prev[ageGroup]["female"] = sumTwoAnswerCounts(
            prev[ageGroup]["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            },
            birthYearGenderStat["female"] || {
              o: 0,
              x: 0,
              "?": 0,
              count: 0
            }
          ) as {
            o: number;
            "?": number;
            x: number;
            count: number;
          };
        } else {
          prev[ageGroup] = birthYearGenderStat;
        }
      }
      return prev;
    }, {});
  }, [cardAnswerAgeGenderState]);

  if (!statsByAgeGroup) {
    return null;
  }

  return (
    <>
      {Object.keys(statsByAgeGroup).map(ageGroup => {
        return (
          <td key={ageGroup} css={CSS_TD}>
            {(
              (((statsByAgeGroup[ageGroup]["male"]?.o || 0) +
                (statsByAgeGroup[ageGroup]["female"]?.o || 0)) /
                ((statsByAgeGroup[ageGroup]["male"]?.count || 0) +
                  (statsByAgeGroup[ageGroup]["female"]?.count || 0) || 1)) *
              100
            ).toFixed(1)}
            %
          </td>
        );
      })}
    </>
  );
}
