import { DescriptionOutlined } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

export default function TextReportPopupButton({ cardId }: { cardId: string }) {
  const history = useHistory();
  return (
    <div>
      <div
        style={{ display: "flex", cursor: "pointer" }}
        onClick={() => history.push(ROUTES.CARD_TEXT_REPORT + "/" + cardId)}
      >
        <div style={{ marginRight: 5 }}>
          <DescriptionOutlined />
        </div>{" "}
        <div style={{ paddingBottom: 7 }}>텍스트 보고서</div>
      </div>
    </div>
  );
}
