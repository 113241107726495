/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";

import ButtonO from "./ButtonO";
import ButtonX from "./ButtonX";
import ButtonD from "./ButtonD";
import {
  ANSWER_DUNNO_STROKE,
  ANSWER_O_STROKE,
  ANSWER_X_STROKE,
  SCAFFOLD_BACKGROUND_COLOR
} from "../../../constants/colors";

const buttonWrapper = css`
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
`;

export function ButtonOx() {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        border-radius: 15px;
        overflow: hidden;
      `}
    >
      <div css={[buttonWrapper, { backgroundColor: ANSWER_O_STROKE }]}>
        <ButtonO color={SCAFFOLD_BACKGROUND_COLOR} />
      </div>
      <div css={[buttonWrapper, { backgroundColor: ANSWER_DUNNO_STROKE }]}>
        <ButtonD color={SCAFFOLD_BACKGROUND_COLOR} />
      </div>
      <div css={[buttonWrapper, { backgroundColor: ANSWER_X_STROKE }]}>
        <ButtonX color={SCAFFOLD_BACKGROUND_COLOR} />
      </div>
    </div>
  );
}
