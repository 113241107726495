import React from "react";
import {
  CommentSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";

import { truncateLongSentence } from "../../../../utils/utils";
import { GRAY_EXTRA_DARK, TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import useFirestoreLiveDocument from "../../../../utils/hooks/useFirestoreLiveDocument";

export default function SingleLineComment({
  commentId,
  disableExpressions,
  maxLen = 100
}: {
  commentId: string;
  disableExpressions?: boolean;
  maxLen?: number;
}) {
  const [comment] = useFirestoreLiveDocument<CommentSchema>(
    FirestoreCollection.COMMENTS,
    commentId
  );

  if (!comment) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start"
      }}
    >
      <div>
        <div
          style={{
            fontWeight: "bold",
            paddingTop: 3,
            paddingRight: 10,
            width: "100px"
          }}
        >
          {comment?.userTribeId &&
            TRIBE_INFO_BY_ID[comment?.userTribeId].shortDescription}
        </div>
      </div>
      <div
        style={{
          color: GRAY_EXTRA_DARK,
          flex: 1,
          overflow: "hidden",
          lineHeight: 1.5
        }}
      >
        {truncateLongSentence(comment?.comment || "", maxLen)}
      </div>
    </div>
  );
}
