import React, { useEffect, useMemo, useState } from "react";
import {
  CardSchema,
  CardType,
  FirestoreCollection
} from "../../../constants/firestore_schema";
import {
  DAY,
  DAYS,
  formatKoreanDate,
  getKoreaStandardTimeWeekBoundary,
  todayInKorea,
  WEEK
} from "../../../utils/datetime";
import firebase from "firebase/compat";
import { getDocsFromSnapshot, groupBy } from "../../../utils/utils";
import HashCommentBubbles from "./HashCommentBubbles";
import { GRAY_DARK } from "../../../constants/enums";
import { MenuItem, Select } from "@material-ui/core";
import TodayHashtagTitle from "../../views/Today/hashtag/TodayHashtagTitle";
import ReactWordcloud, { CallbacksProp, OptionsProp } from "react-wordcloud";

const START_DATE = 1611500400000; //  Monday January 25, 2021 00:00:00 (am) in time zone Asia/Seoul (KST)

interface WordCloudDataSchema {
  text: string;
  value: number;
}

const WORD_CLOUD_OPTIONS: OptionsProp = {
  colors: ["#AC8DB6", "#CEA055", "#699BB0", "#B76E6C", "#AAAF83"],
  enableTooltip: false,
  fontFamily: "Noto Sans KR, sans-serif",
  fontSizes: [14, 30] as [number, number],
  fontWeight: "bold",
  deterministic: true,
  padding: 2,
  rotations: 0
};

export function getWeeksFromLastDay(lastDay: number) {
  const now = lastDay.valueOf();
  const weeks = [];
  for (let i = START_DATE; i <= now - 2 * DAYS; i += WEEK) {
    weeks.push(i);
  }
  return weeks.reverse();
}

export default function WeeklyReviewSummary() {
  const lastDay = new Date().valueOf();
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    new Date(lastDay - 2 * DAYS)
  );

  const weeks = useMemo(() => {
    return getWeeksFromLastDay(lastDay);
  }, []);

  const [cards, setCards] = React.useState<CardSchema[]>();
  const [weekStartAt, weekEndsAt] = useMemo(
    () => getKoreaStandardTimeWeekBoundary(selectedDate.valueOf()),
    [selectedDate]
  );

  useEffect(() => {
    setCards(undefined);
    firebase
      .firestore()
      .collection(FirestoreCollection.CARDS)
      .where("type", "in", [CardType.TOPIC, CardType.NEWS_TICKER])
      .where("publishedAt", ">=", weekStartAt)
      .where("publishedAt", "<", weekEndsAt)
      .orderBy("publishedAt", "desc")
      .get()
      .then(snapshot => {
        let cards: CardSchema[] = getDocsFromSnapshot(snapshot);
        cards = cards.filter(
          card => card.type !== CardType.TOPIC || card.displayToUsers
        );
        setCards(cards);
        setCurrentHashtag(undefined);
      });
  }, [weekStartAt, weekEndsAt]);

  const callbacks: CallbacksProp = useMemo(() => {
    return {
      onWordClick: (word: WordCloudDataSchema) =>
        setCurrentHashtag(word.text.replace(/\s/g, "_"))
    };
  }, []);

  const [currentHashtag, setCurrentHashtag] = useState<string>();
  if (!cards) {
    return null;
  }

  const cardByHashtags = groupBy(cards, card =>
    (card.hashtags?.[0] || "해시태그없음").replace("#", "").trim()
  );

  const sortedHashtags = Object.keys(cardByHashtags)
    .sort(
      (cardIdA, cardIdB) =>
        cardByHashtags[cardIdB].length - cardByHashtags[cardIdA].length
    )
    .slice(0, 12);

  if (!currentHashtag) {
    setCurrentHashtag(sortedHashtags[0]);
  }

  const wordCloudData: WordCloudDataSchema[] = sortedHashtags.map(hashtag => {
    return {
      text: hashtag.replace(/_/gi, " "),
      value: cardByHashtags[hashtag].length + 200
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "30px",
            marginLeft: 5
          }}
        >
          주간 정치
        </div>
        <div
          style={{
            marginTop: 18,
            fontSize: 14,
            color: GRAY_DARK
          }}
        >
          <Select
            value={weekStartAt}
            defaultValue={weekStartAt}
            onChange={e => {
              if (e.target.value) {
                setSelectedDate(new Date(e.target.value as number));
              }
            }}
          >
            {weeks.map(weekStartAt => {
              const startsAt = todayInKorea(weekStartAt);
              const endsAt = todayInKorea(weekStartAt + WEEK - DAY);
              return (
                <MenuItem value={weekStartAt} key={weekStartAt}>
                  {formatKoreanDate({
                    month: startsAt.month,
                    day: startsAt.day
                  })}{" "}
                  ~{" "}
                  {formatKoreanDate({
                    month: endsAt.month,
                    day: endsAt.day
                  })}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <div
        style={{
          height: 0.9,
          width: "100%",
          backgroundColor: "black",
          marginTop: -1
        }}
      >
        {" "}
      </div>
      <div
        style={{
          marginTop: 30,
          height: 200,
          width: "99%",
          paddingTop: 10,
          marginLeft: 5,
          marginRight: 5,
          paddingBottom: 10
        }}
      >
        <ReactWordcloud
          words={wordCloudData}
          callbacks={callbacks}
          options={WORD_CLOUD_OPTIONS}
        />
      </div>
      {currentHashtag && cardByHashtags[currentHashtag] && (
        <div key={currentHashtag}>
          <div style={{ paddingRight: 20 }}>
            <TodayHashtagTitle hashtag={currentHashtag} />
          </div>
          <div>
            <HashCommentBubbles
              cards={cardByHashtags[currentHashtag].sort(
                (cardA, cardB) =>
                  (cardA.publishedAt || cardA.createdAt) -
                  (cardB.publishedAt || cardB.createdAt)
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}
