/** @jsxImportSource @emotion/react */

import React from "react";
import {
  CardAnswerTribeStatsSchema,
  FirestoreCollection
} from "../../../../constants/firestore_schema";
import { removeIdKey } from "../../../../utils/utils";

import { css } from "@emotion/react";
import { TRIBE_INFO_BY_ID } from "../../../../constants/enums";
import getTribeStandupImage from "../../../../utils/image_utils/getTribeStandupImage";
import TribePieChartContainer from "../../DataExplorerView/TribePieChartContainer";
import useFirestoreGetDocument from "../../../../utils/hooks/useFirestoreGetDocument";

export default function CardAnswerTribeV1ChartEdited({
  cardId,
  tribeStats
}: {
  cardId: string;
  tribeStats?: CardAnswerTribeStatsSchema;
}) {
  const cardAnswerTribeStats = tribeStats
    ? tribeStats
    : removeIdKey(
        useFirestoreGetDocument<CardAnswerTribeStatsSchema>(
          FirestoreCollection.CARD_ANSWER_TRIBE_STATS,
          cardId
        )
      );

  if (!cardAnswerTribeStats) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        width: "100%"
      }}
    >
      {Object.keys(removeIdKey(cardAnswerTribeStats)).map(tribeId => {
        return (
          <div
            key={tribeId}
            style={{
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <div
              css={css`
                margin-top: 10px;
                margin-bottom: 40px;
                font-size: 16px;
                text-align: center;
                font-weight: 600;
              `}
            >
              {TRIBE_INFO_BY_ID[tribeId].shortDescription}
            </div>

            <div
              css={css`
                .apexcharts-theme-light {
                  z-index: 100 !important;
                }
              `}
            >
              <TribePieChartContainer stats={cardAnswerTribeStats[tribeId]} />
            </div>

            <div
              style={{ position: "absolute", top: 39, right: 17, zIndex: 1 }}
            >
              <img
                key={tribeId}
                src={getTribeStandupImage(tribeId)}
                alt="부족 이미지"
                css={css`
                  width: 45px;
                  height: 45px;
                `}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
